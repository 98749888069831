import React, { useEffect, useState } from "react";

import { ExclamationCircleOutlined } from "@ant-design/icons";

import "./styles.scss";

import { COLORS } from "assets/colors/colors";

const ErrorRow = ({ message, errorColor }) => {
  const [isShow, setIsShow] = useState(false);
  const color = errorColor ? errorColor : COLORS.unavailable;

  useEffect(() => {
    if (!!message) {
      setIsShow(true);
      setTimeout(() => setIsShow(false), 5000);
    }
  }, [message]);

  return (
    <div className="error-row-container">
      {isShow ? (
        <div>
          <ExclamationCircleOutlined style={{ color }} />
          <div style={{ color }}>{message}</div>
        </div>
      ) : null}
    </div>
  );
};

export default ErrorRow;
