import React, { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import RenderNoData from "components/RenderNoData";
import { roundingNumbers } from "utils/roundingNumbers";
import useColorTheme from "../../hooks/useColorTheme";
import useCountry, { NUMBER_TYPE } from "../../hooks/useCountry";

const ChartSingleLine = ({title, ...props}) => {
  const { primaryColor, primaryColorRgb } = useColorTheme();
  const inputRef = useRef(null);

  const { priceParam, currentData, compareData, isCompare, type } = props;
  const { currentCurrency, formatCurrencyNumber } = useCountry();

  const [dates, setDates] = useState([]);
  const [compareDates, setCompareDates] = useState([]);
  const [pricings, setPricings] = useState([]);
  const [emptyData, setEmptyData] = useState(false);

  useEffect(() => {
    for (const chart of Highcharts.charts) {
      if (chart) {
        chart.reflow();
      }
    }
  }, [isCompare]);

  const prefix = type === "content" ? "" : currentCurrency;
  const isCurrency = type === "content" ? false : true;
  const suffix = type === "content" ? "%" : "";

  const setCurrentDate = () => {
    if (currentData.data.values.length) {
      const pricings = [
        {
          name: currentData.data.name,
          data: currentData.data.values.map((item) => Number(item[priceParam])),
        },
      ];

      const datesMoment = currentData.dates.map((item) => moment(item).format("DD MMM YYYY"));

      setDates(datesMoment);
      setPricings(pricings);
    }
  };

  useEffect(() => {
    setCurrentDate();
  }, [currentData, priceParam]);

  useEffect(() => {
    if (!isCompare) {
      setCurrentDate();
    }
  }, [isCompare]);

  useEffect(() => {
    if (compareData.data.values.length) {
      const newData = [
        {
          name: compareData.data.name,
          data: compareData.data.values.map((item) => Number(item[priceParam])),
          dashStyle: "dash",
          fillColor: 0,
        },
      ];

      setCompareDates(compareData.dates);
      setPricings((prevState) => [...prevState, ...newData]);
    }
  }, [compareData, priceParam]);

  useEffect(() => {
    if (pricings.length) {
      pricings.forEach(({ data }) => {
        if (data.length) {
          setEmptyData(false);
        } else {
          setEmptyData(true);
        }
      });
    }
  }, [pricings]);

  const options = {
    title: "",
    chart: {
      type: "areaspline",
      reflow: true,
      height: 300,
      style: {
        fontFamily: "Gilroy-Medium",
        fontSize: "10px",
      },
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        color: primaryColor,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
      areaspline: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, `${primaryColorRgb(0.2)}`],
            [1, `${primaryColorRgb(0)}`],
          ],
        },
        marker: {
          enabled: false,
        },
        lineWidth: 3,
        threshold: null,
      },
    },
    xAxis: {
      crosshair: {
        width: 1,
        color: "gray",
        dashStyle: "solid",
      },
      type: "datetime",
      labels: {
        step: parseInt(dates.length / 3),
        style: {
          fontSize: "10px",
        },
      },
      categories: dates,
    },
    yAxis: {
      title: {
        text: "",
      },
      minTickInterval: 0.1,
      labels: {
        formatter: function() {
          if(title==="Average Share") {
            return `${formatCurrencyNumber(roundingNumbers(this.value))} %` 
          }
          return `${formatCurrencyNumber(this.value, isCurrency ? NUMBER_TYPE.CURRENCY : NUMBER_TYPE.DECIMAL)}${suffix}`
        },
        style: {
          fontSize: "10px",
        },
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: null,
      borderWidth: 0,
      formatter: function () {
        return renderTooltip(this);
      },
    },
    legend: {
      enabled: false,
    },
    series: pricings,
  };

  const renderTooltip = (thisObj) => {
    const renderValue = (y) => {
      let value

      if(title==="Average Share") {
        value = `${formatCurrencyNumber(roundingNumbers(y))} %` 
      } else {
        value = formatCurrencyNumber(roundingNumbers(y), isCurrency ? NUMBER_TYPE.CURRENCY : NUMBER_TYPE.DECIMAL);
      }
      
      return value;
    };
    
    return `<div class="wrapper-total">
            <div class='title'>${title}</div>
            <div class='wrapper'>
                ${
                  thisObj.points.length > 1
                    ? `
                    <div class='box'>
                        <div class='total-data'>${moment(compareDates[thisObj.points[1].point.index]).format(
                          "DD MMM YY"
                        )}</div>
                        <div>${renderValue(thisObj.points[1].y)}${suffix}</div>
                    </div> 
                    <div class='line'></div>
                    <div class='box'>
                      <div class='total-data'>${moment(thisObj.points[0].key).format("DD MMM YY")}</div>
                      <div>${renderValue(thisObj.points[0].y)}${suffix}
                    </div>
                  </div>
                  `
                    : `
                  <div class='box'>
                    <div class='total-data'>${moment(thisObj.points[0].key).format("DD MMM YY")}</div>
                    <div>${renderValue(thisObj.points[0].y)}${suffix}</div>
                  </div>
                  `
                }
            </div>
          </div>`;
  };

  return (
    <>
      <div className="wrapper-chart-box">
        {emptyData || !pricings.length ? (
          <RenderNoData style={{ position: "absolute", height: "96%", zIndex: "1" }} />
        ) : null}
        <HighchartsReact ref={inputRef} highcharts={Highcharts} options={options} />
      </div>
    </>
  );
};

export default ChartSingleLine;
