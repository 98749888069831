import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Portal from "utils/Portal";
import useRetailers from "../../../../hooks/useRetailers";
import useCache from "../../../../hooks/useCache";
import useFilters from "../../../../hooks/useFilters";

import { changeNameRetailer } from "utils/changeNameRetailer";

import green from "assets/images/green.svg";
import red from "assets/images/red.svg";

const AverageChangeChart = (props) => {
  const { action, storeTerm, storeShare, actionShareSingleDay, storeShareSingleDay, name, data, isLocation } = props;

  const dispatch = useDispatch();
  const { getRemoteLogo } = useRetailers();

  const { cachedCurrentItemTermSearch: currentItemTermSearch } = useCache();
  const { currentItemTermLocation } = useSelector((state) => state.rankingLocationAnalysis);

  const { currentShareSearch } = useSelector((state) => state.rankingSearchAnalysis);
  const { currentShareLocation } = useSelector((state) => state.rankingLocationAnalysis);

  const { currentShareSearchSingleDay } = useSelector((state) => state.rankingSearchAnalysis);
  const { currentShareLocationSingleDay } = useSelector((state) => state.rankingLocationAnalysis);

  const { lastFilter } = useFilters();
  const { date } = lastFilter;
  const wrapperRef = useRef(null);

  const [isSingleDate, setIsSingleDate] = useState(false);

  const [showTooltip, setShowTooltip] = useState(false);
  const [indexTooltip, setIndexTooltip] = useState(null);
  const [coords, setCoords] = useState({});

  const getStore = (param) => {
    const stores = {
      currentItemTermSearch,
      currentItemTermLocation,
      currentShareSearch,
      currentShareLocation,
      currentShareSearchSingleDay,
      currentShareLocationSingleDay,
    };

    return stores[param];
  };

  useEffect(() => {
    if (date.length) {
      if (Number(date)) {
        if (Number(date) === 1 || Number(date) === 2) {
          setIsSingleDate(true);
        } else {
          setIsSingleDate(false);
        }
      } else {
        const startDate = date.split("|")[1].split("-")[2];
        const endDate = date.split("|")[2].split("-")[2];

        const numberDate = Number(endDate) - Number(startDate);

        if (numberDate === 0) {
          setIsSingleDate(true);
        } else {
          setIsSingleDate(false);
        }
      }
    }
  }, [date]);

  function useOutsideAlerter(refWrapper) {
    useEffect(() => {
      if (showTooltip) {
        function handleClickOutside(event) {
          if (refWrapper.current && !refWrapper.current.contains(event.target)) {
            setShowTooltip(false);
          }
        }
        document.addEventListener("mouseout", handleClickOutside);

        return () => {
          document.removeEventListener("mouseout", handleClickOutside);
        };
      }
    }, [refWrapper, showTooltip]);
  }
  useOutsideAlerter(wrapperRef);

  const onMouseMove = (e, index) => {
    if (wrapperRef.current) {
      let wrapperBox = wrapperRef.current.getBoundingClientRect();

      setCoords({
        y: wrapperBox.top - 100,
        x: e.pageX - 98,
      });

      setShowTooltip(true);
      setIndexTooltip(index);
    }
  };

  const setCurrentlyRetailer = (title) => {
    if (getStore(storeTerm).title !== "Summary" || isLocation) {
      const currentItem = data.find((item) => item.title === title);

      const currentShare = {
        sourceType: currentItem.id.toString(),
        retailer: currentItem.title,
      };

      if (isSingleDate) {
        dispatch({ type: actionShareSingleDay, payload: currentShare });
      } else {
        dispatch({ type: action, payload: currentShare });
      }
    } else {
      return;
    }
  };

  const backgroundChange = (title, change) => {
    let currentStore;

    if (isSingleDate) {
      currentStore = getStore(storeShareSingleDay);
    } else {
      currentStore = getStore(storeShare);
    }

    return Object.entries(currentStore).length === 0
      ? change > 0
        ? "#1ec68d"
        : "#fe6a68"
      : getStore(storeTerm).title === name
      ? currentStore.retailer === title
        ? change > 0
          ? "#1ec68d"
          : "#fe6a68"
        : change > 0
        ? "#74DAB9"
        : "#FBA2A2"
      : change > 0
      ? "#1ec68d"
      : "#fe6a68";
  };

  const backgroundAverage = (title) => {
    let currentStore;

    if (isSingleDate) {
      currentStore = getStore(storeShareSingleDay);
    } else {
      currentStore = getStore(storeShare);
    }

    return Object.entries(currentStore).length === 0
      ? "#a0a2a6"
      : getStore(storeTerm).title === name
      ? currentStore.retailer === title
        ? "#a0a2a6"
        : "#D0D1D3"
      : "#a0a2a6";
  };

  const tooltip = (item) => {
    return (
      <Portal>
        <div className="tooltip" style={{ left: `${coords.x}px`, top: `${coords.y}px` }}>
          <div className="name">{changeNameRetailer(item.title)}</div>
          <div className="description">
            <div>Change</div>
            <div className="change">
              {item.change !== 0 ? (
                <img className="direction" src={item.change > 0 ? green : red} alt="direction" />
              ) : null}
              <div>{Number(item.change)}%</div>
            </div>
          </div>
          <div className="description">
            <div>Average Share</div>
            <div>{Number(item.averageShare)}%</div>
          </div>
        </div>
      </Portal>
    );
  };

  return (
    <div className="total-term-chart-wrapper">
      <div className="total-term-chart-box main-term-box">
        <div className="change-box-wrapper">
          <div className="change-box">
            {data.map((item, index) => (
              <div
                key={index}
                className="change-column"
                ref={wrapperRef}
                onClick={() => setCurrentlyRetailer(item.title, item.change)}
                onMouseMove={(e) => onMouseMove(e, index)}
              >
                <div
                  className="change-item"
                  style={{
                    background: backgroundChange(item.title, item.change),
                    height: `${Math.abs(item.change) / 2}px`,
                    bottom: `${item.change < 0 ? item.change / 2 : 0}px`,
                    borderRadius: item.change > 0 ? "3px 3px 0px 0px" : "0px 0px 3px 3px",
                  }}
                ></div>
                {showTooltip && indexTooltip === index ? tooltip(item) : null}
              </div>
            ))}
          </div>
        </div>
        <div className="averageShare-box-wrapper">
          <div className="averageShare-box">
            {data.map((item, index) => (
              <div
                key={index}
                className="averageShare-column"
                onClick={() => setCurrentlyRetailer(item.title)}
                onMouseMove={(e) => onMouseMove(e, index)}
              >
                <div
                  className="averageShare-item"
                  style={{
                    background: backgroundAverage(item.title),
                    height: `${Number(item.averageShare)}px`,
                  }}
                ></div>
                <img className="img" src={getRemoteLogo(item.title)} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AverageChangeChart;
