import { COLORS } from "assets/colors/colors";
import styled from "styled-components";

export const Styles = styled.div.attrs((props) => props)`
  .filter {
    position: relative;
    height: 44px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    background: ${(props) => props.color};
    padding: 10px 10px 10px 15px;
    font-size: 14px;
    color: #000000;
    border-radius: 4px;
    cursor: pointer;
    font-family: "Gilroy-Medium";
    margin-left: 15px;
  }
  .filter-title {
    color: ${COLORS.white};
    margin-right: 15px;
    text-transform: capitalize;
  }
`;
