import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
  fetchRankingSearchAnalysis,
  setCurrentItemTermSearch,
  setCurrentShareSearch,
  setCurrentShareSearchSingleDay,
  fetchRankingSearchAnalysisProducts,
  fetchRankingSearchAnalysisProductsPopup,
  fetchSearchSingleProduct,
  fetchSearchSingleProductSummary,
  searchSingleProductSetPeriod,
  fetchSearchSingleProductCompare,
  fetchSearchSingleProductCompareSummary,
  searchSingleProductSetCompare,
} from "store/ranking/rankingSearchAnalysis/actions";
import SearchTerm from "tabs/RankingTabs/RankingBottomTabs/components/SearchTerm";
import СurrentlyTrend from "./СurrentlyTrend/СurrentlyTrend";
import СurrentlyProducts from "tabs/RankingTabs/RankingBottomTabs/components/СurrentlyProducts";
import SelectBox from "components/Selects/SelectBox";
import LoaderBox from "components/LoaderBox";
import { getTimePeriodRanking } from "utils/getTimePeriodRanking";
import { STATE_STATUSES } from "utils/statuses";
import useCache from "../../../../hooks/useCache";
import useFilters from "../../../../hooks/useFilters";

import { Cancel } from "assets/icons/icons";

const SearchAnalysisTab = (props) => {
  const {
    status,
    fetchRankingSearchAnalysis,
    setCurrentShareSearch,
    currentShareSearch,
    setCurrentShareSearchSingleDay,
    currentShareSearchSingleDay,
    fetchRankingSearchAnalysisProducts,
    fetchSearchSingleProduct,
    fetchSearchSingleProductSummary,
    searchSingleProductSetPeriod,
    fetchSearchSingleProductCompare,
    fetchSearchSingleProductCompareSummary,
    searchSingleProductSetCompare,
    fetchRankingSearchAnalysisProductsPopup,
    setCurrentItemTermSearch,
    isFeaturedProducts,
    isActive,
  } = props;
  const { mainLastFilter: lastFilter, statusFilters } = useFilters();

  const { cachedRankingSearchAnalysis: rankingSearchAnalysis } = useCache();

  const [currentData, setCurrentData] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [shareSize, setShareSize] = useState(null);
  const [shareSelect, setShareSelect] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [currentManufacturer, setCurrentManufacturer] = useState(null);
  const [currentManufacturerId, setCurrentManufacturerId] = useState(null);
  const { manufacturer: defaultManufacturer } = useSelector(
    (state) => state.settingsYourProfile.settingsYourProfile.company
  );

  useEffect(() => {
    setCurrentShareSearch({});
    setCurrentShareSearchSingleDay({});
    setCurrentManufacturer(null);
  }, [lastFilter]);

  useEffect(() => {
    if (isActive && statusFilters === STATE_STATUSES.READY) {
      fetchRankingSearchAnalysis({
        sourceType: lastFilter.sourceType,
        timePeriod: getTimePeriodRanking(lastFilter.date),
        product: lastFilter.product,
        featured: isFeaturedProducts,
      });
    }
  }, [fetchRankingSearchAnalysis, lastFilter, isFeaturedProducts, isActive, statusFilters]);

  //get current manufacturer
  useEffect(() => {
    if (rankingSearchAnalysis.length) {
      const options = rankingSearchAnalysis
        .map((item) => ({ lable: item.title, value: item.title }))
        .sort((a, b) => a.lable.localeCompare(b.lable));

      let defaultName;
      let defaultId;

      const initialValue =
        currentManufacturer && currentManufacturerId
          ? rankingSearchAnalysis.find((item) => item.title === currentManufacturer)
          : rankingSearchAnalysis.find((item) => item.title === defaultManufacturer[0].name);

      if (initialValue) {
        defaultName = initialValue.title;
        defaultId = initialValue.id;
      } else {
        defaultName = rankingSearchAnalysis[0].title;
        defaultId = rankingSearchAnalysis[0].id;
      }

      const manufacturers = [
        {
          name: "manufacturers",
          default: defaultName,
          options,
        },
      ];

      setManufacturers(manufacturers);
      setCurrentManufacturer(defaultName);
      setCurrentManufacturerId(defaultId);
    }
  }, [rankingSearchAnalysis]);

  const getRankingTop = (shareSize) => {
    const rankingTop = {
      5: "rankingTop5",
      10: "rankingTop10",
      20: "rankingTop20",
    };

    return rankingTop[shareSize];
  };

  //get current data by Search Terms
  useEffect(() => {
    if (rankingSearchAnalysis.length && currentManufacturer) {
      const currentData = rankingSearchAnalysis.find((manufacturer) => manufacturer.title === currentManufacturer);

      const optionsInitial = [
        { lable: "Share of Top 5", value: "5" },
        { lable: "Share of Top 10", value: "10" },
        { lable: "Share of Top 20", value: "20" },
      ];

      const options = [];

      const rankingTop = ["rankingTop5", "rankingTop10", "rankingTop20"];

      rankingTop.forEach((item, index) => {
        if (currentData[item].length) {
          options.push(optionsInitial[index]);
        }
      });

      const selects = [
        {
          name: "shareSize",
          default: options[0].value,
          options,
        },
      ];

      setCurrentData(currentData);
      setShareSelect(selects);
      setShareSize(options[0].value);

      if (currentData[getRankingTop(options[0].value)].length) {
        const activeTab = currentData[getRankingTop(options[0].value)][0].title;
        setActiveTab(activeTab);
      }
    }
  }, [rankingSearchAnalysis, currentManufacturer]);

  useEffect(() => {
    if (activeTab && shareSize) {
      const currentDataItem = currentData[getRankingTop(shareSize)].find((item) => item.title === activeTab);
      if (currentDataItem) {
        setCurrentItemTermSearch(currentDataItem);
      }
    }
  }, [activeTab, shareSize, currentData, setCurrentItemTermSearch]);

  useEffect(() => {
    setCurrentShareSearch({});
    setCurrentShareSearchSingleDay({});
  }, [lastFilter, setCurrentShareSearch, setCurrentShareSearchSingleDay]);

  const handleSetShareSize = (values) => {
    const value = Object.keys(values);
    setShareSize(values[value]);
    setCurrentShareSearch({});
    setCurrentShareSearchSingleDay({});
  };

  const handleSetCurrentManufacturer = (values) => {
    const value = Object.keys(values);
    const id = rankingSearchAnalysis.find((item) => item.title === values[value]).id;

    setCurrentManufacturer(values[value]);
    setCurrentManufacturerId(id);

    setCurrentShareSearch({});
    setCurrentShareSearchSingleDay({});
  };

  const handleActiveTab = (title) => {
    setActiveTab(title);
    setCurrentShareSearch({});
    setCurrentShareSearchSingleDay({});
  };

  const hiddenProducts = () => setCurrentShareSearch({});

  const hiddenProductsSingleDay = () => setCurrentShareSearchSingleDay({});

  return (
    <div>
      <div className="filters-box">
        {shareSelect.length
          ? shareSelect.map((item, index) => (
              <SelectBox
                key={index}
                data={item}
                disabled={status !== STATE_STATUSES.READY}
                setSelectValue={handleSetShareSize}
              />
            ))
          : null}
        {manufacturers.map((item, index) => (
          <SelectBox
            key={index}
            data={item}
            disabled={status !== STATE_STATUSES.READY}
            setSelectValue={handleSetCurrentManufacturer}
          />
        ))}
        {Object.entries(currentShareSearch).length !== 0 ? (
          <div className="current-retailer" onClick={hiddenProducts}>
            <span>{currentShareSearch.retailer}</span>
            <Cancel fill="#fff" />
          </div>
        ) : null}
        {Object.entries(currentShareSearchSingleDay).length !== 0 && currentShareSearchSingleDay.retailer ? (
          <div className="current-retailer" onClick={hiddenProductsSingleDay}>
            <span>{currentShareSearchSingleDay.retailer}</span>
            <Cancel fill="#fff" />
          </div>
        ) : null}
      </div>
      <div className="wrapper-box-relative">
        {status === STATE_STATUSES.READY ? (
          <>
            {rankingSearchAnalysis.length ? (
              <div className="title-hint">
                You are currently viewing
                <span>
                  {" "}
                  {currentData[getRankingTop(shareSize)]?.length
                    ? currentData[getRankingTop(shareSize)]?.length - 1
                    : 0}{" "}
                  Search Terms{" "}
                </span>
                across the selected Retailers.
              </div>
            ) : null}
            <div className="row-charts-wrapper">
              {currentData[getRankingTop(shareSize)]?.length && rankingSearchAnalysis.length ? (
                currentData[getRankingTop(shareSize)]
                  .sort((a, b) => {
                    if ((a.title === "Summary") != (b.title === "Summary")) {
                      return a.title === "Summary" ? -1 : 1;
                    }
                    return a.title > b.title ? 1 : a.title < b.title ? -1 : 0;
                  })
                  .map((item, index) => (
                    <SearchTerm
                      key={index}
                      action={"SET_CURRENT_SHARE_SEARCH"}
                      storeTerm={"currentItemTermSearch"}
                      storeShare={"currentShareSearch"}
                      actionShareSingleDay={"SET_CURRENT_SHARE_SEARCH_SINGLE_DAY"}
                      storeShareSingleDay={"currentShareSearchSingleDay"}
                      param={"share"}
                      data={item}
                      isInactive={item.title !== activeTab}
                      handleActiveTab={handleActiveTab}
                      isLocation={false}
                      isMany={true}
                    />
                  ))
              ) : (
                <div className="chart-no-data static">Looks like we don't have data for this request</div>
              )}
            </div>
            {currentManufacturer &&
            currentManufacturerId &&
            activeTab &&
            Object.entries(currentShareSearch).length === 0 &&
            currentData[getRankingTop(shareSize)]?.length &&
            rankingSearchAnalysis.length ? (
              <СurrentlyTrend
                currentManufacturer={currentManufacturer}
                currentManufacturerId={currentManufacturerId}
                shareSize={shareSize}
                activeTab={activeTab}
                type={"search"}
                actionSingleProduct={fetchSearchSingleProduct}
                storeSingleProduct={"searchSingleProduct"}
                actionSingleProductSummary={fetchSearchSingleProductSummary}
                actionSingleProductSetPeriod={searchSingleProductSetPeriod}
                actionRemoveProduct={"REMOVE_SEARCH_PRODUCT"}
                actionProductCompare={fetchSearchSingleProductCompare}
                storeProductCompare={"searchSingleProductCompare"}
                actionSingleProductCompareSummary={fetchSearchSingleProductCompareSummary}
                actionSingleProductSetCompare={searchSingleProductSetCompare}
                statusProduct={"statusSearch"}
              />
            ) : null}
            {currentManufacturerId && activeTab && Object.entries(currentShareSearch).length !== 0 ? (
              <СurrentlyProducts
                type={"search"}
                searchTerm={activeTab}
                manufacturer={currentManufacturerId}
                topRanking={shareSize}
                currentShare={"currentShareSearch"}
                storeProducts={"rankingSearchAnalysisProducts"}
                actionProducts={fetchRankingSearchAnalysisProducts}
                storeProductPopup={"rankingSearchAnalysisProductsPopup"}
                actionProductPopup={fetchRankingSearchAnalysisProductsPopup}
                actionSingleProduct={fetchSearchSingleProduct}
                storeSingleProduct={"searchSingleProduct"}
                actionRemoveProduct={"REMOVE_SEARCH_PRODUCT"}
                actionProductCompare={fetchSearchSingleProductCompare}
                storeProductCompare={"searchSingleProductCompare"}
                actionShowPopup={"SET_SHOW_PRODUCT_SEARCH_POPUP"}
                showPopup={"showProductSearchPopup"}
                status={"statusSearch"}
              />
            ) : null}
          </>
        ) : null}
        {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    currentShareSearch: state.rankingSearchAnalysis.currentShareSearch,
    currentShareSearchSingleDay: state.rankingSearchAnalysis.currentShareSearchSingleDay,
    status: state.rankingSearchAnalysis.status,
  }),
  {
    fetchRankingSearchAnalysis,
    setCurrentItemTermSearch,
    setCurrentShareSearch,
    setCurrentShareSearchSingleDay,
    fetchRankingSearchAnalysisProducts,
    fetchSearchSingleProduct,
    fetchSearchSingleProductSummary,
    searchSingleProductSetPeriod,
    fetchSearchSingleProductCompare,
    fetchSearchSingleProductCompareSummary,
    searchSingleProductSetCompare,
    fetchRankingSearchAnalysisProductsPopup,
  }
)(SearchAnalysisTab);
