import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useColorTheme from "../../../../hooks/useColorTheme";
import useFilters from "../../../../hooks/useFilters";

//Components
import { BENCHMARK_CONTENT } from "utils/currentContentHelper";
import { Styles } from "./Styles";
import ProductSearch from "tabs/ContentTabs/ContentTopTabs/ContentBenchmark/componetns/ProductSearch";
import BenchmarkTable from "components/BenchmarkTable";
import LoaderBox from "components/LoaderBox";
//Utils
import { getTimePeriod } from "utils/getTimePeriod";
import { STATE_STATUSES } from "utils/statuses";
//Actions
import { fetchBenchmarkContent } from "store/content/benchmarkContent/actions";
import { firstCharToUpperCase } from "utils/generalUtils";
import { changeNameRetailer } from "utils/changeNameRetailer";

const ContentBenchmark = () => {
  const dispatch = useDispatch();
  const { primaryColor } = useColorTheme();
  const { benchmarkContent, status } = useSelector((state) => state.benchmarkContent);
  const { mainLastFilter: lastFilter, statusFilters } = useFilters();

  const [retailersFilter, setRetailersFilter] = useState({
    name: "retailer",
    default: "tesco",
    options: [{ lable: "Tesco", value: "tesco" }],
  });

  const [firstProductFilter, setFirstProductFilter] = useState({});
  const [secondProductFilter, setSecondProductFilter] = useState({});

  const [searchForFirstProd, setSearchForFirstProd] = useState("");
  const [searchForSecondProd, setSearchForSecondProd] = useState("");

  const [firstProduct, setFirstProduct] = useState({});
  const [secondProduct, setSecondProduct] = useState({});

  const [firstProductsArr, setFirstProductsArr] = useState([]);
  const [secondProductsArr, setSecondProductsArr] = useState([]);

  const [firstDropdownVisible, setFirstDropdownVisible] = useState(false);
  const [secondDropdownVisible, setSecondDropdownVisible] = useState(false);

  const [isBenchmark, setIsBenchmark] = useState(false);

  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    setSearchForFirstProd("");
    setIsBenchmark(false);
  }, [firstProductFilter]);

  useEffect(() => {
    setSearchForSecondProd("");
    setIsBenchmark(false);
  }, [secondProductFilter]);

  useEffect(() => {
    setIsBenchmark(false);
  }, [searchForFirstProd, searchForSecondProd]);

  useEffect(() => {
    setQueryParams((prevState) => ({
      ...prevState,
      sourceType: lastFilter.sourceType,
      timePeriod: getTimePeriod(lastFilter.date),
      product: lastFilter.product,
    }));
  }, [lastFilter]);

  useEffect(() => {
    if (Object.keys(queryParams).length && statusFilters === STATE_STATUSES.READY) {
      dispatch(fetchBenchmarkContent(queryParams));
    }
  }, [queryParams, statusFilters, dispatch]);

  useEffect(() => {
    if (benchmarkContent.length) {
      const arrOfRetailersValue = benchmarkContent.map(({ name }) => name);

      const options = arrOfRetailersValue.map((el) => {
        return {
          lable: firstCharToUpperCase(changeNameRetailer(el)),
          value: el,
        };
      });

      const defaultValue = options.length ? options[0].value : null;

      setRetailersFilter((prevState) => ({ ...prevState, options: options, default: defaultValue }));
      setFirstProductFilter({ retailer: options[0].value });
      setSecondProductFilter({ retailer: options[0].value });
    }
  }, [benchmarkContent]);

  useEffect(() => {
    if (Object.entries(firstProductFilter).length && searchForFirstProd.length) {
      const matchedRetailer = benchmarkContent.find((el) => el.name === firstProductFilter.retailer);
      const matchedProducts = matchedRetailer?.products.filter(({ coreTitle }) =>
        coreTitle.toLowerCase().includes(searchForFirstProd.toLowerCase())
      );
      if (matchedProducts.length) {
        setFirstProductsArr(matchedProducts);
      } else {
        setFirstProductsArr(["empty"]);
      }
    } else {
      setFirstProductsArr([]);
    }
  }, [firstProductFilter, searchForFirstProd]);

  useEffect(() => {
    if (Object.entries(secondProductFilter).length && searchForSecondProd.length) {
      const matchedRetailer = benchmarkContent.find((el) => el.name === secondProductFilter.retailer);
      const matchedProducts = matchedRetailer?.products.filter(({ coreTitle }) =>
        coreTitle.toLowerCase().includes(searchForSecondProd.toLowerCase())
      );
      if (matchedProducts.length) {
        setSecondProductsArr(matchedProducts);
      } else {
        setSecondProductsArr(["empty"]);
      }
    } else {
      setSecondProductsArr([]);
    }
  }, [secondProductFilter, searchForSecondProd]);

  return (
    <Styles color={primaryColor}>
      <div className="wrapper-box-relative">
        {benchmarkContent.length ? (
          <div className="chart-wrapper-box">
            <div className="benchmark-wrapper">
              <span>Content comparison of two selected products. Select products to compare their content.</span>

              <div className="row">
                <ProductSearch
                  productFilter={firstProductFilter}
                  setProductFilter={setFirstProductFilter}
                  handleSearch={(value) => setSearchForFirstProd(value)}
                  number={1}
                  searchForProd={searchForFirstProd}
                  retailersFilter={retailersFilter}
                  productArr={firstProductsArr}
                  setProduct={setFirstProduct}
                  product={firstProduct}
                  dropdownVisible={firstDropdownVisible}
                  setDropdownVisible={setFirstDropdownVisible}
                />
                <ProductSearch
                  productFilter={secondProductFilter}
                  setProductFilter={setSecondProductFilter}
                  handleSearch={(value) => setSearchForSecondProd(value)}
                  number={2}
                  searchForProd={searchForSecondProd}
                  retailersFilter={retailersFilter}
                  productArr={secondProductsArr}
                  setProduct={setSecondProduct}
                  product={secondProduct}
                  dropdownVisible={secondDropdownVisible}
                  setDropdownVisible={setSecondDropdownVisible}
                />
                <button type="button" onClick={() => setIsBenchmark(true)}>
                  <span>Benchmark Products</span>
                </button>
              </div>

              {Object.entries(firstProduct).length && Object.entries(secondProduct).length && isBenchmark ? (
                <BenchmarkTable product={secondProduct} coreProduct={firstProduct} type={BENCHMARK_CONTENT} />
              ) : null}
            </div>
          </div>
        ) : null}
        {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
      </div>
    </Styles>
  );
};

export default ContentBenchmark;
