import React from "react";
import { Checkbox } from "antd";

const CheckboxBox = ({ label, value, setValue }) => {
  return (
    <div className="custome-checkbox">
      <Checkbox checked={value} onChange={() => setValue(!value)}>
        {label}
      </Checkbox>
    </div>
  );
};

export default CheckboxBox;
