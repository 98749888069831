const url = process.env.REACT_APP_URL;

export const FETCH_FILTERS = "FETCH_FILTERS";
export const fetchFilters = (data) => ({
  type: FETCH_FILTERS,
  request: {
    url: `${url}/v2/filters`,
    method: "POST",
    data,
  },
  meta: {
    asPromise: true,
  },
});

export const SET_TITLE_DATE = "SET_TITLE_DATE";
export const setTitleDateStore = (title) => {
  localStorage.setItem("TitleDate", title);
  return {
    type: SET_TITLE_DATE,
    payload: title,
  };
};
export const RESET_TITLE_DATE = "RESET_TITLE_DATE";
export const resetTitleDate = () => {
  localStorage.setItem("TitleDate", '');
  return {
    type: RESET_TITLE_DATE,
  };
};

export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const updateFilters = (filters) => {
  return {
    type: UPDATE_FILTERS,
    payload: filters,
  };
};

export const UPDATE_COUNTRY = "UPDATE_COUNTRY";
export const updateCountry = (data) => ({
  type: UPDATE_COUNTRY,
  request: {
    url: `${url}/auth/country`,
    method: "PUT",
    data,
  },
  meta: {
    asPromise: true,
  },
});

export const SET_IS_VALIDATED = "SET_IS_VALIDATED";
export const setIsValidated = (isValidated) => {
  return {
    type: SET_IS_VALIDATED,
    payload: isValidated,
  };
};
