import { success, error } from "redux-saga-requests";
import { FETCH_SHARE_OF_MEDIA_TOP, SET_SHARE_OF_MEDIA_TOP_FILTER, SET_PRISTINE_SHARE_OF_MEDIA_TOP } from "./actions";
import { STATE_STATUSES } from "utils/statuses";
import { RESET_ERROR } from "store/error/actions";

const defaultFilter = [
  {
    name: "byManufacturer",
    value: null,
  },
];

const initialState = {
  shareOfMediaTop: {
    success: false,
    total: {
      bannerCount: 0,
      avgLive: 0,
    },
    days: {},
    manufacturers: [],
  },
  filter: localStorage.getItem(SET_SHARE_OF_MEDIA_TOP_FILTER)
    ? JSON.parse(localStorage.getItem(SET_SHARE_OF_MEDIA_TOP_FILTER))
    : defaultFilter,
  status: STATE_STATUSES.INIT,
  isPristine: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SHARE_OF_MEDIA_TOP: {
      return processReducer(state);
    }
    case success(FETCH_SHARE_OF_MEDIA_TOP): {
      return {
        ...state,
        shareOfMediaTop: { ...action.data, success: true },
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }
    case error(FETCH_SHARE_OF_MEDIA_TOP): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case SET_PRISTINE_SHARE_OF_MEDIA_TOP: {
      return {
        ...state,
        isPristine: action.payload,
      };
    }

    case SET_SHARE_OF_MEDIA_TOP_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
