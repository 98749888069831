import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
//Components
import { Styles } from "./Styles";
import LoaderBox from "components/LoaderBox";
import PromotionsHeader from "../components/PromotionsCalendarHeader";
import PromotionsCalendarFilter from "../components/PromotionsCalendarFilter";
import PromotionsCalendarRetailers from "../components/PromotionsCalendarRetailers";
import PromotionsCalendarChart from "../components/PromotionsCalendarChart";
import useCountry, { NUMBER_TYPE } from "../../../../hooks/useCountry";
import useCache from "../../../../hooks/useCache";
import useFilters from "../../../../hooks/useFilters";

//Actions
import {
  setCalendarFilter,
  fetchPromotionsCalendar,
  addToData,
  addToControlData,
  setIsNew,
} from "store/promotions/promotionsCalendar/actions";
//Utils
import { STATE_STATUSES } from "utils/statuses";
import { getTimePeriod } from "utils/getTimePeriod";

import {
  getPromotions,
  getSplitPromotions,
  PRICE_CUT,
  MULTIBUY,
  OTHER,
  getPromotionsObjForRetailer,
} from "utils/calendarPromotionsHelper";

const PromotionsCalendar = () => {
  //Selectors
  const { status, filter, controlData, controlDataForFilter, isNew } = useSelector(
    (state) => state.promotionsCalendar
  );
  const { cachedPromotionsCalendar: promotionsCalendar } = useCache();
  const { status: statusAuth } = useSelector((state) => state.authorization);
  const { status: statusFilters } = useSelector((state) => state.filters);
  const { formatCurrencyNumber } = useCountry();

  //States
  const [minMaxValues, setMinMaxValue] = useState({
    minValue: 0,
    maxValue: 100,
  });
  const [typeOfDiscount, setTypeOfDiscount] = useState(filter[1].value);
  const queryParamsRef = useRef();
  const fetchRef = useRef();
  const [clickedArray, setClickedArray] = useState([]);
  const [maxMinYValue, setMaxMinYValue] = useState({ min: 0, max: 100 });
  const [sliderValue, onSliderChange] = useState([0, 100]);
  const [marginTop, setMarginTop] = useState(0);
  const [isAllCollapsed, setAllCollapsed] = useState(false);
  const [isRedraw, setIsRedraw] = useState(false);
  const {
    setRefreshStatus,
    refreshStatus,
    mainLastFilter: lastFilter,
  } = useFilters();

  //Const
  const dispatch = useDispatch();
  const isDiscountInPercents = typeOfDiscount === "percent";
  const discount = isDiscountInPercents ? "discountPercent" : "discountValue";
  const minValue = filter[3].value[0];
  const maxValue = filter[3].value[1];
  const isBrand = filter[2].value === "byBrand";
  const key = isDiscountInPercents ? "avgDiscountPercent" : "avgDiscountValue";

  const suffix = isDiscountInPercents ? "%" : "";

  //Collapse all (is promise because chart works very slow)
  const resetClickedArr = async () => {
    new Promise((resolve, reject) => {
      setIsRedraw(false);
      setAllCollapsed(true);
      setClickedArray([]);

      return setTimeout(() => {
        resolve();
        setIsRedraw(true);
      }, 500);
    });
  };
  //set queryParams
  useEffect(() => {
    const mechanic =
      filter[0].value === "Price Cut"
        ? "1"
        : filter[0].value === "Multibuy"
        ? "2"
        : filter[0].value === "Other"
        ? "3"
        : "All";

    const groupBy = filter[2].value === "byRetailer" ? "retailer" : "brand";
    if (statusFilters === STATE_STATUSES.READY && statusAuth === STATE_STATUSES.PENDING && status === STATE_STATUSES.READY) {
      fetchRef.current = false;
    }
    if ((statusFilters === STATE_STATUSES.READY && statusAuth === STATE_STATUSES.READY && !fetchRef.current) || refreshStatus === STATE_STATUSES.PENDING)  {
      fetchRef.current = true;
      setRefreshStatus(STATE_STATUSES.READY);

      resetClickedArr().then(() => {
        queryParamsRef.current = {
          ...queryParamsRef.current,
          sourceType: lastFilter.sourceType,
          timePeriod: getTimePeriod(lastFilter.date),
          product: lastFilter.product,
          mechanic,
          groupBy,
        }
        if (Object.keys(queryParamsRef.current).length) {
          dispatch(fetchPromotionsCalendar(queryParamsRef.current));
        }
      });
    }

  }, [lastFilter, filter, dispatch, status, statusAuth, statusFilters, refreshStatus, setRefreshStatus]);

  const handleTypeOfDiscount = (...args) => {
    fetchRef.current = false;
    setTypeOfDiscount(...args);
  }

  //set values for filters
  const setSelectValue = (values) => {
    fetchRef.current = false;
    const value = Object.keys(values);

    const updateData = filter.map((item) => {
      if (item.name === value[0]) {
        return {
          ...item,
          value: values[value[0]],
        };
      } else if (item.name === value[1]) {
        return {
          ...item,
          value: values[value[1]],
        };
      }

      return item;
    });
    //set filters only when chart was redrawn
    resetClickedArr().then(() => {
      dispatch(setCalendarFilter(updateData));
    });
  };
  //set min max values for discount filter, set average discount and products count
  useEffect(() => {
    if (promotionsCalendar.rows.length) {
      let discountofProducts = [];

      discountofProducts = promotionsCalendar.rows
        .map(({ children }) => {
          return children
            .map(({ children }) => {
              return children
                .map(({ promotions }) => {
                  return promotions.map((el) => +el[discount]).flat();
                })
                .flat();
            })
            .flat();
        })
        .flat();


      const min = discountofProducts.length ? Math.min(...discountofProducts) : 0;
      const max = discountofProducts.length ? Math.max(...discountofProducts) : 0;

      setMinMaxValue({ minValue: min, maxValue: max });
    }
  }, [promotionsCalendar, discount]);

  const handleSliderValue = useCallback((sliderValue) => {
    setMaxMinYValue(sliderValue);
    onSliderChange([sliderValue.min, sliderValue.max]);
  },[setMaxMinYValue, onSliderChange]);

  const handleSetIsNew = (isNewValue) => {
    dispatch(setIsNew(isNewValue));
  }

  //Set dynamic data that controled by slider and control data
  const discountFilterType = controlDataForFilter.lastFilter[1].value === 'percent' ? "discountPercent" : "discountValue";
  const controlDataForFilterRows = controlDataForFilter.rows;

  useEffect(() => {
    if (controlDataForFilterRows && controlDataForFilterRows.length > 0) {
      
      let retailers = [];
      for (let retailerIndx = 0; retailerIndx < controlDataForFilterRows.length; retailerIndx++) {
        let brands = [];
        for (let brandIndx = 0; brandIndx < controlDataForFilterRows[retailerIndx].children.length; brandIndx++) {
          let children = [];
          for (
            let childrenIndex = 0;
            childrenIndex < controlDataForFilterRows[retailerIndx].children[brandIndx].children.length;
            childrenIndex++
          ) {
            let promotionsArr = [];
            for (
              let promotionsIndx = 0;
              promotionsIndx <
              controlDataForFilterRows[retailerIndx].children[brandIndx].children[childrenIndex].promotions.length;
              promotionsIndx++
            ) {
              const promotion =
                controlDataForFilterRows[retailerIndx].children[brandIndx].children[childrenIndex].promotions[
                  promotionsIndx
                ][discountFilterType];
              //filter every promotion in every children by duscount filter
              if (promotion >= minValue && promotion <= maxValue) {
                promotionsArr.push(
                  controlDataForFilterRows[retailerIndx].children[brandIndx].children[childrenIndex].promotions[
                    promotionsIndx
                  ]
                );
              }
            }
            if (promotionsArr.length) {
              children.push({
                ...controlDataForFilterRows[retailerIndx].children[brandIndx].children[childrenIndex],
                promotions: promotionsArr,
              });
            }
          }
          if (children.length) {
            //when children deleted or added need to change promotion obj
            const retailerPromotions = [
              getPromotions(children, PRICE_CUT),
              getPromotions(children, MULTIBUY),
              getPromotions(children, OTHER),
            ].filter((el) => Object.keys(el.period).length > 0 && el[discountFilterType] >= minValue && el[discountFilterType] <= maxValue);

            const promotions = [
              ...getSplitPromotions(children, PRICE_CUT),
              ...getSplitPromotions(children, MULTIBUY),
              ...getSplitPromotions(children, OTHER),
            ].filter((el) => Object.keys(el.period).length > 0 && el[discountFilterType] >= minValue && el[discountFilterType] <= maxValue);

            brands.push({
              ...controlDataForFilterRows[retailerIndx].children[brandIndx],
              children,
              promotions,
              retailerPromotions,
            });
          }
        }
        if (brands.length) {
          const newPeriod = getPromotionsObjForRetailer(brands);

          retailers.push({
            ...controlDataForFilterRows[retailerIndx],
            children: brands,
            period: newPeriod,
          });
        }
      }

      dispatch(addToControlData(retailers));
      dispatch(addToData(retailers));
    }
  }, [controlDataForFilterRows, discountFilterType, maxValue, minValue, dispatch]);

  const onOpen = (id, children, label, isBrands, expand) => {
    setIsRedraw(false);

    let mapedChildren;

    if (!isBrands) {
      mapedChildren = children.map((el) => {
        const promotions = el.promotions.map((item) => {
          return {
            mechanic: item.label,
            period: Object.entries(item.period),
            discount: isDiscountInPercents ? item.discountPercent : item.discountValue,
          };
        });

        return {
          id: el.id,
          name: el.label,
          promotions,
        };
      });
    } else {
      mapedChildren = children.map((el) => {
        const promotions = el.promotions.map((item) => {
          return {
            mechanic: item.mechanic,
            label: item.label,
            period: Object.entries(item.period),
            promotedPrice: item.promotedPrice,
            discount: isDiscountInPercents ? item.discountPercent : item.discountValue,
          };
        });
        const product = el.product.productTitle;

        return {
          id: `${id}_${el.product.id}`,
          name: el.product.promotionDescription,
          promotions,
          product,
        };
      });
    }

    let arr = [];
    if (isIncluded(id)) {
      const matchedEl = clickedArray.find((item) => item.id === id);
      const filteredElements = matchedEl.children.map((el) => clickedArray.filter((item) => item.id === el.id)).flat();

      if (filteredElements.length) {
        arr = clickedArray.filter((el) => !filteredElements.some((item) => item.id === el.id) && el.id !== id);
      } else {
        arr = clickedArray.filter((item) => item.id !== id);
      }
    } else {
      arr = [...clickedArray, { id, children: mapedChildren, label }];
    }
    if (expand) {
      setClickedArray((prevState) => [...prevState, ...arr]);
    } else {
      setClickedArray(arr);
    }

    setAllCollapsed(false);
  };

  const isIncluded = (id) => {
    if (clickedArray.some((el) => el.id === id)) {
      return true;
    }
  };

  const expandAll = () => {
    setIsRedraw(false);
    for (let index = 0; index < promotionsCalendar.rows.length; index++) {
      const retailer = promotionsCalendar.rows[index];
      const { id, children, label } = retailer;
      const expand = true;
      onOpen(id, children, label, false, expand);

      for (let i = 0; i < retailer.children.length; i++) {
        const childrens = retailer.children[i];
        const { id, children, label } = childrens;
        onOpen(id, children, label, true, expand);
      }
    }
  };

  const collapseHandle = () => {
    if (clickedArray.length) {
      resetClickedArr(true);
    } else {
      expandAll();
    }
  };

  return (
    <Styles>
      <>
        {promotionsCalendar ? (
          <>
            <div className="main-box-header">
              <PromotionsHeader
                avgDiscount={formatCurrencyNumber(controlData[key], isDiscountInPercents ? NUMBER_TYPE.DECIMAL : NUMBER_TYPE.CURRENCY)}
                productsCount={controlData.productsCount}
                suffix={suffix}
                header={"Calendar"}
              />
  
              <PromotionsCalendarFilter
                setSelectValue={setSelectValue}
                minMaxValues={minMaxValues}
                typeOfDiscount={typeOfDiscount}
                setTypeOfDiscount={handleTypeOfDiscount}
                filter={filter}
                disabled={status !== STATE_STATUSES.READY}
              />
            </div>
            <div className={"calendar-wrapper"}>                   
              <PromotionsCalendarRetailers
                data={promotionsCalendar?.rows}
                isDiscountInPercents={isDiscountInPercents}
                isBrand={isBrand}
                clickedArray={clickedArray}
                setClickedArray={setClickedArray}
                marginTop={marginTop}
                isAllCollapsed={isAllCollapsed}
                onOpen={onOpen}
                collapseHandle={collapseHandle}
                isEmpty={promotionsCalendar.rows.length===0}
              />
              <PromotionsCalendarChart
                data={promotionsCalendar}
                clickedArray={clickedArray}
                setClickedArray={setClickedArray}
                currentChart={filter}
                controlData={controlData}
                status={status}
                maxMinYValue={maxMinYValue}
                setMaxMinYValue={handleSliderValue}
                sliderValue={sliderValue}
                onSliderChange={onSliderChange}
                setMarginTop={setMarginTop}
                setIsRedraw={setIsRedraw}
                onOpen={onOpen}
                isEmpty={promotionsCalendar.rows.length===0}
                isNew={isNew}
                setIsNew={handleSetIsNew}
              />
            </div>
          </>
        ) : null}
        {status === STATE_STATUSES.PENDING || !isRedraw ? <LoaderBox /> : null}
      </>
    </Styles>
  );
};

export default PromotionsCalendar;
