import { COLORS } from "assets/colors/colors";
import styled from "styled-components";

export const Styles = styled.div.attrs((props) => props)`
  .wrapper-compare {
    position: relative;
  }
  .compare-box {
    width: 180px;
    position: relative;
    height: 44px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    background: #eef2f7;
    padding: 10px 10px 10px 15px;
    font-size: 14px;
    color: #000000;
    border-radius: 4px;
    cursor: pointer;
    font-family: "Gilroy-Medium";
    margin-left: 15px;
  }
  .radioBox {
    width: 280px;
    border-radius: 2px;
    box-shadow: 0 30px 50px 0 rgba(29, 30, 78, 0.1),
      0 0 6px 0 rgba(27, 28, 51, 0.08);
    background-color: #ffffff;
    position: absolute;
    padding: 16px;
    padding-bottom: 10px;
    top: 50px;
    right: 0;
    z-index: 9999;
  }
  .ant-radio-group {
    display: flex;
    flex-direction: column;
    .ant-radio-wrapper {
      margin-bottom: 20px;
    }
  }
  .hint {
    color: #abaaba;
    font-size: 12px;
    width: 164px;
    display: block;
    margin-bottom: 15px;
    font-weight: bold;
    line-height: 18px;
  }

  .compare-title {
    margin-right: 25px;
    min-width: 70px;
  }

  .slider-values {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 6px;
  }
  .values-container {
    width: 100px;
    height: 44px;
    border-radius: 2px;
    background-color: ${COLORS.lightGray};
    border: none;
    text-align: center;
    :focus {
      outline: none;
    }
  }
  .dash {
    color: ${COLORS.darkGray};
    font-size: 12px;
  }
  .btn-wrapper {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .aplly-btn {
    border-radius: 4px;
    background-color: ${(props) => props.color};
    color: #fff;
    font-weight: bold;
    border: none;
    padding: 8px 16px;
    height: auto;
  }
  .ant-btn[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
  }
  .select {
    font-size: 16px;
    color: ${(props) => props.color};
    font-family: "Gilroy-ExtraBold";
    border: none;
  }
`;
