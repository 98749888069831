import React from "react";

import { Styles } from "./Styles";

import { renderScoreImg } from "utils/currentContentHelper";
import { getImage } from "utils/getImage";
import useRetailers from "../../hooks/useRetailers";

import { CURRENT_CONTENT, popupSpecifications } from "utils/currentContentHelper";

const staticTexts = [
  { name: "Product Title", key: "title" },
  { name: "Primary Images", key: "image" },
  { name: "Secondary Images", key: "secondaryImages" },
  { name: "Product Description", key: "description" },
  { name: "Size", key: "size" },
  { name: "Specification", key: "specifications" },
  { name: "Features", key: "features" },
  { name: "Ingredients", key: "ingredients" },
];
const benchmarkTexts = [
  { name: "Product Title", key: "productTitle" },
  { name: "Primary Images", key: "productImage" },
  { name: "Secondary Images", key: "secondaryImages" },
  { name: "Product Description", key: "description" },
  { name: "Product Features", key: "features" },
  { name: "Size", key: "size" },
  { name: "Specifications", key: "specification" },
  { name: "Nutritional", key: "info" },
];

const renderProductInfo = (data, key, product) => {
  if (key === "title") {
    return (
      <div className="name">
        <span>{product.detailedTitle}</span>
        <span></span>
      </div>
    );
  } else if (key === "image") {
    if (data.value.startsWith("https")) {
      return (
        <div className="img-wrapper">
          <img src={data.value} alt="" />
        </div>
      );
    } else {
      return (
        <div className="img-wrapper">
          <span>No Image</span>
        </div>
      );
    }
  } else if (key === "secondaryImages") {
    return (
      <div className="img-wrapper">
        <img src={getImage(renderScoreImg(data.score))} alt="" />
      </div>
    );
  } else if (key === "size") {
    return <span>Size: {Number(data.value) !== 0 ? `${data.value}g` : "-"}</span>;
  } else if (key === "specifications") {
    const texts = data?.value?.length ? JSON.parse(data.value) : [];
    return (
      <div className="nutritional-wrapper">
        <div className="nutritional-content header">
          <span>Typical Values</span>
          <span>per 100 g</span>
        </div>
        {texts?.length
          ? texts.map(({ key, value }, index) => {
              return (
                <div className="nutritional-content" key={index}>
                  <span>{key.length ? key : " "}</span>
                  <span>{value}</span>
                </div>
              );
            })
          : null}
      </div>
    );
  } else {
    return <span>{data.value.replace(/<[^>]*>?/gm, "")}</span>;
  }
};

const renderCoreProductInfo = (data, key) => {
  if (data === null) {
    return <p>Missing {key.replace(/([a-z])([A-Z])/g, "$1 $2").toLowerCase()} data</p>;
  }
  if (key === "title") {
    return (
      <div className="name">
        <span>{data}</span>
        <span></span>
      </div>
    );
  } else if (key === "image") {
    if (data.startsWith("https")) {
      return (
        <div className="img-wrapper">
          <img src={data} alt="" />
        </div>
      );
    } else {
      return (
        <div className="img-wrapper">
          <span>No Image</span>
        </div>
      );
    }
  } else if (key === "secondaryImages") {
    return null;
  } else if (key === "size") {
    return <span>Size: {Number(data) !== 0 ? `${data}g` : "-"}</span>;
  } else if (key === "specifications") {
    const texts = data.length ? JSON.parse(data) : [];

    return (
      <div className="nutritional-wrapper">
        <div className="nutritional-content header">
          <span>Typical Values</span>
          <span>per 100 g</span>
        </div>
        {texts?.length
          ? texts.map(({ key, value }, index) => {
              return (
                <div className="nutritional-content" key={index}>
                  <span>{key.length ? key : " "}</span>
                  <span>{value}</span>
                </div>
              );
            })
          : null}
      </div>
    );
  } else {
    return <span>{data.replace(/<[^>]*>?/gm, "")}</span>;
  }
};

const renderBencmarkContent = (data, key, product) => {
  if (data === null) {
    return <p>Missing {key.replace(/([a-z])([A-Z])/g, "$1 $2").toLowerCase()} data</p>;
  }
  if (key === "productTitle") {
    return (
      <div className="name">
        <span>{product.detailTitle}</span>
        <span></span>
      </div>
    );
  } else if (key === "productImage") {
    if (data.startsWith("https")) {
      return (
        <div className="img-wrapper">
          <img src={data} alt="" />
        </div>
      );
    } else {
      return (
        <div className="img-wrapper">
          <span>No Image</span>
        </div>
      );
    }
  } else if (key === "secondaryImages") {
    return (
      <div className="img-wrapper">
        <img src={getImage(data === true ? "good" : "bad")} alt="" />
      </div>
    );
  } else if (key === "size") {
    return <span>Size: {Number(data) !== 0 ? `${data}` : "-"}</span>;
  } else if (key === "specification") {
    const texts = data.length ? JSON.parse(data) : [];
    return (
      <div className="nutritional-wrapper">
        <div className="nutritional-content header">
          <span>Typical Values</span>
          <span>per 100 g</span>
        </div>
        {texts?.length
          ? texts.map(({ key, value }, index) => {
              return (
                <div className="nutritional-content" key={index}>
                  <span>{key.length ? key : " "}</span>
                  <span>{value}</span>
                </div>
              );
            })
          : null}
      </div>
    );
  } else {
    return <span>{data.replace(/<[^>]*>?/gm, "")}</span>;
  }
};

const BenchmarkTable = ({ product, coreProduct, type }) => {
  const { getRemoteLogo } = useRetailers();

  const texts = type === CURRENT_CONTENT ? staticTexts : benchmarkTexts;
  return (
    <Styles>
      {type === CURRENT_CONTENT ? (
        <div className="top-block">
          <div className="static"></div>
          <div className="product">
            <div className="img-wrapper">
              <img src={getImage(coreProduct.retailer)} alt="" />
            </div>
          </div>
          <div className="product">
            <div className="img-wrapper top-block">
              <img src={getRemoteLogo(product?.retailer)} alt="" />
            </div>
          </div>
        </div>
      ) : null}
      <div className="table-wrapper" style={{ marginTop: type === CURRENT_CONTENT ? 0 : null }}>
        {texts.map(({ name, key }, index) => {
          const number = (+product[key].score).toFixed(1);

          return (
            <div className="row-table-benchmark" key={index}>
              <div className="static">
                {name}{" "}
                {type === CURRENT_CONTENT && popupSpecifications.includes(key) ? (
                  <div>
                    <span>{number}%</span>
                    <img src={getImage(renderScoreImg(product[key].score, key))} alt="" />
                  </div>
                ) : null}
              </div>
              <div className="product">
                {type === CURRENT_CONTENT
                  ? renderCoreProductInfo(coreProduct[key], key)
                  : renderBencmarkContent(coreProduct[key], key, coreProduct)}
              </div>
              <div className="product">
                {type === CURRENT_CONTENT
                  ? renderProductInfo(product[key], key, product)
                  : renderBencmarkContent(product[key], key, product)}
              </div>
            </div>
          );
        })}
      </div>
    </Styles>
  );
};

export default BenchmarkTable;
