export const RETAILER = "retailer";
export const BRAND = "brand";
export const PRODUCT = "product";
export const OPPORTUNITES = "opportunites";
export const CURRENT_CONTENT = "currentContent";
export const BENCHMARK_CONTENT = "benchmarkContent";

export const specifications = [
  { value: "Retailer", key: "retailer" },
  { value: "Total", key: "totalScore" },
  { value: "Product Title", key: "titlesScore" },
  { value: "Primary Image", key: "imagesScore" },
  { value: "Secondary Images", key: "secondaryImagesScore" },
];

export const popupSpecifications = [
  "title",
  "image",
];

export const renderScoreImg = (score, key) => {
  if (score >= 85 && key?.includes("titles")) {
    return "good";
  } else if (score === 100 && key?.includes("secondary")) {
    return "good";
  } else if (score !== 100 && key?.includes("secondary")) {
    return "bad";
  } else if (score >= 95) {
    return "good";
  } else if (score <= 49) {
    return "bad";
  } else {
    return "attention";
  }
};

export const tableQuery = {
  productName: "",
  productNameOrder: "asc",
  sourceTypeSort: "",
  brand: "",
  opportunity: "",
  totalScoreOrder: "",
  titleScoreOrder: "",
  imageScoreOrder: "",
  descriptionScoreOrder: "",
  secondaryImagesScoreOrder: "",
  sizeScoreOrder: "",
  specificationScoreOrder: "",
};
