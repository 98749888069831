import React, { useEffect, useState } from "react";
import { Empty } from "antd";

import AssortmentSummaryRetailersItem from "./AssortmentSummaryRetailersItem";

import searchIcon from "assets/images/search.svg";
import sort from "assets/images/sort.svg";
import sortIconDown from "assets/images/sort_down.svg";
import sortIconUp from "assets/images/sort_up.svg";

const AssortmentSummaryRetailers = ({
  data,
  clickedArray,
  onOpen,
  handleSearch,
  search,
  handleSort,
  sortDirection,

  isEmpty,
  setEmptyData,
  arrayOfDesiredBrand,
}) => {
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (arrayOfDesiredBrand.length) {
      const newArr = data.filter(({ item }) => arrayOfDesiredBrand.some((name) => item.name === name));
      setFilteredData(newArr);
    } else {
      setFilteredData(data);
    }
  }, [arrayOfDesiredBrand, data]);

  useEffect(() => {
    if (filteredData.length) {
      setEmptyData(false);
    } else {
      setEmptyData(true);
    }
  }, [filteredData]);

  return (
    <div className="top-retailers">
      <div className="collapse-all">
        <div className="search">
          <img src={searchIcon} alt="search" />
          <input
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Search for a Brand or a Product"
            value={search}
          />

          <img
            src={sortDirection ? sortIconUp : sortIconDown}
            alt="sort"
            className="search-sort-btn"
            onClick={() => handleSort()}
          />
        </div>
      </div>
      {isEmpty ? (
        <div className="empty-wrapper">
          <div className="no-data-box">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        </div>
      ) : (
        <div className="retailers-media">
          {filteredData.map((el, index) => {
            return (
              <div key={index}>
                <AssortmentSummaryRetailersItem data={el} clickedArray={clickedArray} onOpen={onOpen} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AssortmentSummaryRetailers;
