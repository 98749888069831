import React, { useEffect, useState } from "react";
import { Pagination } from "antd";
import { useDispatch, useSelector } from "react-redux";
//Components
import LoaderBox from "components/LoaderBox";
import SelectBox from "components/Selects/SelectBox";
import KeyContentTable from "./components/KeyContentTable";
//Actions
import { fetchKeyContent, setKeyContentFilter } from "store/content/keyContent/actions";
//Utils

import { getTimePeriod } from "utils/getTimePeriod";
import { STATE_STATUSES } from "utils/statuses";
import useFilters from "../../../../hooks/useFilters";

const KeyContent = () => {
  const dispatch = useDispatch();
  const { userManufacturer } = useSelector((state) => state.authorization);
  const { mainLastFilter: lastFilter, statusFilters } = useFilters();

  const { keyContent, status, keyFilter } = useSelector((state) => state.keyContent);
  const { filters } = useSelector((state) => state.filters);

  const defaultFilterState = [
    {
      name: "productTitles",
      options: [
        { lable: "Product Titles", value: "title" },
        { lable: "Product Images", value: "image" },
      ],
    },
  ];
  const selectedManufacture = keyFilter[1].value;

  const [queryParams, setQueryParams] = useState({});

  const [sortId, setSortId] = useState("productNameOrder");
  const [sortDirection, setSortDirection] = useState(true);
  const [search, setSeacrh] = useState("");
  const [productNameOrder, setProductNameOrder] = useState("asc");
  const [retailerOrder, setRetailerOrder] = useState("");

  useEffect(() => {
    if (filters.manufacture.length) {
      const userManufacture = filters.manufacture.find(({ id }) => id === userManufacturer);

      if (userManufacture !== keyFilter[1].value?.id || keyFilter[1].value === null) {
        setSelectValue({
          [keyFilter[0].name]: defaultFilterState[0].options[0].value,
          [keyFilter[1].name]: userManufacture,
        });
      }
    }
  }, [filters, userManufacturer]);

  useEffect(() => {
    setSeacrh("");
  }, [keyFilter[1].value]);

  useEffect(() => {
    if (Object.keys(queryParams).length && statusFilters === STATE_STATUSES.READY) {
      dispatch(fetchKeyContent(queryParams));
    }
  }, [queryParams, statusFilters, dispatch]);

  useEffect(() => {
    if (keyFilter[1].value) {
      setQueryParams((prevState) => ({
        ...prevState,
        sourceType: lastFilter.sourceType,
        timePeriod: getTimePeriod(lastFilter.date),
        product: lastFilter.product,
        productName: search,
        contentType: keyFilter[0].value,
        manufacturer: keyFilter[1].value?.id,
        productNameOrder: productNameOrder,
        retailerOrder: retailerOrder,
        page: 1,
        limit: 10,
      }));
    }
  }, [lastFilter, keyFilter, search, retailerOrder, productNameOrder, filters]);

  const handleSearch = (value) => {
    setSeacrh(value);
  };

  const handleSort = (id) => {
    let sourceTypeSort;

    setSortDirection(!sortDirection);
    sourceTypeSort = `${id}|${!sortDirection ? "asc" : "desc"}`;

    setSortId(id);
    let key, value, lastKey;
    if (id === "productNameOrder") {
      key = "productNameOrder";
      value = sourceTypeSort.split("|")[1];
      lastKey = "retailerOrder";
      setProductNameOrder(value);
      setRetailerOrder("");
    } else {
      key = "retailerOrder";
      value = sourceTypeSort;
      lastKey = "productNameOrder";
      setRetailerOrder(value);
      setProductNameOrder("");
    }
  };

  const onChangePage = (page) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: page,
    }));
  };

  const setSelectValue = (values) => {
    const value = Object.keys(values);

    const updateData = keyFilter.map((item) => {
      if (item.name === value[0]) {
        return {
          ...item,
          value: values[value[0]],
        };
      } else if (item.name === value[1]) {
        return {
          ...item,
          value: values[value[1]],
        };
      }

      return item;
    });
    dispatch(setKeyContentFilter(updateData));
  };

  return (
    <div className="key-wrapper">
      <div className="filters-box">
        {defaultFilterState.map((item, index) => {
          return (
            <SelectBox
              filter={keyFilter[index].value}
              data={item}
              key={index}
              setSelectValue={setSelectValue}
              disabled={status !== STATE_STATUSES.READY}
              // alignRight={index === 1 && item.options.length ? true : false}
            />
          );
        })}
        {selectedManufacture?.name ? <div className="selected-manufacture">{selectedManufacture.name}</div> : null}
      </div>

      <div className="wrapper-box-relative">
        {keyContent.success ? (
          <div className="chart-wrapper-box">
            <KeyContentTable
              data={keyContent}
              timePeriod={queryParams.timePeriod}
              sortId={sortId}
              sortDirection={sortDirection}
              handleSearch={handleSearch}
              searchState={search}
              handleSort={handleSort}
              keyFilter={keyFilter}
              status={status}
            />
          </div>
        ) : null}
        {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
      </div>

      {keyContent.total > queryParams.limit ? (
        <Pagination
          className="pagination-controls"
          onChange={onChangePage}
          current={queryParams.page ? queryParams.page : 1}
          pageSize={queryParams.limit ? queryParams.limit : 10}
          total={keyContent.total}
          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
        />
      ) : null}
    </div>
  );
};

export default KeyContent;
