import { success, error } from "redux-saga-requests";
import { 
  FETCH_TREND_ANALYSIS_CHART, 
  FETCH_TREND_ANALYSIS_CHART_ALL,
  FETCH_TREND_ANALYSIS_MOVERS,
  FETCH_TREND_ANALYSIS_MOVERS_ALL,
  SET_TREND_ANALYSIS_FILTER, 
  SET_PRISTINE_TREND_ANALYSIS_CHART 
} from "./actions";
import { STATE_STATUSES } from "utils/statuses";
import { RESET_ERROR } from "store/error/actions";

const defaultFilter = [
  { name: "byReviews", value: "rating" },
  { name: "byTotal", value: "total" },
  { name: "showAll", value: false },
];

const initialState = {
  trendAnalysis: {
    data: {},
    success: false,
    status: STATE_STATUSES.INIT,
    error: null,
  },
  trendAnalysisAll: {
    data: {},
    success: false,
    status: STATE_STATUSES.INIT,
    error: null,
  },
  trendAnalysisMovers: {
    data: {},
    success: false,
    status: STATE_STATUSES.INIT,
    error: null,
  },
  trendAnalysisMoversAll: {
    data: {},
    success: false,
    status: STATE_STATUSES.INIT,
    error: null,
  },
  filter: localStorage.getItem(SET_TREND_ANALYSIS_FILTER)
  ? JSON.parse(localStorage.getItem(SET_TREND_ANALYSIS_FILTER))
  : defaultFilter,
  isPristine: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TREND_ANALYSIS_CHART: {
      return processReducerChart(state);
    }
    case FETCH_TREND_ANALYSIS_CHART_ALL: {
      return processReducerChartAll(state);
    }
    case FETCH_TREND_ANALYSIS_MOVERS: {
      return processReducerMovers(state);
    }
    case FETCH_TREND_ANALYSIS_MOVERS_ALL: {
      return processReducerMoversAll(state);
    }
    case success(FETCH_TREND_ANALYSIS_CHART): {
      return {
        ...state,
        trendAnalysis: {
          ...state.trendAnalysis,
          data: action.data,
          success: true,
          status: STATE_STATUSES.READY,
        },
      };
    }
    case success(FETCH_TREND_ANALYSIS_CHART_ALL): {
      return {
        ...state,
        trendAnalysisAll: {
          ...state.trendAnalysisAll,
          data: action.data,
          success: true,
          status: STATE_STATUSES.READY,
        },
      };
    }
    case success(FETCH_TREND_ANALYSIS_MOVERS): {
      return {
        ...state,
        trendAnalysisMovers: {
          ...state.trendAnalysisMovers,
          data: action.data,
          success: true,
          status: STATE_STATUSES.READY,
        },
      };
    }
    case success(FETCH_TREND_ANALYSIS_MOVERS_ALL): {
      return {
        ...state,
        trendAnalysisMoversAll: {
          ...state.trendAnalysisMoversAll,
          data: action.data,
          success: true,
          status: STATE_STATUSES.READY,
        },
      };
    }

    case error(FETCH_TREND_ANALYSIS_CHART): {
      const error = action.error.response;
      return errorReducerChart(state, error);
    }
    case error(FETCH_TREND_ANALYSIS_CHART_ALL): {
      const error = action.error.response;
      return errorReducerChartAll(state, error);
    }
    case error(FETCH_TREND_ANALYSIS_MOVERS): {
      const error = action.error.response;
      return errorReducerMovers(state, error);
    }
    case error(FETCH_TREND_ANALYSIS_MOVERS_ALL): {
      const error = action.error.response;
      return errorReducerMoversAll(state, error);
    }

    case SET_PRISTINE_TREND_ANALYSIS_CHART: {
      return {
        ...state,
        isPristine: action.payload,
      };
    }
    case SET_TREND_ANALYSIS_FILTER:
    const newFilter = action.payload;
    console.log("newFilter", newFilter)
    localStorage.setItem(SET_TREND_ANALYSIS_FILTER, JSON.stringify(newFilter));
    return {
      ...state,
      filter: newFilter,
    };

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const processReducerChart = (state) => ({
  ...state,
  trendAnalysis: {
    ...state.trendAnalysis,
    status: STATE_STATUSES.PENDING,
  },
  error: null,
});

const errorReducerChart = (state, error) => ({
  ...state,
  trendAnalysis: {
    ...state.trendAnalysis,
    status: STATE_STATUSES.ERROR,
  },
  isPristine: false,
  error,
});

const processReducerChartAll = (state) => ({
  ...state,
  trendAnalysisAll: {
    ...state.trendAnalysisAll,
    status: STATE_STATUSES.PENDING,
  },
  error: null,
});

const errorReducerChartAll = (state, error) => ({
  ...state,
  trendAnalysisAll: {
    ...state.trendAnalysisAll,
    status: STATE_STATUSES.ERROR,
  },
  isPristine: false,
  error,
});

const processReducerMovers = (state) => ({
  ...state,
  trendAnalysisMovers: {
    ...state.trendAnalysisMovers,
    status: STATE_STATUSES.PENDING,
  },
  error: null,
});

const errorReducerMovers = (state, error) => ({
  ...state,
  trendAnalysisMovers: {
    ...state.trendAnalysisMovers,
    status: STATE_STATUSES.ERROR,
  },
  isPristine: false,
  error,
});

const processReducerMoversAll = (state) => ({
  ...state,
  trendAnalysisMoversAll: {
    ...state.trendAnalysisMoversAll,
    status: STATE_STATUSES.PENDING,
  },
  error: null,
});

const errorReducerMoversAll = (state, error) => ({
  ...state,
  trendAnalysisMoversAll: {
    ...state.trendAnalysisMoversAll,
    status: STATE_STATUSES.ERROR,
  },
  isPristine: false,
  error,
});
