const url = process.env.REACT_APP_URL;

export const FETCH_PROMOTIONS_CALENDAR = "FETCH_PROMOTIONS_CALENDAR";
export const fetchPromotionsCalendar = (params) => ({
  type: FETCH_PROMOTIONS_CALENDAR,
  request: {
    url: `${url}/v2/promotion/calendar`,
    method: "POST",
    data: params,
  },
});

export const SET_CALENDAR_FILTER = "SET_CALENDAR_FILTER";
export const setCalendarFilter = (filter) => {
  localStorage.setItem(SET_CALENDAR_FILTER, JSON.stringify(filter));
  return {
    type: SET_CALENDAR_FILTER,
    payload: filter,
  };
};

export const ADD_TO_DATA = "ADD_TO_DATA";
export const addToData = (rows) => {
  return {
    type: ADD_TO_DATA,
    payload: rows,
  };
};

export const ADD_TO_CONTROL_DATA = "ADD_TO_CONTROL_DATA";
export const addToControlData = (rows) => {
  return {
    type: ADD_TO_CONTROL_DATA,
    payload: rows,
  };
};

export const SET_IS_NEW = "SET_IS_NEW";
export const setIsNew = (isNew) => {
  return {
    type: SET_IS_NEW,
    payload: isNew,
  };
};

export const SET_PRISTINE_PROMOTIONS_CALENDAR = "SET_PRISTINE_PROMOTIONS_CALENDAR";
export const setPristinePromotionsCalendar = (pristine) => {
  return {
    type: SET_PRISTINE_PROMOTIONS_CALENDAR,
    payload: pristine,
  };
};
