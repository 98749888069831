import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import moment from 'moment'
import { Form, Input, Button, Alert, Modal } from "antd";
import { requesOTP, resendOTP, setLoginStatus, setMfaStatus } from "store/authorization/actions";
import { setIsColorThemeFadeIn } from "../store/settings/settingsYourProfile/actions";
import { STATE_STATUSES } from "utils/statuses";
import logo from "../assets/images/general-logo-white.svg";
import styled from "styled-components";
import useColorTheme from  "../hooks/useColorTheme";

const CountdownTimer = ({expiresIn, tokenStart}) => {
  const timer = useRef(null);
  const [min, setMin] = useState(Math.floor(expiresIn / 60));
  const [sec, setSec] = useState(expiresIn - min * 60);
  const [timeLeft, setTimeLeft] = useState(expiresIn);

  const formatTime = (t) => t < 10 ? '0' + t : t;

  useEffect(() => {
    const startTime = moment(tokenStart);
    const now = moment();
    const expire = startTime.add(expiresIn, 'seconds')
    setTimeLeft(expire.diff(now, 'seconds'))
  }, [tokenStart, expiresIn]);

  useEffect(() => {
      const interval = setInterval(() => {
          const m = Math.floor(timeLeft / 60);
          const s = timeLeft - m * 60;

          setMin(m);
          setSec(s);
          if (m <= 0 && s <= 0) return () => clearInterval(interval);

          setTimeLeft((t) => t - 1);
        }, 1000);

        return () => clearInterval(interval);
  }, [timeLeft]);

  useEffect(()=>{
    if (timeLeft === 0) {
      timer.current = setTimeout(() => {
        localStorage.clear();
        window.location.reload();
      }, 1000);
    }
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  },[timeLeft])

  return (timeLeft > 0) ? (
    <> 
      <p>A One-Time Passcode has been sent to you. <br />Please enter the code below. This code will expire in <span style={{ fontWeight: "900" }}>{formatTime(min)}:{formatTime(sec)}</span>.</p>
    </>
  ) : (
    <>
        <span>Passcode expired. Please login again.</span>
    </>
  );
}

const Popup = styled.div`
  width: 100vw;
  height: 100vh;    
  

  .authorization-wrapper {
    transition: opacity 500ms ease-in-out, background-color 1s linear;
    ${({ primaryColor}) => `background-color: ${primaryColor};`}
    ${({ loginStatus }) => loginStatus === STATE_STATUSES.PENDING ? `opacity: 0.9;`: `opacity: 1;`}
  }

  .ant-btn.ant-btn-primary {
    transition: background-color 1s linear;
    ${({ primaryColor}) => `background: ${primaryColor}; background-color: ${primaryColor};`}
  }
`;
//opacity 2s ease-in-out,
//  opacity: ${({ showPopup }) => (showPopup ? '1' : '0')};
const Otp = ({ status, colorStatus, lastColor, loginStatus, logoutStatus, mfaStatus, requesOTP, resendOTP, setLoginStatus, setMfaStatus, setIsColorThemeFadeIn, token, tokenStart, expiresIn }) => {
  const [isError, setIsError] = useState(false);
  const { primaryColor } = useColorTheme();
  const timer = useRef(null);
  const timerClearLogout = useRef(null);
  const lastColorTheme = lastColor ? JSON.parse(lastColor) : { primary: primaryColor } ;
  const lastPrimary = lastColorTheme?.primary;
  const [thisPrimary, setThisPrimary ] = useState(lastPrimary);
  const [showModal, setShowModal] = useState(false);
  const isLoading = (status === STATE_STATUSES.PENDING) || (colorStatus === STATE_STATUSES.PENDING);

  useEffect(()=>{
    //setLoginStatus(STATE_STATUSES.INIT);
    setMfaStatus(STATE_STATUSES.INIT);
  },[setMfaStatus])

  useEffect(()=>{
    if (logoutStatus === STATE_STATUSES.READY ) {

      timerClearLogout.current = setTimeout(() => {
        localStorage.setItem('logoutStatus', null)
        localStorage.setItem('lastColor', null)
        setMfaStatus(STATE_STATUSES.INIT)
        setThisPrimary(primaryColor)
        document.documentElement.style.setProperty('--color-theme-primary', primaryColor);
      }, 200);

    }

    return () => {
      if (timerClearLogout.current) {
        clearTimeout(timerClearLogout.current);
      }
    };
  },[logoutStatus, primaryColor, setMfaStatus])

  useEffect(()=>{
    if (isLoading) {
      timer.current = setTimeout(() => {
        setMfaStatus(STATE_STATUSES.READY)
        setIsColorThemeFadeIn(true);
      }, 500);
    }
    if (mfaStatus === STATE_STATUSES.ERROR && timer.current) {
      clearTimeout(timer.current);
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  },[isLoading, loginStatus, colorStatus, mfaStatus, setMfaStatus, setIsColorThemeFadeIn])

  const onFinishOTP = (values) => {
    setMfaStatus(STATE_STATUSES.PENDING);
    requesOTP(values)
      .then(() => setLoginStatus(STATE_STATUSES.READY))
      .catch(() => {
        setMfaStatus(STATE_STATUSES.ERROR);
        setIsError(true);
      });
  };

  const onFinishFailedOTP = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onResendOTP = () => {
    resendOTP({token});
    setShowModal(true);
  }

  return (
    <>

    <Popup 
      isLoading={isLoading} 
      loginStatus={loginStatus}
      primaryColor={ logoutStatus === STATE_STATUSES.READY ? thisPrimary : primaryColor}
    >
      {

            <div className={`authorization-wrapper ${logoutStatus === STATE_STATUSES.READY && 'animate_fade_in'}`} >
              <img className="logo" src={logo} alt="logo" />
              <div className="signin-box">
                  <Form
                    name="login"
                    initialValues={{ remember: true, token }}
                    onFinish={onFinishOTP}
                    onFinishFailed={onFinishFailedOTP}
                  >
                    {isError && (
                      <>
                        <Alert message="Error" description="Wrong Passcode" type="error" showIcon />
                        <br />
                      </>
                    )}
                    <div className="title">Please Check Your Email.</div>
                    <CountdownTimer expiresIn={expiresIn} tokenStart={tokenStart} />
                    <Form.Item label="" name="otp" rules={[{ required: true, message: "Please input your One-Time Passcode!" }]}>
                      <Input />
                    </Form.Item>
                    <Form.Item label="" name="token" style={{ height: "0px" }}>
                      <Input type="hidden"/>
                    </Form.Item>
                    <div style={{ display: "flex", justifyContent: "space-around" }}>
                      <Button className="otp-btn" type="text" onClick={onResendOTP}>
                        Resend Code
                      </Button>
             
                        <Button className="otp-item" type="primary" htmlType="submit" loading={isLoading }>
                          Login
                        </Button>
                     
                    </div>
                  </Form>
              </div>
            </div>
      }
    </Popup>
    <Modal
        className="export-modal otp"
        title="Passcode resent. Please check your email."
        visible={showModal}
        onCancel={() => setShowModal(false)}
      >
    </Modal>
    </>
  );
};

export default connect(
  (state) => ({
    status: state.authorization.status,
    token: state.authorization.token,
    tokenStart: state.authorization.tokenStart,
    expiresIn: state.authorization.expiresIn,
    loginStatus: state.authorization.loginStatus,
    mfaStatus: state.authorization.mfaStatus,
    colorStatus: state.settingsYourProfile.status,
    logoutStatus: state.authorization.logoutStatus,
    lastColor: state.authorization.lastColor,
  }),
  { requesOTP, resendOTP, setLoginStatus, setMfaStatus, setIsColorThemeFadeIn }
)(Otp);
