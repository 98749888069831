const url = process.env.REACT_APP_URL;

//Ranking Search Overview
export const FETCH_RANKING_LOCATION_OVERVIEW = "FETCH_RANKING_LOCATION_OVERVIEW";
export const fetchRankingLocationOverview = (data) => ({
  type: FETCH_RANKING_LOCATION_OVERVIEW,
  request: {
    url: `${url}/v2/ranking/location-overview`,
    method: "POST",
    data,
  },
});

export const SET_PRISTINE_OVERVIEW_LOCATION = "SET_PRISTINE_OVERVIEW_LOCATION";
export const setPristineSearchOverview = (pristine) => {
  return {
    type: SET_PRISTINE_OVERVIEW_LOCATION,
    payload: pristine,
  };
};
