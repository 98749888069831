import React from "react";
import { Tabs } from "antd";

import CurrentPricesTab from "tabs/PricingTabs/PricingBottomTabs/CurrentPricesTab/CurrentPricesTab";
import PriceRangeTab from "tabs/PricingTabs/PricingBottomTabs/PriceRangeTab/PriceRangeTab";
import PriceComparisonTab from "tabs/PricingTabs/PricingBottomTabs/PriceComparisonTab/PriceComparisonTab";
import about from "assets/images/about.svg";
import { TabWithIcon } from "assets/icons/icons";

const PricingBottomChartTabs = ({ setActiveTab }) => {
  const { TabPane } = Tabs;

  function callback(key) {
    setActiveTab(key);
  }

  return (
    <div className="main-box">
      <Tabs defaultActiveKey="1" onChange={callback}>
        <TabPane
          tab={
            <TabWithIcon
              tabName={"Average Pricing"}
              icon={about}
              tooltip={"Overall the prices moved up 20% based on the selected filters."}
            />
          }
          key="1"
        >
          <CurrentPricesTab />
        </TabPane>
        <TabPane
          tab={
            <TabWithIcon tabName={"Price Range"} icon={about} tooltip={"Overall the prices moved up 20% based on the selected filters."} />
          }
          key="2"
        >
          <PriceRangeTab />
        </TabPane>
        <TabPane
          tab={
            <TabWithIcon
              tabName={"Price Comparison"}
              icon={about}
              tooltip={"Overall the prices moved up 20% based on the selected filters."}
            />
          }
          key="3"
        >
          <PriceComparisonTab />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default PricingBottomChartTabs;
