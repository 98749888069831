import styled, { keyframes } from "styled-components";
import { hexToRgbA } from "../../utils/hexToRgbA";

export const Styles = styled.div.attrs((props) => props)`
  padding: 10px 50px;
  background: #fafbfd;
  ${(props) => props}
  .wrapper-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px -4px 0px;
    position: relative;
    .ant-btn-primary {
      background: ${(props) => props.color};
      border-color: ${(props) => props.color};
      font-weight: bold;
    }
  }
  .is-wait{
    cursor: wait;
  }
  .filter-wrapper {
    display: inline-block;
    position: relative;
    margin: 4px;
    .filter-btn {
      text-transform: capitalize;
      display: inline-block;
      border-radius: 22px;
      border: solid 1px #dbdbdd;
      background: #ffffff;
      padding: 12px 12px;
      cursor: pointer;
      color: #5d6a7d;
      box-sizing: border-box;
      font-family: "Gilroy-Medium";
      font-size: 14px;
    }
    .current-filter {
      border: solid 2px red !important;
    }
    .uncommited-change {
      background: ${(props) => hexToRgbA(props.color, "0.15")};
    }
    .updating-filter {
      cursor: not-allowed;
    }
    .btn-spin {
      display: inline-block;
      position: absolute;
      left: 40%; /* Adjust the position as needed */
      top: 10px; /* Adjust the position as needed */
      z-index: 1;
    }
    .filter-dropdown {
      width: 300px;
      position: absolute;
      left: 0;
      top: 60px;
      z-index: 150;
      border-radius: 4px;
      box-shadow: 0 30px 50px 0 rgba(29, 30, 78, 0.1), 0 0 6px 0 rgba(27, 28, 51, 0.08);
      background-color: #ffffff;
    }
    .filter-list {
      max-height: 420px;
      overflow-y: auto;
      padding: 15px;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      &::-webkit-scrollbar-thumb {
        background: #abaaba;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #807f97;
      }
      .filter-item {
        position: relative;
        display: flex;
        align-items: center;
        border-radius: 4px;
        border: solid 1px #e6ecf0;
        padding: 12px;
        margin-top: 15px;
        cursor: pointer;
        .img {
          width: 16px;
          height: 16px;
          margin-right: 12px;
        }
        .color {
          max-width: 16px;
          height: 16px;
          display: block;
          width: 100%;
          border-radius: 2.8px;
          background-color: #f14758;
          margin-right: 12px;
        }
        .name {
          font-size: 14px;
          color: #000000;
        }
        .count {
          color: #9696a0;
          font-size: 14px;
          margin-left: 5px;
        }
      }
      .filter-item.subbrand {
        position: relative;
        width: 85%;
        margin-left: auto;
        &:after {
          content: "";
          height: 1px;
          width: 20px;
          background: #e0e0e4;
          left: -25px;
          position: absolute;
        }
      }
      .count-items {
        color: #9696a0;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        font-family: "Gilroy-Bold";
        text-transform: capitalize;
      }
      .search {
        width: 100%;
        position: relative;
        input {
          height: 44px;
          background-color: #eef2f7;
          padding-left: 40px;
          padding-right: 12px;
          width: 100%;
          border: none;
          outline: none;
          color: #000000;
          font-size: 14px;
          border-radius: 2px;
        }
        img {
          position: absolute;
          top: 10px;
          left: 10px;
          height: 24px;
          width: 24px;
        }
      }
      .clear-search {
        position: absolute;
        right: 10px;
        top: 10px;
        height: 24px;
        width: 24px;
        background: none;
        border: none;
        color: #8a99a8;
      }
      .clear-search:active {
        color: #8a99a8;
      }
    }
  }
  .reset-date {
    font-size: 16px;
    color: ${(props) => props.color};
    border: none;
    font-family: "Gilroy-ExtraBold";
  }
  .reset-date-disabled {
    background-color: transparent;
    font-size: 16px;
    color: ${(props) => hexToRgbA(props.color, "0.2")};
    font-family: "Gilroy-ExtraBold";
    border: none;
    height: 44px;
  }
  .reset-date-disabled:hover {
    background-color: transparent;
    color: ${(props) => hexToRgbA(props.color, "0.2")};
  }
  .control {
    padding: 15px 15px 15px 15px;
    border-top: 1px solid #e0e0e4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .select {
      font-size: 16px;
      color: ${(props) => props.color};
      font-family: "Gilroy-ExtraBold";
      border: none;
      height: 44px;
    }
    .apply {
      font-size: 14px;
      color: #ffffff;
      font-family: "Gilroy-ExtraBold";
      border-radius: 4px;
      background-color: ${(props) => props.color};
      border: none;
      height: 44px;
      right: 0px;
    }
    .select-disabled {
      background-color: transparent;
      font-size: 16px;
      color: ${(props) => hexToRgbA(props.color, "0.2")};
      font-family: "Gilroy-ExtraBold";
      border: none;
      height: 44px;
    }
  }
  .active-filter {
    border: solid 2px #5d6a7d !important;
    color: #5d6a7d !important;
    font-family: "Gilroy-ExtraBold";
    padding: 13px 13px !important;
  }
  .selected-filter {
    border: solid 2px ${(props) => props.color} !important;
    color: ${(props) => props.color} !important;
    font-family: "Gilroy-ExtraBold";
    padding: 13px 13px !important;
  }
  .select-count {
    font-family: "Gilroy-Regular";
    margin-left: 5px;
  }
  .loader {
    margin: 0 !important;
    svg {
      width: 40px;
      height: 40px;
    }
  }
  .main-lastfilter-item {
    background: ${(props) => hexToRgbA(props.color, "0.1")};
  }
  .selected-item {
    border: solid 2px ${(props) => props.color} !important;
    .name {
      color: ${(props) => props.color} !important;
      font-family: "Gilroy-ExtraBold";
    }
  }
  .selected-some-brand {
    border: solid 2px ${(props) => props.color} !important;
    border-style: dashed !important;
    color: ${(props) => props.color} !important;
  }
  .products-box {
    width: 540px !important;
    left: -100px !important;
  }
  .title-product {
    color: #9696a0;
    font-size: 12px;
    font-family: "Gilroy-Bold";
  }
  .title-product.companyProductGroup {
    margin-top: 25px;
  }
  .no-data {
    font-size: 14px;
    margin: 15px 0;
    color: #9696a0;
  }
  .filter-dropdown.date-box {
    width: 590px;
    .date-title {
      color: #9696a0;
      font-size: 12px;
      font-family: "Gilroy-Bold";
      text-transform: capitalize;
      margin-bottom: 15px;
    }
    .date-box {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 18px;
      .date-item {
        border-radius: 4px;
        border: solid 1px #dfdfea;
        color: #000000;
        font-size: 14px;
        padding: 11px 16px;
        margin-right: 10px;
        margin-bottom: 10px;
        cursor: pointer;
      }
    }
    .date-title.custom {
      margin: 0;
      cursor: pointer;
    }
    .range-box {
      margin-top: 15px;
    }
    .ant-picker.ant-picker-range ~ div {
      position: static !important;
      .ant-picker-dropdown.ant-picker-dropdown-range {
        position: static !important;
      }
    }
  }
  .wrapper-item {
    position: relative;
    overflow: hidden;
  }
  .wrapper-item.haveSubbrand {
    &:after {
      content: "";
      position: absolute;
      width: 1px;
      background: #e0e0e4;
      height: calc(100% - 100px);
      top: 80px;
      left: 16px;
    }
  }
  .down-box {
    position: absolute;
    margin-left: auto;
    width: 40px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0px;
    top: 16px;
    cursor: pointer;
    &:after {
      content: "";
      width: 1px;
      background: #e0e0e4;
      height: 26px;
      position: absolute;
      left: 0px;
      top: 8px;
    }
    img {
      position: absolute;
    }
  }
  .active-date {
    border: solid 2px ${(props) => props.color} !important;
    color: ${(props) => props.color} !important;
    font-family: "Gilroy-ExtraBold";
  }
  .filter-text-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0px -4px;

    .filter-text-wrapper {
      font-weight: 500;
      padding: 20px 0 0 5px;
      font-family: "Gilroy-Medium";
    }

    .switch-container {
      display: flex;
      align-items: flex-end;
      .switch-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        font-family: "Gilroy-Medium";
      }
      .ant-switch-checked {
        background-color: ${(props) => props.color};
      }
      .switch-checked {
        margin: 0px 0px -3px 10px;
      }
    }
  }
  .bold {
    font-weight: 800;
    font-family: "Gilroy-ExtraBold";
  }
  .reset-button {
    font-weight: 800;
    color: ${(props) => props.color};
    font-family: "Gilroy-ExtraBold";
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    &-disabled {
      background-color: transparent;
      border: none;
      display: inline;
      margin: 0;
      padding: 0;
      font-weight: 800;
      font-family: "Gilroy-ExtraBold";
      pointer-events: none;
      cursor: default;
      color: ${(props) => hexToRgbA(props.color, "0.2")};
    }
  }
  .avatar-placeholder {
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 50%;
    object-fit: cover;
  }
  .ant-dropdown-menu-vertical {
  }
  .top-menu-btn {
    width: 24px;
    height: 24px;
    margin: 0 7px -7px 0;
    cursor: pointer;
  }
  .top-menu-flat {
    width: 24px;
    height: 24px;
    margin: 0 7px -7px 0;
  }
  .top-menu {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .site-dropdown-context-menu {
    margin-left: 15px;
  }
`;

const pulse = (props) => keyframes`
  to {
    box-shadow: 0 0 18px 18px ${hexToRgbA(props.color, "0")};
  }
`;
export const ApplyDiv = styled.div.attrs((props) => props)`
  width: 120px;
  height: 50px;
  margin: 0 auto 0;
  padding: 0px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  .disabled {
    position: relative;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
    display: block;
    width: 63px;
    height: 42px;
    text-transform: capitalize;
    display: inline-block;
    border-radius: 22px;
    background: ${(props) => hexToRgbA(props.color, "0.3")};
    padding: 12px 12px;
    cursor: pointer;
    box-sizing: border-box;
    font-size: 14px;
    border: none;
    color: white;
    font-family: "Gilroy-Bold";
    padding: 13px 13px !important;
    pointer-events: none;
    cursor: default;
  }

  .notify-button {
    position: relative;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
    display: block;
    width: 23px;
    height: 23px;
    text-transform: capitalize;
    display: inline-block;
    border-radius: 22px;
    background: ${(props) => hexToRgbA(props.color, "1")};
    padding: 2px 2px;
    cursor: pointer;
    box-sizing: border-box;
    font-size: 14px;
    border: none;
    color: white;
    font-family: "Gilroy-Bold";
    padding: 13px 13px !important;
    cursor: pointer;
    box-shadow: 0 0 0 0 ${(props) => hexToRgbA(props.color, "0.5")};
    animation: ${props => pulse(props)} 1.5s infinite cubic-bezier(0.66, 0, 0, 1);
  }
  .notify-button:hover {
    animation-name: none;
  }

  .pulse-button {
    position: relative;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
    display: block;
    width: 63px;
    height: 42px;
    text-transform: capitalize;
    display: inline-block;
    border-radius: 22px;
    background: ${(props) => hexToRgbA(props.color, "1")};
    padding: 12px 12px;
    cursor: pointer;
    box-sizing: border-box;
    font-size: 14px;
    border: none;
    color: white;
    font-family: "Gilroy-Bold";
    padding: 13px 13px !important;
    cursor: pointer;
    box-shadow: 0 0 0 0 ${(props) => hexToRgbA(props.color, "0.5")};
    animation: ${props => pulse(props)} 1.5s infinite cubic-bezier(0.66, 0, 0, 1);
  }
  .pulse-button:hover {
    animation-name: none;
  }
  
`;
export const NotifyDiv = styled.div.attrs((props) => props)`
  .ant-tooltip-arrow-content{
    background-color: #fafafa !important;
  }
  .ant-tooltip-inner {
    font-family: "Gilroy-Regular";
    color: #000000 !important;
    background-color: #fafafa;
  }

  width: 10px;
  height: 30px;
  margin: 0 auto 0;
  padding: 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .notify-icon {
    margin-left: -12px;
    margin-top: -12px;
  }
  .notify-button {
    font-family: "Gilroy-Bold";
    position: relative;
    top: 50%;
    left: 50%;
    margin-left: -20px;
    margin-top: -50px;
    display: block;
    width: 1px;
    height: 1px;
    text-transform: capitalize;
    display: inline-block;
    border-radius: 30px;
    background: ${(props) => hexToRgbA(props.color, "1")};
    cursor: pointer;
    box-sizing: border-box;
    font-size: 14px;
    border: none;
    color: white;
    font-family: "Gilroy-Bold";
    padding: 7px 7px !important;
    cursor: pointer;
    width: 100%;
    text-align: center;
    box-shadow: 0 0 0 0 ${(props) => hexToRgbA(props.color, "0.5")};
    animation: ${props => pulse(props)} 1.5s infinite cubic-bezier(0.66, 0, 0, 1);
  }
  .notify-button:hover {
    animation-name: none;
  }
  .count-text {
    font-family: "Gilroy-Bold" !important;
    margin-left: -4px;
    margin-top: -5px;
  }
  .pulse-button {
    position: relative;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
    display: block;
    width: 63px;
    height: 42px;
    text-transform: capitalize;
    display: inline-block;
    border-radius: 22px;
    background: ${(props) => hexToRgbA(props.color, "1")};
    padding: 12px 12px;
    cursor: pointer;
    box-sizing: border-box;
    font-size: 14px;
    border: none;
    color: white;
    font-family: "Gilroy-Bold";
    padding: 13px 13px !important;
    cursor: pointer;
    box-shadow: 0 0 0 0 ${(props) => hexToRgbA(props.color, "0.5")};
    animation: ${props => pulse(props)} 1.5s infinite cubic-bezier(0.66, 0, 0, 1);
  }
  .pulse-button:hover {
    animation-name: none;
  }
`;
