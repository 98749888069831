import { success, error } from "redux-saga-requests";
import { FETCH_RATING_SUMMARY, SET_RATING_SUMMARY_FILTER, SET_PRISTINE_RATING_SUMMARY_CHART } from "./actions";
import { STATE_STATUSES } from "utils/statuses";
import { RESET_ERROR } from "store/error/actions";

const defaultFilter = [
  {
    name: "byRetailer",
    value: "byRetailer",
  },
  { name: "showAll", value: false },
];

const initialState = {
  ratingSummary: {
    success: false,
    productCount: 0,
    avgReviewCount: 0,
    bestRated: [],
    byBrand: [],
    byRetailer: [],
  },
  filter: localStorage.getItem(SET_RATING_SUMMARY_FILTER)
    ? JSON.parse(localStorage.getItem(SET_RATING_SUMMARY_FILTER))
    : defaultFilter,

  status: STATE_STATUSES.READY,
  isPristine: true,
  errorL: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RATING_SUMMARY: {
      return processReducer(state);
    }
    case success(FETCH_RATING_SUMMARY): {
      return {
        ...state,
        ratingSummary: { ...action.data, success: true },
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }
    case error(FETCH_RATING_SUMMARY): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case SET_RATING_SUMMARY_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }

    case SET_PRISTINE_RATING_SUMMARY_CHART: {
      return {
        ...state,
        isPristine: action.payload,
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
