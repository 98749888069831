import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

//Components
import NavBar from "components/NavBar";
import MainFilters from "components/Filters/MainFilters";
import AssortmentTopTab from "tabs/AssortmentTabs/AssortmentTopTab";
//Actions
import { setPristineAssortmentSummaryTop } from "store/assortment/assortmentSummaryTop/actions";
import { setPristineAssortmentSummaryBottom } from "store/assortment/assortmentSummaryBottom/actions";
import { setPristineAssortmentChange } from "store/assortment/assortmentChange/actions";
import { setPristineAssortmentDetails } from "store/assortment/assortmentDetails/actions";
import LoaderBox from "components/LoaderBox";
import useFilters from "../hooks/useFilters";

//Utils
import { STATE_STATUSES } from "utils/statuses";

const Assortment = () => {
  const { status: filtersStatus } = useSelector((state) => state.filters);
  const { prepareLogoutStatus, status: statusAuth } = useSelector((state) => state.authorization);

  //States
  const [activeTabTop, setActiveTabTop] = useState("1");
  const {
    mainLastFilter,
    statusMainFilters,
    universalFiltersStatus,
    statusFilters,
    titleDateStatus,
  } = useFilters();

  //Constants
  const dispatch = useDispatch();

  const getActionTop = useCallback((value) => [setPristineAssortmentSummaryTop(value), setPristineAssortmentChange(value), setPristineAssortmentDetails(value)][+activeTabTop - 1],[activeTabTop]);

  const getPristineTop = +activeTabTop > 1 ? false : true;

  const getActionBottom = useCallback((value) => setPristineAssortmentSummaryBottom(value),
  []);

  const getPristineBottom = true;

  useEffect(() => {
    return () => {
      dispatch(getActionTop(getPristineTop));
      dispatch(getActionBottom(getPristineBottom));
    };
  }, [dispatch, getActionBottom, getActionTop, getPristineBottom, getPristineTop]);

  useEffect(() => {
    if (filtersStatus === STATE_STATUSES.PENDING) {
      dispatch(getActionTop(true));
      dispatch(getActionBottom(true));
    }
  }, [filtersStatus, dispatch, getActionTop, getActionBottom]);

  return (
    <div className="main-page-wrapper">
      <NavBar />
      <div className="main-page-box">
        <MainFilters />
        <div className="main-title-page">Assortment</div>

        <div className="main-tabs-box">
          <AssortmentTopTab setActiveTabTop={setActiveTabTop} activeTabTop={activeTabTop} />
        </div>
        <div className="main-tabs-box"></div>
      </div>
      {
        statusAuth !== STATE_STATUSES.PENDING &&
        mainLastFilter &&
        statusMainFilters === STATE_STATUSES.READY &&
        universalFiltersStatus === STATE_STATUSES.READY &&
        statusFilters === STATE_STATUSES.READY &&
        titleDateStatus === STATE_STATUSES.READY &&
        prepareLogoutStatus === STATE_STATUSES.INIT ? null : <LoaderBox isOpacity={true} />
      }
      </div>
  );
};

export default Assortment;
