export const sortData = (data, sortParam, directionSort) => {
    switch(sortParam) {
        case 'title':
        case 'searchTerm':
        case 'retailer': {
            return data.sort((first, second) => {
                if(directionSort) {
                    if (second[sortParam] > first[sortParam]) {
                        return -1;
                    }
                } else {
                    if (first[sortParam] > second[sortParam]) {
                        return -1;
                    }
                }
            })
        }
        case 'shelfPrice': {
            return data.sort((first, second) => {
                if(directionSort) {
                    return Number(second[sortParam]) - Number(first[sortParam])
                } else {
                    return Number(first[sortParam]) - Number(second[sortParam])
                }
            })
        }
        case 'rank': {
                return data.sort((first, second) => {
                    if(directionSort) {
                        return Number(first[sortParam] - Number(second[sortParam]))
                    } else {
                        return Number(second[sortParam] - Number(first[sortParam]))
                    }
                })
            }
        default: {
            break
        }
    }
}