import React from "react";

import { ProductStyles } from "../Styles";

const MediaSummaryProduct = ({ item, isLastChild, key }) => {
  const { label } = item;

  return (
    <ProductStyles>
      <div className="media-wrapper">
        <div className="lines" key={key}>
          <div></div>
          <div style={{ borderLeft: !isLastChild ? null : 0 }}></div>
        </div>
        <div className="product-media">
          <span>{label}</span>
        </div>
      </div>
    </ProductStyles>
  );
};

export default MediaSummaryProduct;
