import { success, error } from "redux-saga-requests";
import {
  FETCH_ASSORTMENT_DETAILS,
  SET_PRISTINE_ASSORTMENT_DETAILS,
  ADD_TO_DATA_ASSORTMENT_DETAILS,
  SET_ASSORTMENT_DETAILS_FILTER,
} from "./actions";
import { STATE_STATUSES } from "utils/statuses";
import { RESET_ERROR } from "store/error/actions";

const defaultFilter = [
  { name: "listed", value: [] },
  { name: "notListed", value: [] },
];

const initialState = {
  controlData: { total: 0, result: [], success: false, largestRetailer: { item: { name: "" }, count: 0 } },
  assortmentDetails: { retailers: [], rows: [], brands: [] },
  filter: localStorage.getItem(SET_ASSORTMENT_DETAILS_FILTER)
    ? JSON.parse(localStorage.getItem(SET_ASSORTMENT_DETAILS_FILTER))
    : defaultFilter,
  status: STATE_STATUSES.INIT,
  isPristine: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ASSORTMENT_DETAILS: {
      return processReducer(state);
    }

    case success(FETCH_ASSORTMENT_DETAILS): {
      return {
        ...state,
        controlData: { success: true, ...action.data },
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }

    case SET_PRISTINE_ASSORTMENT_DETAILS: {
      return {
        ...state,
        isPristine: action.payload,
      };
    }

    case ADD_TO_DATA_ASSORTMENT_DETAILS: {
      return {
        ...state,
        assortmentDetails: action.payload,
      };
    }

    case error(FETCH_ASSORTMENT_DETAILS): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case SET_ASSORTMENT_DETAILS_FILTER: {
      return { ...state, filter: action.payload };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
