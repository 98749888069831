import React, { useState, useEffect } from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import HC_rounded from "highcharts-rounded-corners"
import useColorTheme from "../../../../hooks/useColorTheme";

HC_rounded(Highcharts)

const PromotionsChart = ({ dates, data }) => {
    const { getColorPromotions } = useColorTheme();
    const [dateStep, setDateStep] = useState([])
    const [pricings, setPricings] = useState([])

    useEffect(() => {
        if(data.length) {
            const dateStep = dates.map(item => item.date)
            setDateStep(dateStep)

            const items = data.map(item =>  (
                { 
                    low: dateStep.indexOf(item.from), 
                    high: dateStep.indexOf(item.to),
                    color: getColorPromotions(item.name),
                    x: 1
                }
            ))
            const pricings = [{
                name: 'promotions',
                data: items,
                pointPlacement: 0,
                borderRadius: "4px"
            }]
            setPricings(pricings)
        }
    }, [data])

    const options = {
        title: "",
        chart: {
            type: 'columnrange',
            inverted: true,
            animation: false,
            height: 40,
        },
        plotOptions: {
            series: {
                clip: false,
                pointRange: 1,
                groupPadding: 0.47,
                states: {
                  inactive: {
                    opacity: 1,
                  },
                },
              },
              columnrange: {
                groupping: false,
                animation: false,
                pointPadding: 0,
                pointWidth: 16,
                borderWidth: 0,
              },
        },
        xAxis: {
            visible: false,
            labels: {
                enabled: false
            },
            categories: dateStep,
        },
        yAxis: {
            min: 0,
            max: dateStep.length-1,
            tickInterval: 1,
            gridLineWidth: 1,
            visible: false,
            labels: {
                enabled: false,
            },
        },
        tooltip: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        series: pricings,
    }

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )
}

export default PromotionsChart