export const roundingNumbers = (value) => {
  const number = Math.abs(value);
  const numberSplit = number.toString().split(".");
  if (!number.toString().includes(".")) {
    return `${number}.00`;
  } else if (numberSplit[1]?.length === 1) {
    return `${numberSplit[0]}.${numberSplit[1]}0`;
  } else {
    return number;
  }
};
