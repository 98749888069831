import React from "react";
import { Tabs } from "antd";
//Images
import about from "assets/images/about.svg";
//Assets
import { TabWithIcon } from "assets/icons/icons";
//Componets
import CurrentContent from "./CurrentContent";
import KeyContent from "./KeyContent";
import Benchmark from "./ContentBenchmark";
import ContentTrend from "./ContentTrend";

const ContentTopTabs = ({ activeTabTop, setActiveTabTop }) => {
  const { TabPane } = Tabs;

  function callback(key) {
    setActiveTabTop(key);
  }

  return (
    <div className="main-box">
      <Tabs defaultActiveKey={activeTabTop} onChange={callback}>
        <TabPane
          tab={
            <TabWithIcon
              tabName={"Current Content"}
              icon={about}
              tooltip={"Overall the prices moved up 20% based on the selected filters."}
            />
          }
          key="1"
        >
          <CurrentContent />
        </TabPane>
        <TabPane
          tab={
            <TabWithIcon tabName={"Key Content"} icon={about} tooltip={"Overall the prices moved up 20% based on the selected filters."} />
          }
          key="2"
        >
          <KeyContent />
        </TabPane>
        <TabPane
          tab={
            <TabWithIcon
              tabName={"Content Trend"}
              icon={about}
              tooltip={"Overall the prices moved up 20% based on the selected filters."}
            />
          }
          key="3"
        >
          <ContentTrend />
        </TabPane>
        <TabPane
          tab={
            <TabWithIcon tabName={"Benchmark"} icon={about} tooltip={"Overall the prices moved up 20% based on the selected filters."} />
          }
          key="4"
        >
          <Benchmark />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ContentTopTabs;
