import styled from "styled-components";

export const Styles = styled.div`
  .row-brands {
    margin-top: 12px;
    justify-content: flex-start;
   
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #abaaba;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #807f97;
    }
  }
  .wrapper-prod {
    position: relative;
    border-radius: 5px;
    .retailer-marcker {
      width: 18px;
      height: 18px;
      position: absolute;
      border-radius: 50%;
      right: 0;
      bottom: 0;
    }
  }
  .image {
    width: 40px;
    height: 40px;
  }
  .retailer-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 12px;
    margin-bottom: 6px;
    min-width: 40px;
    img {
      width: 25px;
    }
  }
 
  .retailer-box-image {
      width: 24px,
      height: 24px
  }
  .retailer-box-value{
    font-size: 12px;
    margin-top: 8px;
    text-align: center;
    font-family: "Gilroy-Medium"
  }
  .retailer-box-value.product{
    font-size: 14px;
    margin-top: 4px;
  }
`;
