const url = process.env.REACT_APP_URL;

export const FETCH_MEDIA_SUMMARY_BOTTOM = "FETCH_MEDIA_SUMMARY_BOTTOM";
export const fetchMediaSummaryBottom = (params) => ({
  type: FETCH_MEDIA_SUMMARY_BOTTOM,
  request: {
    url: `${url}/v2/media/calendar`,
    method: "POST",
    data: params,
  },
});

export const FETCH_MEDIA_SUMMARY_BANNER = "FETCH_MEDIA_SUMMARY_BANNER";
export const fetchMediaSummaryBanner = (id) => ({
  type: FETCH_MEDIA_SUMMARY_BANNER,
  request: {
    url: `${url}/v2/media/banner/${id}`,
    method: "POST",
  },
  meta: {
    takeLatest: false,
  },
});
export const DELETE_MEDIA_SUMMARY_BANNER = "DELETE_MEDIA_SUMMARY_BANNER";
export const deleteMediaSummaryBanner = () => {
  return {
    type: DELETE_MEDIA_SUMMARY_BANNER,
  };
};

export const SET_MEDIA_SUMMARY_BOTTOM_FILTER = "SET_MEDIA_SUMMARY_BOTTOM_FILTER";
export const setFilterMediaSummaryBottom = (filter) => {
  localStorage.setItem(SET_MEDIA_SUMMARY_BOTTOM_FILTER, JSON.stringify(filter));
  return {
    type: SET_MEDIA_SUMMARY_BOTTOM_FILTER,
    payload: filter,
  };
};

export const SET_PRISTINE_MEDIA_SUMMARY_BOTTOM = "SET_PRISTINE_MEDIA_SUMMARY_BOTTOM";
export const setPristineMediaSummaryBottom = (pristine) => {
  return {
    type: SET_PRISTINE_MEDIA_SUMMARY_BOTTOM,
    payload: pristine,
  };
};

export const ADD_TO_DATA_MEDIA = "ADD_TO_DATA_MEDIA";
export const addToDataMedia = (rows) => {
  return {
    type: ADD_TO_DATA_MEDIA,
    payload: rows,
  };
};
