import moment from "moment";
export const converToThousands = (val) => {
  const suffix = "k";
  const num = Number(val);
  let updatedLabel = num >= 1000 ? toFixed_norounding(num / 1000, 1) : val;
  let updatedSuffix = num >= 1000 ? suffix : "";
  return updatedLabel + updatedSuffix;
};

export function toFixed_norounding(n, p) {
  var result = n.toFixed(p);
  return result <= n ? result : (result - Math.pow(0.1, p)).toFixed(p);
}

export const convertRatingToString = (arr) => {
  if (arr.includes("All")) {
    return "All";
  } else if (arr.includes(0)) {
    return "0";
  } else if (!arr.length) {
    return "All";
  } else {
    return arr.join("|");
  }
};

export const convertArrToString = (arr) => {
  if (arr.length) {
    return arr[0] + "-" + arr[1];
  } else {
    return "All";
  }
};

export const ratingCalendarTableKeys = [
  { name: "Avg. Rating", key: "avgRating" },
  { name: "No. of Reviews", key: "reviewCount" },
  { name: "Most Recent Review", key: "recentReview" },
];

export const getDateDiffInDays = (date) => {
  const recentReviewinDays = moment(date, "YYYY-MM-DD");
  const current = moment().startOf("day");
  const lastReview = +moment
    .duration(current.diff(recentReviewinDays))
    .asDays()
    .toFixed(0);
  if (date === null) {
    return null;
  } else return lastReview;
};

export const specifications = [
  { value: "Date", key: "date" },
  { value: "Retailer", key: "retailer" },
  { value: "Rating", key: "rating" },
  { value: "Review Title", key: "title" },
  { value: "Comments", key: "comment" },
];

export const specificationsTrendAnalysis = [
  { value: "Retailer", key: "groupName" },
  { value: "No. of Reviews", key: "reviewCount" },
  { value: 1, key: "rating1" },
  { value: 2, key: "rating2" },
  { value: 3, key: "rating3" },
  { value: 4, key: "rating4" },
  { value: 5, key: "rating5" },
  { value: "Avg. Rating", key: "avgRating" },
];
