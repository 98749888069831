import { COLORS } from "assets/colors/colors";
import styled from "styled-components";

export const Styles = styled.div`
  margin-top: 40px;
  position: relative;

  .accordion-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .page-count {
    position: absolute;
    left: 20px;
    bottom: 0;
  }
  .manufacturer-wrapper {
    display: flex;
    height: 64px;
    margin-top: 28px;
    cursor: pointer;
  }
  .container {
    display: flex;
    align-items: center;
    padding-left: 10px;

    & span {
      font-size: 14px;
      font-family: Gilroy-ExtraBold;
      font-weigth: bold;
    }
  }
  .manufacturer {
    width: 22.5%;
    padding-right: 10px;

    .plus-btn {
      width: 12px;
      padding: 6px;
      height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      border: 1px solid ${COLORS.lightGray};

      & > span {
        color: ${COLORS.lightGray};
        font-family: Gilroy-Medium;
      }
    }

    .color {
      width: 16px;
      height: 16px;
      border-radius: 2px;
      margin-right: 12px;
    }
  }
  .retailers {
    width: 14.8%;
  }
  .locations {
    width: 24.2%;
  }
  .thumbnail {
    width: 23%;
  }
  .products {
    width: 15.5%;
  }
`;
