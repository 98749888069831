import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Styles } from "./styles";
import LoaderBox from "components/LoaderBox";
import RenderNoData from "components/RenderNoData";
import PromotionsCalendarRetailers from "tabs/PromotionsTabs/PromotionsBottomTabs/components/PromotionsCalendarRetailers";
import RatingChart from "./components/RatingChart";
import { STATE_STATUSES } from "utils/statuses";
import { getDateDiffInDays } from "utils/ratingHelper";
import { fetchRatingSummaryBottom } from "store/rating/ratingSummaryBottom/actions";
import useFilters from "hooks/useFilters";
import useRatingFilters from "hooks/useRatingFilters";
import Filters from "./components/Filters";
import { RATING } from "constants/constants";

const RatingSummaryBottom = ({
  arrayOfDesiredBrand,
  clickedArray,
  setClickedArray,
  retailer,
  setSelectValue,
  resetClickedArr,
  setAllCollapsed,
  isAllCollapsed,
  isRedraw,
  setIsRedraw,
}) => {
  const dispatch = useDispatch();
  const { status, bottomFilter, ratingSummaryBottom } = useSelector((state) => state.ratingSummaryBottom);
  const { filter } = useSelector((state) => state.ratingSummary);
  const { statusFilters, mainLastFilter } = useFilters();
  const { ratingSummaryProduct, status: ratingSummaryProductStatus } = useSelector(
    (state) => state.ratingSummaryProduct
  );

  const [minMaxValues, setMinMaxValues] = useState([[0, 100]]);

  const [sortId, setSortId] = useState("avgRating");
  const [sortDirection, setSortDirection] = useState(true);
  const [isCountSliderTouched, setIsCountSliderTouched] = useState(false);
  const [isDaysSliderTouched, setIsDaysSliderTouched] = useState(false);

  const [queryParams, setQueryParams] = useRatingFilters(
    statusFilters,
    mainLastFilter,
    sortId,
    sortDirection,
    bottomFilter,
    filter,
    setClickedArray
  );

  const ratingByType = useMemo(() => filter[0].value, [filter]);
  const isBrand = useMemo(() => ratingByType === "byBrand", [ratingByType]);

  const countRange = ratingSummaryBottom.countRange || { min: 0, max: 100 };
  const minValueCount = countRange.min ? +countRange.min : 0;
  const maxValueCount = countRange.max ? +countRange.max : 0;

  useEffect(() => {
    setMinMaxValues([[minValueCount, maxValueCount]]);
  }, [countRange]);

  useEffect(() => {
    setSelectValue({
      countRange: minMaxValues[0],
    });
  }, [minMaxValues[0][0], minMaxValues[0][1]]);

  const filteredData = useMemo(() => {
    const rating = ratingSummaryBottom[ratingByType] || [];
    if (rating.length) {
      if (!isBrand && retailer.length) {
        return rating.filter(({ item }) => item.name === retailer[0]);
      } else if (isBrand && arrayOfDesiredBrand.length) {
        return rating.filter(({ item }) => arrayOfDesiredBrand.includes(item.name));
      } else {
        return rating;
      }
    }
    return [];
  }, [ratingSummaryBottom, ratingByType, retailer, arrayOfDesiredBrand, isBrand]);

  const emptyData = useMemo(() => !filteredData.length, [filteredData]);

  useEffect(() => {
    const products = ratingSummaryBottom[ratingByType]?.flatMap((r) => r.children.flatMap((c) => c.children)) || [];
    if (products.length) {
      const reviews = products.map(({ reviewCount }) => +reviewCount);
      const recentReviews = products.map(({ recentReview }) => getDateDiffInDays(recentReview));

      const minReview = Math.min(...reviews);
      const maxReview = Math.max(...reviews);
      const minDays = Math.min(...recentReviews);
      const maxDays = Math.max(...recentReviews);

      setSelectValue({
        count: [minReview, maxReview],
        daysRange: [minDays, maxDays],
      });
    }
    setIsCountSliderTouched(false);
    setIsDaysSliderTouched(false);
  }, [ratingSummaryBottom, ratingByType]);

  useEffect(() => {
    if (Object.keys(queryParams).length) {
      dispatch(fetchRatingSummaryBottom(queryParams));
    }
  }, [queryParams, dispatch]);

  const handleSort = (id) => {
    setSortDirection((prevDirection) => !prevDirection);
    setSortId(id);
    setQueryParams((prevState) => ({
      ...prevState,
      sort: `${id}|${sortDirection ? "desc" : "asc"}`,
    }));
  };

  const onOpen = (id, children, label, isBrands, expand) => {
    setIsRedraw(false);

    const mapedChildren = children.map((child) => ({
      id: isBrands ? child.id : child.item?.id,
      name: isBrands ? child.title : child.item?.name,
      avgRating: child.avgRating,
      reviewCount: +child.reviewCount,
      recentReview: child.recentReview,
      coreRetailers: isBrands ? child.coreRetailers : {},
      combinedCoreRetailers: child.combinedCoreRetailers,
    }));

    let updatedArray;
    if (isIncluded(id)) {
      const matchedElement = clickedArray.find((item) => item.id === id);
      const filteredElements = matchedElement.children.flatMap((el) =>
        clickedArray.filter((item) => item.id === el.id)
      );
      updatedArray = filteredElements.length
        ? clickedArray.filter((el) => !filteredElements.some((item) => item.id === el.id) && el.id !== id)
        : clickedArray.filter((item) => item.id !== id);
    } else {
      updatedArray = [...clickedArray, { id, children: mapedChildren, label }];
    }

    if (expand) {
      setClickedArray((prevState) => [...prevState, ...updatedArray]);
    } else {
      setClickedArray(updatedArray);
    }

    setTimeout(() => {
      setIsRedraw(true);
      setAllCollapsed(false);
    }, 500);
  };

  const isIncluded = (id) => clickedArray.some((el) => el.id === id);

  const expandAll = () => {
    filteredData.forEach((retailer) => {
      const {
        item: { id, name },
        children,
      } = retailer;
      const label = name;
      const expand = true;

      onOpen(id, children, label, false, expand);

      retailer.children.forEach((child) => {
        const {
          item: { id, name },
          children,
        } = child;
        onOpen(id, children, name, true, expand);
      });
    });
  };

  const collapseHandle = () => {
    if (clickedArray.length) {
      resetClickedArr(true);
    } else {
      expandAll();
    }
  };

  const renderRetailers = () => (
    <PromotionsCalendarRetailers
      data={filteredData}
      isDiscountInPercents={true}
      isBrand={isBrand}
      clickedArray={clickedArray}
      setClickedArray={setClickedArray}
      marginTop={0}
      isAllCollapsed={isAllCollapsed}
      onOpen={onOpen}
      collapseHandle={collapseHandle}
      type={RATING}
      isEmpty={emptyData}
    />
  );

  const renderChart = () => (
    <RatingChart
      data={filteredData}
      clickedArray={clickedArray}
      handleSort={handleSort}
      sortId={sortId}
      sortDirection={sortDirection}
      filter={filter}
    />
  );

  return (
    <Styles>
      <div className="rating-wrapper">
        <div className="main-box-header">
          <Filters
            bottomFilter={bottomFilter}
            setSelectValue={setSelectValue}
            status={status}
            minMaxValues={minMaxValues}
            setIsCountSliderTouched={setIsCountSliderTouched}
            setIsDaysSliderTouched={setIsDaysSliderTouched}
          />
        </div>

        {ratingSummaryBottom.success ? (
          <div className="calendar-wrapper">
            {emptyData ? (
              <RenderNoData />
            ) : (
              <>
                {renderRetailers()}
                {renderChart()}
              </>
            )}
          </div>
        ) : null}
      </div>
      {(status === STATE_STATUSES.PENDING ||
        (!ratingSummaryProduct.success && ratingSummaryProductStatus === STATE_STATUSES.PENDING)) && <LoaderBox />}
    </Styles>
  );
};

export default RatingSummaryBottom;
