import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "antd";

//Components
import { Styles } from "./styles";
import LoaderBox from "components/LoaderBox";
import ShowPerPage from "components/ShowPerPage";
import AssortmentSummaryTable from "./components/AssortmentSummaryTable";
import useFilters from "../../../../../hooks/useFilters";

//Utils
import { getTimePeriod } from "utils/getTimePeriod";
import { STATE_STATUSES } from "utils/statuses";
//Actions
import { fetchAssortmentSummaryBottom, addToDataAssortmentSummaryBottom } from "store/assortment/assortmentSummaryBottom/actions";

const AssortmentSummaryBottom = () => {
  //dispatch
  const dispatch = useDispatch();
  //selectors
  const { filter } = useSelector((state) => state.assortmentSummaryTop);
  const { status, assortmentSummaryBottom, controlData } = useSelector((state) => state.assortmentSummaryBottom);
  const { mainLastFilter: lastFilter, statusFilters } = useFilters();
  const { filters: globalFilters, status: filtersStatus } = useSelector((state) => state.filters);
  //States fro table
  const [sortId, setSortId] = useState(null);
  const [sortDirection, setSortDirection] = useState(true);
  const [inputSearch, setInputSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [queryParams, setQueryParams] = useState({});
  const [isEmpty, setIsEmpty] = useState(false);
  const filterBy = filter[2].value === "manufacture" ? "manufacturer" : filter[2].value;

  const baseQuery = {
    limit: 10,
    page: 1,
    sort: "0|asc",
    search: "",
  };

  useEffect(() => {
    if (statusFilters !== STATE_STATUSES.READY) return;

    setQueryParams((prevState) => ({
      ...prevState,
      sourceType: lastFilter.sourceType,
      timePeriod: getTimePeriod(lastFilter.date),
      product: lastFilter.product,
      groupBy: filter[2].value,
      ...baseQuery,
    }));

    setLimit(10);
    setPage(1);
    setSortDirection(true);
    setSortId("0");
  }, [lastFilter, filter[2].value, statusFilters]);

  useEffect(() => {
    if (Object.keys(queryParams).length && statusFilters === STATE_STATUSES.READY) {
      dispatch(fetchAssortmentSummaryBottom(queryParams));
    }
  }, [queryParams, statusFilters, dispatch]);

  const handleSearch = (value) => {
    setInputSearch(value);
    setQueryParams((prevState) => ({
      ...prevState,
      search: value,
      page: 1,
    }));
  };

  useEffect(() => {
    const globalRetailers = globalFilters.sourceType;
    const { result } = controlData;

    if (globalRetailers.length && result.length) {
      const incomingRetailers = Object.keys(result[0]);
      const retailers = globalRetailers.filter(({ id }) => incomingRetailers.some((el) => id === +el));
      const sortedRetailers = retailers.sort((a, b) => {
        return incomingRetailers.indexOf(String(a.id)) - incomingRetailers.indexOf(String(b.id));
      });

      const rows = result.map((el) => {
        const product = Object.values(el)[0];
        const data = Object.values(el).slice(1);

        return {
          title: product.name,
          color: product.color,
          id: product.id,
          data,
        };
      });
      dispatch(addToDataAssortmentSummaryBottom({ retailers: sortedRetailers, rows }));
    }
  }, [controlData, globalFilters.sourceType]);

  const handleSort = (id) => {
    setSortDirection(!sortDirection);

    changePage(1, limit);

    setSortId(id);

    const sort = `${id}|${!sortDirection ? "asc" : "desc"}`;

    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      sort,
    }));
  };

  const changePage = (page) => {
    setPage(page);

    setQueryParams((prevState) => ({
      ...prevState,
      page,
    }));
  };

  const changeLimitHandler = (limit) => {
    setLimit(limit);
    changePage(1);

    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      limit,
    }));
  };

  useEffect(() => {
    if (controlData.result.length) {
      setIsEmpty(false);
    } else {
      setIsEmpty(true);
    }
  }, [controlData]);
  const renderTable = () => {
    return (
      <AssortmentSummaryTable
        inputSearch={inputSearch}
        handleSearch={handleSearch}
        data={assortmentSummaryBottom}
        handleSort={handleSort}
        sortId={sortId}
        sortDirection={sortDirection}
        isEmpty={isEmpty}
        filterBy={filterBy}
      />
    );
  };

  return (
    <Styles>
      <div className="wrapper-box-relative">
        <div className="chart-wrapper-box">{controlData.success ? renderTable() : null}</div>
        {controlData.success && !isEmpty ? (
          <div className="pagination-with-per-page">
            <Pagination
              className="pagination-controls"
              onChange={changePage}
              current={page}
              pageSize={limit}
              total={controlData.total}
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
            />
            <ShowPerPage setLimit={changeLimitHandler} value={limit} />
          </div>
        ) : null}
        {status === STATE_STATUSES.PENDING || filtersStatus === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
      </div>
    </Styles>
  );
};

export default AssortmentSummaryBottom;
