import React, { useEffect, useRef, useState } from "react";
import useColorTheme from "../../../hooks/useColorTheme";

//Components
import DownArrowIcon from "components/DownArrowIcon";
import DropdownMetricValue from "./components/DropdownMetricValue";
import DropdownValue from "./components/DropdownValue";
import RenderDropdownWrapper from "./components/RenderDropdownWrapper";
import RetailerBoxesGroup from "tabs/RankingTabs/RankingTopTabs/SearchRetailerTab/RetailerBoxesGroup";
import OrderSingleItem from "./components/OrderSingleItem";
import DropdownGoalsValue from "./components/DropdownGoalsValue";
//Styles
import { Styles } from "./styles";

const dropdownKeysSummary = [
  { title: "Select Metrics", key: 0 },
  { title: "Change Order", key: 1 },
  { title: "Select Retailers", key: 2 },
  { title: "Set up Goals", key: 3 },
];

const dropdownMetricKeys = [
  { title: "Pricing" },
  { title: "Promotions" },
  { title: "Ranking Search Terms" },
  { title: "Ranking Locations" },
  { title: "Content" },
  { title: "Ratings & Reviews" },
  { title: "Media" },
  { title: "Navigation" },
  { title: "Availability" },
  { title: "Assortment" },
];

const dropdownRetailersKeys = [
  { title: "Tesco" },
  { title: "Asda" },
  { title: "Sainsbury’s" },
  { title: "Morrisons" },
  { title: "Waitrose" },
  { title: "Ocado" },
  { title: "Amazon Fresh" },
  { title: "Iceland" },
  { title: "Amazon" },
];

const goalsKeys = [
  { title: "Pricing" },
  { title: "Promotion Discount" },
  { title: "Search Terms" },
  { title: "Search Terms Measure" },
  { title: "Location" },
  { title: "Location Measure" },
  { title: "Content" },
  { title: "Ratings & Reviews" },
  { title: "Media" },
  { title: "Navigation" },
  { title: "Availability" },
  { title: "Assortment" },
];

const ChangeViewFilter = ({ disabled }) => {
  //Refs
  const wrapperRef = useRef(null);
  //States
  const [showDropdown, setShowDropdown] = useState(false);
  const [hoveredEl, setHoveredEl] = useState();
  const [dropdownOrdersKeys, setDropdownOrdersKeys] = useState(
    dropdownMetricKeys.map(({ title }, i) => ({ title, id: i }))
  );
  const { primaryColor } = useColorTheme();

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      if (showDropdown) {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setShowDropdown(false);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
    }, [ref, showDropdown]);
  }
  useOutsideAlerter(wrapperRef);

  const isHovered = (key) => {
    return hoveredEl === key ? true : false;
  };

  const handleChangeOrder = (retailers) => {
    setDropdownOrdersKeys(retailers);
  };

  const renderMetricsDropdown = () => {
    return (
      <RenderDropdownWrapper
        nameForClass={"metric"}
        keysForRender={dropdownMetricKeys}
        renderComponent={DropdownMetricValue}
        resetFunc={() => {}}
      />
    );
  };

  const renderOrderDropdown = () => {
    const renderComponent = (props) => {
      return (
        <div className="render-component-order">
          <span>Drag and drop Metrics to change an order.</span>
          <RetailerBoxesGroup {...props} />
        </div>
      );
    };
    return (
      <RenderDropdownWrapper
        nameForClass={"order"}
        keysForRender={[{ title: "Order" }]}
        renderComponent={renderComponent}
        resetFunc={() => {}}
        orderRetailers={dropdownOrdersKeys}
        handleChangeOrder={handleChangeOrder}
        componentForRender={OrderSingleItem}
      />
    );
  };

  const renderRetailersDropdown = () => {
    return (
      <RenderDropdownWrapper
        nameForClass={"retailer"}
        keysForRender={dropdownRetailersKeys}
        renderComponent={DropdownMetricValue}
        resetFunc={() => {}}
      />
    );
  };

  const renderGoalsDropdown = () => {
    return (
      <RenderDropdownWrapper
        nameForClass={"goals"}
        keysForRender={goalsKeys}
        renderComponent={DropdownGoalsValue}
        resetFunc={() => {}}
        removeAll
        titleBox={"Enter a value to set a goal"}
      />
    );
  };

  const renderDropdownFuncs = [
    renderMetricsDropdown,
    renderOrderDropdown,
    renderRetailersDropdown,
    renderGoalsDropdown,
  ];

  return (
    <Styles color={primaryColor}>
      <div className="wrapper-compare" ref={wrapperRef}>
        <div
          className="compare-box"
          onClick={disabled ? () => {} : toggleDropdown}
          style={{ cursor: disabled ? "not-allowed" : "pointer" }}
        >
          <span className="compare-title">Change View</span>

          <DownArrowIcon />
        </div>
        {showDropdown ? (
          <div className="dropdown">
            {dropdownKeysSummary.map(({ title, key }) => (
              <DropdownValue
                title={title}
                key={key}
                val={key}
                setHoveredEl={setHoveredEl}
                renderDropdown={renderDropdownFuncs[hoveredEl]}
                isHovered={isHovered(key)}
              />
            ))}
          </div>
        ) : null}
      </div>
    </Styles>
  );
};

export default ChangeViewFilter;
