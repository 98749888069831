import React, { useState, useEffect, useRef, useMemo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_rounded from "highcharts-rounded-corners";
import moment from "moment";
import _ from "lodash";

import LoaderBox from "components/LoaderBox";

import { STATE_STATUSES } from "utils/statuses";
import useRetailers from "../../../../hooks/useRetailers";
import { changeNameRetailer } from "utils/changeNameRetailer";
import { currentColors, compareColors } from "utils/colorsCharts";

import { Cancel } from "assets/icons/icons";

HC_rounded(Highcharts);

const ComparisonChart = (props) => {
  const inputRef = useRef(null);

  const { currentData, names, compareData, isCompare, status, currentRetailer, setSelectedRetailer, selectedRetailer } =
    props;
  const namesArr = names.length ? names.slice(1) : [];

  const [pricings, setPricings] = useState([]);
  const { getRemoteLogo } = useRetailers();

  const setCurrentData = () => {
    if (currentData.length) {
      const pricings = currentData
        .map((item) => ({
          order: 2,
          name: item.name,
          borderRadiusTopLeft: item.name === "moreExpensive" ? "30%" : "0",
          borderRadiusTopRight: item.name === "moreExpensive" ? "30%" : "0",
          title: item.title,
          color: currentColors[item.name],
          date: item.date,
          stack: "current",
          data: item.data.map((el, i) => {
            const name = namesArr[i];

            return {
              y: el,
              name,
              className:
                !!selectedRetailer && name !== selectedRetailer
                  ? "not-hightlighted"
                  : !!selectedRetailer && name === selectedRetailer
                  ? "hightlighted"
                  : null,
            };
          }),
        }))
        .reverse();

      setPricings(pricings);
    }
  };

  useEffect(() => {
    setCurrentData();
  }, [currentData, names, selectedRetailer]);

  useEffect(() => {
    if (!isCompare) {
      setCurrentData();
    }
  }, [isCompare]);

  useEffect(() => {
    if (compareData.length) {
      const newData = compareData
        .map((item) => ({
          order: 1,
          name: item.name,
          borderRadiusTopLeft: item.name === "moreExpensive" ? "30%" : "0",
          borderRadiusTopRight: item.name === "moreExpensive" ? "30%" : "0",
          color: compareColors[item.name],
          date: item.date,
          stack: "compare",
          data: item.data.map((el, i) => {
            const name = namesArr[i];

            return {
              y: el,
              name,
              className:
                !!selectedRetailer && name !== selectedRetailer
                  ? "not-hightlighted"
                  : !!selectedRetailer && name === selectedRetailer
                  ? "hightlighted"
                  : null,
            };
          }),
        }))
        .reverse();

      setPricings((prevState) => [...prevState, ...newData]);
    }
  }, [compareData, names, selectedRetailer]);

  const getPricings = useMemo(
    () => _.cloneDeep(pricings).sort((first, second) => first.order - second.order),
    [pricings, selectedRetailer]
  );

  const options = {
    title: {
      text: "",
    },
    chart: {
      type: "bar",
      inverted: true,
      animation: false,
      style: {
        fontFamily: "Gilroy-Medium",
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: null,
      borderWidth: 0,
      formatter: function () {
        let initialData = this.points.reverse().map((item) => {
          if (item.series.options.stack === "current") {
            return `<div class='wrapper'>
                                ${
                                  !isCompare
                                    ? `
                                        <div class='box'>
                                            <div class='color' style='background: ${item.color}' ></div>
                                            <div class='name'>${item.series.options.title}</div>
                                        </div>
                                    `
                                    : ""
                                }
                                <div class='price'>${item.y}</div>
                            </div>`;
          }
        });
        initialData = initialData.join("");

        let dataCompare = this.points.map((item) => {
          if (item.series.options.stack === "compare") {
            return `<div class='wrapper'>
                                <div class='box'>
                                    <div class='color' style='background: ${
                                      currentColors[item.series.options.name]
                                    }' ></div>
                                    <div class='name'>${item.series.options.title}</div>
                                </div>
                                <div class='price'>${item.y}</div>
                            </div>`;
          }
        });
        dataCompare = dataCompare.join("");

        return `<div class='wrapper-category'>
                        <div class='title'>${changeNameRetailer(this.points[0].key)}</div>
                        <div class='wrapper-box'>
                            ${
                              !isCompare
                                ? `
                                    <div>
                                        <div class='date'>${moment(
                                          this.points[this.points.length - 1].series.options.date
                                        ).format("DD MMM YYYY")}</div>
                                        <div>
                                            ${initialData}
                                        </div>
                                    </div>
                                `
                                : `
                                    <div>
                                        <div class='date'>${moment(this.points[0].series.options.date).format(
                                          "DD MMM YYYY"
                                        )}</div>
                                        <div>
                                            ${dataCompare}
                                        </div>
                                    </div>
                                    <div class='line-separete'></div>
                                    <div>
                                        <div class='date'>${moment(
                                          this.points[this.points.length - 1].series.options.date
                                        ).format("DD MMM YYYY")}</div>
                                        <div>
                                            ${initialData}
                                        </div>
                                    </div>
                                `
                            }
                        </div>
                    </div>`;
      },
    },
    xAxis: {
      title: {
        enabled: false,
        text: "",
      },
      labels: {
        useHTML: true,
        formatter: function () {
          return `<img style="width: 25px; height: 25px;" src="${getRemoteLogo(namesArr[this.value])}" />`;
        },
      },
    },
    yAxis: {
      labels: {
        step: 5,
      },
      title: {
        text: "",
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        cursor: "pointer",
        stacking: "normal",
        pointPadding: 0.2,
        states: {
          inactive: {
            opacity: 1,
          },
        },
        point: {
          events: {
            click: function () {
              setSelectedRetailer(namesArr[this.category]);
            },
          },
        },
        // borderWidth: 0,
      },
      bar: {
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    series: getPricings,
  };

  const renderSelectedRetailer = () => {
    if (!!selectedRetailer) {
      return (
        <div className="filter" onClick={() => setSelectedRetailer("")}>
          <span className="filter-title">{selectedRetailer}</span>
          <Cancel fill={"#fff"} />
        </div>
      );
    }
  };

  return (
    <div className="wrapper-box-relative">
      <div className="title-wrapper" style={{ marginBottom: !!selectedRetailer ? null : 42 }}>
        <div className="chart-title-desc">
          Comparing against <span className="cur-retailer">{changeNameRetailer(currentRetailer)}.</span>
        </div>
        {renderSelectedRetailer()}
      </div>
      <HighchartsReact ref={inputRef} highcharts={Highcharts} options={options} />
      {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
    </div>
  );
};

export default ComparisonChart;
