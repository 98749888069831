import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as FileSaver from "file-saver";
import { Pagination } from "antd";
import useColorTheme from "../../hooks/useColorTheme";
import moment from "moment";

import { fetchDownloadReportsQueue, downloadReportByKey } from "store/exports/actions";
import { STATE_STATUSES } from "utils/statuses";

import LoaderBox from "components/LoaderBox";
import SelectBox from "components/Selects/SelectBox";
import exportIcn from "assets/images/export.svg";

const selects = [
  {
    name: "perPage",
    default: "10",
    options: [
      { lable: "10", value: "10" },
      { lable: "15", value: "15" },
      { lable: "20", value: "20" },
    ],
  },
];

const Report = ({ item }) => {
  const dispatch = useDispatch();
  const { primaryColor } = useColorTheme();

  const downloadReport = () => {
    dispatch(downloadReportByKey(item.key)).then((response) => {
        if (response.data && response.data.file) {
            let blob = new Blob([Buffer.from(response.data.file, 'base64')], {
                type: "vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
            });
            FileSaver.saveAs(blob, `${item.label}.xlsx`);
        }
    })
  };

  return (
    <div className="report-row">
      <div style={{ width: "280px", fontFamily: "Gilroy-ExtraBold" }}>{item.label}</div>
      <div style={{ width: "140px", textTransform: "capitalize", textAlign: "left", fontFamily: "Gilroy-Medium" }}>
        {moment(item.date).format('HH:mm:ss')}
      </div>
      <div style={{ width: "140px", textTransform: "capitalize", textAlign: "left" , fontFamily: "Gilroy-Medium" }}>
        {['downloaded', 'acknowledged'].includes(item.status) ? 'downloaded' : item.status}
      </div>
      <div className="report-options">
        <div className={`export-btn ${['submitted', 'processing', 'suspended'].includes(item.status) ? 'disabled-btn' : '' }`} style={{ background: primaryColor }} onClick={['submitted', 'processing', 'suspended'].includes(item.status) ? () => {} : downloadReport}>
          <img src={exportIcn} alt='download' />
        </div>
      </div>
    </div>
  );
};

const DownloadReports = () => {
  const dispatch = useDispatch();

  const { downloadReports, status } = useSelector((state) => state.exports);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [reportId, setReportId] = useState(null);

  useEffect(() => {
    dispatch(fetchDownloadReportsQueue());
  }, [dispatch]);

  const limit = page * perPage + perPage < downloadReports.length ? page * perPage + perPage : downloadReports.length;

  const renderData = downloadReports.sort((a,b) => a.date > b.date ? -1 : 1).slice(page * perPage, limit);

  const onChangePage = (page, pageSize) => {
    setPage(page - 1);
  };

  const onChangeSize = (value) => {
    setPerPage(Number(value["perPage"]));
  };

  return (
    <div style={{ position: "relative", minHeight: "120px" }}>
      {downloadReports.length ? (
        <div className="export-tab">
          {(
            <>
              <div className="export-table-header" style={{ marginTop: "45px" }}>
                <div className="export-column" style={{ width: "258px" }}>
                  Report Name
                </div>
                <div className="export-column" style={{ width: "118px" }}>
                  Submitted Time
                </div>
                <div className="export-column" style={{ width: "120px", textAlign: "left" }}>
                  Status
                </div>
                <div className="export-column" style={{ width: "240px", textAlign: "right", paddingRight: "10px" }}>
                  Download
                </div>
              </div>
              <div className="export-table-body">
                {renderData.map((item, index) => (
                  <Report key={index} item={item} setReportId={setReportId} />
                ))}
              </div>
              <div className="pagimation-wrapper">
                <Pagination
                  style={{ position: "static" }}
                  className="pagination-controls"
                  total={downloadReports.length}
                  showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
                  pageSize={perPage}
                  current={page + 1}
                  onChange={onChangePage}
                />
                {selects.map((item, index) => (
                  <div className="per-page-box" key={index}>
                    <span>Show</span>
                    <SelectBox key={index} data={item} setSelectValue={onChangeSize} />
                    <span style={{ marginLeft: "15px" }}>Per Page</span>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      ) : (
        <>
          {status === STATE_STATUSES.READY ? (
            <div className="chart-no-data static">No Downloaded Reports For Today</div>
          ) : null}
        </>
      )}

      {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
    </div>
  );
};

export default DownloadReports;
