import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { defaultFlag } from "../utils/getImage";
import { updateCountry, resetTitleDate } from "../store/filters/actions";
import { requestAuthMe } from "../store/authorization/actions";
import { setCurrentCountry } from "../store/settings/settingsYourProfile/actions";
import { getTimePeriod } from "../utils/getTimePeriod";
import useFilters from "./useFilters";
import { STATE_STATUSES } from "utils/statuses";

const defaultCountry = {
  countryId: 1,
  symbol: "£",
  currencyName: "Pound sterling",
  currencyIso: "GBP",
  countryName: "United Kingdom",
  iso: "GB",
  iso3: "GBR",
  countryFlag: defaultFlag,
};

export const NUMBER_TYPE = {
  DECIMAL: "decimal",
  CURRENCY: "currency",
};

export default function useCountry() {
  const { countryId, country, countries, company } = useSelector(
    (state) => state.settingsYourProfile.settingsYourProfile
  );
  const { country: changedCountry } = useSelector((state) => state.authorization);
  const { filters, status, countryStatus } = useSelector((state) => state.filters);
  const [updateFilters, setUpdateFilters] = useState(null);
  const dispatch = useDispatch();
  const { resetUniversalObj, mainLastFilter: lastFilter } = useFilters();

  const finalisedStatus = useMemo(
    () => (countryStatus === STATE_STATUSES.INIT ? status : countryStatus),
    [status, countryStatus]
  );

  useEffect(() => {
    if (changedCountry?.currency) dispatch(setCurrentCountry(changedCountry));
  }, [changedCountry, dispatch]);

  const getCountryFlag = useCallback(
    (countryISO) => {
      return (countries && countries.find((el) => el.iso === countryISO)?.avatar) || defaultCountry.countryFlag;
    },
    [countries]
  );

  const currentCurrency = useMemo(() => {
    return country?.currency?.symbol || defaultCountry.symbol;
  }, [country]);

  const currentCurrencyName = useMemo(() => {
    return country?.currency?.name || defaultCountry.currencyName;
  }, [country]);

  const currentCurrencyIso = useMemo(() => {
    return country?.currency?.iso || defaultCountry.currencyIso;
  }, [country]);

  const currentCountry = useMemo(() => {
    return {
      countryId,
      countryFlag: (country && country?.avatar) || defaultCountry.countryFlag,
      countryName: country?.name || defaultCountry.countryName,
      iso: country?.iso || defaultCountry.iso,
      iso3: country?.iso3 || defaultCountry.iso3,
    };
  }, [countryId, country]);

  const formatCurrencyNumber = useCallback(
    (number, style = "decimal") => {
      const prefixCurrency = style === "currency" ? `${currentCurrency} ` : "";
      if (style !== "currency") {
        return Number(number)?.toFixed(1);
      }
      return `${prefixCurrency}${new Intl.NumberFormat(undefined, {
        style: "decimal",
        minimumFractionDigits: 2,
        currency: currentCurrencyIso,
      }).format(number)}`;
    },
    [currentCurrencyIso, currentCurrency]
  );

  const setCountry = useCallback(
    (data) => {
      setUpdateFilters({
        ...lastFilter,
        ...data,
        ...{
          product: ["All"],
          productGroup: "",
          productBrand: ["All"],
          category: ["All"],
          sourceType: ["All"],
          manufacture: company.manufacturer.map(({ id }) => `${id}`).join("|"),
          date: "4",
          timePeriod: getTimePeriod("4"),
        },
      });
    },
    [company, lastFilter, setUpdateFilters]
  );

  useEffect(() => {
    if (updateFilters) {
      setUpdateFilters(null);
      resetUniversalObj(updateFilters.countryId, company.id);
      dispatch(resetTitleDate());
      dispatch(updateCountry(updateFilters)).then(() => {
        dispatch(requestAuthMe());
      });
    }
  }, [dispatch, resetUniversalObj, updateFilters, company.id]);

  return {
    countryFilters: filters,
    currentCurrency,
    currentCurrencyName,
    currentCountry,
    countryOptions: countries,
    countryStatus: finalisedStatus,
    getCountryFlag,
    setCountry,
    formatCurrencyNumber,
    changeCountryFilters: updateFilters,
  };
}
