const url = process.env.REACT_APP_URL;

export const FETCH_BENCHMARK_CONTENT = "FETCH_BENCHMARK_CONTENT";
export const fetchBenchmarkContent = (params) => ({
  type: FETCH_BENCHMARK_CONTENT,
  request: {
    url: `${url}/v2/content/benchmark`,
    method: "POST",
    data: params,
  },
});

export const SET_PRISTINE_BENCHMARK_CONTENT = "SET_PRISTINE_BENCHMARK_CONTENT";
export const setPristineBenchmarkContent = (pristine) => {
  return {
    type: SET_PRISTINE_BENCHMARK_CONTENT,
    payload: pristine,
  };
};
