import { success, error } from "redux-saga-requests";
import {
  FETCH_AVAILABILITY_SUMMARY,
  SET_PRISTINE_AVAILABILITY_SUMMARY,
  SET_AVAILABILITY_SUMMARY_FILTER,
  FETCH_AVAILABILITY_SUMMARY_COMPARE,
  SET_AVAILABILITY_SUMMARY_COMPARE,
  ADD_TO_DATA_AVAILABILITY_SUMMARY,
  ADD_TO_DATA_AVAILABILITY_SUMMARY_COMPARE,
} from "./actions";
import { STATE_STATUSES } from "utils/statuses";
import { RESET_ERROR } from "store/error/actions";

const defaultFilter = [
  {
    name: "availability",

    value: "availability",
  },
  { name: "rangeValues", value: [0, 100] },
  {
    name: "byRetailer",

    value: "retailer",
  },
];

const initialState = {
  availabilitySummary: { success: false, row: [] },
  comparativeValues: { success: false, row: [] },
  controlData: { success: false, row: [] },
  controlDataCompare: { success: false, row: [] },

  status: STATE_STATUSES.INIT,
  isPristine: true,
  filter: localStorage.getItem(SET_AVAILABILITY_SUMMARY_FILTER)
    ? JSON.parse(localStorage.getItem(SET_AVAILABILITY_SUMMARY_FILTER))
    : defaultFilter,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_AVAILABILITY_SUMMARY:
    case FETCH_AVAILABILITY_SUMMARY_COMPARE: {
      return processReducer(state);
    }

    case success(FETCH_AVAILABILITY_SUMMARY): {
      return {
        ...state,
        controlData: { success: true, row: action.data },
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }
    case success(FETCH_AVAILABILITY_SUMMARY_COMPARE): {
      return {
        ...state,
        controlDataCompare: { success: true, row: action.data },
        status: STATE_STATUSES.READY,
      };
    }

    case ADD_TO_DATA_AVAILABILITY_SUMMARY: {
      return {
        ...state,
        availabilitySummary: {
          ...state.availabilitySummary,
          row: action.payload,
        },
      };
    }

    case ADD_TO_DATA_AVAILABILITY_SUMMARY_COMPARE: {
      return {
        ...state,
        comparativeValues: {
          ...state.comparativeValues,
          row: action.payload,
        },
      };
    }

    case SET_PRISTINE_AVAILABILITY_SUMMARY: {
      return {
        ...state,
        isPristine: action.payload,
      };
    }
    case SET_AVAILABILITY_SUMMARY_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    case SET_AVAILABILITY_SUMMARY_COMPARE: {
      return {
        ...state,
        comparativeValues: { success: true, row: [] },
      };
    }

    case error(FETCH_AVAILABILITY_SUMMARY):
    case error(FETCH_AVAILABILITY_SUMMARY_COMPARE): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
