import React from "react";
import MultiSelectBox from "components/Selects/MultiSelectBox";
import NumberRangeFilter from "components/Filters/NumberRangeFilter/NumberRangeFilter";
import { filtersForRatingSummaryBottom } from "constants/filters";
import { STATE_STATUSES } from "utils/statuses";

const Filters = ({
  bottomFilter,
  setSelectValue,
  status,
  minMaxValues,
  setIsCountSliderTouched,
  setIsDaysSliderTouched,
}) => {
  return (
    <div className="main-box-filters">
      <div className="filters-box" style={{ top: 0, right: 0 }}>
        {filtersForRatingSummaryBottom.map((item, index) => (
          <div key={index}>
            {item?.options ? (
              <MultiSelectBox
                data={item}
                label={item.label}
                setSelectValue={setSelectValue}
                disabled={status !== STATE_STATUSES.READY}
                predefinedValues={bottomFilter[index].value}
              />
            ) : (
              <NumberRangeFilter
                title={item.title}
                hint={item.hint}
                label={item.label}
                data={item}
                value={bottomFilter[index].value}
                isTouchedHandler={
                  index === 1 ? () => setIsCountSliderTouched(true) : () => setIsDaysSliderTouched(true)
                }
                setSelectValue={setSelectValue}
                minMaxValues={minMaxValues[index - 1]}
                disabled={status !== STATE_STATUSES.READY || bottomFilter[0].value.includes("0")}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Filters;
