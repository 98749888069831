const url = process.env.REACT_APP_URL;

export const FETCH_AVAILABILITY_SUMMARY = "FETCH_AVAILABILITY_SUMMARY";
export const fetchAvailabilitySummary = (params) => ({
  type: FETCH_AVAILABILITY_SUMMARY,
  request: {
    url: `${url}/v2/availability/summary`,
    method: "POST",
    data: params,
  },
});

export const ADD_TO_DATA_AVAILABILITY_SUMMARY = "ADD_TO_DATA_AVAILABILITY_SUMMARY";
export const addToDataAvailabilitySummary = (row) => {
  return {
    type: ADD_TO_DATA_AVAILABILITY_SUMMARY,
    payload: row,
  };
};

export const ADD_TO_DATA_AVAILABILITY_SUMMARY_COMPARE = "ADD_TO_DATA_AVAILABILITY_SUMMARY_COMPARE";
export const addToDataAvailabilitySummaryCompare = (row) => {
  return {
    type: ADD_TO_DATA_AVAILABILITY_SUMMARY_COMPARE,
    payload: row,
  };
};

export const FETCH_AVAILABILITY_SUMMARY_COMPARE = "FETCH_AVAILABILITY_SUMMARY_COMPARE";
export const fetchAvailabilitySummaryCompare = (params) => ({
  type: FETCH_AVAILABILITY_SUMMARY_COMPARE,
  request: {
    url: `${url}/v2/availability/summary`,
    method: "POST",
    data: params,
  },
});

export const SET_AVAILABILITY_SUMMARY_COMPARE = "SET_AVAILABILITY_SUMMARY_COMPARE";
export const setAvailabilitySummaryCompare = (params) => ({
  type: SET_AVAILABILITY_SUMMARY_COMPARE,
  request: {
    url: `${url}/v2/availability/summary`,
    method: "POST",
    data: params,
  },
});

export const SET_PRISTINE_AVAILABILITY_SUMMARY = "SET_PRISTINE_AVAILABILITY_SUMMARY";
export const setPristineAvailabilitySummary = (pristine) => {
  return {
    type: SET_PRISTINE_AVAILABILITY_SUMMARY,
    payload: pristine,
  };
};

export const SET_AVAILABILITY_SUMMARY_FILTER = "SET_AVAILABILITY_SUMMARY_FILTER";
export const setAvailabilitySummaryFilter = (filter) => {
  localStorage.setItem(SET_AVAILABILITY_SUMMARY_FILTER, JSON.stringify(filter));
  return {
    type: SET_AVAILABILITY_SUMMARY_FILTER,
    payload: filter,
  };
};
