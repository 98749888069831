import styled from "styled-components";

export const Styles = styled.div`
  position: relative;
  min-height: 280px;

  .productGroup .ant-btn {
    width: 288px;
    height: 44px;
  }

  .productGroup {
    .select-container {
      display: flex;
      flex-wrap: wrap;
      margin: 30px 0 40px;
      .select {
        margin-right: 15px;
        .ant-input {
          border-radius: 2px;
          border: none;
          outline: none;
          box-shadow: none;
          font-family: "Gilroy-Medium";
          width: 288px;
          height: 44px;
          border-radius: 2px;
          background-color: #eef2f7;
          font-size: 14px;
          padding: 0 20px;
        }
        .ant-input::placeholder {
          color: #333333;
          opacity: 0.65;
        }
        p {
          margin-bottom: 8px;
          font-size: 12px;
          font-family: "Gilroy-ExtraBold";
        }
      }
    }
    .ant-btn {
      font-family: "Gilroy-ExtraBold";
    }
  }

  .text-container {
    max-width: 100%;
    width: 100%;
    font-size: 14px;
    font-family: "Gilroy-Medium";
  }
  .save-button {
    width: 136px !important;
  }
`;
