import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import { fetchAlertFilters } from "store/alerts/actions";
import { STATE_STATUSES } from "utils/statuses";

import ItemAlert from "components/Alerts/ItemAlert";
import RenderNoData from "components/RenderNoData";
import SelectBox from "components/Selects/SelectBox";

import LoaderBox from "components/LoaderBox";

import search from "assets/images/search.svg";

const selects = [
  {
    name: "perPage",
    default: "10",
    options: [
      { lable: "10", value: "10" },
      { lable: "15", value: "15" },
      { lable: "20", value: "20" },
    ],
  },
];

const ListAlert = () => {
  const dispatch = useDispatch();

  const { alerts, status } = useSelector((state) => state.alerts);

  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    dispatch(
      fetchAlertFilters({
        sourceType: "",
        productBrand: "",
        category: "",
        manufacture: "",
        product: "",
        save: false,
      })
    );
  }, [dispatch]);

  const onSearch = (data) =>
    data.filter((item) => {
      if (searchValue) {
        return item["name"].toLowerCase().includes(searchValue.toLowerCase());
      }
      return item;
    });

  const limit = page * perPage + perPage < onSearch(alerts).length ? page * perPage + perPage : onSearch(alerts).length;

  const renderData = onSearch(alerts).slice(page * perPage, limit);

  useEffect(() => {
    setPage(0);
  }, [searchValue]);

  const onChangePage = (page, pageSize) => {
    setPage(page - 1);
  };

  const onChangeSize = (value) => {
    setPerPage(Number(value["perPage"]));
  };

  return (
    <>
      <div className="main-title-page">Alerts</div>
      <div className="main-tabs-box">
        <div className="main-box">
          <div style={{ minHeight: "auto" }} className="wrapper-box-relative">
            <div className="alerts-controls-box">
              {alerts?.length ? (
                <div className="search-location">
                  <img src={search} alt="search" />
                  <input
                    placeholder="Search for an Alert Name"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </div>
              ) : null}

              <Link className="create-btn" to={`/create-alert`}>
                Create a New Nudge
              </Link>
            </div>
            {alerts.length ? (
              <div style={{ marginTop: "80px" }}>
                {renderData.length ? (
                  <>
                    {renderData.map((item) => (
                      <ItemAlert key={item.id} alert={item} />
                    ))}
                    {onSearch(alerts).length > perPage ? (
                      <div className="pagimation-wrapper">
                        <Pagination
                          style={{ position: "static" }}
                          className="pagination-controls"
                          total={onSearch(alerts).length}
                          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
                          pageSize={perPage}
                          current={page + 1}
                          onChange={onChangePage}
                        />
                        {onSearch(alerts).length >= 20
                          ? selects.map((item, index) => (
                              <div className="per-page-box" key={index}>
                                <span>Show</span>
                                <SelectBox key={index} data={item} setSelectValue={onChangeSize} />
                                <span style={{ marginLeft: "15px" }}>Per Page</span>
                              </div>
                            ))
                          : null}
                      </div>
                    ) : null}
                  </>
                ) : (
                  <RenderNoData style={{ height: 120 }} />
                )}
              </div>
            ) : // <RenderNoData style={{ height: 120 }} />
            null}
          </div>
          {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
        </div>
      </div>
    </>
  );
};

export default ListAlert;
