import { success, error } from "redux-saga-requests";
import { FETCH_RANKING_LOCATION_OVERVIEW, SET_PRISTINE_OVERVIEW_LOCATION } from "./actions";
import { RESET_ERROR } from "store/error/actions";

import { STATE_STATUSES } from "utils/statuses";

const initialState = {
  rankingLocationOverview: {},
  status: STATE_STATUSES.INIT,
  isPristine: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RANKING_LOCATION_OVERVIEW: {
      return processReducer(state);
    }
    case success(FETCH_RANKING_LOCATION_OVERVIEW): {
      return {
        ...state,
        rankingLocationOverview: action.data,
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }
    case error(FETCH_RANKING_LOCATION_OVERVIEW): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case SET_PRISTINE_OVERVIEW_LOCATION: {
      return {
        ...state,
        isPristine: action.payload,
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
