import React, { useState } from "react";
import { connect } from "react-redux";
import { Popover, Tooltip } from "antd";
import { fetchCurrentPricePopup } from "store/pricing/currentPrices/actions";
import { replaceNbsps } from "utils/generalUtils";

import LoaderBox from "components/LoaderBox";
import PopupPrice from "./PopupPrice";

import { STATE_STATUSES } from "utils/statuses";
import useRetailers from "../../../../hooks/useRetailers";
import useCountry, { NUMBER_TYPE } from "../../../../hooks/useCountry";
import { roundingNumbers } from "utils/roundingNumbers";

import green from "assets/images/green.svg";
import red from "assets/images/red.svg";
import star from "assets/images/star.svg";
import marketplace from "assets/images/marketplace.svg";
import promotions from "assets/images/promotions.svg";
import pricematch from "assets/images/pricematch.svg";
import pricelock from "assets/images/padlock.svg";
import sort from "assets/images/sort.svg";
import sortIconDown from "assets/images/sort_down.svg";
import sortIconUp from "assets/images/sort_up.svg";
import search from "assets/images/search.svg";
const WORDS_PER_LINE = 12;
const MAX_WORD_COUNT = 75;
const PricesTable = (props) => {
  const {
    status,
    data,
    sortName,
    sortId,
    sortDirection,
    handleSearch,
    handleSort,
    handleSortByName,
    fetchCurrentPricePopup,
    timePeriod,
    popupPrices,
  } = props;

  const { formatCurrencyNumber } = useCountry();
  const [showPopup, setShowPopup] = useState(false);
  const { getRemoteLogo } = useRetailers();

  const [onPromotion, setOnPromotion] = useState(false);
  const [isPriceMatch, setIsPriceMatch] = useState(false);
  const [priceMatchDesc, setPriceMatchDesc] = useState("");
  const [isMarketplace, setIsMarketplace] = useState(false);
  const [marketplaceDesc, setMarketplaceDesc] = useState("");
  const [isPriceLock, setIsPriceLock] = useState(false);

  const handleFetchCurrentPricePopup = (coreProductId, retailerId, timePeriod, status) => {
    if (status !== "not_listed") {
      fetchCurrentPricePopup(coreProductId, retailerId, timePeriod).then(() => setShowPopup(true));
    }
  };

  return (
    <>
      <div className="current-table-box">
        <div className="wrapper-table-box">
          <div className="wrapper-table">
            <div className="box-wrapper">
              <div className="title">
                <div className="search">
                  <img className="img-search" src={search} alt="search" />
                  <input onChange={(e) => handleSearch(e.target.value)} placeholder="Search for a Product" />
                  <img
                    style={{ cursor: "pointer" }}
                    src={sortName?.length ? (sortName === "asc" ? sortIconUp : sortIconDown) : sort}
                    alt="sort"
                    onClick={handleSortByName}
                  />
                </div>
              </div>
              <div className="wrapper">
                {data.retailers.map((item, index) => (
                  <div key={index} className="table-item sort clickable-item" onClick={() => handleSort(item.id)}
                    style={{ width: 230 }} // Use this inline style to disable the media query
                  >
                    <img src={getRemoteLogo(item.name)} alt="retailer" />
                    <img src={sortId === item.id ? (sortDirection ? sortIconDown : sortIconUp) : sort} alt="sort" />
                  </div>
                ))}
              </div>
            </div>
          </div>
          {data.averagePrices.length ? (
            <div className="wrapper-table">
              <div className="box-wrapper">
                <div className="title">
                  <span className="averagePrice">Average Price</span>
                </div>
                <div className="wrapper">
                  {data.averagePrices.length ? (
                    data.averagePrices.map((item, index) => (
                      <div
                        className={`table-item ${item.bestPrice ? "bestPrice" : ""} ${
                          item.highestPrice ? "highestPrice" : ""
                        }`}
                        key={index}
                        style={{ width: 230 }} // Use this inline style to disable the media query
                      >
                        {item.bestPrice ? <span className="hint-price best">Best Price</span> : null}
                        {item.highestPrice ? <span className="hint-price hight">Highest Price</span> : null}
                        {!item.highestPrice && !item.bestPrice ? <span className="hint-price hight">&nbsp;</span> : null}
                        <span className="price">
                          {formatCurrencyNumber(roundingNumbers(item.price), NUMBER_TYPE.CURRENCY)}
                        </span>
                        <div className="item">
                          {item.star ? (
                            <img className="star" src={promotions} alt="star" style={{ width: 17, marginLeft: 1, marginRight: 1 }} />
                          ) : (
                            <div className="box-empty"></div>
                          )}
                          <span>{Number(item.percent) ? item.percent.toFixed(1) : 0}%</span>
                          {item.percent !== 0 ? (
                            <img className="direction" src={item.direction ? green : red} alt="direction" />
                          ) : (
                            <div className="box-empty"></div>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-listed">NOT LISTED</div>
                  )}
                </div>
              </div>

              <div className="wrapper-box-relative">
                {data.products.map((data, index) => {
                  const titleLines = data.title.split(" ").reduce((acc, item, i) => {
                    return i % WORDS_PER_LINE === 0
                      ? [...acc, `${item}`]
                      : [...(acc.length > 0 ? acc.slice(0, -1) : []), `${acc.slice(-1)[0] + " " + item}`];
                  }, []);
                  const content = (
                    <div className="popup-wrapper">
                      {titleLines.map((line, index) => (
                        <div
                          style={{
                            fontFamily: "Gilroy-Medium",
                            width: "310px",
                            fontSize: "14px",
                            fontWeight: "500",
                          }}
                          key={index}
                        >
                          <span>{line}</span>
                        </div>
                      ))}
                    </div>
                  );
                  const title = (
                    <div
                      style={{
                        fontFamily: "Gilroy-ExtraBold",
                        width: "310px",
                        fontSize: "14px",
                      }}
                    >
                      Product Title
                    </div>
                  );
                  const htmlTitle = replaceNbsps(data.title);
                  return (
                    <div className="box-wrapper" key={index}>
                      <Popover
                        title={title}
                        trigger="hover"
                        align={{ offset: [-25, 15] }}
                        key={index}
                        placement="rightTop"
                        content={content}
                      >
                        <div className="title">
                          <img className="image" src={data.image ? data.image : data.retailerImage} alt="banner" />
                          <span>
                            {htmlTitle.length > MAX_WORD_COUNT
                              ? `${htmlTitle.substring(0, MAX_WORD_COUNT)} ...`
                              : htmlTitle}
                          </span>
                        </div>
                      </Popover>
                      <div className="wrapper">
                        {data.prices.map((item, index) => (
                          <div
                            key={index}
                            className={`table-item ${item.status !== "not_listed" ? "clickable-item" : ""} ${
                              item.bestPrice ? "bestPrice" : ""
                            } ${item.highestPrice ? "highestPrice" : ""}`}
                            onClick={() => {
                              handleFetchCurrentPricePopup(data.id, item.retailerId, timePeriod, item.status);
                              setOnPromotion(item.star);
                              setIsPriceMatch(item.showPriceMatch);
                              setPriceMatchDesc(item?.priceMatchDescription);
                              setIsMarketplace(item.showMarketPlace);
                              setMarketplaceDesc(item?.marketplaceData?.seller);
                              setIsPriceLock(item.showPriceLock);
                            }}
                            style={{ width: 230 }} // Use this inline style to disable the media query
                          >
                            {item.status !== "not_listed" ? (
                              <>
                                {item.bestPrice ? <span className="hint-price best">Best Price</span> : null}
                                {item.highestPrice ? <span className="hint-price hight">Highest Price</span> : null}
                                {!item.highestPrice && !item.bestPrice ? <span className="hint-price hight">&nbsp;</span> : null}
                                <span className="price">
                                  {formatCurrencyNumber(roundingNumbers(item.price), NUMBER_TYPE.CURRENCY)}
                                </span>
                                <div className="item" style={{ position: "relative", justifyContent: "flex-start", width: 230, paddingLeft: 10 }}>
                                  <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                    {
                                      !!item.showMarketPlace ?
                                        (
                                          // <Tooltip title={<div style={{ color: "black" }}>{item?.marketplaceData?.seller || "Marketplace"}</div>} color="white">
                                          <Tooltip title={<div style={{ color: "black" }}>{"Marketplace Listing"}</div>} color="white">
                                            <img className="marketplace" src={marketplace} alt="marketplace" style={{ width: 17, marginLeft: 1, marginRight: 1 }} />
                                          </Tooltip>
                                        ) : <div className="box-empty" style={{ width: 19 }}></div>
                                    }
                                    {
                                      !!item.showPriceLock ?
                                        (
                                          <Tooltip title={<div style={{ color: "black" }}>{"Price Lock Observed"}</div>} color="white">
                                            <img className="priceLock" src={pricelock} alt="priceLock" style={{ width: 17, marginLeft: 1, marginRight: 1 }} />
                                          </Tooltip>
                                        ) : <div className="box-empty" style={{ width: 19 }}></div>
                                    }
                                    {
                                      !!item.showPriceMatch ?
                                        (
                                          <Tooltip title={<div style={{ color: "black" }}>{item?.priceMatchDescription || "Price Match"}</div>} color="white">
                                            <img className="pricematch" src={pricematch} alt="pricematch" style={{ width: 17, marginLeft: 1, marginRight: 1 }} />
                                          </Tooltip>
                                        ) : <div className="box-empty" style={{ width: 19 }}></div>
                                    }
                                    {
                                      !!item.star ?
                                        (
                                          <Tooltip title={<div style={{ color: "black" }}>Promotion Observed</div>} color="white">
                                            <img className="star" src={promotions} alt="star" style={{ width: 17, marginLeft: 1, marginRight: 1 }} />
                                          </Tooltip>
                                        ) : <div className="box-empty" style={{ width: 19 }}></div>
                                    }
                                  </div>
                                  <span>{Number(item.percent) ? `${item.percent.toFixed(1)}%` : "-"}</span>
                                  {item.percent !== 0 ? (
                                    <img className="direction" src={item.direction ? green : red} alt="direction" />
                                  ) : (
                                    <div className="box-empty"></div>
                                  )}
                                </div>
                                {item.status === "newly" ? <div className="not-listed">NEW</div> : null}
                                {item.status === "re-listed" ? <div className="not-listed">RESEEN</div> : null}
                                {item.status === "de-listed" ? <div className="not-listed">NOT SEEN</div> : null}
                              </>
                            ) : (
                              <div className="not-listed">NOT LISTED</div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                })}
              </div>
              {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
            </div>
          ) : (
            <div className="chart-no-data static">Looks like we don't have data for this request</div>
          )}
        </div>
      </div>
      {showPopup ? <PopupPrice data={popupPrices} closePopup={() => setShowPopup(false)} onPromotion={onPromotion}
        isPriceMatch={isPriceMatch} priceMatchDesc={priceMatchDesc}
        isMarketplace={isMarketplace} marketplaceDesc={marketplaceDesc}
        isPriceLock={isPriceLock}
      /> : null}
    </>
  );
};

export default connect(
  (state) => ({
    popupPrices: state.currentPrices.popupPrices,
    status: state.currentPrices.status,
  }),
  { fetchCurrentPricePopup }
)(PricesTable);
