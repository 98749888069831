import React, { useState, useEffect, useMemo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";

import ChartLegends from "components/ChartLegends";
import RenderNoData from "components/RenderNoData";

import { roundingNumbers } from "utils/roundingNumbers";
import { changeNameRetailer } from "utils/changeNameRetailer";
import useRetailers from "../../../../hooks/useRetailers";
import { STATE_STATUSES } from "utils/statuses";
import useCountry, { NUMBER_TYPE } from "../../../../hooks/useCountry";

const MAX_ITEMS = 10;

const ChangeChart = (props) => {
  const {
    currentData,
    compareData,
    priceParam,
    type,
    isCompare,
    names,
    handleSetNames,
    autoSetNames,
    manyData,
    setManyData,
    status,
  } = props;
  const { formatCurrencyNumber } = useCountry();
  const [dataChart, setDataChart] = useState([{ data: [] }]);
  const [legends, setLegends] = useState([]);
  const { getRemoteLogo } = useRetailers();

  useEffect(() => {
    for (const chart of Highcharts.charts) {
      if (chart) {
        chart.reflow();
      }
    }
  }, [isCompare]);

  const getData = (name, order, array, colors) => {
    const arrayData = array.filter((item) => names[type].list.includes(item.name));

    return [
      {
        name,
        order,
        data: arrayData.map((item) => ({
          name: item.name,
          y: Number(item[priceParam]),
          color: Number(item[priceParam]) > 0 ? colors[0] : colors[1],
        })),
      },
    ];
  };

  useEffect(() => {
    const legends = currentData.map((item) => ({
      name: item.name,
      color: item.color,
      y: Number(item[priceParam]),
      id: item.id,
    }));
    setLegends(legends);

    if (MAX_ITEMS > currentData.length && names[type].pristine) {
      const names = currentData.map((item) => item.name);
      autoSetNames(type, names);
      setManyData(false);
    } else if (MAX_ITEMS < currentData.length && names[type].pristine) {
      autoSetNames(type, []);
      setManyData(true);
    }
  }, [currentData]);

  useEffect(() => {
    if (isCompare) {
      const dataCurrent = getData("current", 2, currentData, ["#1FC68D", "#FE6A68"]);
      const dataCompare = getData("compare", 1, compareData, ["#bbeddc", "#fed2d1"]);

      const data = [...dataCurrent, ...dataCompare];
      setDataChart(data);
    } else {
      const data = getData("current", 2, currentData, ["#1FC68D", "#FE6A68"]);
      setDataChart(data);
    }
  }, [currentData, compareData, priceParam, isCompare, names, priceParam]);

  const sortRetailer = () => {
    const retailers = dataChart[0].data.map((item) => item.name);
    return names[type].list.sort((a, b) => retailers.indexOf(a) - retailers.indexOf(b));
  };

  const getChartData = useMemo(() => {
    const clonePricing = dataChart
      .sort((first, second) => first.order - second.order)
      .map((item) => {
        return {
          ...item,
          data: type === "byRetailer" ? item.data : item.data.sort((a, b) => a["name"].localeCompare(b["name"])),
        };
      });

    return _.cloneDeep(clonePricing);
  }, [dataChart]);

  const options = {
    title: "",
    chart: {
      type: "column",
      reflow: true,
      height: 300,
      style: {
        fontFamily: "Gilroy-Medium",
      },
    },
    plotOptions: {
      series: {
        borderRadius: 5,
        color: "#1ec68d",
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      minTickInterval: 0.1,
      plotLines: [
        {
          value: 0,
          color: "#bfbfbf",
          width: 1,
          zIndex: 3,
        },
      ],
    },
    xAxis: {
      categories: type === "byRetailer" ? sortRetailer() : names[type].list.sort((a, b) => a.localeCompare(b)),
      labels: {
        enabled: type === "byRetailer" ? true : false,
        useHTML: true,
        formatter: function () {
          return `<img style="width: 25px;" src=${getRemoteLogo(this.value)}></img>`;
        },
      },
      style: {
        zIndex: 1,
      },
    },
    tooltip: {
      shared: true,
      backgroundColor: null,
      borderWidth: 0,
      useHTML: true,
      formatter: function () {
        return `<div class="wrapper-total"> <div class='wrapper'> ${
          this.points.length > 1
            ? `

<div class='box'>
<div class='retailer-title'>${changeNameRetailer(this.points[0].x)}</div>
                                <div class='retailer-price'>
                                    <div>
                                        <div class='total-data'>Previous Price Change</div>
                                        <div>${this.points[0].y < 0 ? "-" : ""}${formatCurrencyNumber(roundingNumbers(this.points[0].y),NUMBER_TYPE.CURRENCY)}</div>
</div>
<div class='line'></div>
<div>
<div class='total-data'>Current Price Change</div>
<div>${this.points[0].y < 0 ? "-" : ""}${formatCurrencyNumber(roundingNumbers(this.points[1].y),NUMBER_TYPE.CURRENCY)}</div>
                                    </div>
                                </div>
                            </div> 
                            `
            : `
                            <div class='box'>
                                <div class='retailer-title'>${changeNameRetailer(this.points[0].x)}</div>
<div>${this.points[0].y < 0 ? "-" : ""}${formatCurrencyNumber(roundingNumbers(this.points[0].y),NUMBER_TYPE.CURRENCY)}</div>
</div>
`
        } </div> </div>`;
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    series: getChartData,
  };

  return (
    <div className="chart-price-wrapper" style={{ width: `${isCompare ? "100%" : "calc(100% - 425px)"}` }}>
      <div style={{ height: "41px" }}></div>
      <div className="wrapper-chart-box">
        {!manyData && !dataChart[0].data.length && status !== STATE_STATUSES.PENDING ? (
          <RenderNoData style={{ position: "absolute", height: "96%", zIndex: "1" }} />
        ) : null}
        {manyData && status !== STATE_STATUSES.PENDING ? (
          <div className="chart-no-data opacity">
            Looks like we have a lot of data. <br /> Select the buttons below to see the graph.{" "}
          </div>
        ) : null}
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
      <ChartLegends
        param={"name"}
        sortByRetailer={type === "byRetailer" ? true : false}
        legends={legends}
        selectedLegends={names[type].list}
        legendLength={MAX_ITEMS}
        setLegends={(name) => handleSetNames(name, type)}
      />
    </div>
  );
};

export default ChangeChart;
