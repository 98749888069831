
import moment from 'moment'

const dateParams = {
    'previousPeriod': {
        //relative
        '1': `custom|${moment().subtract(2, 'd').format('YYYY-MM-DD')}|${moment().subtract(1, 'd').format('YYYY-MM-DD')}`,
        '2': `custom|${moment().subtract(3, 'd').format('YYYY-MM-DD')}|${moment().subtract(2, 'd').format('YYYY-MM-DD')}`,
        '3': `custom|${moment().subtract(14, 'd').format('YYYY-MM-DD')}|${moment().subtract(7, 'd').format('YYYY-MM-DD')}`,
        '4': `custom|${moment().subtract(28, 'd').format('YYYY-MM-DD')}|${moment().subtract(14, 'd').format('YYYY-MM-DD')}`,
        '5': `custom|${moment().subtract(60, 'd').format('YYYY-MM-DD')}|${moment().subtract(30, 'd').format('YYYY-MM-DD')}`,
        '6': `custom|${moment().subtract(180, 'd').format('YYYY-MM-DD')}|${moment().subtract(90, 'd').format('YYYY-MM-DD')}`,
        //monthly
        '14': `custom|${moment(`${moment().year()}-01-01`).format('YYYY-MM-DD')}|${moment(`${moment().year()}-01-31`).format('YYYY-MM-DD')}`,
        '13': `custom|${moment(`${moment().year()}-01-01`).format('YYYY-MM-DD')}|${moment(`${moment().year()}-01-31`).format('YYYY-MM-DD')}`,
        '12': `custom|${moment(`${moment().year()}-02-01`).format('YYYY-MM-DD')}|${moment(`${moment().year()}-02-28`).format('YYYY-MM-DD')}`,
        '10': `custom|${moment(`${moment().year()}-03-01`).format('YYYY-MM-DD')}|${moment(`${moment().year()}-03-31`).format('YYYY-MM-DD')}`,
        '9': `custom|${moment(`${moment().year()}-04-01`).format('YYYY-MM-DD')}|${moment(`${moment().year()}-04-30`).format('YYYY-MM-DD')}`,
        '8': `custom|${moment(`${moment().year()}-05-01`).format('YYYY-MM-DD')}|${moment(`${moment().year()}-05-31`).format('YYYY-MM-DD')}`,
        '7': `custom|${moment(`${moment().year()}-06-01`).format('YYYY-MM-DD')}|${moment(`${moment().year()}-06-30`).format('YYYY-MM-DD')}`,
        '19': `custom|${moment(`${moment().year()}-07-01`).format('YYYY-MM-DD')}|${moment(`${moment().year()}-07-31`).format('YYYY-MM-DD')}`,
        '18': `custom|${moment(`${moment().year()}-8-01`).format('YYYY-MM-DD')}|${moment(`${moment().year()}-8-31`).format('YYYY-MM-DD')}`,
        '17': `custom|${moment(`${moment().year()}-9-01`).format('YYYY-MM-DD')}|${moment(`${moment().year()}-9-30`).format('YYYY-MM-DD')}`,
        '16': `custom|${moment(`${moment().year()}-10-01`).format('YYYY-MM-DD')}|${moment(`${moment().year()}-10-31`).format('YYYY-MM-DD')}`,
        '15': `custom|${moment(`${moment().year()}-11-01`).format('YYYY-MM-DD')}|${moment(`${moment().year()}-11-30`).format('YYYY-MM-DD')}`,
        //yearly
        '20': `custom|${moment(`2018-01-01`).format('YYYY-MM-DD')}|${moment(`2018-12-31`).format('YYYY-MM-DD')}`,
        '21': `custom|${moment(`2019-01-01`).format('YYYY-MM-DD')}|${moment(`2019-12-31`).format('YYYY-MM-DD')}`,
    },
    'lastYearPeriod': {
        //relative
        '1': `custom|${moment().subtract(1, 'y').format('YYYY-MM-DD')}|${moment().subtract(1, 'y').format('YYYY-MM-DD')}`,
        '2': `custom|${moment().subtract(1, 'y').subtract(1, 'd').format('YYYY-MM-DD')}|${moment().subtract(1, 'y').format('YYYY-MM-DD')}`,
        '3': `custom|${moment().subtract(1, 'y').subtract(7, 'd').format('YYYY-MM-DD')}|${moment().subtract(1, 'y').format('YYYY-MM-DD')}`,
        '4': `custom|${moment().subtract(1, 'y').subtract(14, 'd').format('YYYY-MM-DD')}|${moment().subtract(1, 'y').format('YYYY-MM-DD')}`,
        '5': `custom|${moment().subtract(1, 'y').subtract(30, 'd').format('YYYY-MM-DD')}|${moment().subtract(1, 'y').format('YYYY-MM-DD')}`,
        '6': `custom|${moment().subtract(1, 'y').subtract(90, 'd').format('YYYY-MM-DD')}|${moment().subtract(1, 'y').format('YYYY-MM-DD')}`,
        //monthly
        '14': `custom|${moment(`${moment().year()}-01-01`).subtract(1,'y').format('YYYY-MM-DD')}|${moment(`${moment().year()}-01-31`).subtract(1,'y').format('YYYY-MM-DD')}`,
        '13': `custom|${moment(`${moment().year()}-02-01`).subtract(1,'y').format('YYYY-MM-DD')}|${moment(`${moment().year()}-02-28`).subtract(1,'y').format('YYYY-MM-DD')}`,
        '12': `custom|${moment(`${moment().year()}-03-01`).subtract(1,'y').format('YYYY-MM-DD')}|${moment(`${moment().year()}-03-31`).subtract(1,'y').format('YYYY-MM-DD')}`,
        '10': `custom|${moment(`${moment().year()}-04-01`).subtract(1,'y').format('YYYY-MM-DD')}|${moment(`${moment().year()}-04-30`).subtract(1,'y').format('YYYY-MM-DD')}`,
        '9': `custom|${moment(`${moment().year()}-05-01`).subtract(1,'y').format('YYYY-MM-DD')}|${moment(`${moment().year()}-05-31`).subtract(1,'y').format('YYYY-MM-DD')}`,
        '8': `custom|${moment(`${moment().year()}-06-01`).subtract(1,'y').format('YYYY-MM-DD')}|${moment(`${moment().year()}-06-30`).subtract(1,'y').format('YYYY-MM-DD')}`,
        '7': `custom|${moment(`${moment().year()}-07-01`).subtract(1,'y').format('YYYY-MM-DD')}|${moment(`${moment().year()}-07-31`).subtract(1,'y').format('YYYY-MM-DD')}`,
        '19': `custom|${moment(`${moment().year()}-08-01`).subtract(1,'y').format('YYYY-MM-DD')}|${moment(`${moment().year()}-08-31`).subtract(1,'y').format('YYYY-MM-DD')}`,
        '18': `custom|${moment(`${moment().year()}-09-01`).subtract(1,'y').format('YYYY-MM-DD')}|${moment(`${moment().year()}-09-30`).subtract(1,'y').format('YYYY-MM-DD')}`,
        '17': `custom|${moment(`${moment().year()}-10-01`).subtract(1,'y').format('YYYY-MM-DD')}|${moment(`${moment().year()}-10-31`).subtract(1,'y').format('YYYY-MM-DD')}`,
        '16': `custom|${moment(`${moment().year()}-11-01`).subtract(1,'y').format('YYYY-MM-DD')}|${moment(`${moment().year()}-11-30`).subtract(1,'y').format('YYYY-MM-DD')}`,
        '15': `custom|${moment(`${moment().year()}-12-01`).subtract(1,'y').format('YYYY-MM-DD')}|${moment(`${moment().year()}-12-31`).subtract(1,'y').format('YYYY-MM-DD')}`,
        //yearly
        '20': `custom|${moment(`2018-01-01`).format('YYYY-MM-DD')}|${moment(`2018-12-31`).format('YYYY-MM-DD')}`,
        '21': `custom|${moment(`2019-01-01`).format('YYYY-MM-DD')}|${moment(`2019-12-31`).format('YYYY-MM-DD')}`,
    }
}

export const getCompareTimePeriod = (param, date) => {
    if (Number(date)) {
        return dateParams[param][date]
    }
    else {
        const dates = date.split('|')
        if(param === 'previousPeriod') {
            return `custom|${moment(dates[1]).subtract(1, 'month').format('YYYY-MM-DD')}|${moment(dates[2]).subtract(1, 'month').format('YYYY-MM-DD')}`
        } else {
            return `custom|${moment(dates[1]).subtract(1, 'year').format('YYYY-MM-DD')}|${moment(dates[2]).subtract(1, 'year').format('YYYY-MM-DD')}`
        }
    }
}