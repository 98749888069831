import React from "react";

import "./styles.scss";

const AvatarPlaceholder = ({ firstName, lastName, style, ...props }) => {
  const firstLetter = firstName[0] || "";
  const secondLetter = lastName[0] || "";

  return (
    <div {...props} className="avatar-wrapper" style={style}>
      <span>{firstLetter}</span>
      <span>{secondLetter}</span>
    </div>
  );
};

export default AvatarPlaceholder;
