import React from "react";

const MessageBox = ({ value, setValue }) => {
  return (
    <textarea
      className="alert-message"
      placeholder={"Here’s your BrandNudge alert. Check out what's going on and keep on track."}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};

export default MessageBox;
