import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//Actions
import { fetchShareTrend } from "store/navigation/navigationShareTrend/actions";
//Component
import ChartSingleLine from "components/Charts/ChartSingleLine";
import ChartMultiLine from "components/Charts/ChartMultiLine";
import SelectBox from "components/Selects/SelectBox";
import LoaderBox from "components/LoaderBox";
//Utils
import { STATE_STATUSES } from "utils/statuses";
import { getTimePeriod } from "utils/getTimePeriod";
import useFilters from "../../hooks/useFilters";

const selects = [
  {
    name: "chartType",
    default: "byTotal",
    options: [
      { lable: "Total", value: "byTotal" },
      { lable: "By Retailer", value: "byRetailer" },
    ],
  },
];

const ShareTrend = () => {
  const dispatch = useDispatch();

  const { manufacture } = useSelector((state) => state.filters.filters);
  const { mainLastFilter: lastFilter, statusFilters } = useFilters();
  const { shareTrend } = useSelector((state) => state.navigationShareTrend);
  const { status } = useSelector((state) => state.navigationShareTrend);

  const [manufacturers, setManufacturers] = useState([]);
  const [currentManufacturerId, setCurrentManufacturerId] = useState(null);

  const [chartType, setChartType] = useState("byTotal");

  const [manyData, setManyData] = useState(false);

  const [names, setNames] = useState({
    byRetailer: {
      list: [],
      pristine: true,
    },
  });

  useEffect(() => {
    if (currentManufacturerId && statusFilters === STATE_STATUSES.READY) {
      dispatch(
        fetchShareTrend({
          product: lastFilter.product,
          manufacturer: currentManufacturerId.toString(),
          timePeriod: getTimePeriod(lastFilter.date),
        })
      );
    }
  }, [currentManufacturerId, lastFilter, statusFilters, dispatch]);

  useEffect(() => {
    if (manufacture.length) {
      const getManufacturers = (param) => {
        return manufacture.filter(
          (item) =>
            param ===
            lastFilter.manufacture
              .split("|")
              .map((id) => Number(id))
              .includes(Number(item.id))
        );
      };

      let manufacturers;
      if (lastFilter.manufacture.length) {
        manufacturers = getManufacturers(true);
      } else {
        manufacturers = getManufacturers(false);
      }

      const options = manufacturers
        .map((item) => ({
          lable: item.name,
          value: item.name,
          id: item.id,
        }))
        .sort((a, b) => a.lable.localeCompare(b.lable));

      let defoltName;
      let defoltId;

      const initialValue = options.find((item) => item.lable === "Bel UK Ltd");

      if (initialValue) {
        defoltName = initialValue.lable;
        defoltId = initialValue.id;
      } else {
        defoltName = options[0].lable;
        defoltId = options[0].id;
      }

      const selectManufacturer = [
        {
          name: "manufacturers",
          default: defoltName,
          options,
        },
      ];

      setManufacturers(selectManufacturer);
      setCurrentManufacturerId(defoltId);
    }
  }, [manufacture, lastFilter.manufacture]);

  const handleSetChartValue = (values) => {
    setChartType(values["chartType"]);
  };

  const handleSetCurrentManufacturer = (values) => {
    const manufacturerId = manufacturers[0].options.find((item) => item.lable === values["manufacturers"]).id;

    setCurrentManufacturerId(manufacturerId);
  };

  const handleSetNames = (name, type) => {
    if (manyData) {
      setManyData(false);
    }

    const itemName = names[type];
    let updateName = {};

    if (itemName.list.includes(name)) {
      updateName = {
        ...itemName,
        list: itemName.list.filter((el) => el !== name),
        pristine: false,
      };
    } else {
      updateName = {
        ...itemName,
        list: [...itemName.list, name],
        pristine: false,
      };
    }

    setNames((prevState) => ({ ...prevState, [type]: updateName }));
  };

  const autoSetNames = (type, list) => {
    setNames((prevState) => ({ ...prevState, [type]: { list, pristine: true } }));
  };

  const renderChart = () => {
    const charts = {
      byTotal: (
        <ChartSingleLine
          title={"Average Share"} 
          priceParam={"percent"}
          currentData={shareTrend.byTotal}
          compareData={{ data: { values: [] } }}
          isCompare={false}
          type={"pricing"}
        />
      ),
      byRetailer: (
        <ChartMultiLine
          title={"Average Share"} 
          priceParam={"percent"}
          currentData={shareTrend.byRetailer.data}
          compareData={{ data: { values: [] } }}
          isCompare={false}
          type={"byRetailer"}
          names={names}
          autoSetNames={autoSetNames}
          handleSetNames={handleSetNames}
          manyData={manyData}
          setManyData={setManyData}
          status={status}
        />
      ),
    };

    return charts[chartType];
  };

  return (
    <div>
      <div className="filters-box">
        {manufacturers.map((item, index) => (
          <SelectBox
            key={index}
            data={item}
            disabled={status !== STATE_STATUSES.READY}
            setSelectValue={handleSetCurrentManufacturer}
          />
        ))}
        {selects.map((item, index) => (
          <SelectBox
            key={index}
            data={item}
            disabled={status !== STATE_STATUSES.READY}
            setSelectValue={handleSetChartValue}
          />
        ))}
      </div>
      <div className="wrapper-box-relative">
        {shareTrend.byTotal.data.values.length || shareTrend.byRetailer.data.length !== 0 ? (
          <>
            <div className="title-hint">Average share for the manufacturer (Aisle / Shelf).</div>
            {renderChart()}
          </>
        ) : null}
        {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
      </div>
    </div>
  );
};

export default ShareTrend;
