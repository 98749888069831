const url = process.env.REACT_APP_URL;

export const FETCH_SETTINGS_SUBSCRIPTIONS = "FETCH_SETTINGS_SUBSCRIPTIONS";
export const fetchSettingsSubscriptions = () => ({
  type: FETCH_SETTINGS_SUBSCRIPTIONS,
  request: {
    url: `${url}/auth/subscriptions`,
    method: "GET",
  },
});

export const SET_PRISTINE_SETTINGS_SUBSCRIPTIONS = "SET_PRISTINE_SETTINGS_SUBSCRIPTIONS ";
export const setPristineSettingsSubscriptions = (pristine) => {
  return {
    type: SET_PRISTINE_SETTINGS_SUBSCRIPTIONS,
    payload: pristine,
  };
};
