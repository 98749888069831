import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Form, Input, Button, Alert } from "antd";
import { requestLogIn, forgotPassword, setLoginStatus } from "store/authorization/actions";
import { setIsColorThemeFadeIn } from "../store/settings/settingsYourProfile/actions";
import { STATE_STATUSES } from "utils/statuses";
import logo from "../assets/images/general-logo-white.svg";
import backIcn from "assets/images/back.svg";
import styled from "styled-components";
import useColorTheme from  "../hooks/useColorTheme";

const Popup = styled.div`
  width: 100vw;
  height: 100vh;    
  

  .authorization-wrapper {
    transition: opacity 500ms ease-in-out, background-color 1s linear;
    ${({ primaryColor}) => `background-color: ${primaryColor};`}
    ${({ loginStatus }) => loginStatus === STATE_STATUSES.PENDING ? `opacity: 0.9;`: `opacity: 1;`}
  }

  .ant-btn.ant-btn-primary {
    transition: background-color 1s linear;
    ${({ primaryColor}) => `background: ${primaryColor}; background-color: ${primaryColor};`}
  }
`;
//opacity 2s ease-in-out,
//  opacity: ${({ showPopup }) => (showPopup ? '1' : '0')};
const SignIn = ({ status, colorStatus, lastColor, loginStatus, logoutStatus, requestLogIn, forgotPassword, setLoginStatus, setIsColorThemeFadeIn, isMfa }) => {
  const [isError, setIsError] = useState(false);
  const [isForm, setIsForm] = useState(true);
  const [isSendEmail, setIsSendEmail] = useState(false);
  const { primaryColor } = useColorTheme();
  const timer = useRef(null);
  const timerClearLogout = useRef(null);
  const lastColorTheme = lastColor ? JSON.parse(lastColor) : { primary: primaryColor } ;
  const lastPrimary = lastColorTheme?.primary;
  const [thisPrimary, setThisPrimary ] = useState(lastPrimary);

  const isLoading = (status === STATE_STATUSES.PENDING) || (colorStatus === STATE_STATUSES.PENDING);
  useEffect(()=>{
    setLoginStatus(STATE_STATUSES.INIT)
  },[setLoginStatus])

  useEffect(()=>{
    if (logoutStatus === STATE_STATUSES.READY ) {

      timerClearLogout.current = setTimeout(() => {
        localStorage.setItem('logoutStatus', null)
        localStorage.setItem('lastColor', null)
        setLoginStatus(STATE_STATUSES.INIT)
        setThisPrimary(primaryColor)
        document.documentElement.style.setProperty('--color-theme-primary', primaryColor);
      }, 200);

    }

    return () => {
      if (timerClearLogout.current) {
        clearTimeout(timerClearLogout.current);
      }
    };
  },[logoutStatus, primaryColor, setLoginStatus])

  useEffect(()=>{
    if (!isLoading && loginStatus === STATE_STATUSES.PENDING ) {

      timer.current = setTimeout(() => {
        setLoginStatus(STATE_STATUSES.READY);
        setIsColorThemeFadeIn(true);
      }, 500);
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  },[isLoading, loginStatus, setIsColorThemeFadeIn, setLoginStatus])

  const onFinishLogin = (values) => {
    setLoginStatus(STATE_STATUSES.INIT);
    requestLogIn(values)
      .then(() => !isMfa && setLoginStatus(STATE_STATUSES.PENDING))
      .catch(() => setIsError(true));
  };

  const onFinishFailedLogin = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishForgot = (values) => {
    forgotPassword(values).then(() => setIsSendEmail(true));
  };

  const onFinishFailedForgot = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleBack = () => {
    setIsSendEmail(false);
    setIsForm(true);
  };

  return (
    <Popup 
      isLoading={isLoading} 
      loginStatus={loginStatus}
      primaryColor={ logoutStatus === STATE_STATUSES.READY ? thisPrimary : primaryColor}
    >
      {

            <div className={`authorization-wrapper ${logoutStatus === STATE_STATUSES.READY && 'animate_fade_in'}`} >
              <img className="logo" src={logo} alt="logo" />
              <div className="signin-box">
                {isForm ? (
                  <Form
                    name="login"
                    initialValues={{ remember: true }}
                    onFinish={onFinishLogin}
                    onFinishFailed={onFinishFailedLogin}
                  >
                    {isError && (
                      <>
                        <Alert message="Error" description="Wrong password or email" type="error" showIcon />
                        <br />
                      </>
                    )}
                    <div className="title">Login</div>
                    <Form.Item label="Email" name="email" rules={[{ required: true, message: "Please input your email!" }]}>
                      <Input />
                    </Form.Item>
        
                    <Form.Item
                      label="Password"
                      name="password"
                      rules={[{ required: true, message: "Please input your password!" }]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" loading={isLoading || (loginStatus === STATE_STATUSES.PENDING)}>
                        Login
                      </Button>
                    </Form.Item>
                  </Form>
                ) : (
                  <Form name="forgot" onFinish={onFinishForgot} onFinishFailed={onFinishFailedForgot}>
                    <div className="back-box" onClick={handleBack}>
                      <img className="" src={backIcn} alt="back" />
                      <span>Back</span>
                    </div>
                    {isSendEmail ? (
                      <Alert
                        message="A link to reset the password was sent to your email."
                        description
                        type="success"
                        showIcon
                      />
                    ) : (
                      <>
                        <div className="title">Forgot Password</div>
                        <Form.Item label="Email" name="email" rules={[{ required: true, message: "Please input your email!" }]}>
                          <Input />
                        </Form.Item>
                        <Form.Item>
                          <Button type="primary" htmlType="submit" loading={isLoading || (loginStatus === STATE_STATUSES.PENDING)}>
                            Send
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form>
                )}
              </div>
            </div>
      }
    </Popup>
  );
};

export default connect(
  (state) => ({
    status: state.authorization.status,
    loginStatus: state.authorization.loginStatus,
    isMfa: state.authorization.isMfa,
    colorStatus: state.settingsYourProfile.status,
    logoutStatus: state.authorization.logoutStatus,
    lastColor: state.authorization.lastColor,
  }),
  { requestLogIn, forgotPassword, setLoginStatus, setIsColorThemeFadeIn }
)(SignIn);
