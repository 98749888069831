import React, { useEffect } from "react";
import "antd/dist/antd.css";
import "./App.scss";
import "tabs/RankingTabs/Ranking.scss";
import "tabs/NavigationTabs/Navigation.scss";
import "components/TabsExport/Export.scss";
import "components/Alerts/Alerts.scss";
import useColorTheme from "./hooks/useColorTheme";

const App = (props) => {
  const { primaryColor, secondaryColor, lastColorTheme } = useColorTheme();

  useEffect(() => {
    document.documentElement.style.setProperty('--color-theme-primary', JSON.parse(lastColorTheme)?.primary || primaryColor);
    document.documentElement.style.setProperty('--color-theme-primary-background', (JSON.parse(lastColorTheme)?.primary || primaryColor) + '04');
    document.documentElement.style.setProperty('--color-theme-secondary', secondaryColor );
  }, [primaryColor, secondaryColor, lastColorTheme]);

  return <div className="App">{props.children}</div>;
};

export default App;
