import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { roundingNumbers } from "utils/roundingNumbers";
import { fetchRankingRetailers } from "store/pricing/priceRanking/actions";

import { getTimePeriod } from "utils/getTimePeriod";
import { STATE_STATUSES } from "utils/statuses";
import useRetailers from "../../../../hooks/useRetailers";
import useCountry, { NUMBER_TYPE } from "../../../../hooks/useCountry";
import useFilters from "../../../../hooks/useFilters";

import RankingModal from "./RankingModal";

import delisted from "assets/images/delisted.svg";

const RankingList = (props) => {
  const { isCompare, initialData, compareData, fetchRankingRetailers, filtersStatus, totalProducts } =
    props;
  const { lastFilter } = useFilters();
  const { formatCurrencyNumber } = useCountry();
  const [showPopup, setShowPopup] = useState(false);
  const [sortId, setSortId] = useState(null);
  const [sortDirection, setSortDirection] = useState(true);
  const [isFetch, setIsFetch] = useState(false);
  const [page, setPage] = useState(1);
  const [retailerId, setRetailerId] = useState(false);
  const { getRemoteLogo, getRetailerColor } = useRetailers();

  const [rankingRetailers, setRankingRetailers] = useState({
    averagePrices: [],
    products: [],
    retailers: [],
    total: 0,
  });

  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    if (filtersStatus === STATE_STATUSES.READY) {
      setQueryParams((prevState) => ({
        ...prevState,
        sourceType: lastFilter.sourceType,
        timePeriod: getTimePeriod(lastFilter.date),
        product: lastFilter.product,
        sourceTypeSort: "",
        productName: "",
        productNameOrder: "",
        type: "basePrice",
        listing: "",
        page: page,
        limit: 10,
      }));
    }
  }, [lastFilter, filtersStatus]);

  const handlePopup = (id) => {
    setIsFetch(true);
    setRetailerId(id);
    fetchRankingRetailers(queryParams, id).then((response) => {
      setRankingRetailers(response.data);
      setIsFetch(false);
      setShowPopup(true);
    });
  };

  useEffect(() => {
    if (retailerId && Object.keys(queryParams).length) {
      setIsFetch(true);
      fetchRankingRetailers(queryParams, retailerId).then((response) => {
        setRankingRetailers(response.data);
        setIsFetch(false);
      });
    }
  }, [queryParams]);

  const handleSearch = (value) => {
    setQueryParams((prevState) => ({
      ...prevState,
      productName: value,
      page: 1,
    }));
    setPage(1);
  };

  const handleSetPage = (page) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page,
    }));
    setPage(page);
  };

  const handleSort = (id) => {
    let sourceTypeSort;
    if (sortId === id) {
      setSortDirection(!sortDirection);
      sourceTypeSort = `${id},${sortDirection ? "asc" : "desc"}`;
    } else {
      setSortDirection(!sortDirection);
      sourceTypeSort = `${id},${sortDirection ? "asc" : "desc"}`;
    }
    setSortId(id);
    setQueryParams((prevState) => ({
      ...prevState,
      sourceTypeSort,
    }));
  };

  const handleSetShowPopup = (isPopup) => {
    setShowPopup(isPopup);
    setRetailerId(isPopup);
  };

  const renderInitialData = () =>
    initialData.map((item, index) => (
      <div
        key={index}
        className="ranking-item"
        style={{ background: `${getRetailerColor(item.retailer, '.3')}` }}
        onClick={() => handlePopup(item.id)}
      >
        <div className="title">{item.title}</div>
        <img className="image" src={getRemoteLogo(item.retailer)}></img>
        <div className="rate">
          <span className="title">{formatCurrencyNumber(roundingNumbers(item.price), NUMBER_TYPE.CURRENCY)}</span>
          <span className="hint">Average price</span>
        </div>
        {item.index == 0 ? null : (
          <div className="rate">
            <span className="title">{item.index}</span>
            <span className="hint">Index to Lowest</span>
          </div>
        )}
      </div>
    ));

  const renderCompareData = () =>
    initialData.map((item, index) => (
      <div
        key={index}
        className="ranking-item"
        style={{ background: `${getRetailerColor(item.retailer, '.3')}` }}
        onClick={() => handlePopup(item.id)}
      >
        <div className="title">{item.title}</div>
        <div className="compare-params">
          <img className="image" src={getRemoteLogo(compareData[index].retailer)}></img>
          <img className="delisted" src={delisted}></img>
          <img className="image" src={getRemoteLogo(item.retailer)}></img>
        </div>
        <div className="rate">
          <div className="compare-params">
            <span className="title prev">{formatCurrencyNumber(compareData[index].price, NUMBER_TYPE.CURRENCY)}</span>
            <img className="delisted" src={delisted}></img>
            <span className="title">{formatCurrencyNumber(roundingNumbers(item.price), NUMBER_TYPE.CURRENCY)}</span>
          </div>
          <span className="hint">Average price</span>
        </div>
        {item.index == 0 ? null : (
          <div className="rate">
            <div className="compare-params">
              <span className="title prev">{compareData[index].index}</span>
              <img className="delisted" src={delisted}></img>
              <span className="title">{item.index}</span>
            </div>
            <span className="hint">Index to Lowest</span>
          </div>
        )}
      </div>
    ));

  return (
    <>
      <div style={{ position: "relative", width: `${isCompare ? "100%" : "calc(100% - 425px)"}` }}>
        <div className="chart-price-wrapper ranking">
          {initialData.length ? (
            <>
              <div>
                <div className="chart-title-desc">
                  {totalProducts} of the same products are sold across the selected Retailers.
                </div>
                <div className="ranking-wrapper">{compareData.length ? renderCompareData() : renderInitialData()}</div>
              </div>
            </>
          ) : (
            <div className="chart-no-data maxheight">Looks like we don't have data for this request</div>
          )}
        </div>
      </div>

      <RankingModal
        data={rankingRetailers}
        retailers={initialData.map((item) => item.id)}
        sortId={sortId}
        sortDirection={sortDirection}
        handleSearch={handleSearch}
        handleSort={handleSort}
        showPopup={showPopup}
        setShowPopup={handleSetShowPopup}
        status={isFetch}
        page={page}
        setPage={handleSetPage}
      />
    </>
  );
};

export default connect(
  (state) => ({
    products: state.filters.filters.product,
    filtersStatus: state.filters.status,
  }),
  { fetchRankingRetailers }
)(RankingList);
