import { COLORS } from "assets/colors/colors";
import styled from "styled-components";

export const Styles = styled.div.attrs((props) => props)`
  .row {
    display: flex;
    align-items: center;
    & > button {
      margin-bottom: 2px;
      width: 20%;
      height: 44px;
      cursor: pointer;
      padding: 14px 27px 13px 28px;
      border-radius: 4px;
      background-color: ${(props) => props.color};
      outline: none;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      & > span {
        font-size: 14px;
        color: ${COLORS.white};
        font-family: Gilroy-Medium;
        font-weight: bold;
      }
    }
  }
  .benchmark-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    & > span {
      font-family: Gilroy-Medium;
    }
  }
`;
