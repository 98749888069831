import React, { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
//Assets
import { COLORS } from "assets/colors/colors";
//Components
import RenderNoData from "components/RenderNoData";

const PromotionBreakDownPieChart = (props) => {
  //Props
  const { data, currentChart, setHoverEl, hoverEl, arrayOfDesiredBrand, setArrayOfDesiredBrand } = props;
  //Refs
  const inputRef = useRef(null);
  //Constants
  const titleName = currentChart[2].value === "byBrand" ? "brands" : "retailers";
  const isBrand = currentChart[2].value === "byBrand";
  //States

  const [emptyData, setEmptyData] = useState(false);
  const [pricings, setPricings] = useState([]);
  const [clickedLabel, setClickedLabel] = useState("");
  //Converting Data
  useEffect(() => {
    if (Object.entries(data).length !== 0) {
      const pricingWitData = data[currentChart[2].value].totalRatio.map((el, index) => {
        const isHovered = el.title === hoverEl;
        const isIncluded = arrayOfDesiredBrand.includes(el.title);

        const getOpacity = () => {
          if (isBrand) {
            if (isIncluded) {
              return 1;
            } else {
              return 0.3;
            }
          } else {
            if (hoverEl && hoverEl.length && !isHovered) {
              return 0.3;
            } else if (isHovered && hoverEl.length) {
              return 1;
            } else {
              return 1;
            }
          }
        };

        return {
          name: el.title,
          y: el.products,
          color: el.color,
          opacity: getOpacity(),
        };
      });

      setPricings(pricingWitData);
    }
  }, [data, currentChart, hoverEl, arrayOfDesiredBrand]);
  //check for empty array
  useEffect(() => {
    if (pricings.length) {
      setEmptyData(false);
    } else {
      setEmptyData(true);
    }
  }, [pricings]);
  //Options
  const options = {
    chart: {
      type: "pie",
      animation: false,
      style: {
        fontFamily: "Gilroy-Medium",
        fontSize: "10px",
      },
    },
    title: {
      text: `Total ratio of promotional activity across ${titleName}.`,
      verticalAlign: "bottom",
      style: {
        fontFamily: "Gilroy-Medium",
        fontSize: "10px",
        color: COLORS.forGraytext,
      },
    },
    tooltip: {
      headerFormat: "",
      enabled: false,
    },
    plotOptions: {
      series: {
        animation: false,
        borderWidth: 1,
      },
      pie: {
        point: {
          events: {
            click: function () {
              setClickedLabel(this.name);
            },
            mouseOver: function () {
              setElement(this.name);
            },
            mouseOut: function () {
              setElement("");
            },
          },
        },

        states: {
          hover: {
            halo: null,
          },
          inactive: {
            opacity: 0.4,
          },
        },

        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        minPointSize: 10,
        innerSize: "75%",
        name: "countries",
        data: pricings,
      },
    ],
  };

  useEffect(() => {
    if (clickedLabel) {
      onLabelClick(clickedLabel);
    }
  }, [clickedLabel]);

  const onLabelClick = (key) => {
    let array;

    if (arrayOfDesiredBrand.includes(key)) {
      array = arrayOfDesiredBrand.filter((brand) => brand !== key);
      setArrayOfDesiredBrand(array);
    } else {
      array = [...arrayOfDesiredBrand, key];
      setArrayOfDesiredBrand(array);
    }
  };

  const setElement = (el) => {
    setHoverEl(el);
  };

  return (
    <div className="pie-chart-promotions">
      {emptyData ? (
        <RenderNoData style={{ marginTop: 24, marginLeft: 16, width: "auto" }} />
      ) : (
        <HighchartsReact ref={inputRef} highcharts={Highcharts} options={options} />
      )}
    </div>
  );
};

export default PromotionBreakDownPieChart;
