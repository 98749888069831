const url = process.env.REACT_APP_URL;

export const FETCH_CONTENT_TREND = "FETCH_CONTENT_TREND";
export const fetchContentTrend = (params) => ({
  type: FETCH_CONTENT_TREND,
  request: {
    url: `${url}/v2/content/trend`,
    method: "POST",
    data: params,
  },
});
export const FETCH_COMPARE_CONTENT_TREND = "FETCH_COMPARE_CONTENT_TREND";
export const fetchCompareContentTrend = (params) => ({
  type: FETCH_COMPARE_CONTENT_TREND,
  request: {
    url: `${url}/v2/content/trend`,
    method: "POST",
    data: params,
  },
});

export const SET_COMPARE_DATA = "SET_COMPARE_DATA";
export const setCompareData = (params) => ({
  type: SET_COMPARE_DATA,
  payload: params,
});

export const SET_PRISTINE_CONTENT_TREND = "SET_PRISTINE_CONTENT_TREND";
export const setPristineContentTrend = (pristine) => {
  return {
    type: SET_PRISTINE_CONTENT_TREND,
    payload: pristine,
  };
};
