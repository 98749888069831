const url = process.env.REACT_APP_URL;
export const AUTH_ME = "AUTH_ME";

export const FETCH_SETTINGS_YOUR_PROFILE = "FETCH_SETTINGS_YOUR_PROFILE";
export const fetchSettingsYourProfile = () => ({
  type: FETCH_SETTINGS_YOUR_PROFILE,
  request: {
    url: `${url}/auth/me`,
    method: "GET",
  },
  meta: {
    asPromise: true,
  },
});

export const EDIT_SETTINGS_YOUR_PROFILE = "EDIT_SETTINGS_YOUR_PROFILE";
export const editSettingsYourProfile = (data) => {
  return {
    type: EDIT_SETTINGS_YOUR_PROFILE,
    request: {
      url: `${url}/auth/me`,
      method: "PUT",
      data,
    },
    meta: {
      asPromise: true,
    },
  };
};

export const SET_SETTINGS_AVATAR = "SET_SETTINGS_AVATAR";
export const setSettingsAvatar = (data) => ({
  type: SET_SETTINGS_AVATAR,
  request: {
    url: `${url}/auth/avatar`,
    method: "POST",
    data,
  },
  meta: {
    asPromise: true,
  },
});

export const SET_PRISTINE_SETTINGS_PROFILE = "SET_PRISTINE_SETTINGS_PROFILE ";
export const setPristineSettingsProfile = (pristine) => {
  return {
    type: SET_PRISTINE_SETTINGS_PROFILE,
    payload: pristine,
  };
};

export const SET_THEME_COLOR = "SET_THEME_COLOR ";
export const setThemeColor = (themeColor) => {
  return {
    type: SET_THEME_COLOR,
    payload: themeColor,
  };
};

export const SET_IS_COLOR_THEME_FADE_IN = "SET_IS_COLOR_THEME_FADE_IN";
export const setIsColorThemeFadeIn = (isFadeIn) => {
  return {
    type: SET_IS_COLOR_THEME_FADE_IN,
    payload: isFadeIn,
  };
};

export const SET_CURRENT_COUNTRY = "SET_CURRENT_COUNTRY";
export const setCurrentCountry = (country) => {
  return {
    type: SET_CURRENT_COUNTRY,
    payload: country,
  };
};

export const SET_SETTINGS_YOUR_PROFILE = "SET_SETTINGS_YOUR_PROFILE ";
export const setSettingsYourProfile = (profile) => {
  return {
    type: SET_SETTINGS_YOUR_PROFILE,
    payload: profile,
  };
};