import React from "react";
import { getSingularOrPlural } from "utils/generalUtils";

const ShareOfMediaAccordion = ({ data, manufacturer, isOpened, setIsOpened }) => {
  const {
    total: {
      table: { retailerCount, locationCount, productCount },
    },
  } = data;

  return (
    <div className="chart-price-wrapper" style={{ width: "100%" }}>
      <div className="chart-title-desc"></div>

      <div className="manufacturer-wrapper" onClick={() => setIsOpened(!isOpened)}>
        <div className="container manufacturer">
          <div className="plus-btn">
            <span>{!isOpened ? "+" : "-"}</span>
          </div>

          <div className="color" style={{ background: manufacturer.color }}></div>
          <div className="lable">
            <span>{manufacturer.name}</span>
          </div>
        </div>

        <div className="container retailers">
          <span>
            {retailerCount} {getSingularOrPlural(retailerCount, "Retailer")}
          </span>
        </div>
        <div className="container locations">
          <span>
            {locationCount} {getSingularOrPlural(locationCount, "Location")}
          </span>
        </div>
        <div className="container thumbnail"></div>
        <div className="container products">
          <span>
            {productCount} {getSingularOrPlural(productCount, "Product")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ShareOfMediaAccordion;
