import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Checkbox, Modal, Empty } from "antd";
import moment from "moment";
import useColorTheme from "../../../../hooks/useColorTheme";
import useRetailers from "../../../../hooks/useRetailers";

import {
  fetchRankingSearchTermsList,
  fetchRankingRetailersTrend,
  fetchRetailerSingleProduct,
  removerRetailerProduct,
} from "store/ranking/rankingSearchRetailer/actions";
import { requestRankingOrder } from "store/authorization/actions";

import SelectBox from "components/Selects/SelectBox";
import LoaderBox from "components/LoaderBox";
import СurrentlyItemChart from "tabs/RankingTabs/RankingBottomTabs/SearchAnalysisTab/charts/СurrentlyItemChart";
import PromotionsChart from "tabs/RankingTabs/RankingTopTabs/SearchRetailerTab/PromotionsChart";
import RetailerBoxesGroup from "tabs/RankingTabs/RankingTopTabs/SearchRetailerTab/RetailerBoxesGroup";

import { getTimePeriodRanking } from "utils/getTimePeriodRanking";
import { STATE_STATUSES } from "utils/statuses";
import { sortData } from "utils/sortRankingTable";
import { timePeriod } from "utils/timePeriod";
import { getTimePeriodSingleDay } from "utils/getTimePeriodSingleDay";
import { getTimePeriodSearchRetailer } from "utils/getTimePeriodSearchRetailer";

import emptyState from "assets/images/empty-state.svg";
import show_chart from "assets/images/show_chart.svg";
import show_chart_white from "assets/images/show_chart_white.svg";
import sort from "assets/images/sort.svg";
import sortIconUp from "assets/images/sort_up.svg";
import sortIconDown from "assets/images/sort_down.svg";
import printscreen from "assets/images/printscreen.svg";
import featured from "assets/images/featured.svg";
import options from "assets/images/options.svg";
import useFilters from "../../../../hooks/useFilters";

const SearchRetailerTab = (props) => {
  const {
    isFeaturedProducts,
    setIsFeaturedProducts,
    isActive,
  } = props;
  const wrapperPeriodRef = useRef(null);
  const wrapperOrderRef = useRef(null);

  const dispatch = useDispatch();
  const { primaryColor, getColorPromotions } = useColorTheme();
  const { getRemoteLogo } = useRetailers();

  const { mainLastFilter: lastFilter, statusFilters } = useFilters();
  const { rankingOrder } = useSelector((state) => state.authorization);
  const { userManufacturer } = useSelector((state) => state.authorization);
  const { rankingSearchTermsList } = useSelector((state) => state.rankingSearchRetailer);
  const { rankingRetailersTrend } = useSelector((state) => state.rankingSearchRetailer);
  const { retailerSingleProduct } = useSelector((state) => state.rankingSearchRetailer);
  const { status } = useSelector((state) => state.rankingSearchRetailer);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermsList, setSearchTermsList] = useState([]);

  const [selectItems, setSelectItems] = useState([]);

  const [sortParam, setSortParam] = useState("rank");
  const [currentIndex, setCurrentIndex] = useState([]);
  const [directionSort, setDirectionSort] = useState(true);
  const [showScreenshot, setShowScreenshot] = useState(false);
  const [screenshotUrl, sestScreenshotUrl] = useState("");
  const [currentRetailer, setCurrentRetailer] = useState("");

  const [currentDate, setCurrentDate] = useState(null);
  const [datePeriod, setDatePeriod] = useState(14);
  const [isOpenDatePeriod, setIsOpenDatePeriod] = useState(false);
  const [isOpenChangeOrder, setIsOpenChangeOrder] = useState(false);

  const [selectedRetailers, setSelectedRetailers] = useState([]);
  const [orderRetailers, setOrderRetailers] = useState([]);

  const [onlyMyProducts, setOnlyMyProducts] = useState(false);

  useEffect(() => {
    if (statusFilters === STATE_STATUSES.READY) {
      dispatch(
        fetchRankingSearchTermsList({
          type: "search",
          sourceType: lastFilter.sourceType,
          timePeriod: getTimePeriodRanking(lastFilter.date),
          product: lastFilter.product,
        })
      );
    }
  }, [lastFilter, statusFilters, dispatch]);

  useEffect(() => {
    setSelectedRetailers([]);
    setSelectItems([]);
  }, [lastFilter]);

  useEffect(() => {
    if (retailerSingleProduct.length) {
      const retailers = retailerSingleProduct.map((item) => ({
        name: item.name,
        id: item.id,
      }));
      const retailersOrder = _.sortBy(retailers, (item) => rankingOrder.indexOf(item.id));

      setOrderRetailers(retailersOrder);
    } else {
      setOrderRetailers([]);
    }
  }, [retailerSingleProduct]);

  useEffect(() => {
    if (rankingSearchTermsList.length) {
      const options = [];
      let defaultSearchTerm = "Search terms";

      rankingSearchTermsList.forEach((item) => options.push({ lable: item, value: item }));

      if (searchTerm) {
        const isSearchTerm = options.find((item) => item.lable === searchTerm);
        if (isSearchTerm) {
          defaultSearchTerm = searchTerm;
          if (isActive) {
            dispatch(
              fetchRankingRetailersTrend({
                type: "search",
                searchTerm,
                manufacturer: "",
                sourceType: lastFilter.sourceType,
                timePeriod: getTimePeriodSearchRetailer(lastFilter.date),
                product: lastFilter.product,
                featured: isFeaturedProducts,
              })
            );
          }
        } else {
          setSearchTerm(options[0].lable);
          defaultSearchTerm = options[0].lable;
        }
      }

      const selects = [
        {
          name: "searchTerm",
          default: defaultSearchTerm,
          options: options.sort((a, b) => a.lable.localeCompare(b.lable)),
        },
      ];

      setSearchTermsList(selects);
    }
  }, [rankingSearchTermsList]);

  useEffect(() => {
    if (searchTerm.length) {
      dispatch(
        fetchRankingRetailersTrend({
          type: "search",
          searchTerm,
          manufacturer: "",
          sourceType: lastFilter.sourceType,
          timePeriod: getTimePeriodSearchRetailer(lastFilter.date),
          product: lastFilter.product,
          featured: isFeaturedProducts,
        })
      );
    }
  }, [searchTerm, isFeaturedProducts]);

  useEffect(() => {
    if (Object.entries(rankingRetailersTrend).length !== 0 && rankingRetailersTrend.data.length) {
      setCurrentDate(rankingRetailersTrend.data[0].date);
    }
  }, [rankingRetailersTrend]);

  const setSearchTermValue = (values) => {
    setSearchTerm(values["searchTerm"]);
  };

  const filterProducts = (data) =>
    data.filter((item) => {
      if (onlyMyProducts) {
        return item.manufacturerId === userManufacturer;
      }
      return item;
    });

  const sliceData = (data, index) => {
    let convertData;

    if (data.length > 10 && !currentIndex.includes(index)) {
      convertData = data.slice(0, 10);
    } else if (data.length > 10 && currentIndex.includes(index)) {
      convertData = data;
    } else {
      convertData = data;
    }

    return convertData;
  };

  const handleSort = (name) => {
    if (sortParam !== name) {
      setDirectionSort(true);
    } else {
      setDirectionSort(!directionSort);
    }
    setSortParam(name);
  };

  const handleScreenshot = (e, item) => {
    e.stopPropagation();
    if (item.screenshot.length) {
      setShowScreenshot(true);
      sestScreenshotUrl(item.screenshot);
      setCurrentRetailer(item.retailer);
    }
  };

  const handleChangeOrder = (retailers) => {
    setOrderRetailers(retailers);

    const retailersId = retailers.map((item) => item.id);

    dispatch(requestRankingOrder({ rankingOrder: retailersId }));
  };

  const handleAllCharts = (item) => {
    const { title, id, products } = item;

    const productIds = [];
    const uIds = [];

    products.forEach((product) => {
      productIds.push(product.coreProductId);
      uIds.push(product.uid);
    });

    if (selectedRetailers.includes(title)) {
      setSelectedRetailers((prevState) => prevState.filter((item) => item !== title));

      setSelectItems(selectItems.filter((item) => !uIds.includes(item)));

      dispatch(removerRetailerProduct("", title));
    } else {
      setSelectedRetailers((prevState) => [...prevState, title]);

      setSelectItems((prevState) => [...prevState, ...uIds]);

      dispatch(
        fetchRetailerSingleProduct(
          {
            type: "search",
            raw: true,
            promo: true,
            searchTerm,
            sourceType: id.toString(),
            timePeriod: getTimePeriodSingleDay(currentDate, datePeriod),
            product: productIds.join("|"),
            page: "1",
            limit: `${productIds.length}`,
            featured: isFeaturedProducts,
          },
          false,
          title
        )
      );
    }
  };

  const handleChart = (item) => {
    const { coreProductId, uid, retailer_id, retailer } = item;

    if (selectItems.includes(uid)) {
      setSelectItems((prevState) => prevState.filter((item) => item !== uid));

      dispatch(removerRetailerProduct(uid, retailer));
    } else {
      setSelectItems((prevState) => [...prevState, uid]);

      dispatch(
        fetchRetailerSingleProduct(
          {
            type: "search",
            raw: true,
            promo: true,
            searchTerm,
            sourceType: retailer_id.toString(),
            timePeriod: getTimePeriodSingleDay(currentDate, datePeriod),
            product: coreProductId.toString(),
            page: "1",
            limit: "10",
            featured: isFeaturedProducts,
          },
          true,
          retailer
        )
      );
    }
  };

  const handleSetDatePeriod = (period) => {
    setDatePeriod(period);
    setIsOpenDatePeriod(false);

    const requests = [];

    retailerSingleProduct.forEach((mainItem) => {
      if (mainItem.data.length) {
        const sourceType = mainItem.data[0].retailer;

        requests.push({
          name: mainItem.name,
          sourceType,
          products: mainItem.data.map((item) => item.id),
        });
      }
    });

    requests.map((item) =>
      dispatch(
        fetchRetailerSingleProduct(
          {
            type: "search",
            raw: true,
            promo: true,
            searchTerm,
            sourceType: item.sourceType,
            timePeriod: getTimePeriodSingleDay(currentDate, period),
            product: item.products.join("|"),
            page: "1",
            limit: `${item.products.length}`,
          },
          false,
          item.name
        )
      )
    );
  };

  function useOutsideAlerterPeriod(refPeriod) {
    useEffect(() => {
      if (isOpenDatePeriod) {
        function handleClickOutside(event) {
          if (refPeriod.current && !refPeriod.current.contains(event.target)) {
            setIsOpenDatePeriod(false);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
    }, [refPeriod, isOpenDatePeriod]);
  }
  useOutsideAlerterPeriod(wrapperPeriodRef);

  function useOutsideAlerterOrder(refOrder) {
    useEffect(() => {
      if (isOpenChangeOrder) {
        function handleClickOutside(event) {
          if (refOrder.current && !refOrder.current.contains(event.target)) {
            setIsOpenChangeOrder(false);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
    }, [refOrder, isOpenChangeOrder]);
  }
  useOutsideAlerterOrder(wrapperOrderRef);

  return (
    <div>
      <div className="filters-box">
        {Object.entries(rankingRetailersTrend).length !== 0 &&
        orderRetailers.length &&
        rankingRetailersTrend.data.length &&
        searchTerm.length ? (
          <div className="trend-perion-wrapper" ref={wrapperOrderRef}>
            <div className="trend-perion" onClick={() => setIsOpenChangeOrder(!isOpenChangeOrder)}>
              <span className="label">Change Order</span>
            </div>
            {isOpenChangeOrder ? (
              <div className="dropdown retailer" style={{ width: "152px" }}>
                <div className="description">Drag and drop retailers to change an order.</div>
                <div className="period-box">
                  <RetailerBoxesGroup orderRetailers={orderRetailers} handleChangeOrder={handleChangeOrder} />
                </div>
              </div>
            ) : null}
          </div>
        ) : null}

        {selectItems.length ? (
          <div className="trend-perion-wrapper" ref={wrapperPeriodRef}>
            <div className="trend-perion" onClick={() => setIsOpenDatePeriod(!isOpenDatePeriod)}>
              <span className="label">Trend Period</span>
            </div>
            {isOpenDatePeriod ? (
              <div className="dropdown retailer">
                <div className="description">Relative</div>
                <div className="period-box">
                  {timePeriod.map((item, index) => (
                    <div
                      key={index}
                      className="item-period"
                      style={{
                        border: `1px solid ${datePeriod === item.value ? primaryColor : "#dfdfea"}`,
                        fontFamily: datePeriod === item.value ? "Gilroy-ExtraBold" : "Gilroy-Medium",
                        color: datePeriod === item.value ? primaryColor : "#000",
                      }}
                      onClick={() => handleSetDatePeriod(item.value)}
                    >
                      {item.label}
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      <div className="wrapper-box-relative">
        <div className="select-retailers">
          {Object.entries(rankingRetailersTrend).length !== 0 &&
          rankingRetailersTrend.data.length &&
          searchTerm.length ? (
            <div>
              You are currently viewing
              <span> {rankingRetailersTrend.data[0].retailers.length} Retailer Rankings</span> for
              <span> {rankingRetailersTrend.data[0].title}</span> for
              <span> {moment(getTimePeriodSearchRetailer(lastFilter.date).split("|")[2]).format("DD MMM YY")}</span>.
            </div>
          ) : (
            <div>...</div>
          )}
        </div>
        <div className="search-terms-control">
          <div className="search-terms-dropdown">
            <p>Choose Search Terms</p>
            {searchTermsList.map((item, index) => (
              <SelectBox
                key={index}
                isMargin={true}
                data={item}
                disabled={(status !== STATE_STATUSES.READY) && (status !== STATE_STATUSES.ERROR)}
                setSelectValue={setSearchTermValue}
              />
            ))}
          </div>
          {Object.entries(rankingRetailersTrend).length !== 0 &&
          rankingRetailersTrend.data.length &&
          searchTerm.length ? (
            <div style={{ display: "flex" }}>
              <div className="onlyProducts right-wrapper" style={{ marginRight: "15px" }}>
                <Checkbox checked={isFeaturedProducts} onChange={() => {
                  setSelectItems([]);
                  setIsFeaturedProducts(!isFeaturedProducts);
                }}>
                  Sponsored Products
                </Checkbox>
              </div>
              <div className="onlyProducts right-wrapper">
                <Checkbox checked={onlyMyProducts} onChange={() => setOnlyMyProducts(!onlyMyProducts)}>
                  Show Only My Products
                </Checkbox>
              </div>
            </div>
          ) : null}
        </div>
        <div className="search-term-wrapper">
          {Object.entries(rankingRetailersTrend).length !== 0 && searchTerm.length ? (
            <>
              {rankingRetailersTrend.data.length && rankingRetailersTrend.data[0].retailers.length ? (
                _.sortBy(rankingRetailersTrend.data[0].retailers, (item) => rankingOrder.indexOf(item.id)).map(
                  (itemRetailer, indexRetailer) => (
                    <div className="search-term-box" key={indexRetailer}>
                      <div className="panel-header-term">
                        <div className="retailer">
                          <img src={getRemoteLogo(itemRetailer.title)} alt="retailer" />
                          <span>{filterProducts(itemRetailer.products).length} Products</span>
                        </div>
                        <div className="promotions">
                          {onlyMyProducts
                            ? filterProducts(itemRetailer.products).filter((item) => Boolean(item.promotions)).length
                            : itemRetailer.promotions}{" "}
                          On Promotion
                        </div>
                        <div className="printscreen-absolute">
                          {Boolean(itemRetailer.products[0].screenshot) ? (
                            <div className="icon-btn" onClick={(e) => handleScreenshot(e, itemRetailer.products[0])}>
                              <img src={printscreen} alt="printscreen" />
                            </div>
                          ) : (
                            <span className="no-screen">N/A</span>
                          )}
                          <div
                            className="icon-btn"
                            style={{
                              background: selectedRetailers.includes(itemRetailer.title) ? primaryColor : "#eef2f7",
                            }}
                            onClick={() => handleAllCharts(itemRetailer)}
                          >
                            <img
                              src={selectedRetailers.includes(itemRetailer.title) ? show_chart_white : show_chart}
                              alt="back"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="table-header main">
                        <div className="rank-sort" onClick={() => handleSort("rank")}>
                          <div>Rank</div>
                          <img
                            src={sortParam === "rank" ? (directionSort ? sortIconUp : sortIconDown) : sort}
                            alt="sort"
                          />
                        </div>
                        <div className="title-sort" onClick={() => handleSort("title")}>
                          <div>Product Title</div>
                          <img
                            src={sortParam === "title" ? (directionSort ? sortIconUp : sortIconDown) : sort}
                            alt="sort"
                          />
                        </div>
                        <div className="discription-sort">
                          <div>Promotion Description</div>
                        </div>
                        <div style={{ width: "100%", maxWidth: "100px" }}></div>
                      </div>
                      <div style={{ position: "relative" }}>
                        <div className="table-body main">
                          {sortData(
                            sliceData(filterProducts(itemRetailer.products), indexRetailer),
                            sortParam,
                            directionSort
                          ).length ? (
                            sortData(
                              sliceData(filterProducts(itemRetailer.products), indexRetailer),
                              sortParam,
                              directionSort
                            ).map((item, index) => (
                              <div key={index}>
                                <div
                                  className={`table-item ${item.selectedProduct ? "selectedProduct" : ""}`}
                                  style={{
                                    borderBottom: selectItems.includes(item.uid) ? "none" : "2px solid #e0e0e4",
                                  }}
                                >
                                  <div className="rank">
                                    <span>{item.rank}</span>
                                    {item.featured ? <img src={featured} alt="featured" /> : null}
                                    {item.productOptions ? <img src={options} alt="options" /> : null}
                                  </div>
                                  <div
                                    className={`title-wrapper ${
                                      item.fromSelectedManufacture ? "selectedManufacture" : ""
                                    }`}
                                  >
                                    <img src={item.image} alt="product" />
                                    <div className="titles-box">
                                      <div className="title">{item.title}</div>
                                      {/* <div className='subtitle'>{item.detailTitle}</div> */}
                                    </div>
                                  </div>
                                  <div className="description-wrapper">
                                    <div
                                      className="description-box"
                                      style={{
                                        background: item.promotions
                                          ? getColorPromotions(item.promotions.mechanic.name)
                                          : null,
                                      }}
                                    >
                                      {item.promotions ? (
                                        item.promotions.description
                                      ) : (
                                        <span className="no-promotion">No promotion</span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="right-wrapper all">
                                    <div
                                      className="icon-btn"
                                      style={{
                                        background: selectItems.includes(item.uid) ? primaryColor : "#eef2f7",
                                      }}
                                      onClick={() => handleChart(item)}
                                    >
                                      <img
                                        src={selectItems.includes(item.uid) ? show_chart_white : show_chart}
                                        alt="back"
                                      />
                                    </div>
                                  </div>
                                </div>
                                {retailerSingleProduct.length
                                  ? retailerSingleProduct.map((dataItem) =>
                                      dataItem.data.map((chart, index) => {
                                        if (chart.uid == item.uid && selectItems.includes(item.uid)) {
                                          return (
                                            <div
                                              key={index}
                                              className="wrapper-bottom-chart"
                                              style={{
                                                borderBottom: "2px solid #e0e0e4",
                                              }}
                                            >
                                              <СurrentlyItemChart
                                                type={"rank"}
                                                data={chart.ranks}
                                                promotions={chart.promotions}
                                                timePeriod={[0, chart.ranks.length]}
                                                isMarker={false}
                                                isPopup={true}
                                                outside={true}
                                              />
                                              {chart.promotions.length ? (
                                                <PromotionsChart dates={chart.ranks} data={chart.promotions} />
                                              ) : null}
                                            </div>
                                          );
                                        }
                                      })
                                    )
                                  : null}
                              </div>
                            ))
                          ) : (
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                          )}
                        </div>
                      </div>
                      {filterProducts(itemRetailer.products).length > 10 && !currentIndex.includes(indexRetailer) ? (
                        <div
                          className="learn-more"
                          onClick={() => setCurrentIndex((prevState) => [...prevState, indexRetailer])}
                        >
                          Load more
                        </div>
                      ) : null}
                      {retailerSingleProduct.map((item, index) => {
                        return itemRetailer.title === item.name && item.itemStatus === STATE_STATUSES.PENDING ? (
                          <LoaderBox key={index} />
                        ) : null;
                      })}
                    </div>
                  )
                )
              ) : (
                <div className="chart-no-data static" style={{ marginTop: "25px" }}>
                  Looks like we don't have data for this request
                </div>
              )}
            </>
          ) : (
            <img className="empty-image" src={emptyState} alt="empty" />
          )}
        </div>
        <Modal
          className="ranking-page-modal"
          visible={showScreenshot}
          onCancel={() => {
            setShowScreenshot(false);
            sestScreenshotUrl("");
          }}
        >
          <div className="title-popup-single-day">
            <span>{searchTerm}</span> in <span>{currentRetailer}</span>
          </div>
          <div className="screenshot-wrapper">
            <img src={screenshotUrl} alt="screenshot" />
          </div>
        </Modal>
        {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
      </div>
    </div>
  );
};

export default SearchRetailerTab;
