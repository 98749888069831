import { useMemo } from "react";
import { useSelector } from "react-redux";

export default function useCache() {
  const { retailers: retailersOrder } = useSelector((state) => state.settingsYourProfile.settingsYourProfile);
  const { currentPrices } = useSelector((state) => state.currentPrices);
  const { currentData, compareData } = useSelector((state) => state.priceChange);
  const { priceRange } = useSelector((state) => state.priceRange);
  const { priceComparison } = useSelector((state) => state.priceComparison);
  const { summaryPromotions } = useSelector((state) => state.summaryPromotions);
  const { promotionBreakdown } = useSelector((state) => state.promotionBreakdown);
  const { discountCut } = useSelector((state) => state.discountCut);
  const { promotionsCalendar } = useSelector((state) => state.promotionsCalendar);
  const { data: rankingTrendData } = useSelector((state) => state.rankingSearchAnalysis.rankingSearchAnalysisTrend);
  const { currentItemTermSearch, rankingSearchAnalysis } = useSelector((state) => state.rankingSearchAnalysis);
  const { rankingLocationAnalysis } = useSelector((state) => state.rankingLocationAnalysis);
  const { currentContent } = useSelector((state) => state.currentContent);

  const cachedCurrentPrices = useMemo(
    () =>
      currentPrices?.retailers && Array.isArray(currentPrices.retailers) && Array.isArray(retailersOrder)
        ? {
            ...currentPrices,
            retailers: currentPrices.retailers.sort(
              (a, b) => retailersOrder.indexOf(a.id) - retailersOrder.indexOf(b.id)
            ),
            averagePrices: currentPrices.averagePrices.sort(
              (a, b) => retailersOrder.indexOf(a.retailerId) - retailersOrder.indexOf(b.retailerId)
            ),
            products: currentPrices.products.map((el) => ({
              ...el,
              prices: el.prices.sort(
                (a, b) => retailersOrder.indexOf(a.retailerId) - retailersOrder.indexOf(b.retailerId)
              ),
            })),
          }
        : currentPrices,
    [retailersOrder, currentPrices]
  );

  const cachedPriceChangeCurrentData = useMemo(() => {
    if (
      !currentData?.byRetailer?.priceChart?.data ||
      !Array.isArray(currentData.byRetailer.priceChart.data) ||
      !Array.isArray(retailersOrder)
    )
      return currentData;

    const sortedRetailers = currentData.byRetailer.priceChart.data.sort(
      (a, b) => retailersOrder.indexOf(a.id) - retailersOrder.indexOf(b.id)
    );
    return {
      ...currentData,
      byRetailer: {
        priceChart: {
          data: sortedRetailers,
          names: sortedRetailers.map((el) => el.name),
        },
        topMovers: {
          topMoversBase: currentData.byRetailer.topMovers.topMoversBase.sort(
            (a, b) => retailersOrder.indexOf(a.retailerId) - retailersOrder.indexOf(b.retailerId)
          ),
          topMoversPromoted: currentData.byRetailer.topMovers.topMoversPromoted.sort(
            (a, b) => retailersOrder.indexOf(a.retailerId) - retailersOrder.indexOf(b.retailerId)
          ),
          topMoversShelf: currentData.byRetailer.topMovers.topMoversShelf.sort(
            (a, b) => retailersOrder.indexOf(a.retailerId) - retailersOrder.indexOf(b.retailerId)
          ),
        },
      },
    };
  }, [retailersOrder, currentData]);

  const cachedPriceChangeCompareData = useMemo(() => {
    if (
      !compareData?.byRetailer?.priceChart?.data ||
      !Array.isArray(compareData.byRetailer.priceChart.data) ||
      !Array.isArray(retailersOrder)
    )
      return compareData;

    const sortedRetailers = compareData.byRetailer.priceChart.data.sort(
      (a, b) => retailersOrder.indexOf(a.id) - retailersOrder.indexOf(b.id)
    );
    return {
      ...compareData,
      byRetailer: {
        priceChart: {
          data: sortedRetailers,
          names: sortedRetailers.map((el) => el.name),
        },
        topMovers: {
          topMoversBase: compareData.byRetailer.topMovers.topMoversBase.sort(
            (a, b) => retailersOrder.indexOf(a.retailerId) - retailersOrder.indexOf(b.retailerId)
          ),
          topMoversPromoted: compareData.byRetailer.topMovers.topMoversPromoted.sort(
            (a, b) => retailersOrder.indexOf(a.retailerId) - retailersOrder.indexOf(b.retailerId)
          ),
          topMoversShelf: compareData.byRetailer.topMovers.topMoversShelf.sort(
            (a, b) => retailersOrder.indexOf(a.retailerId) - retailersOrder.indexOf(b.retailerId)
          ),
        },
      },
    };
  }, [retailersOrder, compareData]);

  const cachedPriceRange = useMemo(
    () =>
      Array.isArray(retailersOrder)
        ? priceRange.map((pRange) => ({
            ...pRange,
            rows: [
              pRange.rows[0],
              ...pRange.rows.slice(1).sort((a, b) => retailersOrder.indexOf(a.id) - retailersOrder.indexOf(b.id)),
            ],
          }))
        : priceRange,
    [retailersOrder, priceRange]
  );

  const cachedPriceComparison = useMemo(() => {
    if (
      !priceComparison?.products ||
      !Array.isArray(priceComparison.products) ||
      priceComparison.products.length === 0 ||
      !Array.isArray(retailersOrder)
    )
      return priceComparison;
    const orgRetailersOrder = priceComparison?.products[0].values.slice(1);
    return {
      ...priceComparison,
      products: priceComparison.products.map((p) => ({
        product: p.product,
        values: [
          p.values[0],
          ...p.values
            .slice(1)
            .sort((a, b) => retailersOrder.indexOf(a.retailerId) - retailersOrder.indexOf(b.retailerId)),
        ],
      })),
      retailers: priceComparison.retailers.map((r) => {
        const retailersCount = r.data.map((el, index) => ({
          retailerId: orgRetailersOrder[index].retailerId,
          count: el,
        }));
        return {
          ...r,
          data: retailersCount
            .sort((a, b) => retailersOrder.indexOf(a.retailerId) - retailersOrder.indexOf(b.retailerId))
            .map((rCount) => rCount.count),
        };
      }),
    };
  }, [retailersOrder, priceComparison]);

  const cachedSummaryPromotions = useMemo(
    () =>
      Array.isArray(retailersOrder)
        ? {
            ...summaryPromotions,
            byRetailer: {
              ...summaryPromotions.byRetailer,
              data: summaryPromotions.byRetailer.data.sort(
                (a, b) => retailersOrder.indexOf(a.id) - retailersOrder.indexOf(b.id)
              ),
            },
          }
        : summaryPromotions,
    [retailersOrder, summaryPromotions]
  );

  const cachedPromotionBreakdown = useMemo(
    () =>
      Array.isArray(retailersOrder)
        ? {
            ...promotionBreakdown,
            byRetailer: {
              ...promotionBreakdown.byRetailer,
              data: promotionBreakdown.byRetailer.data.sort(
                (a, b) => retailersOrder.indexOf(a.id) - retailersOrder.indexOf(b.id)
              ),
              totalRatio: promotionBreakdown.byRetailer.totalRatio.sort(
                (a, b) => retailersOrder.indexOf(a.id) - retailersOrder.indexOf(b.id)
              ),
            },
          }
        : promotionBreakdown,
    [retailersOrder, promotionBreakdown]
  );

  const cachedDiscountCut = useMemo(
    () =>
      Array.isArray(retailersOrder)
        ? {
            ...discountCut,
            byRetailer: {
              ...discountCut.byRetailer,
              data: discountCut.byRetailer.data.sort(
                (a, b) => retailersOrder.indexOf(a.id) - retailersOrder.indexOf(b.id)
              ),
            },
          }
        : discountCut,
    [retailersOrder, discountCut]
  );

  const cachedPromotionsCalendar = useMemo(
    () =>
      Array.isArray(retailersOrder)
        ? {
            ...promotionsCalendar,
            rows: promotionsCalendar.rows.sort((a, b) => retailersOrder.indexOf(a.id) - retailersOrder.indexOf(b.id)),
          }
        : promotionsCalendar,
    [retailersOrder, promotionsCalendar]
  );

  const cachedCurrentItemTermSearch = useMemo(
    () =>
      currentItemTermSearch?.share && Array.isArray(retailersOrder)
        ? {
            ...currentItemTermSearch,
            share: Array.isArray(currentItemTermSearch.share)
              ? currentItemTermSearch.share.sort((a, b) => retailersOrder.indexOf(a.id) - retailersOrder.indexOf(b.id))
              : currentItemTermSearch.share,
          }
        : currentItemTermSearch,
    [retailersOrder, currentItemTermSearch]
  );

  const cachedRankingTrendData = useMemo(() => {
    if (Array.isArray(rankingTrendData) && rankingTrendData.length > 0 && Array.isArray(retailersOrder)) {
      // Map over each manufacturer's data and apply transformations
      return rankingTrendData.map((manufacturerData) => ({
        ...manufacturerData,
        rankingTop5: manufacturerData.rankingTop5.map((el) => ({
          ...el,
          share: Array.isArray(el.share)
            ? el.share.sort((a, b) => retailersOrder.indexOf(a.id) - retailersOrder.indexOf(b.id))
            : el.share,
        })),
        rankingTop10: manufacturerData.rankingTop10.map((el) => ({
          ...el,
          share: Array.isArray(el.share)
            ? el.share.sort((a, b) => retailersOrder.indexOf(a.id) - retailersOrder.indexOf(b.id))
            : el.share,
        })),
        rankingTop20: manufacturerData.rankingTop20.map((el) => ({
          ...el,
          share: Array.isArray(el.share)
            ? el.share.sort((a, b) => retailersOrder.indexOf(a.id) - retailersOrder.indexOf(b.id))
            : el.share,
        })),
      }));
    }
    // Return the original data if conditions are not met
    return rankingTrendData;
  }, [retailersOrder, rankingTrendData]);

  const cachedRankingSearchAnalysis = useMemo(() => {
    if (Array.isArray(rankingSearchAnalysis) && rankingSearchAnalysis.length > 0 && Array.isArray(retailersOrder)) {
      return rankingSearchAnalysis.map((analysisItem) => ({
        ...analysisItem,
        rankingTop5: analysisItem.rankingTop5.map((el) => ({
          ...el,
          share: Array.isArray(el.share)
            ? el.share.sort((a, b) => retailersOrder.indexOf(a.id) - retailersOrder.indexOf(b.id))
            : el.share,
        })),
        rankingTop10: analysisItem.rankingTop10.map((el) => ({
          ...el,
          share: Array.isArray(el.share)
            ? el.share.sort((a, b) => retailersOrder.indexOf(a.id) - retailersOrder.indexOf(b.id))
            : el.share,
        })),
        rankingTop20: analysisItem.rankingTop20.map((el) => ({
          ...el,
          share: Array.isArray(el.share)
            ? el.share.sort((a, b) => retailersOrder.indexOf(a.id) - retailersOrder.indexOf(b.id))
            : el.share,
        })),
      }));
    } else {
      return rankingSearchAnalysis;
    }
  }, [retailersOrder, rankingSearchAnalysis]);

  const cachedRankingLocationAnalysis = useMemo(() => {
    if (Array.isArray(rankingLocationAnalysis) && rankingLocationAnalysis.length > 0 && Array.isArray(retailersOrder)) {
      return rankingLocationAnalysis.map((analysisItem) => ({
        ...analysisItem,
        rankingTop5: {
          ...analysisItem.rankingTop5,
          retailers: analysisItem.rankingTop5.retailers.sort(
            (a, b) => retailersOrder.indexOf(a.id) - retailersOrder.indexOf(b.id)
          ),
        },
        rankingTop10: {
          ...analysisItem.rankingTop10,
          retailers: analysisItem.rankingTop10.retailers.sort(
            (a, b) => retailersOrder.indexOf(a.id) - retailersOrder.indexOf(b.id)
          ),
        },
        rankingTop20: {
          ...analysisItem.rankingTop20,
          retailers: analysisItem.rankingTop20.retailers.sort(
            (a, b) => retailersOrder.indexOf(a.id) - retailersOrder.indexOf(b.id)
          ),
        },
      }));
    } else {
      return rankingLocationAnalysis;
    }
  }, [retailersOrder, rankingLocationAnalysis]);

  const cachedCurrentContent = useMemo(
    () =>
      currentContent?.retailers && Array.isArray(currentContent.retailers) && Array.isArray(retailersOrder)
        ? {
            ...currentContent,
            retailers: currentContent.retailers.sort(
              (a, b) => retailersOrder.indexOf(a.id) - retailersOrder.indexOf(b.id)
            ),
          }
        : currentContent,
    [retailersOrder, currentContent]
  );

  return {
    retailersOrder,
    cachedCurrentPrices,
    cachedPriceChangeCurrentData,
    cachedPriceChangeCompareData,
    cachedPriceRange,
    cachedPriceComparison,
    cachedSummaryPromotions,
    cachedPromotionBreakdown,
    cachedDiscountCut,
    cachedPromotionsCalendar,
    cachedCurrentItemTermSearch,
    cachedRankingTrendData,
    cachedRankingSearchAnalysis,
    cachedRankingLocationAnalysis,
    cachedCurrentContent,
  };
}
