import { COLORS } from "assets/colors/colors";
import styled from "styled-components";

export const Styles = styled.div.attrs((props) => props)`
  .table-wrapper {
    font-family: "Gilroy-Medium";
    width: 100%;
    overflow-x: auto;
    margin-top: 45px;
    display: grid;
    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #abaaba;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #807f97;
    }
  }
  .table-header {
    display: flex;
    font-family: "Gilroy-Regular";
    padding-bottom: 0;
    .retailers {
      display: flex;
    }
    .search-box {
      height: 80px;
      display: flex;
      align-items: center;
      .search {
        width: 360px;
        position: relative;
        position: relative;
        font-size: 14px;
        color: #000000;
        @media (max-width: 1440px) {
          width: 310px;
        }
        img {
          position: absolute;
          top: 10px;
          left: 10px;
        }
        input {
          border: none;
          background-color: #eef2f7;
          width: 330px;
          height: 44px;
          border-radius: 2px;
          outline: none;
          padding-left: 40px;
          padding-right: 12px;
          @media (max-width: 1440px) {
            width: 280px;
          }
        }
      }
    }
    .retailer {
      cursor: pointer;
      width: 230px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 1440px) {
        width: 160px;
      }
      img {
        width: 26px;
        height: 26px;
      }
    }
    .selected-compare {
      border: 2px solid ${(props) => props.color};
      border-bottom: 0;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }

  .wrapper-line {
    display: inline-flex;
    align-items: center;
    .title {
      width: 360px;
      border: 0.5px solid #dfdfea;
      border-left: none;
      border-right: none;
      height: 80px;
      display: flex;
      align-items: center;
      @media (max-width: 1440px) {
        width: 310px;
      }
      span {
        padding: 0 10px;
      }
      img {
        width: 50px;
        height: 50px;
      }
    }
    .item-price {
      width: 230px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.5px solid #dfdfea;
      border-right: none;
      @media (max-width: 1440px) {
        width: 160px;
      }

      .price {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .delisted {
        width: 15px;
        height: 15px;
        margin: 0 20px;
      }
      .notListed {
        .price {
          font-size: 10px;
          color: #abaaba;
          font-family: "Gilroy-ExtraBold";
        }
      }
    }
    .selected-compare-product {
      border-left: 2px solid ${(props) => props.color};
      border-right: 2px solid ${(props) => props.color};
    }

    .border-bottom-to-last {
      border-bottom: 2px solid ${(props) => props.color};
    }
    .increment {
      background: #d8f7ef;
    }
    .decrement {
      background: #ffefef;
    }
    .gray {
      background: #eaeaee;
    }
  }
  .cur-retailer {
    margin-left: 5px;
    text-transform: capitalize;
  }

  .filter {
    position: relative;
    height: 44px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    background: ${(props) => props.color};
    padding: 10px 10px 10px 15px;
    font-size: 14px;
    color: #000000;
    border-radius: 4px;
    cursor: pointer;
    font-family: "Gilroy-Medium";
    margin-left: 15px;
  }
  .filter-title {
    color: ${COLORS.white};
    margin-right: 15px;
    text-transform: capitalize;
  }

  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 4px 0 20px;
    .chart-title-desc {
      margin-bottom: 0px;
    }
  }
  .selected-not-highlighted {
    opacity: 0.5;
  }
`;
