//import { COLORS } from "assets/colors/colors";
import styled from "styled-components";

export const Styles = styled.div.attrs((props) => props)`
  .wrapper-compare {
    position: relative;
  }
  .compare-box {
    position: relative;
    height: 44px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    background: #eef2f7;
    padding: 0 15px;
    font-size: 14px;
    color: #000000;
    border-radius: 4px;
    cursor: pointer;
    font-family: "Gilroy-Medium";
    margin-left: 15px;

    & > span {
      padding-right: 20px;
    }
  }
  .dropdown {
    width: 180px;
    border-radius: 2px;
    box-shadow: 0 30px 50px 0 rgba(29, 30, 78, 0.1),
      0 0 6px 0 rgba(27, 28, 51, 0.08);
    background-color: #ffffff;
    position: absolute;
    padding: 8px 0;
    top: 50px;
    right: 0;
    z-index: 100;
    display: flex;
    flex-direction: column;
  }
  .dropdown-value {
    height: 48px;
    padding: 15px 12px;
    display: flex;
    align-items: center;
    font-family: Gilroy-Medium;
    font-size: 14px;
    cursor: pointer;
    position: relative;
    &:hover {
      background: #d9dfe6;
    }
  }
  .metric {
    width: 205px;
    top: 0;
    right: 180px;
  }
  .metric-value {
    & span {
      margin-left: 8px;
    }
    & > label {
      & span {
        margin: 0;
      }
    }
  }
  .order {
    width: 290px;
    top: 0;
    right: 180px;
  }
  .order-value {
    justify-content: space-between;
    height: 60px;
    padding: 0 12px;
    & > div {
      & > span {
        margin-left: 16px;
        font-size: 12px;
        font-family: Gilroy-ExtraBold;
      }
    }

    & > div {
      & > label {
        & span {
          margin: 0;
        }
      }
    }
    .title-wrapper {
      display: flex;
      align-items: center;
    }
    .input-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      & > div {
        max-width: 100px;
        margin: 0;
        .ant-select.ant-select-single {
          width: 100px;
        }
      }
    }
    .input {
      position: relative;
      & input {
        width: 100px;
        height: 44px;
        padding: 10px 10px 10px 20px;
        border-radius: 2px;
        background-color: #eef2f7;
        border: none;
        outline: none;
      }
      & img {
        top: 8px;
        right: 10px;
      }
    }
  }
  .reset-wrapper {
    height: 60px;
    border-top: 1px solid #e0e0e4;
    display: flex;
    align-items: center;
    padding: 0 15px;
  }
  .reset-btn {
    font-family: Gilroy-Medium;
    font-size: 16px;
    font-weight: 800;
    color: ${(props) => props.color};
  }
  .remove-wrapper {
    height: 60px;
    border-top: 1px solid #e0e0e4;
    display: flex;
    align-items: center;
    padding: 0 15px;
    justify-content: space-between;
    align-items: center;
  }
  .apply-btn {
    width: 70px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: ${(props) => props.color};
    color: #fff;
  }
  .retailer {
    width: 165px;
    top: 0;
    right: 180px;
  }
  .order {
    width: 290px;
    top: 0;
    right: 180px;
  }
  .goals {
    width: 290px;
    top: -144px;
    right: 180px;
  }
  .order-single-item {
    width: 55px;
    height: 36px;
    border-radius: 4px;
    border: solid 1px #dfdfea;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Gilroy-Medium;
    font-size: 12px;
    font-weight: bold;
    color: #9696a0;
  }
  .render-component-order {
    display: flex;
    flex-direction: column;
    padding: 8px 11px;
    & > span {
      margin-bottom: 11px;
      font-family: Gilroy-Medium;
      font-size: 12px;
      font-weight: bold;
      color: #9696a0;
    }
  }
  .retailer-box {
    margin: 5px;
  }
  .title-box {
    font-family: Gilroy-Medium;
    font-size: 12px;
    font-weight: bold;
    color: #9696a0;
    padding: 10px 12px;
  }
`;
