import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//Components
import MainFilters from "components/Filters/MainFilters";
import NavBar from "components/NavBar";
import RatingTopTabs from "tabs/RatingTabs/RatingTopTabs";
//Actions
import { setRatingSummaryFilter } from "store/rating/ratingSummary/actions";
import {
  setPristineProductInsight,
  setProductInsightFilter,
} from "store/rating/productInsight/actions";
import {
  setPristineTrendAnalysisChart,
  setTrendAnalysisFilter,
} from "store/rating/trendAnalysis/actions";
import { setPristineRatingSummaryChart } from "store/rating/ratingSummary/actions";
import { setPristineRatingSummaryTable } from "store/rating/ratingSummaryBottom/actions";
import { setPristineTrendAnalysisTable } from "store/rating/trendAnalysisTable/actions";
import { STATE_STATUSES } from "utils/statuses";
import LoaderBox from "components/LoaderBox";
import useFilters from "../hooks/useFilters";

const Rating = () => {
  //Selectors
  const { filter } = useSelector((state) => state.ratingSummary);
  const { prepareLogoutStatus, status: statusAuth } = useSelector((state) => state.authorization);

  const { filter: productInsightFilter } = useSelector(
    (state) => state.productInsight
  );
  const { filter: trendAnalysisFilter } = useSelector(
    (state) => state.trendAnalysis
  );
  const { status: filtersStatus } = useSelector((state) => state.filters);


  //states
  const [activeTab, setActiveTab] = useState("1");
  const {
    mainLastFilter,
    statusMainFilters,
    universalFiltersStatus,
    statusFilters,
    titleDateStatus,
  } = useFilters();

  //Conts
  const dispatch = useDispatch();
  const firstTab = activeTab === "1";
  const secondTab = activeTab === "2";
  const isSelectAllTimePeriods = firstTab
    ? filter[1].value
    : secondTab
    ? productInsightFilter[1].value
    : trendAnalysisFilter[2].value;

    const isSelectAllTimePeriodsHandler = () => {
      const currentFilters = firstTab ? filter : secondTab ? productInsightFilter : trendAnalysisFilter;
      const currentShowAll = currentFilters.find(item => item.name === "showAll").value;
      
      setSelectValue({ showAll: currentShowAll });
    };
    

  const setSelectValue = (values) => {
    const valueKeys = Object.keys(values);
    const filters = firstTab
      ? filter
      : secondTab
      ? productInsightFilter
      : trendAnalysisFilter;
    const func = firstTab
      ? setRatingSummaryFilter
      : secondTab
      ? setProductInsightFilter
      : setTrendAnalysisFilter;
  
    const updateData = filters.map((item) => {
      if (valueKeys.includes(item.name)) {
        return {
          ...item,
          value: values[item.name],
        };
      }
      return item;
    });
  
    dispatch(func(updateData));
  };
  

  const getActionTop = (value) =>
    [
      setPristineRatingSummaryChart(value),
      setPristineProductInsight(value),
      setPristineTrendAnalysisChart(value),
    ][+activeTab - 1];
  const getPristineTop = +activeTab > 1 ? false : true;
  const getActionBottom = (value) =>
    [
      setPristineRatingSummaryTable(value),
      () => {},
      setPristineTrendAnalysisTable,
    ][+activeTab - 1];
  const getPristineBottom = +activeTab > 1 ? false : true;

  useEffect(() => {
    return () => {
      dispatch(getActionTop(getPristineTop));
      dispatch(getActionBottom(getPristineBottom));
    };
  }, []);

  useEffect(() => {
    if (filtersStatus === STATE_STATUSES.PENDING) {
      dispatch(getActionTop(true));
      dispatch(getActionBottom(true));
    }
  }, [filtersStatus]);

  return (
    <div className="main-page-wrapper">
      <NavBar />
      <div className="main-page-box">
        <MainFilters
          isSelectAllTimePeriods={isSelectAllTimePeriods}
          isSelectAllTimePeriodsHandler={isSelectAllTimePeriodsHandler}
        />
        <h1 className="main-page-header">Ratings & Reviews</h1>
        <div className="main-tabs-box">
          <RatingTopTabs currentTab={activeTab} setCurrentTab={setActiveTab} />
        </div>
      </div>
      {
        statusAuth !== STATE_STATUSES.PENDING &&
        mainLastFilter &&
        statusMainFilters === STATE_STATUSES.READY &&
        universalFiltersStatus === STATE_STATUSES.READY &&
        statusFilters === STATE_STATUSES.READY &&
        titleDateStatus === STATE_STATUSES.READY &&
        prepareLogoutStatus === STATE_STATUSES.INIT ? null : <LoaderBox isOpacity={true} />
      }
      </div>
  );
};

export default Rating;