import { COLORS } from "assets/colors/colors";
import styled from "styled-components";

export const Styles = styled.div`
  .popup-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 8px;

    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
    .popup-box {
      max-width: 1240px;
      min-width: 840px;
      height: calc(100vh - 80px);
      overflow: hidden;
      width: 100%;
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 8px 20px 0 rgba(30, 31, 32, 0.18), 0 2px 4px 0 rgba(0, 0, 0, 0.17);
      padding: 40px;
      position: relative;

      .cancel {
        position: absolute;
        width: 24px;
        height: 24px;
        right: 40px;
        top: 40px;
        cursor: pointer;
      }
    }
  }
  .header {
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 42px;
    & > span {
      font-size: 22px;
      font-family: Gilroy-ExtraBold;
    }
    .group-btns {
      display: flex;
      background: ${COLORS.lightGray};
      height: fit-content;
      margin-right: 17px;
      border-radius: 5px;
      padding: 3px;
      & > div {
        width: 34px;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        cursor: pointer;
        & > img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .scroll {
    height: 672px;
    overflow-x: hidden;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #abaaba;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #807f97;
    }
    .screenshot-text {
      font-size: 14px;
      margin-bottom: 16px;
      font-family: "Gilroy-Medium";
    }
    .screenshot {
      display: flex;
      flex: 1;
      & > img {
        max-width: 100%;
      }
    }
    .row-periods {
      display: flex;
      & > div {
        height: 92px;
        padding: 14px 16px;
        background: ${COLORS.lightGrayOpacity};
        font-family: Gilroy-ExtraBold;
        font-weight: bold;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        & > :last-child {
          margin-top: 2px;
          font-size: 22px;
        }
      }
      .days {
        width: 100px;
        margin-right: 16px;
      }
      .start-end {
        width: 380px;
      }
    }
    .banner-wrapper {
      margin: 16px 0 30px;
      border: 1px solid #ececf0;
      border-radius: 5px;
      padding: 6px;
      display: flex;
      justify-content: center;
      align-items: center;

      & > span {
        color: #ececf0;
        padding: 12px;
      }
    }
    .products {
      display: flex;
      flex-direction: column;
      .products-title {
        font-size: 12px;
        font-family: Gilroy-Medium;
        color: ${COLORS.forGraytext};
        margin-bottom: 16px;
        font-weight: bold;
      }
      .products-container {
        .product-row {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 20px 0;
          border-top: 1px solid ${COLORS.lightGray};

          & > img {
            width: 40px;
            height: 40px;
            margin-right: 20px;
          }
          & > span {
            font-size: 14px;
            font-family: Gilroy-Medium;
          }
        }
        & > :first-child {
          border-top: 2px solid ${COLORS.lightGray};
        }
      }
    }
  }
`;
