import { COLORS } from "assets/colors/colors";
import styled from "styled-components";

export const Styles = styled.div`
  overflow-x: auto;
  width: 100%;
  .current-table-box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    min-height: 200px;
  }
  .wrapper-table-box {
    width: 100%;

    display: inline-block;
    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #abaaba;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #807f97;
    }
  }
  .box-wrapper {
    width: 100%;
    display: flex;
  }
  .title,
  .title-search {
    width: 408px !important;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #dfdfea;
    border-left: none;
    border-right: none;
    flex-wrap: no-wrap;
    .image {
      width: 50px;
      height: 50px;
    }
    span {
      width: 408px !important;
      font-family: "Gilroy-Medium";
      font-size: 14px;
      font-weight: 500;
      line-height: 1.29;
      color: #000000;
      padding: 0 10px;
    }
    .search {
      width: 408px !important;
      position: relative;
      font-size: 14px;
      color: #000000;

      img {
        position: absolute;
        top: 10px;
        left: 10px;
      }
      input {
        border: none;
        background-color: #eef2f7;
        width: 380px;
        height: 44px;
        border-radius: 2px;
        outline: none;
        padding-left: 40px;
        padding-right: 12px;
      }
    }
  }
  .title-search {
    border: none;
  }
  .search-sort-btn {
    left: 380px !important;
    cursor: pointer;
  }
  .wrapper {
    // width: 100%;
    display: flex;
    .no-listed {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
    }
    .table-item-key-content {
      height: 90px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      border: 1px solid #dfdfea;
      border-right: none;
      padding: 9px 0;
      max-width: 366px;
      .img-wrapper {
        justify-content: center !important;
        width: 116px;
      }
      & > div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 366px;
        padding: 0 20px;
        .not-listed {
          color: #abaaba;
          font-size: 12px;
          font-family: "Gilroy-ExtraBold";
        }
        & > span {
          font-family: "Gilroy-Medium";
          font-size: 13px;
          color: ${COLORS.dark};
        }

        & > img {
          max-width: 100%;
          height: 62px;
          cursor: pointer;
        }
      }
    }
  }
  .table-item.sort {
    display: flex;
    flex-direction: row;
    border: none;
    align-items: center;
    flex: 0 0 366px;
    img {
      width: 25px;
    }
  }
  .table-item-key-title {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: flex-start !important;
    & > div {
      font-family: "Gilroy-Medium";
      font-size: 13px;
    }
    & > :last-child {
      font-size: 11px !important;
      opacity: 0.65;
    }
  }
  .table-item-title-highlighted {
    color: #ff6866;
  }
`;
