import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Pagination } from "antd";
import useColorTheme from   "../../../../hooks/useColorTheme";
import useFilters from "../../../../hooks/useFilters";

//Actions
import {
  addToDataRetailerAvailability,
  fetchRetailerAvailability,
  setRetailerAvailabilityFilter,
  fetchRetailerAvailabilityPopup,
  resetRetailerAvailabilityPopup,
} from "store/availability/retailerAvailability/actions";
//Utils
import { getTimePeriod } from "utils/getTimePeriod";
import { STATE_STATUSES } from "utils/statuses";
import { filtersRetailerAvailability } from "constants/filters";

//Components
import { Styles } from "./styles";
import { RetailerAvailabilityTable } from "./components/RetailerAvailabilityTable";
import DiscountFilter from "components/Filters/DiscountFilter/DiscountFilter";
import LoaderBox from "components/LoaderBox";
import PopupAvailability from "components/Popups/PopupAvailability";
import SelectBox from "components/Selects/SelectBox";
import ShowPerPage from "components/ShowPerPage";

const RetailerAvailability = () => {
  const { primaryColor } = useColorTheme();
  //Selectors
  const { status, retailerAvailability, controlData, filter, availabilityPopup } = useSelector((state) => state.retailerAvailability);
  const { filters: globalFilters, status: filtersStatus } = useSelector((state) => state.filters);
  const { mainLastFilter: lastFilter, statusFilters } = useFilters();
  //States
  const [minMaxValues, setMinMaxValues] = useState({
    minValue: 0,
    maxValue: 100,
  });
  const [sortId, setSortId] = useState("0");
  const [sortDirection, setSortDirection] = useState(true);
  const [inputSearch, setInputSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [showPopup, setShowPopup] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [queryParams, setQueryParams] = useState({});
  const [popupQueryParams, setPopupQueryParams] = useState({});
  const [idsForPopupRequest, setIdForPopupRequest] = useState({});
  const [clickedArray, setClickedArray] = useState([]);
  const [filterTouched, setFilterTouched] = useState(false);

  //Const
  const dispatch = useDispatch();
  const baseQuery = {
    limit: 10,
    page: 1,
    sort: "0|asc",
    search: "",
  };
  const minMaxControlValues = {
    minValue: 0,
    maxValue: 99,
  };
  const searchBy = filter[2].value === "manufacture" ? "manufacturer" : filter[2].value;

  useEffect(() => {
    if (statusFilters !== STATE_STATUSES.READY) return;

    setQueryParams((prevState) => ({
      ...prevState,
      sourceType: lastFilter.sourceType,
      timePeriod: getTimePeriod(lastFilter.date),
      product: lastFilter.product,
      groupBy: filter[2].value,
      ...baseQuery,
    }));

    setLimit(10);
    setPage(1);
    setSortDirection(true);
    setSortId("0");
  }, [lastFilter, filter[2].value, statusFilters]);

  useEffect(() => {
    if (Object.keys(idsForPopupRequest).length) {
      setPopupQueryParams((prevState) => ({
        ...prevState,
        timePeriod: getTimePeriod(lastFilter.date),
        type: filter[2].value,
        retailer: idsForPopupRequest.retailer,
      }));
    }
  }, [lastFilter, filter[2].value, idsForPopupRequest]);

  useEffect(() => {
    if (Object.keys(queryParams).length && statusFilters === STATE_STATUSES.READY) {
      dispatch(fetchRetailerAvailability(queryParams));
    }
    setClickedArray([]);
  }, [queryParams, statusFilters, dispatch]);

  useEffect(() => {
    if (Object.keys(popupQueryParams).length && Object.keys(idsForPopupRequest).length) {
      dispatch(fetchRetailerAvailabilityPopup(idsForPopupRequest.id, popupQueryParams));
    }
  }, [popupQueryParams, idsForPopupRequest]);

  useEffect(() => {
    const incomingRetailers = Object.keys(controlData.average);
    const globalRetailers = globalFilters.sourceType;

    const { result } = controlData;
    const min = filter[1].value[0];
    const max = filter[1].value[1];

    if (globalRetailers.length && incomingRetailers.length && result.length) {
      const retailers = globalRetailers.filter(({ id }) => incomingRetailers.some((el) => id === +el));

      const sortedRetailers = retailers.sort((a, b) => {
        return incomingRetailers.indexOf(String(a.id)) - incomingRetailers.indexOf(String(b.id));
      });

      const averageAvailability = retailers.map(({ name, id }) => {
        return {
          retailerName: name,
          available: controlData.average[id],
        };
      });

      const rows = result.map(({ row, children }) => {
        const dataWithoutProduct = Object.entries(row).slice(1);
        const data = addStatusToItemsArr(dataWithoutProduct, min, max, true);

        return {
          title: row[0].title || row[0].name,
          color: row[0].color,
          image: row[0].image,
          data,
          id: row[0].id,
          children: getChildren(children, min, max),
        };
      });

      dispatch(addToDataRetailerAvailability({ retailers: sortedRetailers, averageAvailability, rows }));
    }
  }, [controlData, globalFilters.sourceType, filter[1].value]);

  const getChildren = (arr, min, max) => {
    const mapedChildren = arr?.length
      ? arr.map((el) => {
          return Object.values(el?.row);
        })
      : [];

    const arrWithChildren = arr?.length && arr[0].children ? arr.map((el) => el.children) : [];

    const childrenNames = mapedChildren.length ? mapedChildren.map((el) => el[0]) : [];
    const childrenData = mapedChildren.length ? mapedChildren.map((el) => el.slice(1)) : [];

    const desiredChildrenData = childrenData.length ? addStatusToItemsObj(childrenData, min, max) : [];

    const childrens = childrenNames.map((el, i) => {
      return {
        title: el.title || el.name,
        color: el.color,
        image: el.image,
        data: desiredChildrenData[i],
        id: el.id,
        children: arrWithChildren.length ? formatDataFromChildren(arrWithChildren[i], min, max) : [],
      };
    });

    return childrens;
  };

  const formatDataFromChildren = (arr, min, max) => {
    const result = arr.map(({ row }) => {
      const arrOfValues = Object.values(row);
      const el = arrOfValues[0];
      const data = arrOfValues.slice(1);
      const desiredData = addStatus(data, min, max);

      return {
        title: el.title || el.name,
        color: el.color,
        image: el.image,
        data: desiredData,
        id: el.id,
        children: [],
      };
    });

    return result;
  };

  const addStatus = (arr, min, max) => {
    return arr.map((el) => {
      const status = el.available === 0 ? "" : el.available <= max && el.available >= min && filterTouched ? "highlighted" : "";

      return { ...el, status };
    });
  };

  const addStatusToItemsObj = (arr, min, max) => {
    const data = arr.map((item) => {
      return addStatus(item, min, max);
    });

    return data;
  };

  const addStatusToItemsArr = (arr, min, max, withKey) => {
    const data = arr.map((item) => {
      const el = withKey ? item[1] : item;

      const status = el.available === 0 ? "" : el.available <= max && el.available >= min && filterTouched ? "highlighted" : "";

      return { ...el, status };
    });
    return data;
  };

  const setSelectValue = (values) => {
    const value = Object.keys(values);

    const updateData = filter.map((item) => {
      if (item.name === value[0]) {
        return {
          ...item,
          value: values[value[0]],
        };
      } else if (item.name === value[1]) {
        return {
          ...item,
          value: values[value[1]],
        };
      }

      return item;
    });
    dispatch(setRetailerAvailabilityFilter(updateData));
  };

  useEffect(() => {
    const minValue = filter[1].value[0];
    const maxValue = filter[1].value[1];

    setMinMaxValues({ minValue, maxValue });
  }, [filter[1].value]);

  const handleSearch = (value) => {
    setInputSearch(value);
    setQueryParams((prevState) => ({
      ...prevState,
      search: value,
      page: 1,
    }));
  };

  const handleSort = (id) => {
    setSortDirection(!sortDirection);

    changePage(1);

    setSortId(id);

    const sort = `${id}|${!sortDirection ? "asc" : "desc"}`;

    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      sort,
    }));
  };

  const changePage = (page, lim) => {
    setPage(page);

    setQueryParams((prevState) => ({
      ...prevState,
      page,
    }));
  };

  const changeLimitHandler = (limit) => {
    setLimit(limit);
    changePage(1);

    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      limit,
    }));
  };

  useEffect(() => {
    if (controlData.result.length) {
      setIsEmpty(false);
    } else {
      setIsEmpty(true);
    }
  }, [controlData]);

  const onPopupClose = () => {
    setShowPopup(false);
    setIdForPopupRequest({});
    dispatch(resetRetailerAvailabilityPopup());
  };

  const renderTable = () => {
    return (
      <RetailerAvailabilityTable
        data={retailerAvailability}
        sortId={sortId}
        sortDirection={sortDirection}
        handleSearch={handleSearch}
        inputSearch={inputSearch}
        handleSort={handleSort}
        setShowPopup={setShowPopup}
        isEmpty={isEmpty}
        setIdForPopupRequest={setIdForPopupRequest}
        clickedArray={clickedArray}
        setClickedArray={setClickedArray}
        filterBy={filter[2].value}
        searchBy={searchBy}
      />
    );
  };

  return (
    <Styles color={primaryColor}>
      <>
        <div className="filters-box" style={{ zIndex: 3 }}>
          <div onClick={() => setFilterTouched(true)}>
            <DiscountFilter
              data={filter[0]}
              setSelectValue={setSelectValue}
              minMaxValues={minMaxValues}
              onlyRange={true}
              typeOfDiscount={"count"}
              rangeTitle={"Filter Availability"}
              minMaxControlValues={minMaxControlValues}
              disabled={status !== STATE_STATUSES.READY || filtersStatus !== STATE_STATUSES.READY}
            />
          </div>

          <SelectBox
            filter={filter[2].value}
            data={filtersRetailerAvailability[1]}
            setSelectValue={setSelectValue}
            disabled={status !== STATE_STATUSES.READY || filtersStatus !== STATE_STATUSES.READY}
          />
        </div>
        <div className="wrapper-box-relative" style={{ marginTop: 36 }}>
          {controlData.success && filtersStatus === STATE_STATUSES.READY ? <div className="chart-wrapper-box">{renderTable()}</div> : null}
          {controlData.success && filtersStatus === STATE_STATUSES.READY && !isEmpty ? (
            <div className="pagination-with-per-page">
              <Pagination
                className="pagination-controls"
                onChange={changePage}
                current={page}
                pageSize={limit}
                total={controlData.total}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
              />
              <ShowPerPage setLimit={changeLimitHandler} value={limit} />
            </div>
          ) : null}
          {status === STATE_STATUSES.PENDING || filtersStatus === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
          {showPopup && availabilityPopup.success ? (
            <PopupAvailability
              data={availabilityPopup}
              onClose={() => {
                onPopupClose();
              }}
            />
          ) : null}
        </div>
      </>
    </Styles>
  );
};

export default RetailerAvailability;
