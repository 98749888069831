import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//Components
import MainFilters from "components/Filters/MainFilters";
import NavBar from "components/NavBar";
import ContentTopTabs from "tabs/ContentTabs/ContentTopTabs/ContentTopTabs";
import { STATE_STATUSES } from "utils/statuses";
import LoaderBox from "components/LoaderBox";
import useFilters from "../hooks/useFilters";

//Actions
import { setPristineCurrentContent } from "store/content/currentContent/actions";
import { setPristineCurrentContentTable } from "store/content/currentContentTable/actions";
import { setPristineKeyContent } from "store/content/keyContent/actions";
import { setPristineBenchmarkContent } from "store/content/benchmarkContent/actions";
import { setPristineContentTrend } from "store/content/contentTrend/actions";

const Content = () => {
  const { status: filtersStatus } = useSelector((state) => state.filters);
  const { prepareLogoutStatus, status: statusAuth } = useSelector((state) => state.authorization);

  //States
  const [activeTabTop, setActiveTabTop] = useState("1");
  const {
    mainLastFilter,
    statusMainFilters,
    universalFiltersStatus,
    statusFilters,
    titleDateStatus,
  } = useFilters();
  //Constants
  const dispatch = useDispatch();

  const getActionTop =  useCallback((value) =>
    [
      setPristineCurrentContent(value),
      setPristineKeyContent(value),
      setPristineBenchmarkContent(value),
      setPristineContentTrend(value),
    ][+activeTabTop - 1],
  [activeTabTop]);

  const getPristineTop = +activeTabTop > 1 ? false : true;
  const getActionBottom = (value) => setPristineCurrentContentTable(value);
  const getPristineBottom = true;

  useEffect(() => {
    return () => {
      dispatch(getActionTop(getPristineTop));
      dispatch(getActionBottom(getPristineBottom));
    };
  }, []);

  useEffect(() => {
    if (filtersStatus === STATE_STATUSES.PENDING) {
      dispatch(getActionTop(true));
      dispatch(getActionBottom(true));
    }
  }, [filtersStatus, dispatch, getActionTop]);

  return (
    <div className="main-page-wrapper">
      <NavBar />
      <div className="main-page-box">
        <MainFilters />
        <h1 className="main-page-header">Content</h1>
        <div className="main-tabs-box">
          <ContentTopTabs
            activeTabTop={activeTabTop}
            setActiveTabTop={setActiveTabTop}
          />
        </div>
      </div>
      {
        statusAuth !== STATE_STATUSES.PENDING &&
        mainLastFilter &&
        statusMainFilters === STATE_STATUSES.READY &&
        universalFiltersStatus === STATE_STATUSES.READY &&
        statusFilters === STATE_STATUSES.READY &&
        titleDateStatus === STATE_STATUSES.READY &&
        prepareLogoutStatus === STATE_STATUSES.INIT ? null : <LoaderBox isOpacity={true} />
      }
    </div>
  );
};

export default Content;
