import React from "react";
import { Empty } from "antd";

const RenderNoData = ({ style }) => {
  return (
    <div className="emptyData" style={style}>
      <Empty description={<span>Looks like we do not have data for this request</span>} />
    </div>
  );
};

export default RenderNoData;
