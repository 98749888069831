import { success, error } from "redux-saga-requests";
import { FETCH_RATING_SUMMARY_PRODUCT, FETCH_RATING_SUMMARY_REVIEWS, FETCH_RATING_SUMMARY_REVIEWS_ALL, FETCH_RATING_SUMMARY_CORE_RETAILERS } from "./actions";
import { STATE_STATUSES } from "utils/statuses";
import { RESET_ERROR } from "store/error/actions";

const initialState = {
  ratingSummaryProduct: {
    success: false,
  },
  ratingSummaryReviews: {
    success: false,
    reviews: [],
  },
  ratingSummaryReviewsAll: {
    success: false,
    reviews: [],
  },
  ratingSummaryCoreRetailers: {
    success: false,
    coreRetailers: [],
  },
  status: STATE_STATUSES.INIT,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RATING_SUMMARY_PRODUCT: {
      return processReducer(state);
    }
    case FETCH_RATING_SUMMARY_REVIEWS: {
      return processReducerReviews(state);
    }
    case FETCH_RATING_SUMMARY_REVIEWS_ALL: {
      return processReducerReviewsAll(state);
    }
    case FETCH_RATING_SUMMARY_CORE_RETAILERS: {
      return processReducerCoreRetailers(state);
    }

    case success(FETCH_RATING_SUMMARY_PRODUCT): {
      return {
        ...state,
        ratingSummaryProduct: action.data,
        status: STATE_STATUSES.READY,
      };
    }
    case success(FETCH_RATING_SUMMARY_REVIEWS): {
      return {
        ...state,
        ratingSummaryReviews: action.data,
        status: STATE_STATUSES.READY,
      };
    }
    case success(FETCH_RATING_SUMMARY_REVIEWS_ALL): {
      return {
        ...state,
        ratingSummaryReviewsAll: action.data,
        status: STATE_STATUSES.READY,
      };
    }
    case success(FETCH_RATING_SUMMARY_CORE_RETAILERS): {
      return {
        ...state,
        ratingSummaryCoreRetailers: action.data,
        status: STATE_STATUSES.READY,
      };
    }

    case error(FETCH_RATING_SUMMARY_PRODUCT): {
      const error = action.error.response;
      return errorReducer(state, error);
    }
    case error(FETCH_RATING_SUMMARY_REVIEWS): {
      const error = action.error.response;
      return errorReducerReviews(state, error);
    }
    case error(FETCH_RATING_SUMMARY_REVIEWS_ALL): {
      const error = action.error.response;
      return errorReducerReviewsAll(state, error);
    }
    case error(FETCH_RATING_SUMMARY_CORE_RETAILERS): {
      const error = action.error.response;
      return errorReducerCoreRetailers(state, error);
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  ratingSummaryProduct: {
    ...state.ratingSummaryProduct,
    success: false,
  },
  error,
});

const errorReducerReviews = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  ratingSummaryReviews: {
    ...state.ratingSummaryReviews,
    success: false,
  },
  error,
});

const errorReducerReviewsAll = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  ratingSummaryReviewsAll: {
    ...state.ratingSummaryReviewsAll,
    success: false,
  },
  error,
});

const errorReducerCoreRetailers = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  ratingSummaryCoreRetailers: {
    ...state.ratingSummaryCoreRetailers,
    success: false,
  },
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  ratingSummaryProduct: {
    ...state.ratingSummaryProduct,
    success: false,
  },
  error: null,
});

const processReducerReviews = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  ratingSummaryReviews: {
    ...state.ratingSummaryReviews,
    success: false,
  },
  error: null,
});

const processReducerReviewsAll = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  ratingSummaryReviewsAll: {
    ...state.ratingSummaryReviewsAll,
    success: false,
  },
  error: null,
});

const processReducerCoreRetailers = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  ratingSummaryCoreRetailers: {
    ...state.ratingSummaryCoreRetailers,
    success: false,
  },
  error: null,
});