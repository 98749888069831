import styled from "styled-components";

export const Styles = styled.div`
  .select-wrapper {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    & > span {
      font-family: Gilroy-Medium;
    }
  }
  .select {
    width: 72px;
    margin: 0 15px;
    height: 44px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    height: 44px;
    padding: 10px 10px 10px 15px;
    border-radius: 4px;
    background-color: #eef2f7;
    display: flex;
    align-items: center;
    .ant-select-selection-item {
      .ant-radio {
        display: none;
      }
      & span {
        padding: 0;
      }
    }
  }
`;
