const url = process.env.REACT_APP_URL;

export const FETCH_SHARE_OF_MEDIA_TOP = "FETCH_SHARE_OF_MEDIA_TOP";
export const fetchShareOfMediaTop = (params) => ({
  type: FETCH_SHARE_OF_MEDIA_TOP,
  request: {
    url: `${url}/v2/media/share/chart`,
    method: "POST",
    data: params,
  },
});

export const SET_SHARE_OF_MEDIA_TOP_FILTER = "SET_SHARE_OF_MEDIA_TOP_FILTER";
export const setFilterShareOfMediaTop = (filter) => {
  localStorage.setItem(SET_SHARE_OF_MEDIA_TOP_FILTER, JSON.stringify(filter));
  return {
    type: SET_SHARE_OF_MEDIA_TOP_FILTER,
    payload: filter,
  };
};

export const SET_PRISTINE_SHARE_OF_MEDIA_TOP = "SET_PRISTINE_SHARE_OF_MEDIA_TOP";
export const setPristineShareOfMediaTop = (pristine) => {
  return {
    type: SET_PRISTINE_SHARE_OF_MEDIA_TOP,
    payload: pristine,
  };
};
