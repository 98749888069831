import { success, error } from "redux-saga-requests";
import { FETCH_TREND_ANALYSIS_TABLE, FETCH_TREND_ANALYSIS_TABLE_ALL, SET_PRISTINE_TREND_ANALYSIS_TABLE } from "./actions";
import { STATE_STATUSES } from "utils/statuses";
import { RESET_ERROR } from "store/error/actions";

const initialState = {
  trendAnalysisTable: {
    success: false,
    byBrand: { total: { productCount: 0 }, result: [] },
    byRetailer: { total: { productCount: 0 }, result: [] },
    byTotal: { total: { productCount: 0 }, result: [] },
  },
  trendAnalysisTableAll: {
    success: false,
    byBrand: { total: { productCount: 0 }, result: [] },
    byRetailer: { total: { productCount: 0 }, result: [] },
    byTotal: { total: { productCount: 0 }, result: [] },
  },

  status: STATE_STATUSES.INIT,
  isPristine: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TREND_ANALYSIS_TABLE: {
      return processReducer(state);
    }
    case success(FETCH_TREND_ANALYSIS_TABLE): {
      return {
        ...state,
        trendAnalysisTable: action.data,
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }

    case error(FETCH_TREND_ANALYSIS_TABLE): {
      const error = action.error.response;
      return errorReducer(state, error);
    }
    case FETCH_TREND_ANALYSIS_TABLE_ALL: {
        return processReducerAll(state);
    }

    case success(FETCH_TREND_ANALYSIS_TABLE_ALL): {
        return {
            ...state,
            trendAnalysisTableAll: action.data,
            status: STATE_STATUSES.READY,
            isPristine: false,
        };
    }

    case error(FETCH_TREND_ANALYSIS_TABLE_ALL): {
        const error = action.error.response;
        return errorReducerAll(state, error);
    }

    case SET_PRISTINE_TREND_ANALYSIS_TABLE: {
      return {
        ...state,
        isPristine: action.payload,
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});

const errorReducerAll = (state, error) => ({
    ...state,
    status: STATE_STATUSES.ERROR,
    isPristine: false,
    error,
  });
  
  const processReducerAll = (state) => ({
    ...state,
    status: STATE_STATUSES.PENDING,
    error: null,
  });
  
