import React from "react";
import useColorTheme from "../../../hooks/useColorTheme";
import { Styles } from "./Styles";
import cancel2 from "assets/images/cancel2.svg";
import useRetailers from "../../../hooks/useRetailers";
import BenchmarkTable from "components/BenchmarkTable";
import { CURRENT_CONTENT } from "utils/currentContentHelper";

const PopupCurrentContent = ({ product, coreProduct, closePopup }) => {
  const { primaryColor } = useColorTheme();
  const { getRemoteLogo } = useRetailers();

  return (
    <Styles color={primaryColor}>
      <div className="popup-wrapper">
        <div className="popup-box">
          <img className="cancel" src={cancel2} alt="cancel" onClick={closePopup} />
          <div className="header">
            <span>{coreProduct.title}</span>
            <div className="retailer-website">
              <img src={getRemoteLogo(product?.retailer)} />{" "}
              <a href={product.href} target="_blank">
                See on Retailer Website
              </a>
            </div>
          </div>
          <div className="table">
            <BenchmarkTable product={product} coreProduct={coreProduct} type={CURRENT_CONTENT} />
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default PopupCurrentContent;
