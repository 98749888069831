import React from "react";
import { Tabs } from "antd";

import PriceTrendTab from "./PriceTrendTab/PriceTrendTab";
import ChangeTab from "./ChangeTab/ChangeTab";
import RankingTab from "./RankingTab/RankingTab";
import about from "assets/images/about.svg";
import { TabWithIcon } from "assets/icons/icons";

const PricingTopTabs = ({ setActiveTab }) => {
  const { TabPane } = Tabs;

  function callback(key) {
    setActiveTab(key);
  }

  return (
    <div className="main-box">
      <Tabs defaultActiveKey="1" onChange={callback}>
        <TabPane
          tab={
            <TabWithIcon
              tabName={"Price Trend"}
              icon={about}
              tooltip={"Overall the prices moved up 20% based on the selected filters."}
            />
          }
          key="1"
        >
          <PriceTrendTab />
        </TabPane>
        <TabPane
          tab={
            <TabWithIcon
              tabName={"Change"}
              icon={about}
              tooltip={"Overall the prices moved up 20% based on the selected filters."}
            />
          }
          key="2"
        >
          <ChangeTab />
        </TabPane>
        <TabPane
          tab={
            <TabWithIcon
              tabName={"Basket Analysis"}
              icon={about}
              tooltip={"Overall the prices moved up 20% based on the selected filters."}
            />
          }
          key="3"
        >
          <RankingTab />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default PricingTopTabs;
