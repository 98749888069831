const url = process.env.REACT_APP_URL;

//Ranking Search Retailer
export const FETCH_RANKING_SEARCH_TERMS_LIST = "FETCH_RANKING_SEARCH_TERMS_LIST";
export const fetchRankingSearchTermsList = (data) => ({
  type: FETCH_RANKING_SEARCH_TERMS_LIST,
  request: {
    url: `${url}/v2/ranking/search-analysis/search-terms`,
    method: "POST",
    data,
  },
});

export const FETCH_RANKING_RETAILERS_TREND = "FETCH_RANKING_RETAILERS_TREND";
export const fetchRankingRetailersTrend = (data) => ({
  type: FETCH_RANKING_RETAILERS_TREND,
  request: {
    url: `${url}/v2/ranking/search-analysis/charts`,
    method: "POST",
    data,
  },
});

export const FETCH_RETAILER_SINGLE_PRODUCT = "FETCH_RETAILER_SINGLE_PRODUCT";
export const fetchRetailerSingleProduct = (data, isSingle, retailer) => ({
  type: FETCH_RETAILER_SINGLE_PRODUCT,
  request: {
    url: `${url}/v2/ranking/search-analysis/products`,
    method: "POST",
    data,
  },
  meta: {
    takeLatest: false,
    asPromise: true,
    isSingle,
    retailer,
  },
});

export const REMOVE_RETAILER_PRODUCT = "REMOVE_RETAILER_PRODUCT";
export const removerRetailerProduct = (uid, retailer) => {
  return {
    type: REMOVE_RETAILER_PRODUCT,
    payload: {
      uid,
      retailer,
    },
  };
};

export const SET_PRISTINE_RETAILER_SEARCH = "SET_PRISTINE_RETAILER_SEARCH";
export const setPristineSearchRetailer = (pristine) => {
  return {
    type: SET_PRISTINE_RETAILER_SEARCH,
    payload: pristine,
  };
};
