import { COLORS } from "assets/colors/colors";
import styled from "styled-components";

export const Styles = styled.div`
  margin-top: 7px;
  .topMovers {
    position: relative;
    width: 400px;
    height: 335px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #abaaba;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #807f97;
    }
  }
  .filters {
    padding: 0 0 12px 24px;
    & > span {
      font-size: 12px;
      font-family: Gilroy-ExtraBold;
      color: ${COLORS.darkGray};
    }
  }

  .row {
    display: flex;
    padding: 10px 20px 10px 24px;
    margin: 5px;
    max-height: 56px;
    align-items: center;
    & > img {
      width: 40px;
      height: 40px;
      border-radius: 5px;
    }
    & > span {
      font-size: 14px;
      font-family: Gilroy-Medium;
    }

    .title {
      padding: 0 16px;
      display: flex;
      flex: 1;
      text-transform: capitalize;
    }

    .direction {
      width: 24px;
    }
  }
`;
