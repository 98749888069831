const url = process.env.REACT_APP_URL;

//Change
export const FETCH_CHANGE = "FETCH_CHANGE";
export const fetchChange = (data) => ({
  type: FETCH_CHANGE,
  request: {
    url: `${url}/v2/price/change`,
    method: "POST",
    data,
  },
});

export const FETCH_COMPARE_CHANGE = "FETCH_COMPARE_CHANGE";
export const fetchCompareChange = (data) => ({
  type: FETCH_COMPARE_CHANGE,
  request: {
    url: `${url}/v2/price/change`,
    method: "POST",
    data,
  },
  meta: {
    asPromise: true,
  },
});

export const CLEAR_COMPARE_CHANGE = "CLEAR_COMPARE_CHANGE";
export const clearCompareChange = () => {
  return {
    type: CLEAR_COMPARE_CHANGE,
  };
};

export const SET_PRISTINE_CHANGE = "SET_PRISTINE_CHANGE";
export const setPristineChange = (pristine) => {
  return {
    type: SET_PRISTINE_CHANGE,
    payload: pristine,
  };
};
