//Range Price
export const colorsTotalCurrent = {
  tesco: "#004995",
  asda: "#82BA27",
  sainsburys: "#FB8717",
  morrisons: "#FBC010",
  waitrose: "#31AF5C",
  ocado: "#4d216d",
  amazon: "#78D988",
  iceland: "#F14758",
  amazon_fresh: "#78d988",
};

export const chartRetailerBackgrounds = {
  tesco: [
    [0, "rgba(0, 73, 149, .2)"],
    [1, "rgba(0, 73, 149, 0)"],
  ],
  asda: [
    [0, "rgba(130, 186, 39, .2)"],
    [1, "rgba(130, 186, 39, 0)"],
  ],
  sainsburys: [
    [0, "rgba(251, 135, 23, .2)"],
    [1, "rgba(251, 135, 23, 0)"],
  ],
  morrisons: [
    [0, "rgba(251, 192, 16, .2)"],
    [1, "rgba(251, 192, 16, 0)"],
  ],
  waitrose: [
    [0, "rgba(49, 175, 92, .2)"],
    [1, "rgba(49, 175, 92, 0)"],
  ],
  ocado: [
    [0, "rgba(77, 33, 109, .2)"],
    [1, "rgba(77, 33, 109, 0)"],
  ],
  amazon: [
    [0, "rgba(120, 217, 136, .2)"],
    [1, "rgba(120, 217, 136, 0)"],
  ],
  iceland: [
    [0, "rgba(241, 71, 88, .2)"],
    [1, "rgba(241, 71, 88, 0)"],
  ],
  "amazon fresh": [
    [0, "rgba(120, 217, 136, .2)"],
    [1, "rgba(120, 217, 136, 0)"],
  ],
  amazon_fresh: [
    [0, "rgba(120, 217, 136, .2)"],
    [1, "rgba(120, 217, 136, 0)"],
  ],
};

export const greaterColor = [
  [0, "rgba(30, 198, 141, .2)"],
  [1, "rgba(30, 198, 141, .1)"],
];

export const lessColor = [
  [0, "rgba(254, 106, 104, .2)"],
  [1, "rgba(254, 106, 104, .1)"],
];

export const equallyColor = [
  [0, "rgba(160, 162, 166, .2)"],
  [1, "rgba(160, 162, 166, .1)"],
];

export const colorsStarCurrent = {
  tesco: "rgba(0, 73, 149, .7)",
  asda: "rgba(130, 186, 39, .7)",
  sainsburys: "rgba(251, 135, 23, .7)",
  morrisons: "rgba(251, 192, 16, .7)",
  waitrose: "rgba(49, 175, 92, .7)",
  ocado: "rgba(77, 33, 109, .7)",
  amazon: "rgba(120, 217, 136, .7)",
  iceland: "rgba(241, 71, 88, .7)",
  amazon_fresh: "rgba(120, 217, 136, .7)",
};

export const colorsTotalCompare = {
  tesco: "rgba(0, 73, 149, .5)",
  asda: "rgba(130, 186, 39, .5)",
  sainsburys: "rgba(251, 135, 23, .5)",
  morrisons: "rgba(251, 192, 16, .5)",
  waitrose: "rgba(49, 175, 92, .5)",
  ocado: "rgba(77, 33, 109, .5)",
  amazon: "rgba(120, 217, 136, .5)",
  iceland: "rgba(241, 71, 88, .5)",
  amazon_fresh: "rgba(120, 217, 136, .5)",
};

export const colorsStarCompare = {
  tesco: "rgba(0, 73, 149, .3)",
  asda: "rgba(130, 186, 39, .3)",
  sainsburys: "rgba(251, 135, 23, .3)",
  morrisons: "rgba(251, 192, 16, .3)",
  waitrose: "rgba(49, 175, 92, .3)",
  ocado: "rgba(77, 33, 109, .3)",
  amazon: "rgba(120, 217, 136, .3)",
  iceland: "rgba(241, 71, 88, .3)",
  amazon_fresh: "rgba(120, 217, 136, .3)",
};

export const currentColors = {
  moreExpensive: "#fe6a68",
  samePrice: "#a0a2a6",
  cheaper: "#1ec68d",
};

export const compareColors = {
  moreExpensive: "#FFB5B4",
  samePrice: "#D0D1D3",
  cheaper: "#8FE3C7",
};
