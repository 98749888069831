import { COLORS } from "assets/colors/colors";
import styled from "styled-components";

export const Styles = styled.div`
  .current-table-box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  .wrapper-table-box {
    width: 100%;
    overflow-x: auto;
    display: inline-block;
    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #abaaba;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #807f97;
    }
  }

  .box-wrapper {
    width: 100%;
    display: flex;
  }

  .wrapper {
    width: 100%;
    display: flex;

    .table-item {
      padding: 16px 10px;
      cursor: default;
      min-width: 115px;
      height: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      border: 1px solid #dfdfea;
      border-right: none;
      padding-left: 10px;
      & > img {
        width: 25px;
      }
      & > span {
        font-family: "Gilroy-Medium";
        font-size: 14px;
      }
    }
    & > :first-child {
      padding: 16px 10px 16px 0;
      width: 22.5%;
      border-left: none;
      padding-left: 0;
    }
    & > :nth-child(n + 2) {
      width: 14.8%;
      justify-content: center;
    }
    & > :nth-child(n + 3) {
      width: 24.2%;
      justify-content: flex-start;
      padding-left: 24px;
    }

    & > :nth-child(n + 4) {
      width: 23%;
      justify-content: center;
    }
    & > :last-child {
      width: 15.5%;
      justify-content: flex-start;
    }
  }
  .table-item.sort {
    flex-direction: row;
    border: none;
    cursor: pointer;
    align-items: center;
    border-top: 1px solid #dfdfea;
    & > span {
      font-family: "Gilroy-Medium";
      font-size: 12px;
      text-align: center;
      color: ${COLORS.darkGray};
      font-weight: bold;
    }
  }
  .manufacturers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .manufacture {
      display: flex;
      align-items: center;
      .manufacture-color {
        width: 12px;
        height: 12px;
        margin-right: 16px;
        border-radius: 2px;
      }
      .manufacture-name {
        font-family: Gilroy-Medium;
      }
    }
  }
  .location {
    display: flex;
    align-items: center;
    .location-icon {
      display: flex;
      & > img {
        max-width: 24px;
      }
      margin-right: 12px;
    }
    .location-name {
      font-family: Gilroy-Medium;
    }
  }
  .thumbnail-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 190px;
    width: 100%;
    padding: 8px 18px;
    border: 1px solid #ececf0;
    border-radius: 2px;
    & img {
      width: 100%;
    }
    .no-iamge {
      font-family: "Gilroy-Medium";
      font-size: 12px;
      text-align: center;
      color: #9696a0;
      font-weight: bold;
    }
  }
`;
