import styled from "styled-components";

export const Styles = styled.div`
  display: flex;
  flex-direction: column;
  .header {
    font-size: 14px;
    font-family: Gilroy-Medium;
    margin-bottom: 20px;
  }
  .row {
    display: flex;
  }
  .wrapper-dscount {
    width: auto;
    background: #eef2f7;
    border: none;
    height: 44px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin-left: 15px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    font-family: "Gilroy-Medium";
    position: absolute;
    top: -64px;
    right: 0;
  }
`;
