import { COLORS } from "assets/colors/colors";
import styled from "styled-components";

export const Styles = styled.div`
  .chartContainer {
    height: 70px;
  }
  .total-score-pie-chart {
    position: relative;
  }
  .label-inside {
    font-size: 10px;
    position: absolute;
    color: ${COLORS.almostBlack};
    top: 27px;
    left: 18px;
    font-family: "Gilroy-Medium";
    width: 35px;
    display: flex;
    justify-content: center;
  }
  .bottom-text {
    font-size: 14px;
    font-family: "Gilroy-Medium";
  }
`;
