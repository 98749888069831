import JSZip from 'jszip';
import moment from "moment";

export const saveAsZip = (files) => {
    const zip = new JSZip();
    if (files.length === 1) {
        const dataURI = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${files[0].file}`;
        const link = document.createElement('a');
        link.href = dataURI;
        link.download = `${files[0].label}.xlsx`;
        link.click();
    } else if (files.length > 1) {
        files.forEach((file, index) => {
            zip.file(`${file.label}.xlsx`, file.file, { base64: true });
        });
        zip.generateAsync({ type: 'blob' }).then((content) => {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(content);
            link.download = `reports-${moment().format('YYYYMMDDHHmmss')}.zip`;
            link.click();
        });
    }
};