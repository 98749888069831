import axios from "axios";
import { createRequestInstance, watchRequests } from "redux-saga-requests";
import { createDriver } from "redux-saga-requests-axios";

const instance = axios.create({
  timeout: 10000000,
  headers: axios.defaults.headers,
});

instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("Token") ? JSON.parse(localStorage.getItem("Token")) : "";
  config.headers.Authorization = token;

  return config;
});

export default function* rootSaga() {
  yield createRequestInstance({ driver: createDriver(instance) });
  yield watchRequests({ takeLatest: true });
}
