import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Button } from "antd";

import { getDays, getDayType } from "components/TabsExport/exportSections";

import { ArrowSelect } from "assets/icons/icons";
import useColorTheme from "../../../hooks/useColorTheme";
const DATE_FILTER_FULL_HISTORY = {
  id: 92,
  name: 'Full History',
};
const DateSelect = ({ disabled, activeDate, setActiveDate }) => {
  const { primaryColor } = useColorTheme();
  const wrapperRef = useRef(null);
  const reportDayType = isNaN(activeDate) ? (activeDate + '|||').split('|')[3] : activeDate;

  const {
    filters: {
      date: { relative },
    },
  } = useSelector((state) => state.filters);
  const {
    settingsYourProfile: {
      company: { filtersStartDate },
    },
  } = useSelector((state) => state.settingsYourProfile);
  const relativeReport = [
    ...relative,
    DATE_FILTER_FULL_HISTORY,
  ];
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const handleSelect = (value) => {
    setActiveDate(value);
    setIsOpenDropdown(false);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      if (isOpenDropdown) {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setIsOpenDropdown(false);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
    }, [ref, isOpenDropdown]);
  }
  useOutsideAlerter(wrapperRef);

  return (
    <div className="wrapper-filter-box" ref={wrapperRef}>
      <Button
        disabled={disabled}
        className="filter-title"
        style={{ background: isOpenDropdown ? "rgb(217, 223, 230)" : "rgb(238, 242, 247)" }}
        onClick={() => setIsOpenDropdown(!isOpenDropdown)}
      >
        <span>{relativeReport.find((item) => getDayType(item.id) === reportDayType).name}</span>
        <ArrowSelect />
      </Button>
      {isOpenDropdown ? (
        <div className="filter-dropdown">
          {relativeReport.map((item, index) => (
            <div
              key={index}
              style={{
                border: `solid 1px ${getDayType(item.id) === reportDayType ? primaryColor : "#dfdfea"}`,
                fontFamily: `${getDayType(item.id) === reportDayType ? "Gilroy-Bold" : "Gilroy-Medium"}`,
                color: `${getDayType(item.id) === reportDayType ? primaryColor : "#000"}`,
              }}
              className="date-box"
              onClick={() => handleSelect(getDays(item.id, filtersStartDate))}
            >
              {item.name}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default DateSelect;
