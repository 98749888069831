import React from 'react'
import { Tabs } from 'antd'

import ShareProducts from 'tabs/NavigationTabs/ShareProducts'
import PathPurchase from 'tabs/NavigationTabs/PathPurchase'
import ShareTrend from 'tabs/NavigationTabs/ShareTrend'

import about from 'assets/images/about.svg'
import { TabWithIcon } from 'assets/icons/icons'

const NavigationTabs = ({ setActiveTab }) => {
    const { TabPane } = Tabs

    function callback(key) {
        setActiveTab(key)
    }

    return (
        <div className="main-box">
            <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab={<TabWithIcon tabName={'Share of Products'}
                    icon={about}
                    tooltip={'Overall the prices moved up 20% based on the selected filters.'}
                />} key="1">
                    <ShareProducts />
                </TabPane>
                <TabPane tab={<TabWithIcon tabName={'Path to Purchase'}
                    icon={about}
                    tooltip={'Overall the prices moved up 20% based on the selected filters.'}
                />} key="2">
                    <PathPurchase />
                </TabPane>
                <TabPane tab={<TabWithIcon tabName={'Share Trend'}
                    icon={about}
                    tooltip={'Overall the prices moved up 20% based on the selected filters.'}
                />} key="3">
                    <ShareTrend />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default NavigationTabs
