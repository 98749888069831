import React, { useState } from "react";
import { Popover } from "antd";

//Components
import RenderNoData from "components/RenderNoData";

//Assets
import sort from "assets/images/sort.svg";
import sortIconDown from "assets/images/sort_down.svg";
import sortIconUp from "assets/images/sort_up.svg";
import search from "assets/images/search.svg";
import { COLORS } from "assets/colors/colors";

//Utils
import useRetailers from "../../../../../../hooks/useRetailers";
import { firstCharToUpperCase } from "utils/generalUtils";

const AssortmentDetailsTable = ({ inputSearch, handleSearch, data, handleSort, sortId, sortDirection, isEmpty }) => {
  const [popupContent, setPopupContent] = useState("");
  const { getRemoteLogo } = useRetailers();

  const keyValue = {
    true: "good",
    false: "bad",
  };

  const renderTitleBlock = (el) => {
    return (
      <div className={`title`}>
        <div className="title-image">
          <img src={el.image} alt="" />
        </div>
        <span>{el.title}</span>
      </div>
    );
  };

  const renderBrandBlock = (el) => {
    return (
      <div className={`brand`}>
        <div className="brand-color" style={{ background: el.color }}></div>

        <span>{el.title}</span>
      </div>
    );
  };

  const renderPopup = () => {
    return (
      <div className="popup-wrapper">
        <div className="popup-row">
          <div className="flex-container">
            <div className="popup-color" style={{ background: COLORS.available }}></div>

            <span>{`Available - ${firstCharToUpperCase(popupContent)}`}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderProductsBlock = (el, arr) => {
    return (
      <div className="wrapper">
        {data.retailers.map((item, index) => {
          return (
            <Popover
              key={index}
              content={renderPopup}
              title={el.title}
              trigger="hover"
              overlayStyle={{ width: 200 }}
              overlayClassName={!arr[item.id] ? "hidden" : null}
            >
              <div className={`table-item`} key={index} onMouseEnter={() => setPopupContent(arr[item.id])}>
                <img src={getRemoteLogo(keyValue[!!arr[item.id]])} alt="" />
              </div>
            </Popover>
          );
        })}
      </div>
    );
  };

  const renderRowBlock = (key, item, arr, brand) => {
    return (
      <div className="box-wrapper" key={key}>
        {renderTitleBlock(item)}
        {renderBrandBlock(brand)}
        {renderProductsBlock(item, arr)}
      </div>
    );
  };

  return (
    <div className="chart-price-wrapper" style={{ width: "100%" }}>
      <div className="current-table-box">
        <div className="wrapper-table-box">
          <div className="wrapper-table">
            <div className="box-wrapper">
              <div className="title sort" style={{ borderBottom: isEmpty ? "none" : "" }}>
                <div className="search">
                  <img src={search} alt="search" />
                  <input value={inputSearch} onChange={(e) => handleSearch(e.target.value)} placeholder={`Search for a product`} />
                  <img
                    src={sortId === "product" ? (sortDirection ? sortIconUp : sortIconDown) : sort}
                    alt="sort"
                    className="search-sort-btn"
                    onClick={data.total === 0 ? () => {} : () => handleSort("product")}
                  />
                </div>
              </div>
              {isEmpty ? null : (
                <>
                  {data.retailers.length ? (
                    <div className="table-item sort brand-title" onClick={() => handleSort("brand")}>
                      <span>Brand</span>
                      <img src={sortId === "brand" ? (sortDirection ? sortIconUp : sortIconDown) : sort} alt="sort" />
                    </div>
                  ) : null}
                  <div className="wrapper">
                    {data.retailers.map((item, index) => (
                      <div className="table-item sort" onClick={() => handleSort(item.id)} key={index}>
                        <img src={getRemoteLogo(item.name)} alt="retailer" />
                        <img src={sortId === item.id ? (sortDirection ? sortIconUp : sortIconDown) : sort} alt="sort" />
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
          {isEmpty ? (
            <RenderNoData />
          ) : (
            <div className="wrapper-table">
              <div className="wrapper-box-relative">
                {data.rows.map((el, index) => {
                  return renderRowBlock(index, el, el.data, data.brands[index]);
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssortmentDetailsTable;
