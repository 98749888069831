import React from "react";
import { Tabs } from "antd";

import about from "assets/images/about.svg";
import { TabWithIcon } from "assets/icons/icons";

import AssortmentSummary from "./AssortmentSummary";
import AssortmentChange from "./AssortmentChange";
import AssortmentDetails from "./AssortmentDetails";

const AssortmentTopTab = ({ activeTabTop, setActiveTabTop }) => {
  const { TabPane } = Tabs;

  function callback(key) {
    setActiveTabTop(key);
  }

  return (
    <div className="main-box">
      <Tabs defaultActiveKey={activeTabTop} onChange={callback}>
        <TabPane
          tab={<TabWithIcon tabName={"Summary"} icon={about} tooltip={"Brand Nudge visits 9 retailers every day. It's a sample data."} />}
          key="1"
        >
          <AssortmentSummary />
        </TabPane>
        <TabPane
          tab={<TabWithIcon tabName={"Change"} icon={about} tooltip={"Brand Nudge visits 9 retailers every day. It's a sample data."} />}
          key="2"
        >
          <AssortmentChange />
        </TabPane>
        <TabPane
          tab={<TabWithIcon tabName={"Details"} icon={about} tooltip={"Brand Nudge visits 9 retailers every day. It's a sample data."} />}
          key="3"
        >
          <AssortmentDetails />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default AssortmentTopTab;
