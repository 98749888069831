import React from "react";

//Components
import RetailerAvailability from "./RetailerAvailability";

//Assets
import { TabWithIcon } from "assets/icons/icons";
import about from "assets/images/about.svg";

const AvailabilityBottom = () => {
  return (
    <div className="main-box">
      <div className="tab-wrapper">
        <TabWithIcon
          tabName={"Retailer Availability"}
          icon={about}
          tooltip={
            "Overall the prices moved up 20% based on the selected filters."
          }
        />
      </div>
      <RetailerAvailability />
    </div>
  );
};

export default AvailabilityBottom;
