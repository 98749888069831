import { COLORS } from "assets/colors/colors";
import styled from "styled-components";

export const Styles = styled.div`
  .current-table-box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 45px;
  }
  .wrapper-table-box {
    width: 100%;
    overflow-x: auto;
    display: inline-block;
    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #abaaba;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #807f97;
    }
  }
  .box-wrapper {
    width: 100%;
    display: flex;
  }
  .title,
  .title-search {
    width: 277px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #dfdfea;
    border-left: none;
    border-right: none;
    .image {
      width: 40px;
      height: 40px;
    }
    span {
      font-family: "Gilroy-Medium";
      width: 310px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.29;
      color: #000000;
      padding: 0 10px;
    }

    .search {
      width: 277px;
      position: relative;
      font-size: 14px;
      color: #000000;
      display: flex;
      img {
        position: absolute;
        top: 10px;
        left: 10px;
      }
      input {
        border: none;
        background-color: #eef2f7;
        width: 240px;
        height: 44px;
        border-radius: 2px;
        outline: none;
        padding-left: 40px;
        padding-right: 12px;
      }
      .search-sort-btn {
        left: 240px !important;
      }
    }
    .total {
      padding: 6px 60px;
    }
  }
  .title.total,
  .table-item.total {
    background: #fafafa;
  }
  .title-search {
    border: none;
  }
  .search-sort-btn {
    left: 240px !important;
    cursor: pointer;
  }
  .wrapper {
    width: 100%;
    display: flex;

    & > :nth-child(n + 2) {
      min-width: 12% !important;
    }

    & > :last-child {
      min-width: 14% !important;
    }

    .no-listed {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
    }
    .table-item {
      cursor: pointer;
      min-width: 144px;
      width: 14%;
      height: 80px;
      padding: 6px 20px;
      display: flex;

      justify-content: center;
      align-items: flex-start;
      border: 1px solid #dfdfea;
      border-right: none;

      & > span {
        font-family: "Gilroy-Medium";
        font-size: 14px;
        text-align: center;

        display: flex;
        align-items: center;
      }
    }
  }
  .table-item.sort {
    border: none;

    align-items: center;
    & > span {
      font-family: "Gilroy-Medium";
      font-size: 12px;
      text-align: center;
      color: ${COLORS.darkGray};
      font-weight: bold;
      display: flex;
      align-items: center;
    }
  }

  .groupName {
    display: flex;
    font-size: 14px;
    font-family: Gilroy-Medium;

    .groupNameColor {
      min-width: 12px;
      height: 12px;
      margin-right: 16px;
    }
    & > span {
      text-align: start;
      line-height: normal;
    }
  }
  .groupNameImg {
    display: flex;
    width: 100%;
    flex-grow: 1;
    justify-content: center;
    img {
      width: 25px;
    }
  }
`;
