const url = process.env.REACT_APP_URL;

export const FETCH_SHARE_TREND = "FETCH_SHARE_TREND";
export const fetchShareTrend = (data) => ({
  type: FETCH_SHARE_TREND,
  request: {
    url: `${url}/v2/navigation/trend`,
    method: "POST",
    data,
  },
  meta: {
    asPromise: true,
  },
});

export const SET_PRISTINE_SHARE_TREND = "SET_PRISTINE_SHARE_TREND";
export const setPristineShareTrend = (pristine) => {
  return {
    type: SET_PRISTINE_SHARE_TREND,
    payload: pristine,
  };
};
