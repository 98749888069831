import styled from "styled-components";

export const Styles = styled.div.attrs((props) => props)`
  .current-table-box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 45px;
  }
  .wrapper-table-box {
    width: 100%;
    overflow-x: auto;
    display: grid;
    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #abaaba;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #807f97;
    }
  }
  .wrapper-table {
    // width: 100vw;
  }
  .box-wrapper {
    width: 100%;
    display: flex;
  }
  .title {
    // width: 400px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #dfdfea;
    border-left: none;
    border-right: none;
    .image {
      width: 50px;
      height: 50px;
    }
    span {
      font-family: "Gilroy-Medium";
      width: 310px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.29;
      color: #000000;
      padding: 0 10px;
      @media (max-width: 1440px) {
        width: 200px;
      }
    }
    .averagePrice {
      width: 360px;
      text-align: center;
      @media (max-width: 1440px) {
        width: 250px;
      }
    }
    .search {
      width: 360px;
      position: relative;
      font-size: 14px;
      color: #000000;
      display: flex;
      align-items: center;
      @media (max-width: 1440px) {
        width: 250px;
      }
      .img-search {
        position: absolute;
        top: 10px;
        left: 10px;
      }
      input {
        border: none;
        background-color: #eef2f7;
        width: 330px;
        height: 44px;
        border-radius: 2px;
        outline: none;
        padding-left: 40px;
        padding-right: 12px;
        @media (max-width: 1440px) {
          width: 220px;
        }
      }
    }
  }
  .wrapper {
    width: 100%;
    display: flex;
    .no-listed {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
    }
    .table-item {
      width: 230px;
      height: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid #dfdfea;
      border-right: none;
      img {
        width: 25px;
      }
      @media (max-width: 1440px) {
        width: 116px;
      }
      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          font-family: "Gilroy-Medium";
          width: 60px;
          text-align: center;
        }
        .box-empty {
          width: 24px;
        }
      }
      .price {
        font-family: "Gilroy-ExtraBold";
        font-size: 14px;
        color: #374456;
      }
      .notListed {
        color: #abaaba;
        font-size: 10px;
      }
    }
  }
  .table-item.sort {
    flex-direction: row;
  }
  .bestPrice {
    background-color: #d8f7ef;
  }
  .highestPrice {
    background-color: #ffefef;
  }
  .clickable-item {
    cursor: pointer;
  }
  .popup-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
    .popup-box {
      max-width: 840px;
      width: 100%;
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 8px 20px 0 rgba(30, 31, 32, 0.18), 0 2px 4px 0 rgba(0, 0, 0, 0.17);
      padding: 40px;
      position: relative;
      .title-box {
        display: flex;
        margin-bottom: 30px;
        .title-product {
          font-size: 22px;
          font-family: "Gilroy-ExtraBold";
          color: #000000;
        }
        .date-product {
          font-family: "Gilroy-Medium";
          font-size: 14px;
          color: #374456;
        }
        .product-img {
          width: 120px;
          height: 120px;
          margin-right: 25px;
        }
      }
      .cancel {
        position: absolute;
        width: 24px;
        height: 24px;
        right: 12px;
        top: 12px;
        cursor: pointer;
      }
    }
    .price-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 50px;
      .line {
        width: 220px;
        height: 4px;
        background-color: #25c891;
      }
      .price-type {
        width: 220px;
        height: 176px;
        padding: 15px;
        .title-price {
          font-size: 14px;
          color: #000000;
          font-family: "Gilroy-ExtraBold";
        }
        .no-promotions {
          margin-top: 40px;
        }
        .pricing {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 20px 0;
          .price-product {
            font-size: 14px;
            color: #000000;
            font-family: "Gilroy-ExtraBold";
          }
          .date-prod {
            font-size: 12px;
            color: #3a3d40;
            font-family: "Gilroy-Medium";
          }
        }
        .change {
          display: flex;
          align-items: center;
        }
      }
    }
  }
  .not-listed {
    cursor: default;
    color: #abaaba;
    font-size: 10px;
    font-family: "Gilroy-ExtraBold";
  }
  .hint-price {
    font-size: 10px;
    font-family: Gilroy-ExtraBold;
  }
  .hint-price.hight {
    color: #ff6866;
  }
  .hint-price.best {
    color: #1ec68d;
  }
  .product-link {
    display: flex;
    align-items: center;
    margin-top: 25px;
    a {
      font-size: 16px;
      color: ${(props) => props.color};
      font-family: "Gilroy-ExtraBold";
      display: block;
    }
    img {
      margin-right: 10px;
      width: 25px;
    }
  }
  .promoted-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .product-link {
      margin: 0;
    }
  }
`;
