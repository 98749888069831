import React from "react";
import LoaderBox from "components/LoaderBox";
import { STATE_STATUSES } from "utils/statuses";
import useRetailers from "../../../../hooks/useRetailers";
import useCountry, { NUMBER_TYPE } from "../../../../hooks/useCountry";

import sort from "assets/images/sort.svg";
import sortIconDown from "assets/images/sort_down.svg";
import sortIconUp from "assets/images/sort_up.svg";
import search from "assets/images/search.svg";
import delisted from "assets/images/delisted.svg";

const ComparisonTable = (props) => {
  const colorType = {
    higher: "#ffefef",
    lower: "#d8f7ef",
    original: "#fafafa",
    same: "#eaeaee",
    empty: "#fff",
  };

  const { currentData, names, compareData, sortId, sortDirection, handleSearch, handleSort, status, selectedRetailer } =
    props;
  
  const { getRemoteLogo } = useRetailers();
  const { formatCurrencyNumber } = useCountry();

  const getRetailer = (name) => {
    const retailers = {
      amazon: 4,
      asda: 2,
      morrisons: 9,
      sainsburys: 3,
      tesco: 1,
      waitrose: 10,
      iceland: 11,
      ocado: 8,
    };

    return retailers[name];
  };

  const sortRetailer = (name) => {
    const retailerId = getRetailer(name);
    handleSort(retailerId);
  };

  return (
    <div className="table-wrapper">
      <div className="table-header">
        <div className="search-box">
          <div className="search">
            <img src={search} alt="search" />
            <input onChange={(e) => handleSearch(e.target.value)} placeholder="Search for a Product" />
          </div>
        </div>
        <div className="retailers">
          {names.map((item, index) => (
            <div
              className={`retailer ${index === 0 && !!selectedRetailer ? "selected-compare" : ""} ${
                !!selectedRetailer && selectedRetailer !== item && index !== 0 ? "selected-not-highlighted" : ""
              }`}
              onClick={() => sortRetailer(item)}
              key={index}
            >
              <img src={getRemoteLogo(item)} alt="retailer" />
              <img src={sortId === getRetailer(item) ? (sortDirection ? sortIconUp : sortIconDown) : sort} alt="sort" />
            </div>
          ))}
        </div>
      </div>
      <div className="wrapper-box-relative">
        {currentData.length ? (
          currentData.map((itemData, indexData) => (
            <div className="wrapper-line" key={indexData}>
              <div className="title">
                <img className="image" src={itemData.product.productImage} alt="banner" />
                <span>{itemData.product.productTitle}</span>
              </div>
              {itemData.values.map((item, index) => (
                <div
                  className={`item-price ${index === 0 && !!selectedRetailer ? "selected-compare-product" : ""} ${
                    indexData === currentData.length - 1 && !!selectedRetailer && index === 0
                      ? "border-bottom-to-last"
                      : ""
                  } ${
                    !!selectedRetailer && selectedRetailer !== item.label && index !== 0
                      ? "selected-not-highlighted"
                      : ""
                  }`}
                  style={{ background: colorType[item.type] }}
                  key={index}
                >
                  {item.price && Number(item.price) ? (
                    <>
                      {compareData.length && compareData[indexData].values[index] ? (
                        <>
                          <div>
                            <div className="price">
                              <span>{formatCurrencyNumber(compareData[indexData].values[index].price, NUMBER_TYPE.CURRENCY)}</span>
                            </div>
                            {compareData[indexData].values[index].diff !== 0 ? (
                              <div>
                                {compareData[indexData].values[index].diff > 0 ? "+" : ""}
                                {compareData[indexData].values[index].diff}%
                              </div>
                            ) : null}
                          </div>
                          <img className="delisted" src={delisted} alt="Not Seen" />
                        </>
                      ) : null}
                      <div>
                        <div className="price">
                          <span>{formatCurrencyNumber(item.price, NUMBER_TYPE.CURRENCY)}</span>
                        </div>
                        {item.diff !== 0 ? (
                          <div>
                            {item.diff > 0 ? "+" : ""}
                            {item.diff}%
                          </div>
                        ) : null}
                      </div>
                    </>
                  ) : (
                    <div className="notListed">
                      <div className="price">NOT LISTED</div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ))
        ) : (
          <div className="chart-no-data static">Looks like we don't have data for this request</div>
        )}
        {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
      </div>
    </div>
  );
};

export default ComparisonTable;
