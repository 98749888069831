import { success, error } from "redux-saga-requests";
import { FETCH_SETTINGS_SUBSCRIPTIONS, SET_PRISTINE_SETTINGS_SUBSCRIPTIONS } from "./actions";
import { STATE_STATUSES } from "utils/statuses";
import { RESET_ERROR } from "store/error/actions";

const initialState = {
  settingsSubscriptions: {
    retailers: [],
    locations: [],
    searchTerms: [],
  },

  status: STATE_STATUSES.READY,
  isPristine: true,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SETTINGS_SUBSCRIPTIONS: {
      return processReducer(state);
    }
    case success(FETCH_SETTINGS_SUBSCRIPTIONS): {
      return {
        ...state,
        settingsSubscriptions: {
          retailers: [...action.data.subscriptions.retailers.data],
          locations: [...action.data.subscriptions.locations.data],
          searchTerms: [...action.data.subscriptions.searchTerms.data],
        },
        isPristine: false,
        status: STATE_STATUSES.READY,
      };
    }
    case error(FETCH_SETTINGS_SUBSCRIPTIONS): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case SET_PRISTINE_SETTINGS_SUBSCRIPTIONS: {
      return {
        ...state,
        isPristine: action.payload,
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
