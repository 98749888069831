import { success, error } from "redux-saga-requests";
import {
  SET_CALENDAR_FILTER,
  FETCH_PROMOTIONS_CALENDAR,
  ADD_TO_DATA,
  ADD_TO_CONTROL_DATA,
  SET_PRISTINE_PROMOTIONS_CALENDAR,
  SET_IS_NEW,
} from "./actions";
import { RESET_ERROR } from "store/error/actions";

import { STATE_STATUSES } from "utils/statuses";

const defaultFilter = [
  {
    name: "All",
    value: "All",
  },
  { name: "discount", value: "percent" },
  {
    name: "byRetailer",
    value: "byRetailer",
  },
  { name: "rangeValues", value: [0, 100] },
];

const initialState = {
  success: false,
  controlDataForFilter: {
    avgDiscountPercent: 0,
    avgDiscountValue: 0,
    productsCount: 0,
    rows: [],
    lastFilter: defaultFilter,
  },
  controlData: {
    avgDiscountPercent: 0,
    avgDiscountValue: 0,
    productsCount: 0,
    rows: [],
  },
  promotionsCalendar: {
    avgDiscountPercent: 0,
    avgDiscountValue: 0,
    productsCount: 0,
    rows: [],
  },
  filter: localStorage.getItem(SET_CALENDAR_FILTER)
    ? JSON.parse(localStorage.getItem(SET_CALENDAR_FILTER))
    : defaultFilter,
  status: STATE_STATUSES.INIT,
  isPristine: true,
  error: null,
  isNew: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROMOTIONS_CALENDAR: {
      return processReducer(state);
    }
    case success(FETCH_PROMOTIONS_CALENDAR): {
      return {
        ...state,
        promotionsCalendar: action.data,
        controlData: action.data,
        controlDataForFilter: {
          ...action.data,
          lastFilter: state.filter,
        },
        status: STATE_STATUSES.READY,
        isPristine: false,
        isNew: true,
      };
    }
    case error(FETCH_PROMOTIONS_CALENDAR): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case SET_PRISTINE_PROMOTIONS_CALENDAR: {
      return {
        ...state,
        isPristine: action.payload,
      };
    }

    case SET_IS_NEW: {
      return {
        ...state,
        isNew: action.payload,
      };
    }

    case ADD_TO_DATA: {
      return {
        ...state,
        promotionsCalendar: {
          ...state.promotionsCalendar,
          rows: action.payload,
        },
      };
    }

    case ADD_TO_CONTROL_DATA: {
      return {
        ...state,
        controlData: {
          ...state.controlData,
          rows: action.payload,
        },
      };
    }

    case SET_CALENDAR_FILTER: {
      return {
        ...state,
        filter: action.payload,
        controlDataForFilter: {
          ...state.controlDataForFilter,
          lastFilter: action.payload,
        }      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
