import React from "react";
import { Checkbox, Dropdown, Menu } from "antd";
import { ArrowSelect } from "assets/icons/icons";

const UsersList = ({ disabled, list, selected, setSelected }) => {
  const itemsList = (
    <Menu>
      {list.length
        ? list
            .sort(function (a, b) {
              if (a.last_name[0] && b.last_name[0] && (a.last_name[0].toLowerCase() < b.last_name[0].toLowerCase())) {
                return -1;
              }
              if (a.last_name[0] && b.last_name[0] && (a.last_name[0].toLowerCase() > b.last_name[0].toLowerCase())) {
                return 1;
              }
              return 0;
            })
            .sort(function (a, b) {
              if (a.first_name[0] && b.first_name[0] && (a.first_name[0].toLowerCase() < b.first_name[0].toLowerCase())) {
                return -1;
              }
              if (a.first_name[0] && b.first_name[0] && (a.first_name[0].toLowerCase() > b.first_name[0].toLowerCase())) {
                return 1;
              }
              return 0;
            })
            .map((item, index) => {
              const avatar = item.avatar || item.company?.avatar;
              return (
                 <div key={index} className="user-item">
                   <Checkbox checked={selected.includes(item.id)} onChange={() => setSelected(item.id)}>
                     {`${item.first_name} ${item.last_name}`}
                   </Checkbox>
                   { avatar?.length ? <img src={avatar} alt="avatar" /> : null }
                 </div>
            )})
        : null}
    </Menu>
  );

  return (
    <div className="share-dropdown-wrapper" style={{ marginTop: "10px" }}>
      <Dropdown
        disabled={disabled}
        overlay={itemsList}
        placement="bottomLeft"
        trigger={["click"]}
        getPopupContainer={(trigger) => trigger.parentElement}
      >
        <div className="share-dropdown">
          <p>Users({selected.length})</p>
          <ArrowSelect />
        </div>
      </Dropdown>
    </div>
  );
};

export default UsersList;
