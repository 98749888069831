//Ranking
const url = process.env.REACT_APP_URL;

export const FETCH_RANKING = "FETCH_RANKING";
export const fetchRanking = (data) => ({
  type: FETCH_RANKING,
  request: {
    url: `${url}/v2/price/ranking`,
    method: "POST",
    data,
  },
});
export const FETCH_COMPARE_RANKING = "FETCH_COMPARE_RANKING";
export const fetchCompareRanking = (data) => ({
  type: FETCH_COMPARE_RANKING,
  request: {
    url: `${url}/v2/price/ranking`,
    method: "POST",
    data,
  },
  meta: {
    asPromise: true,
  },
});

export const FETCH_RANKING_RETAILERS = "FETCH_RANKING_RETAILERS";
export const fetchRankingRetailers = (data, retailerId) => ({
  type: FETCH_RANKING_RETAILERS,
  request: {
    url: `${url}/v2/price/ranking/${retailerId}`,
    method: "POST",
    data,
  },
  meta: {
    asPromise: true,
  },
});

export const SET_PRISTINE_RANKING = "SET_PRISTINE_RANKING";
export const setPristineChange = (pristine) => {
  return {
    type: SET_PRISTINE_RANKING,
    payload: pristine,
  };
};
