const url = process.env.REACT_APP_URL;

//Current Prices
export const FETCH_CURRENT_PRICES = "FETCH_CURRENT_PRICES";
export const fetchCurrentPrices = (data) => ({
  type: FETCH_CURRENT_PRICES,
  request: {
    url: `${url}/v2/price/current`,
    method: "POST",
    data,
  },
});

export const FETCH_CURRENT_PRICE_POPUP = "FETCH_CURRENT_PRICE_POPUP";
export const fetchCurrentPricePopup = (coreProductId, retailerId, timePeriod) => ({
  type: FETCH_CURRENT_PRICE_POPUP,
  request: {
    url: `${url}/v2/price/current/${coreProductId}/retailers/${retailerId}`,
    method: "POST",
    data: { timePeriod },
  },
  meta: {
    asPromise: true,
  },
});

export const SET_PRISTINE_CURRRENT_PRICES = "SET_PRISTINE_CURRRENT_PRICES";
export const setPristineCurrentPrices = (pristine) => {
  return {
    type: SET_PRISTINE_CURRRENT_PRICES,
    payload: pristine,
  };
};
