import React from "react";
import { Dropdown, Menu, Checkbox } from "antd";
import SelectBox from "components/Selects/SelectBox";
import CheckboxList from "components/CheckboxList";
import TimeBox from "components/Alerts/components/TimeBox";
import { ArrowSelect } from "assets/icons/icons";

import { alertTemplate } from "components/Alerts/alertTemplate";

const ScheduleAlert = ({ type, days, time, setType, setDays, setTime, errorField, noTime, isDays, disabled }) => {
  const daysList = (
    <Menu>
      {alertTemplate().schedule["days"].map((item, index) => (
        <div key={index} className="custome-checkbox">
          <Checkbox checked={days.includes(item.id)} onChange={() => setDays(item.id)}>
            {item.label}
          </Checkbox>
        </div>
      ))}
    </Menu>
  );

  return (
    <div className="schedule-wrapper">
      <div className={errorField.isEmptyOften ? "is-empty-field" : "alert-field"}>
        <div className="schedule-frequency">
          <CheckboxList
            disabled={disabled}
            data={alertTemplate().schedule["type"]}
            filter={type}
            setSelectValue={(value) => setType(value.schedule)}
          />
        </div>
        {errorField.isEmptyOften ? <div className="error-hint">This cannot be empty.</div> : <br />}
      </div>

      {(type === "weekly" || type === "monthly") && isDays ? (
        <div className={errorField.isEmptyDay ? "is-empty-field" : "alert-field"}>
          <div className="share-dropdown-wrapper">
            <Dropdown
              disabled={disabled}
              overlay={daysList}
              placement="bottomLeft"
              trigger={["click"]}
              getPopupContainer={(trigger) => trigger.parentElement}
            >
              <div className="share-dropdown">
                <p>{days.length ? `Days (${days.length})` : "Set a day"}</p>
                <ArrowSelect />
              </div>
            </Dropdown>
          </div>
          {errorField.isEmptyDay ? <div className="error-hint">This cannot be empty.</div> : <br />}
        </div>
      ) : null}

      {type.length && !noTime ? (
        <div className={errorField.isEmptTime ? "is-empty-field" : "alert-field"}>
          <TimeBox value={time} setValue={setTime} />
          {errorField.isEmptTime ? <div className="error-hint">This cannot be empty.</div> : <br />}
        </div>
      ) : null}
    </div>
  );
};

export default ScheduleAlert;
