import { Radio, Select } from "antd";
import SelectBox from "components/Selects/SelectBox";
import React, { useState } from "react";

import { Styles } from "./styles";

const selects = {
  name: "show",
  default: 10,
  options: [
    { lable: "10", value: 10 },
    { lable: "20", value: 20 },
    { lable: "30", value: 30 },
    { lable: "40", value: 40 },
    { lable: "50", value: 50 },
  ],
};

const ShowPerPage = ({ setLimit, style, value }) => {
  const { Option } = Select;
  const changeHandler = (value) => {
    setLimit(value);
  };

  return (
    <Styles>
      <div className="select-wrapper" style={style}>
        <span>Show</span>
        <Select
          name={selects.name}
          showSearch={false}
          placeholder="Please select"
          onChange={changeHandler}
          value={value}
          className="select"
        >
          {selects.options.map((item, index) => (
            <Option value={item.value} key={index}>
              <Radio checked={value === item.value}>
                <span>{item.lable}</span>
              </Radio>
            </Option>
          ))}
        </Select>

        <span>Per Page</span>
      </div>
    </Styles>
  );
};

export default ShowPerPage;
