import { COLORS } from "assets/colors/colors";
import styled from "styled-components";

export const Styles = styled.div.attrs((props) => props)`
  .content-wrapper {
    display: flex;
    flex-grow: 1;
  }
  .top-content {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .row,
  .row-second {
    margin: 8px 0 8px 0;
    display: flex;
    flex: 1;
    justify-content: space-between;
    & > :nth-child(n + 1) {
      max-width: 49.3%;
    }
  }
  .filter {
    position: relative;
    height: 44px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    background: ${(props) => props.color};
    padding: 10px 10px 10px 15px;
    font-size: 14px;
    color: #000000;
    border-radius: 4px;
    cursor: pointer;
    font-family: "Gilroy-Medium";
  }
  .filter-title {
    color: ${COLORS.white};
    margin-right: 15px;
  }
`;
