const url = process.env.REACT_APP_URL;

export const FETCH_ASSORTMENT_DETAILS = "FETCH_ASSORTMENT_DETAILS";
export const fetchAssortmentDetails = (params) => ({
  type: FETCH_ASSORTMENT_DETAILS,
  request: {
    url: `${url}/v2/assortment/details`,
    method: "POST",
    data: params,
  },
  meta: {
    asPromise: true,
  },
});

export const SET_PRISTINE_ASSORTMENT_DETAILS = "SET_PRISTINE_ASSORTMENT_DETAILS";
export const setPristineAssortmentDetails = (pristine) => {
  return {
    type: SET_PRISTINE_ASSORTMENT_DETAILS,
    payload: pristine,
  };
};

export const ADD_TO_DATA_ASSORTMENT_DETAILS = "ADD_TO_DATA_ASSORTMENT_DETAILS";
export const addToDataAssortmentDetails = (data) => {
  return {
    type: ADD_TO_DATA_ASSORTMENT_DETAILS,
    payload: data,
  };
};

export const SET_ASSORTMENT_DETAILS_FILTER = "SET_ASSORTMENT_DETAILS_FILTER";
export const setAssortmentDetailsFilter = (data) => {
  localStorage.setItem(SET_ASSORTMENT_DETAILS_FILTER, JSON.stringify(data));
  return {
    type: SET_ASSORTMENT_DETAILS_FILTER,
    payload: data,
  };
};
