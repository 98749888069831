import styled from "styled-components";

export const Styles = styled.div`
  position: relative;
  .text-container {
    width: 600px;
    font-size: 14px;
    font-family: "Gilroy-Medium";
    margin-bottom: 40px;
  }
`;
