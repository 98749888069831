const url = process.env.REACT_APP_URL;

export const FETCH_TREND_ANALYSIS_TABLE = "FETCH_TREND_ANALYSIS_TABLE";
export const fetchTrendAnalysisTable = (params) => ({
  type: FETCH_TREND_ANALYSIS_TABLE,
  request: {
    url: `${url}/v2/rating/trend/products`,
    method: "POST",
    data: params,
  },
});

export const FETCH_TREND_ANALYSIS_TABLE_ALL = "FETCH_TREND_ANALYSIS_TABLE_ALL";
export const fetchTrendAnalysisTableAll = (params) => ({
    type: FETCH_TREND_ANALYSIS_TABLE_ALL,
    request: {
        url: `${url}/v2/rating/trend/products/all`,
        method: "POST",
        data: params,
    },
});

export const SET_PRISTINE_TREND_ANALYSIS_TABLE = "SET_PRISTINE_TREND_ANALYSIS_TABLE";
export const setPristineTrendAnalysisTable = (pristine) => {
  return {
    type: SET_PRISTINE_TREND_ANALYSIS_TABLE,
    payload: pristine,
  };
};
