const WATCHLIST_ITEMS = [
    'category',
    'manufacture',
    'productBrand',
    'sourceType'
];
export const getWatchlistInArray = (watchlistFilter = {}) => {
    const watchlistInArray = WATCHLIST_ITEMS.reduce((acc, item) => {
        return {
            ...acc,
            [item] : Array.isArray(watchlistFilter[item]) ? watchlistFilter[item] : watchlistFilter[item] ? watchlistFilter[item].split('|').map(el => Number(el)) : [],
        }
    }, {});
    return watchlistInArray;
}