import React, { useEffect, useState } from "react";
import { Empty } from "antd";

import { Styles } from "./styles";

import useRetailers from "../../../../../hooks/useRetailers";
import useCountry, { NUMBER_TYPE } from "../../../../../hooks/useCountry";

import { changeNameRetailer } from "utils/changeNameRetailer";

import product from "assets/images/con-product.svg";
import ratingBy from "assets/images/ratingBy.svg";
import duration from "assets/images/duration.svg";
import sort from "assets/images/sort.svg";
import sortIconDown from "assets/images/sort_down.svg";
import sortIconUp from "assets/images/sort_up.svg";
import placeholder from "assets/images/placeholder.svg";

const TopMoversWithFilter = ({ data, currentChart, retailer, filter, setSelectValue }) => {
  //Constants
  const isBrand = currentChart[2].value === "byBrand";
  const isDiscountInPercents = currentChart[1].value === "percent";

  const filters = [
    { name: "products", image: product, btnName: "byProducts" },
    {
      name: "promotions",
      image: ratingBy,
      btnName: isDiscountInPercents ? "byDiscountPercent" : "byDiscount",
    },
    { name: "duration", image: duration, btnName: "byDuration" },
  ];
  const MAX = "Max";
  const MIN = "Min";

  //States
  const [movers, setMovers] = useState([]);
  const [emptyData, setEmptyData] = useState(false);
  const { getRemoteLogo } = useRetailers();
  const { formatCurrencyNumber } = useCountry();

  useEffect(() => {
    let movers;
    movers = data[currentChart[2].value].promotioners[filter.value];
    if (retailer.length && !isBrand) {
      const retailerObj = data[currentChart[2].value].data.find(({ title }) => title === retailer[0]);
      movers = retailerObj.brands.promotioners[filter.value];
    }

    setMovers(movers);
  }, [data, currentChart, filter.value, retailer]);

  const handleSort = (name, direction) => {
    let sort;
    if (filter.value.includes(name)) {
      if (filter.value.includes(MAX)) {
        sort = `${name}${MIN}`;
      } else {
        sort = `${name}${MAX}`;
      }
    } else if (direction) {
      sort = `${name}${direction}`;
    } else {
      sort = `${name}${MIN}`;
    }
    setSelectValue({ [filter.name]: sort });
  };

  const renderImage = (name) => {
    if (filter.value.includes(name)) {
      if (filter.value.includes(MIN)) {
        return sortIconUp;
      } else {
        return sortIconDown;
      }
    } else {
      return sort;
    }
  };

  useEffect(() => {
    if (movers.length) {
      setEmptyData(false);
    } else {
      setEmptyData(true);
    }
  }, [movers]);

  return (
    <Styles>
      <div className="filters">
        {emptyData ? null : (
          <div className="filters-wrapper">
            {filters.map(({ name, image, btnName }) => {
              return (
                <div className="single-filter" key={name}>
                  <img src={image} alt="" onClick={() => handleSort(btnName)} />

                  <div className="sort-btns">
                    <div className="sort-btn-up" onClick={() => handleSort(btnName, MAX)}></div>
                    <div className="sort-btn-bottom" onClick={() => handleSort(btnName, MIN)}></div>
                    <img src={renderImage(btnName)} alt="sort" />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <div className="topMovers">
        {emptyData ? (
          <div className="empty-wrapper">
            <div className="no-data-box">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          </div>
        ) : null}

        {movers.map((item, index) => {
          return (
            <div className="mover" key={index}>
              <div className="row">
                <img
                  style={{
                    backgroundColor: isBrand || retailer.length ? item.color : null,
                  }}
                  className="image"
                  src={isBrand || retailer.length ? placeholder : getRemoteLogo(item.title)}
                  alt="banner"
                />
                <div className="title">{changeNameRetailer(item.title)}</div>
              </div>
              <div className="mover-results">
                <div className="mover-result">{item.products}</div>
                <div className="mover-result">
                  {isDiscountInPercents
                    ? `${item?.averageDiscountPercent}%`
                    : `${formatCurrencyNumber(item?.averageDiscount, NUMBER_TYPE.CURRENCY)}`}
                </div>
                <div className="mover-result">{item.duration}d</div>
              </div>
            </div>
          );
        })}
      </div>
    </Styles>
  );
};

export default TopMoversWithFilter;
