const url = process.env.REACT_APP_URL;

export const FETCH_PATH_PURCHASE = "FETCH_PATH_PURCHASE";
export const fetchPathPurchase = (data, productId) => ({
  type: FETCH_PATH_PURCHASE,
  request: {
    url: `${url}/v2/navigation/path-to-purchase/${productId}`,
    method: "POST",
    data,
  },
  meta: {
    asPromise: true,
  },
});

export const REMOVE_PURCHASE_ALL_PRODUCTS = "REMOVE_PURCHASE_ALL_PRODUCTS";
export const removePurchaseAllProducts = () => {
  return {
    type: REMOVE_PURCHASE_ALL_PRODUCTS,
  };
};

export const REMOVE_PURCHASE_PRODUCT = "REMOVE_PURCHASE_PRODUCT";
export const removePurchaseProduct = (id) => {
  return {
    type: REMOVE_PURCHASE_PRODUCT,
    payload: id,
  };
};

export const SET_PRISTINE_PATH_PURCHASE = "SET_PRISTINE_PATH_PURCHASE";
export const setPristinePathPurchase = (pristine) => {
  return {
    type: SET_PRISTINE_PATH_PURCHASE,
    payload: pristine,
  };
};
