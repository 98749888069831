import React from "react";
import { Radio, Space } from "antd";

const RadioButtons = ({ title, onChange, value, section, property, items, disabled, isVertical, styleOverride }) => {
  return (
    <>
      {title ? <div className="export-hint" style={{ marginTop: "10px", fontFamily: "Gilroy-ExtraBold" }}>
        {title}
      </div> : null}
      <Radio.Group
        onChange={(e) => onChange(property, e.target.value)}
        value={value[section][property]}
        disabled={disabled}
      >
        <Space direction={isVertical ? "vertical" : "horizontal"}>
          {items.map((item, index) => (
            <Radio style={styleOverride} key={index} value={item.value}>
              {item.lable}
            </Radio>
          ))}
        </Space>
      </Radio.Group>
    </>
  );
};

export default RadioButtons;
