import React from "react";
import { Modal } from "antd";
import CustomButton from "components/CustomButton";

import "./styles.scss";

const MsgPopup = ({themeColor, buttonType, onOk, onCancel, isShow, title, message}) => {
    return (
    <Modal
        className="export-modal download"
        key='download-report-submitted'
        visible={isShow}
        title={title}
        onOk={onOk}
        onCancel={onCancel}
        centered
        footer={null}
    >
        <div className="msg-popup">
        <div className="main-text">{`${message}`}</div>
        <div className="buttons-container">
            <CustomButton
            title='OK'
            onClick={onOk}
            type={buttonType}
            color={themeColor}
            />
        </div>
        </div>
    </Modal>
    )
}
export default MsgPopup;
