import React from "react";

export const getOverallChange = (changeParams, priceParam, descText) => {
  const overallChangeParams = {
    shelfPrices: changeParams.shelfPricePercent,
    basePrices: changeParams.basePrice,
    promotedPrices: changeParams.promotedPricePercent,
  };

  const currentOverallChange = overallChangeParams[priceParam];
  return (
    <>
      <span>
        {`${descText[0]} `}
        {Number(currentOverallChange.percent) > 0
          ? `${descText[1]}`
          : Number(currentOverallChange.percent) < 0
          ? `${descText[2]}`
          : Number(currentOverallChange.percent) === 0
          ? ` are unchanged`
          : null}
      </span>
      {Number(currentOverallChange.percent) === 0 ? null : (
        <span className="percent">{Number(currentOverallChange.percent).toFixed(1)}%</span>
      )}
      <span>&nbsp;based on the selected filters.</span>
    </>
  );
};
