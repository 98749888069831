import { COLORS } from "assets/colors/colors";
import styled from "styled-components";

export const Styles = styled.div`
  width: 38%;
  .search-product-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    min-height: 111px;
    & > span {
      font-size: 12px;
      color: ${COLORS.dark};
      margin-bottom: 5px;
      font-family: "Gilroy-ExtraBold";
      font-weight: bold;
    }
  }
  .row {
    align-items: flex-start;
    & > div {
      margin-left: 0;
      margin-right: 16px;
      & > div {
        margin-left: 0;
      }
      .ant-select-selector {
        width: 136px !important;
        padding: 0 4px;
      }
    }
    display: flex;
  }

  .search {
    position: relative;
    font-size: 14px;
    color: #000000;
    margin-right: 16px;
    width: 100% !important;
    img {
      position: absolute;
      top: 10px;
      left: 10px;
    }

    input {
      border: none;
      background-color: #eef2f7;
      width: 100%;
      min-width: 12px;
      height: 44px;
      border-radius: 2px;
      outline: none;
      padding-left: 40px;
      padding-right: 12px;
    }
    .error {
      font-size: 12px;
      font-family: "Gilroy-Medium";
      color: #ff5555;
    }
  }
`;
