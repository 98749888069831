import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//Components
import MainFilters from "components/Filters/MainFilters";
import NavBar from "components/NavBar";
import PromotionsTopTabs from "tabs/PromotionsTabs/PromotionsTopTabs/PromotionsTopTabs";
import PromotionsBottomTabs from "tabs/PromotionsTabs/PromotionsBottomTabs/PromotionsBottomTabs";
//utils
import { STATE_STATUSES } from "utils/statuses";
//Actions
import { setPristinePromotionsCalendar } from "store/promotions/promotionsCalendar/actions";
import { setPristineSummaryPromotions } from "store/promotions/summaryPromotions/actions";
import { setPristinePromotionsBreakdown } from "store/promotions/pormotionBreakdown/actions";
import { setPristinePromotionsDiscountCut } from "store/promotions/promotionsDiscountCut/actions";
import LoaderBox from "components/LoaderBox";
import useFilters from "../hooks/useFilters";

const Promotions = () => {
  const { status: filtersStatus } = useSelector((state) => state.filters);
  const { prepareLogoutStatus, status: statusAuth } = useSelector((state) => state.authorization);

  //States
  const [activeTabTop, setActiveTabTop] = useState("1");
  const {
    mainLastFilter,
    statusMainFilters,
    universalFiltersStatus,
    statusFilters,
    titleDateStatus,
  } = useFilters();
  
  //Constants
  const dispatch = useDispatch();
  const getActionTop = useCallback((value) =>
    [
      setPristineSummaryPromotions(value),
      setPristinePromotionsBreakdown(value),
      setPristinePromotionsDiscountCut(value),
    ][+activeTabTop - 1]
  ,[activeTabTop]);
  
  const getPristineTop = +activeTabTop > 1 ? false : true;

  const getActionBottom = (value) => setPristinePromotionsCalendar(value);
  const getPristineBottom = true;

  useEffect(() => {
    return () => {
      dispatch(getActionTop(getPristineTop));
      dispatch(getActionBottom(getPristineBottom));
    };
  }, [dispatch, getActionTop, getPristineBottom, getPristineTop]);

  useEffect(() => {
    if (filtersStatus === STATE_STATUSES.PENDING) {
      dispatch(getActionTop(true));
      dispatch(getActionBottom(true));
    }
  }, [filtersStatus, dispatch, getActionTop]);

  return (
    <div className="main-page-wrapper">
      <NavBar />
      <div className="main-page-box">
        <MainFilters />
        <h1 className="main-page-header">Promotions</h1>
        <div className="main-tabs-box">
          <PromotionsTopTabs
            setActiveTabTop={setActiveTabTop}
            activeTabTop={activeTabTop}
          />
        </div>
        <div className="main-tabs-box">
          <PromotionsBottomTabs />
        </div>
      </div>
      {
        statusAuth !== STATE_STATUSES.PENDING &&
        mainLastFilter &&
        statusMainFilters === STATE_STATUSES.READY &&
        universalFiltersStatus === STATE_STATUSES.READY &&
        statusFilters === STATE_STATUSES.READY &&
        titleDateStatus === STATE_STATUSES.READY &&
        prepareLogoutStatus === STATE_STATUSES.INIT ? null : <LoaderBox isOpacity={true} />
      }
      </div>
  );
};

export default Promotions;
