import React from "react";

const RenderTooMoreData = ({ style }) => {
  return (
    <div className="too-more-data" style={style}>
      <p>Looks like we have a lot of data.</p>
      <p>Select the buttons below to see the graph.</p>
    </div>
  );
};

export default RenderTooMoreData;
