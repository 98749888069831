import { Button, Radio, Slider } from "antd";

import { Styles } from "./DiscountFiltersStyle";
import React, { useEffect, useRef, useState } from "react";
import { ArrowSelect } from "assets/icons/icons";
import useColorTheme from "../../../hooks/useColorTheme";
import useCountry, { NUMBER_TYPE } from "../../../hooks/useCountry";

const DiscountFilter = ({
  data,
  setSelectValue,
  minMaxValues,
  typeOfDiscount,
  setTypeOfDiscount,
  disabled,
  radioButtons,
  onlyRange,
  rangeTitle,
  decimalStep,
  minMaxControlValues,
  resetWithEmptyArr,
  isOnPromotions,
}) => {
  //Refs
  const wrapperRef = useRef(null);
  //Constants
  const isTypeInPercent = typeOfDiscount === "percent" || typeOfDiscount === "availability";
  const min = isTypeInPercent || minMaxControlValues.minValue - 1 <= 0 ? 0 : minMaxControlValues.minValue - 1;
  const max = isTypeInPercent ? 100 : minMaxControlValues.maxValue + 1;
  const { primaryColor } = useColorTheme();
  const { formatCurrencyNumber } = useCountry();

  //States
  const isCurrency = isOnPromotions && !isTypeInPercent;
  const suffix = isTypeInPercent ? "%" : "";
  const [showDropdown, setShowDropdown] = useState(false);
  const [sliderValue, setSliderValue] = useState([minMaxValues.minValue, minMaxValues.maxValue]);

  useEffect(() => {
    setSliderValue([minMaxValues.minValue, minMaxValues.maxValue]);
  }, [minMaxValues]);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const onChangeRadio = (e) => {
    const { value } = e.target;
    setTypeOfDiscount(value);

    if (!isTypeInPercent) {
      setSliderValue([minMaxValues.minValue, max]);
    } else {
      setSliderValue([minMaxValues.minValue, max]);
    }
  };

  const onSliderChange = (numsArr) => {
    setSliderValue(numsArr);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      if (showDropdown) {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setShowDropdown(false);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
    }, [ref, showDropdown]);
  }
  useOutsideAlerter(wrapperRef);

  const applyFilter = () => {
    setSelectValue({ rangeValues: sliderValue, [data.name]: typeOfDiscount });
    setShowDropdown(false);
  };

  const resetAll = () => {
    if (resetWithEmptyArr) {
      setSelectValue({
        [data.name]: typeOfDiscount,
        rangeValues: [],
      });
    } else {
      setSelectValue({
        [data.name]: typeOfDiscount,
        rangeValues: [min, max],
      });
    }
    setSliderValue([min, max]);
    setShowDropdown(false);
  };

  return (
    <Styles color={primaryColor}>
      <div className="wrapper-compare" ref={wrapperRef}>
        <div className="compare-box" onClick={disabled ? () => {} : toggleDropdown}>
          <span className="compare-title">
            {typeOfDiscount.length && !onlyRange
              ? radioButtons.find((item) => item.value === typeOfDiscount)?.lable
              : onlyRange
              ? rangeTitle
              : "% Discount"}
          </span>

          <ArrowSelect />
        </div>
        {showDropdown ? (
          <div className="radioBox">
            {onlyRange ? null : (
              <div>
                <span className="hint">Type</span>
                <Radio.Group onChange={onChangeRadio} value={typeOfDiscount}>
                  {radioButtons.map((item, index) => (
                    <Radio key={index} value={item.value}>
                      {item.lable}
                    </Radio>
                  ))}
                </Radio.Group>
              </div>
            )}

            <span className="hint">Range</span>

            <Slider
              max={max}
              min={min}
              range
              step={!isTypeInPercent && decimalStep ? 0.1 : 1}
              value={sliderValue}
              onChange={onSliderChange}
            />
            <div className="slider-values">
              <div className="values-container">
                {formatCurrencyNumber(sliderValue[0], isCurrency ? NUMBER_TYPE.CURRENCY : NUMBER_TYPE.DECIMAL)} {suffix}
              </div>
              <div className="dash">-</div>
              <div className="values-container">
                {formatCurrencyNumber(sliderValue[1], isCurrency ? NUMBER_TYPE.CURRENCY : NUMBER_TYPE.DECIMAL)} {suffix}
              </div>
            </div>
            <div className="btn-wrapper">
              <Button className="select" onClick={resetAll}>
                Reset Range
              </Button>
              <Button className="aplly-btn" onClick={applyFilter}>
                Apply
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    </Styles>
  );
};

export default DiscountFilter;
