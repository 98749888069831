import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { STATE_STATUSES } from "../utils/statuses";
import { setIsColorThemeFadeIn } from "../store/settings/settingsYourProfile/actions";

const WrapperComponent = ({ isStart, loginStatus, logoutStatus, prepareLogoutStatus, isColorThemeFadeIn, setIsColorThemeFadeIn, children, ...rest }) => {
  //const [isPageLoaded, setIsPageLoaded] = useState(isColorThemeFadeIn);
  const timerIn = useRef(null);

  useEffect(()=>{
    if (isColorThemeFadeIn && loginStatus === STATE_STATUSES.READY) {
      timerIn.current = setTimeout(() => setIsColorThemeFadeIn(false), 1000)
    }

    return () => {
        if (timerIn.current) {
          clearTimeout(timerIn.current)
        }
    }
  },[loginStatus, isColorThemeFadeIn, setIsColorThemeFadeIn])

  return (
    <div className={`
         ${isColorThemeFadeIn && loginStatus === STATE_STATUSES.READY && 'animate_fade_in'}
         ${(prepareLogoutStatus === STATE_STATUSES.PENDING || prepareLogoutStatus === STATE_STATUSES.READY) && 'animate_fade_out'}
    `}>
        {children}
    </div>
  )
};


export default connect((state) => ({
    loginStatus: state.authorization.loginStatus,
    logoutStatus: state.authorization.logoutStatus,
    prepareLogoutStatus: state.authorization.prepareLogoutStatus,
    isColorThemeFadeIn: state.settingsYourProfile.isColorThemeFadeIn,
}),
{ setIsColorThemeFadeIn }
)(WrapperComponent);