import React from "react";

import { Styles } from "components/CalendarItems/Styles";
import AssortmentSummaryProductItem from "./AssortmentSummaryProductItem";
import Icon, { Icons } from "components/Icon/Icon";

const MediaSummaryRetailersItem = ({ data, clickedArray, onOpen }) => {
  const { children } = data;
  const isIncluded = (id) => {
    return clickedArray.some((el) => el.id === id);
  };

  const renderRetailers = (element) => {
    const {
      children,
      item: { id, name, color },
    } = element;

    return (
      <div key={id}>
        <div
          className="retailer-item"
          onClick={() => (children.length ? onOpen(id, children, color, data) : () => {})}
          style={{ cursor: !children.length ? "not-allowed" : null }}
        >
          <div className={"plus-btn-retailers"} style={{ cursor: !children.length ? "not-allowed" : null }}>
            <span>{!isIncluded(id) ? "+" : "-"}</span>
          </div>
          <Icon
            icon={Icons["placeholder"]}
            width={24}
            heigth={24}
            style={{
              backgroundColor: color,
              borderRadius: 2,
            }}
          />
          <div className="retailer-row">
            <div className="retailer-title">
              <span className="retailer-name">{name} </span>
              <span className="retailer-total">({children.length})</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Styles>
      {renderRetailers(data)}
      {isIncluded(data.item.id)
        ? children.map((el, index) => {
            const isLastChild = index === children.length - 1;
            return <AssortmentSummaryProductItem el={el} key={index} isLastChild={isLastChild} />;
          })
        : null}
    </Styles>
  );
};

export default MediaSummaryRetailersItem;
