import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import useColorTheme from "../../../../hooks/useColorTheme";

const PieChart = (props) => {
  const { title, percent } = props;

  const [pricings, setPricings] = useState([]);
  const { primaryColor } = useColorTheme();

  useEffect(() => {
    if (percent) {
      const pricings = [
        {
          innerSize: "70%",
          clickable: false,
          data: [
            {
              y: percent,
              color: primaryColor,
            },
            {
              y: 100 - percent,
              color: "#e0e0e4",
            },
          ],
        },
      ];

      setPricings(pricings);
    }
  }, [percent]);

  const options = {
    chart: {
      type: "pie",
      height: 100,
      width: 100,
      backgroundColor: "rgba(0,0,0,0)",
      animation: false,
    },
    title: {
      text: "",
    },
    tooltip: {
      shared: false,
      enabled: false,
      backgroundColor: null,
      borderWidth: 0,
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
        },
        borderWidth: 1,
      },
      pie: {
        states: {
          hover: {
            halo: null,
          },
          inactive: {
            opacity: 1,
          },
        },
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: pricings,
  };

  return (
    <div className="pie-chart-item">
      <div className="title">{title}</div>
      <div className="pie-chart">
        <div className="percent">{Math.round(percent)}%</div>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    </div>
  );
};

export default PieChart;
