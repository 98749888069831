const url = process.env.REACT_APP_URL;

//Ranking Search Overview
export const FETCH_RANKING_SEARCH_OVERVIEW = "FETCH_RANKING_SEARCH_OVERVIEW";
export const fetchRankingSearchOverview = (data) => ({
  type: FETCH_RANKING_SEARCH_OVERVIEW,
  request: {
    url: `${url}/v2/ranking/search-overview`,
    method: "POST",
    data,
  },
});

export const SET_PRISTINE_OVERVIEW_SEARCH = "SET_PRISTINE_OVERVIEW_SEARCH";
export const setPristineSearchOverview = (pristine) => {
  return {
    type: SET_PRISTINE_OVERVIEW_SEARCH,
    payload: pristine,
  };
};
