import React, { useState, useEffect, useRef } from "react";
import { Modal, Checkbox, Collapse } from "antd";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import moment from "moment";
import useColorTheme from "../../../../../hooks/useColorTheme";
import useRetailers from "../../../../../hooks/useRetailers";
import useCountry, { NUMBER_TYPE } from "../../../../../hooks/useCountry";

import СurrentlyItemChart from "tabs/RankingTabs/RankingBottomTabs/SearchAnalysisTab/charts/СurrentlyItemChart";
import CompareProductsChart from "tabs/RankingTabs/RankingBottomTabs/components/CompareProductsChart";
import LoaderBox from "components/LoaderBox";
import { getTimePeriodSingleDay } from "utils/getTimePeriodSingleDay";
import { sortData } from "utils/sortRankingTable";
import { timePeriod } from "utils/timePeriod";
import { STATE_STATUSES } from "utils/statuses";

import { CollapseClose, CollapseOpen } from "assets/icons/icons";

import sort from "assets/images/sort.svg";
import sortIconUp from "assets/images/sort_up.svg";
import sortIconDown from "assets/images/sort_down.svg";
import show_chart from "assets/images/show_chart.svg";
import show_chart_white from "assets/images/show_chart_white.svg";
import printscreen from "assets/images/printscreen.svg";
import featured from "assets/images/featured.svg";
import options from "assets/images/options.svg";

const AllTerms = (props) => {
  const {
    data,
    date,
    activeTab,
    isOpen,
    currentShareSearchSingleDay,
    storeShareSingleDay,
    type,
    actionSingleProduct,
    storeSingleProduct,
    actionRemoveProduct,
    actionProductCompare,
    storeProductCompare,
    statusProduct,
    isFeaturedProducts,
    setIsFeaturedProducts,
  } = props;

  const { Panel } = Collapse;

  const dispatch = useDispatch();
  const { getRemoteLogo } = useRetailers();
  const { formatCurrencyNumber } = useCountry();

  const wrapperRef = useRef(null);
  const { primaryColor, primaryColorRgb, getColorPromotions } = useColorTheme();

  const [sortParam, setSortParam] = useState("rank");
  const [directionSort, setDirectionSort] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [activeRetailer, setActiveRetailer] = useState("");

  const [showScreenshot, setShowScreenshot] = useState(false);
  const [screenshotUrl, sestScreenshotUrl] = useState("");
  const [currentRetailer, setCurrentRetailer] = useState("");

  const [currentDate, setCurrentDate] = useState(null);
  const [datePeriod, setDatePeriod] = useState(14);
  const [isOpenDatePeriod, setIsOpenDatePeriod] = useState(false);

  const [onlyMyProducts, setOnlyMyProducts] = useState(false);
  const [isCompare, setIsCompare] = useState(false);

  const [isCompareChart, setIsCompareChart] = useState(false);

  const [selectItems, setSelectItems] = useState([]);
  const [compareItems, setCompareItems] = useState([]);

  useEffect(() => {
    setCurrentDate(date);
    return () => dispatch({ type: actionRemoveProduct, payload: "" });
  }, []);

  useEffect(() => {
    setSelectItems([]);
    setCompareItems([]);
    setIsCompare(false);
    dispatch({ type: actionRemoveProduct, payload: "" });
  }, [props[storeShareSingleDay]]);

  useEffect(() => {
    const retailers = {};

    data[0].retailers.forEach((item, index) => Object.assign(retailers, { [item.title]: index }));

    setActiveRetailer(retailers[currentShareSearchSingleDay.retailer]);
  }, [currentShareSearchSingleDay]);

  const filterProducts = (data) =>
    data.filter((item) => {
      if (onlyMyProducts) {
        return item.fromSelectedManufacture === true;
      }
      return item;
    });

  const sliceData = (data, index) => {
    let convertData;

    if (data.length > 10 && index !== currentIndex) {
      convertData = data.slice(0, 10);
    } else if (data.length > 10 && index === currentIndex) {
      convertData = data;
    } else {
      convertData = data;
    }

    return convertData;
  };

  const handleSort = (name) => {
    if (sortParam !== name) {
      setDirectionSort(true);
    } else {
      setDirectionSort(!directionSort);
    }
    setSortParam(name);
  };

  const handleScreenshot = (e, item) => {
    e.stopPropagation();
    if (item.screenshot.length) {
      setShowScreenshot(true);
      sestScreenshotUrl(item.screenshot);
      setCurrentRetailer(item.retailer);
    }
  };

  const handleCollapse = (key) => {
    setActiveRetailer(key);

    const retailers = [];

    data[0].retailers.forEach((item) => retailers.push({ retailer: item.title, sourceType: item.id }));

    let sendData;

    if (key) {
      sendData = {
        retailer: retailers[key].retailer,
        sourceType: retailers[key].sourceType.toString(),
      };
    } else {
      sendData = { retailer: "", sourceType: "" };
    }

    setSelectItems([]);
    setCompareItems([]);
    setIsCompare(false);
    dispatch({ type: actionRemoveProduct, payload: "" });
    dispatch({
      type: "SET_CURRENT_SHARE_SEARCH_SINGLE_DAY",
      payload: sendData,
    });
  };

  const handleCheckbox = (productId) => {
    if (compareItems.includes(productId)) {
      setCompareItems((prevState) => prevState.filter((item) => item !== productId));
    } else {
      setCompareItems((prevState) => [...prevState, productId]);
    }
  };

  const handleCompareProducts = () => {
    if (compareItems.length >= 2) {
      actionProductCompare({
        type,
        raw: true,
        searchTerm: activeTab,
        sourceType: props[storeShareSingleDay].sourceType,
        timePeriod: getTimePeriodSingleDay(currentDate, datePeriod),
        product: compareItems.join("|"),
        page: "1",
        limit: "10",
      }).then(() => setIsCompareChart(true));
    }
  };

  const handleSetDatePeriod = (period) => {
    setDatePeriod(period);
    setIsOpenDatePeriod(false);

    actionSingleProduct(
      {
        type,
        raw: true,
        searchTerm: activeTab,
        sourceType: props[storeShareSingleDay].sourceType,
        timePeriod: getTimePeriodSingleDay(currentDate, period),
        product: selectItems.join("|"),
        page: "1",
        limit: "10",
      },
      false
    );
  };

  const handleChart = (productId, uid) => {
    if (selectItems.includes(productId)) {
      setSelectItems((prevState) => prevState.filter((item) => item !== productId));

      dispatch({ type: actionRemoveProduct, payload: uid });
    } else {
      setSelectItems((prevState) => [...prevState, productId]);

      actionSingleProduct(
        {
          type,
          raw: true,
          searchTerm: activeTab,
          sourceType: props[storeShareSingleDay].sourceType,
          timePeriod: getTimePeriodSingleDay(currentDate, datePeriod),
          product: productId.toString(),
          page: "1",
          limit: "10",
        },
        true
      );
    }
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      if (isOpenDatePeriod) {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setIsOpenDatePeriod(false);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
    }, [ref, isOpenDatePeriod]);
  }
  useOutsideAlerter(wrapperRef);

  return (
    <div className="collapse-terms-wrapper">
      <div className="popup-setting-box singleDay">
        <div className="title-terms all">
          Ranking <span> {data[0].title} </span>
          {props[storeShareSingleDay].retailer ? (
            <p>
              By <span>{props[storeShareSingleDay].retailer}</span>
            </p>
          ) : (
            "By Retailer"
          )}
        </div>
        <div className="controls-box">
          {!isCompare && selectItems.length ? (
            <div className="trend-perion-wrapper" ref={wrapperRef}>
              <div className="trend-perion" onClick={() => setIsOpenDatePeriod(!isOpenDatePeriod)}>
                <span className="label">Trend Period</span>
              </div>
              {isOpenDatePeriod ? (
                <div className="dropdown">
                  <div className="description">Relative</div>
                  <div className="period-box">
                    {timePeriod.map((item, index) => (
                      <div
                        key={index}
                        className="item-period"
                        style={{
                          border: `1px solid ${datePeriod === item.value ? primaryColor : "#dfdfea"}`,
                          fontFamily: datePeriod === item.value ? "Gilroy-ExtraBold" : "Gilroy-Medium",
                          color: datePeriod === item.value ? primaryColor : "#000",
                        }}
                        onClick={() => handleSetDatePeriod(item.value)}
                      >
                        {item.label}
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
          <div className="onlyProducts right-wrapper" style={{ marginRight: "15px" }}>
            <Checkbox checked={isFeaturedProducts} onChange={() => setIsFeaturedProducts(!isFeaturedProducts)}>
              Sponsored Products
            </Checkbox>
          </div>
          <div
            className="compare-data-popup"
            style={{
              background: isCompare ? primaryColor : "#eef2f7",
              color: isCompare ? "#fff" : "#000",
            }}
            onClick={() => setIsCompare(!isCompare)}
          >
            Compare Products
          </div>
          <div className="onlyProducts">
            <Checkbox checked={onlyMyProducts} onChange={() => setOnlyMyProducts(!onlyMyProducts)}>
              Show Only My Products
            </Checkbox>
          </div>
        </div>
      </div>

      <Collapse accordion onChange={handleCollapse} activeKey={isOpen ? ["0"] : [activeRetailer]}>
        {data[0].retailers.map((itemRetailer, indexRetailer) => (
          <Panel
            key={indexRetailer}
            showArrow={false}
            header={
              <div className="panel-header-term">
                <div className="icon-collapse">
                  {activeRetailer === indexRetailer ? (
                    <CollapseOpen color={"#B6BFC9"} />
                  ) : (
                    <CollapseClose color={"#B6BFC9"} />
                  )}
                </div>
                <div className="retailer">
                  <img src={getRemoteLogo(itemRetailer.title)} alt="retailer" />
                  <span>{filterProducts(itemRetailer.products).length} Products</span>
                </div>
                <div className="promotions">
                  {onlyMyProducts
                    ? filterProducts(itemRetailer.products).filter((item) => Boolean(item.promotions)).length
                    : itemRetailer.promotions}{" "}
                  On Promotion
                </div>
                <div className="price">
                  Avg Shelf Price
                  <br />{formatCurrencyNumber(itemRetailer.shelfPrice, NUMBER_TYPE.CURRENCY)}
                </div>
                <div className="printscreen-absolute">
                  {Boolean(itemRetailer.products[0].screenshot) ? (
                    <div className="icon-btn" onClick={(e) => handleScreenshot(e, itemRetailer.products[0])}>
                      <img src={printscreen} alt="printscreen" />
                    </div>
                  ) : (
                    <span className="no-screen">N/A</span>
                  )}
                </div>
              </div>
            }
          >
            <div className="table-header main">
              <div className="rank-sort" onClick={() => handleSort("rank")}>
                <div>Rank</div>
                <img src={sortParam === "rank" ? (directionSort ? sortIconUp : sortIconDown) : sort} alt="sort" />
              </div>
              <div className="title-sort" onClick={() => handleSort("title")}>
                <div>Product Title (Retailer)</div>
                <img src={sortParam === "title" ? (directionSort ? sortIconUp : sortIconDown) : sort} alt="sort" />
              </div>
              <div className="discription-sort">
                <div>Promotion Description</div>
              </div>
              <div className="price-sort" onClick={() => handleSort("shelfPrice")}>
                <div>Shelf Price</div>
                <img src={sortParam === "shelfPrice" ? (directionSort ? sortIconUp : sortIconDown) : sort} alt="sort" />
              </div>
              <div style={{ width: "100%", maxWidth: "100px" }}></div>
            </div>
            <div style={{ position: "relative" }}>
              <div className="table-body main">
                {sortData(
                  sliceData(filterProducts(itemRetailer.products), indexRetailer),
                  sortParam,
                  directionSort
                ).map((item, index) => (
                  <div key={index}>
                    <div
                      className={`table-item ${item.selectedProduct ? "selectedProduct" : ""}`}
                      style={{
                        borderBottom: selectItems.includes(item.coreProductId) ? "none" : "2px solid #e0e0e4",
                      }}
                    >
                      <div className="rank">
                        <span>{item.rank}</span>
                        {item.featured ? <img src={featured} alt="featured" /> : null}
                        {item.productOptions ? <img src={options} alt="options" /> : null}
                      </div>
                      <div className={`title-wrapper ${item.fromSelectedManufacture ? "selectedManufacture" : ""}`}>
                        <img src={item.image} alt="product" />
                        <div className="titles-box">
                          <div className="title">{item.title}</div>
                          <div className="subtitle">{item.detailTitle}</div>
                        </div>
                      </div>
                      <div className="description-wrapper">
                        <div
                          className="description-box"
                          style={{
                            background: item.promotions ? getColorPromotions(item.promotions.mechanic.name) : null,
                          }}
                        >
                          {item.promotions ? (
                            item.promotions.description
                          ) : (
                            <span className="no-promotion">No promotion</span>
                          )}
                        </div>
                      </div>
                      <div className="price">{formatCurrencyNumber(item.shelfPrice, NUMBER_TYPE.CURRENCY)}</div>
                      <div className="right-wrapper all">
                        {isCompare ? (
                          <Checkbox
                            checked={compareItems.includes(item.coreProductId)}
                            onChange={() => handleCheckbox(item.coreProductId)}
                          ></Checkbox>
                        ) : (
                          <div
                            className="icon-btn"
                            style={{
                              background: selectItems.includes(item.coreProductId) ? primaryColor : "#eef2f7",
                            }}
                            onClick={() => handleChart(item.coreProductId, item.uid)}
                          >
                            <img
                              src={selectItems.includes(item.coreProductId) ? show_chart_white : show_chart}
                              alt="back"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {props[storeSingleProduct].length
                      ? props[storeSingleProduct].map((chart, index) => {
                          if (chart.id == item.coreProductId && selectItems.includes(item.coreProductId)) {
                            return (
                              <div
                                key={index}
                                className="wrapper-bottom-chart"
                                style={{ borderBottom: "2px solid #e0e0e4" }}
                              >
                                <СurrentlyItemChart
                                  type={"rank"}
                                  data={chart.ranks}
                                  timePeriod={[0, chart.ranks.length]}
                                  isMarker={false}
                                  isPopup={true}
                                  outside={false}
                                />
                              </div>
                            );
                          }
                        })
                      : null}
                  </div>
                ))}
              </div>
              {props[statusProduct] === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
            </div>
            {filterProducts(itemRetailer.products).length > 10 && indexRetailer !== currentIndex ? (
              <div className="learn-more" onClick={() => setCurrentIndex(indexRetailer)}>
                Load more
              </div>
            ) : null}
            {isCompare ? (
              <div className="table-footer">
                <div className="compare-hint">
                  <div className="title">Compare Products</div>
                  <div className="hint">Select at least 2 products to compare.</div>
                </div>
                <div
                  className="compare-btn"
                  style={{
                    background: compareItems.length === 1 ?primaryColorRgb(0.5) : primaryColor,
                  }}
                  onClick={handleCompareProducts}
                >
                  {compareItems.length > 0 ? (
                    <span>{`Compare ${compareItems.length} Product${compareItems.length >= 2 ? "s" : ""}`}</span>
                  ) : (
                    "Select Products"
                  )}
                </div>
                <div className="shadow-box"></div>
              </div>
            ) : null}
          </Panel>
        ))}
      </Collapse>
      <Modal
        className="ranking-page-modal"
        visible={showScreenshot}
        onCancel={() => {
          setShowScreenshot(false);
          sestScreenshotUrl("");
        }}
      >
        <div className="title-popup-single-day">
          <span>{activeTab}</span> in <span>{currentRetailer}</span>
        </div>
        <div className="screenshot-wrapper">
          <img src={screenshotUrl} alt="screenshot" />
        </div>
      </Modal>
      <Modal
        className="ranking-page-modal bottom"
        visible={isCompareChart}
        onCancel={() => {
          setIsCompareChart(false);
        }}
      >
        <div className="wrapper-title-popup">
          <div className="product-title-popup">
            <div className="title">
              Ranking Product Comparison for
              <span style={{ textTransform: "capitalize" }}> {props[storeShareSingleDay].retailer} </span>{" "}
              <span style={{ textTransform: "lowercase" }}>“{activeTab}”</span>
            </div>

            <div className="subtitle">
              {moment(currentDate).subtract(datePeriod, "d").format("DD MMM YY")} -{" "}
              {moment(currentDate).format("DD MMM YY")}
            </div>
          </div>
        </div>

        <CompareProductsChart data={props[storeProductCompare]} />
      </Modal>
    </div>
  );
};

export default connect(
  (state) => ({
    currentShareSearchSingleDay: state.rankingSearchAnalysis.currentShareSearchSingleDay,
    searchSingleProduct: state.rankingSearchAnalysis.searchSingleProduct,
    searchSingleProductCompare: state.rankingSearchAnalysis.searchSingleProductCompare,
    statusSearch: state.rankingSearchAnalysis.popupStatus,

    currentShareLocationSingleDay: state.rankingLocationAnalysis.currentShareLocationSingleDay,
    analisisSingleProduct: state.rankingLocationAnalysis.analisisSingleProduct,
    analisisSingleProductCompare: state.rankingLocationAnalysis.analisisSingleProductCompare,
    statusLocation: state.rankingLocationAnalysis.popupStatus,
  }),
  null
)(AllTerms);
