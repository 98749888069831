import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "antd";

//Components
import { Styles } from "./styles";
import LoaderBox from "components/LoaderBox";
import ShowPerPage from "components/ShowPerPage";
import AssortmentDetailsTable from "./components/assortmentDetailsTable/AssortmentDetailsTable";
import AssortmentDetailsCompareRetailers from "./components/compareRetailers/AssortmentDetailsCompareRetailers";

//Utils
import { getTimePeriod } from "utils/getTimePeriod";
import { STATE_STATUSES } from "utils/statuses";

//Actions
import { addToDataAssortmentDetails, fetchAssortmentDetails } from "store/assortment/assortmentDetails/actions";
import { firstCharToUpperCase } from "utils/generalUtils";
import useFilters from "../../../../hooks/useFilters";

const AssortmentDetails = () => {
  //dispatch
  const dispatch = useDispatch();
  //selectors
  const { status, assortmentDetails, controlData, filter } = useSelector((state) => state.assortmentDetails);
  const { lastFilter } = useFilters();
  const { filters: globalFilters, status: filtersStatus } = useSelector((state) => state.filters);
  //States fro table
  const [sortId, setSortId] = useState(null);
  const [sortDirection, setSortDirection] = useState(true);
  const [inputSearch, setInputSearch] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [queryParams, setQueryParams] = useState({});
  const [isEmpty, setIsEmpty] = useState(false);
  const [isGetBtnClicked, setIsGetBtnClicked] = useState(true);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const baseQuery = {
    limit: 10,
    page: 1,
    sort: "product|asc",
    search: "",
  };

  useEffect(() => {
    setQueryParams((prevState) => ({
      ...prevState,
      sourceType: lastFilter.sourceType,
      timePeriod: getTimePeriod(lastFilter.date),
      product: lastFilter.product,
      listed: filter[0].value.join("|"),
      notListed: filter[1].value.join("|"),
      ...baseQuery,
    }));

    setLimit(10);
    setPage(1);
    setSortDirection(true);
    setSortId("product");
  }, [lastFilter, filter]);

  useEffect(() => {
    if (Object.keys(queryParams).length && isGetBtnClicked) {
      dispatch(fetchAssortmentDetails(queryParams)).then(() => setIsGetBtnClicked(false));
    }
  }, [queryParams, isGetBtnClicked]);

  const handleSearch = (value) => {
    setIsGetBtnClicked(true);
    setInputSearch(value);
    setQueryParams((prevState) => ({
      ...prevState,
      search: value,
      page: 1,
    }));
  };

  useEffect(() => {
    const globalRetailers = globalFilters.sourceType;
    const { result } = controlData;

    if (globalRetailers.length && result.length && queryParams?.listArray?.length) {
      const incomingRetailers = Object.keys(result[0].retailers);
      const retailers = globalRetailers
        .filter(({ id }) => incomingRetailers.some((el) => id === +el))
        .filter((r) => queryParams.listArray.includes(r.id));
      const sortedRetailers = retailers.sort((a, b) => {
        return incomingRetailers.indexOf(String(a.id)) - incomingRetailers.indexOf(String(b.id));
      });

      const rows = result.map((el) => {
        const {
          product: { title, image, id },
          retailers,
        } = el;

        return {
          title,
          image,
          id,
          data: retailers,
        };
      });

      const brands = result.map((el) => {
        const { brand } = el;

        return {
          title: brand.name,
          color: brand.color,
          id: brand.id,
        };
      });

      dispatch(addToDataAssortmentDetails({ retailers: sortedRetailers, rows, brands }));
    }
  }, [controlData, globalFilters.sourceType, queryParams.listArray]);

  const handleSort = (id) => {
    setIsGetBtnClicked(true);
    setSortDirection(!sortDirection);

    changePage(1);

    setSortId(id);

    const sort = `${id}|${!sortDirection ? "asc" : "desc"}`;

    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      sort,
    }));
  };

  const changePage = (page, lim) => {
    setIsGetBtnClicked(true);
    setPage(page);

    setQueryParams((prevState) => ({
      ...prevState,
      page,
    }));
  };

  const changeLimitHandler = (limit) => {
    setIsGetBtnClicked(true);
    setLimit(limit);
    changePage(1);

    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      limit,
    }));
  };

  useEffect(() => {
    if (controlData.result.length) {
      setIsEmpty(false);
    } else {
      setIsEmpty(true);
    }
  }, [controlData]);

  const renderTable = () => {
    if (!isFirstRender) {
      return (
        <AssortmentDetailsTable
          inputSearch={inputSearch}
          handleSearch={handleSearch}
          data={assortmentDetails}
          handleSort={handleSort}
          sortId={sortId}
          sortDirection={sortDirection}
          isEmpty={isEmpty}
        />
      );
    }
  };

  const renderCompareRetailers = () => {
    return (
      <AssortmentDetailsCompareRetailers
        setQueryParams={setQueryParams}
        getProductsBtnClicked={() => {
          setIsGetBtnClicked(true);
          setIsFirstRender(false);
        }}
      />
    );
  };

  const renderUpperText = () => {
    const {
      largestRetailer: {
        item: { name },
        count,
      },
    } = controlData;
    if (name.length) {
      return `${firstCharToUpperCase(name)} has the largest range of the selected retailers: ${count} product${count > 1 ? "s" : ""}.`;
    }
  };

  return (
    <Styles>
      <div className="wrapper-box-relative">
        {controlData.success && filtersStatus === STATE_STATUSES.READY ? (
          <div className="chart-title-desc" style={{ maxWidth: "100%" }}>
            {renderUpperText()}
          </div>
        ) : null}
        {controlData.success && filtersStatus === STATE_STATUSES.READY ? renderCompareRetailers() : null}
        <div className="chart-wrapper-box">{controlData.success && filtersStatus === STATE_STATUSES.READY ? renderTable() : null}</div>
        {controlData.success && filtersStatus === STATE_STATUSES.READY && !isEmpty && !isFirstRender ? (
          <div className="pagination-with-per-page">
            <Pagination
              className="pagination-controls"
              onChange={changePage}
              current={page}
              pageSize={limit}
              total={controlData.total}
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
            />
            <ShowPerPage setLimit={changeLimitHandler} value={limit} />
          </div>
        ) : null}
        {status === STATE_STATUSES.PENDING || filtersStatus === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
      </div>
    </Styles>
  );
};

export default AssortmentDetails;
