import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Link, withRouter, useHistory } from "react-router-dom";
import styled from "styled-components";
import { Modal, Tooltip } from "antd";
import useColorTheme from "../hooks/useColorTheme";
import Reports from "components/Reports";
import { STATE_STATUSES } from "../utils/statuses";
import { getTimePeriod } from "utils/getTimePeriod";
import { BUTTONS_TYPE } from "constants/buttons-type.constants";

import {
  Desktop,
  Promotions,
  Pricing,
  Ranking,
  Content,
  Rating,
  Media,
  Navigation,
  Availability,
  Assortment,
} from "assets/icons/icons";
import logo from "assets/images/general-logo-white.svg";
import useFilters from "hooks/useFilters";
import StyledButtonLink from "./ButtonLink";

const Styles = styled.div.attrs((props) => props)`
  width: 100px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px 5px;
  box-sizing: border-box;
  background: ${(props) => props.color};
  overflow: auto;
  &::-webkit-scrollbar {
    width: 2px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #abaaba;
    border-radius: 1px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #807f97;
  }
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 60px;
      width: 100%;
      height: 32px;
    }
  }
  .links-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 45px;
    color: ${(props) => props.color};
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px;
      transition: 0.3s;
      border-radius: 4px;
      margin: 15px 0;
      &:hover {
        box-shadow: 0 8px 20px 0 rgba(30, 31, 32, 0.18), 0 2px 4px 0 rgba(0, 0, 0, 0.17);
        background: #ffffff;
        svg g > :nth-child(n + 2) {
          fill: ${(props) => props.color};
        }
      }
    }
    @media (max-height: 900px) {
      margin-top: 60px;
      a {
        margin: 8px 0;
        padding: 8px;
      }
    }
  }
  .module-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    transition: 0.3s;
    border-radius: 4px;
    margin: 15px 0;
    &:hover {
      box-shadow: 0 8px 20px 0 rgba(30, 31, 32, 0.18), 0 2px 4px 0 rgba(0, 0, 0, 0.17);
      background: #ffffff;
      svg g > :nth-child(n + 2) {
        fill: ${(props) => props.color};
      }
    }
  }
  .report-btn {
    cursor: pointer;
    margin-top: "16px";
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    transition: 0.3s;
    border-radius: 4px;
    &:hover {
      box-shadow: 0 8px 20px 0 rgba(30, 31, 32, 0.18), 0 2px 4px 0 rgba(0, 0, 0, 0.17);
      background: #ffffff;
      svg g > :nth-child(n + 2) {
        fill: ${(props) => props.color};
      }
    }
  }
`;

const NavBar = (props) => {
  const {
    match: { path },
    isSelectAllTimePeriodsHandler,
  } = props;

  const [showReportsModal, setShowReportsModal] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState({});
  const showModal = (itemName) => {
    setIsModalOpen((prevState) => ({
      ...prevState,
      [itemName]: true,
    }));
  };
  const handleOk = (itemName) => {
    setIsModalOpen((prevState) => ({
      ...prevState,
      [itemName]: false,
    }));
  };
  const handleCancel = (itemName) => {
    setIsModalOpen((prevState) => ({
      ...prevState,
      [itemName]: false,
    }));
  };

  const { sections } = useSelector((state) => state.authorization);
  const { primaryColor, secondaryColor } = useColorTheme();
  const { lastFilter, fetchMainFilters, isFilterChange, resetSelectedFilters, statusTempFilters, tempLastFilter } = useFilters();
  const hasUnappliedChange = statusTempFilters !== STATE_STATUSES.INIT && isFilterChange
  const history = useHistory();
  const getIcons = (item) => {
    const icons = {
      summary: <Desktop fill={path.includes(item.name) ? primaryColor : item.status ? "#fff" : secondaryColor} />,
      pricing: <Pricing fill={path.includes(item.name) ? primaryColor : item.status ? "#fff" : secondaryColor} />,
      promotions: <Promotions fill={path.includes(item.name) ? primaryColor : item.status ? "#fff" : secondaryColor} />,
      ranking: <Ranking fill={path.includes(item.name) ? primaryColor : item.status ? "#fff" : secondaryColor} />,
      content: <Content fill={path.includes(item.name) ? primaryColor : item.status ? "#fff" : secondaryColor} />,
      rating: <Rating fill={path.includes(item.name) ? primaryColor : item.status ? "#fff" : secondaryColor} />,
      media: <Media fill={path.includes(item.name) ? primaryColor : item.status ? "#fff" : secondaryColor} />,
      navigation: <Navigation fill={path.includes(item.name) ? primaryColor : item.status ? "#fff" : secondaryColor} />,
      availability: (
        <Availability fill={path.includes(item.name) ? primaryColor : item.status ? "#fff" : secondaryColor} />
      ),
      assortment: <Assortment fill={path.includes(item.name) ? primaryColor : item.status ? "#fff" : secondaryColor} />,
    };

    return icons[item.name];
  };
  const applyFilters = useCallback((page = null, keep = true) => {
    isSelectAllTimePeriodsHandler && isSelectAllTimePeriodsHandler();
    const params = { ...lastFilter, ...{ timePeriod: getTimePeriod(lastFilter.date) } };
    keep
      ? fetchMainFilters({
          ...params,
          watchlist: false,
          isStart: false,
        })
      : resetSelectedFilters();

    if (page) history.push(page);
  }, [fetchMainFilters, history, isSelectAllTimePeriodsHandler, lastFilter, resetSelectedFilters]);

  const generateModalContent = useCallback((itemName, isValidated) => {
    return (
      <>
        <StyledButtonLink
          disabled={!tempLastFilter || tempLastFilter.product === ''}
          title={"Apply New Filters"}
          onClick={() => applyFilters(itemName)}
          type={isValidated && tempLastFilter && tempLastFilter.product !== '' ? BUTTONS_TYPE.SOLID : BUTTONS_TYPE.DISABLED}
          color={primaryColor}
        />
        <StyledButtonLink
          title={"Keep Old Filters"}
          onClick={() => applyFilters(itemName, false)}
          type={BUTTONS_TYPE.TRANSPARENT}
          color={primaryColor}
        />
        <StyledButtonLink
          title={"Cancel"}
          onClick={() => handleCancel(itemName)}
          type={BUTTONS_TYPE.CANCEL}
          color={primaryColor}
        />
      </>
    );
  }, [applyFilters, primaryColor, tempLastFilter]);

  return (
    <Styles color={primaryColor}>
      <div>
        <div className="logo">
          <Link to="/summary">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className="links-wrapper">
          {sections.map((item) =>
            isFilterChange && item.status ? (
              <div key={item.name}>
                <Tooltip
                  color={primaryColor}
                  placement="right"
                  title={
                    item.status ? item.title : `${item.title} - This module is not part of your current subscription.`
                  }
                >
                  <button
                    disabled={!item.status}
                    onClick={() => showModal(item.name)}
                    style={{ background: "transparent", border: "none" }}
                  >
                    <div className="module-icon" style={{ background: `${path.includes(item.name) ? "#fff" : ""}` }}>
                      {getIcons(item)}
                    </div>
                  </button>
                </Tooltip>
                <Modal
                  key={item.name}
                  visible={isModalOpen[item.name] || false}
                  title={`${item.name.charAt(0).toLocaleUpperCase() + item.name.substring(1)} - Unapplied Filters`}
                  onOk={() => handleOk(item.name)}
                  onCancel={() => handleCancel(item.name)}
                  centered
                  footer={null}
                >
                  <div className="error-popup">
                    <div className="buttons-container">{generateModalContent(item.name, hasUnappliedChange)}</div>
                  </div>
                </Modal>
              </div>
            ) : (
              <Tooltip
                color={primaryColor}
                key={item.name}
                placement="right"
                title={
                  item.status ? item.title : `${item.title} - This module is not part of your current subscription.`
                }
              >
                <button disabled={!item.status} style={{ background: "transparent", border: "none", outline: "none" }}>
                  <Link to={`/${item.name}`} style={{ background: `${path.includes(item.name) ? "#fff" : ""}` }}>
                    {getIcons(item)}
                  </Link>
                </button>
              </Tooltip>
            )
          )}
        </div>
      </div>

      <Modal
        className="export-modal"
        title="Custom reporting section"
        visible={showReportsModal}
        onCancel={() => setShowReportsModal(false)}
      >
        <Reports />
      </Modal>
    </Styles>
  );
};

export default withRouter(NavBar);
