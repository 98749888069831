const url = process.env.REACT_APP_URL;

//Price Trend
export const FETCH_TREND = "FETCH_TREND";
export const fetchTrend = (data) => ({
  type: FETCH_TREND,
  request: {
    url: `${url}/v2/price`,
    method: "POST",
    data,
  },
});

export const FETCH_COMPARE_TREND = "FETCH_COMPARE_TREND";
export const fetchCompareTrend = (data) => ({
  type: FETCH_COMPARE_TREND,
  request: {
    url: `${url}/v2/price`,
    method: "POST",
    data,
  },
  meta: {
    asPromise: true,
  },
});

export const CLEAR_COMPARE_TREND = "CLEAR_COMPARE_TREND";
export const clearCompareTrend = () => {
  return {
    type: CLEAR_COMPARE_TREND,
  };
};

export const FETCH_PRICE_TREND_POPUP = "FETCH_PRICE_TREND_POPUP";
export const fetchCurrentPricePopup = (coreProductId, retailerId, timePeriod) => ({
  type: FETCH_PRICE_TREND_POPUP,
  request: {
    url: `${url}/v2/price/current/${coreProductId}/retailers/${retailerId}`,
    method: "POST",
    data: { timePeriod },
  },
  meta: {
    asPromise: true,
  },
});

export const SET_PRISTINE_PRICE_TREND = "SET_PRISTINE_PRICE_TREND";
export const setPristinePriceTrend = (pristine) => {
  return {
    type: SET_PRISTINE_PRICE_TREND,
    payload: pristine,
  };
};
