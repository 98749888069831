import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import { Form, Input, InputNumber, Button } from "antd";
import { Colorpicker, ColorPickerValue } from 'antd-colorpicker'
import { LoadingOutlined } from "@ant-design/icons";
import { COLOR_SHIFT_FACTOR } from "../../../assets/colors/colors";
import { hexToRgbShift } from "../../../utils/hexToRgbA";
//

//
import { STATE_STATUSES } from "utils/statuses";
//
import { Styles } from "./styles";
//
import LoaderBox from "components/LoaderBox";
//
import { editSettingsYourProfile, setSettingsAvatar } from "store/settings/settingsYourProfile/actions";
import ErrorRow from "components/ErrorRow";

import _ from "lodash";
import AvatarPlaceholder from "components/AvatarPlaceholder";

import useColorTheme from "../../../hooks/useColorTheme";

const fieldNames = [
  'avatar',
  'email',
  'jobTitle',
  'name',
  'phone',
  'primaryColor',
  'shortName',
]

const SettingsYourProfileTab = ({ form, imageState, setImageState }) => {
  const { primaryColor, secondaryColor, defaultColorTheme, primaryColorRgb } = useColorTheme();
  const { settingsYourProfile, status, error } = useSelector((state) => state.settingsYourProfile);
  
  const dispatch = useDispatch();
  const { avatar, company, email, jobTitle, first_name: name, phone, last_name: shortName } = settingsYourProfile;
  const profileObj = { avatar, companyAvatar: company.avatar, email, jobTitle, name, phone, shortName, primaryColor, secondaryColor };

  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [isLoadProfile, setIsLoadProfile] = useState(false);
  const [isImageChange, setIsImageChange] = useState(false);

  const checkFieldChange = useCallback(() => {
    const formValues = form.getFieldsValue();
    let isChange = false;
    fieldNames.forEach(f => {
      let fieldValue;
      if (f === 'primaryColor') {
        fieldValue = typeof formValues[f] === 'object' ? formValues[f].hex : formValues[f];
        if (fieldValue !== profileObj[f]) {
          isChange = true;
          return;
        }

      } else if (f === 'avatar') {
        if (!!profileObj.avatar) {
          fieldValue = imageState.imageUrl;
          if (imageState.imageUrl !== profileObj.avatar) {
            isChange = true;
            setIsImageChange(true);
            return;
          }
        } else {
          fieldValue = !!imageState.imageUrl ? imageState.imageUrl : null;
          if (!!imageState.imageUrl) {
            isChange = true;
            setIsImageChange(true);
            return;
          }
        }
        setIsImageChange(false);
      } else {
        fieldValue = formValues[f];

        if (fieldValue !== profileObj[f]) {
          isChange = true;
          return;
        }
      }
    });
    setIsDisabledButton(!isChange);
  }, [form, profileObj, imageState]);

  useEffect(() => {
    if (isLoadProfile) checkFieldChange();
  }, [checkFieldChange, isLoadProfile]);

  const onTouchFields = () => {
    checkFieldChange();
  };

  useEffect(() => {
    if (status === STATE_STATUSES.READY && !isLoadProfile) {
      setIsLoadProfile(true);
      form.setFieldsValue(profileObj);
      setImageState((preImageState) => {
        return {
          ...preImageState,
          imageUrl: profileObj.avatar,
        };
      });
    }
    checkFieldChange();
  }, [form, setImageState, profileObj, isLoadProfile, status, checkFieldChange]);

  useEffect(() => {
    setImageState((preImageState) => {
      return {
        ...preImageState,
        imageUrl: avatar,
      }
    });
  }, [avatar, setImageState])

  const onChangePicture = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    const data = new FormData();
    data.append("file", file);

    if (file) {
      reader.onloadend = () => {
        setImageState((preImageState) => {
          return {
            ...preImageState,
            file: data,
            imageUrl: URL.createObjectURL(file),
          }
        });
      };

      reader.readAsDataURL(file);
    }
  };

  const onFinish = useCallback((values) => {
    const primarySelected = typeof values.primaryColor === 'object' ? values.primaryColor.hex : values.primaryColor;

    const data = {
      first_name: values.name,
      last_name: values.shortName,
      jobTitle: `${values.jobTitle || ''}`,
      phone: `${values.phone || ''}`,
      colorTheme: {
        primary: primarySelected || values.primaryColor,
        secondary: hexToRgbShift(primarySelected, COLOR_SHIFT_FACTOR),
      }
    };

    if (isImageChange) {
      if (imageState.file) {
        dispatch(setSettingsAvatar(imageState.file));
      } else if (!imageState.imageUrl) {
        const data = new FormData();
        data.append("file", {});
        dispatch(setSettingsAvatar(data));
      }
    }

    dispatch(editSettingsYourProfile(data));
    setIsLoadProfile(false);
  },[dispatch, imageState, isImageChange]);

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };

  const validateMessages = {
    required: "Please input your ${label}!",

    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
  };

  const resetButton = (
    <>
      <label htmlFor="file" className="upload-button" onClick={
        () => {
          setImageState({
            loading: false,
            file: "",
            imageUrl: profileObj.avatar,
          });
          setIsImageChange(false);
          checkFieldChange();
        }
      }>
        Reset
      </label>
    </>
  );
  const uploadButton = (
    <div className="image-container">
      <input type="file" id="file" onChange={onChangePicture} />
      <label htmlFor="file">
        {imageState.loading ? (
          <LoadingOutlined />
        ) : (
          company.avatar ? (
            <div className="avatar-placeholder">
              <img src={company.avatar} alt="avatar" />
            </div>
          ) :
          <AvatarPlaceholder firstName={settingsYourProfile.first_name} lastName={settingsYourProfile.last_name} />
        )}
      </label>
      <div className="image-text-container">
        {!!profileObj.avatar && isImageChange  && resetButton}
        <label htmlFor="file" className="upload-button">
          Upload a Picture
        </label>
      </div>
    </div>
  );

  const editButton = (
    <div className="image-container">
      <div className="avatar-placeholder">
        <img src={imageState.imageUrl} alt="avatar" />
      </div>
      <div className="image-text-container">
        {!!profileObj.avatar && isImageChange ?
          resetButton :
          (
            <>
              <input type="file" id="file" onChange={onChangePicture} />
              <label htmlFor="file" className="upload-button">
                Change
              </label>
            </>
        )}
          <Button
            className="remove-button"
            onClick={() => {
              setImageState({
                loading: false,
                file: "",
                imageUrl: "",
              });
            }}
          >
            Remove
          </Button>
      </div>
    </div>
  );

  const resetColorTheme = () => {
    form.resetFields();
    form.setFieldsValue({
      ...profileObj,
      primaryColor,
    });
    checkFieldChange();
  }

  return (
    <Styles>
      {!!error ? <ErrorRow message={error?.message ? error.message : error} /> : null}
      {status === STATE_STATUSES.READY &&
      <Form
        name="profileForm"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        labelAlign="left"
        validateMessages={validateMessages}
        onFieldsChange={onTouchFields}
      >
        <Form.Item name="avatar" label="Your Picture" className="avatar-label">
          {imageState.imageUrl ? editButton : uploadButton}
        </Form.Item>

        <Form.Item
          label="First Name"
          name="name"
        >
          <Input placeholder="Enter your First Name" />
        </Form.Item>

        <Form.Item
          label="Last Name"
          name="shortName"
        >
          <Input placeholder="Enter your Last Name" />
        </Form.Item>

        <Form.Item
          label="Job Title"
          name="jobTitle"
        >
          <Input placeholder="Your Job" />
        </Form.Item>

        <Form.Item name="email" label="E-mail" rules={[{ type: "email", required: true }]}>
          <Input placeholder="Mattyboy@brandnudge.co.uk" disabled />
        </Form.Item>

        <Form.Item
          label="Phone"
          name="phone"
        >
          <Input type="number" placeholder="Enter your Phone Number" />
        </Form.Item>

        <div className="color-theme-wrapper">
          <Form.Item label={'Color Theme'} name="primaryColor">
            <Colorpicker popup
              blockStyles={{
                width: '30px',
                height: '30px',
                borderRadius: '50%',
              }}
            />
          </Form.Item>
          
          <Button
            type="default"
            style={{
                color: company?.color?.primary || defaultColorTheme.primary
              }}
            onClick={resetColorTheme}
          >
              Reset Color Theme
          </Button>

        </div>

        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={isDisabledButton}>
            Save
          </Button>
        </Form.Item>
      </Form>
      }

      {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
    </Styles>
  );
};

export default SettingsYourProfileTab;
