import styled from "styled-components";

export const Styles = styled.div`
  font-weight: initial;
  position: relative;
  min-height: 280px;
  .text-container {
    width: 600px;
    font-size: 14px;
    font-family: "Gilroy-Medium";
    margin-bottom: 40px;
    font-weight: 500;
  }
  .product-text-box-title {
    font-weight: initial;
    font-family: "Gilroy-ExtraBold";
  }
  .product-text-box-desc {
    font-weight: initial;
    font-family: "Gilroy-Regular";
    opacity: 0.65;
  }
  .colour {
    border-radius: 2px;
  }
  .col-2.item-product {
    font-family: "Gilroy-Medium";
  }
`;
