import React, { useState, useEffect, useRef } from "react";
import { Select, Radio, Checkbox } from "antd";
import styled from "styled-components";
import useRetailers from "../../hooks/useRetailers";
import { ArrowSelect } from "assets/icons/icons";
import { forwardRef } from "react";

const Styles = styled.div`
  margin-left: ${(props) => (props.isMargin ? "0" : "15px")};

  .ant-select-item {
    span {
      text-transform: capitalize;
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background: #eef2f7;
    border: none;
    height: 44px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    width: ${(props) => props.customWidth} !important;
    span {
      text-transform: capitalize;
    }

    .ant-select-selection-item {
      .ant-radio {
        display: none;
      }
    }
  }
`;

const SelectBox = forwardRef((props, incomeRef) => {
  const { Option } = Select;

  const { data, setSelectValue, filter, disabled, alignRight, isMargin, color, customWidth } = props;

  const [value, setValue] = useState("");
  const [maxOptionWidth, setMaxOptionWidth] = useState(0);
  const spanWidthMultiplier = data.name === "byManufacturer" ? 9.82 : 7.67;
  const { getRemoteLogo } = useRetailers();

  const ref = useRef();

  useEffect(() => {
    if (filter) {
      setValue(filter);
    } else {
      setValue(data.default);
    }
  }, [filter, data]);

  const handleChange = (value) => {
    setValue(value);
    setSelectValue({ [data.name]: value });
  };

  useEffect(() => {
    if (data.options.length) {
      const lablesLengthArr = data.options.map(({ lable }) => lable.length);
      const maxLengthEl = Math.max(...lablesLengthArr);

      setMaxOptionWidth(maxLengthEl);
    }
  }, [data]);

  return (
    <Styles isMargin={isMargin} customWidth={customWidth}>
      <div ref={incomeRef ? incomeRef : ref}>
        <Select
          suffixIcon={<ArrowSelect />}
          disabled={disabled}
          name={data.name}
          showSearch={false}
          placeholder="Please select"
          onChange={handleChange}
          value={
            color ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{ background: color, width: "10px", height: "10px", borderRadius: "2px", marginRight: "5px" }}
                ></div>
                <div>{value}</div>
              </div>
            ) : (
              value
            )
          }
          dropdownMatchSelectWidth={false}
          getPopupContainer={(trigger) => trigger.parentElement}
          dropdownAlign={
            alignRight
              ? {
                  offset: [-maxOptionWidth * spanWidthMultiplier + ref?.current?.offsetWidth, 4],
                }
              : null
          }
        >
          {data.options.map((item, index) => (
            <Option value={item.value} disabled={item.disabled} key={index}>
              {data.name !== "select-colour" ? (
                <Radio disabled={item.disabled || disabled} checked={value === item.value}>
                  {data.name === "retailer" ? (
                    <img className="retailer-img" src={getRemoteLogo(item.lable.toLowerCase().replaceAll(' ', '_'))} alt="retailer" />
                  ) : data.name === "byManufacturer" ? (
                    <div className="select-color" style={{ background: item.color }}></div>
                  ) : null}
                  <span>{item.lable}</span>
                </Radio>
              ) : (
                <div className="select-color" style={{ background: item.value, width: 16, height: 16 }}></div>
              )}
            </Option>
          ))}
        </Select>
      </div>
    </Styles>
  );
});

export default SelectBox;
