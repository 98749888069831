import { success, error } from "redux-saga-requests";
import {
  FETCH_ASSORTMENT_SUMMARY_TOP,
  FETCH_ASSORTMENT_SUMMARY_TOP_COMPARE,
  SET_PRISTINE_ASSORTMENT_SUMMARY_TOP,
  SET_ASSORTMENT_SUMMARY_TOP_FILTER,
  RESET_ASSORTMENT_SUMMARY_TOP_COMPARE,
} from "./actions";
import { STATE_STATUSES } from "utils/statuses";
import { RESET_ERROR } from "store/error/actions";

const defaultFilter = [
  {
    name: "showBrands",
    value: false,
  },
  {
    name: "products",
    value: "count",
  },
  {
    name: "byBrand",
    value: "brand",
  },
];

const defaultValue = { success: false, largestRetailer: { item: {}, products: 0, group: 0 }, result: [] };

const initialState = {
  assortmentSummaryTop: defaultValue,
  comparativeValues: defaultValue,
  status: STATE_STATUSES.INIT,
  isPristine: true,
  filter: localStorage.getItem(SET_ASSORTMENT_SUMMARY_TOP_FILTER)
    ? JSON.parse(localStorage.getItem(SET_ASSORTMENT_SUMMARY_TOP_FILTER))
    : defaultFilter,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ASSORTMENT_SUMMARY_TOP: {
      return processReducer(state);
    }
    case FETCH_ASSORTMENT_SUMMARY_TOP_COMPARE: {
      return processReducer(state);
    }

    case success(FETCH_ASSORTMENT_SUMMARY_TOP): {
      return {
        ...state,
        assortmentSummaryTop: { success: true, ...action.data },
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }
    case success(FETCH_ASSORTMENT_SUMMARY_TOP_COMPARE): {
      return {
        ...state,
        comparativeValues: { success: true, ...action.data },
        status: STATE_STATUSES.READY,
      };
    }

    case SET_PRISTINE_ASSORTMENT_SUMMARY_TOP: {
      return {
        ...state,
        isPristine: action.payload,
      };
    }

    case SET_ASSORTMENT_SUMMARY_TOP_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }

    case RESET_ASSORTMENT_SUMMARY_TOP_COMPARE: {
      return {
        ...state,
        comparativeValues: defaultValue,
      };
    }

    case error(FETCH_ASSORTMENT_SUMMARY_TOP):
    case error(FETCH_ASSORTMENT_SUMMARY_TOP_COMPARE): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
