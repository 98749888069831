import { COLORS } from "assets/colors/colors";
import styled from "styled-components";

export const Styles = styled.div`
  display: flex;
  flex-direction: row;
  width: 592px;
  padding: 24px 16px;
  background-color: ${COLORS.lightGrayOpacity};
  .imgWrapper {
    margin-right: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${COLORS.lightGray};
    border-radius: 5px;
    background-color: #fff;
    max-height: 78px;
    max-width: 78px;
  }
  .productImg {
    width: 72px;
    height: 72px;
    padding: 6px;
  }
  .column {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .title-wrapper {
    margin-bottom: 11px;
    width: 100%;
  }
  .title {
    font-size: 14px;
    font-family: Gilroy-ExtraBold;
    font-weight: bold;
  }
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }
  .half {
    width: 50%;
    display: flex;
  }
  .text {
    font-size: Gilroy-Medium;
    opacity: 0.9;
  }
`;
