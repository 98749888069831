import React from "react";
import { CollapseClose, CollapseOpen } from "assets/icons/icons";
import { Input, Button } from "antd";

const EmailInputs = ({ disabled, emails, email, handleAddInput, handleDeleteInput, handleChangeInput }) => {
  return (
    <>
      <div className="export-label">Users added below will also be sent report</div>
      {emails.map((item, index) => (
        <div key={index}>
          <div className="add-email-box">
            <Input
              className="export-input"
              style={{ border: item.length && item.split("@")[1] !== email.split("@")[1] ? "1px solid red" : "none" }}
              disabled={disabled}
              value={item}
              onChange={(e) => handleChangeInput(e.target.value, index)}
              placeholder="E-Mail address"
            />
            {index === emails.length - 1 ? (
              <Button
                disabled={disabled}
                style={{ display: "flex", border: "none", padding: 0, alignItems: "center", background: "none" }}
                onClick={handleAddInput}
              >
                <CollapseClose color={"#BAC2CB"} />
              </Button>
            ) : (
              <Button
                disabled={disabled}
                style={{ display: "flex", border: "none", padding: 0, alignItems: "center", background: "none" }}
                onClick={() => handleDeleteInput(index)}
              >
                <CollapseOpen color={"#BAC2CB"} />
              </Button>
            )}
          </div>
          {item.length && item.split("@")[1] !== email.split("@")[1] ? (
            <span className="error-hint">Domains must match</span>
          ) : null}
        </div>
      ))}
    </>
  );
};

export default EmailInputs;
