import React, { useRef, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Route, Redirect, withRouter } from "react-router-dom";
import { logOutRequest, setLogoutEnd } from "store/authorization/actions";
import { STATE_STATUSES } from "../../utils/statuses";
import WrapperComponent from "../../templates/WrapperComponent";

const PrivateRoute = ({
  component: Component,
  loginStatus, prepareLogoutStatus, logoutStatus, mfaStatus,
  ...rest
}) => {
  const allSections = [
    ...rest.sections,
    ...[
      { name: "settings", status: true },
      { name: "alerts", status: true },
      { name: "create-alert", status: true },
      { name: "alert", status: true },
    ],
  ];

  const timer = useRef(null);

  useEffect(()=>{
    if (prepareLogoutStatus === STATE_STATUSES.READY && logoutStatus === STATE_STATUSES.PENDING ) {
      // Fade-Out proccess ended and trigger the actual Logout
      timer.current = setTimeout(() => rest.setLogoutEnd(), 0);
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  },[prepareLogoutStatus, logoutStatus])

  return (
    <Route
      {...rest}
      render={(props) => {
        if (rest.isMfa) {
          return rest.path.split("/")[1] === 'otp' ?
            <WrapperComponent {...props}><Component /></WrapperComponent> : <Redirect to={`/otp`} />;
        }
    
        if (rest.token.length &&
            rest.path.split("/")[1] !== 'otp' &&
            (mfaStatus === STATE_STATUSES.READY || loginStatus === STATE_STATUSES.READY)) {
          if (rest.sections.length && allSections.find((section) => section.name === rest.path.split("/")[1]).status) {
            return <WrapperComponent {...props}><Component /></WrapperComponent>;
          }

          if (rest.sections.length && !allSections.find((section) => section.name === rest.path.split("/")[1]).status) {
            const link = allSections.find((item) => item.status).name;
            return <Redirect to={`/${link}`} />;
          }

          if (!rest.sections.length) {
            rest.logOutRequest();
            return <Redirect to="/" />;
          }
        } else {
          return <Redirect to="/" />;
        }
      }}
    />
  );
};

export default connect(
  (state) => ({
    token: state.authorization.token,
    isMfa: state.authorization.isMfa,
    mfaStatus: state.authorization.mfaStatus,
    sections: state.authorization.sections,
    loginStatus: state.authorization.loginStatus,
    prepareLogoutStatus: state.authorization.prepareLogoutStatus,
    logoutStatus: state.authorization.logoutStatus,
  }),
  { logOutRequest, setLogoutEnd }
)(withRouter(PrivateRoute));
