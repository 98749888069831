import React, { useState, useEffect } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment'
import { hexToRgbA } from "utils/hexToRgbA"
import useColorTheme from "../../../../hooks/useColorTheme";

const CompareProductsChart = ({ data }) => {
    const [dates, setDates] = useState([])
    const [pricings, setPricings] = useState([])
    const [selectedLegend, setSelectedLegend] = useState([])
    const [legends, setLegends] = useState([])
    const { primaryColor } = useColorTheme();

    useEffect(() => {
        if(data.length) {
            const pricings = data.map(item => ({
                id: item.id,
                name: item.title,
                color: selectedLegend.length ? selectedLegend.includes(item.id) ? hexToRgbA(item.color, '1') : hexToRgbA(item.color, '.3') :  hexToRgbA(item.color, '1'),
                image: item.image,
                data: item.ranks.map(item => item.value)
            }))

            const datesMoment = data[0].ranks.map(item => moment(item.date).format('DD MMM YYYY'))

            setLegends(pricings)
            setDates(datesMoment);
            setPricings(pricings)
        }
    }, [data, selectedLegend])

    const handleSelectProduct = (id) => {
        if(selectedLegend.includes(id)) {
            setSelectedLegend(prevState => prevState.filter(item => item !== id))
        } else {
            setSelectedLegend(prevState => [...prevState, id])
        }
    }

    const options = {
        title: '',
        chart: {
            type: 'areaspline',
            height: 300,
            style: {
                fontFamily: "Gilroy-Medium"
            }
        },
        plotOptions: {
            series: {
                label: {
                    connectorAllowed: false
                },
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
            },
            areaspline: {
                fillColor: 0,
                marker: {
                    enabled: false,
                },
                lineWidth: 3,
                threshold: null
            },
        },
        xAxis: {
            crosshair: {
                width: 2,
                color: '#384493'
            },
            type: 'datetime',
            labels: {
                step: parseInt(dates.length / 3)
            },
            categories: dates,
        },
        yAxis: {
            allowDecimals: false,
            title: {
                text: "Ranking Position",
            },
            labels: {
                formatter: function () {
                    return `${Math.abs(this.value)}`
                }
            }
        },
        tooltip: {
            shared: true,
            useHTML: true,
            backgroundColor: null,
            borderWidth: 0,
            zIndex: 999,
            formatter: function () {
                let compareData = this.points.map((item) => {
                    return (
                        `<div class='wrapper'>
                            <div class='box'>
                                <div class='color' style='background: ${item.color}' ></div>
                                <div class='name'>${item.series.options.name}</div>
                            </div>
                            <div class='price'>${Math.abs(item.y)}</div>
                        </div>`
                    )
                })
                compareData = compareData.join('')

                return (
                    `<div class='wrapper-category'>
                        <div class='title'>Rank Position</div>
                        <div class='wrapper-box'>
                            <div>
                                <div class='date'>${moment(this.points[0].key).format('DD MMM YY')}</div>
                                <div>
                                    ${compareData}
                                </div>
                            </div>
                        </div>
                    </div>`
                )
            },
        },
        legend: {
            enabled: false
        },
        series: pricings,
    }

    return (
        <>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
            <div className='lables-compare-product'>
                {legends.map((item, index) => 
                    <div 
                        key={index} 
                        className='lable-product'
                        style={{ border: `solid 1px ${selectedLegend.includes(item.id) ? primaryColor : '#dbdbdd'}` }} 
                        onClick={() => handleSelectProduct(item.id)}
                    >
                        <div style={{ background: item.color }} className='color-box'></div>
                        <img src={item.image} alt='prodcuts'/>
                        <div>{item.name}</div>
                    </div>)}
            </div>
        </>
        
    )
}

export default CompareProductsChart