import { success, error } from "redux-saga-requests";
import {
  FETCH_PATH_PURCHASE,
  REMOVE_PURCHASE_ALL_PRODUCTS,
  REMOVE_PURCHASE_PRODUCT,
  SET_PRISTINE_PATH_PURCHASE,
} from "./actions";
import { STATE_STATUSES } from "utils/statuses";
import { RESET_ERROR } from "store/error/actions";

const initialState = {
  pathPurchase: [],
  status: STATE_STATUSES.INIT,
  isPristine: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PATH_PURCHASE: {
      return processReducer(state);
    }
    case success(FETCH_PATH_PURCHASE): {
      return {
        ...state,
        pathPurchase: [...state.pathPurchase, action.data],
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }
    case error(FETCH_PATH_PURCHASE): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case REMOVE_PURCHASE_ALL_PRODUCTS: {
      return {
        ...state,
        pathPurchase: [],
      };
    }

    case REMOVE_PURCHASE_PRODUCT: {
      const products = state.pathPurchase.filter((item) => item.id !== action.payload);

      return {
        ...state,
        pathPurchase: products,
      };
    }

    case SET_PRISTINE_PATH_PURCHASE: {
      return {
        ...state,
        isPristine: action.payload,
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
