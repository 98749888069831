import { useSelector } from "react-redux";
import { COLORS } from '../assets/colors/colors';
import { hexToRgbA } from '../utils/hexToRgbA';
import { STATE_STATUSES } from "utils/statuses";

const formatAntD = (r, g, b, opacity) => ({r, g, b, a: opacity})
 
const defaultColorTheme = {
    primary: {...hexToRgbA(COLORS.primaryBlue, 1, formatAntD), hex:COLORS.primaryBlue},
    secondary: {...hexToRgbA(COLORS.secondaryBlue, 1, formatAntD), hex:COLORS.secondaryBlue},
}

export default function useColorTheme() {
    const { settingsYourProfile } = useSelector((state) => state.settingsYourProfile);
    const { isMfa, mfaStatus, colorTheme: authColorTheme, company: authCompany } = useSelector((state) => state.authorization);
    const { colorTheme, isPristine, status, company } = settingsYourProfile;
    const cachedColorTheme = JSON.parse(localStorage.getItem('colorTheme'))
    const lastColorTheme = JSON.parse(localStorage.getItem('lastColor'))

    const primaryColor = isMfa && mfaStatus !== STATE_STATUSES.READY ?
      COLORS.primaryBlue :
      cachedColorTheme?.primary || colorTheme?.primary || company?.color?.primary ||authColorTheme?.primary || authCompany?.color?.primary || COLORS.primaryBlue;
    const secondaryColor = isMfa && mfaStatus !== STATE_STATUSES.READY ?
      COLORS.secondaryBlue :
      cachedColorTheme?.secondary || colorTheme?.secondary || company?.color?.secondary || authColorTheme?.secondary || authCompany?.color?.secondary || COLORS.secondaryBlue;
    const primaryColorRgb = (opacity=1) => hexToRgbA(primaryColor, opacity);

    const getColorPromotions = (value) => {
        const colors = {
            'Price Cut': '#56bfc4',
            'Multibuy': '#ea5f94',
            'Other': primaryColor
        }
        return colors[value]
    }

    return {
        primaryColor,
        secondaryColor,
        primaryColorRgb,
        defaultColorTheme,
        getColorPromotions,
        isPristine,
        status,
        lastColorTheme
    }
}
