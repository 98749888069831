import { success, error } from "redux-saga-requests";
import { FETCH_CURRENT_PRICES, FETCH_CURRENT_PRICE_POPUP, SET_PRISTINE_CURRRENT_PRICES } from "./actions";
import { RESET_ERROR } from "store/error/actions";

import { STATE_STATUSES } from "utils/statuses";

const initialState = {
  currentPrices: {},
  popupPrices: {},
  status: STATE_STATUSES.INIT,
  isPristine: true,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CURRENT_PRICES:
    case FETCH_CURRENT_PRICE_POPUP: {
      return processReducer(state);
    }
    case success(FETCH_CURRENT_PRICES): {
      return {
        ...state,
        currentPrices: action.data,
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }

    case success(FETCH_CURRENT_PRICE_POPUP): {
      return {
        ...state,
        popupPrices: action.data,
        status: STATE_STATUSES.READY,
      };
    }

    case error(FETCH_CURRENT_PRICE_POPUP):
    case error(FETCH_CURRENT_PRICES): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case SET_PRISTINE_CURRRENT_PRICES: {
      return {
        ...state,
        isPristine: action.payload,
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
