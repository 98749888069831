const url = process.env.REACT_APP_URL;

export const FETCH_ASSORTMENT_SUMMARY_BOTTOM = "FETCH_ASSORTMENT_SUMMARY_BOTTOM";
export const fetchAssortmentSummaryBottom = (params) => ({
  type: FETCH_ASSORTMENT_SUMMARY_BOTTOM,
  request: {
    url: `${url}/v2/assortment/table`,
    method: "POST",
    data: params,
  },
});

export const SET_PRISTINE_ASSORTMENT_SUMMARY_BOTTOM = "SET_PRISTINE_ASSORTMENT_SUMMARY_BOTTOM";
export const setPristineAssortmentSummaryBottom = (pristine) => {
  return {
    type: SET_PRISTINE_ASSORTMENT_SUMMARY_BOTTOM,
    payload: pristine,
  };
};

export const ADD_TO_DATA_ASSORTMENT_SUMMARY_BOTTOM = "ADD_TO_DATA_ASSORTMENT_SUMMARY_BOTTOM";
export const addToDataAssortmentSummaryBottom = (data) => {
  return {
    type: ADD_TO_DATA_ASSORTMENT_SUMMARY_BOTTOM,
    payload: data,
  };
};
