import React, { useEffect, useState, useMemo } from "react";
import useColorTheme from "../../../../../hooks/useColorTheme";
import { useDispatch, useSelector } from "react-redux";

import { Checkbox } from "antd";

//Components
import { Styles } from "./styles";
import CompareBox from "components/CompareBox";
import LoaderBox from "components/LoaderBox";
import SelectBox from "components/Selects/SelectBox";
import AssortmentSummaryChartAll from "./components/AssortmentSummaryChartAll";
import AssortmentSummaryChartSingle from "./components/AssortmentSummaryChartSingle";
import useFilters from "../../../../../hooks/useFilters";

//Actions
import {
  fetchAssortmentSummaryTop,
  fetchAssortmentSummaryTopCompare,
  setAssortmentSummaryTopFilter,
  resetAssortmentSummaryTopCompare,
} from "store/assortment/assortmentSummaryTop/actions";
//Utils
import { getTimePeriod } from "utils/getTimePeriod";
import { STATE_STATUSES } from "utils/statuses";
import { firstCharToUpperCase } from "utils/generalUtils";

//Constants
import { filtersAssortmentSummaryTop } from "constants/filters";

//Assets
import { Cancel } from "assets/icons/icons";

const AssortmentSummaryTop = ({
  selectedEntity,
  setSelectedEntity,
  setClickedArray,
  arrayOfDesiredBrand,
  setArrayOfDesiredBrand,
}) => {
  const { primaryColor } = useColorTheme();
  //dispatch
  const dispatch = useDispatch();
  //selectors
  const { status, filter, assortmentSummaryTop, comparativeValues } = useSelector(
    (state) => state.assortmentSummaryTop
  );
  const { mainLastFilter: lastFilter, statusFilters } = useFilters();
  //States for chart
  const [comparativeValue, setComparativeValue] = useState("");
  const [isResetCompare, setIsResetCompare] = useState(false);
  const [hoverEl, setHoverEl] = useState("");
  const [queryParams, setQueryParams] = useState({});
  const [queryParamsCompare, setQueryParamsCompare] = useState({});
  const [compareSelectedEntity, setCompareSelectedEntity] = useState({ children: [] });
  const entity = filter[2].value === "manufacture" ? "manufacturer" : filter[2].value;
  const currentDate = useMemo(() => {
    if (statusFilters !== STATE_STATUSES.READY) return "";
    return lastFilter && lastFilter.date.length ? getTimePeriod(lastFilter.date) : "";
  }, [statusFilters, lastFilter]);

  useEffect(() => {
    if (statusFilters !== STATE_STATUSES.READY) return;
    setQueryParams((prevState) => ({
      ...prevState,
      sourceType: lastFilter.sourceType,
      timePeriod: getTimePeriod(lastFilter.date),
      product: lastFilter.product,
      groupBy: filter[2].value,
    }));
    setSelectedEntity({});
    setComparativeValue("");
    dispatch(resetAssortmentSummaryTopCompare([]));
    setClickedArray([]);
    setArrayOfDesiredBrand([]);
  }, [lastFilter, filter[2].value, statusFilters]);

  useEffect(() => {
    if (comparativeValue?.length && statusFilters === STATE_STATUSES.READY) {
      setQueryParamsCompare((prevState) => ({
        ...prevState,
        sourceType: lastFilter.sourceType,
        timePeriod: comparativeValue,
        product: lastFilter.product,
        groupBy: filter[2].value,
      }));
    } else {
      dispatch(resetAssortmentSummaryTopCompare([]));
      setCompareSelectedEntity({ children: [] });
    }
  }, [comparativeValue, statusFilters]);

  useEffect(() => {
    if (Object.keys(queryParams).length && statusFilters === STATE_STATUSES.READY) {
      dispatch(fetchAssortmentSummaryTop(queryParams));
    }
  }, [queryParams, statusFilters, dispatch]);

  useEffect(() => {
    if (Object.keys(queryParamsCompare).length) {
      dispatch(fetchAssortmentSummaryTopCompare(queryParamsCompare));
    }
  }, [queryParamsCompare]);

  useEffect(() => {
    if (comparativeValue?.length) {
      setIsResetCompare(true);
      setComparativeValue("");
    } else {
      setIsResetCompare(false);
    }

    onEntityClick();
    setArrayOfDesiredBrand([]);
  }, [filter[2].value]);

  const setSelectValue = (values) => {
    const value = Object.keys(values);

    const updateData = filter.map((item) => {
      if (item.name === value[0]) {
        return {
          ...item,
          value: values[value[0]],
        };
      } else if (item.name === value[1]) {
        return {
          ...item,
          value: values[value[1]],
        };
      }

      return item;
    });
    dispatch(setAssortmentSummaryTopFilter(updateData));
  };

  const onEntityClick = () => {
    setSelectedEntity({});
    setCompareSelectedEntity({ children: [] });
    setClickedArray([]);

    const isShowAllBrands = filter[0].value;
    if (!isShowAllBrands) {
      setArrayOfDesiredBrand([]);
    }
  };

  useEffect(() => {
    if (Object.entries(selectedEntity).length && comparativeValues.result.length) {
      const { name } = selectedEntity.item;
      const matchedCompareEl = comparativeValues.result.length
        ? comparativeValues.result.find(({ item }) => item.name === name) || { children: [] }
        : { children: [] };
      setCompareSelectedEntity(matchedCompareEl);
    }
  }, [comparativeValues, selectedEntity]);

  const renderSelectedEntity = () => {
    const condition = Object.entries(selectedEntity).length;

    if (condition) {
      const { name } = selectedEntity.item;
      const content = name.length ? firstCharToUpperCase(name) : "";
      return (
        <div className="filter" onClick={() => onEntityClick()}>
          <span className="filter-title">{content}</span>
          <Cancel fill={"#fff"} />
        </div>
      );
    }
  };

  const renderUpperText = () => {
    const entity = filter[2].value;
    const {
      largestRetailer: {
        item: { name },
        group,
        products,
      },
    } = assortmentSummaryTop;
    return `${firstCharToUpperCase(name)} has the largest range of the selected retailers: ${products} product${
      products > 1 ? "s" : ""
    }, ${group} ${entity}${group > 1 ? "s" : ""}.`;
  };

  const onCheckboxClick = () => {
    setSelectValue({ showBrands: !filter[0].value });
    setArrayOfDesiredBrand([]);
  };

  const renderChart = () => {
    if (Object.entries(selectedEntity).length) {
      return (
        <AssortmentSummaryChartSingle
          data={selectedEntity}
          currentChart={filter}
          arrayOfDesiredBrand={arrayOfDesiredBrand}
          setArrayOfDesiredBrand={setArrayOfDesiredBrand}
          isCompare={!!comparativeValue && status === STATE_STATUSES.READY}
          compareData={compareSelectedEntity}
          comparativeDate={comparativeValue}
          currentDate={currentDate}
        />
      );
    } else {
      return (
        <AssortmentSummaryChartAll
          data={assortmentSummaryTop}
          currentChart={filter}
          setHoverEl={setHoverEl}
          hoverEl={hoverEl}
          arrayOfDesiredBrand={arrayOfDesiredBrand}
          setArrayOfDesiredBrand={setArrayOfDesiredBrand}
          isCompare={!!comparativeValue && status === STATE_STATUSES.READY}
          compareData={comparativeValues}
          setSelectedEntity={setSelectedEntity}
          setCompareSelectedEntity={setCompareSelectedEntity}
          comparativeDate={comparativeValue}
          currentDate={currentDate}
        />
      );
    }
  };

  return (
    <Styles color={primaryColor}>
      <div className="filters-box">
        <CompareBox
          isResetCompare={isResetCompare}
          setComparativeValue={setComparativeValue}
          disabled={status == STATE_STATUSES.PENDING}
        />
        {Object.entries(selectedEntity).length ? null : (
          <div className="wrapper-dscount">
            <Checkbox
              checked={filter[0].value}
              onChange={() => onCheckboxClick()}
              disabled={status !== STATE_STATUSES.READY}
            >
              Show {firstCharToUpperCase(entity)}s
            </Checkbox>
          </div>
        )}

        <SelectBox
          filter={filter[1].value}
          data={filtersAssortmentSummaryTop[1]}
          setSelectValue={setSelectValue}
          disabled={status !== STATE_STATUSES.READY}
        />
        <SelectBox
          filter={filter[2].value}
          data={filtersAssortmentSummaryTop[2]}
          setSelectValue={setSelectValue}
          disabled={status !== STATE_STATUSES.READY}
        />
        {renderSelectedEntity()}
      </div>
      <div className="wrapper-box-relative">
        {assortmentSummaryTop.success ? (
          <div className="chart-title-desc" style={{ maxWidth: "100%" }}>
            {renderUpperText()}
          </div>
        ) : null}
        {assortmentSummaryTop.success ? <div className="chart-wrapper-box"> {renderChart()}</div> : null}
        {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
      </div>
    </Styles>
  );
};

export default AssortmentSummaryTop;
