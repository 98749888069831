export const filtersForPromotions = [
  {
    name: "All",
    default: "All",
    options: [
      { lable: "All", value: "All" },
      { lable: "Price Cut", value: "Price Cut" },
      { lable: "Multibuy", value: "Multibuy" },
      { lable: "Other", value: "Other" },
    ],
  },
  {
    name: "discount",
    default: "discount",
    options: [{ lable: "% Discount", value: "percent" }],
  },
  {
    name: "byRetailer",
    default: "byRetailer",
    options: [
      { lable: "By Retailer", value: "byRetailer" },
      { lable: "By Brand", value: "byBrand" },
    ],
  },
];

export const retailersFilter = {
  name: "retailer",
  default: "tesco",
  options: [
    { lable: "Tesco", value: "tesco" },
    { lable: "Asda", value: "asda" },
    { lable: "Sainsburys", value: "sainsburys" },
    { lable: "Morrisons", value: "morrisons" },
    { lable: "Iceland", value: "iceland" },
    { lable: "Ocado", value: "ocado" },
    { lable: "Waitrose", value: "waitrose" },
    { lable: "Amazon", value: "amazon" },
    { lable: "Amazon Fresh", value: "amazon_fresh" },
  ],
};

export const filtersForRatingSummary = [
  {
    name: "byRetailer",
    default: "byRetailer",
    options: [
      { lable: "By Retailer", value: "byRetailer" },
      { lable: "By Brand", value: "byBrand" },
    ],
  },
];

export const filtersForRatingSummaryBottom = [
  {
    name: "rating",
    default: "All",
    placeholder: "All Ratings",
    options: [
      { lable: "All Ratings", value: "All" },
      { lable: "No Reviews", value: "0" },
      { lable: "1-2 Stars", value: "1-2" },
      { lable: "2-3 Stars", value: "2-3" },
      { lable: "3-4 Stars", value: "3-4" },
      { lable: "4-5 Stars", value: "4-5" },
    ],
  },
  {
    label: "Rev/pr",
    name: "countRange",
    title: "All Reviews",
    hint: "No. of Reviews per product listing",
    default: [0, 1000],
  },
];

export const filterForMediaSummary = [
  {
    name: "showAll",
    value: false,
  },
  {
    name: "banners",
    default: "all",
    placeholder: "All Banners",
    options: [
      { lable: "All Banners", value: "all" },
      { lable: "Search Terms", value: "searchTerms" },
      { lable: "Locations", value: "locations" },
    ],
  },
  {
    name: "byRetailer",
    default: "byRetailer",
    options: [
      { lable: "By Retailer", value: "byRetailer" },
      { lable: "By Brand", value: "byBrand" },
    ],
  },
];

export const trendAnalysisFilters = [
  {
    name: "byReviews",
    default: "byReviews",
    options: [
      { lable: "No. of Reviews", value: "count" },
      { lable: "Avg. Rating", value: "rating" },
    ],
  },
  {
    name: "byTotal",
    default: "byTotal",
    options: [
      { lable: "Total", value: "total" },
      { lable: "By Retailer", value: "retailer" },
      { lable: "By Brand", value: "brand" },
    ],
  },
];

export const filtersForAvailabilitySummary = [
  {
    name: "availability",
    default: "availability",
    value: "availability",
    options: [
      {
        value: "availability",
        lable: "% Availability",
      },
      {
        value: "count",
        lable: "Count",
      },
    ],
  },
  {
    name: "byRetailer",
    default: "retailer",
    value: "retailer",
    options: [
      { lable: "By Retailer", value: "retailer" },
      { lable: "By Brand", value: "brand" },
      { lable: "By Manufacturer", value: "manufacture" },
    ],
  },
];

export const filtersRetailerAvailability = [
  {
    name: "availability",
    default: "availability",
    value: "availability",
  },
  {
    name: "byProduct",
    default: "product",
    value: "product",
    options: [
      { lable: "By Product", value: "product" },
      { lable: "By Brand", value: "brand" },
      { lable: "By Manufacturer", value: "manufacture" },
    ],
  },
];

export const filtersAssortmentSummaryTop = [
  {
    name: "showBrands",
    value: false,
  },
  {
    name: "products",
    default: "count",
    value: "count",
    options: [
      { lable: "No. of Products", value: "count" },
      { lable: "% of Products", value: "percent" },
    ],
  },
  {
    name: "byBrand",
    default: "brand",
    value: "brand",
    options: [
      { lable: "By Brand", value: "brand" },
      { lable: "By Manufacturer", value: "manufacture" },
    ],
  },
];
