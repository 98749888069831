const url = process.env.REACT_APP_URL;

export const FETCH_MEDIA_DETAILS = "FETCH_MEDIA_DETAILS";
export const fetchMediaDetails = (params) => ({
  type: FETCH_MEDIA_DETAILS,
  request: {
    url: `${url}/v2/media/details`,
    method: "POST",
    data: params,
  },
});

export const SET_PRISTINE_MEDIA_DETAILS = "SET_PRISTINE_MEDIA_DETAILS";
export const setPristineMediaDetails = (pristine) => {
  return {
    type: SET_PRISTINE_MEDIA_DETAILS,
    payload: pristine,
  };
};
