import { success, error } from "redux-saga-requests";
import {
  FETCH_RANKING_SEARCH_ANALYSIS,
  FETCH_RANKING_SEARCH_ANALYSIS_TREND,
  SET_CURRENT_ITEM_TERM_SEARCH,
  SET_CURRENT_SHARE_SEARCH,
  SET_CURRENT_SHARE_SEARCH_SINGLE_DAY,
  FETCH_RANKING_SEARCH_ANALYSIS_PRODUCTS,
  FETCH_RANKING_SEARCH_ANALYSIS_PRODUCTS_POPUP,
  SET_SHOW_PRODUCT_SEARCH_POPUP,
  SET_PRISTINE_ANALYSIS_SEARCH,
  FETCH_SEARCH_SINGLE_PRODUCT,
  FETCH_SEARCH_SINGLE_PRODUCT_SUMMARY,
  SET_SEARCH_SINGLE_PRODUCT_PERIOD,
  REMOVE_SEARCH_PRODUCT,
  FETCH_SEARCH_SINGLE_PRODUCT_COMPARE,
  FETCH_SEARCH_SINGLE_PRODUCT_COMPARE_SUMMARY,
  SET_SEARCH_SINGLE_PRODUCT_COMPARE,
} from "./actions";
import { RESET_ERROR } from "store/error/actions";

import { STATE_STATUSES } from "utils/statuses";

const initialState = {
  rankingSearchAnalysis: [],
  currentItemTermSearch: {},
  currentShareSearch: {},
  currentShareSearchSingleDay: {},
  rankingSearchAnalysisTrend: {
    type: "",
    data: [],
  },
  chartStatus: STATE_STATUSES.INIT,
  rankingSearchAnalysisProducts: {},
  rankingSearchAnalysisProductsPopup: [],
  showProductPopup: false,
  productsStatus: STATE_STATUSES.INIT,
  searchSingleProduct: [],
  searchSingleProductCompare: [],
  popupStatus: STATE_STATUSES.INIT,
  status: STATE_STATUSES.INIT,
  isPristine: true,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RANKING_SEARCH_ANALYSIS: {
      return processReducer(state);
    }
    case success(FETCH_RANKING_SEARCH_ANALYSIS): {
      return {
        ...state,
        rankingSearchAnalysis: action.data,
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }
    case error(FETCH_RANKING_SEARCH_ANALYSIS): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case SET_CURRENT_ITEM_TERM_SEARCH: {
      return {
        ...state,
        currentItemTermSearch: action.payload,
      };
    }

    case SET_CURRENT_SHARE_SEARCH: {
      return {
        ...state,
        currentShareSearch: action.payload,
      };
    }

    case SET_CURRENT_SHARE_SEARCH_SINGLE_DAY: {
      return {
        ...state,
        currentShareSearchSingleDay: action.payload,
      };
    }

    case FETCH_RANKING_SEARCH_ANALYSIS_TREND: {
      return {
        ...state,
        chartStatus: STATE_STATUSES.PENDING,
        error: null,
      };
    }
    case success(FETCH_RANKING_SEARCH_ANALYSIS_TREND): {
      return {
        ...state,
        rankingSearchAnalysisTrend: action.data,
        chartStatus: STATE_STATUSES.READY,
      };
    }
    case error(FETCH_RANKING_SEARCH_ANALYSIS_TREND): {
      const error = action.error.response;
      return {
        ...state,
        chartStatus: STATE_STATUSES.ERROR,
        error,
      };
    }

    case FETCH_RANKING_SEARCH_ANALYSIS_PRODUCTS: {
      return {
        ...state,
        productsStatus: STATE_STATUSES.PENDING,
        error: null,
      };
    }
    case success(FETCH_RANKING_SEARCH_ANALYSIS_PRODUCTS): {
      return {
        ...state,
        rankingSearchAnalysisProducts: action.data,
        productsStatus: STATE_STATUSES.READY,
      };
    }
    case error(FETCH_RANKING_SEARCH_ANALYSIS_PRODUCTS): {
      const error = action.error.response;
      return {
        ...state,
        productsStatus: STATE_STATUSES.ERROR,
        error,
      };
    }

    case FETCH_RANKING_SEARCH_ANALYSIS_PRODUCTS_POPUP: {
      return {
        ...state,
        productsStatus: STATE_STATUSES.PENDING,
        popupStatus: STATE_STATUSES.PENDING,
        error: null,
      };
    }
    case success(FETCH_RANKING_SEARCH_ANALYSIS_PRODUCTS_POPUP): {
      return {
        ...state,
        rankingSearchAnalysisProductsPopup: action.data,
        showProductPopup: true,
        productsStatus: STATE_STATUSES.READY,
        popupStatus: STATE_STATUSES.READY,
      };
    }
    case error(FETCH_RANKING_SEARCH_ANALYSIS_PRODUCTS_POPUP): {
      const error = action.error.response;
      return {
        ...state,
        productsStatus: STATE_STATUSES.ERROR,
        error,
      };
    }

    case SET_SHOW_PRODUCT_SEARCH_POPUP: {
      return {
        ...state,
        showProductPopup: action.payload,
        searchSingleProduct: [],
      };
    }

    case SET_PRISTINE_ANALYSIS_SEARCH: {
      return {
        ...state,
        isPristine: true,
      };
    }

    case FETCH_SEARCH_SINGLE_PRODUCT: {
      return {
        ...state,
        popupStatus: STATE_STATUSES.PENDING,
        error: null,
      };
    }
    case success(FETCH_SEARCH_SINGLE_PRODUCT): {
      let products = action.meta.isSingle
        ? [...state.searchSingleProduct, ...action.data.products]
        : action.data.products;

      return {
        ...state,
        searchSingleProduct: products,
        popupStatus: STATE_STATUSES.READY,
      };
    }
    case error(FETCH_SEARCH_SINGLE_PRODUCT): {
      const error = action.error.response;
      return {
        ...state,
        popupStatus: STATE_STATUSES.ERROR,
        error,
      };
    }

    case FETCH_SEARCH_SINGLE_PRODUCT_SUMMARY: {
      return {
        ...state,
        popupStatus: STATE_STATUSES.PENDING,
        error: null,
      };
    }
    case success(FETCH_SEARCH_SINGLE_PRODUCT_SUMMARY): {
      return {
        ...state,
        popupStatus: STATE_STATUSES.READY,
      };
    }
    case error(FETCH_SEARCH_SINGLE_PRODUCT_SUMMARY): {
      const error = action.error.response;
      return {
        ...state,
        popupStatus: STATE_STATUSES.ERROR,
        error,
      };
    }

    case SET_SEARCH_SINGLE_PRODUCT_PERIOD: {
      return {
        ...state,
        searchSingleProduct: action.payload,
      };
    }

    case REMOVE_SEARCH_PRODUCT: {
      let products = action.payload ? state.searchSingleProduct.filter((item) => item.uid != action.payload) : [];

      return {
        ...state,
        searchSingleProduct: products,
      };
    }

    case FETCH_SEARCH_SINGLE_PRODUCT_COMPARE: {
      return {
        ...state,
        popupStatus: STATE_STATUSES.PENDING,
        error: null,
      };
    }
    case success(FETCH_SEARCH_SINGLE_PRODUCT_COMPARE): {
      return {
        ...state,
        searchSingleProductCompare: action.data.products,
        popupStatus: STATE_STATUSES.READY,
      };
    }
    case error(FETCH_SEARCH_SINGLE_PRODUCT_COMPARE): {
      const error = action.error.response;
      return {
        ...state,
        popupStatus: STATE_STATUSES.ERROR,
        error,
      };
    }

    case FETCH_SEARCH_SINGLE_PRODUCT_COMPARE_SUMMARY: {
      return {
        ...state,
        popupStatus: STATE_STATUSES.PENDING,
        error: null,
      };
    }
    case success(FETCH_SEARCH_SINGLE_PRODUCT_COMPARE_SUMMARY): {
      return {
        ...state,
        popupStatus: STATE_STATUSES.READY,
      };
    }
    case error(FETCH_SEARCH_SINGLE_PRODUCT_COMPARE_SUMMARY): {
      const error = action.error.response;
      return {
        ...state,
        popupStatus: STATE_STATUSES.ERROR,
        error,
      };
    }

    case SET_SEARCH_SINGLE_PRODUCT_COMPARE: {
      return {
        ...state,
        searchSingleProductCompare: action.payload,
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
