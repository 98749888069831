import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Pagination } from "antd";
import _ from "lodash";
import { fetchPriceComparsion, fetchComparePriceComparsion } from "store/pricing/priceComparison/actions";
import ComparisonChart from "./ComparisonChart";
import ComparisonTable from "./ComparisonTable";
import SelectBox from "components/Selects/SelectBox";
import LoaderBox from "components/LoaderBox";
import CompareBox from "components/CompareBox";
import { Styles } from "./Styles";
import { STATE_STATUSES } from "utils/statuses";
import { getTimePeriod } from "utils/getTimePeriod";
import { changeNameRetailer } from "utils/changeNameRetailer";
import useCache from "../../../../hooks/useCache";
import useFilters from "../../../../hooks/useFilters";

const PriceComparisonTab = (props) => {
  const {
    status,
    fetchPriceComparsion,
    fetchComparePriceComparsion,
    sourceType,
  } = props;
  const { mainLastFilter } = useFilters();
  const { retailersOrder, cachedPriceComparison: priceComparison, cachedPriceComparison: { products, retailers, total, retailer } } = useCache();

  const [comparativeValue, setComparativeValue] = useState("");
  const [isResetCompare, setIsResetCompare] = useState(false);
  const [compareData, setCompareData] = useState({
    products: [],
    retailers: [],
  });
  const [names, setNames] = useState([]);
  const [sortId, setSortId] = useState(null);
  const [sortDirection, setSortDirection] = useState(true);
  const [selectedRetailer, setSelectedRetailer] = useState("");

  const [filters, setFilters] = useState([
    {
      name: "retailer",
      value: null,
    },
    {
      name: "price",
      value: "shelfPrice",
    },
    {
      name: "page",
      value: 1,
    },
    {
      name: "perPage",
      value: 10,
    },
  ]);

  const [selects, setSelects] = useState([
    {
      name: "price",
      default: "shelfPrice",
      options: [
        { lable: "Shelf Price", value: "shelfPrice" },
        { lable: "Base Price", value: "basePrice" },
        { lable: "Promoted Price", value: "promotedPrice" },
      ],
    },
  ]);

  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    if (sourceType.length) {
      const capitalizeLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
      };
      const sourceTypeArray = mainLastFilter.sourceType.split("|").map((item) => Number(item));
      const retailersInitial = sourceType.filter((item) => sourceTypeArray.includes(item.id));

      const retailers = _.sortBy(retailersInitial, (item) => retailersOrder.indexOf(item.id)).map((item) => ({
        lable: capitalizeLetter(changeNameRetailer(item.name)),
        value: item.id,
      }));

      const retailersObj = {
        name: "retailer",
        default: retailers[0]?.value || '',
        options: retailers,
      };

      const filtersNew = filters.map((item) => {
        if (item.name === "retailer") {
          return {
            ...item,
            value: retailers[0]?.value || '',
          };
        }

        return item;
      });

      setFilters(filtersNew);

      const selectsUpdate = selects.filter((item) => item.name !== "retailer");

      setSelects([...selectsUpdate, retailersObj]);
    }
  }, [sourceType, mainLastFilter]);

  useEffect(() => {
    if (filters[0].value) {
      setQueryParams((prevState) => ({
        ...prevState,
        retailer: filters[0].value.toString(),
        sourceType: mainLastFilter.sourceType,
        timePeriod: getTimePeriod(mainLastFilter.date),
        product: mainLastFilter.product,
        productNameOrder: "asc",
        type: filters[1].value,
        page: filters[2].value,
        limit: 10,
      }));
    }
  }, [mainLastFilter, filters]);

  useEffect(() => {
    if (Object.keys(queryParams).length) {
      fetchPriceComparsion(queryParams);
    }
  }, [fetchPriceComparsion, queryParams]);

  useEffect(() => {
    if (products && products.length) {
      const names = products[0].values.map((item) => item.label);
      setNames(names);
    }
  }, [products]);

  useEffect(() => {
    if (filters[0].value === selectedRetailer) {
      setSelectedRetailer("");
    }
  }, [filters[0].value, setSelectedRetailer]);

  useEffect(() => {
    if (Boolean(comparativeValue?.length)) {
      setIsResetCompare(false);
      fetchComparePriceComparsion({
        retailer: filters[0].value.toString(),
        sourceType: mainLastFilter.sourceType,
        timePeriod: comparativeValue,
        product: mainLastFilter.product,
        type: filters[1].value,
      }).then((response) => {
        setCompareData(response.data);
      });
    } else {
      setIsResetCompare(false);

      setCompareData({
        products: [],
        retailers: [],
      });
    }
  }, [comparativeValue]);

  const setSelectValue = (values) => {
    const value = Object.keys(values);

    const updateData = filters.map((item) => {
      if (item.name === value[0]) {
        return {
          ...item,
          value: values[value],
        };
      }

      return item;
    });

    setFilters(updateData);
    setIsResetCompare(true);
  };

  const onChangePage = (page) => {
    const updateData = filters.map((item) => {
      if (item.name === "page") {
        return {
          ...item,
          value: page,
        };
      }

      return item;
    });

    setFilters(updateData);
    setIsResetCompare(true);
  };

  const handleSearch = (value) => {
    setQueryParams((prevState) => ({
      ...prevState,
      productName: value,
    }));
  };

  const handleSort = (id) => {
    let sourceTypeSort;
    if (sortId === id) {
      setSortDirection(!sortDirection);
      sourceTypeSort = `${id},${sortDirection ? "asc" : "desc"}`;
    } else {
      setSortDirection(!sortDirection);
      sourceTypeSort = `${id},${sortDirection ? "asc" : "desc"}`;
    }
    setSortId(id);
    setQueryParams((prevState) => ({
      ...prevState,
      sourceTypeSort,
    }));
  };

  return (
    <Styles>
      <div className="filters-box">
        <CompareBox
          setComparativeValue={setComparativeValue}
          isResetCompare={isResetCompare}
          disabled={status === STATE_STATUSES.PENDING}
        />
        {selects.map((item, index) => (
          <SelectBox
            key={index}
            data={item}
            disabled={status === STATE_STATUSES.PENDING}
            setSelectValue={setSelectValue}
          />
        ))}
      </div>

      <div className="wrapper-box-relative">
        {Object.entries(priceComparison).length !== 0 && retailers && retailers.length ? (
          <>
            <ComparisonChart
              currentData={retailers}
              currentRetailer={retailer}
              names={names}
              compareData={compareData.retailers}
              isCompare={Boolean(comparativeValue?.length)}
              status={status}
              setSelectedRetailer={setSelectedRetailer}
              selectedRetailer={selectedRetailer}
            />
            <ComparisonTable
              currentData={products}
              names={names}
              compareData={compareData.products}
              sortId={sortId}
              sortDirection={sortDirection}
              handleSearch={handleSearch}
              handleSort={handleSort}
              status={status}
              selectedRetailer={selectedRetailer}
            />
            {total > 0 ? (
              <Pagination
                className="pagination-controls"
                onChange={onChangePage}
                current={filters[2].value}
                pageSize={filters[3].value}
                total={total}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
              />
            ) : null}
          </>
        ) : (
          <>
            {status === STATE_STATUSES.READY ? (
              <div className="chart-no-data static">Looks like we don't have data for this request</div>
            ) : null}
          </>
        )}
        {status === STATE_STATUSES.PENDING && retailers && !retailers.length ? (
          <div style={{ height: "100px" }}>
            <LoaderBox />
          </div>
        ) : null}
      </div>
    </Styles>
  );
};

export default connect(
  (state) => ({
    status: state.priceComparison.status,
    sourceType: state.filters.filters.sourceType,
  }),
  { fetchPriceComparsion, fetchComparePriceComparsion }
)(PriceComparisonTab);
