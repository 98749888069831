import React, { useState, useEffect, useCallback } from "react";
import { Tooltip, Tabs } from "antd";
import QuickReport from "components/TabsExport/QuickReport";
import ScheduledReports from "components/TabsExport/ScheduledReports";
import SavedReports from "components/TabsExport/SavedReports";
import DownloadReports from "components/TabsExport/DownloadReports";

import about from "assets/images/about.svg";
import { TabWithIcon } from "assets/icons/icons";
import useColorTheme from "../../hooks/useColorTheme";

const TabsExport = ({ onClose }) => {
  const { primaryColor } = useColorTheme();
  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = useState("1");

  const handleChangeTab = (key) => {
    setActiveTab(key);
  };

  useEffect(() => {
    return () => setActiveTab("1");
  }, []);

  return (
    <Tabs activeKey={activeTab} onChange={handleChangeTab}>
      <TabPane
        key="1"
        tab={
          <TabWithIcon
            tabName={"Run Quick Report"}
            icon={about}
            tooltip={"Overall the prices moved up 20% based on the selected filters."}
          />
        }
      >
        <QuickReport onClose={onClose} />
      </TabPane>
      <TabPane
        key="2"
        tab={
          <TabWithIcon
            tabName={"Create Scheduled Report"}
            icon={about}
            tooltip={"Overall the prices moved up 20% based on the selected filters."}
          />
        }
      >
        <ScheduledReports onClose={onClose} />
      </TabPane>
      <TabPane
        key="3"
        tab={
          <TabWithIcon
            tabName={"Edit Scheduled Report"}
            icon={about}
            tooltip={"Overall the prices moved up 20% based on the selected filters."}
          />
        }
      >
        <SavedReports onClose={onClose} />
      </TabPane>
      <TabPane
        key="4"
        tab={
          <div className="tab-label-notification">
            <TabWithIcon tabName={"Downloaded Reports"} icon={about} />
          </div>
        }
      >
        <DownloadReports />
      </TabPane>
    </Tabs>
  );
};

export default TabsExport;
