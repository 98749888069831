import React, { useEffect, useState } from "react";
import useColorTheme from "../../../../hooks/useColorTheme";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox } from "antd";
//Components
import CompareBox from "components/CompareBox";
import LoaderBox from "components/LoaderBox";
import SelectBox from "components/Selects/SelectBox";
import AssortmentChangeChart from "./components/AssortmentChangeChart";
import AssortmentChangeChartDoublicate from "./components/AssortmentChangeChartDoublicate";
import { Styles } from "./styles";
//Constants
import { filtersAssortmentSummaryTop } from "constants/filters";
//Actions
import {
  fetchAssortmentChange,
  fetchAssortmentChangeCompare,
  resetAssortmentChangeCompare,
  setAssortmentChangeFilter,
} from "store/assortment/assortmentChange/actions";
//Utils
import { firstCharToUpperCase } from "utils/generalUtils";
import { getTimePeriod } from "utils/getTimePeriod";
import { STATE_STATUSES } from "utils/statuses";
import useFilters from "../../../../hooks/useFilters";

const AssortmentChange = () => {
  const { primaryColor } = useColorTheme();
  //dispatch
  const dispatch = useDispatch();
  //selectors
  const { status, filter, assortmentChange, comparativeValues } = useSelector((state) => state.assortmentChange);
  const { mainLastFilter: lastFilter, statusFilters } = useFilters();
  //States for chart
  const [comparativeValue, setComparativeValue] = useState("");
  const [isResetCompare, setIsResetCompare] = useState(false);
  const [hoverEl, setHoverEl] = useState("");
  const [queryParams, setQueryParams] = useState({});
  const [queryParamsCompare, setQueryParamsCompare] = useState({});
  const [arrayOfDesiredBrand, setArrayOfDesiredBrand] = useState([]);
  const entity = filter[2].value === "manufacture" ? "manufacturer" : filter[2].value;

  useEffect(() => {
    setQueryParams((prevState) => ({
      ...prevState,
      sourceType: lastFilter.sourceType,
      timePeriod: getTimePeriod(lastFilter.date),
      product: lastFilter.product,
      groupBy: filter[2].value,
    }));
  }, [lastFilter, filter[2].value]);

  useEffect(() => {
    if (comparativeValue?.length) {
      setQueryParamsCompare((prevState) => ({
        ...prevState,
        sourceType: lastFilter.sourceType,
        timePeriod: comparativeValue,
        product: lastFilter.product,
        groupBy: filter[2].value,
      }));
    } else {
      dispatch(resetAssortmentChangeCompare([]));
    }
  }, [comparativeValue]);

  useEffect(() => {
    if (Object.keys(queryParams).length && statusFilters === STATE_STATUSES.READY) {
      dispatch(fetchAssortmentChange(queryParams));
    }
  }, [queryParams, statusFilters, dispatch]);

  useEffect(() => {
    if (Object.keys(queryParamsCompare).length) {
      dispatch(fetchAssortmentChangeCompare(queryParamsCompare));
    }
  }, [queryParamsCompare]);

  useEffect(() => {
    if (comparativeValue?.length) {
      setIsResetCompare(true);
      setComparativeValue("");
    } else {
      setIsResetCompare(false);
    }

    setArrayOfDesiredBrand([]);
  }, [filter[2].value]);

  const setSelectValue = (values) => {
    const value = Object.keys(values);

    const updateData = filter.map((item) => {
      if (item.name === value[0]) {
        return {
          ...item,
          value: values[value[0]],
        };
      } else if (item.name === value[1]) {
        return {
          ...item,
          value: values[value[1]],
        };
      }

      return item;
    });
    dispatch(setAssortmentChangeFilter(updateData));
  };

  const renderUpperText = () => {
    const entity = filter[2].value;
    const {
      largestRetailer: {
        item: { name },
        group,
        products,
      },
    } = assortmentChange;
    return `${firstCharToUpperCase(name)} has the largest range of the selected retailers: ${products} product${
      products > 1 ? "s" : ""
    }, ${group} ${entity}${group > 1 ? "s" : ""}.`;
  };

  const onCheckboxClick = () => {
    setSelectValue({ showBrands: !filter[0].value });
    setArrayOfDesiredBrand([]);
  };

  const renderChart = () => {
    // AssortmentChangeChartDoublicate same as AssortmentChangeChart chart only for one reason
    // When click on showAllBrands button stacked chart shows only on hover
    // For that reason added new component
    if (!filter[0].value) {
      return (
        <AssortmentChangeChart
          data={assortmentChange}
          currentChart={filter}
          setHoverEl={setHoverEl}
          hoverEl={hoverEl}
          arrayOfDesiredBrand={arrayOfDesiredBrand}
          setArrayOfDesiredBrand={setArrayOfDesiredBrand}
          isCompare={!!comparativeValue && status === STATE_STATUSES.READY}
          compareData={comparativeValues}
        />
      );
    } else {
      return (
        <AssortmentChangeChartDoublicate
          data={assortmentChange}
          currentChart={filter}
          setHoverEl={setHoverEl}
          hoverEl={hoverEl}
          arrayOfDesiredBrand={arrayOfDesiredBrand}
          setArrayOfDesiredBrand={setArrayOfDesiredBrand}
          isCompare={!!comparativeValue && status === STATE_STATUSES.READY}
          compareData={comparativeValues}
        />
      );
    }
  };

  return (
    <Styles color={primaryColor}>
      <div className="filters-box">
        <CompareBox
          isResetCompare={isResetCompare}
          setComparativeValue={setComparativeValue}
          disabled={status === STATE_STATUSES.PENDING}
        />

        <div className="wrapper-dscount">
          <Checkbox
            checked={filter[0].value}
            onChange={() => onCheckboxClick()}
            disabled={status !== STATE_STATUSES.READY}
          >
            Show {firstCharToUpperCase(entity)}
          </Checkbox>
        </div>

        <SelectBox
          filter={filter[1].value}
          data={filtersAssortmentSummaryTop[1]}
          setSelectValue={setSelectValue}
          disabled={status !== STATE_STATUSES.READY}
        />
        <SelectBox
          filter={filter[2].value}
          data={filtersAssortmentSummaryTop[2]}
          setSelectValue={setSelectValue}
          disabled={status !== STATE_STATUSES.READY}
        />
      </div>
      <div className="wrapper-box-relative">
        {assortmentChange.success ? (
          <div className="chart-title-desc" style={{ maxWidth: "100%" }}>
            {renderUpperText()}
          </div>
        ) : null}
        {assortmentChange.success ? <div className="chart-wrapper-box"> {renderChart()}</div> : null}
        {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
      </div>
    </Styles>
  );
};

export default AssortmentChange;
