import styled from "styled-components";

export const Styles = styled.div.attrs((props) => props)`
  display: flex;
  flex-direction: row;
  .compare-retailers-box {
    margin-right: 16px;
  }
  .get-products-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    width: 288px;
    height: 44px;
    border-radius: 4px;
    background-color: ${(props) => props.color};
    font-family: Gilroy-ExtraBold;
    font-size: 14px;
    font-weight: 800;
    color: #fff;
    cursor: pointer;
    :disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
`;
