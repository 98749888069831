import { success, error } from "redux-saga-requests";
import {
  FETCH_CURRENT_CONTENT_TABLE,
  FETCH_CURRENT_CONTENT_POPUP,
  SET_PRISTINE_CURRENT_CONTENT_TABLE,
} from "./actions";
import { STATE_STATUSES } from "utils/statuses";
import { RESET_ERROR } from "store/error/actions";

const initialState = {
  currentContentTable: {
    count: 1,
    products: [],
  },
  product: { product: {}, coreProduct: {} },
  status: STATE_STATUSES.INIT,
  isPristine: true,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CURRENT_CONTENT_TABLE:
    case FETCH_CURRENT_CONTENT_POPUP: {
      return processReducer(state);
    }

    case success(FETCH_CURRENT_CONTENT_TABLE): {
      return {
        ...state,
        currentContentTable: action.data,
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }
    case success(FETCH_CURRENT_CONTENT_POPUP): {
      return {
        ...state,
        product: action.data,
        status: STATE_STATUSES.READY,
      };
    }

    case error(FETCH_CURRENT_CONTENT_TABLE):
    case error(FETCH_CURRENT_CONTENT_POPUP): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case SET_PRISTINE_CURRENT_CONTENT_TABLE: {
      return {
        ...state,
        isPristine: action.payload,
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
