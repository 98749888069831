const url = process.env.REACT_APP_URL;

export const FETCH_MEDIA_SUMMARY_TOP = "FETCH_MEDIA_SUMMARY_TOP";
export const fetchMediaSummaryTop = (params) => ({
  type: FETCH_MEDIA_SUMMARY_TOP,
  request: {
    url: `${url}/v2/media/summary`,
    method: "POST",
    data: params,
  },
});

export const SET_MEDIA_SUMMARY_TOP_FILTER = "SET_MEDIA_SUMMARY_TOP_FILTER";
export const setFilterMediaSummaryTop = (filter) => {
  localStorage.setItem(SET_MEDIA_SUMMARY_TOP_FILTER, JSON.stringify(filter));
  return {
    type: SET_MEDIA_SUMMARY_TOP_FILTER,
    payload: filter,
  };
};

export const SET_PRISTINE_MEDIA_SUMMARY_TOP = "SET_PRISTINE_MEDIA_SUMMARY_TOP";
export const setPristineMediaSummaryTop = (pristine) => {
  return {
    type: SET_PRISTINE_MEDIA_SUMMARY_TOP,
    payload: pristine,
  };
};
