import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchDownloadReportsQueue, ackReports, clearDownloadedFiles } from "store/exports/actions";
import { STATUS_REPORT } from "utils/statuses";
import { saveAsZip } from "utils/saveAsZip";

export default function useReport() {
    const { downloadReports, files } = useSelector((state) => state.exports);
    const dispatch = useDispatch();
    const unReadList = useMemo(() => downloadReports ? downloadReports.filter(r => r.status === STATUS_REPORT.DOWNLOADED).map(r => r.key) : [], [downloadReports]);
    const numUnread = useMemo(() => unReadList.length, [unReadList]);
    const timeRef = useRef(null);
    const ackTimeRef = useRef(null);
    const [ackList, setAckList] = useState([]);
    
    const clearAckTimer = () => {
        if (ackTimeRef.current) clearTimeout(ackTimeRef.current);
        console.log('!!Timeout cleared=', ackTimeRef.current);
        ackTimeRef.current = null;
    }
    
    const acknowledgeAll = useCallback(() => {
        if (numUnread === 0) return;
        clearAckTimer();
        dispatch(ackReports({ keys: unReadList }));
    }, [dispatch, unReadList, numUnread]);

    useEffect(() => {
        dispatch(fetchDownloadReportsQueue());
        timeRef.current = setInterval(() => {
            dispatch(fetchDownloadReportsQueue());
        }, 30000);
        return () => {
            if (timeRef.current) clearInterval(timeRef.current);
            timeRef.current = null;
        }
    }, [dispatch]);

    useEffect(() => {
        if (numUnread === 0) {
            setAckList([]);
            return;
        }
        setAckList(preList => {
                const newAckList = [...new Set([...preList, ...unReadList])];
                if (newAckList.length !== preList.length) {
                    if (ackTimeRef.current) clearTimeout(ackTimeRef.current);
                        ackTimeRef.current = setTimeout(() => {
                    console.log('!!Ack All')
                    acknowledgeAll();
                }, 120000);
                console.log('!!Start timer=', ackTimeRef.current);
            }
            return newAckList;
        });
    }, [numUnread, acknowledgeAll, unReadList]);

    useEffect(() => {
        if (!files || files.length === 0) return;
        console.log('!!save files:', files)
        saveAsZip(files);
        dispatch(clearDownloadedFiles());
    }, [dispatch, files])

    return { acknowledgeAll, numUnread };
}
