import { success, error } from "redux-saga-requests";
import {
  FETCH_CONTENT_TREND,
  FETCH_COMPARE_CONTENT_TREND,
  SET_COMPARE_DATA,
  SET_PRISTINE_CONTENT_TREND,
} from "./actions";
import { STATE_STATUSES } from "utils/statuses";
import { RESET_ERROR } from "store/error/actions";

const initialState = {
  contentTrend: {
    topMovers: { topMoversShelf: [] },
    currentData: { dates: [], data: { name: "", values: [] } },
    overalChange: { shelfPricePercent: { direction: false, percent: 100 } },
    compareData: {},
  },
  status: STATE_STATUSES.INIT,
  isPristine: false,
  errorL: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONTENT_TREND:
    case FETCH_COMPARE_CONTENT_TREND: {
      return processReducer(state);
    }

    case success(FETCH_CONTENT_TREND): {
      const mapedValues = action.data.chart.data.values.map(({ date, score }) => {
        return {
          date,
          shelfPrices: score,
        };
      });

      return {
        ...state,
        contentTrend: {
          ...state.contentTrend,
          topMovers: { topMoversShelf: action.data.topMovers },
          currentData: {
            dates: action.data.chart.dates,
            data: {
              ...action.data.chart.data,
              values: mapedValues,
            },
          },
          overalChange: {
            shelfPricePercent: {
              direction: action.data.overallChange.score.direction,
              percent: +action.data.overallChange.score.percent,
            },
          },
        },
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }
    case success(FETCH_COMPARE_CONTENT_TREND): {
      const mapedValues = action.data.chart.data.values.map(({ date, score }) => {
        return {
          date,
          shelfPrices: score,
        };
      });

      return {
        ...state,
        contentTrend: {
          ...state.contentTrend,

          compareData: {
            byTotal: {
              priceChart: {
                dates: action.data.chart.dates,
                data: {
                  ...action.data.chart.data,
                  values: mapedValues,
                },
              },
            },
          },
        },

        status: STATE_STATUSES.READY,
      };
    }

    case SET_COMPARE_DATA: {
      return {
        ...state,
        contentTrend: {
          ...state.contentTrend,
          compareData: {},
        },
      };
    }

    case error(FETCH_CONTENT_TREND):
    case error(FETCH_COMPARE_CONTENT_TREND): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case SET_PRISTINE_CONTENT_TREND: {
      return {
        ...state,
        isPristine: action.payload,
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
