import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Menu, Empty } from "antd";

import { fetchPathPurchase, removePurchaseAllProducts } from "store/navigation/navigationPathPurchase/actions";

import { STATE_STATUSES } from "utils/statuses";
import { Cancel } from "assets/icons/icons";
import useFilters from "../../hooks/useFilters";

import LoaderBox from "components/LoaderBox";
import ProductBox from "tabs/NavigationTabs/components/ProductBox";

import search from "assets/images/search.svg";

const PathPurchase = () => {
  const dispatch = useDispatch();

  const { product } = useSelector((state) => state.filters.filters);
  const { lastFilter } = useFilters();
  const { product: products } = lastFilter;
  const { pathPurchase } = useSelector((state) => state.navigationPathPurchase);
  const { status } = useSelector((state) => state.navigationPathPurchase);

  const [productId, setProductId] = useState(null);
  const [searchProductValue, setSearchProductValue] = useState("");
  const [showDropdown, setShowDropdown] = useState("");

  const [averageRank, setAverageRank] = useState(0);
  const [averageBuy, setAverageBuy] = useState(0);

  useEffect(() => {
    if (pathPurchase.length) {
      const getAmount = (param) => pathPurchase.reduce((prev, cur) => prev + cur[param], 0) / pathPurchase.length;
      setAverageRank(getAmount("averageRank"));
      setAverageBuy(getAmount("averageClicksToPurchase"));
    }
  }, [pathPurchase]);

  const handleGetProduct = () => {
    if (productId && searchProductValue?.length) {
      dispatch(fetchPathPurchase({ product: products }, productId)).then(() => setSearchProductValue(""));
    }
  };

  const onSearch = (data) =>
    data.filter((item) => {
      if (searchProductValue) {
        return item.title.toLowerCase().includes(searchProductValue.toLowerCase());
      }
      return item;
    });

  const handleSelectProduct = (item) => {
    setSearchProductValue(item.title);
    setProductId(item.id);
    setShowDropdown(false);
  };

  const handleRemoveProducts = () => dispatch(removePurchaseAllProducts());

  const productList = (
    <Menu>
      {onSearch(product).length ? (
        onSearch(product).map((item, index) => {
          return (
            <Menu.Item key={index} onClick={() => handleSelectProduct(item)}>
              <div className="product-item">
                <span>{item.title}</span>
              </div>
            </Menu.Item>
          );
        })
      ) : (
        <>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </>
      )}
    </Menu>
  );

  return (
    <div>
      <div className="filters-box">
        {pathPurchase.length ? (
          <div className="current-retailer" onClick={handleRemoveProducts}>
            <span>{pathPurchase.length} Products</span>
            <Cancel fill="#fff" />
          </div>
        ) : null}
      </div>
      <div className="wrapper-box-relative">
        <div className="path-purchase-header">
          <div>
            <div className="title-hint">Choose a product to see its locations across selected retailers.</div>
            <div className="search-product-box">
              <Dropdown
                overlay={productList}
                placement="bottomLeft"
                trigger="click"
                visible={showDropdown && searchProductValue?.length}
                getPopupContainer={(trigger) => trigger.parentElement}
              >
                <div className="search-location">
                  <img src={search} alt="search" />
                  <input
                    placeholder="Choose Product"
                    value={searchProductValue}
                    onFocus={() => setShowDropdown(true)}
                    onChange={(e) => setSearchProductValue(e.target.value)}
                  />
                </div>
              </Dropdown>
              <div className="get-prod-btn" onClick={handleGetProduct}>
                Get Product Insight
              </div>
            </div>
          </div>

          {pathPurchase.length ? (
            <div className="average-info-wrapper">
              <div className="average-info-item">
                <span>Average Rank</span>
                <span>{parseInt(averageRank)}</span>
              </div>
              <div className="average-info-item">
                <span>Average Click to Buy</span>
                <span>{parseInt(averageBuy)}</span>
              </div>
            </div>
          ) : null}
        </div>

        <div>{pathPurchase.length ? pathPurchase.map((item, index) => <ProductBox key={index} data={item} />) : null}</div>
        {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
      </div>
    </div>
  );
};

export default PathPurchase;
