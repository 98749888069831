import React from "react";
import Loader from "react-loader-spinner";
import { BUTTONS_TYPE } from "constants/buttons-type.constants";
import "./styles.scss";
import { Link } from "react-router-dom";
import { hexToRgbA } from "utils/hexToRgbA";

const StyledButtonLink = ({ title, onClick, style, type, color, disabled, isLoading, to = "", children }) => {
  const hoverColor = hexToRgbA(color, type === BUTTONS_TYPE.SOLID ? "0.65" : "0.15");

  return (
    <button
      className={`custom-button ${type}`}
      onClick={disabled ? () => {} : onClick}
      disabled={disabled || isLoading}
      style={{ "--hoverColor": hoverColor, "--mainColor": color }}
    >
      {/* <Link to={to}>{children}</Link> */}
      {children}
      {isLoading ? <Loader className="spinner" type="Oval" color="white" height={16} width={16} /> : title}
    </button>
  );
};

export default StyledButtonLink;
