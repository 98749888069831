import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { fetchChange, fetchCompareChange, clearCompareChange } from "store/pricing/priceChange/actions";
import ChangeChart from "./ChangeChart";
import TopMovers from "components/TopMovers";
import SelectBox from "components/Selects/SelectBox";
import CompareBox from "components/CompareBox";
import LoaderBox from "components/LoaderBox";
import useCache from "../../../../hooks/useCache";
import useFilters from "../../../../hooks/useFilters";

import { STATE_STATUSES } from "utils/statuses";
import { getTimePeriod } from "utils/getTimePeriod";

const selects = [
  {
    name: "price",
    default: "shelfPrices",
    options: [
      { lable: "Shelf Price", value: "shelfPrices" },
      { lable: "Base Price", value: "basePrices" },
      { lable: "Promoted Price", value: "promotedPrices" },
    ],
  },
  {
    name: "type",
    default: "byRetailer",
    options: [
      { lable: "By Retailer", value: "byRetailer" },
      { lable: "By Brand", value: "byBrand" },
      { lable: "By Category", value: "byCategory" },
      { lable: "By Manufacturer", value: "byManufacturer" },
      { lable: "By Custom Group", value: "byProductGroup" },
    ],
  },
];

const ChangeTab = (props) => {
  const { status, fetchChange, fetchCompareChange, clearCompareChange } = props;
  const { cachedPriceChangeCurrentData: currentData, cachedPriceChangeCompareData: compareData } = useCache();
  const {
    setRefreshStatus,
    refreshStatus,
    statusFilters,
    lastFilter,
  } = useFilters();
  const [tabParams, setTabParams] = useState([
    {
      name: "price",
      value: "shelfPrices",
    },
    {
      name: "type",
      value: "byRetailer",
    },
  ]);

  const [manyData, setManyData] = useState(false);

  const [names, setNames] = useState({
    byRetailer: {
      list: [],
      pristine: true,
    },
    byBrand: {
      list: [],
      pristine: true,
    },
    byCategory: {
      list: [],
      pristine: true,
    },
    byManufacturer: {
      list: [],
      pristine: true,
    },
    byProductGroup: {
      list: [],
      pristine: true,
    },
  });

  const [comparativeValue, setComparativeValue] = useState("");

  const queryParams = {
    sourceType: lastFilter.sourceType,
    category: lastFilter.category,
    manufacture: lastFilter.manufacture,
    productBrand: lastFilter.productBrand,
    productGroup: lastFilter.productGroup,
    product: lastFilter.product,
  };
  const fetchRef = useRef();
  useEffect(() => {
    if (statusFilters !== STATE_STATUSES.READY) {
      fetchRef.current = false;
    } else if (!fetchRef.current || refreshStatus === STATE_STATUSES.PENDING) {
      if (Object.keys(queryParams).length) {
        fetchRef.current = true;
        setRefreshStatus(STATE_STATUSES.READY);
        fetchChange({
          ...queryParams,
          timePeriod: getTimePeriod(lastFilter.date),
        });
      }
    }
  }, [fetchChange, lastFilter, queryParams, statusFilters, refreshStatus, setRefreshStatus]);

  useEffect(() => {
    if (comparativeValue?.length) {
      fetchCompareChange({
        ...queryParams,
        timePeriod: comparativeValue,
      });
    } else {
      clearCompareChange();
    }
  }, [fetchChange, comparativeValue]);

  const setSelectValue = (values) => {
    const value = Object.keys(values);

    const updateData = tabParams.map((item) => {
      if (item.name === value[0]) {
        return {
          ...item,
          value: values[value],
        };
      }

      return item;
    });

    setTabParams(updateData);
  };

  const handleSetNames = (name, type) => {
    if (manyData) {
      setManyData(false);
    }

    const itemName = names[type];
    let updateName = {};

    if (itemName.list.includes(name)) {
      updateName = {
        ...itemName,
        list: itemName.list.filter((el) => el !== name),
        pristine: false,
      };
    } else {
      updateName = {
        ...itemName,
        list: [...itemName.list, name],
        pristine: false,
      };
    }

    setNames((prevState) => ({ ...prevState, [type]: updateName }));
  };

  const autoSetNames = (type, list) => {
    setNames((prevState) => ({ ...prevState, [type]: { list, pristine: true } }));
  };

  return (
    <>
      <div className="filters-box">
        <CompareBox setComparativeValue={setComparativeValue} disabled={status === STATE_STATUSES.PENDING} />
        {selects.map((item, index) => (
          <SelectBox
            key={index}
            data={item}
            disabled={status === STATE_STATUSES.PENDING}
            setSelectValue={setSelectValue}
          />
        ))}
      </div>
      <div className="wrapper-box-relative">
        {currentData[tabParams[1].value].priceChart.data.length ? (
          <div className="chart-wrapper-box">
            <ChangeChart
              priceParam={tabParams[0].value}
              currentData={currentData[tabParams[1].value].priceChart.data}
              compareData={compareData[tabParams[1].value].priceChart.data}
              isCompare={Boolean(comparativeValue?.length)}
              type={tabParams[1].value}
              names={names}
              autoSetNames={autoSetNames}
              handleSetNames={handleSetNames}
              manyData={manyData}
              setManyData={setManyData}
              status={status}
            />
            {!comparativeValue?.length ? (
              <TopMovers
                currentChart={tabParams[1].value}
                priceParam={tabParams[0].value}
                topMovers={currentData[tabParams[1].value].topMovers}
              />
            ) : null}
          </div>
        ) : null}
        {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    status: state.priceChange.status,
  }),
  { fetchChange, fetchCompareChange, clearCompareChange }
)(ChangeTab);
