import React, { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import useColorTheme from "../../../../../../hooks/useColorTheme";

//Components
import RenderNoData from "components/RenderNoData";

const TrendAnalysisTotalChart = (props) => {
  const { primaryColor, primaryColorRgb } = useColorTheme();

  //Props
  const { data, filter } = props;

  //Refs
  const inputRef = useRef(null);
  //States
  const [dates, setDates] = useState([]);
  const [pricings, setPricings] = useState([]);
  const [emptyData, setEmptyData] = useState(false);
  //Constants
  const byReviews = filter[0].value === "count";
  const title = byReviews ? "No. of Reviews" : "Avg. Rating";
  //form data for chart and xAxis
  useEffect(() => {
    const currentData = data.data.total.chart[filter[0].value];
    if (currentData.length) {
      const pricings = [
        {
          data: currentData.map((el) => el[1].data),
        },
      ];

      const datesMoment = data.data.days.map((item) =>
        moment(item).format("DD MMM YYYY")
      );

      setDates(datesMoment);
      setPricings(pricings);
    }
  }, [data, filter]);
  //check for empty data
  useEffect(() => {
    if (pricings.length) {
      pricings.forEach(({ data }) => {
        if (data.length) {
          setEmptyData(false);
        } else {
          setEmptyData(true);
        }
      });
    } else {
      setEmptyData(true);
    }
  }, [pricings]);

  const options = {
    title: "",
    chart: {
      type: "areaspline",
      reflow: true,
      height: 300,
      style: {
        fontFamily: "Gilroy-Medium",
      },
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        color: primaryColor,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
      areaspline: {
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, `${primaryColorRgb(0.2)}`],
            [1, `${primaryColorRgb(0)}`],
          ],
        },
        marker: {
          enabled: false,
        },
        lineWidth: 3,
        threshold: null,
      },
    },
    xAxis: {
      crosshair: {
        width: 1,
        color: "gray",
        dashStyle: "solid",
      },
      type: "datetime",
      labels: {
        step: parseInt(dates.length / 3),
      },
      categories: dates,
    },
    yAxis: {
      title: {
        offset: 15,
        x: 25,
        text: title,
      },
      offset: 30,
      startOnTick: byReviews ? true : false,
      endOnTick: byReviews ? true : false,
      min: byReviews ? null : 0,
      softMax: byReviews ? null : 5,
      labels: {
        formatter: function () {
          const value = this.value;
          if (value >= 1000) {
            return (value / 1000) + 'k';
          } else {
            return value;
          }
        }
      }
    },
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: null,
      borderWidth: 0,
      formatter: function () {
        return renderTooltip(this);
      },
    },
    legend: {
      enabled: false,
    },
    series: pricings,
  };

  const renderTooltip = (thisObj) => {
    return `<div class="wrapper-total">
        <div class="title">${title}</div>
        <div class="wrapper">
          <div class="box">
            <div class="total-data">
              ${moment(thisObj.points[0].key).format("DD MMM YY")}
            </div>
            <div>${thisObj.points[0].y}</div>
          </div>
        </div>
      </div>`;
  };

  return (
    <div
      className="chart-price-wrapper"
      style={{ width: "calc(100% - 412px)" }}
    >
      <div className="chart-title-desc">
        Select a product to see its reviews.
      </div>
      <div className="wrapper-chart-box">
        {emptyData ? (
          <RenderNoData style={{ height: 335 }} />
        ) : (
          <HighchartsReact
            ref={inputRef}
            highcharts={Highcharts}
            options={options}
          />
        )}
      </div>
    </div>
  );
};

export default TrendAnalysisTotalChart;
