import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { connect } from "react-redux";
import { Button, DatePicker } from "antd";
import { setTitleDateStore } from "store/filters/actions";
import moment from "moment";
import { getTimePeriod } from "utils/getTimePeriod";
import { STATE_STATUSES } from "utils/statuses";
import useFilters from "../../hooks/useFilters";

const DateFilterBox = (props) => {
  const { RangePicker } = DatePicker;
  const allTime = "All Time";

  const {
    data,
    setTitleDateStore,
    isSelectAllTimePeriods,
    isSelectAllTimePeriodsHandler,
    settingsYourProfile,
    paramName,
    handleSetActiveFilter,
    isResetDate,
    setIsResetDate,
  } = props;

  const {
    company: { filtersStartDate, createdAt },
  } = settingsYourProfile;
  const {
    fetchTempFilters,
    setEqualValues,
    mainLastFilter,
    universalFiltersStatus,
    statusTempFilters,
    validateFilters,
    tempLastFilter,
    statusMainFilters,
  } = useFilters();

  const lastFilter = useMemo(
    () => (tempLastFilter || statusTempFilters === STATE_STATUSES.READY ? tempLastFilter : mainLastFilter),
    [mainLastFilter, tempLastFilter, statusTempFilters]
  );
  const statusFilters = useMemo(
    () => (statusTempFilters === STATE_STATUSES.INIT ? statusMainFilters : statusTempFilters),
    [statusMainFilters, statusTempFilters]
  );
  const startDate = filtersStartDate ? moment(filtersStartDate) : moment(createdAt);

  const wrapperRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [customSelectedDate, setCustomSelectedDate] = useState(mainLastFilter[paramName]);

  const [titleDate, setTitleDate] = useState("Date");
  const [dateInterval, setDateInterval] = useState([]);
  const [selectedDate, setSelectedDate] = useState(mainLastFilter[paramName]);

  const isChange = useMemo(() => {
    if (Number(selectedDate)) {
      if (Number(selectedDate) !== Number(lastFilter.date)) return true;
    } else {
      if (customSelectedDate !== lastFilter.date) {
        return true;
      }
    }
    return false;
  }, [customSelectedDate, lastFilter.date, selectedDate]);

  useEffect(() => {
    if (Number(selectedDate)) {
      let titleDate;
      Object.keys(data).forEach((item) => {
        const title = data[item].find((item) => item.id === Number(selectedDate));
        if (title) {
          titleDate = title.name;
        }
      });

      setTitleDate(titleDate);
      setTitleDateStore(`${titleDate}`);
    } else if (customSelectedDate) {
      const data = customSelectedDate.split("|");
      if ((data[0] = "custom")) {
        setTitleDate(`${moment(data[1]).format("DD/MM/YYYY")} - ${moment(data[2]).format("DD/MM/YYYY")}`);
        setTitleDateStore(`${moment(data[1]).format("DD/MM/YYYY")} - ${moment(data[2]).format("DD/MM/YYYY")}`);
      }
    }
  }, [data, selectedDate, lastFilter, setTitleDateStore, customSelectedDate, mainLastFilter, tempLastFilter]);

  useEffect(() => {
    if (!Number(selectedDate)) {
      let dates = selectedDate.length ? selectedDate : customSelectedDate;
      const data = dates.split("|");
      if ((data[0] = "custom")) {
        setDateInterval([moment(data[1]), moment(data[2])]);
      }
    }
  }, [selectedDate, customSelectedDate]);

  const toggleopen = () => {
    if (statusFilters === STATE_STATUSES.READY) setIsOpen(!isOpen);
  };

  const handleSelectDate = (id) => {
    setSelectedDate(`${id}`);
    handleSetActiveFilter(paramName, id);
    setCustomSelectedDate("");
    setDateInterval([]);
  };

  const changeCustomDate = (date, dateString) => {
    if (date) {
      let createdStart = moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD");
      if (dateString[0] === dateString[1]) {
        createdStart = moment(dateString[0], "DD-MM-YYYY").subtract(1, "d").format("YYYY-MM-DD");
      }

      const createdEnd = moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD");

      const value = `custom|${createdStart}|${createdEnd}`;

      setSelectedDate("");
      handleSetActiveFilter(paramName, value);
      setCustomSelectedDate(value);
    } else {
      setDateInterval([]);
    }
  };


  const resetDate = useCallback(() => {
    setIsResetDate(true);
    dateInterval && setDateInterval([]);
    setSelectedDate(mainLastFilter[paramName]);
    setCustomSelectedDate(mainLastFilter[paramName]);
  }, [dateInterval, mainLastFilter, paramName, setIsResetDate]);

  useEffect(() => {
    if (!isResetDate && statusTempFilters === STATE_STATUSES.INIT) {
      resetDate();
    }
  }, [statusTempFilters, resetDate, isResetDate])

  const equalsCheck = (a, b) => a.length === b.length && a.every((aItem) => b.some((bItem) => aItem === bItem));
  function useOutsideAlerter(ref, customSelectedDate, selectedDate, isChange, isOpen, setIsOpen) {
    useEffect(() => {
      if (isOpen) {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setIsOpen(false);
            document.removeEventListener("mousedown", handleClickOutside);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
    }, [ref, customSelectedDate, selectedDate, isChange, isOpen, setIsOpen]);
  }
  useOutsideAlerter(wrapperRef, customSelectedDate, selectedDate, isChange, isOpen, setIsOpen);

  const applyFilters = useCallback(() => {
    let dateParam;

    if (customSelectedDate.length) {
      dateParam = customSelectedDate;
    } else {
      dateParam = selectedDate.toString();
    }

    const { productBrandsWithChilds, ...coreItemsLastFilter } = lastFilter;
    const updateFilters = { ...coreItemsLastFilter, ...{ date: dateParam, timePeriod: getTimePeriod(dateParam) } };

    isSelectAllTimePeriodsHandler && isSelectAllTimePeriodsHandler();
    if (isChange) {
      fetchTempFilters(updateFilters);
      const filtersDifferent =
        mainLastFilter && { ...updateFilters } &&
        !equalsCheck(mainLastFilter[paramName].split("|"), updateFilters[paramName].split("|"))
          ? true
          : false;
      setEqualValues({ date: filtersDifferent });
      validateFilters(updateFilters);
    }
    setIsOpen(false)
  }, [selectedDate, customSelectedDate, isChange, fetchTempFilters, isSelectAllTimePeriodsHandler, lastFilter, mainLastFilter, paramName, setEqualValues, validateFilters]);

  useEffect(() => {
    if (lastFilter.date) {
      setSelectedDate(lastFilter.date);

      if (!Number(lastFilter.date)) {
        setCustomSelectedDate(lastFilter.date);
      }
    }
  }, [lastFilter]);

  return (
    <div className="filter-wrapper" ref={wrapperRef}>
      <div
        className={`filter-btn ${isOpen ? "selected-filter" : ""} 
        ${statusMainFilters === STATE_STATUSES.READY &&
          universalFiltersStatus === STATE_STATUSES.READY &&
          statusFilters === STATE_STATUSES.READY &&
          mainLastFilter &&
          isChange ? "current-filter" : ""}
        ${
          statusMainFilters === STATE_STATUSES.READY &&
          universalFiltersStatus === STATE_STATUSES.READY &&
          statusFilters === STATE_STATUSES.READY &&
          mainLastFilter && 
          tempLastFilter &&
          mainLastFilter[paramName] !== tempLastFilter[paramName] ? "uncommited-change" : ""}
          ${titleDate !== "Date" ? "active-filter" : ""}`}
        onClick={toggleopen}
      >
        <span>{isSelectAllTimePeriods ? allTime : titleDate}</span>
      </div>
      {isOpen ? (
        <div className="filter-dropdown date-box">
          <div className="filter-list">
            {Object.keys(data).map((itemDate, indexDate) => {
              return (
                <div key={indexDate}>
                  {data[itemDate].length ? (
                    <div className="date-wrapper">
                      <div className="date-title">{itemDate}</div>
                      <div className="date-box">
                        {data[itemDate].map((item, index) => (
                          <div
                            key={index}
                            onClick={() => handleSelectDate(item.id)}
                            className={`date-item ${selectedDate === `${item.id}` ? "active-date" : ""}`}
                          >
                            {item.name}
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : null}
                </div>
              );
            })}
            <div className="date-wrapper">
              <div className="date-title custom">Select Time Period</div>
              <div className="range-box">
                <RangePicker
                  format={"DD/MM/YYYY"}
                  value={dateInterval}
                  disabledDate={(current) => {
                    return current > moment().subtract(1, "d").endOf("day") || current < startDate;
                  }}
                  onChange={(date, dateString) => changeCustomDate(date, dateString)}
                  getPopupContainer={(trigger) => trigger.parentElement}
                  open={true}
                />
              </div>
            </div>
          </div>
          <div className="control">
              <Button className={`${isChange ? 'reset-date' : 'reset-date-disabled'}`} onClick={resetDate} disabled={!isChange}>
                {dateInterval.length && selectedDate !== mainLastFilter[paramName] ? 'Reset custom' : 'Reset'}
              </Button>
              <Button className={`apply ${!isChange ? 'disabled' : ''}`} onClick={applyFilters} disabled={!isChange}>
                OK
              </Button>
            </div>
        </div>
      ) : null}
    </div>
  );
};

export default connect(
  (state) => ({
    settingsYourProfile: state.settingsYourProfile.settingsYourProfile,
  }),
  { setTitleDateStore }
)(DateFilterBox);
