import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { Pagination, Modal, Checkbox } from "antd";
import moment from "moment";
import useColorTheme from "../../../../../hooks/useColorTheme";
import useRetailers from "../../../../../hooks/useRetailers";
import useCountry, { NUMBER_TYPE } from "../../../../../hooks/useCountry";

import СurrentlyItemChart from "tabs/RankingTabs/RankingBottomTabs/SearchAnalysisTab/charts/СurrentlyItemChart";
import CompareProductsChart from "tabs/RankingTabs/RankingBottomTabs/components/CompareProductsChart";
import LoaderBox from "components/LoaderBox";
import SelectBox from "components/Selects/SelectBox";
import { getTimePeriodSingleDay } from "utils/getTimePeriodSingleDay";
import { sortData } from "utils/sortRankingTable";
import { STATE_STATUSES } from "utils/statuses";
import { timePeriod } from "utils/timePeriod";

import sort from "assets/images/sort.svg";
import sortIconUp from "assets/images/sort_up.svg";
import sortIconDown from "assets/images/sort_down.svg";

import printscreen from "assets/images/printscreen.svg";
import show_chart from "assets/images/show_chart.svg";
import show_chart_white from "assets/images/show_chart_white.svg";
import featured from "assets/images/featured.svg";
import options from "assets/images/options.svg";

const selects = [
  {
    name: "renderItems",
    default: "10",
    options: [
      { lable: "10", value: "10" },
      { lable: "15", value: "15" },
      { lable: "20", value: "20" },
    ],
  },
];

const SummaryTerm = (props) => {
  const {
    data,
    date,
    currentManufacturer,
    termType,
    type,
    storeShareSingleDay,
    actionSingleProduct,
    storeSingleProduct,
    actionSingleProductSummary,
    actionSingleProductSetPeriod,
    actionRemoveProduct,
    actionProductCompare,
    storeProductCompare,
    actionSingleProductCompareSummary,
    actionSingleProductSetCompare,
    statusProduct,
    isFeaturedProducts,
    setIsFeaturedProducts,
  } = props;

  const dispatch = useDispatch();
  const { getRemoteLogo } = useRetailers();
  const { formatCurrencyNumber } = useCountry();

  const wrapperRef = useRef(null);
  const { primaryColor, primaryColorRgb, getColorPromotions } = useColorTheme();

  const [currentPage, setCurrentPage] = useState(0);
  const [renderItems, setRenderItems] = useState(10);
  const [sortParam, setSortParam] = useState("rank");
  const [directionSort, setDirectionSort] = useState(true);

  const [showScreenshot, setShowScreenshot] = useState(false);
  const [retailer, setRetailer] = useState("");
  const [screenshotUrl, sestScreenshotUrl] = useState("");

  const [currentDate, setCurrentDate] = useState(null);
  const [datePeriod, setDatePeriod] = useState(14);
  const [isOpenDatePeriod, setIsOpenDatePeriod] = useState(false);

  const [onlyMyProducts, setOnlyMyProducts] = useState(false);
  const [isCompare, setIsCompare] = useState(false);

  const [isCompareChart, setIsCompareChart] = useState(false);

  const [selectItems, setSelectItems] = useState([]);
  const [selectObjectsChart, setSelectObjectsChart] = useState([]);

  const [compareItems, setCompareItems] = useState([]);
  const [selectObjectsCompare, setSelectObjectsCompare] = useState([]);

  useEffect(() => {
    setCurrentDate(date);
    return () => dispatch({ type: actionRemoveProduct, payload: "" });
  }, []);

  useEffect(() => {
    setCurrentPage(0);
  }, [sortParam, directionSort, renderItems, onlyMyProducts]);

  const filterProducts = (data) =>
    data.filter((item) => {
      if (onlyMyProducts) {
        return item.fromSelectedManufacture === true;
      }
      return item;
    });

  const limit =
    currentPage * renderItems + renderItems < filterProducts(data).length
      ? currentPage * renderItems + renderItems
      : filterProducts(data).length;

  const renderData = sortData(filterProducts(data), sortParam, directionSort).slice(currentPage * renderItems, limit);

  const handleSetDatePeriod = (period) => {
    setDatePeriod(period);
    setIsOpenDatePeriod(false);

    let requests = selectObjectsChart.map((item) =>
      actionSingleProductSummary({
        type,
        raw: true,
        searchTerm: item.searchTerm,
        sourceType: item.retailer_id.toString(),
        timePeriod: getTimePeriodSingleDay(currentDate, period),
        product: item.coreProductId.toString(),
        page: "1",
        limit: "10",
      })
    );

    Promise.all(requests).then((response) => {
      const products = [];

      response.forEach((item) => {
        if (item.data.products.length) {
          products.push(item.data.products[0]);
        }
      });

      actionSingleProductSetPeriod(products);
    });
  };

  const handleChart = (object) => {
    const { coreProductId, retailer_id, searchTerm, uid } = object;

    if (selectItems.includes(uid)) {
      setSelectItems((prevState) => prevState.filter((item) => item !== uid));
      setSelectObjectsChart((prevState) => prevState.filter((item) => item.uid !== uid));

      dispatch({ type: actionRemoveProduct, payload: uid });
    } else {
      setSelectItems((prevState) => [...prevState, uid]);
      setSelectObjectsChart((prevState) => [...prevState, object]);

      actionSingleProduct(
        {
          type,
          raw: true,
          searchTerm,
          sourceType: retailer_id.toString(),
          timePeriod: getTimePeriodSingleDay(currentDate, datePeriod),
          product: coreProductId.toString(),
          page: "1",
          limit: "10",
        },
        true
      );
    }
  };

  const handleCheckbox = (object) => {
    const { uid } = object;

    if (compareItems.includes(uid)) {
      setCompareItems((prevState) => prevState.filter((item) => item !== uid));
      setSelectObjectsCompare((prevState) => prevState.filter((item) => item.uid !== uid));

      dispatch({ type: actionRemoveProduct, payload: uid });
    } else {
      setCompareItems((prevState) => [...prevState, uid]);
      setSelectObjectsCompare((prevState) => [...prevState, object]);
    }
  };

  const handleCompareProducts = () => {
    if (compareItems.length >= 2) {
      let requests = selectObjectsCompare.map((item) =>
        actionSingleProductCompareSummary({
          type,
          raw: true,
          searchTerm: item.searchTerm,
          sourceType: item.retailer_id.toString(),
          timePeriod: getTimePeriodSingleDay(currentDate, datePeriod),
          product: item.coreProductId.toString(),
          page: "1",
          limit: "10",
        })
      );

      Promise.all(requests).then((response) => {
        const products = [];

        response.forEach((item) => products.push(item.data.products[0]));

        actionSingleProductSetCompare(products);

        setIsCompareChart(true);
      });
    }
  };

  const handleSort = (name) => {
    if (sortParam !== name) {
      setDirectionSort(true);
    } else {
      setDirectionSort(!directionSort);
    }
    setSortParam(name);
  };

  const onChangePage = (page, pageSize) => {
    setCurrentPage(page - 1);
  };

  const onChangeSize = (page, pageSize) => {
    setRenderItems(pageSize);
  };

  const handleScreenshot = (screenshot, retailer) => {
    if (screenshot.length) {
      sestScreenshotUrl(screenshot);
      setRetailer(retailer);
      setShowScreenshot(true);
    }
  };

  const setPerPage = (values) => {
    const value = Object.keys(values);

    setRenderItems(Number(values[value]));
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      if (isOpenDatePeriod) {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setIsOpenDatePeriod(false);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
    }, [ref, isOpenDatePeriod]);
  }
  useOutsideAlerter(wrapperRef);

  return (
    <>
      <div className="popup-setting-box singleDay">
        <div className="title-terms">Average Ranking for {currentManufacturer}</div>
        <div className="controls-box">
          {!isCompare && selectItems.length ? (
            <div className="trend-perion-wrapper" ref={wrapperRef}>
              <div className="trend-perion" onClick={() => setIsOpenDatePeriod(!isOpenDatePeriod)}>
                <span className="label">Trend Period</span>
              </div>
              {isOpenDatePeriod ? (
                <div className="dropdown">
                  <div className="description">Relative</div>
                  <div className="period-box">
                    {timePeriod.map((item, index) => (
                      <div
                        key={index}
                        className="item-period"
                        style={{
                          border: `1px solid ${datePeriod === item.value ? primaryColor : "#dfdfea"}`,
                          fontFamily: datePeriod === item.value ? "Gilroy-ExtraBold" : "Gilroy-Medium",
                          color: datePeriod === item.value ? primaryColor : "#000",
                        }}
                        onClick={() => handleSetDatePeriod(item.value)}
                      >
                        {item.label}
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
          <div className="onlyProducts right-wrapper" style={{ marginRight: "15px" }}>
            <Checkbox checked={isFeaturedProducts} onChange={() => setIsFeaturedProducts(!isFeaturedProducts)}>
              Sponsored Products
            </Checkbox>
          </div>
          <div
            className="compare-data-popup"
            style={{
              background: isCompare ? primaryColor : "#eef2f7",
              color: isCompare ? "#fff" : "#000",
            }}
            onClick={() => setIsCompare(!isCompare)}
          >
            Compare Products
          </div>
          <div className="onlyProducts right-wrapper">
            <Checkbox checked={onlyMyProducts} onChange={() => setOnlyMyProducts(!onlyMyProducts)}>
              Show Only My Products
            </Checkbox>
          </div>
        </div>
      </div>
      <div className="search-term-single-day">
        {data.length ? (
          <>
            <div className="table-header main">
              <div className="rank-sort" onClick={() => handleSort("rank")}>
                <div>Rank</div>
                <img src={sortParam === "rank" ? (directionSort ? sortIconUp : sortIconDown) : sort} alt="sort" />
              </div>
              <div className="search-term-sort" onClick={() => handleSort("searchTerm")}>
                <div>{termType}</div>
                <img src={sortParam === "searchTerm" ? (directionSort ? sortIconUp : sortIconDown) : sort} alt="sort" />
              </div>
              <div className="retailer-sort" onClick={() => handleSort("retailer")}>
                <div>Retailer</div>
                <img src={sortParam === "retailer" ? (directionSort ? sortIconUp : sortIconDown) : sort} alt="sort" />
              </div>
              <div className="title-sort" onClick={() => handleSort("title")}>
                <div>Product Title (Retailer)</div>
                <img src={sortParam === "title" ? (directionSort ? sortIconUp : sortIconDown) : sort} alt="sort" />
              </div>
              <div className="discription-sort">
                <div>Promotion Description</div>
              </div>
              <div className="price-sort" onClick={() => handleSort("shelfPrice")}>
                <div>Shelf Price</div>
                <img src={sortParam === "shelfPrice" ? (directionSort ? sortIconUp : sortIconDown) : sort} alt="sort" />
              </div>
              <div style={{ width: "100%", maxWidth: "100px" }}></div>
            </div>
            <div style={{ position: "relative" }}>
              <div className="table-body main">
                {renderData.map((item, index) => (
                  <div key={index}>
                    <div
                      className={`table-item ${item.selectedProduct ? "selectedProduct" : ""}`}
                      style={{
                        borderBottom: selectItems.includes(item.uid) ? "none" : "2px solid #e0e0e4",
                      }}
                    >
                      <div className="rank">
                        <span>{item.rank}</span>
                        {item.featured ? <img src={featured} alt="featured" /> : null}
                        {item.productOptions ? <img src={options} alt="options" /> : null}
                      </div>
                      <div className="search-term">{item.searchTerm}</div>
                      <div className="retailer">
                        <img src={getRemoteLogo(item.retailer)} alt="retailer" />
                      </div>
                      <div className={`title-wrapper ${item.fromSelectedManufacture ? "selectedManufacture" : ""}`}>
                        <img src={item.image} alt="product" />
                        <div className="titles-box">
                          <div className="title">{item.title}</div>
                          <div className="subtitle">{item.detailTitle}</div>
                        </div>
                      </div>
                      <div className="description-wrapper">
                        <div
                          className="description-box"
                          style={{
                            background: item.promotions ? getColorPromotions(item.promotions.mechanic.name) : null,
                          }}
                        >
                          {item.promotions ? (
                            item.promotions.description
                          ) : (
                            <span className="no-promotion">No promotion</span>
                          )}
                        </div>
                      </div>
                      <div className="price">{formatCurrencyNumber(item.shelfPrice, NUMBER_TYPE.CURRENCY)}</div>

                      <div className="right-wrapper">
                        {Boolean(item.screenshot) ? (
                          <div className="icon-btn" onClick={() => handleScreenshot(item.screenshot, item.retailer)}>
                            <img src={printscreen} alt="printscreen" />
                          </div>
                        ) : (
                          <span className="no-screen">N/A</span>
                        )}
                        {isCompare ? (
                          <Checkbox
                            checked={compareItems.includes(item.uid)}
                            onChange={() => handleCheckbox(item)}
                          ></Checkbox>
                        ) : (
                          <div
                            className="icon-btn"
                            style={{
                              background: selectItems.includes(item.uid) ? primaryColor : "#eef2f7",
                            }}
                            onClick={() => handleChart(item)}
                          >
                            <img
                              style={{ cursor: "pointer" }}
                              src={selectItems.includes(item.uid) ? show_chart_white : show_chart}
                              alt="back"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {props[storeSingleProduct].length
                      ? props[storeSingleProduct].map((chart, index) => {
                          if (chart.uid === item.uid && selectItems.includes(item.uid)) {
                            return (
                              <div
                                key={index}
                                className="wrapper-bottom-chart"
                                style={{ borderBottom: "2px solid #e0e0e4" }}
                              >
                                <СurrentlyItemChart
                                  type={"rank"}
                                  data={chart.ranks}
                                  timePeriod={[0, chart.ranks.length]}
                                  isMarker={false}
                                  isPopup={true}
                                  outside={false}
                                />
                              </div>
                            );
                          }
                        })
                      : null}
                  </div>
                ))}
              </div>
              {props[statusProduct] === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
            </div>
            <div className="pagimation-wrapper">
              <Pagination
                className="pagination-controls"
                total={filterProducts(data).length}
                pageSize={renderItems}
                current={currentPage + 1}
                onChange={onChangePage}
                onShowSizeChange={onChangeSize}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
              />
              {data.length >= 20
                ? selects.map((item, index) => (
                    <div className="per-page-box" key={index}>
                      <span>Show</span>
                      <SelectBox key={index} data={item} setSelectValue={setPerPage} />
                      <span style={{ marginLeft: "15px" }}>Per Page</span>
                    </div>
                  ))
                : null}
            </div>
            {isCompare ? (
              <div className="table-footer summary">
                <div className="compare-hint">
                  <div className="title">Compare Products</div>
                  <div className="hint">Select at least 2 products to compare.</div>
                </div>
                <div
                  className="compare-btn"
                  style={{
                    background: compareItems.length === 1 ? primaryColorRgb(0.5) : primaryColor,
                  }}
                  onClick={handleCompareProducts}
                >
                  {compareItems.length > 0 ? (
                    <span>{`Compare ${compareItems.length} Product${compareItems.length >= 2 ? "s" : ""}`}</span>
                  ) : (
                    "Select Products"
                  )}
                </div>
                <div className="shadow-box"></div>
              </div>
            ) : null}
            <Modal
              className="ranking-page-modal"
              visible={showScreenshot}
              onCancel={() => {
                setShowScreenshot(false);
                sestScreenshotUrl("");
                setRetailer("");
              }}
            >
              <div className="title-popup-single-day">
                Summary in <span>{retailer}</span>
              </div>
              <div className="screenshot-wrapper">
                <img src={screenshotUrl} alt="screenshot" />
              </div>
            </Modal>
            <Modal
              className="ranking-page-modal bottom"
              visible={isCompareChart}
              onCancel={() => {
                setIsCompareChart(false);
              }}
            >
              <div className="wrapper-title-popup">
                <div className="product-title-popup">
                  <div className="title">
                    Ranking Product Comparison
                    {/* <span style={{ textTransform: 'capitalize' }}> {props[storeShareSingleDay].retailer} </span> <span style={{ textTransform: 'lowercase' }}>“ ”</span> */}
                  </div>

                  <div className="subtitle">
                    {moment(currentDate).subtract(datePeriod, "d").format("DD MMM YY")} -{" "}
                    {moment(currentDate).format("DD MMM YY")}
                  </div>
                </div>
              </div>

              <CompareProductsChart data={props[storeProductCompare]} />
            </Modal>
          </>
        ) : (
          <div className="chart-no-data static">
            <span>Looks like we don't have data for this request</span>
          </div>
        )}
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    searchSingleProduct: state.rankingSearchAnalysis.searchSingleProduct,
    searchSingleProductCompare: state.rankingSearchAnalysis.searchSingleProductCompare,
    statusSearch: state.rankingSearchAnalysis.popupStatus,

    analisisSingleProduct: state.rankingLocationAnalysis.analisisSingleProduct,
    analisisSingleProductCompare: state.rankingLocationAnalysis.analisisSingleProductCompare,
    statusLocation: state.rankingLocationAnalysis.popupStatus,
  }),
  null
)(SummaryTerm);
