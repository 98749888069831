import { useCallback } from "react";
import { useSelector } from "react-redux";
import { getImage } from "../utils/getImage";
import { hexToRgbA } from "../utils/hexToRgbA";

export default function useRetailers() {
    const { retailersLogo } = useSelector((state) => state.authorization);
    const { sourceType } = useSelector((state) => state.filters.filters);

    const getRemoteLogo = useCallback((retailerName) => {
       return getImage(retailerName, retailersLogo.find(el => el.name === retailerName)?.logo)
    },[retailersLogo])

    const getRetailerColor = useCallback((retailerNam, opacity='1') => {
        return hexToRgbA(sourceType.find(el => el.name === retailerNam)?.color || '#000000', opacity)
     },[sourceType])

     const getRetailerColorHex = useCallback((retailerNam) => {
        return sourceType.find(el => el.name === retailerNam)?.color || '#000000';
     },[sourceType])

     const getRetailerBackgroundColor = useCallback((retailerNam) => {
        return [
          [0, hexToRgbA(sourceType.find(el => el.name === retailerNam)?.color || '#000000', '.2')],
          [1, hexToRgbA(sourceType.find(el => el.name === retailerNam)?.color || '#000000', '0')],
        ];
     },[sourceType])

    return { getRemoteLogo, getRetailerColor, getRetailerColorHex, getRetailerBackgroundColor };
}
