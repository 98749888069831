import React from "react";
import { Tabs } from "antd";

import about from "assets/images/about.svg";
import { TabWithIcon } from "assets/icons/icons";

import MediaSummaryTab from "./MediaSummaryTab";
import ShareOfMediaTab from "./ShareOfMediaTab";
import MediaDetailsTab from "./MediaDetailsTab";

const MediaTopTabs = ({ activeTabTop, setActiveTabTop }) => {
  const { TabPane } = Tabs;

  function callback(key) {
    setActiveTabTop(key);
  }

  return (
    <div className="main-box">
      <Tabs defaultActiveKey={activeTabTop} onChange={callback}>
        <TabPane
          tab={
            <TabWithIcon
              tabName={"Summary"}
              icon={about}
              tooltip={
                "Brand Nudge visits 9 retailers every day. It's a sample data."
              }
            />
          }
          key="1"
        >
          <MediaSummaryTab />
        </TabPane>
        <TabPane
          tab={
            <TabWithIcon
              tabName={"Share of Media"}
              icon={about}
              tooltip={
                "Brand Nudge visits 9 retailers every day. It's a sample data."
              }
            />
          }
          key="2"
        >
          <ShareOfMediaTab />
        </TabPane>
        <TabPane
          tab={
            <TabWithIcon
              tabName={"Details"}
              icon={about}
              tooltip={
                "Brand Nudge visits 9 retailers every day. It's a sample data."
              }
            />
          }
          key="3"
        >
          <MediaDetailsTab />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default MediaTopTabs;
