import { success, error } from "redux-saga-requests";
import {
  FETCH_TREND,
  FETCH_COMPARE_TREND,
  CLEAR_COMPARE_TREND,
  FETCH_PRICE_TREND_POPUP,
  SET_PRISTINE_PRICE_TREND,
} from "./actions";
import { RESET_ERROR } from "store/error/actions";
import { STATE_STATUSES } from "utils/statuses";

const initialData = {
  byTotal: {
    priceChart: {
      data: [],
      names: [],
    },
    topMovers: {
      topMoversBase: [],
      topMoversPromoted: [],
      topMoversShelf: [],
    },
  },
  byRetailer: {
    priceChart: {
      data: [],
      names: [],
    },
    topMovers: {
      topMoversBase: [],
      topMoversPromoted: [],
      topMoversShelf: [],
    },
  },
  byBrand: {
    priceChart: {
      data: [],
      names: [],
    },
    topMovers: {
      topMoversBase: [],
      topMoversPromoted: [],
      topMoversShelf: [],
    },
  },
  byCategory: {
    priceChart: {
      data: [],
      names: [],
    },
    topMovers: {
      topMoversBase: [],
      topMoversPromoted: [],
      topMoversShelf: [],
    },
  },
  byManufacturer: {
    priceChart: {
      data: [],
      names: [],
    },
    topMovers: {
      topMoversBase: [],
      topMoversPromoted: [],
      topMoversShelf: [],
    },
  },
  byProductGroup: {
    priceChart: {
      data: [],
      names: [],
    },
    topMovers: {
      topMoversBase: [],
      topMoversPromoted: [],
      topMoversShelf: [],
    },
  },
  overallChange: {
    basePrice: { percent: 0 },
    promotedPricePercent: { percent: 0 },
    shelfPricePercent: { percent: 0 },
  },
};

const initialState = {
  currentData: initialData,
  compareData: initialData,
  status: STATE_STATUSES.INIT,
  isPristine: true,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TREND:
    case FETCH_COMPARE_TREND:
    case FETCH_PRICE_TREND_POPUP: {
      return processReducer(state);
    }

    case success(FETCH_TREND): {
      return {
        ...state,
        currentData: action.data,
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }

    case success(FETCH_COMPARE_TREND): {
      return {
        ...state,
        compareData: action.data,
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }

    case success(FETCH_PRICE_TREND_POPUP): {
      return {
        ...state,
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }

    case error(FETCH_TREND):
    case error(FETCH_COMPARE_TREND):
    case error(FETCH_PRICE_TREND_POPUP): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case CLEAR_COMPARE_TREND: {
      return {
        ...state,
        compareData: initialData,
      };
    }

    case SET_PRISTINE_PRICE_TREND: {
      return {
        ...state,
        isPristine: action.payload,
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
