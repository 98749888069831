import styled from "styled-components";

export const Styles = styled.div`
   margin-left: 16px;
  margin-top: 25px;

  .topMovers {
    position: relative;
    width: 402px;
    height: 400px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #abaaba;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #807f97;
    }
  }
  .filters {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 6px;
    min-height: 32px;
  }
  .filters-wrapper {
    display: flex;
    flex-direction: row;
  }
  .single-filter {
    display: flex;
    margin: 4px;
    width: 62px;
    justify-content: center;
    cursor: pointer;
  }
  .sort-btns {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .sort-btn-up {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 12px;
  }
  .sort-btn-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 12px;
  }
  .mover {
    display: flex;
    align-items: center;
    margin: 10px 0;
    justify-content: space-between;
  }
  .image {
    min-width: 40px;
    height: 40px;
    border-radius: 4px;
  }
  .mover-results {
    display: flex;
    flex-direction: row;

    & > :last-child {
      border-right: none;
    }
  }
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .title {
    font-size: 14px;
    margin-left: 20px;
    text-transform: capitalize;
    font-family: Gilroy-Medium;
  }
  .mover-result {
    width: 62px;
    margin: 4px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    border-right: 1px solid #e0e0e4;
    font-family: Gilroy-Medium;
  }
`;
