import moment from 'moment'

const timePeriodData = {
    '7': "Jul",
    '8': "Jun",
    '9': "May",
    '10': "Apr",
    '12': "Mar",
    '13': "Feb",
    '14': "Jan",
    '15': "Dec",
    '16': "Nov",
    '17': "Oct",
    '18': "Sep",
    '19': "Aug",
    '1': "Today",
    '2': "Yesterday",
    '3': "Last 7 days",
    '4': "Last 14 days",
    '5': "Last 30 days",
    '6': "Last 90 days",
    '20': "2019",
    '21': "2020"
}

export const priceRangeTimePeriod = (date) => {
    if (Number(date)) {
        return timePeriodData[date]
    } else {
        const dates = date.split('|')
        return `${moment(dates[1]).format('DD MMM YYYY')}-${moment(dates[2]).format('DD MMM YYYY')}`
    }
}