import React from "react";
import _ from "lodash";
import { Modal, Pagination } from "antd";

import LoaderBox from "components/LoaderBox";

import { roundingNumbers } from "utils/roundingNumbers";
import useRetailers from "../../../../hooks/useRetailers";
import useCountry, { NUMBER_TYPE } from "../../../../hooks/useCountry";

import green from "assets/images/green.svg";
import red from "assets/images/red.svg";
import star from "assets/images/star.svg";
import sort from "assets/images/sort.svg";
import sortIconDown from "assets/images/sort_down.svg";
import sortIconUp from "assets/images/sort_up.svg";
import search from "assets/images/search.svg";

const RankingModal = (props) => {
  const {
    status,
    showPopup,
    setShowPopup,
    data,
    retailers,
    sortId,
    sortDirection,
    handleSearch,
    handleSort,
    page,
    setPage,
  } = props;
  const { getRemoteLogo } = useRetailers();
  const { formatCurrencyNumber } = useCountry();

  const onChangePage = (page) => {
    setPage(page);
  };

  return (
    <Modal className="ranking-modal" visible={showPopup} onCancel={() => setShowPopup(false)}>
      <div className="current-table-box">
        <div className="wrapper-table-box ranking">
          <div className="wrapper-table">
            <div className="box-wrapper">
              <div className="title-popup">
                <div className="search">
                  <img src={search} alt="search" />
                  <input onChange={(e) => handleSearch(e.target.value)} placeholder="Search for a Product" />
                </div>
              </div>
              <div className="wrapper">
                {_.sortBy(data.retailers, (item) => retailers.indexOf(item.id)).map((item, index) => (
                  <div className="table-item sort" onClick={() => handleSort(item.id)} key={index}>
                    <img className="retailer-logo" src={getRemoteLogo(item.name)} alt="retailer" />
                    <img src={sortId === item.id ? (sortDirection ? sortIconUp : sortIconDown) : sort} alt="sort" />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="wrapper-table">
            <div className="box-wrapper">
              <div className="title-popup">
                <span className="averagePrice">Average Price</span>
              </div>
              <div className="wrapper">
                {data.averagePrices.length ? (
                  _.sortBy(data.averagePrices, (item) => retailers.indexOf(item.retailerId)).map((item, index) => (
                    <div
                      className={`table-item ${item.bestPrice ? "bestPrice" : ""} ${
                        item.highestPrice ? "highestPrice" : ""
                      }`}
                      key={index}
                    >
                      <span className="price">
                        {formatCurrencyNumber(roundingNumbers(item.price), NUMBER_TYPE.CURRENCY)}
                      </span>
                      <div className="item">
                        {item.star ? <img className="star" src={star} alt="star" /> : <div className="box-empty"></div>}
                        <span>{Number(item.percent) ? item.percent.toFixed(1) : 0}%</span>
                        {item.percent !== 0 ? (
                          <img className="direction" src={item.direction ? green : red} alt="direction" />
                        ) : (
                          <div className="box-empty"></div>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="no-listed">NOT LISTED</div>
                )}
              </div>
            </div>

            <div className="wrapper-box-relative">
              {data.products.map((data, index) => (
                <div className="box-wrapper" key={index}>
                  <div className="title-popup">
                    <img className="image" src={data.image ? data.image : data.retailerImage} alt="banner" />
                    <span>{data.title}</span>
                  </div>
                  <div className="wrapper">
                    {_.sortBy(data.prices, (item) => retailers.indexOf(item.retailerId)).map((item, index) => (
                      <div
                        key={index}
                        className={`table-item ${item.bestPrice ? "bestPrice" : ""} ${
                          item.highestPrice ? "highestPrice" : ""
                        }`}
                      >
                        <span className="price">
                          {formatCurrencyNumber(roundingNumbers(item.price), NUMBER_TYPE.CURRENCY)}
                        </span>
                        <div className="item">
                          {item.star ? (
                            <img className="star" src={star} alt="star" />
                          ) : (
                            <div className="box-empty"></div>
                          )}
                          <span>{Number(item.percent) ? item.percent.toFixed(1) : 0}%</span>
                          {item.percent !== 0 ? (
                            <img className="direction" src={item.direction ? green : red} alt="direction" />
                          ) : (
                            <div className="box-empty"></div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              {status ? <LoaderBox /> : null}
            </div>
          </div>
        </div>
      </div>
      {data.total > 0 ? (
        <Pagination
          className="pagination-controls"
          onChange={onChangePage}
          current={page}
          pageSize={10}
          total={data.total}
          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
        />
      ) : null}
    </Modal>
  );
};

export default RankingModal;
