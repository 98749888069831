import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useColorTheme from "../../../../../../hooks/useColorTheme";
import useFilters from "../../../../../../hooks/useFilters";

//Components
import FiltersAlert from "components/Alerts/components/FiltersAlert";
import { Styles } from "./styles";
//
import { setAssortmentDetailsFilter } from "store/assortment/assortmentDetails/actions";

const AssortmentDetailsCompareRetailers = ({ setQueryParams, getProductsBtnClicked }) => {
  //Selectors
  const { sourceType } = useSelector((state) => state.filters.filters);
  const { lastFilter } = useFilters();
  const { filter } = useSelector((state) => state.assortmentDetails);

  //States
  const [firstAlertIdsArr, setFirstAlertIdsArr] = useState(filter[0].value);
  const [secondAlertIdsArr, setSecondAlertIdsArr] = useState(filter[1].value);
  const [selectedFirstArrIds, setSelectedFirstArrIds] = useState([]);
  const [selectedSecondArrIds, setSelectedSecondArrIds] = useState([]);
  const { primaryColor } = useColorTheme();

  const selectedArrays = [firstAlertIdsArr, secondAlertIdsArr];
  //Constants
  const selectedRetailersIdsInGlobalFilters = lastFilter.sourceType.length ? lastFilter.sourceType.split("|") : [];
  const dispatch = useDispatch();

  const arrOfStringsToArrOfNumbers = (arr) => {
    return arr.map((el) => +el);
  };

  const setSelectValue = (values) => {
    let updateData = [];
    for (let index = 0; index < filter.length; index++) {
      const element = filter[index];

      for (const key in values) {
        const el = values[key];
        if (key === element.name) {
          const newObj = { name: key, value: el };
          updateData.push(newObj);
        } else {
          updateData.push(element);
        }
      }
    }

    dispatch(setAssortmentDetailsFilter(updateData));
  };

  const setFirstAlertIdsArrParams = (name, selectItems) => {
    setSelectValue({ [name]: selectItems });
    setFirstAlertIdsArr(selectItems);
  };

  const setSecondAlertIdsArrParams = (name, selectItems) => {
    setSelectValue({ [name]: selectItems });
    setSecondAlertIdsArr(selectItems);
  };

  const setFirstIdsOnClick = (id) => {
    setSelectedFirstArrIds(id);
  };
  const setSecondIdsOnClick = (id) => {
    setSelectedSecondArrIds(id);
  };

  const selectedFunc = [setFirstAlertIdsArrParams, setSecondAlertIdsArrParams];
  const setOnClickId = [setFirstIdsOnClick, setSecondIdsOnClick];

  const filterArrFromAnotherArrNotIncludes = (arr1, arr2, key) => {
    return arr1.filter((item) => !arr2.includes(item[key]));
  };

  const filterArrFromAnotherArrIncludes = (arr1, arr2, key) => {
    return arr1.filter((item) => arr2.includes(item[key]));
  };

  const data = selectedRetailersIdsInGlobalFilters.length
    ? filterArrFromAnotherArrIncludes(sourceType, arrOfStringsToArrOfNumbers(selectedRetailersIdsInGlobalFilters), "id")
    : sourceType;

  const firstDataArr = filterArrFromAnotherArrNotIncludes(data, [...secondAlertIdsArr, ...selectedSecondArrIds], "id");
  const secondDataArr = filterArrFromAnotherArrNotIncludes(data, [...firstAlertIdsArr, ...selectedFirstArrIds], "id");

  const item = {
    name: "listed",
    label: "Retailers",
    placeholder: "retailer",
    nameParam: "name",
    data: firstDataArr,
    customLabelName: (count) => `Listed in ${count} Retailer${count > 1 ? "s" : ""}`,
    notIncludeSearch: true,
    notIncludeCount: true,
  };
  const dataForFiltersAlert = [
    item,
    { ...item, name: "notListed", data: secondDataArr, customLabelName: (count) => `Not Listed in ${count} Retailers` },
  ];

  const prepareArrForQueryParams = (arr) => {
    return arr.join("|");
  };

  const onButtonClick = () => {
    const listed = prepareArrForQueryParams(firstAlertIdsArr);
    const notListed = prepareArrForQueryParams(secondAlertIdsArr);
    setQueryParams((prevState) => ({ ...prevState, listed, notListed, listArray: [...firstAlertIdsArr, ...secondAlertIdsArr] }));
    getProductsBtnClicked();
  };

  return (
    <Styles color={primaryColor}>
      {dataForFiltersAlert.map((item, i) => {
        return (
          <div key={i} className="compare-retailers-box">
            <FiltersAlert
              item={item}
              setFiltersParams={selectedFunc[i]}
              selectItems={selectedArrays[i]}
              setOnclickId={setOnClickId[i]}
              textSelectStyles={{ fontFamily: "Gilroy-Medium" }}
            />
          </div>
        );
      })}
      <button className="get-products-btn" disabled={!firstAlertIdsArr.length || !secondAlertIdsArr.length} onClick={() => onButtonClick()}>
        Get Products
      </button>
    </Styles>
  );
};

export default AssortmentDetailsCompareRetailers;
