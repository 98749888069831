import { success, error } from "redux-saga-requests";
import { FETCH_SHARE_TREND, SET_PRISTINE_SHARE_TREND } from "./actions";
import { STATE_STATUSES } from "utils/statuses";
import { RESET_ERROR } from "store/error/actions";

const initialState = {
  shareTrend: {
    byTotal: {
      data: {
        values: [],
      },
    },
    byRetailer: {
      data: [],
    },
  },
  status: STATE_STATUSES.INIT,
  isPristine: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SHARE_TREND: {
      return processReducer(state);
    }
    case success(FETCH_SHARE_TREND): {
      return {
        ...state,
        shareTrend: action.data,
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }
    case error(FETCH_SHARE_TREND): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case SET_PRISTINE_SHARE_TREND: {
      return {
        ...state,
        isPristine: action.payload,
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
