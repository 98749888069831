const url = process.env.REACT_APP_URL;

//Price Comprasion
export const FETCH_PRICE_COMPARISON = "FETCH_PRICE_COMPARISON";
export const fetchPriceComparsion = (data) => ({
  type: FETCH_PRICE_COMPARISON,
  request: {
    url: `${url}/v2/price/comparison`,
    method: "POST",
    data,
  },
});

export const FETCH_COMPARE_PRICE_COMPARISON = "FETCH_COMPARE_PRICE_COMPARISON";
export const fetchComparePriceComparsion = (data) => ({
  type: FETCH_COMPARE_PRICE_COMPARISON,
  request: {
    url: `${url}/v2/price/comparison`,
    method: "POST",
    data,
  },
  meta: {
    asPromise: true,
  },
});

export const SET_PRISTINE_COMPARISON = "SET_PRISTINE_COMPARISON";
export const setPristineRange = (pristine) => {
  return {
    type: SET_PRISTINE_COMPARISON,
    payload: pristine,
  };
};
