const url = process.env.REACT_APP_URL;

export const FETCH_UNIVERSAL_FILTERS = "FETCH_UNIVERSAL_FILTERS";
export const fetchUniversalFilters = (data) => ({
  type: FETCH_UNIVERSAL_FILTERS,
  request: {
    url: `${url}/v2/filters/universal`,
    method: "POST",
    data,
  },
  meta: {
    asPromise: true,
  },
});

export const SET_FETCH_UNIVERSAL_FILTERS = "SET_FETCH_UNIVERSAL_FILTERS ";
export const setUniversalFilters= (data) => {
  return {
    type: SET_FETCH_UNIVERSAL_FILTERS,
    data,
  };
};

export const SET_IS_FETCH_STATUS = "SET_IS_FETCH_STATUS ";
export const setIsFetchStatus= (data) => {
  return {
    type: SET_IS_FETCH_STATUS,
    data,
  };
};

export const SET_UNIVERSAL_OBJ_STATUS = "SET_UNIVERSAL_OBJ_STATUS ";
export const setUniversalObjStatus= (data) => {
  return {
    type: SET_UNIVERSAL_OBJ_STATUS,
    data,
  };
};