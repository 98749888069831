import React from "react";
import { Checkbox } from "antd";

const DeliveryAlert = ({ item, value, setValue }) => {
  const { name, icon, label } = item;

  return (
    <div className="wrapper-custom-select-box">
      <div className="wrapper-custom-select">
        <Checkbox
          disabled={name === "email"}
          style={{ backgroundColor: "#eef2f7" }}
          checked={value}
          onChange={() => setValue(name, !value)}
        >
          <div className="label">{label}</div>
          <div className="hint-selected-item">{icon}</div>
        </Checkbox>
      </div>
    </div>
  );
};

export default DeliveryAlert;
