const url = process.env.REACT_APP_URL;

export const FETCH_SHARE_OF_MEDIA_BOTTOM = "FETCH_SHARE_OF_MEDIA_BOTTOM";
export const fetchShareOfMediaBottom = (id, params) => ({
  type: FETCH_SHARE_OF_MEDIA_BOTTOM,
  request: {
    url: `${url}/v2/media/share/list/${id}`,
    method: "POST",
    data: params,
  },
  meta: {
    asPromise: true,
  },
});

export const SET_PRISTINE_SHARE_OF_MEDIA_BOTTOM = "SET_PRISTINE_SHARE_OF_MEDIA_BOTTOM";
export const setPristineShareOfMediaBottom = (pristine) => {
  return {
    type: SET_PRISTINE_SHARE_OF_MEDIA_BOTTOM,
    payload: pristine,
  };
};
