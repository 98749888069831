import { success, error } from "redux-saga-requests";
import {
  FETCH_RETAILER_AVAILABILITY,
  SET_PRISTINE_RETAILER_AVAILABILITY,
  SET_RETAILER_AVAILABILITY_FILTER,
  ADD_TO_DATA_RETAILER_AVAILABILITY,
  FETCH_RETAILER_AVAILABILITY_POPUP,
  RESET_RETAILER_AVAILABILITY_POPUP,
} from "./actions";
import { STATE_STATUSES } from "utils/statuses";
import { RESET_ERROR } from "store/error/actions";

const defaultFilter = [
  {
    name: "availability",

    value: "availability",
  },
  { name: "rangeValues", value: [0, 100] },
  {
    name: "byProduct",

    value: "product",
  },
];
const availabilityPopup = { success: false, item: {}, period: {} };

const initialState = {
  retailerAvailability: {
    retailers: [],
    averageAvailability: [],
    rows: [],
  },
  controlData: {
    total: 0,
    average: {},
    result: [],
    success: false,
  },
  availabilityPopup: availabilityPopup,
  status: STATE_STATUSES.INIT,
  isPristine: true,
  filter: localStorage.getItem(SET_RETAILER_AVAILABILITY_FILTER)
    ? JSON.parse(localStorage.getItem(SET_RETAILER_AVAILABILITY_FILTER))
    : defaultFilter,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RETAILER_AVAILABILITY:
    case FETCH_RETAILER_AVAILABILITY_POPUP: {
      return processReducer(state);
    }

    case success(FETCH_RETAILER_AVAILABILITY): {
      return {
        ...state,
        controlData: { ...action.data, success: true },
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }
    case success(FETCH_RETAILER_AVAILABILITY_POPUP): {
      return {
        ...state,
        availabilityPopup: { success: true, ...action.data },
        status: STATE_STATUSES.READY,
      };
    }
    case SET_RETAILER_AVAILABILITY_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }

    case ADD_TO_DATA_RETAILER_AVAILABILITY: {
      return {
        ...state,
        retailerAvailability: action.payload,
      };
    }
    case SET_PRISTINE_RETAILER_AVAILABILITY: {
      return {
        ...state,
        isPristine: action.payload,
      };
    }
    case RESET_RETAILER_AVAILABILITY_POPUP: {
      return {
        ...state,
        availabilityPopup: availabilityPopup,
      };
    }

    case error(FETCH_RETAILER_AVAILABILITY):
    case error(FETCH_RETAILER_AVAILABILITY_POPUP): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
