import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//Components
import MainFilters from "components/Filters/MainFilters";
import NavBar from "components/NavBar";
import MediaTopTabs from "tabs/MediaTabs/MediaTopTabs/MediaTopTabs";
import LoaderBox from "components/LoaderBox";
import useFilters from "../hooks/useFilters";

//Actions
import { setPristineMediaSummaryTop } from "store/media/mediaSummaryTop/actions";
import { setPristineShareOfMediaTop } from "store/media/shareOfMediaTop/actions";
import { setPristineMediaDetails } from "store/media/mediaDetails/actions";
import { setPristineMediaSummaryBottom } from "store/media/mediaSummaryBottom/actions";
//Utils
import { STATE_STATUSES } from "utils/statuses";

const Media = () => {
  const { status: filtersStatus } = useSelector((state) => state.filters);
  const { prepareLogoutStatus, status: statusAuth } = useSelector((state) => state.authorization);

  //States
  const [activeTabTop, setActiveTabTop] = useState("1");
  const {
    mainLastFilter,
    statusMainFilters,
    universalFiltersStatus,
    statusFilters,
    titleDateStatus,
  } = useFilters();

  //Constants
  const dispatch = useDispatch();

  const getActionTop = useCallback((value) =>
    [
      setPristineMediaSummaryTop(value),
      setPristineShareOfMediaTop(value),
      setPristineMediaDetails(value),
    ][+activeTabTop - 1]
  ,[activeTabTop]);

  const getPristineTop = +activeTabTop > 1 ? false : true;

  const getActionBottom = (value) => setPristineMediaSummaryBottom(value);
  const getPristineBottom = true;

  useEffect(() => {
    return () => {
      dispatch(getActionTop(getPristineTop));
      dispatch(getActionBottom(getPristineBottom));
    };
  }, [dispatch, getActionTop, getPristineBottom, getPristineTop]);

  useEffect(() => {
    if (filtersStatus === STATE_STATUSES.PENDING) {
      dispatch(getActionTop(true));
      dispatch(getActionBottom(true));
    }
  }, [filtersStatus, dispatch, getActionTop]);

  return (
    <div className="main-page-wrapper">
      <NavBar />
      <div className="main-page-box">
        <MainFilters />
        <div className="main-title-page">Media</div>
        <div className="main-tabs-box">
          <MediaTopTabs
            activeTabTop={activeTabTop}
            setActiveTabTop={setActiveTabTop}
          />
        </div>
      </div>
      {
        statusAuth !== STATE_STATUSES.PENDING &&
        mainLastFilter &&
        statusMainFilters === STATE_STATUSES.READY &&
        universalFiltersStatus === STATE_STATUSES.READY &&
        statusFilters === STATE_STATUSES.READY &&
        titleDateStatus === STATE_STATUSES.READY &&
        prepareLogoutStatus === STATE_STATUSES.INIT ? null : <LoaderBox isOpacity={true} />
      }
      </div>
  );
};

export default Media;
