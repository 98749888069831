import React from "react";
import { Styles } from "./Styles";
import sort from "assets/images/sort.svg";
import sortIconDown from "assets/images/sort_down.svg";
import sortIconUp from "assets/images/sort_up.svg";
import RenderNoData from "components/RenderNoData";

const ContentBox = ({ header, children, isOpacity, type, sortArr, handleSort, data, sortDirection }) => {
  const ASC = "asc";
  const DESC = "desc";

  const renderImage = (name) => {
    const ifState = sortArr.map((el) => el.includes(name)).filter((el) => el === true);

    if (ifState.length) {
      const indexOfEl = sortArr.findIndex((el) => el.includes(name));
      if (sortArr[indexOfEl].includes(DESC)) {
        return sortIconUp;
      } else {
        return sortIconDown;
      }
    } else {
      return sort;
    }
  };

  return (
    <Styles>
      {data.length ? (
        <div className="content-box" style={{ opacity: isOpacity ? 0.6 : null }}>
          <div className="header">
            <span
              className="content-header"
              style={{ cursor: type ? "pointer" : null }}
              onClick={type ? () => handleSort(type, ASC) : null}
            >
              {header}
            </span>
            {type ? (
              <div className="sort-btns">
                <div className="sort-btn-up" onClick={() => handleSort(type, ASC)}></div>
                <div className="sort-btn-bottom" onClick={() => handleSort(type, DESC)}></div>
                <img src={renderImage(type)} alt="sort" />
              </div>
            ) : null}
          </div>
          {children}
        </div>
      ) : (
        <RenderNoData style={{ height: 145 }} />
      )}
    </Styles>
  );
};

export default ContentBox;
