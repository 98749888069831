import React from "react";

import { ProductStyles } from "../Styles";

import productImage from "assets/images/testImage.png";

const ProductItemCalendar = ({ item, isLastChild, key }) => {
  const { title, image } = item;

  return (
    <ProductStyles>
      <div className="lines" key={key}>
        <div></div>
        <div style={{ borderLeft: !isLastChild ? null : 0 }}></div>
      </div>
      <div className="product">
        <img src={image ? image : productImage} alt="" />
        <span>{title}</span>
      </div>
    </ProductStyles>
  );
};

export default ProductItemCalendar;
