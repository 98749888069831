import { success, error } from "redux-saga-requests";
import { FETCH_CHANGE, FETCH_COMPARE_CHANGE, CLEAR_COMPARE_CHANGE, SET_PRISTINE_CHANGE } from "./actions";
import { RESET_ERROR } from "store/error/actions";

import { STATE_STATUSES } from "utils/statuses";

const initialData = {
  byRetailer: {
    priceChart: {
      data: [],
      names: [],
    },
    topMovers: {
      topMoversBase: [],
      topMoversPromoted: [],
      topMoversShelf: [],
    },
  },
  byBrand: {
    priceChart: {
      data: [],
      names: [],
    },
    topMovers: {
      topMoversBase: [],
      topMoversPromoted: [],
      topMoversShelf: [],
    },
  },
  byCategory: {
    priceChart: {
      data: [],
      names: [],
    },
    topMovers: {
      topMoversBase: [],
      topMoversPromoted: [],
      topMoversShelf: [],
    },
  },
  byManufacturer: {
    priceChart: {
      data: [],
      names: [],
    },
    topMovers: {
      topMoversBase: [],
      topMoversPromoted: [],
      topMoversShelf: [],
    },
  },
  byProductGroup: {
    priceChart: {
      data: [],
      names: [],
    },
    topMovers: {
      topMoversBase: [],
      topMoversPromoted: [],
      topMoversShelf: [],
    },
  },
};

const initialState = {
  currentData: initialData,
  compareData: initialData,
  status: STATE_STATUSES.INIT,
  isPristine: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CHANGE:
    case FETCH_COMPARE_CHANGE: {
      return processReducer(state);
    }
    case success(FETCH_CHANGE): {
      return {
        ...state,
        currentData: action.data,
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }
    case success(FETCH_COMPARE_CHANGE): {
      return {
        ...state,
        compareData: action.data,
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }
    case error(FETCH_CHANGE):
    case error(FETCH_COMPARE_CHANGE): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case CLEAR_COMPARE_CHANGE: {
      return {
        ...state,
        compareData: initialData,
      };
    }

    case SET_PRISTINE_CHANGE: {
      return {
        ...state,
        isPristine: action.payload,
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
