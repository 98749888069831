const url = process.env.REACT_APP_URL;

export const FETCH_RATING_SUMMARY_PRODUCT = "FETCH_RATING_SUMMARY_PRODUCT";
export const fetchRatingSummaryProduct = (params) => ({
  type: FETCH_RATING_SUMMARY_PRODUCT,

  request: {
    url: `${url}/v2/rating/product/${params.id}`,
    method: "POST",
  },
});


export const FETCH_RATING_SUMMARY_CORE_RETAILERS = "FETCH_RATING_SUMMARY_CORE_RETAILERS";
export const fetchRatingSummaryCoreRetailers = (params) => ({
  type: FETCH_RATING_SUMMARY_CORE_RETAILERS,

  request: {
    url: `${url}/v2/rating/product/coreretailers`,
    method: "POST",
    data: params,
  },
});

export const FETCH_RATING_SUMMARY_REVIEWS = "FETCH_RATING_SUMMARY_REVIEWS";
export const fetchRatingSummaryReviews = (params) => ({
  type: FETCH_RATING_SUMMARY_REVIEWS,

  request: {
    url: `${url}/v2/rating/reviews`,
    method: "POST",
    data: params,
  },
});


export const FETCH_RATING_SUMMARY_REVIEWS_ALL = "FETCH_RATING_SUMMARY_REVIEWS_ALL";
export const fetchRatingSummaryReviewsAll = (params) => ({
  type: FETCH_RATING_SUMMARY_REVIEWS_ALL,

  request: {
    url: `${url}/v2/rating/reviews/all`,
    method: "POST",
    data: params,
  },
});
