import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { STATE_STATUSES } from "utils/statuses";

const PublicRoute = ({
  component: Component,
  loginStatus,
  mfaStatus,
  ...rest
}) => {
  const [link, setLink] = useState("");

  useEffect(() => {
    if (rest.sections.length) {
      for (let item of rest.sections) {
        if (item.status) {
          setLink(item.name);
          break;
        }
      }
    }
  }, [rest.sections]);

  return (
    <Route
      {...rest}
      render={(props) => (rest.isMfa ? <Redirect to={`/otp`} /> :
        rest.token.length && (mfaStatus === STATE_STATUSES.READY || loginStatus === STATE_STATUSES.READY) ?
          <Redirect to={`/${link}`} /> : <Component {...props} />)}
    />
  );
};

export default connect((state) => ({
  token: state.authorization.token,
  isMfa: state.authorization.isMfa,
  mfaStatus: state.authorization.mfaStatus,
  sections: state.authorization.sections,
  loginStatus: state.authorization.loginStatus,
}))(PublicRoute);
