import { success, error } from "redux-saga-requests";
import { FETCH_PRICE_COMPARISON, SET_PRISTINE_COMPARISON, FETCH_COMPARE_PRICE_COMPARISON } from "./actions";
import { RESET_ERROR } from "store/error/actions";

import { STATE_STATUSES } from "utils/statuses";

const initialState = {
  priceComparison: {
    products: [],
    retailers: [],
    total: 0,
  },
  status: STATE_STATUSES.INIT,
  isPristine: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRICE_COMPARISON:
    case FETCH_COMPARE_PRICE_COMPARISON: {
      return processReducer(state);
    }
    case success(FETCH_PRICE_COMPARISON): {
      return {
        ...state,
        priceComparison: action.data,
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }
    case success(FETCH_COMPARE_PRICE_COMPARISON): {
      return {
        ...state,

        status: STATE_STATUSES.READY,
      };
    }
    case error(FETCH_PRICE_COMPARISON):
    case error(FETCH_COMPARE_PRICE_COMPARISON): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case SET_PRISTINE_COMPARISON: {
      return {
        ...state,
        isPristine: action.payload,
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
