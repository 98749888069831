const url = process.env.REACT_APP_URL;

export const FETCH_RATING_SUMMARY_BOTTOM = "FETCH_RATING_SUMMARY_BOTTOM";
export const fetchRatingSummaryBottom = (params) => ({
  type: FETCH_RATING_SUMMARY_BOTTOM,
  request: {
    url: `${url}/v2/rating/products`,
    method: "POST",
    data: params,
  },
});

export const SET_RATING_SUMMARY_FILTER_BOTTOM = "SET_RATING_SUMMARY_FILTER_BOTTOM";
export const setRatingSummaryFilterBottom = (filter) => {
  return {
    type: SET_RATING_SUMMARY_FILTER_BOTTOM,
    payload: filter,
  };
};

export const SET_PRISTINE_RATING_SUMMARY_TABLE = "SET_PRISTINE_RATING_SUMMARY_TABLE";
export const setPristineRatingSummaryTable = (pristine) => {
  return {
    type: SET_PRISTINE_RATING_SUMMARY_TABLE,
    payload: pristine,
  };
};
