import React from "react";

import { Styles } from "./styles";

const PopupMultiContent = ({
  children,
  moreData,
  isLastEl,
  isFirstEl,
  nextPopup,
  prevPopup,
}) => {
  return (
    <Styles>
      <div>{children}</div>
      {isFirstEl ? null : (
        <div className="btn-left" onClick={() => prevPopup()}>
          &#8592;
        </div>
      )}
      {!isLastEl && moreData ? (
        <div className="btn-right" onClick={() => nextPopup()}>
          &#8594;
        </div>
      ) : null}
    </Styles>
  );
};

export default PopupMultiContent;
