import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import ListAlert from "components/Alerts/ListAlert";
import MainFilters from "components/Filters/MainFilters";
import NavBar from "components/NavBar";
import LoaderBox from "components/LoaderBox";
import { STATE_STATUSES } from "utils/statuses";
import { getAllAlerts } from "store/alerts/actions";

const Alerts = (props) => {
  const { isPristine, filtersStatus } = props;

  const dispatch = useDispatch();

  const [activeTabTop, setActiveTabTop] = useState(1);

  const getStoreTop = () => [isPristine][activeTabTop - 1];
  const getActionTop = () => ["SET_PRISTINE_ALERTS"][activeTabTop - 1];
  const getPristineTop = () => (activeTabTop > 1 ? false : true);

  useEffect(() => {
    return () => {
      dispatch({ type: "RESET_ALERT_FILTERS" });
      dispatch({ type: getActionTop(), payload: getPristineTop() });
    };
  }, []);

  useEffect(() => {
    if (filtersStatus === STATE_STATUSES.PENDING) {
      dispatch({ type: getActionTop(), payload: true });
    }
    if (filtersStatus === STATE_STATUSES.READY) {
      dispatch(getAllAlerts());
    }
  }, [filtersStatus, getActionTop, dispatch]);

  return (
    <div className="main-page-wrapper">
      <NavBar />
      <div className="main-page-box">
        <MainFilters />
        <ListAlert />
      </div>
      {getStoreTop() || filtersStatus === STATE_STATUSES.PENDING ? <LoaderBox isOpacity={true} /> : null}
    </div>
  );
};

export default connect(
  (state) => ({
    isPristine: state.alerts.isPristine,

    filtersStatus: state.filters.status,
  }),
  null
)(Alerts);
