import React, { useState, useEffect, useMemo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import _ from "lodash";

import ChartLegends from "components/ChartLegends";
import RenderNoData from "components/RenderNoData";

import { roundingNumbers } from "utils/roundingNumbers";
import { changeNameRetailer } from "utils/changeNameRetailer";
import { STATE_STATUSES } from "utils/statuses";
import useCountry, { NUMBER_TYPE } from "../../hooks/useCountry";

const MAX_ITEMS = 10;

const ChartMultiLine = ({ title, ...props }) => {
  const {
    currentData,
    compareData,
    priceParam,
    type,
    isCompare,
    names,
    handleSetNames,
    autoSetNames,
    manyData,
    setManyData,
    status,
  } = props;
  const { formatCurrencyNumber } = useCountry();

  const [dataChart, setDataChart] = useState([]);
  const [legends, setLegends] = useState([]);

  const [currentDates, setCurrentDates] = useState([]);
  const [compareDates, setCompareDates] = useState([]);

  useEffect(() => {
    for (const chart of Highcharts.charts) {
      if (chart) {
        chart.reflow();
      }
    }
  }, [isCompare]);

  const getData = (typeData, array, dashStyle) => {
    const arrayData = array.filter((item) => names[type].list.includes(item.name));

    return arrayData.map((item) => ({
      typeData,
      name: item.name,
      color: item.color,
      dashStyle,
      data: item.values.map((item) => Number(item[priceParam])),
    }));
  };

  const getDates = (data, method) => {
    if (data.length) {
      const dates = data[0].values.map((item) => item.date);
      method(dates);
    }
  };

  useEffect(() => {
    getDates(currentData, setCurrentDates);
  }, [currentData]);

  useEffect(() => {
    getDates(compareData, setCompareDates);
  }, [compareData]);

  useEffect(() => {
    const legends = currentData.map((item) => ({
      name: item.name,
      color: item.color,
      y: Number(item[priceParam]),
      id: item.id,
    }));
    setLegends(legends);

    if (MAX_ITEMS > currentData.length && names[type].pristine) {
      const names = currentData.map((item) => item.name);
      autoSetNames(type, names);
      setManyData(false);
    } else if (MAX_ITEMS < currentData.length && names[type].pristine) {
      autoSetNames(type, []);
      setManyData(true);
    }
  }, [currentData]);

  useEffect(() => {
    if (isCompare) {
      const dataCurrent = getData("current", currentData, "line");
      const dataCompare = getData("compare", compareData, "dash");

      const data = [...dataCurrent, ...dataCompare];
      setDataChart(data);
    } else {
      const data = getData("current", currentData, "line");
      setDataChart(data);
    }
  }, [currentData, compareData, priceParam, isCompare, names, priceParam]);

  const getPricings = useMemo(() => _.cloneDeep(dataChart).sort(), [dataChart]);

  const options = {
    title: "",
    chart: {
      type: "areaspline",
      reflow: true,
      height: 300,
      style: {
        fontFamily: "Gilroy-Medium",
      },
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
      areaspline: {
        fillColor: 0,
        marker: {
          enabled: false,
        },
        lineWidth: 3,
        threshold: null,
      },
    },
    xAxis: {
      crosshair: {
        width: 1,
        color: "gray",
        dashStyle: "solid",
      },
      type: "datetime",
      labels: {
        step: parseInt(currentDates.length / 3),
        style: {
          fontSize: "10px",
        },
      },
      categories: currentDates,
    },
    yAxis: {
      title: {
        text: "",
      },
      minTickInterval: 0.1,
      labels: {
        formatter: function() {
          if(title==="Average Share") {
            return `${formatCurrencyNumber(roundingNumbers(this.value))} %`
          }
          return `${formatCurrencyNumber(this.value, NUMBER_TYPE.CURRENCY)}`
        },
        style: {
          fontSize: "10px",
        },
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: null,
      borderWidth: 0,
      zIndex: 999,
      outside: true,
      formatter: function () {
        let initialData = this.points
          .sort((a, b) => b.y - a.y)
          .map((item) => {
            if (item.series.userOptions.typeData === "current" && title !== "Average Share") {
              return `<div class='wrapper'>
                                ${
                                  !isCompare
                                    ? `
                                        <div class='box'>
                                            <div class='color' style='background: ${item.color}' ></div>
                                            <div class='name'>${changeNameRetailer(item.series.options.name)}</div>
                                        </div>
                                    `
                                    : ""
                                }
                                <div class='price' style='margin-bottom: 2.2px'>${formatCurrencyNumber(roundingNumbers(item.y), NUMBER_TYPE.CURRENCY)}</div>
                            </div>`;
            } else {
              return `<div class='wrapper'>
                                ${
                                  !isCompare
                                    ? `
                                        <div class='box'>
                                            <div class='color' style='background: ${item.color}' ></div>
                                            <div class='name'>${changeNameRetailer(item.series.options.name)}</div>
                                        </div>
                                    `
                                    : ""
                                }
                                <div class='price' style='margin-bottom: 2.2px'>${formatCurrencyNumber(roundingNumbers(item.y))} %</div>
                            </div>`;
            }
          });
        initialData = initialData.join("");

        let dataCompare = this.points
          .sort((a, b) => b.y - a.y)
          .map((item) => {
            if (item.series.userOptions.typeData === "compare") {
              return `<div class='wrapper'>
                                <div class='box'>
                                    <div class='color' style='background: ${item.color}'></div>
                                    <div class='name'>${changeNameRetailer(item.series.options.name)}</div>
                                </div>
                                <div class='price'>${formatCurrencyNumber(roundingNumbers(item.y),NUMBER_TYPE.CURRENCY)}</div>
                            </div>`;
            }
          });
        dataCompare = dataCompare.join("");

        return `<div class='wrapper-category'>
                        <div class='title'>${title}</div>
                        <div class='wrapper-box'>
                        ${
                          isCompare
                            ? `
                            <div>
                                <div class='date'>${moment(
                                  compareDates[this.points[this.points.length - 1].point.index]
                                ).format("DD MMM YY")}</div>
                                <div>
                                    ${dataCompare}
                                </div>
                            </div>
                            <div class='line'></div>
                          
                            `
                            : ""
                        }
                        <div>
                            <div class='date'>${moment(this.points[0].key).format("DD MMM YY")}</div>
                            <div>
                                ${initialData}
                            </div>
                        </div>
                       
                        </div>
                    </div>`;
      },
    },
    legend: {
      enabled: false,
    },
    series: getPricings,
  };

  return (
    <>
      <div className="wrapper-chart-box">
        {!manyData && !dataChart.length && status !== STATE_STATUSES.PENDING ? (
          <RenderNoData style={{ position: "absolute", height: "96%", zIndex: "1" }} />
        ) : null}
        {manyData && status !== STATE_STATUSES.PENDING ? (
          <div className="chart-no-data opacity">
            Looks like we have a lot of data. <br /> Select the buttons below to see the graph.{" "}
          </div>
        ) : null}
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
      <ChartLegends
        param={"name"}
        sortByRetailer={type === "byRetailer" ? true : false}
        legends={legends}
        selectedLegends={names[type].list}
        legendLength={MAX_ITEMS}
        setLegends={(name) => handleSetNames(name, type)}
      />
    </>
  );
};

export default ChartMultiLine;
