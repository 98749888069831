import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_rounded from "highcharts-rounded-corners";
import _ from "lodash";

import LoaderBox from "components/LoaderBox";
import ChartLegends from "components/ChartLegends";

import { fetchSharePopup } from "store/navigation/navigationShareProducts/actions";
import { getTimePeriod } from "utils/getTimePeriod";
import { STATE_STATUSES } from "utils/statuses";

import search from "assets/images/search.svg";
import sort from "assets/images/sort.svg";
import sortIconDown from "assets/images/sort_down.svg";
import sortIconUp from "assets/images/sort_up.svg";
import useFilters from "../../../hooks/useFilters";

import { Cancel } from "assets/icons/icons";

HC_rounded(Highcharts);

const SharePopup = ({ data, taxonomyId }) => {
  const dispatch = useDispatch();

  const { lastFilter } = useFilters();
  const { status } = useSelector((state) => state.navigationShareProducts);

  const [names, setNames] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [legends, setLegends] = useState([]);
  const [mainSort, setMainSort] = useState([]);

  const [searchValue, setSearchValue] = useState("");
  const [sortType, setSortType] = useState("");
  const [sortByName, setSortByName] = useState("");
  const [sortDirection, setSortDirection] = useState(true);

  useEffect(() => {
    if (searchValue?.length || sortType?.length || sortByName?.length) {
      dispatch(
        fetchSharePopup({
          taxonomyId: taxonomyId,
          product: lastFilter.product,
          timePeriod: getTimePeriod(lastFilter.date),
          productName: searchValue,
          productNameOrder: sortByName,
          sortBy: sortType,
          page: "1",
          limit: "100000",
        })
      );
    }
  }, [searchValue, sortType, sortByName]);

  useEffect(() => {
    if (data.brands.length) {
      const chartData = data.brands.map((item) => ({
        name: item.title,
        color: item.color,
        y: item.productsCount,
      }));

      const legends = data.brands.map((item) => ({
        name: item.title,
        color: item.color,
        y: item.productsCount,
      }));

      const names = data.brands.map((item) => item.title);
      const mainSort = legends.sort((a, b) => b.y - a.y).map((item) => item.name);

      setChartData(chartData);
      setLegends(legends);
      setNames(names);
      setMainSort(mainSort);
    }
  }, [data]);

  const handleClearCharts = () => {
    setNames([]);
    setChartData([]);
  };

  const handleSearch = (e) => setSearchValue(e.target.value);

  const handleSortByName = () => {
    if (sortByName === "asc") {
      setSortByName("desc");
    } else {
      setSortByName("asc");
    }
    setSortType("");
  };

  const handleSort = (type) => {
    let typeSort;
    if (sortType === type) {
      setSortDirection(!sortDirection);
      typeSort = `${type},${sortDirection ? "asc" : "desc"}`;
    } else {
      setSortDirection(!sortDirection);
      typeSort = `${type},${sortDirection ? "asc" : "desc"}`;
    }
    setSortType(typeSort);
    setSortByName("");
  };

  const handleChangeLable = (name) => {
    if (names.includes(name)) {
      setNames((prevState) => prevState.filter((item) => item !== name));
      setChartData((prevState) => prevState.filter((item) => item.name !== name));
    } else {
      setNames((prevState) => [...prevState, name]);

      const item = data.brands.find((item) => item.title === name);

      setChartData((prevState) => [...prevState, { name: item.title, color: item.color, y: item.productsCount }]);
    }
  };

  const options = {
    title: "",
    chart: {
      type: "column",
      reflow: true,
      height: 200,
      style: {
        fontFamily: "Gilroy-Medium",
      },
    },
    plotOptions: {
      series: {
        animation: false,
        stacking: "normal",
        borderWidth: 0,
        groupPadding: 0.2,
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    yAxis: {
      tickInterval: 10,
      title: {
        offset: 15,
        x: 25,
        text: "No. of Products",
      },
      offset: 30,
      labels: { style: { fontSize: "10px" } },
      style: {
        fontFamily: "Gilroy-Medium",
        fontSize: "10px",
      },
    },
    xAxis: {
      visible: false,
    },
    tooltip: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        borderRadiusTopLeft: "10%",
        borderRadiusTopRight: "10%",
        data: _.sortBy(chartData, (item) => mainSort.indexOf(item.name)),
      },
    ],
  };

  return (
    <div className="wrapper-sharw-popup">
      <div className="wrapper-share-chart">
        {data.brands.length ? (
          <>
            <div className="current-retailer brands" onClick={handleClearCharts}>
              <span>{chartData.length} Brands</span>
              <Cancel fill="#fff" />
            </div>
            <HighchartsReact highcharts={Highcharts} options={options} />
            <ChartLegends
              sortByY={true}
              param={"name"}
              legends={legends}
              selectedLegends={names}
              legendLength={10}
              setLegends={handleChangeLable}
            />
          </>
        ) : (
          <div className="chart-no-data static">Looks like we don't have data for this request</div>
        )}
        {status !== STATE_STATUSES.READY ? <LoaderBox /> : null}
      </div>
      <div className="wrapper-share-table">
        <div className="header-share-table">
          <div className="search-share-table">
            <div className="search-location">
              <img src={search} alt="search" />
              <input onChange={handleSearch} value={searchValue} placeholder="Search for a product" />
            </div>
            <img
              src={sortByName.length ? (sortByName === "asc" ? sortIconUp : sortIconDown) : sort}
              alt="sort"
              onClick={handleSortByName}
            />
          </div>
          <div className="title-share-table" onClick={() => handleSort("manufacturer")}>
            <span>Manufacturer</span>
            <img
              src={sortType.split(",")[0] === "manufacturer" ? (sortDirection ? sortIconDown : sortIconUp) : sort}
              alt="sort"
            />
          </div>
          <div className="title-share-table" onClick={() => handleSort("brand")}>
            <span>Brand</span>
            <img
              src={sortType.split(",")[0] === "brand" ? (sortDirection ? sortIconDown : sortIconUp) : sort}
              alt="sort"
            />
          </div>
          <div className="value-share-table" onClick={() => handleSort("highest")}>
            <span>Highest Rank Position</span>
            <img
              src={sortType.split(",")[0] === "highest" ? (sortDirection ? sortIconDown : sortIconUp) : sort}
              alt="sort"
            />
          </div>
          <div className="value-share-table" onClick={() => handleSort("lowest")}>
            <span>Lowest Rank Position</span>
            <img
              src={sortType.split(",")[0] === "lowest" ? (sortDirection ? sortIconDown : sortIconUp) : sort}
              alt="sort"
            />
          </div>
        </div>
        <div className="wrapper-body-share-table">
          <div className="body-share-table">
            {data.products.length ? (
              data.products.map((item, index) => (
                <div key={index} className="item-share-table">
                  <div className="item-share-title">
                    <img src={item.image} alt="product" />
                    <span>{item.title}</span>
                  </div>
                  <div className="item-share-param border">
                    <span className="color-box" style={{ background: item.manufacturer.color }}></span>
                    <span>{item.manufacturer.name}</span>
                  </div>
                  <div className="item-share-param">
                    <span className="color-box" style={{ background: item.manufacturer.color }}></span>
                    <span>{item.brand.name}</span>
                  </div>
                  <div className="item-share-position border">{item.highestRank}</div>
                  <div className="item-share-position">{item.lowestRank}</div>
                </div>
              ))
            ) : (
              <div className="chart-no-data static">Looks like we don't have data for this request</div>
            )}
          </div>
          {status !== STATE_STATUSES.READY ? <LoaderBox /> : null}
        </div>
      </div>
    </div>
  );
};

export default SharePopup;
