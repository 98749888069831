import React, { useEffect, useState } from "react";
import { Select, Checkbox } from "antd";
import styled from "styled-components";
import { ArrowSelect } from "assets/icons/icons";

const Styles = styled.div`
  margin-left: 15px;
  font-family: "Gilroy-Medium";

  .ant-select.ant-select-multiple {
    .ant-select-selector {
      min-width: 140px;
      width: auto;
      background: #eef2f7;
      border: none;
      height: 44px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      .ant-checkbox.ant-checkbox-checked,
      .ant-select-selection-item-remove {
        display: none;
      }
      .ant-select-selection-item {
        background: inherit;
        border: none !important;
      }
    }
    .ant-select-selection-placeholder {
      opacity: 1 !important;
      color: rgba(0, 0, 0, 0.85);
    }
  }
`;

const SelectBox = (props) => {
  const { Option } = Select;

  const { data, setSelectValue, disabled, predefinedValues } = props;

  const [values, setValues] = useState([]);

  const handleChange = (value) => {
    const lastValue = value[value.length - 1];
    const allValue = "All";
    const zeroValue = "0";

    if (lastValue === allValue) {
      setValues([allValue]);
      setSelectValue({ [data.name]: [allValue] });
    } else if (lastValue === zeroValue) {
      setValues([zeroValue]);
      setSelectValue({ [data.name]: [zeroValue] });
    } else {
      const filteredValues = value.filter((val) => val !== allValue && val !== zeroValue);
      setValues(filteredValues);
      setSelectValue({ [data.name]: filteredValues });
    }
  };

  useEffect(() => {
    if (predefinedValues?.length) {
      setValues(predefinedValues);
      setSelectValue({ [data.name]: predefinedValues });
    }
  }, [predefinedValues]);

  return (
    <Styles>
      <Select
        suffixIcon={<ArrowSelect />}
        disabled={disabled}
        name={data.name}
        showSearch={false}
        mode="multiple"
        placeholder={data.placeholder}
        onChange={handleChange}
        showArrow={true}
        value={values}
        defaultValue={predefinedValues ? predefinedValues : []}
        dropdownMatchSelectWidth={false}
        getPopupContainer={(trigger) => trigger.parentElement}
      >
        {data.options.map((item, index) => {
          return (
            <Option value={item.value} key={index}>
              <Checkbox checked={values.includes(item.value)} style={{ pointerEvents: "none" }}>
                {item.lable}
              </Checkbox>
            </Option>
          );
        })}
      </Select>
    </Styles>
  );
};

export default SelectBox;
