import { COLORS } from "assets/colors/colors";
import styled from "styled-components";

export const Styles = styled.div`
  .current-table-box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
  }
  .wrapper-table-box {
    width: 100%;
    overflow-x: auto;
    display: inline-block;
    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #abaaba;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #807f97;
    }
  }

  .box-wrapper {
    width: 100%;
    display: flex;
  }

  .wrapper {
    width: 100%;
    display: flex;

    .table-item {
      padding: 16px 10px;
      cursor: default;
      min-width: 115px;
      height: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: 1px solid #dfdfea;
      border-right: none;
      img {
        width: 25px;
      }
      & > span {
        font-family: "Gilroy-Medium";
        font-size: 14px;
      }
    }
    & > :first-child {
      justify-content: flex-start;
      padding: 16px 0;
      width: 170px;
      border-left: none;
    }
    & > :nth-child(n + 3) {
      width: 140px;
      justify-content: flex-start;
    }

    & > :nth-child(n + 4) {
      width: 233px;
      align-items: flex-start;
      justify-content: center;
      text-align: center;
    }
    & > :last-child {
      flex-grow: 1;
      justify-content: flex-start;
      text-align: left;
    }
  }
  .table-item.sort {
    flex-direction: row;
    border: none;
    cursor: pointer;
    align-items: center;
    & > span {
      font-family: "Gilroy-Medium";
      font-size: 12px;
      text-align: center;
      color: ${COLORS.darkGray};
      font-weight: bold;
    }
  }

  .no-reviews {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    background-color: ${COLORS.lightGrayOpacity};
  }
`;
