import React from "react";

//Components
import AvailabilitySummary from "./AvailabilitySummary";
//Assets
import { TabWithIcon } from "assets/icons/icons";
import about from "assets/images/about.svg";

const AvailabilityTopTab = () => {
  return (
    <div className="main-box">
      <div className="tab-wrapper">
        <TabWithIcon
          tabName={"Summary"}
          icon={about}
          tooltip={
            "Overall the prices moved up 20% based on the selected filters."
          }
        />
      </div>
      <AvailabilitySummary />
    </div>
  );
};

export default AvailabilityTopTab;
