import { success, error } from "redux-saga-requests";
import { LOG_IN, AUTH_ME, FORGOT_PASSWORD, SET_RANKING_ORDER, SET_RETAILERS_ORDER, RE_LOGIN, LOGOUT, LOGOUT_END, OTP_END, PREPARE_LOGOUT, SET_LOGIN_STATUS, SET_MFA_STATUS, SUBMIT_OTP, RESEND_OTP, SET_WATCHLIST, SET_AUTH_REFRESH_STATUS, REFRESH_AUTH_ME } from "./actions";
import { FETCH_SETTINGS_YOUR_PROFILE } from "store/settings/settingsYourProfile/actions";
import { STATE_STATUSES } from "utils/statuses";
import { RESET_ERROR } from "store/error/actions";

const initialState = {
  token: localStorage.getItem("Token") ? JSON.parse(localStorage.getItem("Token")) : "",
  refreshToken: localStorage.getItem("RefreshToken") ? JSON.parse(localStorage.getItem("RefreshToken")) : "",
  lastFilter: localStorage.getItem("LastFilter") ? JSON.parse(localStorage.getItem("LastFilter")) : "",
  retailers: localStorage.getItem("Retailers") ? JSON.parse(localStorage.getItem("Retailers")) : "",
  retailersLogo: localStorage.getItem("RetailersLogo") ? JSON.parse(localStorage.getItem("RetailersLogo")) : [],
  userManufacturer: localStorage.getItem("UserManufacturer")
    ? JSON.parse(localStorage.getItem("UserManufacturer"))
    : "",
  rankingOrder: localStorage.getItem("RankingOrder") ? JSON.parse(localStorage.getItem("RankingOrder")) : "",
  retailersOrder: localStorage.getItem("RetailersOrder") ? JSON.parse(localStorage.getItem("RetailersOrder")) : "",
  sections: localStorage.getItem("Sections") ? JSON.parse(localStorage.getItem("Sections")) : [],
  watchlist: localStorage.getItem("Watchlist") ? JSON.parse(localStorage.getItem("Watchlist")) : false,
  watchlistFilter: localStorage.getItem("WatchlistFilter")
    ? JSON.parse(localStorage.getItem("WatchlistFilter"))
    : { sourceType: [], category: [], manufacture: [], productBrand: [] },
  status: STATE_STATUSES.INIT,
  error: null,
  colorTheme: "",
  logoutStatus: localStorage.getItem("logoutStatus") || STATE_STATUSES.INIT,
  lastColor: localStorage.getItem("lastColor") ? JSON.parse(localStorage.getItem("lastColor")) : null,
  prepareLogoutStatus: STATE_STATUSES.INIT,
  loginStatus: localStorage.getItem("loginStatus") ? localStorage.getItem("loginStatus"): STATE_STATUSES.INIT,
  mfaStatus: localStorage.getItem("mfaStatus") ? localStorage.getItem("mfaStatus"): STATE_STATUSES.INIT,
  refreshStatus: localStorage.getItem("refreshStatus") ? localStorage.getItem("refreshStatus"): STATE_STATUSES.INIT,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOG_IN:
    case SUBMIT_OTP:
    case RE_LOGIN:{
      return {
        ...processReducer(state),
        logoutStatus: STATE_STATUSES.INIT,
      };
    }
    case PREPARE_LOGOUT: {
      return{
        ...processReducer(state),
        prepareLogoutStatus: STATE_STATUSES.PENDING,
      };
    }
    case LOGOUT: {
      return{
        ...processReducer(state),
        logoutStatus: STATE_STATUSES.PENDING,
        prepareLogoutStatus: STATE_STATUSES.READY,
      };
    }
    case OTP_END:
    case LOGOUT_END: {
      return{
        ...processReducer(state),
        logoutStatus: STATE_STATUSES.READY,
        lastColor: localStorage.getItem("lastColor") ? JSON.parse(localStorage.getItem("lastColor")) : null,
        mfaStatus: STATE_STATUSES.INIT,
      };
    }
    case SET_LOGIN_STATUS: {
      return {
        ...state,
        loginStatus: action.payload,
      };
    }
    case SET_MFA_STATUS: {
      return {
        ...state,
        mfaStatus: action.payload,
      };
    }
    case SET_WATCHLIST: {
      return {
        ...state,
        watchlist: action.payload.watchlist,
        watchlistFilter: action.payload.watchlistFilter ? action.payload.watchlistFilter : state.watchlistFilter,
      };
    }
    case REFRESH_AUTH_ME: {
      return {
        ...state,
        lastFilter: action.payload.filter,
        refreshStatus: action.payload.isStart ? STATE_STATUSES.READY : STATE_STATUSES.PENDING,
        error: null,
      };
    }
    case SET_AUTH_REFRESH_STATUS: {
      return {
        ...state,
        refreshStatus: action.payload,
        error: null,
      };
    }

    case success(RE_LOGIN): {
      const { token, refreshToken } = action.data;
      return {
        ...state,
        token,
        refreshToken,
        status: STATE_STATUSES.READY,
      };
    }
    case success(RESEND_OTP): {
      const {
        token,
        tokenStart,
        expiresIn,
      } = action.data;
      return {
        ...state,
        token,
        tokenStart,
        expiresIn,
      }
    }
    case success(LOG_IN): {
      const {
        token,
        refreshToken,
        isMfa,
        tokenStart,
        expiresIn,
        user: {
          lastFilter,
          retailers,
          retailersLogo,
          rankingOrder,
          retailersOrder,
          company: { manufacturer, sections },
          watchlist = initialState.watchlist,
          watchlistFilter = initialState.watchlistFilter,
          countryId,
          country,
          countries,
          colorTheme,
          company,
        },
      } = action.data;
      return {
        ...state,
        token,
        isMfa,
        tokenStart,
        expiresIn,
        refreshToken,
        lastFilter,
        retailers,
        retailersLogo,
        userManufacturer: manufacturer[manufacturer.length - 1]?.id,
        rankingOrder,
        retailersOrder,
        sections,
        watchlist,
        watchlistFilter,
        status: STATE_STATUSES.READY,
        colorTheme,
        countryId,
        country,
        countries,
        company,
      };
    }
    case success(SUBMIT_OTP): {
      const {
        token,
        refreshToken,
        user: {
          lastFilter,
          retailers,
          retailersLogo,
          rankingOrder,
          retailersOrder,
          company: { manufacturer, sections },
          watchlist = initialState.watchlist,
          watchlistFilter = initialState.watchlistFilter,
          countryId,
          country,
          countries,
          colorTheme,
        },
      } = action.data;
      return {
        ...state,
        token,
        isMfa: false,
        refreshToken,
        lastFilter,
        retailers,
        retailersLogo,
        userManufacturer: manufacturer[manufacturer.length - 1]?.id,
        rankingOrder,
        retailersOrder,
        sections,
        watchlist,
        watchlistFilter,
        status: STATE_STATUSES.READY,
        colorTheme,
        countryId,
        country,
        countries,
      };
    }
    case success(LOGOUT): {
      return {
        ...state,
        token: "",
        refreshToken: "",
        lastFilter: "",
        userManufacturer: "",
        rankingOrder: "",
        retailersOrder: "",
        sections: [],
      };
    }
    case error(RE_LOGIN): {
      const error = action.error.response;
      return errorReducer(state, error);
    }
    case error(SUBMIT_OTP):
    case error(LOG_IN):
    case error(LOGOUT): {
      return errorReducer(state);
    }

    case FORGOT_PASSWORD: {
      return processReducer(state);
    }
    case success(FORGOT_PASSWORD): {
      return {
        ...state,
        status: STATE_STATUSES.READY,
      };
    }
    case error(FORGOT_PASSWORD): {
      return errorReducer(state);
    }

    case success(FETCH_SETTINGS_YOUR_PROFILE): {
      const {
        company: { sections },
      } = action.data;
      localStorage.setItem("Sections", JSON.stringify(sections));
      return {
        ...state,
        sections,
        status: STATE_STATUSES.READY,
      };
    }

    // case SET_RANKING_ORDER: {
    //     return processReducer(state)
    // }
    case success(SET_RANKING_ORDER): {
      const { rankingOrder } = action.data;
      return {
        ...state,
        rankingOrder,
      };
    }
    // case error(SET_RANKING_ORDER): {
    //     return errorReducer(state)
    // }

    case success(SET_RETAILERS_ORDER): {
      const { retailersOrder } = action.data;
      return {
        ...state,
        retailersOrder,
      };
    }

    case AUTH_ME: {
      return processReducer(state);
    }
    case success(AUTH_ME): {
      const {
        lastFilter,
        retailers,
        retailersLogo,
        rankingOrder,
        retailersOrder,
        company: { sections },
        watchlist,
        watchlistFilter,
        colorTheme,
        countryId,
        country,
        countries,
      } = action.data;
      return {
        ...state,
        lastFilter,
        retailers,
        retailersLogo,
        rankingOrder,
        retailersOrder,
        sections,
        watchlist,
        watchlistFilter,
        colorTheme,
        countryId,
        country,
        countries,
        status: STATE_STATUSES.READY,
      };
    }
    case error(AUTH_ME): {
      return errorReducer(state);
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default: {
      return state;
    }
  }
};

const errorReducer = (state, error = null) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
