import React, { useEffect, useState } from "react";
//Components
import ContentBox from "components/ContentBox";
import ContentScrollBox from "components/ContentScrollBox";
import Opportunities from "tabs/ContentTabs/ContentTopTabs/CurrentContent/CurrentTopContent/components/Opportunities";
//Utils
import { BRAND, PRODUCT, RETAILER } from "utils/currentContentHelper";
import { sortAlphabetically } from "utils/generalUtils";

const CurrentTopContent = ({ selectedFilter, setSelectedFilter, currentContent, status }) => {
  const filterSelected = Object.entries(selectedFilter).length ? true : false;
  const [data, setData] = useState({
    total: 0,
    brands: [],
    lowestProducts: [],
    opportunities: [],
    retailers: [],
  });
  const [sortArr, setSortArr] = useState([]);
  const ASC = "asc";
  const DESC = "desc";

  useEffect(() => {
    setData(currentContent);
  }, [currentContent]);

  useEffect(() => {
    if (sortArr.length) {
      sortArr.forEach((el) => {
        let result = [];
        const key = el.split("|")[0];
        if (key === BRAND) {
          if (el.includes(DESC)) {
            result = data.brands.sort((a, b) => a.score - b.score);
          } else {
            result = data.brands.sort((a, b) => b.score - a.score);
          }
          setData((prevState) => ({ ...prevState, brands: result }));
        }
        let resultProduct = [];
        if (key === PRODUCT) {
          if (el.includes(DESC)) {
            resultProduct = data.lowestProducts.sort((a, b) => a.score - b.score);
          } else {
            resultProduct = data.lowestProducts.sort((a, b) => b.score - a.score);
          }
          setData((prevState) => ({
            ...prevState,
            lowestProducts: resultProduct,
          }));
        }
      });
    }
  }, [sortArr]);

  const handleSort = (name, direction) => {
    const ifState = sortArr.map((el) => el.includes(name)).filter((el) => el === true);
    let newEl;

    if (ifState.length) {
      const indexOfEl = sortArr.findIndex((el) => el.includes(name));
      if (sortArr[indexOfEl].includes(ASC)) {
        newEl = name + "|" + DESC;
      } else {
        newEl = name + "|" + ASC;
      }

      const result = [...sortArr.slice(0, indexOfEl), newEl, ...sortArr.slice(indexOfEl + 1)];
      setSortArr(result);
    } else {
      setSortArr((prevState) => [...prevState, name + "|" + direction]);
    }
  };

  return (
    <div className="content-wrapper">
      <div className="top-content">
        <div className="row">
          <ContentBox header={"Retailer Content Performance"} isOpacity={filterSelected} data={data.retailers} status={status}>
            <ContentScrollBox data={data.retailers} type={RETAILER} />
          </ContentBox>
          <ContentBox
            header={"Brand Content Performance"}
            isOpacity={filterSelected && selectedFilter.type !== BRAND}
            type={BRAND}
            handleSort={handleSort}
            sortArr={sortArr}
            data={data.brands}
            status={status}
          >
            <ContentScrollBox
              data={sortAlphabetically(data.brands, "name")}
              type={BRAND}
              setSelectedFilter={setSelectedFilter}
              selectedFilter={selectedFilter}
            />
          </ContentBox>
        </div>
        <div className="row-second">
          <ContentBox
            header={"Lowest Scored Product"}
            isOpacity={filterSelected}
            type={PRODUCT}
            handleSort={handleSort}
            sortArr={sortArr}
            data={data.lowestProducts}
            status={status}
          >
            <ContentScrollBox data={data.lowestProducts} type={PRODUCT} />
          </ContentBox>
          <ContentBox
            header={"Opportunities"}
            isOpacity={filterSelected && selectedFilter.type === BRAND}
            data={data.opportunities}
            status={status}
          >
            <Opportunities data={data.opportunities} setSelectedFilter={setSelectedFilter} selectedFilter={selectedFilter} />
          </ContentBox>
        </div>
      </div>
    </div>
  );
};

export default CurrentTopContent;
