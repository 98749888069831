import styled from "styled-components";

export const Styles = styled.div`
  position: relative;
  .retailer-tab-text-container {
    & > span {
      font-size: 14px;
      font-family: "Gilroy-Medium";
    }
    & > p {
      font-size: 12px;
      margin: 30px 0 16px;
      font-family: "Gilroy-ExtraBold";
    }
  }
  .retailer-tab-retailers-container {
    height: 61px;
    width: 100%;
    display: flex;
  }
  .retailer-tab-retailers-section {
    width: 36px;
    height: 57px;
    margin-right: 4px;
    border-radius: 4px;
    border: dotted 1px #dfdfea;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .retailer-tab-retailers-section img {
    width: 24px;
    height: 24px;
  }

  .retailer-tab-retailers-section p {
    font-size: 12px;
    color: #9696a0;
    margin-bottom: 5px;
    font-family: "Gilroy-ExtraBold";
  }
  .retailer-tab-buttons {
    margin: 40px 0 0;
    & > :first-child {
      margin-right: 16px;
    }
  }
`;
