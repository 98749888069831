import { Popover, Checkbox } from "antd";
import React from "react";
import styled from "styled-components";

const Styles = styled.div`
  .wrapper-discount {
    width: auto;
    background: #eef2f7;
    border: none;
    height: 44px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin-left: 15px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    font-family: "Gilroy-Medium";
    position: absolute;
    top: -64px;
    right: 0;
  }
`;

const ShowAll = ({ icon, onChangeHandler, value, disabled, text }) => {

  return (
    <Styles>
      <div className="wrapper-discount">       
      <Checkbox checked={value} onChange={() => onChangeHandler({ showAll: !value })} disabled={disabled}>
          {text ? text : "Show All Reviews"}
        </Checkbox>
      </div>
    </Styles>
  );
};

export default ShowAll;
