import React from "react";
import useColorTheme from "../../../../hooks/useColorTheme";

import {
  Assortment,
  Availability,
  Media,
  Pricing,
  Promotions,
  Rating,
  Content,
  Navigation,
  Ranking,
} from "assets/icons/icons";
//import { COLORS } from "assets/colors/colors";

const OrderSingleItem = (props) => {
  const { primaryColor } = useColorTheme();

  const titleToImage = {
    Pricing: Pricing,
    Promotions: Promotions,
    "Ranking Search Terms": Ranking,
    "Ranking Locations": Ranking,
    Content: Content,
    "Ratings & Reviews": Rating,
    Media: Media,
    Navigation: Navigation,
    Availability: Availability,
    Assortment: Assortment,
  };

  const Icon = titleToImage[props.box.title];

  return (
    <div
      className="retailer-box"
      draggable={props.draggable}
      onDragStart={props.onDragStart({ id: props.box.id })}
      onDragOver={props.onDragOver({ id: props.box.id })}
      onDrop={props.onDrop({ id: props.box.id })}
    >
      <div className="retailer-name">
        <Icon fill={primaryColor} />
        <span>{props.index + 1}</span>
      </div>
    </div>
  );
};

export default OrderSingleItem;
