import React, { useRef, useState, useEffect, useMemo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_rounded from "highcharts-rounded-corners";
import { Slider } from "antd";
import { useDispatch } from "react-redux";
//Components
import RenderNoData from "components/RenderNoData";
//Actions
import { addToDataMedia } from "store/media/mediaSummaryBottom/actions";

//Utils
import { formatDateToTimeStamp, formatToDate, formatToPeriodObj } from "utils/calendarPromotionsHelper";

//Assets
import { COLORS } from "assets/colors/colors";
import { firstCharToUpperCase } from "utils/generalUtils";
import moment from "moment";

HC_rounded(Highcharts);

const MediaSummaryCalendar = ({
  data,
  controlData,
  clickedArray,
  setClickedArray,
  currentChart,
  maxMinYValue,
  maxMinSliderValue,
  sliderValue,
  onSliderChange,
  setIsRedraw,
  desiredArr,
  filter,
  isEmpty,
  sliderValueHandler,
  fetchBanner,
}) => {
  //Refs
  const inputRef = useRef(null);
  //Const
  const dispatch = useDispatch();
  const RETAILERS = "retailers";
  const BRANDS = "brands";
  const BANNERS = "banners";
  const isShowAll = currentChart[0].value;
  const sevenDaysInterval = 24 * 3600 * 1000 * 7;
  const oneDayInterval = 24 * 3600 * 1000;
  const interval = maxMinYValue.max - maxMinYValue.min >= sevenDaysInterval ? sevenDaysInterval : oneDayInterval;

  //States
  const [pricings, setPricings] = useState([]);
  const [retailersLabel, setRetailersLabel] = useState([]);
  const [options, setOptions] = useState({});

  //Converting data
  useEffect(() => {
    let filteredData = [];
    if (desiredArr.length) {
      filteredData = data.result.filter(({ label }) => desiredArr.some((el) => el === label));
    } else {
      filteredData = data.result;
    }

    if (filteredData.length) {
      let retailers = [];
      for (let index = 0; index < filteredData.length; index++) {
        const element = filteredData[index];
        let brands = [];
        for (let index = 0; index < element.children.length; index++) {
          const el = element.children[index];

          let children = [];
          for (let index = 0; index < el.children.length; index++) {
            const child = el.children[index];

            if (filter[1].value === child.label || filter[1].value === "All") {
              children.push(child);
            }
          }
          if (children.length) {
            brands.push({ ...el, children });
          }
        }

        if (brands.length) {
          retailers.push({ ...element, children: brands });
        } else if (!brands.length && isShowAll) {
          retailers.push({ ...element });
        }
      }

      //Set count of  opened labels to calculate charts height
      const retailersLabel = retailers
        .map(({ label, id, children, color }) => {
          if (children.length) {
            return { name: label, id, color };
          } else if (!children.length && isShowAll) {
            return { name: label, id, color };
          } else return null;
        })
        .filter((el) => el !== null);

      setRetailersLabel(retailersLabel);
      if (clickedArray.length) {
        //If clicked on plus btn put new item in order
        for (let index = 0; index < clickedArray.length; index++) {
          const indexOfMatchedEl = retailersLabel.findIndex(({ id }) => id === clickedArray[index].id);
          const labels = clickedArray[index].children.map(({ name, id }) => ({
            name,
            id,
          }));

          for (let i = 0; i < labels?.length; i++) {
            retailersLabel.splice(indexOfMatchedEl + i + 1, 0, labels[i]);
          }
        }
      }
      //Function to create data for chart

      const convertToDays = (data, retailer) => {
        let resultArr = [];
        data.forEach((el, i) => {
          const opacity =
            el.banner && Object.entries(el.banner)
              ? 0.5
              : el.name === "Locations" || el.name === "Search Terms"
              ? 0.7
              : 1;

          const days = Object.entries(el.days);
          getArrOfDaysValues(days, [], el.id, resultArr, el, opacity, retailer);
        });
        return resultArr.flat();
      };

      const convertToPricing = (arr) => {
        let result = [];
        arr.forEach((el) => {
          for (let index = 0; index < el.days.length; index++) {
            const day = el.days[index];
            const x = retailersLabel.findIndex((item) => item.id === el.id);
            const { name, color, id, opacity, retailer, banner } = el;

            if (x > -1) {
              result = [
                ...result,
                {
                  low: formatDateToTimeStamp(day[0][0][0]),
                  high: formatDateToTimeStamp(day[0][day[0].length - 1][0]),
                  x,
                  name,
                  color,
                  id,
                  opacity,
                  retailer,
                  banner,
                },
              ];
            }
          }
        });
        return result;
      };

      const res = convertToDays(retailers);
      const pr = convertToPricing(res);

      setPricings([
        {
          data: pr,
          borderRadius: "4px",
          name: "AllRetailers",
        },
      ]);

      //Set data to chart in desired order
      if (clickedArray.length) {
        for (let index = 0; index < clickedArray.length; index++) {
          const res = convertToDays(clickedArray[index].children, clickedArray[index].retailer);
          const pr = convertToPricing(res);

          setPricings((prevState) => [...prevState, { data: pr, borderRadius: "4px" }]);
        }
      }
    } else {
      setPricings([]);
    }
  }, [data, clickedArray, desiredArr, filter, isShowAll]);
  //Get new arr of true dates
  const getArrOfDaysValues = (arr, desiredArr, id, resultArr, element, opacity, retailer) => {
    let arrNew = [];
    const firstTrueEl = arr.find((el) => el[1] === true);

    if (firstTrueEl?.length) {
      const indexOfFirstTrueEl = arr.indexOf(firstTrueEl);

      const firstFalseEl = arr.find((el, i) => el[1] === false && i > indexOfFirstTrueEl);

      const indexOfFirstFalseEl = arr.indexOf(firstFalseEl);

      const newArr = arr.slice(indexOfFirstTrueEl, indexOfFirstFalseEl > -1 ? indexOfFirstFalseEl : arr.length);

      const matchedElIndex = desiredArr.findIndex((el) => el.id === id);

      if (matchedElIndex > -1) {
        arrNew = [
          ...desiredArr.slice(0, matchedElIndex),
          {
            id,
            name: element.name,
            color: element.color,
            opacity,
            days: [...desiredArr[matchedElIndex].days, [newArr]],
            banner: element.banner,
            retailer,
          },
          ...desiredArr.slice(matchedElIndex + 1),
        ];
      } else {
        arrNew = [
          ...desiredArr,
          {
            id,
            name: element.name,
            color: element.color,
            opacity,
            days: [[newArr]],
            banner: element.banner,
            retailer,
          },
        ];
      }

      const lastOfArr = arr.slice(indexOfFirstFalseEl > -1 ? indexOfFirstFalseEl : arr.length);
      getArrOfDaysValues(lastOfArr, arrNew, id, resultArr, element, opacity, retailer);
    } else {
      resultArr.push(desiredArr);
    }
  };

  //Chart options
  useEffect(() => {
    const additionalHeight = retailersLabel.length === 1 && !clickedArray.length ? 60 : 49;

    setOptions({
      chart: {
        style: {
          fontFamily: "Gilroy-Medium",
          fontSize: "10px",
          overflow: "hidden",
        },
        animation: false,
        type: "columnrange",
        inverted: true,
        height: retailersLabel.length * 48 + additionalHeight,
        marginLeft: 0,
        events: {
          load: function () {
            setIsRedraw(true);
          },
        },
      },

      title: {
        text: "",
      },

      subtitle: {
        text: "",
      },

      xAxis: {
        tickColor: "transparent",
        labels: {
          enabled: false,
        },
        lineColor: "#e9e9e9",
        startOnTick: true,
        endOnTick: true,
        tickPixelInterval: 48,
        min: 0,
        max: retailersLabel.length,
        zoomEnabled: false,
        top: retailersLabel.length === 1 && !clickedArray.length ? 28 : 34,
        tickPositions: retailersLabel.map((el, i) => i),
        lineColor: "transparent",
      },

      yAxis: {
        type: "datetime",
        opposite: true,
        showLastLabel: false,
        lineColor: COLORS.lightGray,
        lineWidth: 2,
        gridLineColor: COLORS.lightGray,

        tickPositioner: function (min, max) {
          let arr = [];
          let minVal = min;
          let interval = (max - min) / 7;

          while (minVal <= max) {
            arr.push(minVal);
            minVal += interval;
          }
          arr.push(max);

          return arr;
        },

        max: maxMinYValue.max,
        min: maxMinYValue.min,
        title: { text: "" },

        labels: {
          formatter: function () {
            return formatToDate(this.value);
          },
          style: {
            fontSize: "10px",
            whiteSpace: "nowrap",
          },
          rotation: 0,
        },
        style: {
          fontFamily: "Gilroy-Medium",
        },
      },

      tooltip: {
        useHTML: true,
        backgroundColor: null,
        borderWidth: 0,
        zIndex: 999,
        followPointer: true,
        formatter: function () {
          if (this.point.options.banner && this.point.options.banner.length) {
            return renderTooltip(this.point.options);
          } else return false;
        },
        followTouchMove: true,
      },

      plotOptions: {
        series: {
          cursor: "pointer",
          point: {
            events: {
              click: function () {
                if (this.banner?.length) {
                  const idsArr = this.banner.map(({ id }) => {
                    return id;
                  });
                  fetchBanner(idsArr);
                } else {
                  return false;
                }
              },
            },
          },

          clip: false,
          pointRange: 1,
          groupPadding: 0.47,
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
        columnrange: {
          groupping: false,
          animation: false,
          pointPadding: 0,
          pointWidth: 16,
          borderWidth: 0,

          dataLabels: {
            enabled: false,
          },
          states: {
            hover: {
              halo: null,
            },
          },
        },
      },

      legend: {
        enabled: false,
      },

      series: pricings,
    });
  }, [retailersLabel, maxMinYValue, pricings, clickedArray]);

  //filter data by clicked names or bottom filter
  useEffect(() => {
    const { min, max } = maxMinYValue;
    const cntrlData = controlData.result;

    if (cntrlData.length) {
      let retailers = [];
      for (let index = 0; index < cntrlData.length; index++) {
        const element = cntrlData[index];
        let brands = [];
        for (let index = 0; index < element.children.length; index++) {
          const el = element.children[index];
          let children = [];
          for (let index = 0; index < el.children.length; index++) {
            const child = el.children[index];
            let products = [];
            for (let index = 0; index < child.children.length; index++) {
              const product = child.children[index];
              const newDaysObj = formatToPeriodObj(product.days, min, max, "days");

              if (Object.entries(newDaysObj).length) {
                products.push({ ...product, days: newDaysObj });
              }
            }

            if (products.length) {
              const days = products.map(({ days }) => days);
              let resultObj = {};
              for (let index = 0; index < days.length; index++) {
                const element = days[index];
                for (const key in element) {
                  if (resultObj[key] === true && element[key] === false) {
                    resultObj = { ...resultObj };
                  } else {
                    resultObj = { ...resultObj, [key]: element[key] };
                  }
                }
              }

              if (filter[1].value === child.label || filter[1].value === "All") {
                children.push({
                  ...child,
                  days: resultObj,
                  children: products,
                });
              }
            }
          }

          if (children.length) {
            const days = children.map(({ days }) => days);
            let resultObj = {};
            for (let index = 0; index < days.length; index++) {
              const element = days[index];
              for (const key in element) {
                if (resultObj[key] === true && element[key] === false) {
                  resultObj = { ...resultObj };
                } else {
                  resultObj = { ...resultObj, [key]: element[key] };
                }
              }
            }

            brands.push({ ...el, days: resultObj, children: children });
          }
        }

        const days = brands.map(({ days }) => days);
        let resultObj = {};
        for (let index = 0; index < days.length; index++) {
          const element = days[index];
          for (const key in element) {
            if (resultObj[key] === true && element[key] === false) {
              resultObj = { ...resultObj };
            } else {
              resultObj = { ...resultObj, [key]: element[key] };
            }
          }
        }
        if (brands.length) {
          retailers.push({ ...element, days: resultObj, children: brands });
        } else if (!brands.length && isShowAll) {
          retailers.push({ ...element });
        }
      }

      dispatch(addToDataMedia({ result: retailers }));
    }
  }, [controlData, maxMinYValue, filter[1].value, isShowAll]);
  //filter clicked arr by data
  useEffect(() => {
    if (clickedArray.length && data.result.length) {
      for (let index = 0; index < clickedArray.length; index++) {
        const element = clickedArray[index];

        if (element.type === RETAILERS) {
          const retailer = data.result.find((el) => el.id === element.retailer.id);

          if (retailer?.id) {
            returnNewClickedArr(retailer.id, retailer.children, element.type, element.id, retailer, element.brand);
          } else {
            setClickedArray((prevState) => {
              const indexOfEl = prevState.findIndex((el) => el.id === element.id);
              return [...prevState.slice(0, indexOfEl), ...prevState.slice(indexOfEl + 1, prevState.length)];
            });
          }
        } else if (element.type === BRANDS) {
          const retailer = data.result.find((el) => el.id === element.retailer.id);
          if (retailer?.children.length) {
            const brand = retailer.children.find((el) => el.id === element.brand);

            if (brand?.id) {
              returnNewClickedArr(brand.id, brand.children, element.type, brand.color, retailer, element.brand);
            } else {
              setClickedArray((prevState) => {
                const indexOfEl = prevState.findIndex((el) => el.id === element.id);
                return [...prevState.slice(0, indexOfEl), ...prevState.slice(indexOfEl + 1, prevState.length)];
              });
            }
          } else {
            setClickedArray((prevState) => {
              const indexOfEl = prevState.findIndex((el) => el.id === element.id);
              return [...prevState.slice(0, indexOfEl), ...prevState.slice(indexOfEl + 1, prevState.length)];
            });
          }
        } else if (element.type === BANNERS) {
          const retailer = data.result.find((el) => el.id === element.retailer.id);

          if (retailer?.children.length) {
            const brand = retailer.children.find((el) => el.id === element.brand);

            if (brand?.children.length) {
              const product = brand.children.find((el) => el.id === element.id);

              if (product?.id) {
                returnNewClickedArr(product.id, product.children, element.type, brand.color, retailer, element.brand);
              } else {
                setClickedArray((prevState) => {
                  const indexOfEl = prevState.findIndex((el) => el.id === element.id);
                  return [...prevState.slice(0, indexOfEl), ...prevState.slice(indexOfEl + 1, prevState.length)];
                });
              }
            } else {
              setClickedArray((prevState) => {
                const indexOfEl = prevState.findIndex((el) => el.id === element.id);
                return [...prevState.slice(0, indexOfEl), ...prevState.slice(indexOfEl + 1, prevState.length)];
              });
            }
          } else {
            setClickedArray((prevState) => {
              const indexOfEl = prevState.findIndex((el) => el.id === element.id);
              return [...prevState.slice(0, indexOfEl), ...prevState.slice(indexOfEl + 1, prevState.length)];
            });
          }
        }
      }
    }
  }, [data]);

  const returnNewClickedArr = (id, children, type, colour, retailer, brand) => {
    const mapedChildren = children.map(({ label, id: childId, banners, days, color }) => {
      return {
        id: childId,
        name: label,
        days,
        banner: banners,
        color: color ? color : colour,
      };
    });

    const matchedEl = clickedArray.find((el) => el.id === id);

    setClickedArray((prevState) => {
      const indexOfEl = prevState.findIndex((el) => el.id === id);
      return [
        ...prevState.slice(0, indexOfEl),
        {
          ...matchedEl,
          children: mapedChildren,
          type,
          retailer: { id: retailer.id, label: retailer.label },
          brand,
        },
        ...prevState.slice(indexOfEl + 1, prevState.length),
      ];
    });
  };

  const renderTooltip = (options) => {
    const { retailer, banner, name } = options;
    let groupBanners = [];
    for (let index = 0; index < banner.length; index++) {
      const element = banner[index];
      const isIncluded = groupBanners.filter(({ bannerId }) => bannerId === element.bannerId).length > 0;
      if (!isIncluded) {
        groupBanners.push(element);
      }
    }

    const getTitle = (title) => {
      if (title?.length) {
        return title;
      } else return name;
    };
    if (groupBanners.length > 1) {
      let initialData = groupBanners.map((el) => {
        return tooltipContent(getTitle(el.title), retailer.label, el.image, true);
      });

      initialData = initialData.join(" ");

      return `<div class="wrapper-category-discount-cut">${initialData}</div>`;
    } else {
      return tooltipContent(getTitle(groupBanners[0].title), retailer.label, groupBanners[0].image, false);
    }
  };

  const tooltipContent = (title, retailer, image, multiple) => {
    return `<div class="tooltip-wrapper-media">
        <div class="title">
          "${title ? title : ""}" in
          ${firstCharToUpperCase(retailer)}
        </div>

        <div class="img-wrapper">
          ${image?.length ? `<img src="${image}" />` : `<span>No image</span>`}
        </div>
        <span class="show-banner">Click on bar to show banner</span>
      </div>`;
  };

  return (
    <div className="calendar" ref={inputRef}>
      <div className="slider-wrapper">
        <Slider
          max={maxMinSliderValue.max}
          min={maxMinSliderValue.min}
          range
          value={sliderValue}
          onChange={(values) => {
            onSliderChange(values);
          }}
          onAfterChange={sliderValueHandler}
          tipFormatter={(e) => formatToDate(e)}
        />
      </div>

      {isEmpty ? (
        <RenderNoData style={{ height: 300, marginTop: 43 }} />
      ) : (
        <HighchartsReact
          containerProps={{ className: "enableOverflow" }}
          ref={inputRef}
          highcharts={Highcharts}
          options={options}
          immutable={true}
        />
      )}
    </div>
  );
};

export default MediaSummaryCalendar;
