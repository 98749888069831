import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Button } from "antd";
import { fetchFilters } from "store/filters/actions";
import { requestAuthMe } from "store/authorization/actions";
import useRetailers from "../../../../hooks/useRetailers";

import search from "assets/images/search.svg";
import down from "assets/images/down.svg";

import { Styles } from "./styles";
import { Styles as FilterStyles } from "components/Filters/Styles";
import { ArrowSelect } from "assets/icons/icons";
import useFilters from "../../../../hooks/useFilters";

const SelectProducts = (props) => {
  const {
    name,
    titleParam,
    placeholder,
    data,
    activeItems,
    paramName,
    fetchFilters,
    requestAuthMe,
    selectItems,
    setSelectItems,
  } = props;
  const { lastFilter } = useFilters();

  const wrapperRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSybBrand, setIsOpenSybBrand] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [productsData, setProductsData] = useState("");
  const [indexSubbrand, setIndexSubbrand] = useState(null);
  const { getRemoteLogo } = useRetailers();

  useEffect(() => {
    if (!Array.isArray(data) && data.hasOwnProperty("companyProductGroup") && data.hasOwnProperty("userProductGroup")) {
      const products = [...data.companyProductGroup, ...data.userProductGroup];
      setProductsData(products);
    }
  }, [data]);

  const applyFilters = () => {
    setIsOpen(!isOpen);
  };

  const toggleopen = () => {
    setIsOpen(!isOpen);
  };

  const toggleOpenSubBrand = (index) => {
    if (index !== indexSubbrand) {
      setIsOpenSybBrand(true);
    } else {
      setIsOpenSybBrand(!isOpenSybBrand);
    }
    setIndexSubbrand(index);
  };

  const handleSearch = (e) => {
    const { value } = e.target;

    setSearchValue(value);
  };

  const onSearch = (data) =>
    data.filter((item) => {
      if (searchValue) {
        return item[titleParam].toLowerCase().includes(searchValue.toLowerCase());
      }
      return item;
    });

  const handleSelectItem = (id) => {
    if (Number(id)) {
      if (selectItems.includes(id.toString())) {
        const filtred = selectItems.filter((item) => item !== id.toString());
        setSelectItems(filtred);
      } else {
        setSelectItems((prevState) => [...prevState, id.toString()]);
      }
    } else {
      if (selectItems.includes(id.id.toString())) {
        let selectBrands = selectItems;

        if (id.child.length) {
          const subbrends = id.child.map((item) => item.id.toString());

          const filtredSubbrand = selectItems.filter((el) => !subbrends.includes(el));

          selectBrands = filtredSubbrand;
        }

        const filtred = selectBrands.filter((item) => item !== id.id.toString());
        setSelectItems(filtred);
      } else {
        let items = id.child.length ? id.child.map((item) => item.id.toString()) : [];

        setSelectItems((prevState) => [...new Set([...prevState, id.id.toString(), ...items])]);
      }
    }
  };

  const handleToggleSelect = () => {
    const dataPram = Array.isArray(data) ? data : productsData;

    if (selectItems.length !== dataPram.length && selectItems.length === 0) {
      const ids = [];

      dataPram.forEach((item) => {
        ids.push(item.id.toString());
        if (item.child && item.child.length) {
          item.child.forEach((item) => ids.push(item.id.toString()));
        }
      });
      setSelectItems(ids);
    } else {
      setSelectItems([]);
    }
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      if (isOpen) {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setIsOpen(false);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
    }, [ref, isOpen]);
  }
  useOutsideAlerter(wrapperRef);

  const renderMajorityFilters = () =>
    data.length &&
    onSearch(data).map((item, index) => {
      return (
        <React.Fragment key={index}>
          {name === "brands" ? (
            <div className={`wrapper-item ${item.child.length ? "haveSubbrand" : ""}`} key={index}>
              <div
                key={index}
                onClick={() => handleSelectItem(item)}
                className={`filter-item ${selectItems.includes([item.id].toString()) ? "selected-item" : ""} 

                                        ${
                                          item.child.length && selectItems.length
                                            ? `${
                                                !item.child
                                                  .map((item) => item.id.toString())
                                                  .some((brand) => selectItems.includes(brand)) &&
                                                selectItems.includes([item.id].toString())
                                                  ? "selected-some-brand"
                                                  : ""
                                              }`
                                            : ""
                                        }
                                        ${
                                          item.child.length && selectItems.length
                                            ? `${
                                                !item.child
                                                  .map((item) => item.id.toString())
                                                  .every((brand) => selectItems.includes(brand)) &&
                                                selectItems.includes([item.id].toString())
                                                  ? "selected-some-brand"
                                                  : ""
                                              }`
                                            : ""
                                        }
                                        ${
                                          item.child.length && selectItems.length
                                            ? `${
                                                item.child
                                                  .map((item) => item.id.toString())
                                                  .some((brand) => selectItems.includes(brand)) &&
                                                !selectItems.includes([item.id].toString())
                                                  ? "selected-some-brand"
                                                  : ""
                                              }`
                                            : ""
                                        }
                                    `}
              >
                <span className="color" style={{ background: `${item.color}` }}></span>
                <span className="name">{item[titleParam]}</span>
                <span className="count">({item.productsCount})</span>
              </div>
              {isOpenSybBrand && indexSubbrand === index && item.child.length
                ? item.child.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => handleSelectItem(item.id)}
                      className={`filter-item subbrand ${
                        selectItems.includes([item.id].toString()) ? "selected-item" : ""
                      }`}
                    >
                      <span className="name">{item[titleParam]}</span>
                      <span className="count">({item.productsCount})</span>
                    </div>
                  ))
                : null}
              {item.child.length ? (
                <span onClick={() => toggleOpenSubBrand(index)} className="down-box">
                  <img src={down} alt="down" />
                </span>
              ) : null}
            </div>
          ) : (
            <div
              key={index}
              onClick={() => handleSelectItem(item.id)}
              className={`filter-item ${selectItems.includes([item.id].toString()) ? "selected-item" : ""}`}
            >
              {name !== "retailers" ? (
                <>
                  <span className="color" style={{ background: `${item.color}` }}></span>
                  <span className="name">{item[titleParam]}</span>
                  {titleParam === "name" ? <span className="count">({item.productsCount})</span> : null}
                </>
              ) : (
                <>
                  <img className="img" src={getRemoteLogo(item[titleParam])} alt="retailer" />
                  <span className="name">{item[titleParam]}</span>
                </>
              )}
            </div>
          )}
        </React.Fragment>
      );
    });

  const renderMinorityFilters = () => {
    const getTitle = {
      userProductGroup: "My Custom Groups",
      companyProductGroup: "Company-Wide Custom Groups",
    };

    return (
      Object.entries(data).length !== 0 &&
      Object.keys(data).map((productItem, productIndex) => (
        <div key={productIndex}>
          <div className={`title-product ${productItem}`}>{getTitle[productItem]}</div>
          {data[productItem].length ? (
            data[productItem].map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => handleSelectItem(item.id)}
                  className={`filter-item ${selectItems.includes([item.id].toString()) ? "selected-item" : ""}`}
                >
                  <span className="color" style={{ background: `${item.color}` }}></span>
                  <span className="name">{item[titleParam]}</span>
                  <span className="count">({item.productsCount})</span>
                </div>
              );
            })
          ) : (
            <div className="no-data">No Data</div>
          )}
        </div>
      ))
    );
  };

  return (
    <Styles className="filter-wrapper" ref={wrapperRef}>
      <div className="select-product-box" onClick={toggleopen}>
        {selectItems && selectItems.length ? (
          <span className="select-count">
            {selectItems.length} {selectItems.length === 1 ? "Product Selected" : "Products Selected"}
          </span>
        ) : (
          <span className="select-placeholder">Choose Products</span>
        )}
        <ArrowSelect />
      </div>
      {isOpen ? (
        <FilterStyles style={{ padding: 0, height: 0 }}>
          <div className="filter-wrapper">
            <div className={`filter-dropdown ${titleParam === "name" ? "" : "products-box"}`} style={{ top: -6 }}>
              <div className="filter-list">
                {placeholder !== "productGroups" ? (
                  <>
                    <div className="search">
                      <img src={search} alt="search" style={{ top: "20px" }} />
                      <input onChange={handleSearch} value={searchValue} placeholder={`Search for a ${placeholder}`} />
                    </div>
                    <div className="count-items">
                      <span>
                        {onSearch(data).length} {data.length > 1 ? name : placeholder}
                      </span>
                      <span>{selectItems.length} Selected</span>
                    </div>
                  </>
                ) : null}
                {Array.isArray(data) ? renderMajorityFilters() : renderMinorityFilters()}
              </div>
              <div className="control">
                <Button onClick={handleToggleSelect} className="select">
                  {Array.isArray(data)
                    ? selectItems.length === data.length || selectItems.length > data.length
                      ? "Deselect All"
                      : selectItems.length !== data.length && selectItems.length > 0
                      ? "Reset"
                      : "Select All"
                    : selectItems.length === productsData.length || selectItems.length > productsData.length
                    ? "Deselect All"
                    : selectItems.length !== productsData.length && selectItems.length > 0
                    ? "Reset"
                    : "Select All"}
                </Button>
                <Button className="apply" onClick={applyFilters}>
                  Apply
                </Button>
              </div>
            </div>
          </div>
        </FilterStyles>
      ) : null}
    </Styles>
  );
};

export default connect(
  (state) => ({}),
  { fetchFilters, requestAuthMe }
)(SelectProducts);
