import { COLORS } from "assets/colors/colors";
import styled from "styled-components";

export const Styles = styled.div`
  margin-left: 16px;
  margin-top: 30px;
  .topMovers {
    position: relative;
    width: 402px;
    height: 400px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #abaaba;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #807f97;
    }
  }
  .filters {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 16px;
    & > span {
      font-size: 12px;
      font-family: Gilroy-ExtraBold;
      color: ${COLORS.darkGray};
      margin-left: 20px;
    }
  }

  .mover {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 10px 20px;
    justify-content: space-between;
  }
  .image {
    width: 40px;
    height: 40px;
    border-radius: 4px;
  }
  .mover-results {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    min-height: 46px;
    align-items: center;
    & > :first-child {
      font-family: Gilroy-ExtraBold;
      font-weight: bold;
    }
  }
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .title {
    font-size: 14px;
    margin-left: 20px;
    margin-right: 20px;
    text-transform: capitalize;
    font-family: Gilroy-Medium;
  }
  .mover-result {
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-family: Gilroy-Medium;
  }
  .wrapper-prod {
    position: relative;
    border-radius: 5px;
    .retailer-marcker {
      width: 18px;
      height: 18px;
      position: absolute;
      border-radius: 50%;
      right: 0;
      bottom: 0;
    }
  }
`;
