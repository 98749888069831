import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Styles } from "./Styles";
import { fetchRanking, fetchCompareRanking } from "store/pricing/priceRanking/actions";
import TopMovers from "components/TopMovers";
import CompareBox from "components/CompareBox";
import RankingList from "./RankingList";
import { STATE_STATUSES } from "utils/statuses";
import { getTimePeriod } from "utils/getTimePeriod";
import LoaderBox from "components/LoaderBox";
import useFilters from "../../../../hooks/useFilters";

const RankingTab = (props) => {
  const { status, ranking, fetchRanking, fetchCompareRanking } = props;
  const {
    setRefreshStatus,
    refreshStatus,
    statusFilters,
    lastFilter,
  } = useFilters();
  const [comparativeValue, setComparativeValue] = useState("");

  const [isLoader, setIsLoader] = useState(false);

  const [compareData, setCompareData] = useState({
    data: [],
  });
  const fetchRef = useRef();
  useEffect(() => {
    if (statusFilters !== STATE_STATUSES.READY) {
      fetchRef.current = false;
    } else if (!fetchRef.current || refreshStatus === STATE_STATUSES.PENDING) {
      fetchRef.current = true;
      setRefreshStatus(STATE_STATUSES.READY);
      fetchRanking({
        sourceType: lastFilter.sourceType,
        timePeriod: getTimePeriod(lastFilter.date),
        product: lastFilter.product,
      });
    }


  }, [fetchRanking, lastFilter, statusFilters, refreshStatus, setRefreshStatus]);

  useEffect(() => {
    if (Boolean(comparativeValue?.length)) {
      setIsLoader(true);
      fetchCompareRanking({
        sourceType: lastFilter.sourceType,
        timePeriod: comparativeValue,
        product: lastFilter.product,
      }).then((response) => {
        setCompareData(response.data);
        setIsLoader(false);
      });
    } else {
      setIsLoader(true);

      setCompareData({ data: [] });

      setTimeout(function () {
        setIsLoader(false);
      }, 200);
    }
  }, [comparativeValue]);

  return (
    <Styles>
      {ranking.data.length ? (
        <div className="filters-box">
          <CompareBox setComparativeValue={setComparativeValue} disabled={status === STATE_STATUSES.PENDING} />
        </div>
      ) : null}
      <div className="wrapper-box-relative">
        <div className="chart-wrapper-box">
          {status !== STATE_STATUSES.READY && !ranking.data.length ? null : (
            <RankingList
              initialData={ranking.data}
              compareData={compareData.data}
              totalProducts={ranking.totalProducts}
              isCompare={Boolean(comparativeValue?.length)}
              isLoader={isLoader}
            />
          )}
          {status !== STATE_STATUSES.READY && !ranking.cheapestPrice.length ? null : (
            <>
              {!comparativeValue?.length ? (
                <TopMovers
                  isSwitch={true}
                  topMovers={{ cheapestPrice: ranking.cheapestPrice, expensivePrice: ranking.expensivePrice }}
                />
              ) : null}
            </>
          )}
        </div>
        {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
      </div>
    </Styles>
  );
};

export default connect(
  (state) => ({
    ranking: state.priceRanking.ranking,
    status: state.priceRanking.status,
  }),
  { fetchRanking, fetchCompareRanking }
)(RankingTab);
