const url = process.env.REACT_APP_URL;

export const FETCH_SETTINGS_MY_GROUPS = "FETCH_SETTINGS_MY_GROUPS";
export const fetchSettingsMyGroups = () => ({
  type: FETCH_SETTINGS_MY_GROUPS,
  request: {
    url: `${url}/auth/myGroups`,
    method: "GET",
  },
  meta: {
    asPromise: true,
  },
});

export const CREATE_SETTINGS_MY_GROUP = "CREATE_SETTINGS_MY_GROUP";
export const createSettingsMyGroup = (data) => ({
  type: CREATE_SETTINGS_MY_GROUP,
  request: {
    url: `${url}/auth/create/myGroup`,
    method: "POST",
    data,
  },
  meta: {
    asPromise: true,
  },
});

export const DELETE_SETTINGS_MY_GROUP = "DELETE_SETTINGS_MY_GROUP";
export const deleteSettingsMyGroup = (id) => ({
  type: DELETE_SETTINGS_MY_GROUP,
  request: {
    url: `${url}/auth/delete/myGroup/${id}`,
    method: "DELETE",
  },
  meta: {
    id,
    asPromise: true,
  },
});

export const EDIT_SETTINGS_MY_GROUP = "EDIT_SETTINGS_MY_GROUP";
export const editSettingsMyGroup = (id, data) => ({
  type: EDIT_SETTINGS_MY_GROUP,
  request: {
    url: `${url}/auth/edit/myGroup/${id}`,
    method: "PUT",
    data,
  },
  meta: {
    asPromise: true,
  },
});
