import React from "react";

const DropdownValue = ({
  title,
  val,
  setHoveredEl,
  renderDropdown,
  isHovered,
}) => {
  return (
    <div
      onMouseEnter={() => setHoveredEl(val)}
      onMouseLeave={() => setHoveredEl()}
      className="dropdown-value"
    >
      <span>{title}</span>
      {isHovered ? renderDropdown() : null}
    </div>
  );
};

export default DropdownValue;
