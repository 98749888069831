import React, { useState, useEffect } from "react";
import useCountry, { NUMBER_TYPE } from "../../../../../hooks/useCountry";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { greaterColor, lessColor, equallyColor } from "utils/colorsCharts";
import useRetailers from "../../../../../hooks/useRetailers";
import moment from "moment";

import cup from "assets/images/cup.svg";
import star from "assets/images/star.svg";

const СurrentlyItemChart = (props) => {
  const {
    type,
    timePeriod,
    data,
    promotions,
    currentRetailer,
    searchTerm,
    sourceType,
    manufacturer,
    productId,
    actionType,
    actionProductPopup,
    isPopup,
    outside,
  } = props;

  const [dates, setDates] = useState([]);
  const [pricings, setPricings] = useState([]);
  const [topRankings, setTopRankings] = useState([]);
  const [stars, setStars] = useState([]);

  const [promotionsDate, setPromotionsDate] = useState([]);
  const { formatCurrencyNumber } = useCountry();
  const { getRetailerColorHex, getRetailerBackgroundColor } = useRetailers();

  useEffect(() => {
    if (promotions?.length) {
      const dates = [];

      promotions.forEach((promotion) =>
        promotion.promotions[0].date.forEach((item) => dates.push({ date: moment(item).format("DD MMM YYYY"), id: promotion.id }))
      );

      setPromotionsDate(dates);
    }
  }, [promotions]);

  const getMainColor = (retailer) => getRetailerColorHex(retailer);

  const getBackground = (retailer) => getRetailerBackgroundColor(retailer);

  useEffect(() => {
    if (data.length) {
      let dataChart;
      let start;
      let end;

      if (isPopup) {
        dataChart = data.map((item) => ({
          y: Number(item.value),
          max: item.max,
          min: item.min,
          marker: {
            enabled: item.max || item.min,
            lineColor: item.max ? "#1cc08a" : "#fe6a68",
            lineWidth: 2,
            fillColor: "#fff",
            radius: 4,
          },
        }));

        start = dataChart[0].y;
        end = dataChart[dataChart.length - 1].y;
      } else {
        dataChart = data.map((item) => Number(item.value)).slice(timePeriod[0], timePeriod[1] + 1);

        start = dataChart[0];
        end = dataChart[dataChart.length - 1];
      }

      const topRankings = data.map((item) => item.topRanking).slice(timePeriod[0], timePeriod[1] + 1);
      const stars = data.map((item) => item.star).slice(timePeriod[0], timePeriod[1] + 1);

      let pricings;

      if (type === "average") {
        pricings = [
          {
            name: "Average Share",
            color: getMainColor(currentRetailer),
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1,
              },
              stops: getBackground(currentRetailer),
            },
            dataLabels: {
              enabled: false,
            },
            data: dataChart,
          },
        ];
      }

      if (type === "rank") {
        pricings = [
          {
            name: "Rank Position",
            color: start === end ? "#a0a2a6" : start < end ? "#1ec68d" : "#fe6a68",
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1,
              },
              stops: start === end ? equallyColor : start < end ? greaterColor : lessColor,
            },
            dataLabels: {
              enabled: false,
            },
            data: dataChart,
          },
        ];

        if (isPopup) {
          const points = dataChart.map((item) => item.y);

          const maxValue = Math.max(...points);
          const minValue = Math.min(...points);

          const averageValue = (maxValue + minValue) / 2;

          const averageRank = {
            dataLabels: {
              enabled: true,
              align: "left",
              style: {
                fontSize: "8px",
                color: "#5d6a7d",
                fontFamily: "Gilroy-Medium",
              },
            },
            color: "#dcdce7",
            dashStyle: "dash",
            fillColor: 0,
            data: points.map((item, index) => ({ x: index, y: averageValue, label: `Average Rank: ${Math.abs(averageValue)}` })),
          };

          pricings = [...pricings, averageRank];
        }
      }

      const datesMoment = data.map((item) => moment(item.date).format("DD MMM YYYY")).slice(timePeriod[0], timePeriod[1] + 1);

      setPricings(pricings);
      setTopRankings(topRankings);
      setStars(stars);
      setDates(datesMoment);
    }
  }, [data, timePeriod]);

  const getTimePeriod = (time) => {
    return `custom|${moment(time).format("YYYY-MM-DD")}|${moment(time).format("YYYY-MM-DD")}`;
  };

  const getProductPopup = (point) => {
    actionProductPopup(productId, {
      type: actionType,
      searchTerm,
      sourceType,
      manufacturer,
      featured: true,
      timePeriod: getTimePeriod(dates[point.x]),
    });
  };

  const options = {
    title: "",
    chart: {
      type: "areaspline",
      height: 100,
      animation: false,
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        marker: {
          enabled: true,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function () {
            if (this.x == 0) return this.point.options.label;
          },
        },
        events: {
          click: function (e) {
            getProductPopup(e.point);
          },
        },
      },
      areaspline: {
        marker: {
          enabled: false,
        },
        lineWidth: 2,
        threshold: null,
      },
    },
    xAxis: {
      visible: false,
      crosshair: {
        width: 2,
        color: "#384493",
      },
      type: "line",
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      visible: false,
      title: {
        text: "",
      },
      labels: {
        enabled: false,
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: null,
      borderWidth: 0,
      hideDelay: 0,
      shadow: false,
      outside: outside,
      formatter: function () {
        const findPromotion = (index) => {
          const promoId = promotionsDate.find((item) => item.date === moment(dates[index]).format("DD MMM YYYY")).id;

          const currentPromo = promotions.find((item) => item.id === promoId);

          return currentPromo;
        };

        return `<div class="wrapper-category">
            <div class='title'>${this.points[0].series.name}</div>
            <div class='total-data'>${moment(dates[this.points[0].x]).format("DD MMM YY")}</div>
            <div class='wrapper'>
                <div class='box'>
                    <div style="padding-right: 5px">${Math.abs(this.points[0].y)}${type === "average" ? "%" : ""}</div>
                    ${topRankings[this.points[0].point.index] ? `<img src=${cup} />` : ""}
                    ${stars[this.points[0].point.index] ? `<img src=${star} />` : ""}
                </div>
            </div>
            ${this.points[0].point.min ? `<div class='lowest'>Lowest Position</div>` : ""}
            ${this.points[0].point.max ? `<div class='best'>Best Position</div>` : ""}

            ${
              promotions?.length && promotionsDate.find((item) => item.date === moment(dates[this.points[0].x]).format("DD MMM YYYY"))
                ? `
                <div class='ranking-separate-line'></div>
                <div class='title' style='margin-bottom: 5px;'>${findPromotion(this.points[0].x).promotions[0].name}</div>
                <div class='wrapper' style='margin-bottom: 5px;'>
                  <div>Promoted Price</div>
                  <div style='margin-left: 5px;'>${formatCurrencyNumber(findPromotion(this.points[0].x).promotions[0].promotedPrice, NUMBER_TYPE.CURRENCY)}</div>
                </div>
                <div class='wrapper'>
                  <div>Discount</div>
                  <div style='margin-left: 5px;'>${formatCurrencyNumber(findPromotion(this.points[0].x).promotions[0].discount, NUMBER_TYPE.DECIMAL)}%</div>
                </div>
              `
                : ""
            }
          </div>`;
      },
    },
    legend: {
      enabled: false,
    },
    series: pricings,
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default СurrentlyItemChart;
