const url = process.env.REACT_APP_URL;

export const FETCH_RETAILER_AVAILABILITY = "FETCH_RETAILER_AVAILABILITY";
export const fetchRetailerAvailability = (params) => ({
  type: FETCH_RETAILER_AVAILABILITY,
  request: {
    url: `${url}/v2/availability/table`,
    method: "POST",
    data: params,
  },
});

export const SET_PRISTINE_RETAILER_AVAILABILITY = "SET_PRISTINE_RETAILER_AVAILABILITY";
export const setPristineRetailerAvailability = (pristine) => {
  return {
    type: SET_PRISTINE_RETAILER_AVAILABILITY,
    payload: pristine,
  };
};

export const SET_RETAILER_AVAILABILITY_FILTER = "SET_RETAILER_AVAILABILITY_FILTER";
export const setRetailerAvailabilityFilter = (filter) => {
  localStorage.setItem(SET_RETAILER_AVAILABILITY_FILTER, JSON.stringify(filter));
  return {
    type: SET_RETAILER_AVAILABILITY_FILTER,
    payload: filter,
  };
};

export const ADD_TO_DATA_RETAILER_AVAILABILITY = "ADD_TO_DATA_RETAILER_AVAILABILITY";
export const addToDataRetailerAvailability = (data) => {
  return {
    type: ADD_TO_DATA_RETAILER_AVAILABILITY,
    payload: data,
  };
};

export const FETCH_RETAILER_AVAILABILITY_POPUP = "FETCH_RETAILER_AVAILABILITY_POPUP";
export const fetchRetailerAvailabilityPopup = (id, data) => ({
  type: FETCH_RETAILER_AVAILABILITY_POPUP,
  request: {
    url: `${url}/v2/availability/details/${id}`,
    method: "POST",
    data: data,
  },
});

export const RESET_RETAILER_AVAILABILITY_POPUP = "RESET_RETAILER_AVAILABILITY_POPUP";
export const resetRetailerAvailabilityPopup = () => {
  return {
    type: RESET_RETAILER_AVAILABILITY_POPUP,
  };
};
