import React, { useEffect, useState } from "react";
import { Select, Pagination } from "antd";

import sortIconDown from "assets/images/sort_down.svg";
import sortIconUp from "assets/images/sort_up.svg";
import { connect } from "react-redux";
import { fetchSettingsSubscriptions } from "store/settings/settingsSubscriptions/actions";

import { STATE_STATUSES } from "utils/statuses";

import { Styles } from "./styles";
import LoaderBox from "components/LoaderBox";
import { firstCharToUpperCase, sortAlphabetically, sortAlphabeticallyReverse } from "utils/generalUtils";
import useRetailers from "../../../hooks/useRetailers";
import ShowPerPage from "components/ShowPerPage";

const SettingsSubscriptions = (props) => {
  const { fetchSettingsSubscriptions, subscriptions, settingsStatus } = props;

  const { Option } = Select;
  const { getRemoteLogo } = useRetailers();

  const [renderData, setRenderData] = useState({
    locations: {
      data: [],
      filteredData: [],
      pagination: {
        page: null,
        perPage: null,
      },
    },
    searchTerms: {
      data: [],
      filteredData: [],
      pagination: {
        page: null,
        perPage: null,
      },
    },
  });

  const [toggleList, setToggleList] = useState({
    retailers: false,
    locations: false,
    searchTerms: false,
  });

  useEffect(() => {
    if (subscriptions.locations && subscriptions.searchTerms) {
      const locationsData = sortAlphabetically(subscriptions.locations, "name");
      const searchTermsData = sortAlphabetically(subscriptions.searchTerms, "name");

      setRenderData({
        locations: {
          data: locationsData,
          filteredData: locationsData.slice(0, 10),
          pagination: {
            page: 1,
            perPage: 10,
            sortDirection: "asc",
          },
        },
        searchTerms: {
          data: searchTermsData,
          filteredData: searchTermsData.slice(0, 10),
          pagination: {
            page: 1,
            perPage: 10,
          },
        },
      });
    }
  }, [subscriptions]);

  useEffect(() => {
    fetchSettingsSubscriptions();
  }, [fetchSettingsSubscriptions]);

  const handleToggle = (value) => {
    switch (value) {
      case "retailers":
        setToggleList({ ...toggleList, retailers: !toggleList.retailers });
        break;
      case "locations":
        setToggleList({ ...toggleList, locations: !toggleList.locations });
        break;
      case "searchTerms":
        setToggleList({ ...toggleList, searchTerms: !toggleList.searchTerms });
        break;
    }
  };

  const onChangePage = (page, section) => {
    let perPage;

    switch (section) {
      case "locations":
        perPage = renderData.locations.pagination.perPage;

        setRenderData({
          ...renderData,
          locations: {
            ...renderData.locations,
            filteredData: renderData.locations.data.slice((page - 1) * perPage, page * perPage),
            pagination: {
              ...renderData.locations.pagination,
              page: page,
            },
          },
        });
        break;
      case "searchTerms":
        perPage = renderData.searchTerms.pagination.perPage;

        setRenderData({
          ...renderData,
          searchTerms: {
            ...renderData.searchTerms,
            filteredData: renderData.searchTerms.data.slice((page - 1) * perPage, page * perPage),
            pagination: {
              ...renderData.searchTerms.pagination,
              page: page,
            },
          },
        });
        break;
    }
  };

  const handleSort = (direction) => {
    const data =
      direction === "asc"
        ? sortAlphabeticallyReverse(renderData.locations.data, "name")
        : sortAlphabetically(renderData.locations.data, "name");
    const perPage = renderData.locations.pagination.perPage;
    const page = 1;

    setRenderData({
      ...renderData,
      locations: {
        ...renderData.locations,
        data,
        filteredData: data.slice((page - 1) * perPage, page * perPage),
        pagination: {
          ...renderData.locations.pagination,
          page,
          sortDirection: direction === "asc" ? "desc" : "asc",
        },
      },
    });
  };

  const handleSelectChange = (pageSize, section) => {
    const page = 1;

    switch (section) {
      case "locations":
        setRenderData({
          ...renderData,
          locations: {
            ...renderData.locations,
            filteredData: renderData.locations.data.slice((page - 1) * pageSize, page * pageSize),
            pagination: {
              ...renderData.locations.pagination,
              perPage: pageSize,
              page,
            },
          },
        });
        break;
      case "searchTerms":
        setRenderData({
          ...renderData,
          searchTerms: {
            ...renderData.searchTerms,
            filteredData: renderData.searchTerms.data.slice((page - 1) * pageSize, page * pageSize),
            pagination: {
              ...renderData.searchTerms.pagination,
              perPage: pageSize,
              page,
            },
          },
        });
        break;
    }
  };

  return (
    <Styles>
      {settingsStatus === STATE_STATUSES.READY ? (
        <>
          <div className="text-container">
            You are subscribed to specific retailers, locations and search terms to displayed you data you need.
          </div>
          <div className="table" style={{ marginBottom: toggleList.retailers ? 30 : 0 }}>
            <div className="item-box header">
              <div
                className="item-box col-1 collapseButton"
                onClick={subscriptions.retailers.length ? () => handleToggle("retailers") : () => {}}
                style={{ cursor: !subscriptions.retailers.length ? "not-allowed" : null }}
              >
                <div className="item-text">
                  <div className="plus-btn-expand">
                    <span>{toggleList.retailers ? "-" : "+"}</span>
                  </div>
                </div>
                <div className="item-text item-group-name">
                  {subscriptions.retailers.length === 1
                    ? subscriptions.retailers.length + " retailer"
                    : subscriptions.retailers.length + " retailers"}
                </div>
              </div>
            </div>
            <div style={{ display: toggleList.retailers ? "block" : "none" }}>
              <div className="item-box-retailers">
                {subscriptions.retailers.map((item) => {
                  return (
                    <div key={item.id} className="item-retailer">
                      <img src={getRemoteLogo(item.name)} alt="product-picture" /> {firstCharToUpperCase(item.name)}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="table" style={{ marginBottom: toggleList.locations ? 30 : 0 }}>
            <div className="item-box header">
              <div
                className="item-box col-1 collapseButton"
                onClick={subscriptions.locations.length ? () => handleToggle("locations") : () => {}}
                style={{ cursor: !subscriptions.locations.length ? "not-allowed" : null }}
              >
                <div className="item-text">
                  <div className="plus-btn-expand">
                    <span>{toggleList.locations ? "-" : "+"}</span>
                  </div>
                </div>
                <div className="item-text item-group-name">
                  {subscriptions.locations.length === 1
                    ? subscriptions.locations.length + " location"
                    : subscriptions.locations.length + " locations"}
                </div>
              </div>
            </div>
            <div style={{ display: toggleList.locations ? "block" : "none" }}>
              <div className="item-box header">
                <div className="col-1">
                  <div className="item-text item-brand">
                    Location{" "}
                    <img
                      className="icon-for-sort"
                      onClick={() => handleSort(renderData.locations.pagination.sortDirection)}
                      src={renderData.locations.pagination.sortDirection === "asc" ? sortIconUp : sortIconDown}
                      alt="sort icon"
                    />
                  </div>
                </div>
                {/* <div className="col-2 item-locations">
                         <div className="item-text item-brand">Retailer <img className="icon-for-sort" src={sortIcon} alt='sort icon' /></div>
                     </div> */}
              </div>
              {renderData.locations.filteredData.map((item) => {
                return (
                  <div key={item.id} className="item-box">
                    <div className="col-1 item-product subscriptions-locatins-temp">{item.name}</div>
                  </div>
                );
              })}
              <div className="pagination-with-per-page">
                <Pagination
                  style={{ margin: "12px 0 32px 0" }}
                  className="pagination-controls"
                  onChange={(page) => onChangePage(page, "locations")}
                  current={renderData.locations.pagination.page}
                  pageSize={renderData.locations.pagination.perPage}
                  total={subscriptions.locations.length}
                  showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
                />
                <ShowPerPage
                  setLimit={(value) => handleSelectChange(value, "locations")}
                  value={renderData.locations.pagination.perPage}
                />
              </div>
            </div>
          </div>

          <div className="table" style={{ marginBottom: toggleList.searchTerms ? 30 : 0 }}>
            <div className="item-box header">
              <div
                className="item-box col-1 collapseButton"
                onClick={subscriptions.searchTerms.length ? () => handleToggle("searchTerms") : () => {}}
                style={{ cursor: !subscriptions.searchTerms.length ? "not-allowed" : null }}
              >
                <div className="item-text">
                  <div className="plus-btn-expand">
                    <span>{toggleList.searchTerms ? "-" : "+"}</span>
                  </div>
                </div>
                <div className="item-text item-group-name">
                  {subscriptions.searchTerms.length === 1
                    ? subscriptions.searchTerms.length + " Search Term"
                    : subscriptions.searchTerms.length + " Search Terms"}
                </div>
              </div>
            </div>
            <div style={{ display: toggleList.searchTerms ? "block" : "none" }}>
              <div className="item-box-retailers">
                {renderData.searchTerms.filteredData.map((item) => {
                  return (
                    <div key={item.id} className="item-retailer">
                      {item.name}
                    </div>
                  );
                })}
              </div>
              <div className="pagination-with-per-page">
                <Pagination
                  style={{ margin: "12px 0 32px 0" }}
                  className="pagination-controls"
                  onChange={(page) => onChangePage(page, "searchTerms")}
                  current={renderData.searchTerms.pagination.page}
                  pageSize={renderData.searchTerms.pagination.perPage}
                  total={subscriptions.searchTerms.length}
                  showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
                />
                <ShowPerPage
                  setLimit={(value) => handleSelectChange(value, "searchTerms")}
                  value={renderData.searchTerms.pagination.perPage}
                />
              </div>
            </div>
          </div>
        </>
      ) : null}
      {settingsStatus === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
    </Styles>
  );
};

export default connect(
  (state) => ({
    subscriptions: state.settingsSubscriptions.settingsSubscriptions,
    settingsStatus: state.settingsSubscriptions.status,
  }),
  { fetchSettingsSubscriptions }
)(SettingsSubscriptions);
