import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { fetchRankingSearchAnalysisTrend } from "store/ranking/rankingSearchAnalysis/actions";
import { getTimePeriodRanking } from "utils/getTimePeriodRanking";
import { STATE_STATUSES } from "utils/statuses";
import TrendAllDayColumn from "./TrendAllDayColumn";
import TrendSingleDay from "./TrendSingleDay";
import LoaderBox from "components/LoaderBox";
import useCache from "../../../../../hooks/useCache";
import useFilters from "../../../../../hooks/useFilters";

const СurrentlyTrend = (props) => {
  const {
    currentManufacturer,
    shareSize,
    activeTab,
    currentManufacturerId,
    fetchRankingSearchAnalysisTrend,
    rankingTrendType,
    status,
    type,
    actionSingleProduct,
    storeSingleProduct,
    actionSingleProductSummary,
    actionSingleProductSetPeriod,
    actionRemoveProduct,
    actionProductCompare,
    storeProductCompare,
    actionSingleProductCompareSummary,
    actionSingleProductSetCompare,
    statusProduct,
    rankingTrendDate,
  } = props;
  const { mainLastFilter: lastFilter, statusFilters } = useFilters();

  const [dataTrend, setDataTrend] = useState(null);
  const [isFeaturedProducts, setIsFeaturedProducts] = useState(true);
  const { cachedRankingTrendData: rankingTrendData } = useCache();

  useEffect(() => {
    setDataTrend(null);
  }, [activeTab]);

  useEffect(() => {
    if (statusFilters === STATE_STATUSES.READY) {
      fetchRankingSearchAnalysisTrend({
        type: "search",
        manufacturer: currentManufacturerId.toString(),
        sourceType: lastFilter.sourceType,
        timePeriod: getTimePeriodRanking(lastFilter.date),
        product: lastFilter.product,
        featured: isFeaturedProducts,
      });
    }
  }, [fetchRankingSearchAnalysisTrend, lastFilter, currentManufacturerId, shareSize, currentManufacturer, isFeaturedProducts, statusFilters]);

  const getRankingTop = useCallback(() => {
    const rankingTop = {
      5: "rankingTop5",
      10: "rankingTop10",
      20: "rankingTop20",
    };

    return rankingTop[shareSize];
  }, [shareSize]);

  useEffect(() => {
    if (rankingTrendData.length && currentManufacturer && activeTab) {
      if (rankingTrendType === "charts") {
        const dataTrend =
          rankingTrendData.find((manufacturer) => manufacturer.title === currentManufacturer) &&
          rankingTrendData.find((manufacturer) => manufacturer.title === currentManufacturer)[getRankingTop()].find((item) => item.title === activeTab);

        setDataTrend(dataTrend);
      } else if (rankingTrendType === "products") {
        setDataTrend(rankingTrendData);
      }
    }
  }, [rankingTrendData, currentManufacturer, activeTab, getRankingTop, rankingTrendType]);

  return (
    <div className="wrapper-box-relative">
      {status === STATE_STATUSES.READY ? (
        <>
          {rankingTrendData.length ? (
            <>
              {rankingTrendType === "charts" ? (
                !Array.isArray(dataTrend) ? (
                  <TrendAllDayColumn data={dataTrend} activeTab={activeTab} />
                ) : null
              ) : Array.isArray(dataTrend) ? (
                <TrendSingleDay
                  data={dataTrend}
                  date={rankingTrendDate}
                  activeTab={activeTab}
                  currentManufacturer={currentManufacturer}
                  type={type}
                  actionSingleProduct={actionSingleProduct}
                  storeSingleProduct={storeSingleProduct}
                  actionSingleProductSummary={actionSingleProductSummary}
                  actionSingleProductSetPeriod={actionSingleProductSetPeriod}
                  actionRemoveProduct={actionRemoveProduct}
                  actionProductCompare={actionProductCompare}
                  storeProductCompare={storeProductCompare}
                  actionSingleProductCompareSummary={actionSingleProductCompareSummary}
                  actionSingleProductSetCompare={actionSingleProductSetCompare}
                  statusProduct={statusProduct}
                  isFeaturedProducts={isFeaturedProducts}
                  setIsFeaturedProducts={setIsFeaturedProducts}
                />
              ) : null}
            </>
          ) : (
            <div style={{ marginTop: "32px" }} className="chart-no-data static">
              Looks like we don't have data for this request
            </div>
          )}
        </>
      ) : null}
      {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
    </div>
  );
};

export default connect(
  (state) => ({
    rankingTrendType: state.rankingSearchAnalysis.rankingSearchAnalysisTrend.type,
    rankingTrendDate: state.rankingSearchAnalysis.rankingSearchAnalysisTrend.date,
    status: state.rankingSearchAnalysis.chartStatus,
    lastFilter: state.authorization.lastFilter,
  }),
  { fetchRankingSearchAnalysisTrend }
)(СurrentlyTrend);
