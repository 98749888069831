import { COLORS } from "assets/colors/colors";
import styled from "styled-components";

export const Styles = styled.div`
  .table-wrapper {
    display: flex;
    margin-top: 16px;
    flex-direction: column;
    & > :first-child {
      border-top: 1px solid ${COLORS.lightGray};
    }
  }
  .row-table-benchmark {
    display: flex;
    border-bottom: 1px solid ${COLORS.lightGray};

    & > :nth-child(2) {
      border-left: 1px solid ${COLORS.lightGray};
      border-right: 1px solid ${COLORS.lightGray};
    }
  }
  .static {
    width: 360px;
    display: flex;
    font-size: 12px;
    color: #9696a0;
    font-family: Gilroy-Medium;
    font-weight: bold;
    align-items: center;
    padding-right: 32px;
    justify-content: space-between;
    & > div {
      display: flex;
      & > span {
        margin-right: 8px;
        display: flex;
        align-items: center;
      }
    }
  }
  .product {
    width: calc((100% - 360px) / 2);
    padding: 16px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    & > span {
      font-family: Gilroy-Medium;
      font-size: 12px;
    }
  }
  .name {
    display: flex;
    flex-direction: column;

    & > :first-child {
      font-size: 14px;
      color: ${COLORS.dark};
      font-family: Gilroy-Medium;
    }
    & > :last-child {
      color: ${COLORS.dark};
      opacity: 0.65;
    }
  }
  .img-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    & > img {
      max-width: 226px;
    }
  }
  .img-wrapper.top-block {
    & > img {
      width: 30px;
    }
  }
  .top-block {
    display: flex;
  }
  .nutritional-wrapper {
    width: 100%;
    height: 100%;
  }
  .nutritional-content {
    display: flex;
    & > span {
      width: 33.33%;
      font-size: 12px;
    }
  }
  .nutritional-content.header {
    flex-direction: row;
    & > span {
      font-family: "Gilroy-ExtraBold";
      margin-bottom: 16px;
    }
  }
`;
