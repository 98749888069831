import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import PublicRoute from "components/Routes/PublicRoute";
import PrivateRoute from "components/Routes/PrivateRoute";

import SignIn from "./pages/SignIn";
import ResetPassword from "./pages/ResetPassword";
import Summary from "pages/Summary";
import Pricing from "./pages/Pricing";
import Promotions from "./pages/Promotions";
import Ranking from "./pages/Ranking";
import Content from "pages/Content";
import Rating from "pages/Rating";
import Media from "pages/Media";
import Navigation from "pages/Navigation";
import Availability from "pages/Availability";
import Assortment from "pages/Assortment";
import Settings from "pages/Settings";
import Alerts from "pages/Alerts";
import AlertEdit from "pages/AlertEdit";
import AlertCreate from "pages/AlertCreate";
import ErrorBoundary from "components/ErrorBoundary";
import ErrorPopup from "components/ErrorPopup";
import NotFoundPage from "components/NotFoundPage";
import Otp from "./pages/Otp";

//Actions
import { resetError } from "store/error/actions";

const Routers = () => {
  //Selectors:
  //Authorization
  const { error: authorizationError } = useSelector((state) => state.authorization);
  //Pricing Top Tabs
  const { error: priceTrendError } = useSelector((state) => state.priceTrend);
  const { error: priceChangeError } = useSelector((state) => state.priceChange);
  const { error: priceRankingError } = useSelector((state) => state.priceRanking);
  //Pricing Bottom Tabs
  const { error: currentPricesError } = useSelector((state) => state.currentPrices);
  const { error: priceRangeError } = useSelector((state) => state.priceRange);
  const { error: priceComparisonError } = useSelector((state) => state.priceComparison);

  //Promotions Top Tabs
  const { error: summaryPromotionsError } = useSelector((state) => state.summaryPromotions);
  const { error: promotionBreakdownError } = useSelector((state) => state.promotionBreakdown);
  const { error: discountCutError } = useSelector((state) => state.discountCut);
  //Promotions Bottom Tabs
  const { error: promotionsCalendarError } = useSelector((state) => state.promotionsCalendar);

  //Ranking Top Tab
  const { error: rankingSearchOverviewError } = useSelector((state) => state.rankingSearchOverview);
  const { error: rankingSearchRetailerError } = useSelector((state) => state.rankingSearchRetailer);
  const { error: rankingLocationOverviewError } = useSelector((state) => state.rankingLocationOverview);
  //Ranking Bottom Tab
  const { error: rankingSearchAnalysisError } = useSelector((state) => state.rankingSearchAnalysis);
  const { error: rankingLocationAnalysisError } = useSelector((state) => state.rankingLocationAnalysis);

  //Current content
  const { error: currentContentError } = useSelector((state) => state.currentContent);
  const { error: currentContentTableError } = useSelector((state) => state.currentContentTable);
  const { error: keyContentError } = useSelector((state) => state.keyContent);
  const { error: contentTrendError } = useSelector((state) => state.contentTrend);
  const { error: benchmarkContentError } = useSelector((state) => state.benchmarkContent);

  //Rating
  const { error: ratingSummaryError } = useSelector((state) => state.ratingSummary);
  const { error: ratingSummaryBottomError } = useSelector((state) => state.ratingSummaryBottom);
  const { error: ratingSummaryProductError } = useSelector((state) => state.ratingSummaryProduct);
  const { error: productInsightError } = useSelector((state) => state.productInsight);
  const { error: trendAnalysisError } = useSelector((state) => state.trendAnalysis);
  const { error: trendAnalysisTableError } = useSelector((state) => state.trendAnalysisTable);

  //Media
  const { error: mediaSummaryTopError } = useSelector((state) => state.mediaSummaryTop);
  const { error: mediaSummaryBottomError } = useSelector((state) => state.mediaSummaryBottom);
  const { error: shareOfMediaTopError } = useSelector((state) => state.shareOfMediaTop);
  const { error: shareOfMediaBottomError } = useSelector((state) => state.shareOfMediaBottom);
  const { error: mediaSummaryDetailsError } = useSelector((state) => state.mediaSummaryDetails);

  //Navigation
  const { error: navigationShareProductsError } = useSelector((state) => state.navigationShareProducts);
  const { error: navigationPathPurchaseError } = useSelector((state) => state.navigationPathPurchase);
  const { error: navigationShareTrendError } = useSelector((state) => state.navigationShareTrend);

  //Availability
  const { error: availabilitySummaryError } = useSelector((state) => state.availabilitySummary);
  const { error: retailerAvailabilityError } = useSelector((state) => state.retailerAvailability);

  //Assortment
  const { error: assortmentSummaryTopError } = useSelector((state) => state.assortmentSummaryTop);
  const { error: assortmentSummaryBottomError } = useSelector((state) => state.assortmentSummaryBottom);
  const { error: assortmentSummaryCalendarError } = useSelector((state) => state.assortmentSummaryCalendar);
  const { error: assortmentChangeError } = useSelector((state) => state.assortmentChange);
  const { error: assortmentDetailsError } = useSelector((state) => state.assortmentDetails);

  //Alerts
  const { error: alertsError } = useSelector((state) => state.alerts);

  //Exports
  const { error: exportsError } = useSelector((state) => state.exports);

  //Filters
  const { error: filtersError } = useSelector((state) => state.filters);

  //Settings
  const { error: settingsError } = useSelector((state) => state.settings);
  const { error: settingsSubscriptionsError } = useSelector((state) => state.settingsSubscriptions);
  const { error: settingsProductGroupsError } = useSelector((state) => state.settingsProductGroups);
  const { error: settingsYourProfileError } = useSelector((state) => state.settingsYourProfile);

  //Constants
  const errorsArr = [
    //Authorization
    authorizationError,
    //Pricing Top Tabs
    priceTrendError,
    priceChangeError,
    priceRankingError,
    //Pricing Bottom Tabs
    currentPricesError,
    priceRangeError,
    priceComparisonError,

    //Promotions Top Tabs
    summaryPromotionsError,
    promotionBreakdownError,
    discountCutError,
    //Promotions Bottom Tabs
    promotionsCalendarError,

    //Ranking Top Tab,
    rankingSearchOverviewError,
    rankingSearchRetailerError,
    rankingLocationOverviewError,
    //Ranking Bottom Tab
    rankingSearchAnalysisError,
    rankingLocationAnalysisError,

    //Current content
    currentContentError,
    currentContentTableError,
    keyContentError,
    contentTrendError,
    benchmarkContentError,

    //Rating
    ratingSummaryError,
    ratingSummaryBottomError,
    ratingSummaryProductError,
    productInsightError,
    trendAnalysisError,
    trendAnalysisTableError,

    //Media
    mediaSummaryTopError,
    mediaSummaryBottomError,
    shareOfMediaTopError,
    shareOfMediaBottomError,
    mediaSummaryDetailsError,

    //Navigation
    navigationShareProductsError,
    navigationPathPurchaseError,
    navigationShareTrendError,

    //Availability
    availabilitySummaryError,
    retailerAvailabilityError,

    //Assortment
    assortmentSummaryTopError,
    assortmentSummaryBottomError,
    assortmentSummaryCalendarError,
    assortmentChangeError,
    assortmentDetailsError,

    //Alerts
    alertsError,

    //Exports
    exportsError,

    //Filters
    filtersError,

    //Settings
    settingsError,
    settingsSubscriptionsError,
    settingsProductGroupsError,
    settingsYourProfileError,
  ];
  const dispatch = useDispatch();
  //States
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const atLeastOneError = errorsArr.some((el) => !!el);
    if (atLeastOneError) {
      const error = errorsArr.find((el) => !!el);
      setError(error);

      setHasError(true);
    } else {
      setError(null);
      setHasError(false);
    }
  }, [errorsArr]);

  return (
    <Router>
      <ErrorBoundary>
        <Switch>
          <PublicRoute exact path="/" component={SignIn} />
          <PublicRoute exact path="/reset-password/:id" component={ResetPassword} />
          <PrivateRoute exact path="/otp" component={Otp} />
          <PrivateRoute exact path="/summary" component={Summary} />
          <PrivateRoute exact path="/pricing" component={Pricing} />
          <PrivateRoute exact path="/promotions" component={Promotions} />
          <PrivateRoute exact path="/ranking" component={Ranking} />
          <PrivateRoute exact path="/content" component={Content} />
          <PrivateRoute exact path="/rating" component={Rating} />
          <PrivateRoute exact path="/media" component={Media} />
          <PrivateRoute exact path="/navigation" component={Navigation} />
          <PrivateRoute exact path="/availability" component={Availability} />
          <PrivateRoute exact path="/assortment" component={Assortment} />
          <PrivateRoute exact path="/settings" component={Settings} />
          <PrivateRoute exact path="/alerts" component={Alerts} />
          <PrivateRoute exact path="/create-alert" component={AlertCreate} />
          <PrivateRoute exact path="/alert/:id" component={AlertEdit} />
          <Route component={NotFoundPage} />
        </Switch>
        {hasError ? (
          <ErrorPopup
            tokenHasExpired={error.status === 401}
            cantVerifyToken={error.status === 403}
            cancelHandler={() => dispatch(resetError())}
          />
        ) : null}
      </ErrorBoundary>
    </Router>
  );
};

export default Routers;
