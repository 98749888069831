import React, { useState } from 'react'
import { Tabs } from 'antd'

import SearchOverviewTab from 'tabs/RankingTabs/RankingTopTabs/SearchOverviewTab/SearchOverviewTab'
import LocationOverviewTab from 'tabs/RankingTabs/RankingTopTabs/LocationOverviewTab/LocationOverviewTab'
import SearchRetailerTab from 'tabs/RankingTabs/RankingTopTabs/SearchRetailerTab/SearchRetailerTab'

import about from 'assets/images/about.svg'
import { TabWithIcon } from 'assets/icons/icons'

const RankingTopTabs = ({
    setActiveTab,
    isFeaturedProducts,
    setIsFeaturedProducts
}) => {
    const { TabPane } = Tabs
    const [currentKey, setCurrentKey] = useState('1');

    function callback(key) {
        setCurrentKey(key);
        setActiveTab(key)
    }

    return (
        <div className="main-box">
            <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab={<TabWithIcon tabName={'Search Overview'}
                    icon={about}
                    tooltip={'Overall the prices moved up 20% based on the selected filters.'}
                />} key="1">
                    <SearchOverviewTab
                        isFeaturedProducts={isFeaturedProducts}
                        setIsFeaturedProducts={setIsFeaturedProducts}
                        isActive={currentKey==='1'}
                    />
                </TabPane>
                <TabPane tab={<TabWithIcon tabName={'Search Retailer Comparison'}
                    icon={about}
                    tooltip={'Overall the prices moved up 20% based on the selected filters.'}
                />} key="2">
                    <SearchRetailerTab
                        isFeaturedProducts={isFeaturedProducts}
                        setIsFeaturedProducts={setIsFeaturedProducts}
                        isActive={currentKey==='2'}
                    />
                </TabPane>
                <TabPane tab={<TabWithIcon tabName={'Location Overview'}
                    icon={about}
                    tooltip={'Overall the prices moved up 20% based on the selected filters.'}
                />} key="3">
                    <LocationOverviewTab
                      isFeaturedProducts={isFeaturedProducts}
                      setIsFeaturedProducts={setIsFeaturedProducts}
                      isActive={currentKey==='3'}
                    />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default RankingTopTabs
