import React, { useState } from "react";
import { Tabs, Form } from "antd";
import { useSelector } from "react-redux";
import _ from "lodash";
import useColorTheme from "../../hooks/useColorTheme";

import { Styles } from "./style";

import SettingsYourProfileTab from "./SettingsYourProfileTab";
import SettingsChangePasswordTab from "./SettingsChangePassword";
import SettingsWatchlist from "./SettingsWatchlist";
import SettingsRetailerDisplay from "./SettingsRetailerDisplay";
import SettingsProductGroups from "./SettingsProductGroups/index";
import SettingsSubscriptions from "./SettingsSubscriptions";
import SettingsYourProducts from "./SettingsYourProducts";
import UnsavedProfileModal from "components/UnsavedProfileModal";

const SettingsLeftTabs = ({ activeTab, setActiveTab }) => {
  //Selectors
  const { settingsYourProfile } = useSelector((state) => state.settingsYourProfile);
  const { sourceType } = useSelector((state) => state.filters.filters);
  const { retailers } = useSelector((state) => state.authorization);
  //States
  const [profileForm] = Form.useForm();
  const [passwordForm] = Form.useForm();

  const [imageState, setImageState] = useState({
    loading: false,
    file: "",
    imageUrl: "",
  });
  const [formNotSaved, setFormNotSaved] = useState(false);
  const [clickedTab, setClickedTab] = useState("0");
  const [retailersOrder, setRetailersOrder] = useState([]);
  const [selectCoreProducts, setSelectCoreProducts] = useState([]);
  const [selectColor, setSelectColor] = useState("#F0F8FF");
  const [productGroupName, setProductGroupName] = useState("");
  const [editProductGroup, setEditProductGroup] = useState({});
  const { primaryColor, secondaryColor } = useColorTheme();
  //Const

  const { TabPane } = Tabs;
  const dataForTabs = [
    { tabName: "Your Profile", component: SettingsYourProfileTab },
    { tabName: "Change Password", component: SettingsChangePasswordTab },
    { tabName: "Watchlist", component: SettingsWatchlist },
    { tabName: "Retailer Display", component: SettingsRetailerDisplay },
    { tabName: "My Custom Groups", component: SettingsProductGroups },
    { tabName: "Subscriptions", component: SettingsSubscriptions },
    { tabName: "Your Products", component: SettingsYourProducts },
  ];
  const { avatar, company, email, jobTitle, first_name: name, phone, last_name: shortName } = settingsYourProfile;
  const conbinedAvatar = avatar || company.avatar;
  //const profileObj = { avatar, email, jobTitle, name, phone, shortName, primaryColor, secondaryColor }; //Testing
  const profileObj = { avatar: conbinedAvatar, email, jobTitle, name, phone, shortName, primaryColor };
  const passwordObj = { currentPassword: "", newPassword: "", repeatedPassword: "" };
  const productGroupObj = { productGroupName: "", selectColor: "#F0F8FF", selectCoreProducts: [] };
  const productGroup = { productGroupName, selectColor, selectCoreProducts };

  const comparativeObjArr = [profileObj, passwordObj, productGroupObj];
  const formsArray = [profileForm, passwordForm];

  const comparativeObj = comparativeObjArr[+activeTab] || passwordObj;
  const activeForm = formsArray[+activeTab] || passwordForm;

  const currentOrder = sourceType?.length ? sourceType.filter((item) => retailers.includes(item.id)) : [];
  const sortedCurrentOrder = _.sortBy(currentOrder, (item) => retailers.indexOf(item.id));

  const handleChangeTab = (key) => {
    setClickedTab(key);
    if (+activeTab === 0 || +activeTab === 1) {
      compareFormWithObj(key);
    } else if (+activeTab === 2) {
      compareArrays(key);
    } else if (+activeTab === 3) {
      const comparativeObj = Object.entries(editProductGroup).length
        ? {
            productGroupName: editProductGroup.name,
            selectColor: editProductGroup.color,
            selectCoreProducts: editProductGroup.coreProduct.map(({ id }) => String(id)),
          }
        : productGroupObj;
      compareTwoObj(key, comparativeObj, productGroup);
    } else {
      setActiveTab(key);
    }
  };

  const compareFormWithObj = (key) => {
    const formObj = activeForm.getFieldsValue();
    const {
      avatar: formObjAvatar,
      ...checkFormObj
    } = formObj;
    const {
      avatar: comparativeObjAvatar,
      ...checkComparativeObj
    } = comparativeObj;
    const isEqual = _.isEqual(checkFormObj, checkComparativeObj);
    const imagesIsEqual = +activeTab === 0 ? imageState.imageUrl === avatar : true;
    if (isEqual && imagesIsEqual) {
      setActiveTab(key);
    } else {
      setFormNotSaved(true);
    }
  };

  const compareArrays = (key) => {
    const isEqualArrays =
      retailersOrder.length === sortedCurrentOrder.length &&
      retailersOrder.every((value, index) => value === sortedCurrentOrder[index]);
    if (isEqualArrays) {
      setActiveTab(key);
    } else {
      setFormNotSaved(true);
    }
  };

  const compareTwoObj = (key, firstObj, secondObj) => {
    const isEqual = _.isEqual(firstObj, secondObj);

    if (isEqual) {
      setActiveTab(key);
    } else {
      setFormNotSaved(true);
    }
  };

  const onCancel = () => {
    setFormNotSaved(false);
  };

  const onDiscard = () => {
    if (+activeTab === 0 || +activeTab === 1) {
      activeForm.setFieldsValue(comparativeObj);
      setImageState((prevState) => ({ ...prevState, imageUrl: profileObj.avatar }));
    } else if (+activeTab === 2) {
      setRetailersOrder(sortedCurrentOrder);
    } else if (+activeTab === 3) {
      setSelectCoreProducts([]);
      setSelectColor("#F0F8FF");
      setProductGroupName("");
      setEditProductGroup({});
    }

    setFormNotSaved(false);
    setActiveTab(clickedTab);
  };

  return (
    <Styles color={primaryColor}>
      <div className="main-box-no-padding">
        <Tabs activeKey={activeTab} tabPosition="left" onChange={handleChangeTab}>
          {dataForTabs.map(({ tabName, component }, i) => {
            const Component = component;
            return (
              <TabPane tab={tabName} key={i}>
                <Component
                  form={activeForm}
                  imageState={imageState}
                  setImageState={setImageState}
                  retailersOrder={retailersOrder}
                  setRetailersOrder={setRetailersOrder}
                  selectCoreProducts={selectCoreProducts}
                  setSelectCoreProducts={setSelectCoreProducts}
                  selectColor={selectColor}
                  setSelectColor={setSelectColor}
                  productGroupName={productGroupName}
                  setProductGroupName={setProductGroupName}
                  editProductGroup={editProductGroup}
                  setEditProductGroup={setEditProductGroup}
                  activeTab={activeTab}
                />
              </TabPane>
            );
          })}
        </Tabs>
        {formNotSaved ? <UnsavedProfileModal onCancel={onCancel} onDiscard={onDiscard} /> : null}
      </div>
    </Styles>
  );
};

export default SettingsLeftTabs;
