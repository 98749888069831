import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux"
import MainFilters from 'components/Filters/MainFilters'
import NavBar from 'components/NavBar'
import NavigationTabs from 'tabs/NavigationTabs/Index'
import { STATE_STATUSES } from "utils/statuses"
import LoaderBox from "components/LoaderBox";
import useFilters from "../hooks/useFilters";

const Navigation = (props) => {
  const {
    filtersStatus,
    prepareLogoutStatus,
    statusAuth,
  } = props;

  const dispatch = useDispatch();

  const [activeTabTop, setActiveTabTop] = useState(1);
  const getActionTop = useCallback(() =>
    [ "SET_PRISTINE_SHARE_PRODUCTS", "SET_PRISTINE_PATH_PURCHASE", "SET_PRISTINE_SHARE_TREND" ][+activeTabTop - 1],
  [activeTabTop]);
  const getPristineTop = () => (+activeTabTop > 1 ? false : true);
  const {
    mainLastFilter,
    titleDateStatus,
  } = useFilters();

  useEffect(() => {
    return () => {
      dispatch({ type: getActionTop(), payload: getPristineTop() });
    };
  }, []);

  useEffect(() => {
    if (filtersStatus === STATE_STATUSES.PENDING) {
      dispatch({ type: getActionTop(), payload: true });
    }
  }, [filtersStatus, getActionTop, dispatch]);

  return (
    <div className="main-page-wrapper">
      <NavBar />
      <div className="main-page-box">
        <MainFilters />
        <div className="main-title-page">Navigation</div>
        <div className="main-tabs-box">
          <NavigationTabs setActiveTab={setActiveTabTop} />
        </div>
      </div>
      {
        statusAuth !== STATE_STATUSES.PENDING &&
        mainLastFilter &&
        titleDateStatus === STATE_STATUSES.READY &&
        prepareLogoutStatus === STATE_STATUSES.INIT ? null : <LoaderBox isOpacity={true} />
      }
      </div>
  );
};

export default connect(
  (state) => ({
    isPristineShareProducts: state.navigationShareProducts.isPristine,
    isPristinePathPurchase: state.navigationPathPurchase.isPristine,
    isPristineShareTrend: state.navigationShareTrend.isPristine,

    filtersStatus: state.filters.status,
    prepareLogoutStatus: state.authorization.prepareLogoutStatus,
    statusAuth: state.authorization.status,
  }),
  null
)(Navigation);
