import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//Components
import MainFilters from "components/Filters/MainFilters";
import NavBar from "components/NavBar";
import AvailabilityBottom from "tabs/AvailabilityTabs/AvailabilityBottomTab/AvailabilityBottomTab";
import AvailabilityTopTab from "tabs/AvailabilityTabs/AvailabilityTopTab/AvailabiltyTopTab";

//Actions
import { setPristineAvailabilitySummary } from "store/availability/availabiltySummary/actions";
import { setPristineRetailerAvailability } from "store/availability/retailerAvailability/actions";
//Utils
import { STATE_STATUSES } from "utils/statuses";
import LoaderBox from "components/LoaderBox";
import useFilters from "../hooks/useFilters";

const Availability = () => {
  const { status: filtersStatus } = useSelector((state) => state.filters);
  const { prepareLogoutStatus, status: statusAuth } = useSelector((state) => state.authorization);

  //Constants
  const dispatch = useDispatch();

  const {
    mainLastFilter,
    statusMainFilters,
    universalFiltersStatus,
    statusFilters,
    titleDateStatus,
  } = useFilters();

  const getActionTop = (value) => setPristineAvailabilitySummary(value);

  const getPristineTop = true;

  const getActionBottom = (value) => setPristineRetailerAvailability(value);
  const getPristineBottom = true;

  useEffect(() => {
    return () => {
      dispatch(getActionTop(getPristineTop));
      dispatch(getActionBottom(getPristineBottom));
    };
  }, []);

  useEffect(() => {
    if (filtersStatus === STATE_STATUSES.PENDING) {
      dispatch(getActionTop(true));
      dispatch(getActionBottom(true));
    }
  }, [filtersStatus]);
  return (
    <div className="main-page-wrapper">
      <NavBar />
      <div className="main-page-box">
        <MainFilters />
        <div className="main-title-page">Availability</div>

        <div className="main-tabs-box">
          <AvailabilityTopTab />
        </div>
        <div className="main-tabs-box">
          <AvailabilityBottom />
        </div>
      </div>
      {
        statusAuth !== STATE_STATUSES.PENDING &&
        mainLastFilter &&
        statusMainFilters === STATE_STATUSES.READY &&
        universalFiltersStatus === STATE_STATUSES.READY &&
        statusFilters === STATE_STATUSES.READY &&
        titleDateStatus === STATE_STATUSES.READY &&
        prepareLogoutStatus === STATE_STATUSES.INIT ? null : <LoaderBox isOpacity={true} />
      }
      </div>
  );
};

export default Availability;
