import { STATE_STATUSES } from "utils/statuses";
import { getWatchlistInArray } from "utils/getWatchlistInArray";

import { setSettingsYourProfile } from "../settings/settingsYourProfile/actions";

const url = process.env.REACT_APP_URL;

export const LOG_IN = "LOG_IN";
const logIn = (values) => {
  return {
    type: LOG_IN,
    request: {
      method: "POST",
      url: `${url}/auth/login`,
      data: values,
    },
    meta: {
      asPromise: true,
    },
  };
};
export const SUBMIT_OTP = "SUBMIT_OTP";
const submitOTP = (values) => {
  return {
    type: SUBMIT_OTP,
    request: {
      method: "POST",
      url: `${url}/auth/submit-otp`,
      data: values,
    },
    meta: {
      asPromise: true,
    },
  };
};

export const RESEND_OTP = "RESEND_OTP";
export const resendOTP = (values) => {
  return {
    type: RESEND_OTP,
    request: {
      method: "POST",
      url: `${url}/auth/resend-otp`,
      data: values,
    },
    meta: {
      asPromise: true,
    },
  };
};
export const requesOTP = (values) => async (dispatch) => {
  await dispatch(submitOTP(values)).then((response) => {
    const {
      token,
      refreshToken,
      user: {
        lastFilter,
        retailers,
        rankingOrder,
        retailersOrder,
        company: { manufacturer, sections, color },
        watchlist,
        watchlistFilter,
        colorTheme,
      },
    } = response.data;
    const combinedColorTheme = {
      primary: colorTheme?.primary || color?.primary,
      secondary: colorTheme?.secondary || color?.secondary,
    };

    localStorage.setItem("Token", JSON.stringify(token));
    localStorage.setItem("RefreshToken", JSON.stringify(refreshToken));
    localStorage.setItem("LastFilter", JSON.stringify(lastFilter));
    localStorage.setItem("Retailers", JSON.stringify(retailers));
    localStorage.setItem("UserManufacturer", JSON.stringify(manufacturer[manufacturer.length - 1].id));
    localStorage.setItem(
      "UserBrands",
      JSON.stringify(manufacturer[manufacturer.length - 1].brands.map((brand) => brand.id))
    );
    localStorage.setItem("RankingOrder", JSON.stringify(rankingOrder));
    localStorage.setItem("RetailersOrder", JSON.stringify(retailersOrder));
    localStorage.setItem("Sections", JSON.stringify(sections));
    localStorage.setItem("Watchlist", JSON.stringify(watchlist));
    localStorage.setItem("WatchlistFilter", JSON.stringify(watchlistFilter));
    localStorage.setItem("colorTheme", JSON.stringify(combinedColorTheme));
  });
};
export const requestLogIn = (values) => async (dispatch) => {
  await dispatch(logIn(values)).then((response) => {
    const {
      token,
      refreshToken,
      isMfa,
      user: {
        lastFilter,
        retailers,
        rankingOrder,
        retailersOrder,
        company: { manufacturer, sections, color },
        watchlist,
        watchlistFilter,
        colorTheme,
      },
    } = response.data;
    const combinedColorTheme = {
      primary: colorTheme?.primary || color?.primary,
      secondary: colorTheme?.secondary || color?.secondary,
    };

    localStorage.setItem("Token", JSON.stringify(token));
    localStorage.setItem("RefreshToken", JSON.stringify(refreshToken));
    localStorage.setItem("LastFilter", JSON.stringify(lastFilter));
    localStorage.setItem("Retailers", JSON.stringify(retailers));
    localStorage.setItem("UserManufacturer", JSON.stringify(manufacturer[manufacturer.length - 1].id));
    localStorage.setItem(
      "UserBrands",
      JSON.stringify(manufacturer[manufacturer.length - 1].brands.map((brand) => brand.id))
    );
    localStorage.setItem("RankingOrder", JSON.stringify(rankingOrder));
    localStorage.setItem("RetailersOrder", JSON.stringify(retailersOrder));
    localStorage.setItem("Sections", JSON.stringify(sections));
    localStorage.setItem("Watchlist", JSON.stringify(watchlist));
    localStorage.setItem("WatchlistFilter", JSON.stringify(watchlistFilter));
    if (!isMfa) {
      localStorage.setItem("colorTheme", JSON.stringify(combinedColorTheme));
    }
  });
};
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const forgotPassword = (data) => {
  return {
    type: FORGOT_PASSWORD,
    request: {
      method: "POST",
      url: `${url}/auth/forgotPassword`,
      data,
    },
    meta: {
      asPromise: true,
    },
  };
};

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const changePassword = (data, token) => {
  return {
    type: CHANGE_PASSWORD,
    request: {
      method: "POST",
      url: `${url}/auth/forgotPassword/${token}`,
      data,
    },
    meta: {
      asPromise: true,
    },
  };
};

export const SET_RANKING_ORDER = "SET_RANKING_ORDER";
const setRankingOrder = (data) => {
  return {
    type: SET_RANKING_ORDER,
    request: {
      url: `${url}/v2/ranking/retailers-order`,
      method: "POST",
      data,
    },
    meta: {
      asPromise: true,
    },
  };
};
export const requestRankingOrder = (values) => async (dispatch) => {
  await dispatch(setRankingOrder(values)).then((response) => {
    const { rankingOrder } = response.data;
    localStorage.setItem("RankingOrder", JSON.stringify(rankingOrder));
  });
};

export const SET_RETAILERS_ORDER = "SET_RETAILERS_ORDER";
const setRetailersOrder = (data) => {
  return {
    type: SET_RETAILERS_ORDER,
    request: {
      url: `${url}/v2/navigation/retailer-order`,
      method: "POST",
      data,
    },
    meta: {
      asPromise: true,
    },
  };
};
export const requestRetailerOrder = (values) => async (dispatch) => {
  await dispatch(setRetailersOrder(values)).then((response) => {
    const { retailersOrder } = response.data;
    localStorage.setItem("RetailersOrder", JSON.stringify(retailersOrder));
  });
};

export const AUTH_ME = "AUTH_ME";
export const authMe = () => {
  return {
    type: AUTH_ME,
    request: {
      method: "GET",
      url: `${url}/auth/me`,
    },
    meta: {
      asPromise: true,
    },
  };
};
export const requestAuthMe = () => async (dispatch) => {
  await dispatch(authMe()).then((response) => {
    const {
      lastFilter,
      retailers,
      rankingOrder,
      retailersOrder,
      retailersLogo,
      company: { sections },
      watchlist,
      watchlistFilter,
    } = response.data;
    dispatch(setSettingsYourProfile(response.data));
    localStorage.setItem("LastFilter", JSON.stringify(lastFilter));
    localStorage.setItem("Retailers", JSON.stringify(retailers));
    localStorage.setItem("RankingOrder", JSON.stringify(rankingOrder));
    localStorage.setItem("RetailersOrder", JSON.stringify(retailersOrder));
    localStorage.setItem("RetailersLogo", JSON.stringify(retailersLogo || []));
    localStorage.setItem("Sections", JSON.stringify(sections));
    localStorage.setItem("Watchlist", JSON.stringify(watchlist));
    localStorage.setItem("WatchlistFilter", JSON.stringify(watchlistFilter));
  });
};

export const PREPARE_LOGOUT = "PREPARE_LOGOUT";
export const setPrepareLogout = () => {
  return {
    type: PREPARE_LOGOUT,
  };
};

export const LOGOUT = "LOGOUT";
const logout = () => {
  return {
    type: LOGOUT,
    request: {
      method: "POST",
      url: `${url}/auth/logout`,
    },
    meta: {
      asPromise: true,
    },
  };
};

export const LOG_OUT = "LOG_OUT";
export const logOutRequest = () => async (dispatch) => {
  await dispatch(logout()).finally(() => {
    //localStorage.clear();
    //window.location.reload();
    return {
      type: LOG_OUT,
    };
  });
};

export const LOGOUT_END = "LOGOUT_END";
export const setLogoutEnd = () => {
  const lastColor = localStorage.getItem('colorTheme');
  localStorage.clear();
  localStorage.setItem('logoutStatus', STATE_STATUSES.READY);
  if (lastColor) localStorage.setItem('lastColor', JSON.stringify(lastColor));
  window.location.reload();
  return {
    type: LOGOUT_END,
  };
};
export const OTP_END = "OTP_END";
export const setOtpEnd = () => {
  localStorage.clear();
  window.location.reload();
  return {
    type: OTP_END,
  };
};
export const RE_LOGIN = "RE_LOGIN";
export const relogin = (data) => {
  return {
    type: RE_LOGIN,
    request: {
      url: `${url}/auth/refresh-token`,
      method: "POST",
      data,
    },
    meta: {
      asPromise: true,
    },
  };
};
export const requestReLogIn = (values) => async (dispatch) => {
  await dispatch(relogin(values)).then((response) => {
    const { token, refreshToken } = response.data;
    localStorage.setItem("Token", JSON.stringify(token));
    localStorage.setItem("RefreshToken", JSON.stringify(refreshToken));
  });
};


export const SET_LOGIN_STATUS = "SET_LOGIN_STATUS";
export const setLoginStatus = (loginStatus) => {
  localStorage.setItem('loginStatus', loginStatus);
  return {
    type: SET_LOGIN_STATUS,
    payload: loginStatus,
  };
};
export const SET_MFA_STATUS = "SET_MFA_STATUS";
export const setMfaStatus = (mfaStatus) => {
  return {
    type: SET_MFA_STATUS,
    payload: mfaStatus,
  };
};
export const SET_WATCHLIST = "SET_WATCHLIST";
export const setWatchlist = ({ watchlist, watchlistFilter }) => {
  localStorage.setItem("Watchlist", watchlist);
  if (watchlistFilter) {
    const watchlistInArray = getWatchlistInArray(watchlistFilter);
    localStorage.setItem("WatchlistFilter", JSON.stringify(watchlistInArray));
  }

  return {
    type: SET_WATCHLIST,
    payload: { watchlist, watchlistFilter },
  };
};
export const SET_AUTH_REFRESH_STATUS = "SET_AUTH_REFRESH_STATUS";
export const setAuthRefreshStatus = (status) => {
  localStorage.setItem("refreshStatus", status);
  return {
    type: SET_AUTH_REFRESH_STATUS,
    payload: status,
  };
};
export const REFRESH_AUTH_ME = "REFRESH_AUTH_ME";
export const refreshAuthMe = ({ filter, isStart }) => {
  localStorage.setItem("LastFilter", JSON.stringify(filter));
  return {
    type: REFRESH_AUTH_ME,
    payload: { filter, isStart },
  };
};
