import { COLORS } from "assets/colors/colors";
import styled from "styled-components";

export const Styles = styled.div`
  .retailer-item {
    padding: 12px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    max-height: 48px;
  }
  .plus-btn-retailers,
  .plus-btn-brands,
  .plus-btn-banners {
    cursor: pointer;
    width: 12px;
    padding: 6px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    border: 1px solid ${COLORS.lightGray};
    & > span {
      color: ${COLORS.lightGray};
    }
  }
  .plus-btn-brands {
    margin-left: 16px;
  }
  .plus-btn-banners {
    margin-left: 24px;
  }
  .retailer-row {
    margin-left: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  retailer-title {
    font-size: 14px;
    padding-right: 6px;
  }
  .retailer-name {
    text-transform: capitalize;
    font-family: Gilroy-Medium;
  }
  .retailer-total {
    color: ${COLORS.darkGray};
    font-family: Gilroy-Medium;
  }
`;

export const ProductStyles = styled.div`
  display: flex;
  padding-left: 24px;
  .media-wrapper {
    display: flex;
    padding-left: 6px;
    height: 48px;
    max-height: 48px;
  }
  .lines {
    width: 14px;
    display: flex;
    flex-direction: column;
    & > div {
      height: 50%;
      border-left: 1px solid ${COLORS.lightGray};
    }
    & > :last-child {
      border-top: 1px solid ${COLORS.lightGray};
    }
  }

  .vertical-line {
    height: auto;
  }
  .product,
  .product-media {
    padding: 12px;
    font-size: 14px;
    font-family: Gilroy-Medium;
    display: flex;
    align-items: center;
    max-height: 48px;
    & > img {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }
  }
`;
