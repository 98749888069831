export const firstCharToUpperCase = (string) => {
  if (string.length > 1) {
    return string[0].toUpperCase() + string.slice(1);
  } else {
    return string;
  }
};

export const sortAlphabetically = (arr, key) => {
  return arr.sort((a, b) => a[key].localeCompare(b[key]));
};

export const sortAlphabeticallyReverse = (arr, key) => {
  return arr.sort((a, b) => b[key].localeCompare(a[key]));
};

export const getSingularOrPlural = (count, key) => {
  return `${count > 1 ? key + "s" : key}`;
};

export const replaceNbsps = (str) => {
  var re = new RegExp(String.fromCharCode(160), "g");
  return str.replace(re, " ");
} 