import styled from "styled-components";

export const Styles = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;
  .btn-left {
    position: absolute;
    left: 50px;
    top: 50%;
    color: white;
    font-size: 44px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.7);
    }
  }
  .btn-right {
    position: absolute;
    right: 50px;
    top: 50%;
    color: white;
    font-size: 44px;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: rgba(0, 0, 0, 0.7);
    }
  }
`;
