import { COLORS } from "assets/colors/colors";
import styled from "styled-components";

export const Styles = styled.div.attrs((props) => props)`
  .filters-row {
    display: flex;
    justify-content: space-between;

    .wrapper-checkbox {
      width: 140px;
      background: #eef2f7;
      border: none;
      height: 44px;
      border-radius: 4px;
      display: flex;
      align-items: center;

      padding: 0 10px;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      font-family: "Gilroy-Medium";
    }
    .filters {
      display: flex;
    }
  }
  .title {
    font-family: "Gilroy-Medium";
    font-size: 14px;
    margin-bottom: 20px;
    margin-top: 12px;
  }
  .content-row {
    display: flex;
    flex-wrap: wrap;
  }
  .content-card-wrapper {
    display: flex;
    align-items: stretch;
  }
  .content-wrapper {
    width: 280px;
    border-radius: 4px;
    border: solid 1px #e0e0e4;
    background-color: #ffffff;
    padding: 10px 15px;
    margin: 8px;
    :hover {
      border: solid 1px ${(props) => props.color};
    }
    .separator {
      margin-top: 10px;
      height: 1px;
      background: ${COLORS.lightGray};
    }
  }
  .top-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    .top-block-title {
      display: flex;
      align-items: center;

      & span {
        font-family: Gilroy-ExtraBold;
        padding: 0 12px;
      }
    }
    .images {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & img {
        margin-left: 15px;
      }
    }
  }
  .keys-wrapper {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
  }
  .keys-wrapper-row {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    font-family: Gilroy-Medium;
    font-size: 12px;
    .bold {
      font-family: Gilroy-ExtraBold;
      color: ${(props) => props.color};
    }
    & :last-child {
      font-family: Gilroy-ExtraBold;
    }
  }
`;
