import React from "react";
import SummaryTerm from "./SummaryTerm";
import AllTerms from "./AllTerms";

const TrendSingleDay = ({
  data,
  date,
  activeTab,
  currentManufacturer,
  type,
  actionSingleProduct,
  storeSingleProduct,
  actionSingleProductSummary,
  actionSingleProductSetPeriod,
  actionRemoveProduct,
  actionProductCompare,
  storeProductCompare,
  actionSingleProductCompareSummary,
  actionSingleProductSetCompare,
  statusProduct,
  isFeaturedProducts,
  setIsFeaturedProducts,
}) => {
  return (
    <div className="trend-single-day">
      {activeTab === "Summary" ? (
        <SummaryTerm
          data={data}
          date={date}
          currentManufacturer={currentManufacturer}
          termType={"Search Term"}
          storeShareSingleDay={"currentShareSearchSingleDay"}
          type={type}
          actionSingleProduct={actionSingleProduct}
          storeSingleProduct={storeSingleProduct}
          actionSingleProductSummary={actionSingleProductSummary}
          actionSingleProductSetPeriod={actionSingleProductSetPeriod}
          actionRemoveProduct={actionRemoveProduct}
          actionProductCompare={actionProductCompare}
          storeProductCompare={storeProductCompare}
          actionSingleProductCompareSummary={actionSingleProductCompareSummary}
          actionSingleProductSetCompare={actionSingleProductSetCompare}
          statusProduct={statusProduct}
          isFeaturedProducts={isFeaturedProducts}
          setIsFeaturedProducts={setIsFeaturedProducts}
        />
      ) : (
        <AllTerms
          data={data}
          date={date}
          activeTab={activeTab}
          isOpen={false}
          storeShareSingleDay={"currentShareSearchSingleDay"}
          type={type}
          actionSingleProduct={actionSingleProduct}
          storeSingleProduct={storeSingleProduct}
          actionRemoveProduct={actionRemoveProduct}
          actionProductCompare={actionProductCompare}
          storeProductCompare={storeProductCompare}
          statusProduct={statusProduct}
          isFeaturedProducts={isFeaturedProducts}
          setIsFeaturedProducts={setIsFeaturedProducts}
        />
      )}
    </div>
  );
};

export default TrendSingleDay;
