import React, { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import RowOfBrands from "components/RowOfBrands";
import { changeNameRetailer } from "utils/changeNameRetailer";
import { Empty } from "antd";

const TrendAnalysisCategoryChart = (props) => {
  const { data, filter, arrayOfDesiredBrand, setArrayOfDesiredBrand } = props;

  //Ref
  const chartRef = useRef(null);

  //States
  const [dates, setDates] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [pricings, setPricings] = useState([]);
  const [names, setNames] = useState([]);
  const [emptyData, setEmptyData] = useState(false);
  const [isTooMoreData, setIsTooMoreData] = useState(false);
  const [hasClearedAll, setHasClearedAll] = useState(false);

  //Constants
  const isBrand = filter[1].value === "brand";
  const byReviews = filter[0].value === "count";
  const renderLabelsStep = 16;
  const title = byReviews ? "No. of Reviews" : "Avg. Rating";

  useEffect(() => {
    if (arrayOfDesiredBrand.length === 0 && !hasClearedAll) {
      const uniqueBrandsMap = new Map();
      const ratingData = data.data[filter[1].value]?.chart.rating;

      if (ratingData) {
        ratingData.forEach((dayArray) => {
          const items = dayArray[1] || [];

          items.forEach((entry) => {
            if (entry && entry.item) {
              const { id, name } = entry.item;
              if (!uniqueBrandsMap.has(id)) {
                uniqueBrandsMap.set(id, { id, name });
              }
            }
          });
        });

        const uniqueBrandsArray = Array.from(uniqueBrandsMap.values());
        setArrayOfDesiredBrand(uniqueBrandsArray);
      }
    }
  }, [data, arrayOfDesiredBrand, hasClearedAll]);

  useEffect(() => {
    const currentData = data.data[filter[1].value].chart[filter[0].value];
    let resultArr = [];
    if (arrayOfDesiredBrand.length) {
      for (let index = 0; index < currentData.length; index++) {
        const element = currentData[index];
        const filteredData = element[1].filter(({ item }) => arrayOfDesiredBrand.some((el) => el.name === item.name));
        resultArr = [...resultArr.slice(0, index), [element[0], filteredData], ...resultArr.slice(index + 1)];
      }
      setFilteredData(resultArr);
    } else {
      setFilteredData([]);
    }
  }, [data, arrayOfDesiredBrand, filter]);

  useEffect(() => {
    const currentData = data.data[filter[1].value].chart[filter[0].value];
    if (filteredData.length) {
      const datesMoment = data.data.days.map((item) => moment(item).format("DD MMM YYYY"));
      setDates(datesMoment);

      const names = [...new Set(currentData.map((item) => item[1].map((el) => el.item.name)).flat())];
      const ids = [...new Set(currentData.map((item) => item[1].map((el) => el.item.id)).flat())];
      const filteredNames = [...new Set(filteredData.map((item) => item[1].map((el) => el.item.name)).flat())];
      const colors = [...new Set(currentData.map((item) => item[1].map((el) => el.item.color)).flat())];
      const namesWithColorsAndIds = names.map((el, index) => ({
        name: el,
        color: colors[index],
        id: ids[index],
      }));
      setNames(namesWithColorsAndIds);

      let resultArr = filteredNames.map((name) => ({ name, data: [] }));

      for (let index = 0; index < filteredData.length; index++) {
        const elementArr = filteredData[index][1];
        for (let subIndex = 0; subIndex < elementArr.length; subIndex++) {
          const element = elementArr[subIndex];

          const indexOfMatchedEl = resultArr.findIndex(({ name }) => name === element.item?.name);
          if (indexOfMatchedEl !== -1) {
            resultArr = [
              ...resultArr.slice(0, indexOfMatchedEl),
              {
                name: resultArr[indexOfMatchedEl]?.name,
                data: [...resultArr[indexOfMatchedEl]?.data, element.data],
                color: element.item.color,
                className: isTooMoreData ? "not-highlighted" : "highlighted",
              },
              ...resultArr.slice(indexOfMatchedEl + 1),
            ];
          }
        }
      }
      setPricings(resultArr);
    } else {
      setPricings([]);
    }
  }, [data, filteredData, filter, isTooMoreData]);

  useEffect(() => {
    if (pricings.length) {
      const hasData = pricings.some(({ data }) => data.length);
      setEmptyData(!hasData);
    } else {
      setEmptyData(true);
    }
  }, [pricings]);

  useEffect(() => {
    if (names.length > renderLabelsStep) {
      setIsTooMoreData(!arrayOfDesiredBrand.length);
    } else {
      setIsTooMoreData(false);
    }
  }, [names, isBrand, arrayOfDesiredBrand]);

  const options = {
    title: "",
    chart: {
      type: "areaspline",
      height: 300,
      style: {
        fontFamily: "Gilroy-Medium",
      },
      marginRight: 40,
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
      areaspline: {
        fillColor: 0,
        marker: {
          enabled: false,
        },
        lineWidth: 3,
        threshold: null,
      },
    },
    xAxis: {
      crosshair: {
        width: 1,
        color: "gray",
        dashStyle: "solid",
      },
      type: "datetime",
      labels: {
        step: parseInt(dates.length / 3),
      },
      categories: dates,
    },
    yAxis: {
      title: {
        offset: 20,
        x: 25,
        text: title,
      },
      offset: 28,
      startOnTick: byReviews ? true : false,
      endOnTick: byReviews ? true : false,
      min: byReviews ? null : 0,
      softMax: byReviews ? null : 5,
      labels: {
        formatter: function () {
          const value = this.value;
          if (value >= 1000) {
            return value / 1000 + "k";
          } else {
            return value;
          }
        },
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: null,
      borderWidth: 0,
      zIndex: 999,
      formatter: function () {
        let sortedData = this.points.sort((a, b) => b.y - a.y);
        let initialData = sortedData
          .map((item) => {
            return `
            <div class='wrapper'>
              <div class='box'>
                <div class='color' style='background: ${item.color}'></div>
                <div class='name'>${changeNameRetailer(item.series.options.name)}</div>
              </div>
              <div class='price'>${item.y}</div>
            </div>`;
          })
          .join("");

        return `
          <div class='wrapper-category'>
            <div class='title'>${title}</div>
            <div class='wrapper-box'>
              <div>
                <div class='date'>${moment(this.points[0].key).format("DD MMM YY")}</div>
                <div>${initialData}</div>
              </div>
            </div>
          </div>`;
      },
    },
    series: pricings,
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
  };

  const renderTooMoreData = () => {
    return (
      <div className={hasClearedAll ? "too-more-data-ta" : "display-null"}>
        <Empty
          description={
            <span>
              {hasClearedAll
                ? "Select the buttons below to see the graph"
                : "Looks like we do not have data for this request"}
            </span>
          }
        />
      </div>
    );
  };

  return (
    <div className="chart-price-wrapper" style={{ width: "calc(100% - 412px)" }}>
      <div className="chart-title-desc">Select a product to see its reviews.</div>
      {pricings.length && dates.length ? (
        <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} />
      ) : (
        renderTooMoreData()
      )}
      <div className="brand-labels-row">
        <RowOfBrands
          names={names}
          arrayOfDesiredBrand={arrayOfDesiredBrand}
          setArrayOfDesiredBrand={setArrayOfDesiredBrand}
          withId
          setHasClearedAll={setHasClearedAll}
        />
      </div>
    </div>
  );
};

export default TrendAnalysisCategoryChart;
