import React, { useEffect, useState } from "react";
import { Button, Input, Pagination, Popover } from "antd";
import { connect, useSelector } from "react-redux";
//Actions
import { fetchFilters } from "store/filters/actions";
import {
  fetchSettingsMyGroups,
  deleteSettingsMyGroup,
  editSettingsMyGroup,
  createSettingsMyGroup,
} from "store/settings/settingsProductGroups/actions";
//Assets
import search from "assets/images/search.svg";
import sortIconDown from "assets/images/sort_down.svg";
import sortIconUp from "assets/images/sort_up.svg";
import rubishBin from "assets/images/icons/rubbish-bin-icon.png";
import editIcon from "assets/images/icons/edit-icon.png";
import goDownIcon from "assets/images/icons/go-down-icon.png";
//Components
import LoaderBox from "components/LoaderBox";
import { Styles } from "./styles";

//Utils
import { STATE_STATUSES } from "utils/statuses";
import SelectBox from "components/Selects/SelectBox";
import { FULL_COLORS } from "utils/colors";
import SelectProducts from "./SelectProducts";
import ShowPerPage from "components/ShowPerPage";
import { sortAlphabetically, sortAlphabeticallyReverse } from "utils/generalUtils";
import useFilters from "../../../hooks/useFilters";
import useCountry from "../../../hooks/useCountry";

const SettingsProductGroups = (props) => {
  const {
    filters,
    fetchFilters,
    fetchSettingsMyGroups,
    productGroups,
    deleteSettingsMyGroup,
    editSettingsMyGroup,
    createSettingsMyGroup,
    statusProductGroups,

    productGroupName,
    setProductGroupName,
    selectColor,
    setSelectColor,
    selectCoreProducts,
    setSelectCoreProducts,
    editProductGroup,
    setEditProductGroup,
  } = props;
  const { lastFilter, resetUniversalObj } = useFilters();
  const { currentCountry } = useCountry();
  const { companyId } = useSelector((state) => state.settingsYourProfile.settingsYourProfile);

  const [toggleList, setToggleList] = useState([]);
  const [brandsCount, setBrandsCount] = useState({});
  const [paginationData, setPaginationData] = useState([]);

  const [isOpenHint, setIsOpenHint] = useState({ id: null, value: false });

  const colors = Object.entries(FULL_COLORS).map((el, i) => {
    return { id: i, lable: el[0], value: el[1] };
  });

  const data = {
    name: "select-colour",
    options: colors,
  };

  useEffect(() => {
    const brandsDict = {};
    const productsData = [];

    productGroups.forEach((productGroup) => {
      productsData.push({
        data: productGroup.coreProduct,
        filteredData: sortAlphabetically(productGroup.coreProduct, "title"),
        pagination: {
          list: productGroup.coreProduct.slice(0, 10),
          page: 1,
          perPage: 10,
          sortDirection: "asc",
        },
      });

      productGroup.coreProduct.length === 0
        ? (brandsDict[productGroup.id] = {})
        : productGroup.coreProduct.forEach((product, index) => {
            if (
              product.productBrand &&
              brandsDict[productGroup.id] &&
              brandsDict[productGroup.id][product.productBrand["name"]]
            ) {
              brandsDict[productGroup.id][product.productBrand.name] =
                brandsDict[productGroup.id][product.productBrand.name] + 1;
              return;
            } else if (
              brandsDict[productGroup.id] &&
              product.productBrand &&
              !brandsDict[productGroup.id][product.productBrand["name"]]
            ) {
              brandsDict[productGroup.id][product.productBrand.name] = 1;
              return;
            } else if (product.productBrand && !brandsDict[productGroup.id]) {
              brandsDict[productGroup.id] = {};
              brandsDict[productGroup.id][product.productBrand.name] = 1;
              return;
            } else if (!brandsDict[productGroup.id] && productGroup.coreProduct.length === index + 1) {
              brandsDict[productGroup.id] = {};
              return;
            }
          });
    });
    setPaginationData(productsData);
    setToggleList(Array(productGroups.length).fill(false));
    setBrandsCount({ ...brandsDict });
  }, [productGroups]);

  useEffect(() => {
    fetchSettingsMyGroups();
  }, [fetchFilters, fetchSettingsMyGroups]);

  const handleChangeColor = (value) => {
    setSelectColor(value[data.name]);
  };

  const handleSearch = (value, index, pageSize) => {
    let items = [...paginationData];

    const page = 1;
    const data = items[index].data;
    const filteredData = value.length
      ? data.filter(({ title }) => title?.toLowerCase()?.includes(value?.toLowerCase()))
      : data;

    items[index] = {
      ...items[index],
      filteredData,
      pagination: {
        ...items[index].pagination,
        list: filteredData.slice((page - 1) * pageSize, page * pageSize),
        page,
      },
    };

    setPaginationData([...items]);
  };

  const handleToggle = (index) => {
    let copyToggleList = [...toggleList];
    copyToggleList[index] = !toggleList[index];

    setToggleList([...copyToggleList]);
  };

  const onChangePage = (page, index, pageSize) => {
    let items = [...paginationData];

    const data = items[index].data;
    const filteredData = items[index].filteredData;

    items[index] = {
      ...items[index],
      pagination: {
        ...items[index].pagination,
        list: filteredData.slice((page - 1) * pageSize, page * pageSize),
        page: page,
      },
    };

    setPaginationData([...items]);
  };

  const handleSelectChange = (pageSize, index) => {
    let items = [...paginationData];
    const page = 1;
    const data = items[index].data;
    const filteredData = items[index].filteredData;

    items[index] = {
      ...items[index],
      pagination: {
        ...items[index].pagination,
        list: filteredData.slice((page - 1) * pageSize, page * pageSize),
        page: page,
        perPage: pageSize,
      },
    };

    setPaginationData([...items]);
  };

  const handleSort = (sort, index, pageSize) => {
    let items = [...paginationData];
    const page = 1;
    const filteredData =
      sort === "asc"
        ? sortAlphabeticallyReverse(items[index].filteredData, "title")
        : sortAlphabetically(items[index].filteredData, "title");

    items[index] = {
      ...items[index],
      filteredData,
      pagination: {
        ...items[index].pagination,
        list: filteredData.slice((page - 1) * pageSize, page * pageSize),
        page,
        sortDirection: sort === "asc" ? "desc" : "asc",
      },
    };

    setPaginationData([...items]);
  };

  const handleEditProductGroup = (productGroup) => {
    setSelectCoreProducts(productGroup.coreProduct.map((item) => item.id.toString()));
    handleChangeColor({ [data.name]: productGroup.color });
    setProductGroupName(productGroup.name);
    setEditProductGroup(productGroup);
  };

  const handleGoDown = () => {
    setEditProductGroup({});
    setSelectCoreProducts([]);
    setSelectColor("#F0F8FF");
    setProductGroupName("");
  };

  const onFinishEditProductGroup = () => {
    const ids = selectCoreProducts.map((item) => Number(item));
    const data = {
      products: ids,
      name: productGroupName,
      color: selectColor.length ? selectColor : "#ffffff",
    };
    editSettingsMyGroup(editProductGroup.id, data).then(() => {
      setEditProductGroup({});
      setSelectCoreProducts([]);
      setSelectColor("#F0F8FF");
      setProductGroupName("");
      resetUniversalObj(currentCountry.countryId, companyId);
    });
  };

  const onFinishCreateProductGroup = () => {
    const data = {
      products: selectCoreProducts.length ? selectCoreProducts.map((item) => +item) : [],
      name: productGroupName.length ? productGroupName : "No Name",
      color: selectColor.length ? selectColor : "#ffffff",
    };
    createSettingsMyGroup(data).then(() => {
      setSelectCoreProducts([]);
      setSelectColor("#F0F8FF");
      setProductGroupName("");
      resetUniversalObj(currentCountry.countryId, companyId);
    });
  };

  const editProductGroupPage = (
    <div className="productGroup">
      <div className="text-container">
        <div className="edit-btn-wrapper">
          <img src={goDownIcon} alt="go down icon" onClick={handleGoDown} />
          Edit Custom Group
        </div>
      </div>
      <div className="select-container">
        <div className="select">
          <p>Group Name</p>
          <Input
            placeholder="Eg. Sugar Free"
            value={productGroupName}
            onChange={(e) => setProductGroupName(e.target.value)}
          />
        </div>

        <div className="select">
          <p>Products</p>
          <SelectProducts
            className="ant-input"
            name="products"
            titleParam="title"
            placeholder="Choose Products"
            paramName="product"
            data={filters["product"]}
            activeItems={lastFilter["product"]}
            selectItems={selectCoreProducts}
            setSelectItems={setSelectCoreProducts}
          />
        </div>

        <div className="select">
          <p>Colour</p>
          <SelectBox
            filter={selectColor}
            data={data}
            setSelectValue={handleChangeColor}
            customWidth={"136px"}
            isMargin={true}
          />
        </div>
      </div>
      <Button
        type="primary"
        onClick={onFinishEditProductGroup}
        className="save-button"
        disabled={!productGroupName || !selectCoreProducts.length}
      >
        Save
      </Button>
    </div>
  );

  const productGroupsList = (
    <div className="productGroup">
      <div className="text-container">
        Having custom groups allows you to quickly filter data. By selecting a group products, you can only see
        information about those products.
      </div>
      <div className="select-container">
        <div className="select">
          <p>Group Name</p>
          <Input
            placeholder="Eg. Sugar Free"
            value={productGroupName}
            onChange={(e) => setProductGroupName(e.target.value)}
          />
        </div>

        <div className="select">
          <p>Products</p>
          <SelectProducts
            className="ant-input"
            name="products"
            titleParam="title"
            placeholder="Choose Products"
            paramName="product"
            data={filters["product"]}
            activeItems={lastFilter["product"]}
            selectItems={selectCoreProducts}
            setSelectItems={setSelectCoreProducts}
          />
        </div>

        <div className="select">
          <p>Colour</p>
          <SelectBox
            filter={selectColor}
            data={data}
            setSelectValue={handleChangeColor}
            customWidth={"136px"}
            isMargin={true}
          />
        </div>
      </div>

      <Button
        type="primary"
        onClick={onFinishCreateProductGroup}
        disabled={!productGroupName || !selectCoreProducts.length}
      >
        Create Custom Group
      </Button>

      <div className="table-container">
        {productGroups && paginationData.length !== 0
          ? productGroups.map((productGroup, index) => {
              return (
                <div key={productGroup.id} className="table">
                  <div className="item-box header">
                    <div
                      className="item-box col-1"
                      onClick={productGroup.coreProduct.length ? () => handleToggle(index) : () => {}}
                      style={{ cursor: productGroup.coreProduct.length ? "pointer" : "not-allowed" }}
                    >
                      <div>
                        <div className="plus-btn-expand" style={{ color: "#e0e0e4" }}>
                          {toggleList[index] ? "-" : "+"}
                        </div>
                      </div>
                      <div className="item-color" style={{ backgroundColor: productGroup.color }}></div>
                      <div className="item-text item-group-name">{productGroup.name}</div>
                      <div className="item-text products-selected">
                        {productGroup.coreProduct.length === 1
                          ? productGroup.coreProduct.length + " Product"
                          : productGroup.coreProduct.length + " Products"}
                      </div>
                    </div>
                    <div className="item-box col-2">
                      <div className="item-text brands-selected">
                        {productGroups.length === Object.keys(brandsCount).length
                          ? productGroup.coreProduct.length === 0
                            ? "0 Brands"
                            : brandsCount &&
                              Object.keys(brandsCount).length !== 0 &&
                              Object.keys(brandsCount[productGroup.id]).length === 1
                            ? Object.keys(brandsCount[productGroup.id]).length + " Brand"
                            : (brandsCount && Object.keys(brandsCount).length) !== 0
                            ? Object.keys(brandsCount[productGroup.id]).length + " Brands"
                            : null
                          : null}
                      </div>
                      <div className="item-text">
                        <Popover
                          content={
                            <div className="delete-alert-hint">
                              <div className="title">Do you want to remove this group?</div>
                              <div className="wrapper-btn">
                                <div className="cancel" onClick={() => setIsOpenHint({ id: null, value: false })}>
                                  Cancel
                                </div>
                                <div
                                  className="remove"
                                  onClick={() =>
                                    deleteSettingsMyGroup(productGroup.id).then(() => {
                                      resetUniversalObj(currentCountry.countryId, companyId);
                                    })
                                  }
                                >
                                  Remove
                                </div>
                              </div>
                            </div>
                          }
                          trigger="click"
                          visible={isOpenHint.value && isOpenHint.id === productGroup.id}
                          onVisibleChange={(visible) => setIsOpenHint({ id: productGroup.id, value: visible })}
                        >
                          <img
                            className="item-tools"
                            src={rubishBin}
                            alt="delete button"
                            onClick={() => setIsOpenHint({ id: productGroup.id, value: true })}
                          />
                        </Popover>
                        <img
                          className="item-tools"
                          src={editIcon}
                          alt="edit button"
                          onClick={() => handleEditProductGroup(productGroup)}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ display: toggleList[index] ? "block" : "none" }}>
                    <div className="item-box header">
                      <div className="col-1">
                        <div className="item-text">
                          <div className="search">
                            <img className="search-icon" src={search} alt="search" />
                            <input
                              onChange={(e) =>
                                handleSearch(e.target.value, index, paginationData[index].pagination.perPage)
                              }
                              placeholder="Search for a Product"
                            />

                            <img
                              className="sort-icon"
                              src={
                                paginationData[index]?.pagination.sortDirection === "asc" ? sortIconUp : sortIconDown
                              }
                              alt="sort icon"
                              onClick={() =>
                                handleSort(
                                  paginationData[index].pagination.sortDirection,
                                  index,
                                  paginationData[index].pagination.perPage
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="item-text item-brand">Brand</div>
                      </div>
                    </div>
                    {paginationData[index]?.pagination.list.map((item) => {
                      return (
                        <div key={item.id} className="item-box">
                          <div className="col-1 item-product">
                            <img src={item.image} alt="product-picture" /> {item.title}
                          </div>
                          {item.productBrand ? (
                            <div className="col-2 item-product">
                              <div
                                className="colour"
                                style={{
                                  backgroundColor: item.productBrand.color,
                                }}
                              ></div>{" "}
                              {item.productBrand.name}
                            </div>
                          ) : (
                            <div className="col-2 item-product">
                              <div className="colour" style={{ backgroundColor: "gray" }}></div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                    {!paginationData[index]?.filteredData.length ? null : (
                      <div className="pagination-with-per-page">
                        <Pagination
                          style={{ margin: "12px 0 32px 0" }}
                          className="pagination-controls"
                          onChange={(page) => onChangePage(page, index, paginationData[index].pagination.perPage)}
                          current={paginationData[index].pagination.page}
                          pageSize={paginationData[index].pagination.perPage}
                          total={paginationData[index].filteredData.length}
                          showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
                        />
                        <ShowPerPage
                          setLimit={(value) => handleSelectChange(value, index)}
                          value={paginationData[index].pagination.perPage}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })
          : null}
      </div>
    </div>
  );

  return (
    <Styles>
      {statusProductGroups !== STATE_STATUSES.READY
        ? null
        : Object.keys(editProductGroup).length === 0
        ? productGroupsList
        : editProductGroupPage}
      {statusProductGroups === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
    </Styles>
  );
};

export default connect(
  (state) => ({
    filters: state.filters.filters,
    statusFilters: state.filters.status,
    statusAuth: state.authorization.status,
    productGroups: state.settingsProductGroups.settingsProductGroups,
    statusProductGroups: state.settingsProductGroups.status,
  }),
  {
    fetchFilters,
    fetchSettingsMyGroups,
    deleteSettingsMyGroup,
    editSettingsMyGroup,
    createSettingsMyGroup,
  }
)(SettingsProductGroups);
