import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import _ from "lodash";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { setOrderSettingsRetailers } from "store/settings/actions";
import { fetchSettingsYourProfile } from "store/settings/settingsYourProfile/actions";

import { Styles } from "./styles";

import LoaderBox from "components/LoaderBox";

import { STATE_STATUSES } from "utils/statuses";
import useRetailers from "../../../hooks/useRetailers";

const SettingsRetailerDisplay = ({ retailersOrder, setRetailersOrder }) => {
  const dispatch = useDispatch();
  const { getRemoteLogo } = useRetailers();

  const {
    filters: { sourceType },
  } = useSelector((state) => state.filters);
  const {
    settingsYourProfile: { retailers },
    status,
  } = useSelector((state) => state.settingsYourProfile);
  const { status: settingsStatus } = useSelector((state) => state.settings);

  useEffect(() => {
    if (sourceType?.length && retailers?.length) {
      const sortedCurrentOrder = _.sortBy(sourceType, (item) => retailers.indexOf(item.id));
      setRetailersOrder(sortedCurrentOrder);
    }
  }, [retailers, sourceType]);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(retailersOrder);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setRetailersOrder(items);
  };

  const onSave = () => {
    const ids = retailersOrder.map((retailer) => retailer.id);

    dispatch(setOrderSettingsRetailers(ids)).then(() => dispatch(fetchSettingsYourProfile()));
  };

  const Reset = () => {
    const items = [...retailersOrder];
    items.sort((a, b) => a.id - b.id);

    setRetailersOrder(items);
  };

  return (
    <Styles>
      <div className="retailer-tab-text-container">
        <span>Which Retailers interest you most? You can define your preferred sort order below.</span>
        <p>Simply drag and drop Retailers to change the default order.</p>
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable direction="horizontal" droppableId="characters">
          {(provided) => (
            <ul className="retailer-tab-retailers-container" {...provided.droppableProps} ref={provided.innerRef}>
              {retailersOrder
                ? retailersOrder.map((retailer, index) => {
                    return (
                      <Draggable key={retailer.name} draggableId={retailer.id.toString()} index={index}>
                        {(provided) => (
                          <li
                            className="retailer-tab-retailers-section"
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <p>{index + 1}</p>
                            <img src={getRemoteLogo(retailer.name)}></img>
                          </li>
                        )}
                      </Draggable>
                    );
                  })
                : null}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
      <div className="retailer-tab-buttons">
        <Button type="default" onClick={Reset}>
          Reset
        </Button>
        <Button type="primary" onClick={onSave}>
          Save
        </Button>
      </div>
      {status === STATE_STATUSES.PENDING || settingsStatus === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
    </Styles>
  );
};

export default SettingsRetailerDisplay;
