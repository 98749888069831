import { COLORS } from "assets/colors/colors";
import styled from "styled-components";

export const Styles = styled.div.attrs((props) => props)`
  .popup-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
    .popup-box {
      max-width: 1240px;
      height: ${(props) => props.topRowMargin * 15 + 848}px;
      overflow: hidden;
      width: 100%;
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 8px 20px 0 rgba(30, 31, 32, 0.18), 0 2px 4px 0 rgba(0, 0, 0, 0.17);
      padding: 40px;
      position: relative;

      .cancel {
        position: absolute;
        width: 24px;
        height: 24px;
        right: 40px;
        top: 40px;
        cursor: pointer;
      }
    }
  }
  .top-block {
    display: flex;
    align-items: center;
    margin-bottom: ${(props) => props.topRowMargin * 15}px;
  }
  .img-wrapper {
    max-width: 120px;
    display: flex;
    & > img {
      width: 120px;
    }
  }
  .row {
    margin: 0 30px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 120px;
  }
  .title-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 17px 8px 7px 0;
    & > span {
      font-size: 22px;
      font-family: "Gilroy-ExtraBold";
      font-weight: 800;
    }
  }
  .web-site {
    display: flex;
    cursor: pointer;
    & > img {
      max-width: 24px;
      margin-right: 16px;
    }
    & > a {
      font-size: 16px;
      font-family: "Gilroy-ExtraBold";
      font-weight: 800;
      color: ${(props) => props.color};
    }
  }
  .review-wrapper {
    display: flex;
  }
  .avg-rating {
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
    background-color: ${COLORS.lightGrayOpacity};
    width: 150px;
    height: 92px;
    justify-content: center;
    align-items: center;
    margin: 0 8px;
    & > span {
      font-size: 14px;
      font-family: "Gilroy-ExtraBold";
      font-weight: 800;
      padding-bottom: 15px;
    }
    .no-reviews {
      font-size: 10px;
      font-family: Gilroy-ExtraBold;
      color: #abaaba;
      padding-bottom: 0 !important;
    }
    .reviews-count {
      font-size: 22px !important;
      padding-bottom: 0 !important;
    }
  }
  .avg-rating.review {
    width: 100px;
  }
  .table {
    margin-top: 52px;
  }
  .table-item {
    img {
      width: 25px;
    }
  }
  .show-all-wrapper {
    width: 90.5%;
    position: absolute;
    display: flex;
    justify-content: right;
  }
  .show-all-btn {
    width: 92.5%;
    background: #eef2f7;
    border: none;
    height: 44px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin-left: 14px;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    font-family: "Gilroy-Medium";
  }
`;
