import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Checkbox, Tooltip } from "antd";

import { fetchRankingLocationOverview } from "store/ranking/rankingLocationOverview/actions";

import OverviewPieChart from "tabs/RankingTabs/RankingTopTabs/SearchOverviewTab/OverviewPieChart";
import SelectBox from "components/Selects/SelectBox";
import LoaderBox from "components/LoaderBox";
import ChartLegends from "components/ChartLegends";
import useFilters from "../../../../hooks/useFilters";

import { getTimePeriodRanking } from "utils/getTimePeriodRanking";
import { STATE_STATUSES } from "utils/statuses";
import { changeNameRetailer } from "utils/changeNameRetailer";

import about from "assets/images/about.svg";

const selects = [
  {
    name: "shareSize",
    default: "5",
    options: [
      { lable: "Share of Top 5", value: "5" },
      { lable: "Share of Top 10", value: "10" },
      { lable: "Share of Top 20", value: "20" },
    ],
  },
];

const LocationOverviewTab = (props) => {
  const {
    status,
    rankingLocationOverview,
    fetchRankingLocationOverview,
    isFeaturedProducts,
    setIsFeaturedProducts,
    isActive,
  } = props;
  const { mainLastFilter: lastFilter, statusFilters } = useFilters();

  const [optionsTab, setOptionsTab] = useState({
    shareSize: "5",
  });

  const [legends, setLegends] = useState([]);
  const [selectedLegend, setSelectedLegend] = useState([]);

  const getRankingTop = () => {
    const rankingTop = {
      5: "rankingTop5",
      10: "rankingTop10",
      20: "rankingTop20",
    };

    return rankingTop[optionsTab.shareSize];
  };

  const handleClickLable = (name) => {
    if (selectedLegend.includes(name)) {
      const items = selectedLegend.filter((item) => item !== name);
      setSelectedLegend(items);
    } else {
      setSelectedLegend((prevState) => [...prevState, name]);
    }
  };

  useEffect(() => {
    if (isActive && statusFilters === STATE_STATUSES.READY) {
      fetchRankingLocationOverview({
        sourceType: lastFilter.sourceType,
        timePeriod: getTimePeriodRanking(lastFilter.date),
        product: lastFilter.product,
        featured: isFeaturedProducts,
      });
    }

  }, [fetchRankingLocationOverview, lastFilter, isFeaturedProducts, isActive, statusFilters]);
  useEffect(() => {
    if (Object.entries(rankingLocationOverview).length !== 0) {
      const items = [];

      rankingLocationOverview["byRetailer"][getRankingTop()].data.forEach((item) =>
        item.manufacturers.forEach((manufacturer) => items.push(manufacturer))
      );

      const dataArr = items.map((item) => [item.title, item]);

      const mapArr = new Map(dataArr);

      const legends = [...mapArr.values()];

      setLegends(legends);
    }
  }, [rankingLocationOverview, optionsTab]);

  const setSelectValue = (values) => {
    const value = Object.keys(values);

    setOptionsTab((prevState) => ({
      ...prevState,
      [value[0]]: values[value],
    }));
  };

  return (
    <div>
      {rankingLocationOverview["byRetailer"] ? (
        <div className="filters-box">
          <div className="onlyProducts right-wrapper">
            <Checkbox checked={isFeaturedProducts} onChange={() => setIsFeaturedProducts(!isFeaturedProducts)}>
              Sponsored Products
            </Checkbox>
          </div>
          {selects.map((item, index) => (
            <SelectBox
              key={index}
              data={item}
              disabled={status !== STATE_STATUSES.READY}
              setSelectValue={setSelectValue}
            />
          ))}
        </div>
      ) : null}
      <div className="wrapper-box-relative">
        {rankingLocationOverview["byRetailer"] ? (
          <>
            <div className="title-hint">
              You are currently viewing
              <span> {rankingLocationOverview["byRetailer"][getRankingTop()].searchTermsCount} Locations </span>
              across the selected Retailers.
            </div>
            <div className="wrapper-charts">
              {rankingLocationOverview["byRetailer"][getRankingTop()].data.length ? (
                rankingLocationOverview["byRetailer"][getRankingTop()].data
                  .sort((a, b) => a["title"].localeCompare(b["title"]))
                  .map((item, index) => (
                    <div className="chart-box" key={index}>
                      <div className="title">
                        <span>{changeNameRetailer(item.title)}</span>
                        {/* <Tooltip
                          color={"#fff"}
                          title={<div className="tooltip-piechart">{parseInt(item.manufacturers[0].percent / 10)}%</div>}
                        >
                          <img src={about} alt="icon" />
                        </Tooltip> */}
                      </div>
                      <OverviewPieChart
                        name={item.title}
                        data={item.manufacturers}
                        titlePopup={"Location Share in"}
                        selectedLegend={selectedLegend}
                      />
                    </div>
                  ))
              ) : (
                <div className="chart-no-data static">Looks like we don't have data for this request</div>
              )}
            </div>
            {legends.length ? (
              <ChartLegends
                param={"title"}
                legends={legends}
                selectedLegends={selectedLegend}
                legendLength={10}
                setLegends={handleClickLable}
              />
            ) : null}
          </>
        ) : null}

        {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    rankingLocationOverview: state.rankingLocationOverview.rankingLocationOverview,
    status: state.rankingLocationOverview.status,
  }),
  { fetchRankingLocationOverview }
)(LocationOverviewTab);
