import React from "react";
import CustomLoader from "components/CustomLoader";
import styled from "styled-components";

const Styles = styled.div`
  position: absolute;
  top: 0;
  left: ${(props) => (props.isOpacity ? "100px" : "0")};
  width: ${(props) => (props.isOpacity ? "calc(100% - 100px)" : "100%")};
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 111;
  background: ${(props) =>
    props.isOpacity ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, .6)"};
`;

const LoaderBox = ({ isOpacity, style }) => {
  return (
    <Styles isOpacity={isOpacity} style={style}>
      <CustomLoader />
    </Styles>
  );
};

export default LoaderBox;
