import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Collapse, Pagination } from "antd";
import useRetailers from "../../../hooks/useRetailers";

import SelectBox from "components/Selects/SelectBox";

import { removePurchaseProduct } from "store/navigation/navigationPathPurchase/actions";

import { CollapseClose, CollapseOpen } from "assets/icons/icons";

import { Cancel } from "assets/icons/icons";
import cup from "assets/images/cup.svg";
import clicks from "assets/images/clicks.svg";
import sort from "assets/images/sort.svg";
import sortIconUp from "assets/images/sort_up.svg";
import sortIconDown from "assets/images/sort_down.svg";

const selects = [
  {
    name: "renderItems",
    default: "10",
    options: [
      { lable: "10", value: "10" },
      { lable: "15", value: "15" },
      { lable: "20", value: "20" },
    ],
  },
];

const ProductBox = ({ data }) => {
  const { searchTerms, locations } = data;

  const { Panel } = Collapse;

  const dispatch = useDispatch();
  const { getRemoteLogo } = useRetailers();

  const [sortParam, setSortParam] = useState("title");
  const [directionSort, setDirectionSort] = useState(true);

  const [locationPage, setLocationPage] = useState(0);
  const [locationItems, setLocationItems] = useState(10);

  const [searchPage, setSearchPage] = useState(0);
  const [searchItems, setSearchItems] = useState(10);

  const handleRemoveProduct = () => dispatch(removePurchaseProduct(data.id));

  const handleSortProduct = (param) => {
    if (sortParam !== param) {
      setDirectionSort(true);
    } else {
      setDirectionSort(!directionSort);
    }
    setSortParam(param);
  };

  const sortProducts = (data) => {
    switch (sortParam) {
      case "title":
      case "retailer": {
        return data.sort((first, second) => {
          if (directionSort) {
            if (second[sortParam] > first[sortParam]) {
              return -1;
            }
          } else {
            if (first[sortParam] > second[sortParam]) {
              return -1;
            }
          }
        });
      }
      case "rank":
      case "clicksToPurchase": {
        return data.sort((first, second) => {
          if (directionSort) {
            return second[sortParam] - first[sortParam];
          } else {
            return first[sortParam] - second[sortParam];
          }
        });
      }
      default: {
        break;
      }
    }
  };

  const renderData = (data, page, items) => {
    const limit = page * items + items < data.length ? page * items + items : data.length;

    return sortProducts(data).slice(page * items, limit);
  };

  const onChangePage = (page, pageSize, name) => {
    const methods = {
      Locations: setLocationPage,
      "Search Term": setSearchPage,
    };
    methods[name](page - 1);
  };

  const onChangeItems = (values, name) => {
    const methods = {
      Locations: setLocationItems,
      "Search Term": setSearchItems,
    };
    methods[name](Number(values["renderItems"]));
  };

  const renderHeaderCollapse = (data, name) => (
    <div className="headaer-collapse-purchase">
      <div className="headaer-collapse-title">
        {data.items.length} {name}
      </div>
      <div className="headaer-collapse-retailer">{data.retailers} Retailers</div>
      <div className="headaer-collapse-value amount">
        <img src={cup} alt="cup" /> <div>{data.rank}</div>
      </div>
      <div className="headaer-collapse-value clicks">
        <img src={clicks} alt="clicks" /> <div>{data.clicksToPurchase}</div>
      </div>
    </div>
  );

  const renderCollapseWrapper = (data, name, page, items) => (
    <Collapse
      accordion
      expandIcon={({ isActive }) =>
        isActive ? (
          <div style={{ position: "absolute", left: "0" }}>
            <CollapseOpen color={"#B6BFC9"} />
          </div>
        ) : (
          <div style={{ position: "absolute", left: "0" }}>
            <CollapseClose color={"#B6BFC9"} />
          </div>
        )
      }
    >
      <Panel header={renderHeaderCollapse(data, name)} key="1">
        <div className="table-product-header">
          <div className="table-product-title" onClick={() => handleSortProduct("title")}>
            <span>{name}</span>
            <img src={sortParam === "title" ? (directionSort ? sortIconUp : sortIconDown) : sort} />
          </div>
          <div className="table-product-retailer" onClick={() => handleSortProduct("retailer")}>
            <span>Retailer</span>
            <img src={sortParam === "retailer" ? (directionSort ? sortIconUp : sortIconDown) : sort} />
          </div>
          <div className="table-product-rank" onClick={() => handleSortProduct("rank")}>
            <span>Rank</span>
            <img src={sortParam === "rank" ? (directionSort ? sortIconUp : sortIconDown) : sort} />
          </div>
          <div className="table-product-clicks" onClick={() => handleSortProduct("clicksToPurchase")}>
            <span>Clicks to Purchase</span>
            <img src={sortParam === "clicksToPurchase" ? (directionSort ? sortIconUp : sortIconDown) : sort} />
          </div>
        </div>
        {renderData(data.items, page, items).map((item, index) => (
          <div key={index} className="table-product-item">
            <div className="product-item-title">{item.title}</div>
            <div className="product-item-retailer">
              <img src={getRemoteLogo(item.retailer)} />
            </div>
            <div className="product-item-rank">{item.rank}</div>
            <div className="product-item-clicksToPurchase">{item.clicksToPurchase}</div>
          </div>
        ))}
        {data.items.length > 10 ? (
          <div className="pagimation-wrapper">
            <Pagination
              className="pagination-controls"
              total={data.items.length}
              pageSize={items}
              defaultCurrent={1}
              onChange={(page, pageSize) => onChangePage(page, pageSize, name)}
              showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
            />
            {data.items.length >= 20
              ? selects.map((item, index) => (
                  <div className="per-page-box" key={index}>
                    <span>Show</span>
                    <SelectBox key={index} data={item} setSelectValue={(values) => onChangeItems(values, name)} />
                    <span style={{ marginLeft: "15px" }}>Per Page</span>
                  </div>
                ))
              : null}
          </div>
        ) : null}
      </Panel>
    </Collapse>
  );

  return (
    <div className="purchase-product-wrapper">
      <div className="purchase-product-header">
        <img className="purchase-product-image" src={data.image} alt="product" />
        <div className="purchase-product-box">
          <div className="purchase-product-title">{data.title}</div>
          <div className="purchase-product-info">
            <div className="purchase-product-average">
              <div>{data.averageRank}</div>
              <div>Average Rank</div>
            </div>
            <div className="purchase-product-average">
              <div>{data.averageClicksToPurchase}</div>
              <div>Average Clicks to Buy</div>
            </div>
          </div>
        </div>

        <div className="purchase-product-cancel" onClick={handleRemoveProduct}>
          <Cancel fill={"#8A99A8"} />
        </div>
      </div>

      {locations.items.length ? renderCollapseWrapper(locations, "Locations", locationPage, locationItems) : null}
      {searchTerms.items.length ? renderCollapseWrapper(searchTerms, "Search Term", searchPage, searchItems) : null}
    </div>
  );
};

export default ProductBox;
