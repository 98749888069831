const url = process.env.REACT_APP_URL;

export const FETCH_ASSORTMENT_SUMMARY_TOP = "FETCH_ASSORTMENT_SUMMARY_TOP";
export const fetchAssortmentSummaryTop = (params) => ({
  type: FETCH_ASSORTMENT_SUMMARY_TOP,
  request: {
    url: `${url}/v2/assortment/summary`,
    method: "POST",
    data: params,
  },
});

export const FETCH_ASSORTMENT_SUMMARY_TOP_COMPARE = "FETCH_ASSORTMENT_SUMMARY_TOP_COMPARE";
export const fetchAssortmentSummaryTopCompare = (params) => ({
  type: FETCH_ASSORTMENT_SUMMARY_TOP_COMPARE,
  request: {
    url: `${url}/v2/assortment/summary`,
    method: "POST",
    data: params,
  },
});

export const SET_PRISTINE_ASSORTMENT_SUMMARY_TOP = "SET_PRISTINE_ASSORTMENT_SUMMARY_TOP";
export const setPristineAssortmentSummaryTop = (pristine) => {
  return {
    type: SET_PRISTINE_ASSORTMENT_SUMMARY_TOP,
    payload: pristine,
  };
};

export const SET_ASSORTMENT_SUMMARY_TOP_FILTER = "SET_ASSORTMENT_SUMMARY_TOP_FILTER";
export const setAssortmentSummaryTopFilter = (filter) => {
  localStorage.setItem(SET_ASSORTMENT_SUMMARY_TOP_FILTER, JSON.stringify(filter));
  return {
    type: SET_ASSORTMENT_SUMMARY_TOP_FILTER,
    payload: filter,
  };
};

export const RESET_ASSORTMENT_SUMMARY_TOP_COMPARE = "RESET_ASSORTMENT_SUMMARY_TOP_COMPARE";
export const resetAssortmentSummaryTopCompare = (row) => {
  return {
    type: RESET_ASSORTMENT_SUMMARY_TOP_COMPARE,
    payload: row,
  };
};
