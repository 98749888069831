import { success, error } from "redux-saga-requests";
import { FETCH_SETTINGS_YOUR_PRODUCTS, SET_ORDER_SETTINGS_RETAILERS, SET_PRISTINE_SETTINGS } from "./actions";
import { STATE_STATUSES } from "utils/statuses";
import { RESET_ERROR } from "store/error/actions";

const initialState = {
  settingsSubscriptions: {
    retailers: [],
    locations: [],
    searchTerms: [],
  },
  settingsYourProducts: {
    count: 0,
    products: [],
  },
  status: STATE_STATUSES.READY,
  isPristine: true,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SETTINGS_YOUR_PRODUCTS:
    case SET_ORDER_SETTINGS_RETAILERS: {
      return processReducer(state);
    }
    case success(FETCH_SETTINGS_YOUR_PRODUCTS): {
      return {
        ...state,
        settingsYourProducts: {
          count: action.data.countProducts,
          products: action.data.products,
        },
        isPristine: false,
        status: STATE_STATUSES.READY,
      };
    }

    case success(FETCH_SETTINGS_YOUR_PRODUCTS):
    case success(SET_ORDER_SETTINGS_RETAILERS): {
      return {
        ...state,
        status: STATE_STATUSES.READY,
      };
    }
    case error(FETCH_SETTINGS_YOUR_PRODUCTS):
    case error(SET_ORDER_SETTINGS_RETAILERS): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case SET_PRISTINE_SETTINGS: {
      return {
        ...state,
        isPristine: action.payload,
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
