import { success, error } from "redux-saga-requests";
import {
  FETCH_RATING_SUMMARY_BOTTOM,
  SET_RATING_SUMMARY_FILTER_BOTTOM,
  SET_PRISTINE_RATING_SUMMARY_TABLE,
} from "./actions";
import { STATE_STATUSES } from "utils/statuses";
import { RESET_ERROR } from "store/error/actions";

const defaultFilter = [
  {
    name: "rating",
    value: ["All"],
  },
  {
    value: [0, 100],
    name: "countRange",
  },
];

const initialState = {
  ratingSummaryBottom: {
    success: false,
    productCount: 0,
    avgReviewCount: 0,
    byBrand: [],
    byRetailer: [],
    countRange: {},
    daysRange: {},
  },

  bottomFilter: defaultFilter,
  status: STATE_STATUSES.INIT,
  isPristine: true,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RATING_SUMMARY_BOTTOM: {
      return processReducer(state);
    }

    case success(FETCH_RATING_SUMMARY_BOTTOM): {
      return {
        ...state,
        ratingSummaryBottom: action.data,
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }

    case error(FETCH_RATING_SUMMARY_BOTTOM): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case SET_RATING_SUMMARY_FILTER_BOTTOM: {
      return {
        ...state,
        bottomFilter: action.payload,
      };
    }
    case SET_PRISTINE_RATING_SUMMARY_TABLE: {
      return {
        ...state,
        isPristine: action.payload,
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
