import React from "react";
import useColorTheme from "../../hooks/useColorTheme";
import { Styles } from "./styles";

const UnsavedProfileModal = ({ onCancel, onDiscard }) => {
  const { primaryColor } = useColorTheme();

  return (
    <Styles color={primaryColor}>
      <div className="modal-window-container">
        <div className="modal-window">
          <div className="text">
            <span>You have unsaved changes.</span>
            <span>Do you want to discard them?</span>
          </div>
          <div className="buttons-wrapper">
            <button onClick={onCancel}>Cancel</button>
            <button onClick={onDiscard}>Discard</button>
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default UnsavedProfileModal;
