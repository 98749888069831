import React, { useState, useEffect, useRef } from "react";
import useColorTheme from "../hooks/useColorTheme";
import { connect } from "react-redux";
import { Button, Radio, DatePicker } from "antd";
import moment from "moment";
import styled from "styled-components";
import { getCompareTimePeriod } from "utils/getCompareTimePeriod";
import { getTimePeriod } from "utils/getTimePeriod";
import useFilters from "hooks/useFilters";

import { Cancel } from "assets/icons/icons";
import calendar from "assets/images/calendar.svg";

const Styles = styled.div.attrs((props) => props)`
  .wrapper-compare {
    position: relative;
  }
  .compare-box {
    position: relative;
    height: 44px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    background: #eef2f7;
    padding: 10px 10px 10px 15px;
    font-size: 14px;
    color: #000000;
    border-radius: 4px;
    cursor: pointer;
    font-family: "Gilroy-Medium";
  }
  .radioBox {
    border-radius: 2px;
    box-shadow: 0 30px 50px 0 rgba(29, 30, 78, 0.1), 0 0 6px 0 rgba(27, 28, 51, 0.08);
    background-color: #ffffff;
    position: absolute;
    padding: 25px 12px;
    top: 45px;
    right: 0;
  }
  .ant-radio-group {
    display: flex;
    flex-direction: column;
    .ant-radio-wrapper {
      margin-bottom: 20px;
    }
    & > :nth-child(3) {
      margin-bottom: 0;
    }
  }
  .hint {
    color: #abaaba;
    font-size: 12px;
    width: 164px;
    display: block;
    margin-bottom: 35px;
    font-weight: bold;
    line-height: 18px;
  }
  .pangePicker-box {
    display: block;
    width: 610px;
    height: 420px;
    padding: 25px;
    position: absolute;
    left: -610px;
    top: 0;
    border-radius: 2px;
    box-shadow: 0 30px 50px 0 rgba(29, 30, 78, 0.1), 0 0 6px 0 rgba(27, 28, 51, 0.08);
    background-color: #ffffff;
  }
  .compare-title {
    margin-right: 15px;
  }
  .aplly-btn {
    position: absolute;
    bottom: 25px;
    right: 25px;
    border-radius: 4px;
    background-color:  ${(props) => props.color};
    color: #fff;
    font-weight: bold;
    border: none;
    padding: 8px 16px;
    height: auto;
  }
  .ant-btn[disabled] {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
  }

  .ant-picker.ant-picker-range ~ div {
    position: static !important;
    .ant-picker-dropdown.ant-picker-dropdown-range {
      position: static !important;
    }
  }
`;

const CompareBox = (props) => {
  const { primaryColor } = useColorTheme();
  const { lastFilter } = useFilters();
  const { RangePicker } = DatePicker;

  const { setComparativeValue, filters, titleDate, isResetCompare, disabled } = props;

  const radioButtons = [
    {
      value: "previousPeriod",
      lable: "Previous Period",
    },
    {
      value: "lastYearPeriod",
      lable: "Same Period Last Year",
    },
    {
      value: "customPeriod",
      lable: "Custom Date Range",
    },
  ];

  const wrapperRef = useRef(null);

  const [value, setValue] = useState("");
  const [initCustomDate, setInitCustmDate] = useState({});
  const [finalCustomDate, setFinalCustomDate] = useState({});
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    setValue("");
    setFinalCustomDate("");
    setComparativeValue("");
  }, [filters]);

  useEffect(() => {
    if (isResetCompare) {
      setValue("");
      setFinalCustomDate("");
      setComparativeValue("");
    }
  }, [isResetCompare]);

  useEffect(() => {
    switch (value) {
      case "previousPeriod": {
        const date = getCompareTimePeriod("previousPeriod", lastFilter.date);
        setComparativeValue(date);
        break;
      }
      case "lastYearPeriod": {
        const date = getCompareTimePeriod("lastYearPeriod", lastFilter.date);
        setComparativeValue(date);
        break;
      }
      case "customPeriod": {
        if (Object.entries(finalCustomDate).length !== 0) {
          const date = `custom|${finalCustomDate.createdStart}|${finalCustomDate.createdEnd}`;
          setComparativeValue(date);
        }
        break;
      }
      default: {
        break;
      }
    }
  }, [value, finalCustomDate]);

  const toggleCompare = () => {
    if (value?.length) {
      setValue("");
      setFinalCustomDate({});
      setShowDropdown(false);
      setComparativeValue("");
    } else {
      setShowDropdown(!showDropdown);
    }
  };

  const onChangeRadio = (e) => {
    const { value } = e.target;
    if (value === "customPeriod") {
      setValue(e.target.value);
    } else {
      setValue(e.target.value);
      setShowDropdown(false);
    }
  };

  const changeCustomDate = (date, dateString) => {
    const startValue = moment(dateString[0], "DD-MM-YYYY").format("YYYY-MM-DD");
    const endValue = moment(dateString[1], "DD-MM-YYYY").format("YYYY-MM-DD");

    let createdStart = moment(startValue, "YYYY-MM-DD");
    let createdEnd = moment(endValue, "YYYY-MM-DD");

    const compareDateDays = Math.abs(moment.duration(createdStart.diff(createdEnd)).asDays()) + 1;

    const currentDate = getTimePeriod(lastFilter.date);

    const currentDateStart = moment(currentDate.split("|")[1], "YYYY-MM-DD");
    const currentDateEnd = moment(currentDate.split("|")[2], "YYYY-MM-DD");

    const currentDateDays = Math.abs(moment.duration(currentDateStart.diff(currentDateEnd)).asDays()) + 1;

    if (compareDateDays > currentDateDays) {
      const extraDays = compareDateDays - currentDateDays;

      const cutCompareDateEnd = moment(createdEnd).subtract(extraDays, "d").format("YYYY-MM-DD");

      createdStart = moment(createdStart).format("YYYY-MM-DD");
      createdEnd = cutCompareDateEnd;

      setInitCustmDate({ createdStart, createdEnd });
    } else {
      setInitCustmDate({
        createdStart: moment(createdStart).format("YYYY-MM-DD"),
        createdEnd: moment(createdEnd).format("YYYY-MM-DD"),
      });
    }
  };

  const applyCustomDate = () => {
    setFinalCustomDate(initCustomDate);
    setValue("customPeriod");
    setShowDropdown(false);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      if (showDropdown) {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setValue("");
            setFinalCustomDate("");
            setShowDropdown(false);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
    }, [ref, showDropdown]);
  }
  useOutsideAlerter(wrapperRef);

  return (
    <Styles color={primaryColor}>
      <div className="wrapper-compare" ref={wrapperRef}>
        <div
          className="compare-box"
          style={{
            background: `${value?.length ? primaryColor : "#eef2f7"}`,
            color: `${value?.length ? "#fff" : "#000"}`,
            cursor: disabled ? "not-allowed" : "pointer",
          }}
          onClick={disabled ? () => {} : toggleCompare}
        >
          <span className="compare-title">
            {Object.entries(finalCustomDate).length !== 0 && value?.length
              ? `${moment(finalCustomDate.createdStart).format("DD/MM/YYYY")} - ${moment(finalCustomDate.createdEnd).format("DD/MM/YYYY")}`
              : value?.length
              ? radioButtons.find((item) => item.value === value).lable
              : "Compare"}
          </span>
          {value?.length ? (
            <Cancel fill={"#fff"} />
          ) : !value?.length && showDropdown ? (
            <Cancel fill={"#8A99A8"} />
          ) : (
            <img src={calendar} alt="" />
          )}
        </div>
        {showDropdown ? (
          <div className="radioBox">
            <span className="hint">Your current date selection is {titleDate}. Compare to:</span>
            <Radio.Group onChange={onChangeRadio} value={value}>
              {radioButtons.map((item, index) => (
                <Radio key={index} value={item.value} checked={value.includes(item.value)}>
                  {item.lable}
                </Radio>
              ))}
            </Radio.Group>
            {value === "customPeriod" ? (
              <div className="pangePicker-box">
                <RangePicker
                  format={"DD/MM/YYYY"}
                  disabledDate={(current) => {
                    return current && current > moment().endOf("day");
                  }}
                  onChange={(date, dateString) => changeCustomDate(date, dateString)}
                  getPopupContainer={(trigger) => trigger.parentElement}
                  open={true}
                />
                <Button className="aplly-btn" disabled={!Boolean(initCustomDate.createdStart)} onClick={applyCustomDate}>
                  Apply
                </Button>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </Styles>
  );
};

export default connect(
  (state) => ({
    filters: state.filters.filters,
    titleDate: state.filters.titleDate,
  }),
  null
)(CompareBox);
