const url = process.env.REACT_APP_URL;

export const FETCH_PROMOTION_BREAKDOWN = "FETCH_PROMOTION_BREAKDOWN";
export const fetchPromotionBreakdown = (params) => ({
  type: FETCH_PROMOTION_BREAKDOWN,
  request: {
    url: `${url}/v2/promotion/breakdown`,
    method: "POST",
    data: params,
  },
});

export const SET_BREAKDOWN_FILTER = "SET_BREAKDOWN_FILTER";
export const setBreakdownFilter = (filter) => {
  localStorage.setItem(SET_BREAKDOWN_FILTER, JSON.stringify(filter));
  return {
    type: SET_BREAKDOWN_FILTER,
    payload: filter,
  };
};

export const SET_PRISTINE_PROMOTIONS_BREAKDOWN = "SET_PRISTINE_PROMOTIONS_BREAKDOWN";
export const setPristinePromotionsBreakdown = (pristine) => {
  return {
    type: SET_PRISTINE_PROMOTIONS_BREAKDOWN,
    payload: pristine,
  };
};
