import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Checkbox } from "antd";
import { Styles } from "./Styles";
import { fetchPriceRange, fetchComparePriceRange } from "store/pricing/priceRange/actions";

import SelectBox from "components/Selects/SelectBox";
import CompareBox from "components/CompareBox";
import PriceRangeChart from "./PriceRangeChart";
import PriceRangeTable from "./PriceRangeTable";
import LoaderBox from "components/LoaderBox";
import useCountry, { NUMBER_TYPE } from "../../../../hooks/useCountry";
import useCache from "../../../../hooks/useCache";

import { STATE_STATUSES } from "utils/statuses";
import { getTimePeriod } from "utils/getTimePeriod";

const selects = [
  {
    name: "price",
    default: "shelfPrice",
    options: [
      { lable: "Shelf Price", value: "shelfPrice" },
      { lable: "Base Price", value: "basePrice" },
      { lable: "Promoted Price", value: "promotedPrice" },
    ],
  },
];

const PriceRangeTab = (props) => {
  const { status, fetchPriceRange, fetchComparePriceRange, lastFilter } = props;
  const { cachedPriceRange: priceRange } = useCache();

  const { formatCurrencyNumber } = useCountry();

  const [typePrice, setCurrentType] = useState("shelfPrice");

  const [comparativeValue, setComparativeValue] = useState("");
  const [compareData, setCompareData] = useState([]);
  const [isDiscount, setIsDiscount] = useState(false);
  const [maxTotalY, setsMaxTotalY] = useState(0);
  const [maxDiscountY, setMaxDiscountY] = useState(0);
  const [isLoader, setIsLoader] = useState(false);
  const [isResetCompare, setIsResetCompare] = useState(false);

  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    setQueryParams((prevState) => ({
      ...prevState,
      sourceType: lastFilter.sourceType,
      timePeriod: getTimePeriod(lastFilter.date),
      product: lastFilter.product,
      showDiscount: "true",
      type: typePrice,
    }));
  }, [lastFilter, typePrice]);

  useEffect(() => {
    setComparativeValue("");
  }, [typePrice]);

  useEffect(() => {
    if (Object.keys(queryParams).length) {
      fetchPriceRange(queryParams);
    }
  }, [fetchPriceRange, queryParams]);

  const getMaxVAlues = (data) => {
    const arrTotal = [];
    const arrDiscount = [];

    data.forEach((rangePrice) => {
      rangePrice.rows.forEach((price) => {
        if (price.label !== "No. of Products") {
          arrTotal.push(price.total);
          arrDiscount.push(price.discount);
        }
      });
    });

    const maxTotalItem = Math.max(...arrTotal);
    const maxDiscountItem = Math.max(...arrDiscount);

    setsMaxTotalY(maxTotalItem);
    setMaxDiscountY(maxDiscountItem);
  };

  useEffect(() => {
    if (priceRange.length) {
      getMaxVAlues(priceRange);
    }
  }, [priceRange]);

  useEffect(() => {
    if (compareData.length) {
      getMaxVAlues(compareData);
    }
  }, [compareData]);

  useEffect(() => {
    if (Boolean(comparativeValue?.length)) {
      setIsLoader(true);
      setIsResetCompare(false);
      fetchComparePriceRange({
        sourceType: lastFilter.sourceType,
        timePeriod: comparativeValue,
        product: lastFilter.product,
        showDiscount: "true",
        type: typePrice,
      }).then((response) => {
        setCompareData(response.data);
        setIsLoader(false);
      });
    } else {
      setIsLoader(true);
      setIsResetCompare(false);
      setCompareData({});

      setTimeout(function () {
        setIsLoader(false);
      }, 200);
    }
  }, [comparativeValue]);

  const setSelectValue = (values) => {
    setCurrentType(values.price);
    setIsResetCompare(true);
  };

  return (
    <Styles>
      {priceRange.length ? (
        <div className="filters-box">
          <CompareBox
            setComparativeValue={setComparativeValue}
            isResetCompare={isResetCompare}
            disabled={status === STATE_STATUSES.PENDING}
          />
          {/* <div className="wrapper-dscount">
            <Checkbox
              checked={isDiscount}
              disabled={isLoader || typePrice === "promotedPrice"}
              onChange={() => setIsDiscount(!isDiscount)}
            >
              Show Discount
            </Checkbox>
          </div> */}
          {selects.map((item, index) => (
            <SelectBox key={index} data={item} disabled={isLoader} setSelectValue={setSelectValue} />
          ))}
        </div>
      ) : null}
      <div className="wrapper-box-relative">
        {priceRange.length ? (
          <>
            <div className="price-range-wrapper">
              {priceRange.map((item, index) => {
                return (
                  <div className="priceRange-chart" key={index}>
                    <PriceRangeChart
                      data={item.rows}
                      maxTotalY={maxTotalY}
                      maxDiscountY={maxDiscountY}
                      label={index === 0}
                      labelDiscount={priceRange.length - 1 === index}
                      isDiscount={isDiscount}
                      isCompare={Boolean(comparativeValue?.length)}
                      compareData={
                        compareData.length
                          ? compareData[index]
                            ? compareData[index].rows
                            : {
                                range: [],
                                rows: [],
                              }
                          : []
                      }
                      currentDate={lastFilter.date}
                    />
                    <div
                      style={{
                        textAlign: "center",
                        margin: "20px 0",
                        fontFamily: "Gilroy-Medium",
                      }}
                    >
                      <span>
                        {Number(item.range[0]) >= 1 ? `${formatCurrencyNumber(Number(item.range[0]),NUMBER_TYPE.CURRENCY)}` : `${parseInt(Number(item.range[0]) * 100)}p`}
                      </span>
                      <span> - </span>
                      <span>
                        {Number(item.range[1]) >= 1 ? `${formatCurrencyNumber(Number(item.range[1]),NUMBER_TYPE.CURRENCY)}` : `${parseInt(Number(item.range[1]) * 100)}p`}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
            <PriceRangeTable isDiscount={isDiscount} data={priceRange} compareData={compareData} />
          </>
        ) : (
          <>
            {status === STATE_STATUSES.READY ? (
              <div className="chart-no-data static">Looks like we don't have data for this request</div>
            ) : null}
          </>
        )}
        {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
      </div>
    </Styles>
  );
};

export default connect(
  (state) => ({
    status: state.priceRange.status,
    lastFilter: state.authorization.lastFilter,
  }),
  { fetchPriceRange, fetchComparePriceRange }
)(PriceRangeTab);
