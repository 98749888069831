import React from "react";
import StarRatings from "react-star-ratings";

//Components
import { Styles } from "./styles";

//Icons
import sort from "assets/images/sort.svg";
import sortIconDown from "assets/images/sort_down.svg";
import sortIconUp from "assets/images/sort_up.svg";

//Utils
import { specifications } from "utils/productInsightHelper";
import useRetailers from "../../../../../../hooks/useRetailers";
import { formatDate } from "utils/formatDate";

const ProductInsightTable = ({ data, handleSort, sortId, sortDirection }) => {
  const { getRemoteLogo } = useRetailers();
  
  const renderCellItem = (el) => {
    return specifications.map(({ key }, index) => {
      return (
        <div key={index} className="table-item">
          <>
            {key === "retailer" ? (
              <img src={getRemoteLogo(el?.coreRetailer?.retailer?.name)} alt="" />
            ) : key === "rating" ? (
              <StarRatings
                rating={+el[key]}
                starRatedColor="#F9C752"
                numberOfStars={5}
                name="rating"
                isSelectable={false}
                starDimension="18px"
                starSpacing="2px"
                starEmptyColor="#DDE4EC"
              />
            ) : key === "date" ? (
              <span>{formatDate(el[key])}</span>
            ) : (
              <span>{el[key]}</span>
            )}
          </>
        </div>
      );
    });
  };

  return (
    <Styles>
      <div className="current-table-box">
        <div className="wrapper-table-box">
          <div className="wrapper-table">
            <div className="box-wrapper">
              <div className="wrapper">
                {specifications.map((item, index) => {
                  const hideSortBtns = item.key === "title" || item.key === "comment";
                  return (
                    <div
                      className="table-item sort"
                      key={index}
                      onClick={hideSortBtns ? () => {} : () => handleSort(item.key)}
                      style={{ cursor: hideSortBtns ? "default" : "pointer" }}
                    >
                      <span className="">{item.value}</span>
                      {hideSortBtns ? null : (
                        <img src={sortId === item.key ? (sortDirection ? sortIconUp : sortIconDown) : sort} alt="sort" />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="wrapper-table">
            <div className="wrapper-box-relative">
              {data.length ? (
                data.map((el, index) => (
                  <div className="box-wrapper" key={index}>
                    <div className="wrapper">{renderCellItem(el)}</div>
                  </div>
                ))
              ) : (
                <div className="no-reviews">No Reviews</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default ProductInsightTable;
