import React, { useState } from "react";
import { Popover } from "antd";

//Components
import LoaderBox from "components/LoaderBox";
import RenderNoData from "components/RenderNoData";

//Assets
import sort from "assets/images/sort.svg";
import sortIconDown from "assets/images/sort_down.svg";
import sortIconUp from "assets/images/sort_up.svg";
import search from "assets/images/search.svg";
import { COLORS } from "assets/colors/colors";
//Utils
import useRetailers from "../../../../../hooks/useRetailers";
import { firstCharToUpperCase } from "utils/generalUtils";

export const RetailerAvailabilityTable = ({
  inputSearch,
  handleSearch,
  data,
  handleSort,
  sortId,
  sortDirection,
  setShowPopup,
  isEmpty,
  setIdForPopupRequest,
  clickedArray,
  setClickedArray,
  filterBy,
  searchBy,
}) => {
  const [dataForPopup, setDataForPopup] = useState({
    available: 0,
    unavailable: 0,
    id: 1,
    index: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const { getRemoteLogo } = useRetailers();

  const PRODUCT = "product";
  const BRAND = "brand";
  const MANUFACTURE = "manufacture";
  const isProduct = filterBy === "product";
  const isBrand = filterBy === "brand";
  const isManufacture = filterBy === "manufacture";

  const keyValue = {
    0: isProduct ? PRODUCT : isBrand ? BRAND : MANUFACTURE,
    1: isBrand ? PRODUCT : BRAND,
    2: isManufacture ? PRODUCT : MANUFACTURE,
  };

  const isIncluded = (id) => {
    return clickedArray.some((el) => el === id);
  };

  const putToArray = (id, children) => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
    let childrenIds = [];
    if (children.length) {
      childrenIds = children.map(({ id }) => id);
    }

    if (isIncluded(id)) {
      const result = clickedArray.filter((el) => ![...childrenIds, id].includes(el));
      setClickedArray(result);
    } else {
      setClickedArray((prevState) => [...prevState, id]);
    }
  };

  const renderTitleBlock = (el, callTimes) => {
    const type = keyValue[callTimes];

    return (
      <div
        className={`title-${callTimes}`}
        onClick={() => (type !== PRODUCT ? putToArray(el.id, el.children) : () => {})}
        style={{ cursor: type !== PRODUCT ? "pointer" : null }}
      >
        {type !== PRODUCT ? (
          <div className={`plus-btn`} style={{ cursor: !el.children.length ? "not-allowed" : null }}>
            <span>{!isIncluded(el.id) ? "+" : "-"}</span>
          </div>
        ) : null}
        {el.image ? <img src={el.image} alt="" className="image" /> : <div className="title-color" style={{ background: el.color }}></div>}

        <span>{el.title}</span>
      </div>
    );
  };

  const renderProductsBlock = (el, arr, type) => {
    return (
      <div className="wrapper">
        {arr.map(({ available, unavailable, status }, index) => (
          <Popover
            content={popupContent}
            title={el.title}
            trigger="hover"
            align={{ offset: [0, 25] }}
            key={index}
            overlayStyle={{ width: 160 }}
            overlayClassName={type !== PRODUCT || available === 0 || available === -1 ? "hidden" : null}
          >
            <div
              className={`table-item ${status === "highlighted" ? "highlighted" : ""}`}
              key={index}
              style={{ cursor: type === PRODUCT ? "pointer" : "initial" }}
              onMouseOver={() =>
                setDataForPopup({
                  available,
                  unavailable,
                  id: el.id,
                  index: index,
                })
              }
            >
              {available === -1 ? (
                <span className="not-listed">NOT LISTED</span>
              ) : available === 0 ? (
                <span className="unavailable">UNAVAILABLE</span>
              ) : (
                <span>{available}%</span>
              )}
            </div>
          </Popover>
        ))}
      </div>
    );
  };

  const popupContent = () => {
    const { available, unavailable, id, index } = dataForPopup;
    const retailer = data.retailers[index].id;

    return (
      <div className="popup-wrapper">
        <div className="popup-row">
          <div className="flex-container">
            <div className="popup-color" style={{ background: COLORS.available }}></div>
            <span>Available</span>
          </div>
          <span>{available}%</span>
        </div>
        <div className="popup-row">
          <div className="flex-container">
            <div className="popup-color" style={{ background: COLORS.unavailable }}></div>
            <span>Unavailable</span>
          </div>
          <span>{unavailable}%</span>
        </div>

        <span
          className="show-details"
          onClick={() => {
            setShowPopup(true);
            setIdForPopupRequest({ id, retailer });
          }}
        >
          Show Details
        </span>
      </div>
    );
  };

  const renderRowBlock = (key, item, arr, times) => {
    let callTimes = 0 + times;

    return (
      <div key={key}>
        <div className="box-wrapper">
          {renderTitleBlock(item, callTimes)}
          {renderProductsBlock(item, arr, keyValue[callTimes])}
        </div>
        {isIncluded(item.id) && item.children.length
          ? item.children.map((el, i) => {
              return renderRowBlock(el.id, el, el.data, 1 + callTimes);
            })
          : null}
      </div>
    );
  };

  return (
    <div className="chart-price-wrapper" style={{ width: "100%" }}>
      <div className="chart-title-desc">Average availability over selected time.</div>
      <div className="current-table-box">
        <div className="wrapper-table-box">
          <div className="wrapper-table">
            <div className="box-wrapper">
              <div className="title sort">
                <div className="search">
                  <img src={search} alt="search" />
                  <input
                    value={inputSearch}
                    onChange={(e) => handleSearch(e.target.value)}
                    placeholder={`Search for a ${firstCharToUpperCase(searchBy)}`}
                  />
                  <img
                    src={sortId === "0" ? (sortDirection ? sortIconUp : sortIconDown) : sort}
                    alt="sort"
                    className="search-sort-btn"
                    onClick={data.total === 0 ? () => {} : () => handleSort("0")}
                  />
                </div>
              </div>
              {isEmpty ? null : (
                <div className="wrapper">
                  {data.retailers.map((item, index) => (
                    <div className="table-item sort" onClick={() => handleSort(item.id)} key={index}>
                      <img src={getRemoteLogo(item.name)} alt="retailer" />
                      <img src={sortId === item.id ? (sortDirection ? sortIconUp : sortIconDown) : sort} alt="sort" />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          {isEmpty ? (
            <RenderNoData />
          ) : (
            <div className="wrapper-table">
              <div className="box-wrapper">
                <div className="title average">
                  <span className="averagePrice">Average Availability</span>
                </div>
                <div className="wrapper">
                  {data.averageAvailability.map((item, index) => (
                    <div className="table-item average" key={index}>
                      {item.available === -1 ? (
                        <span className="not-listed">NOT LISTED</span>
                      ) : item.available === 0 ? (
                        <span className="unavailable">UNAVAILABLE</span>
                      ) : (
                        <span>{item.available}%</span>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div className="wrapper-box-relative">
                {isLoading ? <LoaderBox /> : null}
                {data.rows.map((el, index) => {
                  return renderRowBlock(index, el, el.data, 0);
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
