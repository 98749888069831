import styled from "styled-components";

export const Styles = styled.div.attrs((props) => props)`
  .modal-window-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    color: #000000;
    flex: 1;
    & > span {
      margin: 2px 0;
    }
  }
  .buttons-wrapper {
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    & button {
      width: 136px;
      height: 44px;
      border-radius: 4px;
      border: none;
      outline: none;
      cursor: pointer;
      font-size: 14px;
    }
    & :first-child {
      background-color: #eef2f7;
      color: ${(props) => props.color};
    }
    & :last-child {
      background-color: #fe6a68;
      color: #fff;
    }
  }

  .modal-window {
    width: 328px;
    height: 186px;
    padding: 20px;
    border-radius: 8px;

    background-color: #ffffff;
    display: flex;
    flex-direction: column;

    font-size: 16px;
    font-family: "Gilroy-ExtraBold";
    text-align: center;
    color: #ffffff;
  }
`;
