import { COLORS } from "assets/colors/colors";
import styled from "styled-components";

export const Styles = styled.div.attrs((props) => props)`
  .row {
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
    margin-top: 12px;
    & > button {
      width: 288px;
      height: 44px;
      cursor: pointer;
      padding: 14px 27px 13px 28px;
      border-radius: 4px;
      background-color: ${(props) => props.color};
      outline: none;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      & > span {
        font-size: 14px;
        color: ${COLORS.white};
        font-family: Gilroy-Medium;
        font-weight: bold;
      }
    }
  }

  .search {
    position: relative;
    font-size: 14px;
    color: #000000;
    margin-right: 16px;
    width: 100%;
    max-width: 288px;
    img {
      position: absolute;
      top: 10px;
      left: 10px;
    }

    input {
      border: none;
      background-color: #eef2f7;
      width: 100%;
      max-width: 288px;
      height: 44px;
      border-radius: 2px;
      outline: none;
      padding-left: 40px;
      padding-right: 12px;
    }
    .error {
      font-size: 12px;
      font-family: "Gilroy-Medium";
      color: #ff5555;
    }
  }
`;
