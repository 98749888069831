import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Popover } from "antd";
import PopupSerchTerm from "./PopupSerchTerm";
import { hexToRgbA } from "utils/hexToRgbA";

const OverviewPieChart = (props) => {
  const { type, name, data, selectedLegend, titlePopup } = props;

  const [pricings, setPricings] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [retailers, setRetailers] = useState([]);
  const [mainData, setMainData] = useState({});

  const handleClick = () => {
    setShowPopup(true);
  };

  useEffect(() => {
    if (data.length) {
      const mainData = data.sort((first, second) => second.percent - first.percent);

      const pricings = [
        {
          innerSize: "75%",
          clickable: false,
          data: mainData.map((item) => ({
            name: item.title,
            y: Number(item.percent),
            color: selectedLegend.length
              ? selectedLegend.includes(item.title)
                ? hexToRgbA(item.color, "1")
                : hexToRgbA(item.color, ".3")
              : hexToRgbA(item.color, "1"),
          })),
        },
      ];

      setMainData(mainData[0]);

      setPricings(pricings);
    }
  }, [data, selectedLegend]);

  useEffect(() => {
    if (data.length) {
      const retailers = data[0].share.map((item) => item.title);
      setRetailers(retailers.slice(1, retailers.length));
    }
  }, [data]);

  const options = {
    chart: {
      type: "pie",
      height: 180,
      width: 180,
      animation: false,
    },
    title: {
      text: "",
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
        },
        borderWidth: 1,
        animation: false,
      },
      pie: {
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            opacity: 1,
          },
        },
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: pricings,
  };

  return (
    <div className="wrapper-pie-chart">
      <Popover
        trigger="hover"
        placement="top"
        content={
          <div className="cutom-tooltip-box">
            <div className="title-name">{name}</div>
            <div className="tooltip-box">
              {data.map((item, index) => (
                <div key={index} className="tooltip-item">
                  <div className="box">
                    <div className="color" style={{ background: item.color }}></div>
                    <div className="name">{item.title}</div>
                  </div>
                  <div className="value">{item.percent}%</div>
                </div>
              ))}
            </div>
          </div>
        }
      >
        <div className="relative-box" onClick={handleClick}>
          <HighchartsReact highcharts={Highcharts} options={options} />
          <div className="description">
            <div>{mainData.title}</div>
            <div>{mainData.percent}%</div>
          </div>
        </div>
      </Popover>
      <PopupSerchTerm
        type={type}
        name={name}
        data={data}
        retailers={retailers}
        titlePopup={titlePopup}
        showPopup={showPopup}
        setShowPopup={setShowPopup}
      />
    </div>
  );
};

export default OverviewPieChart;
