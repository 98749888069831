import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
//Components
import SelectBox from "components/Selects/SelectBox";
import DiscountFilter from "components/Filters/DiscountFilter/DiscountFilter";
import DiscountCutTabChart from "./components/DiscountCutTabChart";
import LoaderBox from "components/LoaderBox";
//Utils
import { STATE_STATUSES } from "utils/statuses";
import { getTimePeriod } from "utils/getTimePeriod";

//Actions
import { fetchDiscountCut, setDiscountFilter } from "store/promotions/promotionsDiscountCut/actions";
//Contants
import { filtersForPromotions } from "constants/filters";
import useCountry from "../../../../hooks/useCountry";
import useCache from "../../../../hooks/useCache";
import useFilters from "../../../../hooks/useFilters";

const radioButtons = (currencySymbol = "£") => [
  {
    value: "percent",
    lable: "% Discount",
  },
  {
    value: "value",
    lable: `${currencySymbol} Discount`,
  },
];

const DiscountCutTab = () => {
  //dispatch
  const dispatch = useDispatch();
  //selectors
  const { status, filter } = useSelector((state) => state.discountCut);
  const { cachedDiscountCut: discountCut } = useCache();
  const { mainLastFilter: lastFilter, statusFilters } = useFilters();
  const { currentCurrency } = useCountry();

  //states
  const [hoverEl, setHoverEl] = useState("");
  const [arrayOfDesiredBrand, setArrayOfDesiredBrand] = useState([]);
  const [queryParams, setQueryParams] = useState({});
  const [minMaxValues, setMinMaxValue] = useState({
    minValue: 0,
    maxValue: 100,
  });
  const [minMaxControlValues, setMinMaxControlValues] = useState({
    minValue: 0,
    maxValue: 0,
  });
  const [typeOfDiscount, setTypeOfDiscount] = useState(filter[1].value);

  useEffect(() => {
    const key = typeOfDiscount === "percent" ? "averageDiscountPercent" : "averageDiscount";
    const promotionsArr = discountCut[filter[2].value].data.map((el) => el[key]);

    const minValue = promotionsArr.length ? Math.min(...promotionsArr) : 0;
    const maxValue = promotionsArr.length ? Math.max(...promotionsArr) : 0;

    setMinMaxValue({ minValue, maxValue });
  }, [discountCut, typeOfDiscount]);

  useEffect(() => {
    const minValue = discountCut.range[typeOfDiscount].min;
    const maxValue = discountCut.range[typeOfDiscount].max;

    setMinMaxControlValues({ minValue, maxValue });
  }, [discountCut, typeOfDiscount]);

  useEffect(() => {
    const discountRange = filter[3].value.length ? `${filter[3].value[0]}-${filter[3].value[1]}` : "All";
    const mechanic =
      filter[0].value === "Price Cut"
        ? "1"
        : filter[0].value === "Multibuy"
        ? "2"
        : filter[0].value === "Other"
        ? "3"
        : "All";

    setQueryParams((prevState) => ({
      ...prevState,
      sourceType: lastFilter.sourceType,
      category: lastFilter.category,
      manufacture: lastFilter.manufacture,
      productBrand: lastFilter.productBrand,
      productGroup: lastFilter.productGroup,
      product: lastFilter.product,
      timePeriod: getTimePeriod(lastFilter.date),
      mechanic,
      discountType: filter[1].value,
      discountRange,
    }));

    setArrayOfDesiredBrand([]);
  }, [lastFilter, filter[0], filter[1], filter[3]]);

  //set values for filters
  const setSelectValue = (values) => {
    const value = Object.keys(values);
    setArrayOfDesiredBrand([]);

    const updateData = filter.map((item) => {
      if (item.name === value[0]) {
        return {
          ...item,
          value: values[value[0]],
        };
      } else if (item.name === value[1]) {
        return {
          ...item,
          value: values[value[1]],
        };
      }

      return item;
    });

    dispatch(setDiscountFilter(updateData));
  };

  useEffect(() => {
    if (Object.keys(queryParams).length && statusFilters === STATE_STATUSES.READY) {
      dispatch(fetchDiscountCut(queryParams));
    }
  }, [queryParams, statusFilters, dispatch]);

  const discountCutTabRenderChart = useMemo(() => {
    return (
      <DiscountCutTabChart
        data={discountCut}
        currentChart={filter}
        setHoverEl={setHoverEl}
        hoverEl={hoverEl}
        arrayOfDesiredBrand={arrayOfDesiredBrand}
        setArrayOfDesiredBrand={setArrayOfDesiredBrand}
        setMinMaxValue={setMinMaxValue}
      />
    );
  }, [discountCut, arrayOfDesiredBrand, filter, hoverEl]);

  return (
    <>
      <div className="filters-box">
        {filtersForPromotions.map((item, index) => {
          if (index === 1) {
            return (
              <DiscountFilter
                key={index}
                data={item}
                setSelectValue={setSelectValue}
                minMaxValues={minMaxValues}
                typeOfDiscount={typeOfDiscount}
                setTypeOfDiscount={setTypeOfDiscount}
                disabled={status !== STATE_STATUSES.READY}
                radioButtons={radioButtons(currentCurrency)}
                decimalStep
                minMaxControlValues={minMaxControlValues}
                resetWithEmptyArr
                isOnPromotions={true}
              />
            );
          } else {
            return (
              <SelectBox
                filter={filter[index].value}
                key={index}
                data={item}
                setSelectValue={setSelectValue}
                disabled={status !== STATE_STATUSES.READY}
              />
            );
          }
        })}
      </div>
      <div className="wrapper-box-relative">
        {discountCut.success ? <div className="chart-wrapper-box">{discountCutTabRenderChart}</div> : null}
        {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
      </div>
    </>
  );
};

export default DiscountCutTab;
