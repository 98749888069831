export const COLORS = {
  primaryBlue: "#3b4799",
  secondaryBlue: "#767eb7",
  primaryLightBlue: "#c1c8f6",
  lightGray: "#e0e0e4",
  darkGray: "#9696a0",
  almostBlack: "#5d6a7d",
  oceanBlue: "#56bfc4",
  opacityOceanBlue: "rgba(86, 191, 196, 0.4)",
  pink: "#ea5f94",
  opacityPink: "rgba(234, 95, 14, 0.4)",
  orange: "#ffb14e",
  opacityOrange: "rgba(255, 177, 78, 0.4)",
  darkGreen: "#0d4f18",
  grassGreen: "#82ba27",
  lightGreen: "#78d988",
  forGraytext: "#737283",
  violet: "#3b4799",
  lightGrayOpacity: "rgba(4, 35, 70, 0.06)",
  yellow: "#F0D343",
  yellowOpacity: "rgba(240, 211, 67, 0.6)",
  dark: "#000000",
  white: "#fff",
  available: "#1ec68d",
  unavailable: "#fe6a68",
};

export const COLOR_SHIFT_FACTOR = [77, 77, 75];

export const colorsPriceCut = {
  priceCut: COLORS.orange,
  multibuy: COLORS.pink,
  other: COLORS.oceanBlue,
};

export const opacityColorsPriceCut = {
  priceCut: COLORS.opacityOrange,
  multibuy: COLORS.opacityPink,
  other: COLORS.opacityOceanBlue,
};

export const colorsArr = [
  "#004995",
  "#82BA27",
  "#FB8717",
  "#FBC010",
  "#31AF5C",
  "#0D4F18",
  "#78D988",
  "#F14758",
  "#78D988",
  "#91e8e1",
];

export const opacityColorsArr = [
  "rgba(0, 73, 149, .5)",
  "rgba(130, 186, 39, .5)",
  "rgba(251, 135, 23, .5)",
  "rgba(251, 192, 16, .5)",
  "rgba(49, 175, 92, .5)",
  "rgba(13, 79, 24, .5)",
  "rgba(120, 217, 136, .5)",
  "rgba(241, 71, 88, .5)",
  "rgba(145, 232, 225, 0.5)",
  "rgba(145, 232, 225, 0.5)",
];
