import React, { useState } from "react";

//Components
import TrendAnalysisBottom from "./TrendAnalysisBottom";
import TrendAnalysisTop from "./TrendAnalysisTop";

const TrendAnalysis = () => {
  const [arrayOfDesiredBrand, setArrayOfDesiredBrand] = useState([]);

  return (
    <div>
      <TrendAnalysisTop setArrayOfDesiredBrand={setArrayOfDesiredBrand} arrayOfDesiredBrand={arrayOfDesiredBrand} />
      <TrendAnalysisBottom arrayOfDesiredBrand={arrayOfDesiredBrand} />
    </div>
  );
};

export default TrendAnalysis;
