const url = process.env.REACT_APP_URL;

export const FETCH_PRODUCT_INSIGHT = "FETCH_PRODUCT_INSIGHT";
export const fetchProductInsight = (params) => ({
  type: FETCH_PRODUCT_INSIGHT,
  request: {
    url: `${url}/v2/rating/insights`,
    method: "POST",
    data: params,
  },
});

export const FETCH_PRODUCT_INSIGHT_ALL = "FETCH_PRODUCT_INSIGHT_ALL";
export const fetchProductInsightAll = (params) => ({
  type: FETCH_PRODUCT_INSIGHT_ALL,
  request: {
    url: `${url}/v2/rating/insights/all`,
    method: "POST",
    data: params,
  },
});

export const FETCH_PRODUCT_INSIGHT_REVIEWS_ALL = "FETCH_PRODUCT_INSIGHT_REVIEWS_ALL";
export const fetchProductInsightReviewsAll = (params) => ({
  type: FETCH_PRODUCT_INSIGHT_REVIEWS_ALL,

  request: {
    url: `${url}/v2/rating/reviews/all`,
    method: "POST",
    data: params,
  },
});

export const FETCH_PRODUCT_INSIGHT_REVIEWS = "FETCH_PRODUCT_INSIGHT_REVIEWS";
export const fetchProductInsightReviews = (params) => ({
  type: FETCH_PRODUCT_INSIGHT_REVIEWS,

  request: {
    url: `${url}/v2/rating/reviews`,
    method: "POST",
    data: params,
  },
});

export const SET_RATING_PRODUCT_INSIGHT_FILTER = "SET_RATING_PRODUCT_INSIGHT_FILTER";
export const setProductInsightFilter = (filter) => {
  localStorage.setItem(SET_RATING_PRODUCT_INSIGHT_FILTER, JSON.stringify(filter));
  return {
    type: SET_RATING_PRODUCT_INSIGHT_FILTER,
    payload: filter,
  };
};

export const SET_PRISTINE_PRODUCT_INSIGHT = "SET_PRISTINE_PRODUCT_INSIGHT";
export const setPristineProductInsight = (pristine) => {
  return {
    type: SET_PRISTINE_PRODUCT_INSIGHT,
    payload: pristine,
  };
};
