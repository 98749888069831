import React from "react";
//Components
import DiscountFilter from "components/Filters/DiscountFilter/DiscountFilter";
import SelectBox from "components/Selects/SelectBox";
import useCountry from "../../../../hooks/useCountry";

//Constants
import { filtersForPromotions } from "constants/filters";

const radioButtons = (currencySymbol='£') => ([
  {
    value: "percent",
    lable: "% Discount",
  },
  {
    value: "value",
    lable: `${currencySymbol} Discount`,
  },
]);

const PromotionsCalendarFilter = ({ setSelectValue, minMaxValues, typeOfDiscount, setTypeOfDiscount, filter, disabled }) => {
  const { currentCurrency } = useCountry();

  return (
    <div className="main-box-filters">
      <div className="filters-box">
        {filtersForPromotions.map((item, index) => {
          if (index === 1) {
            return (
              <DiscountFilter
                key={index}
                data={item}
                setSelectValue={setSelectValue}
                minMaxValues={minMaxValues}
                typeOfDiscount={typeOfDiscount}
                setTypeOfDiscount={setTypeOfDiscount}
                disabled={disabled}
                radioButtons={radioButtons(currentCurrency)}
                decimalStep
                minMaxControlValues={minMaxValues}
                isOnPromotions={true}
              />
            );
          } else {
            return <SelectBox filter={filter[index].value} key={index} data={item} setSelectValue={setSelectValue} disabled={disabled} />;
          }
        })}
      </div>
    </div>
  );
};

export default PromotionsCalendarFilter;
