import React from "react";

import { ProductStyles } from "../Styles";

const AssortmentSummaryProductItem = ({ el, isLastChild }) => {
  const {
    item: { title, image },
  } = el;

  return (
    <ProductStyles style={{ paddingLeft: 7 }}>
      <div className="lines">
        <div></div>
        <div style={{ borderLeft: !isLastChild ? null : 0 }}></div>
      </div>
      <div className="product">
        <img src={image} alt="" />
        <span>{title}</span>
      </div>
    </ProductStyles>
  );
};

export default AssortmentSummaryProductItem;
