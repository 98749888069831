import React, { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_rounded from "highcharts-rounded-corners";
import HC_patternFill from "highcharts-pattern-fill";
import { Popover } from "antd";

//Components
import RenderNoData from "components/RenderNoData";
import RowOfBrands from "components/RowOfBrands";
import RenderTooMoreData from "components/RenderTooMoreData";

import useRetailers from "../../../../../../hooks/useRetailers";
import { changeNameRetailer } from "utils/changeNameRetailer";
import { STATE_STATUSES } from "utils/statuses";

HC_rounded(Highcharts);
HC_patternFill(Highcharts);

const MediaSummaryTopChart = (props) => {
  //Props
  const {
    data,
    currentChart,
    setHoverEl,
    hoverEl,
    arrayOfDesiredBrand,
    setArrayOfDesiredBrand,
    retailer,
    setRetailer,
    bottomFilter,
    status,
  } = props;

  //Refs
  const inputRef = useRef(null);
  const moreBrandsRef = useRef(null);
  const ref = useRef(null);
  const { getRemoteLogo } = useRetailers();

  //Constants
  const isBrand = currentChart[0].value === "byBrand";
  const MORE = "more";
  const SEARCH = "searchTermCount";
  const LOCATION = "locationCount";
  const renderLabelsStep = 16;
  const desiredArr = isBrand ? arrayOfDesiredBrand : retailer;
  const setFunc = isBrand ? setArrayOfDesiredBrand : setRetailer;
  const isShowAll = bottomFilter[0].value;

  //States
  const [isMoreBtnClicked, setMoreBtnClick] = useState(false);
  const [names, setNames] = useState([]);
  const [pricings, setPricings] = useState([]);
  const [isTooMoreData, setIsTooMoreData] = useState(false);
  const [hasClearedAll, setHasClearedAll] = useState(false);
  const [emptyData, setEmptyData] = useState(false);
  const [clickedLabel, setClickedLabel] = useState("");
  const [chartWidth, setChartWidth] = useState(0);

  useEffect(() => {
    pricings[0]?.data.length * 35 > ref?.current?.offsetWidth
      ? setChartWidth(pricings[0]?.data.length * 35)
      : setChartWidth(ref?.current?.offsetWidth);
  }, [ref, pricings]);

  //Converting Data
  useEffect(() => {
    if (data.result.length) {
      setEmptyData(false);

      const filteredData = data.result.filter(({ name }) => desiredArr.includes(name));

      const dataFilteredByChart = isBrand && filteredData.length ? filteredData : data.result;

      const dataForNames = data.result;
      //Find Names
      const namesArr = dataForNames.map(({ name, color }) => ({
        name,
        color,
      }));
      const namesForCheck = dataFilteredByChart.map(({ name, color }) => ({
        name,
        color,
      }));
      setNames(namesArr);

      const getDesiredArray = (type) => {
        //formating this arr to have obj with keys: name, color, y
        const dataWithNameAndColors = dataFilteredByChart.map((el, indx) => {
          const { bannerCount, avgLive } = el;
          const isIncluded = desiredArr.includes(namesForCheck[indx].name);
          const isHovered = namesForCheck[indx].name === hoverEl && !desiredArr.length;

          const getClassName = (type) => {
            const isSearch = type === SEARCH;

            if (isBrand) {
              if (isIncluded && isSearch) {
                return "hightlighted-media";
              } else if (isIncluded && !isSearch) {
                return "hightlighted";
              } else if (!isIncluded && isSearch) {
                return "not-hightlighted-media";
              } else if (!isIncluded && !isSearch) {
                return "not-hightlighted";
              }
            } else {
              if (!hoverEl.length && !isHovered && !desiredArr.length && !isSearch) {
                return "hightlighted";
              } else if (!hoverEl.length && !isHovered && !desiredArr.length && isSearch) {
                return "hightlighted-media";
              } else if (hoverEl.length && !isHovered && !desiredArr.length && isSearch) {
                return "not-hightlighted-media";
              } else if (hoverEl.length && !isHovered && !desiredArr.length && !isSearch) {
                return "not-hightlighted";
              } else if (desiredArr.length && !isIncluded && !isSearch) {
                return "not-hightlighted";
              } else if (desiredArr.length && !isIncluded && isSearch) {
                return "not-hightlighted-media";
              } else if (isIncluded && !isSearch) {
                return "hightlighted";
              } else if (isIncluded && isSearch) {
                return "hightlighted-media";
              } else if (isHovered && hoverEl.length && !isSearch) {
                return "hightlighted";
              } else if (isHovered && hoverEl.length && isSearch) {
                return "hightlighted-media";
              } else {
                return "not-hightlighted";
              }
            }
          };

          return {
            name: namesForCheck[indx].name,
            y: el[type],
            color: el.color,
            bannerCount,
            avgLive,
            [SEARCH]: el[SEARCH],
            [LOCATION]: el[LOCATION],
            className: getClassName(type),
          };
        });

        return dataWithNameAndColors;
      };

      const searchCount = getDesiredArray(SEARCH);

      const locationCount = getDesiredArray(LOCATION);

      //get pricing arr with desired data
      const pricing = [
        {
          name: SEARCH,
          data: searchCount,
          borderRadiusTopLeft: "10%",
          borderRadiusTopRight: "10%",
        },
        {
          name: LOCATION,
          data: locationCount,
        },
      ];

      setPricings(pricing);
    } else {
      setEmptyData(true);
    }
  }, [data, desiredArr, hoverEl, isShowAll]);

  useEffect(() => {
    if (names.length > renderLabelsStep && isBrand) {
      if (!arrayOfDesiredBrand.length) {
        setIsTooMoreData(true);
      } else {
        setIsTooMoreData(false);
      }
    } else {
      setIsTooMoreData(false);
    }
  }, [names, isBrand, arrayOfDesiredBrand]);

  //Settings for chart
  const options = {
    title: {
      text: "",
    },

    chart: {
      animation: false,
      width: chartWidth,
      type: "column",
      style: {
        fontSize: "10px",
        fontFamily: "Gilroy-Medium",
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: null,
      borderWidth: 0,
      formatter: function () {
        let key;
        if (this.points) {
          key = this.points[0].point;
        }
        if (this.point) {
          key = this.point;
        }
        let initialData = this.points.map((item) => {
          return `<div class='wrapper'>
                                        <div class='box'>
                                            <div class='color' style='background: ${item.point.color}; opacity: ${
            item.series.options.name === SEARCH ? 0.5 : 1
          }' ></div>
                                            <div class='name'>${
                                              item.series.options.name === SEARCH
                                                ? "Search Term Banners"
                                                : "Location Banners"
                                            }</div>
                                        </div>
                                   
                                <div class='price'>${item.y}</div>
                            </div>`;
        });
        initialData = initialData.join("");

        return `<div class='wrapper-category'>
                        <div class='title'>${changeNameRetailer(key.name)}</div>
                        <div class='wrapper-box'>
                            <div>
                                <div style="margin-top: 10px">
                                  <div class='wrapper'>
                                    <div class='box'>
                                      <div class='name' style="margin: 0">
                                      No. of Banners
                                      </div>
                                    </div>
                                    <div class='price'>${key.bannerCount}</div>
                                  </div>
                                </div>
                                <div style="margin-bottom: 10px">
                                  <div class='wrapper'>
                                    <div class='box'>
                                      <div class='name' style="margin: 0">
                                      Average Time
                                      </div>
                                    </div>
                                    <div class='price'>${key.avgLive} day${+key.avgLive > 0 ? "s" : ""}</div>
                                  </div>
                                </div>
                                <div>
                                    ${initialData}
                                </div>
                        </div>
                    </div>`;
      },
    },
    xAxis: {
      categories: names.map(({ name }) => name),
      labels: {
        useHTML: !isBrand && status === STATE_STATUSES.READY,
        formatter: function () {
          return `<img style="width: 25px;" src=${getRemoteLogo(this.value)}></img>`;
        },
      },
    },
    yAxis: {
      title: {
        offset: 15,
        x: 25,
        text: "No. of Banners",
      },
      offset: 30,
      labels: {
        style: {
          fontSize: "10px",
        },
      },
      style: {
        fontFamily: "Gilroy-Medium",
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        animation: false,
        stacking: "normal",
        borderWidth: 0,
        pointWidth: 24,
        states: {},
        point: {
          events: {
            click: function () {
              setClickedLabel(this.category);
            },
            mouseOut: function () {
              setElement("");
            },
            mouseOver: function () {
              setElement(this.category);
            },
          },
        },
        events: {},
      },
    },
    series: pricings,
  };

  useEffect(() => {
    if (clickedLabel) {
      onLabelClick(clickedLabel);
    }
  }, [clickedLabel]);

  //set hover element
  const setElement = (el) => {
    setHoverEl(el);
  };
  //if array of brands has at least 1 item remove div with opacity
  useEffect(() => {
    if (arrayOfDesiredBrand.length) {
      setIsTooMoreData(false);
    }
  }, [arrayOfDesiredBrand]);
  //put name of brand to array

  const onLabelClick = (key) => {
    let array;

    if (desiredArr.includes(key)) {
      array = !isBrand ? [] : desiredArr.filter((brand) => brand !== key);
      setFunc(array);
    } else {
      array = !isBrand ? [key] : [...desiredArr, key];
      setFunc(array);
    }
  };

  return (
    <div className="chart-price-wrapper" style={{ width: `100%` }} ref={ref}>
      {isTooMoreData ? <RenderTooMoreData style={{ top: 38 }} /> : null}
      <div className="chart-title-desc">
        A total of {data.total.banners} banners have been detected. Average time banners live
        <span style={{ fontWeight: 800, marginLeft: 4 }}>{data.total.live} days</span>.
      </div>

      {emptyData ? (
        <RenderNoData />
      ) : (
        <HighchartsReact
          ref={inputRef}
          highcharts={Highcharts}
          options={options}
          containerProps={{ className: "enableOverflow" }}
        />
      )}

      <div className="brand-labels-row">
        {isBrand ? (
          <RowOfBrands
            names={names}
            arrayOfDesiredBrand={desiredArr}
            setArrayOfDesiredBrand={setFunc}
            selectSingle={!isBrand}
            setHasClearedAll={setHasClearedAll}
          />
        ) : null}
      </div>
    </div>
  );
};

export default MediaSummaryTopChart;
