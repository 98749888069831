import React, { useState, useEffect } from "react";
import useRetailers from "../../../../hooks/useRetailers";
import { thousSeparator } from "utils/thousSeparator";

import product from "assets/images/con-product.svg";
import star from "assets/images/star.svg";
import discount from "assets/images/discount.svg";
import green from "assets/images/green.svg";
import red from "assets/images/red.svg";
import sort from "assets/images/sort.svg";
import sortIconDown from "assets/images/sort_down.svg";
import sortIconUp from "assets/images/sort_up.svg";
import useCountry, { NUMBER_TYPE } from "../../../../hooks/useCountry";

const PriceRangeTable = (props) => {
  const { isDiscount, data, compareData } = props;

  const { formatCurrencyNumber } = useCountry();
  const [retailer, setRetailers] = useState([]);
  const [sortParam, setSortParam] = useState("price");
  const [retailerIndex, setRetailerIndex] = useState(0);
  const [directionPrice, setDirectionPrice] = useState(true);
  const [directionProduct, setDirectionProduct] = useState(true);
  const { getRemoteLogo } = useRetailers();

  useEffect(() => {
    if (data.length) {
      const retailersSlice = data[0].rows.filter((item) => item.label !== "No. of Products");

      const retailers = retailersSlice.map((item) => item.label);

      setRetailers(retailers);
    }
  }, [data]);

  const sortByPrice = () => {
    if (sortParam === "price") {
      setDirectionPrice(!directionPrice);
      return;
    }
    setSortParam("price");
  };

  const sortByProduct = (index) => {
    setRetailerIndex(index);
    setSortParam("product");

    if (sortParam === "product" && index === retailerIndex) {
      setDirectionProduct(!directionProduct);
      return;
    }
  };

  const sortData = (data) => {
    switch (sortParam) {
      case "price": {
        return data.sort((a, b) => {
          const first = Number(a.range[0]) + Number(a.range[1]);
          const second = Number(b.range[0]) + Number(b.range[1]);

          if (directionPrice) {
            return first - second;
          } else {
            return second - first;
          }
        });
      }
      case "product": {
        return data.sort((a, b) => {
          const first = a.rows[retailerIndex].total;
          const second = b.rows[retailerIndex].total;

          if (directionProduct) {
            return first - second;
          } else {
            return second - first;
          }
        });
      }
      default: {
        return data;
      }
    }
  };

  return (
    <div className="range-price-table">
      <div className="price-range-header">
        <div className="header-item price" onClick={sortByPrice}>
          <div className="title-range-box">
            <span>Price</span>
            <img src={sortParam === "price" ? (directionPrice ? sortIconUp : sortIconDown) : sort} alt="sort" />
          </div>
        </div>
        <div className="header-item no-prod" onClick={() => sortByProduct(0)}>
          <div className="title-range-box">
            <span>No. of Products</span>
            <img
              src={
                sortParam === "product" && retailerIndex === 0 ? (directionProduct ? sortIconUp : sortIconDown) : sort
              }
              alt="sort"
            />
          </div>
        </div>
        <div className="header-item">
          {retailer.length &&
            retailer.map((item, index) => (
              <div className="retailer-img-rn" key={index} onClick={() => sortByProduct(index + 1)}>
                <img src={getRemoteLogo(item)} alt="retailer" />
                <img
                  src={
                    sortParam === "product" && retailerIndex === index + 1
                      ? directionProduct
                        ? sortIconUp
                        : sortIconDown
                      : sort
                  }
                  alt="sort"
                />
              </div>
            ))}
        </div>
      </div>
      {data.length &&
        sortData(data).map((dataItem, dataIndex) => (
          <div className="main-rows-box" key={dataIndex}>
            <div className="range-box">
              <div style={{ width: "170px" }}>
                <span>
                  {Number(dataItem.range[0]) >= 1
                    ? `${formatCurrencyNumber(Number(dataItem.range[0]),NUMBER_TYPE.CURRENCY)}`
                    : `${parseInt(Number(dataItem.range[0]) * 100)}p`}
                </span>
                <span> - </span>
                <span>
                  {Number(dataItem.range[1]) >= 1
                    ? `${formatCurrencyNumber(Number(dataItem.range[1]),NUMBER_TYPE.CURRENCY)}`
                    : `${parseInt(Number(dataItem.range[1]) * 100)}p`}
                </span>
              </div>
            </div>
            <div className="rows-box">
              {dataItem.rows.map((item, index) => (
                <div className="item-box" key={index}>
                  <div>
                    <div className="item">
                      <img src={product} alt="icon" />
                      <span>{thousSeparator(item.total)}</span>
                    </div>
                    <div className="item">
                      <img src={star} alt="icon" />
                      <span>{item.star}</span>
                    </div>
                    {isDiscount ? (
                      <div className="item">
                        <img src={discount} alt="icon" />
                        <span>{item.discount}%</span>
                      </div>
                    ) : null}
                  </div>

                  {compareData.length ? (
                    <div className="compare-direction">
                      <div>
                        {compareData[dataIndex] && item?.total && compareData[dataIndex].rows[index]?.total ? (
                          <img
                            className="direction"
                            src={item.total - compareData[dataIndex].rows[index].total ? green : red}
                            alt="direction"
                          />
                        ) : null}
                      </div>
                      <div>
                        {compareData[dataIndex] && item?.star && compareData[dataIndex].rows[index]?.star ? (
                          <img
                            className="direction"
                            src={item.star - compareData[dataIndex].rows[index].star ? green : red}
                            alt="direction"
                          />
                        ) : null}
                      </div>
                      {isDiscount ? (
                        <div>
                          {compareData[dataIndex] && item?.discount &&  compareData[dataIndex].rows[index]?.discount ? (
                            <img
                              className="direction"
                              src={item.discount - compareData[dataIndex].rows[index].discount ? green : red}
                              alt="direction"
                            />
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        ))}
    </div>
  );
};

export default PriceRangeTable;
