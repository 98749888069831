import styled from "styled-components";

export const Styles = styled.div.attrs((props) => props)`
  display: flex;
  flex-direction: column;
  min-height: 778px;

  .ant-tabs-nav {
    margin-bottom: 0;
    width: 277px;
  }
  .ant-tabs-nav .ant-tabs-ink-bar {
    left: 0;
  }
  .ant-tabs-nav-wrap {
    margin-top: 20px;
  }
  .ant-tabs-content-holder {
    padding: 40px;
  }
  .ant-tabs-tabpane-active {
    padding: 0 !important;
  }

  .image-container label {
    margin: 0;
  }

  .ant-form-item-control-input-content input {
    width: 288px;
    height: 44px;
    border-radius: 2px;
    background-color: #eef2f7;
    font-size: 14px;
    padding: 0 20px;
  }

  .ant-input,
  .ant-input-number {
    border-radius: 2px;
    border: none;
    outline: none;
    box-shadow: none;
    font-family: "Gilroy-Regular";
  }

  .ant-input-password input {
    width: 100%;
    height: auto;
  }

  .ant-input-number-input-wrap {
    width: 288px;
    height: 44px;
  }

  .ant-input-number {
    width: 288px;
    height: 44px;
    overflow: hidden;
    background-color: #eef2f7;
  }

  .ant-form-vertical {
    width: 432px;
  }

  .ant-btn.ant-btn-primary {
    width: 136px;
    height: 44px;
    font-size: 14px;
    font-weight: 800;
    border-radius: 4px;
    border: none;
  }
  .ant-btn.ant-btn-primary:hover,
  .ant-btn.ant-btn-primary:focus {
    border: none;
    background: ${(props) => props.color} !important;
  }
  .ant-btn.ant-btn-primary:disabled {
    opacity: 0.5;
  }

  .ant-btn-default {
    width: 136px;
    height: 44px;
    font-size: 14px;
    font-family: "Gilroy-ExtraBold";
    border: none;
    background: #eef2f7;
    color: ${(props) => props.color};
  }

  [ant-click-animating-without-extra-node]:after {
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before,
  .ant-input-number-handler-wrap {
    display: none;
  }
  .ant-form-item-label > label {
    font-size: 12px;
    font-family: "Gilroy-ExtraBold";
  }
  .ant-form-item-has-error .ant-form-item-explain {
    color: #fe6a68;
    font-size: 12px;
  }
  .ant-form-item-has-error > div > label {
    color: #fe6a68 !important;
  }
  .ant-form-item-has-error .ant-input:focus {
    box-shadow: none;
  }
  .ant-form-item-has-error .ant-input::placeholder {
    color: #fe6a68;
  }
  .ant-form-item-has-error .ant-input:not(.ant-form-item-has-error .ant-input-disabled),
  .ant-input-affix-wrapper:not(.ant-form-item-has-error .ant-input-disabled) {
    background-color: #eef2f7;
  }
  .ant-form-item-has-error {
    .ant-input-password {
      border: 1px solid #fe6a68;
    }
  }

  .ant-input-affix-wrapper {
    font-weight: 800;
    width: 288px;
    height: 44px;
    margin: 0;
    padding: 0;
    border-radius: 2px;
    background-color: #eef2f7;
    border: none;
  }
  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    border: none;
    outline: none;
    box-shadow: none;
  }

  .ant-input-suffix {
    display: none;
  }

  .ant-upload.ant-upload-select-picture-card {
    border: none;
    background-color: white;
  }

  .ant-upload-picture-card-wrapper {
    width: auto;
  }

  .ant-upload {
    width: 100%;
    display: flex;
    justify-align: center;
    align-conternt: center;
  }

  .ant-upload img {
    width: 86px !important;
    height: 86px !important;
    border-radius: 50%;
  }

  .ant-upload div {
    width: 240px;
    hegiht: 90px;
    display: flex;
    justify-content: center;
    align-content: center;
  }

  .anticon-spin {
    margin-top: 34.5px;
  }

  .filter-wrapper {
    padding: 0;
    margin: 0;
  }

  .active-filter,
  .colour-picker-wrapper {
    border: 1px solid #d9d9d9 !important;
    color: black !important;
    padding: 0 !important;
    line-height: 38px;
  }

  .active-filter:hover {
    border-color: #40a9ff !important;
  }

  .selected-filter {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  .filter-btn .select-count,
  .filter-btn .select-placeholder {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    margin-left: 11px;
  }

  .select-placeholder {
    color: rgb(191, 191, 191) !important;
  }

  .colour {
    width: 16px !important;
    height: 16px !important;
    margin: 4px 14px 4px 0;
    border: 1px solid #e8e8e8;
    border-radius: 2.8px;
  }

  .table-container {
    margin-top: 40px;
  }

  .table {
    font-family: "Gilroy-Medium";
    font-size: 14px;
  }

  .item-box {
    min-height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    font-size: 14px;
    border-bottom: 1px solid #e0e0e4;
    .item-text {
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      overflow-x: auto;
      word-wrap: break-word;
      font-size: 14px;
    }
    .item-color {
      width: 16px;
      height: 16px;
      margin: 0 16px 0 20px;
      border-radius: 2px;
      border: 1px solid #e8e8e8;
    }
    .item-group-name {
      width: 30%;
      padding: 0 6px;
      font-family: "Gilroy-ExtraBold";
    }
    .products-selected {
      width: 30%;
      padding: 0 6px;
      font-family: "Gilroy-ExtraBold";
    }
    .brands-selected {
      width: 40%;
      padding: 0 6px;
      font-family: "Gilroy-ExtraBold";
    }
    .item-tools {
      width: 44px;
      height: 44px;
      margin: 10px 10px 8px 0;
      padding: 10px;
      border-radius: 4px;
      background-color: #eef2f7;
      cursor: pointer;
    }
    .item-brand {
      font-size: 12px;
      color: #9696a0;
    }
    .item-product {
      display: flex;
      align-items: center;
      justify-content: flex-start !important;
      // height: 64px;
    }
    .item-product img {
      width: 40px;
      height: 40px;
      margin: 0 20px 0 0;
      padding: 3px;
    }
  }

  .item-box-retailers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .item-box-retailers img {
    width: 40px;
    height: 40px;
    margin: 0 20px 0 0;
    padding: 3px;
  }

  .item-retailer {
    height: 64px;
    width: 100%;
    border-bottom: 1px solid #e0e0e4;
    display: flex;
    align-items: center;
  }

  .item-box.header {
    border-bottom: 2px solid #e0e0e4;
  }

  .item-box .col-1 {
    width: 70%;
    display: flex;
    justify-content: flex-start;
  }
  .col-1.item-product {
    border-right: 1px solid #e0e0e4;
  }

  .item-box .col-2 {
    width: 30%;
    display: flex;
    justify-content: space-between;
    height: 64px;
    padding-left: 20px;
  }

  .item-expand-button {
    width: 24px;
    height: 24px;
    margin: 20px 20px 18px 10px;
    cursor: pointer;
  }

  .search {
    width: 360px;
    position: relative;
    font-size: 14px;
    color: #000000;
    @media (max-width: 1440px) {
      width: 250px;
    }
    img.search-icon {
      position: absolute;
      top: 19px;
      left: 10px;
    }
    input {
      width: 316px;
      height: 44px;
      border: none;
      border-radius: 2px;
      outline: none;
      margin: 10px 4px 8px 0;
      padding: 10px 130px 10px 40px;
      border-radius: 2px;
      background-color: #eef2f7;
      @media (max-width: 1440px) {
        width: 220px;
      }
    }
    img.sort-icon {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 19px;
      cursor: pointer;
    }
  }

  .icon-for-sort {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .pagination-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    font-size: 14px;
  }

  .pagination-container .pagination-controls {
    margin: 0 !important;
  }

  .pagination-container .ant-select {
    margin: 0 15px;
  }

  .pagination-container .ant-select,
  .pagination-container .ant-select-selector {
    width: 72px !important;
    height: 44px;
  }

  .plus-btn-expand {
    cursor: pointer;
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    margin-right: 12px;
    border: 1px solid #e0e0e4;
    & > span {
      color: #e0e0e4;
    }
  }

  .item-locations {
    justify-content: center !important;
  }

  .item-locations img {
    width: 24px;
    height: 24px;
  }

  .text-margin {
    margin-bottom: 40px;
  }

  .your-products {
    font-weight: 800;
  }

  .product-text-box-title {
    font-size: 14px;
    font-weight: 800;
    color: ${(props) => props.color};
  }

  .product-text-box-desc {
    font-size: 12px;
    font-weight: 500;
    color: #000000;
    overflow: hidden;
  }

  .collapseButton {
    cursor: pointer;
  }

  .file-trigger {
    /* your style */
  }

  .subscriptions-locatins-temp {
    width: 100% !important;
    border-right: none !important;
  }

  .edit-btn-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
    font-family: "Gilroy-ExtraBold";
  }

  .edit-btn-wrapper img {
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-right: 10px;
  }
  .ant-input::placeholder {
    color: #333333;
    opacity: 0.65;
  }
  /* Chrome, Safari, Edge, Opera */
  .ant-input::-webkit-outer-spin-button,
  .ant-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  .ant-input[type="number"] {
    -moz-appearance: textfield;
  }
`;
