import React from "react";

const RenderDropdownWrapper = (props) => {
  const {
    nameForClass,
    keysForRender,
    renderComponent,
    resetFunc,
    removeAll,
    titleBox,
  } = props;
  const Component = renderComponent;
  return (
    <div className={`dropdown ${nameForClass}`}>
      {titleBox ? (
        <div className="title-box">
          <span>{titleBox}</span>
        </div>
      ) : null}
      {keysForRender.map(({ title }) => {
        return <Component title={title} key={title} {...props} />;
      })}
      {removeAll ? (
        <div className="remove-wrapper">
          <div className="reset-btn" onClick={() => resetFunc()}>
            Remove All
          </div>
          <div className="apply-btn">Apply</div>
        </div>
      ) : (
        <div className="reset-wrapper">
          <div className="reset-btn" onClick={() => resetFunc()}>
            Reset
          </div>
        </div>
      )}
    </div>
  );
};

export default RenderDropdownWrapper;
