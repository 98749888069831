import styled from "styled-components";

export const Styles = styled.div`
  min-height: 100px;
  .main-box-header {
    display: flex;
    margin-bottom: 30px;
  }
  .main-box-title,
  .main-box-filters {
    width: 50%;
  }
  .calendar-title {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    & > span {
      position: relative;
      font-size: 18px;
      font-weight: 800;
      font-family: Gilroy-ExtraBold;
    }
  }
  .calendar-title-desc {
    display: flex;
    flex-direction: column;
    line-height: 1.29;
    font-size: 14px;
    max-width: 522px;
    font-family: Gilroy-Medium;
  }
  .calendar {
    margin-left: 12px;
    width: calc(100% - 390px - 12px);
  }
  .calendar-wrapper {
    display: flex;
  }
  .top-retailers {
    width: 390px;
    display: flex;
    flex-direction: column;
  }
  .collapse-all {
    padding: 20px 0;
    margin-bottom: 11px;
    & > span {
      font-size: 16px;
      color: #4469c4;
      font-family: Gilroy-ExtraBold;
      font-weight: bold;
    }
  }
  .slider-wrapper {
    margin-left: 14px;
    margin-right: 6px;
  }
`;
