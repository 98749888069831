import React from "react";
import useColorTheme from "../../../../hooks/useColorTheme";
import { Tooltip } from "antd";

//Components
import {
  Assortment,
  Availability,
  Media,
  Pricing,
  Promotions,
  Rating,
  Content,
  Navigation,
  Ranking,
} from "assets/icons/icons";
// import { COLORS } from "assets/colors/colors";
import SelectBox from "components/Selects/SelectBox";

import about from "assets/images/about.svg";

const measureDropdown = {
  name: "measure",
  default: "5",
  options: [
    { lable: "Top 5", value: 5 },
    { lable: "Top 10", value: 10 },
    { lable: "Top 20", value: 20 },
  ],
};

const DropdownGoalsValue = ({ title }) => {
  const { primaryColor } = useColorTheme();
  const titleToImage = {
    Pricing: Pricing,
    "Promotion Discount": Promotions,
    "Search Terms": Ranking,
    Location: Ranking,
    Content: Content,
    "Ratings & Reviews": Rating,
    Media: Media,
    Navigation: Navigation,
    Availability: Availability,
    Assortment: Assortment,
  };

  const titleToInputValue = {
    Pricing: "120%",
    "Promotion Discount": "33%",
    "Search Terms": "25%",
    Location: "25",
    Content: "93%",
    "Ratings & Reviews": "4.5",
    Media: "15",
    Navigation: "3",
    Availability: "99.5",
    Assortment: "35%",
  };

  const measureStyle = {
    color: "#9696a0",
    marginLeft: 40,
  };

  const Icon =
    titleToImage[title] ||
    (() => {
      return null;
    });

  const isContainMeasure = title.includes("Measure");
  const titleVal = isContainMeasure ? "Measure" : title;

  const renderInput = () => {
    if (isContainMeasure) {
      return (
        <SelectBox
          filter={10}
          data={measureDropdown}
          setSelectValue={() => {}}
        />
      );
    } else {
      return (
        <div className="input">
          <input value={titleToInputValue[title]} onChange={() => {}} />
          <Tooltip
            className="about-icon-tooltip"
            placement="top"
            title={"Pick a range between 40% and 75%."}
          >
            <img src={about} className="about-icon" />
          </Tooltip>
        </div>
      );
    }
  };

  return (
    <div className="dropdown-value order-value">
      <div className="title-wrapper">
        <Icon fill={primaryColor} />
        <span style={isContainMeasure ? measureStyle : null}>{titleVal}</span>
      </div>
      <div className="input-wrapper">{renderInput()}</div>
    </div>
  );
};

export default DropdownGoalsValue;
