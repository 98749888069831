import React, { useRef, useState, useEffect, useMemo } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_rounded from "highcharts-rounded-corners";
import { Slider } from "antd";
import { useDispatch } from "react-redux";
//Components
import RenderNoData from "components/RenderNoData";
//Actions
import { addToDataAssortmentSummaryCalendar } from "store/assortment/assortmentSummaryCalendar/actions";
//Utils
import { formatDateToTimeStamp, formatToDate, formatToPeriodObj } from "utils/calendarPromotionsHelper";

//Assets
import { COLORS } from "assets/colors/colors";

HC_rounded(Highcharts);

const AssortmentCalendar = ({
  data,
  controlData,
  clickedArray,
  setClickedArray,
  maxMinYValue,
  maxMinSliderValue,
  sliderValue,
  onSliderChange,
  setIsRedraw,
  desiredArr,
  filter,
  isEmpty,
  sliderValueHandler,
}) => {
  //Refs
  const inputRef = useRef(null);
  //Const
  const dispatch = useDispatch();
  const sevenDaysInterval = 24 * 3600 * 1000 * 7;
  const oneDayInterval = 24 * 3600 * 1000;
  const interval = maxMinYValue.max - maxMinYValue.min >= sevenDaysInterval ? sevenDaysInterval : oneDayInterval;
  //States
  const [pricings, setPricings] = useState([]);
  const [retailersLabel, setRetailersLabel] = useState([]);
  const [options, setOptions] = useState({});

  //Converting data
  useEffect(() => {
    let filteredData = [];
    if (desiredArr.length) {
      filteredData = data.result.filter(({ item: { name } }) => desiredArr.some((el) => el === name));
    } else {
      filteredData = data.result;
    }

    if (filteredData.length) {
      let retailers = filteredData;

      //Set count of  opened labels to calculate charts height
      const retailersLabel = retailers.map(({ item: { name, id, color }, children }) => {
        if (children.length) {
          return { name, id, color };
        }
      });

      setRetailersLabel(retailersLabel);

      if (clickedArray.length) {
        //If clicked on plus btn put new item in order
        for (let index = 0; index < clickedArray.length; index++) {
          const indexOfMatchedEl = retailersLabel.findIndex(({ id }) => id === clickedArray[index].id);
          const labels = clickedArray[index].children.map(({ item: { name, id } }) => {
            return {
              name,
              id,
            };
          });

          for (let i = 0; i < labels?.length; i++) {
            retailersLabel.splice(indexOfMatchedEl + i + 1, 0, labels[i]);
          }
        }
      }
      //Function to create data for chart

      const convertToDays = (data, retailer) => {
        let resultArr = [];
        data.forEach((el, i) => {
          const opacity = !el?.children ? 0.6 : 1;

          const days = Object.entries(el.days);

          getArrOfDaysValues(days, [], el.item.id, resultArr, el, opacity, retailer);
        });

        return resultArr.flat();
      };

      const convertToPricing = (arr) => {
        let result = [];
        arr.forEach((el) => {
          for (let index = 0; index < el.days.length; index++) {
            const day = el.days[index];

            const x = retailersLabel.findIndex((item) => item.id === el.id);

            const { name, color, id, opacity, retailer } = el;

            if (x > -1) {
              result = [
                ...result,
                {
                  low: formatDateToTimeStamp(day[0][0][0]),
                  high: formatDateToTimeStamp(day[0][day[0].length - 1][0]),
                  x,
                  name,
                  color,
                  id,
                  opacity,
                  retailer,
                },
              ];
            }
          }
        });
        return result;
      };

      const res = convertToDays(retailers);

      const pr = convertToPricing(res);

      setPricings([
        {
          data: pr,
          borderRadius: "4px",
          name: "AllRetailers",
        },
      ]);

      //Set data to chart in desired order
      if (clickedArray.length) {
        for (let index = 0; index < clickedArray.length; index++) {
          const res = convertToDays(clickedArray[index].children, clickedArray[index].retailer);

          const pr = convertToPricing(res);

          setPricings((prevState) => [...prevState, { data: pr, borderRadius: "4px" }]);
        }
      }
    } else {
      setPricings([]);
    }
  }, [data, clickedArray, desiredArr, filter]);
  //Get new arr of true dates
  const getArrOfDaysValues = (arr, desiredArr, id, resultArr, element, opacity, retailer) => {
    let arrNew = [];
    const firstTrueEl = arr.find((el) => el[1] === true);

    if (firstTrueEl?.length) {
      const indexOfFirstTrueEl = arr.indexOf(firstTrueEl);

      const firstFalseEl = arr.find((el, i) => el[1] === false && i > indexOfFirstTrueEl);

      const indexOfFirstFalseEl = arr.indexOf(firstFalseEl);

      const newArr = arr.slice(indexOfFirstTrueEl, indexOfFirstFalseEl > -1 ? indexOfFirstFalseEl : arr.length);

      const matchedElIndex = desiredArr.findIndex((el) => el.id === id);

      if (matchedElIndex > -1) {
        arrNew = [
          ...desiredArr.slice(0, matchedElIndex),
          {
            id,
            name: element.item.name,
            color: element.item.color,
            opacity,
            days: [...desiredArr[matchedElIndex].days, [newArr]],
            retailer,
          },
          ...desiredArr.slice(matchedElIndex + 1),
        ];
      } else {
        arrNew = [
          ...desiredArr,
          {
            id,
            name: element.item.name,
            color: element.item.color,
            opacity,
            days: [[newArr]],
            retailer,
          },
        ];
      }

      const lastOfArr = arr.slice(indexOfFirstFalseEl > -1 ? indexOfFirstFalseEl : arr.length);
      getArrOfDaysValues(lastOfArr, arrNew, id, resultArr, element, opacity, retailer);
    } else {
      resultArr.push(desiredArr);
    }
  };

  //Chart options
  useEffect(() => {
    const additionalHeight = retailersLabel.length === 1 && !clickedArray.length ? 60 : 49;

    setOptions({
      chart: {
        style: {
          fontFamily: "Gilroy-Medium",
          fontSize: 10,
        },
        animation: false,
        type: "columnrange",
        inverted: true,
        height: retailersLabel.length * 48 + additionalHeight,

        marginLeft: 0,

        events: {
          load: function () {
            setIsRedraw(true);
          },
        },
      },

      title: {
        text: "",
      },

      subtitle: {
        text: "",
      },

      xAxis: {
        tickColor: "transparent",
        labels: {
          enabled: false,
        },
        lineColor: "#e9e9e9",
        startOnTick: true,
        endOnTick: true,
        tickPixelInterval: 48,
        min: 0,
        max: retailersLabel.length,
        zoomEnabled: false,
        top: retailersLabel.length === 1 && !clickedArray.length ? 28 : 34,
        tickPositions: retailersLabel.map((el, i) => i),
        lineColor: "transparent",
      },

      yAxis: {
        type: "datetime",
        opposite: true,
        lineColor: COLORS.lightGray,
        showLastLabel: false,
        lineWidth: 2,
        gridLineColor: COLORS.lightGray,
        tickInterval: interval,
        tickPositioner: function (min, max) {
          let arr = [];
          let minVal = min;

          while (minVal <= max) {
            arr.push(minVal);
            minVal += interval;
          }
          arr.push(max);

          return arr;
        },
        max: maxMinYValue.max,
        min: maxMinYValue.min,
        title: { text: "" },

        labels: {
          formatter: function () {
            return formatToDate(this.value);
          },
        },
      },

      tooltip: {
        enabled: false,
      },

      plotOptions: {
        series: {
          clip: false,
          pointRange: 1,
          groupPadding: 0.47,
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
        columnrange: {
          groupping: false,
          animation: false,
          pointPadding: 0,
          pointWidth: 16,
          borderWidth: 0,

          dataLabels: {
            enabled: false,
          },
          states: {
            hover: {
              halo: null,
            },
          },
        },
      },

      legend: {
        enabled: false,
      },

      series: pricings,
    });
  }, [retailersLabel, maxMinYValue, pricings, clickedArray]);
  //filter data by clicked names or bottom filter
  useEffect(() => {
    const { min, max } = maxMinYValue;
    const cntrlData = controlData.result;

    if (cntrlData.length) {
      let retailers = [];
      for (let index = 0; index < cntrlData.length; index++) {
        const element = cntrlData[index];

        let brands = [];
        for (let index = 0; index < element.children.length; index++) {
          const el = element.children[index];

          const newDaysObj = formatToPeriodObj(el.period, min, max);

          if (Object.entries(newDaysObj).length) {
            brands.push({ ...el, days: newDaysObj });
          }
        }
        if (brands.length) {
          const days = brands.map(({ days }) => days);
          let resultObj = {};
          for (let index = 0; index < days.length; index++) {
            const element = days[index];
            for (const key in element) {
              if (resultObj[key] === true && element[key] === false) {
                resultObj = { ...resultObj };
              } else {
                resultObj = { ...resultObj, [key]: element[key] };
              }
            }
          }

          retailers.push({ ...element, days: resultObj, children: brands });
        }
      }

      dispatch(addToDataAssortmentSummaryCalendar({ result: retailers }));
    }
  }, [controlData, maxMinYValue]);
  //filter clicked arr by data
  useEffect(() => {
    if (clickedArray.length && data.result.length) {
      for (let index = 0; index < clickedArray.length; index++) {
        const element = clickedArray[index];

        const retailer = data.result.find(({ item }) => item.id === element.id);
        if (retailer?.item?.id) {
          returnNewClickedArr(retailer.item.id, retailer.children, retailer.item.color, retailer);
        } else {
          setClickedArray((prevState) => {
            const indexOfEl = prevState.findIndex((el) => el.id === element.id);
            return [...prevState.slice(0, indexOfEl), ...prevState.slice(indexOfEl + 1, prevState.length)];
          });
        }
      }
    }
  }, [data]);

  const returnNewClickedArr = (id, children, color, element) => {
    const mapedChildren = children.map(({ item: { title, id: childId, color: childColor }, period }) => {
      return {
        item: { id: childId, name: title, color: childColor ? childColor : color },
        days: period,
      };
    });

    const matchedEl = clickedArray.find((el) => el.id === id);

    setClickedArray((prevState) => {
      const indexOfEl = prevState.findIndex((el) => el.id === id);
      return [
        ...prevState.slice(0, indexOfEl),
        {
          ...matchedEl,
          children: mapedChildren,
          retailer: { id: element.id, label: element.label },
        },
        ...prevState.slice(indexOfEl + 1, prevState.length),
      ];
    });
  };

  return (
    <div className="calendar" ref={inputRef}>
      <div className="slider-wrapper">
        <Slider
          max={maxMinSliderValue.max}
          min={maxMinSliderValue.min}
          range
          value={sliderValue}
          onChange={(values) => {
            onSliderChange(values);
          }}
          onAfterChange={sliderValueHandler}
          tipFormatter={(e) => formatToDate(e)}
        />
      </div>

      {isEmpty ? (
        <RenderNoData style={{ height: 300, marginTop: 43 }} />
      ) : (
        <HighchartsReact ref={inputRef} highcharts={Highcharts} options={options} immutable={true} />
      )}
    </div>
  );
};

export default AssortmentCalendar;
