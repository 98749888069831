import styled from "styled-components";

export const Styles = styled.div`
  position: relative;
  .image-container {
    width: 100%;
    display: flex;
    align-content: center;
  }
  .avatar-placeholder {
    width: 86px;
    height: 86px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .image-text-container {
    margin-left: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }

  .image-text-container .ant-btn {
    margin: 0;
  }
  #file {
    display: none;
  }
  .upload-button {
    cursor: pointer;
    display: flex;
    justify-content: left;
    margin-left: 15px !important;
    font-size: 16px;
    font-family: "Gilroy-ExtraBold";
    color:  ${(props) => props.color}
  }
  .remove-button {
    font-size: 16px;
    font-family: "Gilroy-ExtraBold";
    color: #ff6866;
    border: none;
  }
  .reset-button {
    margin-top: 12px !important;
    text-align: left;
    font-size: 17px;
    font-family: "Gilroy-ExtraBold";
    border: none;
  }
  .upload-button::after {
    all: unset;
  }
  .avatar-label {
    & > div {
      padding-bottom: 16px;
      > label {
        font-family: "Gilroy-ExtraBold";
        font-size: 12px;
      }
    }
  }
  .ant-btn-default {
    background: #fff;
    align-self: center;
  }
  .color-theme-wrapper {
    display: flex;
    flex-direction: row;
  }
  .color-theme-wrapper .ant-form-item:first-child {
    margin-right: 2rem;
  }
`;
