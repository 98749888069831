import React, { useEffect } from "react";
import { Form, Input, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

//Actions
import { setSettingsChangePassword } from "store/settings/settingsChangePassword/actions";

//
import { Styles } from "./styles";
import { validatePassword } from "utils/validate";
import { STATE_STATUSES } from "utils/statuses";
import LoaderBox from "components/LoaderBox";
import ErrorRow from "components/ErrorRow";

const SettingsChangePasswordTab = ({ form }) => {
  const { status, error, success } = useSelector((state) => state.settingsChangePassword);
  const dispatch = useDispatch();

  const CURRENT_PASS = "currentPassword";
  const NEW_PASS = "newPassword";
  const REPEAT_PASS = "repeatedPassword";

  useEffect(() => {
    form.setFieldsValue({ [CURRENT_PASS]: "", [NEW_PASS]: "", [REPEAT_PASS]: "" });
  }, [form]);

  const onFinish = (values) => {
    dispatch(
      setSettingsChangePassword({
        oldPassword: values[CURRENT_PASS],
        newPassword: values[NEW_PASS],
      })
    ).then(() => {
      form.setFieldsValue({ [CURRENT_PASS]: "", [NEW_PASS]: "", [REPEAT_PASS]: "" });
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };

  return (
    <Styles>
      {!!error ? <ErrorRow message={error?.message ? error.message : error} /> : null}
      <Form
        name="change-password-form"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        labelAlign="left"
      >
        <div className="your-password">
          Your password should contain at least one upper case, at least one lower case, at least one digit, at least
          one special character.
        </div>

        <Form.Item
          label="Current Password"
          name={CURRENT_PASS}
          rules={[
            { required: true, message: "Please enter your current password!" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password placeholder="Enter your current password" />
        </Form.Item>

        <Form.Item
          label="Set a New Password"
          name={NEW_PASS}
          rules={[
            {
              required: true,
              message: "Please enter a new password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (getFieldValue(NEW_PASS) === getFieldValue(CURRENT_PASS)) {
                  return Promise.reject("New password should not equal current password");
                } else if (!validatePassword(value)) {
                  return Promise.reject(
                    "Password should contain at least one upper case, at least one lower case, at least one digit, at least one special character"
                  );
                } else return Promise.resolve();
                // return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="Enter your new password"
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>

        <Form.Item
          label="Repeat a New Password"
          name={REPEAT_PASS}
          dependencies={[NEW_PASS]}
          rules={[
            {
              required: true,
              message: "Please repeat a new password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (getFieldValue(REPEAT_PASS) === getFieldValue(CURRENT_PASS)) {
                  return Promise.reject("New password should not equal current password");
                } else if (!validatePassword(value)) {
                  return Promise.reject(
                    "Password should contain at least one upper case, at least one lower case, at least one digit, at least one special character"
                  );
                } else if (!value || getFieldValue(NEW_PASS) === value) {
                  return Promise.resolve();
                }
                return Promise.reject("New password and the repeated do not match.");
                // return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password placeholder="Enter your new password" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
      {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
    </Styles>
  );
};

export default SettingsChangePasswordTab;
