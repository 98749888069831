import styled from "styled-components";

export const Styles = styled.div`
  position: relative;
  .your-password {
    font-size: 14px;
    margin-bottom: 30px;
    font-family: "Gilroy-Medium";
  }
  .ant-input-suffix {
    display: flex;
    margin-right: 10px;

    & svg {
      height: 24px;
      width: 24px;
      color: #8a99a8;
      & > :nth-child(n + 3) {
        fill: #8a99a8;
      }
    }
    & :hover {
      color: #8a99a8;
    }
  }
`;
