import { success, error } from "redux-saga-requests";
import {
  FETCH_TEMP_FILTERS,
  SET_TEMP_TITLE_DATE,
  UPDATE_TEMP_COUNTRY,
  UPDATE_TEMP_FILTERS,
  RESET_TEMP_FILTERS,
  UPDATE_EQUAL_STATUS,
  CLEAR_EQUAL_STATUS,
} from "./actions";
import { STATE_STATUSES } from "utils/statuses";
import { RESET_ERROR } from "store/error/actions";

const initialState = {
  filters: {
    category: "",
    date: { relative: [] },
    manufacture: "",
    product: "",
    productBrand: "",
    productGroup: "",
    sourceType: "",
  },
  titleDate: localStorage.getItem("tempTitleDate") ? localStorage.getItem("tempTitleDate") : "",
  status: STATE_STATUSES.INIT,
  error: null,
  equalValues: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TEMP_COUNTRY:
    case FETCH_TEMP_FILTERS: {
      return processReducer(state);
    }
    case success(UPDATE_TEMP_COUNTRY):
    case success(FETCH_TEMP_FILTERS): {
      return {
        ...state,
        filters: action.data.filters,
        status: STATE_STATUSES.READY,
      };
    }
    case error(UPDATE_TEMP_COUNTRY):
    case error(FETCH_TEMP_FILTERS): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case SET_TEMP_TITLE_DATE: {
      return {
        ...state,
        titleDate: action.payload,
      };
    }

    case UPDATE_TEMP_FILTERS: {
      return {
        ...state,
        filters: action.payload,
        status: STATE_STATUSES.READY,
      };
    }

    case UPDATE_EQUAL_STATUS: {
      return {
        ...state,
        equalValues: {
          ...state.equalValues,
          ...action.payload,
        },
      };
    }
    case CLEAR_EQUAL_STATUS: {
      return {
        ...state,
        equalValues: {},
      };
    }

    case RESET_TEMP_FILTERS: {
      return {
        ...state,
        filters: initialState.filters,
        status: STATE_STATUSES.INIT,
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
