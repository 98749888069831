import MediaSummaryProduct from "./MediaSummaryProduct";
import Icon, { Icons } from "components/Icon/Icon";
import React from "react";
import useRetailers from "../../../hooks/useRetailers";

import { Styles } from "components/CalendarItems/Styles";

const MediaSummaryRetailersItem = ({
  data,
  index,
  clickedArray,
  onOpen,
  isBrand,
}) => {
  const { children } = data;
  const SEARCH = "Search Terms";
  const LOCATIONS = "Locations";
  const { getRemoteLogo } = useRetailers();
  const isIncluded = (id) => {
    if (typeof id !== "object") {
      if (clickedArray.some((el) => el.id === id)) {
        return true;
      }
    } else {
      const condition = clickedArray.filter((item) =>
        id.some((el) => el.id === item.id)
      );
      if (condition.length) {
        return true;
      }
    }
  };

  const renderRetailers = (element, type, index, col, brand) => {
    const { children, label, color, id } = element;
    const isBrands = type === "brands";
    const isBanners = type === "banners";

    const icon =
      label === SEARCH
        ? Icons["searchOpacity"]
        : label === LOCATIONS
        ? Icons["burger"]
        : !isBrand && isBrands
        ? Icons["placeholder"]
        : !isBrand && !isBrands
        ? getRemoteLogo(label)
        : isBrand && isBrands
        ? getRemoteLogo(label)
        : Icons["placeholder"];
    const colour = color ? color : col;

    const backgroundColor =
      (!isBrand && isBrands) || (!isBrand && isBanners)
        ? colour
        : (!isBrand && !isBrands) || (!isBrand && !isBanners)
        ? null
        : isBrand && isBrands
        ? null
        : colour;

    return (
      <div key={id}>
        <div
          className="retailer-item"
          onClick={() =>
            children.length
              ? onOpen(id, children, type, colour, data, brand)
              : () => {}
          }
          style={{ cursor: !children.length ? "not-allowed" : null }}
        >
          <div
            className={`plus-btn-${type}`}
            style={{ cursor: !children.length ? "not-allowed" : null }}
          >
            <span>{!isIncluded(id) ? "+" : "-"}</span>
          </div>
          <Icon
            icon={icon}
            width={24}
            heigth={24}
            style={{
              backgroundColor: backgroundColor,
              borderRadius: 2,
            }}
          />
          <div className="retailer-row">
            <div className="retailer-title">
              <span className="retailer-name">{label} </span>
              <span className="retailer-total">({children.length})</span>
            </div>
          </div>
        </div>
        {isIncluded(id) && isBrands
          ? children.map((el, i) => {
              return renderRetailers(el, "banners", index, color, brand);
            })
          : null}

        {isIncluded(id) && isBanners
          ? children.map((el, i) => {
              const isLastChild = i === children.length - 1;
              return (
                <div key={i}>
                  <MediaSummaryProduct item={el} isLastChild={isLastChild} />
                </div>
              );
            })
          : null}
      </div>
    );
  };

  return (
    <Styles>
      {renderRetailers(data, "retailers", index)}
      {isIncluded(data.id)
        ? children.map((el, index) => {
            return renderRetailers(el, "brands", index, el.color, el.id);
          })
        : null}
    </Styles>
  );
};

export default MediaSummaryRetailersItem;
