import React from 'react'
import styled from 'styled-components'
import useColorTheme from "../hooks/useColorTheme";

export const Styles = styled.div.attrs((props) => props)`
.loader1 {
    height: 10px;
    width: 60px;
    margin: 10px 0;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    background-color: #ddd;
  }
  .loader1:before{
    display: block;
    position: absolute;
    content: "";
    left: -60px;
    width: 60px;
    height: 10px;
    border-radius: 4px;
    background-color: ${(props) => props.color};
    animation: loading1 1.95s linear infinite;
  }
  @keyframes loading1 {
      from { rigth: 0%; width: 100%; }
      20% { left: 0%; width: 100%; }
      40% { left: 0%; width: 100%; }
      60% { left: 100%; width: 100%; }
      80% { left: 100%; width: 100%; }
      to { left: 100%; width: 100%; }
  }
  .loader2 {
    height: 10px;
    width: 30px;
    margin: 10px 0;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    background-color: #ddd;
  }
  .loader2:before{
    display: block;
    position: absolute;
    content: "";
    left: -30px;
    width: 30px;
    height: 10px;
    border-radius: 4px;
    background-color: ${(props) => props.color};
    animation: loading1 1.98s linear infinite;
  }
  @keyframes loading2 {
      from { rigth: 0%; width: 100%; }
      20% { left: 0%; width: 100%; }
      40% { left: 0%; width: 100%; }
      60% { left: 100%; width: 100%; }
      80% { left: 100%; width: 100%; }
      to { left: 100%; width: 100%; }
  }
  .loader3 {
    height: 10px;
    width: 60px;
    margin: 10px 0;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    background-color: #ddd;
  }
  .loader3:before{
    display: block;
    position: absolute;
    content: "";
    left: -60px;
    width: 60px;
    height: 10px;
    border-radius: 4px;
    background-color: ${(props) => props.color};
    animation: loading1 2s linear infinite;
  }
  @keyframes loading3 {
      from { rigth: 0%; width: 100%; }
      20% { left: 0%; width: 100%; }
      40% { left: 0%; width: 100%; }
      60% { left: 100%; width: 100%; }
      80% { left: 100%; width: 100%; }
      to { left: 100%; width: 100%; }
  }
`

const CustomLoader = () => {
  const { primaryColor } = useColorTheme();
  return (
      <Styles color={primaryColor}>
          <div className="loader1"></div>
          <div className="loader2"></div>
          <div className="loader3"></div>
      </Styles>
  )
}

export default CustomLoader