import React, { useState, useEffect, useRef } from "react";
import { alertTemplate } from "components/Alerts/alertTemplate";
import { ArrowSelect } from "assets/icons/icons";
import moment from "moment";
import useColorTheme from "../../../hooks/useColorTheme";

const TimeBox = ({ value, setValue }) => {
  const { primaryColor } = useColorTheme();
  const { hours, minutes } = alertTemplate().schedule["time"];

  const wrapperRef = useRef(null);

  const [hoursValue, setHoursValue] = useState("");
  const [minutesValue, setMinutesValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  const setDefaultValue = () => {
    if (value?.length) {
      const hours = moment(value).format("HH:mm").split(":")[0];
      const minutes = moment(value).format("HH:mm").split(":")[1];

      setHoursValue(hours);
      setMinutesValue(minutes);
    }
  };

  useEffect(() => {
    setDefaultValue();
  }, [value]);

  const handleChangeTime = () => {
    setValue(moment(`${moment().format("YYYY-MM-DD")} ${hoursValue}:${minutesValue}`).format());
    setShowDropdown(false);
  };

  const handleResetTime = () => {
    setHoursValue("");
    setMinutesValue("");
  };

  const getStyle = (item, time) => ({
    background: time === item.value ? primaryColor : "",
    color: time === item.value ? "#fff" : "",
  });

  const renderHours = (type, date) => {
    return (
      <div className="date-box">
        <div style={{ marginTop: "8px", marginRight: "16px" }} className="title">
          {type}
        </div>
        <div className="par-time">
          {date.map((item, index) => (
            <div key={index} className="time-item" style={getStyle(item, hoursValue)} onClick={() => setHoursValue(item.value)}>
              {item.label}
            </div>
          ))}
        </div>
      </div>
    );
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      if (showDropdown) {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setShowDropdown(false);
            setDefaultValue();
          }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
    }, [ref, showDropdown]);
  }
  useOutsideAlerter(wrapperRef);

  return (
    <div className="wrapper-time" ref={wrapperRef}>
      <div className="time-value" onClick={() => setShowDropdown(!showDropdown)}>
        <div>{value ? moment(value).format("hh:mm A") : "Set a time"}</div>
        <ArrowSelect />
      </div>
      {showDropdown ? (
        <div className="time-dropdown">
          <div className="time-box">
            <div className="hours">
              <div className="title">Hours</div>
              <div className="hours-box">
                {renderHours("AM", hours.am)}
                {renderHours("PM", hours.pm)}
              </div>
            </div>
            <div className="minutes">
              <div className="title">Minutes</div>
              <div className="minute-box">
                {minutes.map((item, index) => (
                  <div key={index} className="time-item" style={getStyle(item, minutesValue)} onClick={() => setMinutesValue(item.value)}>
                    {item.label}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div style={{ padding: "10px 16px 0 16px" }} className="control-select-btn">
            <div onClick={handleResetTime}>Reset</div>
            <button className="apply" disabled={!hoursValue?.length || !minutesValue?.length} onClick={handleChangeTime}>
              Apply
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default TimeBox;
