import React, { useEffect, useRef, useState } from "react";

import search from "assets/images/search.svg";

import SelectBox from "components/Selects/SelectBox";
import productImage from "assets/images/testImage.png";

import { Styles } from "./styles";
import { Dropdown, Menu } from "antd";

const ProductSearch = ({
  productFilter,
  setProductFilter,
  handleSearch,
  number,
  searchForProd,
  retailersFilter,
  productArr,
  setProduct,
  setDropdownVisible,
  dropdownVisible,
  product,
}) => {
  const isEmpty = productArr[0] === "empty";
  const wrapperRef = useRef();
  const suffix = number === 1 ? "st" : "nd";

  useEffect(() => {
    if (productArr.length && !isEmpty && !Object.entries(product).length) {
      setDropdownVisible(true);
    } else {
      setDropdownVisible(false);
    }
  }, [productArr, product]);

  const onMenuClick = (el) => {
    handleSearch(el.coreTitle);
    setProduct(el);
    setDropdownVisible(false);
  };

  const inputHandler = (el) => {
    handleSearch(el);
    setProduct({});
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      if (dropdownVisible) {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setDropdownVisible(false);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
    }, [ref, dropdownVisible]);
  }
  useOutsideAlerter(wrapperRef);

  const menu = (
    <Menu>
      {productArr.length && !isEmpty ? <span className="products-count">{productArr.length} Products</span> : null}
      {productArr.length && !isEmpty
        ? productArr.map((el, i) => {
            return (
              <Menu.Item onClick={() => onMenuClick(el)} onItemHover={() => {}} key={i}>
                <div className="product-item">
                  <img src={el.productImage ? el.productImage : productImage} />
                  <span>{el.coreTitle}</span>
                </div>
              </Menu.Item>
            );
          })
        : null}
    </Menu>
  );

  return (
    <Styles>
      <div className="search-product-wrapper">
        <span>
          Choose {number}
          {suffix} product
        </span>
        <div className="row" ref={wrapperRef}>
          <div style={{ textTransform: "capitalize" }}>
            <SelectBox
              filter={productFilter.value}
              data={retailersFilter}
              setSelectValue={setProductFilter}
              customWidth={"148px"}
            />
          </div>

          <Dropdown
            overlay={menu}
            placement="bottomLeft"
            trigger="click"
            visible={dropdownVisible}
            getPopupContainer={(trigger) => trigger.parentElement}
          >
            <div className="search">
              <img src={search} alt="search" />
              <input
                onChange={(e) => inputHandler(e.target.value)}
                placeholder="Choose Product"
                value={searchForProd}
              />
              {productArr.length && isEmpty ? (
                <span className="error">Product not listed in the selected retailer. Select another product.</span>
              ) : null}
            </div>
          </Dropdown>
        </div>
      </div>
    </Styles>
  );
};

export default ProductSearch;
