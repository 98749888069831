import { success, error } from "redux-saga-requests";
import { FETCH_RANKING, SET_PRISTINE_RANKING, FETCH_COMPARE_RANKING, FETCH_RANKING_RETAILERS } from "./actions";
import { RESET_ERROR } from "store/error/actions";

import { STATE_STATUSES } from "utils/statuses";

const initialState = {
  ranking: {
    data: [],
    cheapestPrice: [],
    expensivePrice: [],
  },
  status: STATE_STATUSES.INIT,
  isPristine: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RANKING:
    case FETCH_COMPARE_RANKING:
    case FETCH_RANKING_RETAILERS: {
      return processReducer(state);
    }
    case success(FETCH_RANKING): {
      return {
        ...state,
        ranking: action.data,
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }
    case success(FETCH_COMPARE_RANKING):
    case success(FETCH_RANKING_RETAILERS): {
      return {
        ...state,
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }
    case error(FETCH_RANKING):
    case error(FETCH_COMPARE_RANKING):
    case error(FETCH_RANKING_RETAILERS): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case SET_PRISTINE_RANKING: {
      return {
        ...state,
        isPristine: action.payload,
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
