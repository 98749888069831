import { success, error } from "redux-saga-requests";
import { FETCH_FILTERS, SET_TITLE_DATE, UPDATE_COUNTRY, UPDATE_FILTERS, RESET_TITLE_DATE, SET_IS_VALIDATED } from "./actions";
import { STATE_STATUSES } from "utils/statuses";
import { RESET_ERROR } from "store/error/actions";

const initialState = {
  filters: {
    category: "",
    date: { relative: [] },
    manufacture: "",
    product: "",
    productBrand: "",
    productGroup: "",
    sourceType: "",
  },
  titleDate: localStorage.getItem("TitleDate") ? localStorage.getItem("TitleDate") : "",
  titleDateStatus: localStorage.getItem("TitleDate") ? STATE_STATUSES.READY : STATE_STATUSES.INIT,
  status: STATE_STATUSES.INIT,
  countryStatus:  STATE_STATUSES.INIT,
  isValidated: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_COUNTRY: {
      return {
        ...state,
        countryStatus: STATE_STATUSES.PENDING,
      };
    }
    case FETCH_FILTERS: {
      return processReducer(state);
    }
    case success(UPDATE_COUNTRY): {
      return {
        ...state,
        countryStatus: STATE_STATUSES.READY,
      };
    }
    case success(FETCH_FILTERS): {
      return {
        ...state,
        filters: action.data.filters,
        status: STATE_STATUSES.READY,
      };
    }
    case error(UPDATE_COUNTRY):
    case error(FETCH_FILTERS): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case SET_TITLE_DATE: {
      return {
        ...state,
        titleDate: action.payload,
        titleDateStatus: STATE_STATUSES.READY,
      };
    }
    
    case SET_IS_VALIDATED: {
      return {
        ...state,
        isValidated: action.payload,
      };
    }

    case RESET_TITLE_DATE: {
      return {
        ...state,
        titleDateStatus: STATE_STATUSES.INIT,
      };
    }

    case UPDATE_FILTERS: {
      return {
        ...state,
        filters: action.payload,
        status: STATE_STATUSES.READY,
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
