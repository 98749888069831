import React from "react";
import { Space, Alert } from "antd";

const AlertMessage = ({
  direction,
  width,
  description,
  message,
  type,
  showIcon = false,
  closable = false,
  padding,
}) => {
  return (
    <Space direction={direction} width={width} style={{ padding: padding }}>
      <Alert description={description} message={message} type={type} showIcon={showIcon} closable={closable} />
    </Space>
  );
};

export default AlertMessage;
