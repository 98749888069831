import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { greaterColor, lessColor, equallyColor } from "utils/colorsCharts";

const ContentCardChart = ({ data }) => {
  const [pricings, setPricings] = useState([]);

  useEffect(() => {
    if (data.length) {
      const max = Math.max(...data);
      const min = Math.min(...data);

      const dataChart = data.map((el) => ({
        y: el,
        marker: {
          enabled: el === max || el === min,
          lineColor: el === max ? "#1cc08a" : "#fe6a68",
          lineWidth: 2,
          fillColor: "#fff",
          radius: 4,
        },
      }));

      const start = dataChart[0];
      const end = dataChart[dataChart.length - 1];

      const pricing = [
        {
          color:
            start.y === end.y
              ? "#a0a2a6"
              : start.y < end.y
              ? "#1ec68d"
              : "#fe6a68",
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops:
              start.y === end.y
                ? equallyColor
                : start.y < end.y
                ? greaterColor
                : lessColor,
          },

          data: dataChart,
        },
      ];

      setPricings(pricing);
    }
  }, [data]);

  const options = {
    title: "",
    chart: {
      type: "areaspline",
      height: 48,
      animation: false,
      margin: [5, 5, 5, 5],
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        states: {
          hover: {
            enabled: false,
          },
        },
        marker: {
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
      areaspline: {
        marker: {
          enabled: false,
        },
        lineWidth: 2,
        threshold: null,
      },
    },
    xAxis: {
      type: "line",
      tickColor: "transparent",
      title: {
        text: "",
      },
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      visible: false,
      title: {
        text: "",
      },
      labels: {
        enabled: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    series: pricings,
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default ContentCardChart;
