import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Pagination, Slider } from "antd";
import СurrentlyItemChart from "tabs/RankingTabs/RankingBottomTabs/SearchAnalysisTab/charts/СurrentlyItemChart";
import { getTimePeriodRanking } from "utils/getTimePeriodRanking";
import { STATE_STATUSES } from "utils/statuses";
import LoaderBox from "components/LoaderBox";
import SelectBox from "components/Selects/SelectBox";
import ProductPopup from "tabs/RankingTabs/RankingBottomTabs/components/ProductPopup";
import moment from "moment";

import star from "assets/images/star.svg";
import cup from "assets/images/cup.svg";
import search from "assets/images/search.svg";
import useCache from "hooks/useCache";
import useFilters from "hooks/useFilters";

const selects = [
  {
    name: "limit",
    default: "10",
    options: [
      { lable: "10", value: "10" },
      { lable: "15", value: "15" },
      { lable: "20", value: "20" },
    ],
  },
];

const СurrentlyProducts = (props) => {
  const {
    type,
    topRanking,
    searchTerm,
    manufacturer,
    currentShare,
    storeProducts,
    actionProducts,
    storeProductPopup,
    actionProductPopup,
    actionSingleProduct,
    storeSingleProduct,
    actionRemoveProduct,
    actionProductCompare,
    storeProductCompare,
    actionShowPopup,
    showPopup,
    status,
  } = props;
  const { mainLastFilter: lastFilter, statusFilters } = useFilters();
  const { cachedCurrentItemTermSearch } = useCache();
  const [queryParams, setQueryParams] = useState({});

  const [marks, setMarks] = useState([]);
  const [dates, setDates] = useState([]);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(null);
  const [timePeriod, setTimePeriod] = useState([]);

  useEffect(() => {
    setQueryParams((prevState) => ({
      ...prevState,
      type,
      raw: false,
      topRanking: topRanking,
      productName: "",
      searchTerm: searchTerm,
      manufacturer: manufacturer.toString(),
      sourceType: props[currentShare].sourceType,
      timePeriod: getTimePeriodRanking(lastFilter.date),
      product: lastFilter.product,
      page: "1",
      limit: "10",
    }));
  }, [lastFilter, topRanking, searchTerm, manufacturer, props[currentShare]]);

  useEffect(() => {
    if (Object.keys(queryParams).length && statusFilters === STATE_STATUSES.READY) {
      actionProducts(queryParams);
    }
  }, [queryParams, statusFilters, actionProducts]);

  useEffect(() => {
    if (Object.entries(props[storeProducts]).length !== 0) {
      const { averageRankingData } = props[storeProducts];

      const maxValue = averageRankingData.length - 1;

      const marks = {};
      const dates = {};

      let multiple = 5;

      if (maxValue > 31) {
        multiple = 10;
      }

      averageRankingData.forEach((item, index) => Object.assign(dates, { [index]: moment(item.date).format("DD MMM YY") }));

      averageRankingData.forEach((item, index) => {
        if (index % multiple == 0) {
          Object.assign(marks, { [index]: moment(item.date).format("DD MMM YY") });
        }
      });

      setMarks(marks);
      setDates(dates);
      setMaxValue(maxValue);
      setTimePeriod([0, maxValue]);
    }
  }, [props[storeProducts]]);

  const onChangePage = (page) => {
    setQueryParams((prevState) => ({
      ...prevState,
      page: page,
    }));
  };

  const setPerPage = (values) => {
    const value = Object.keys(values);

    setQueryParams((prevState) => ({
      ...prevState,
      page: "1",
      limit: values[value],
    }));
  };

  const handleSearch = (value) => {
    setQueryParams((prevState) => ({
      ...prevState,
      productName: value,
      page: "1",
    }));
  };

  const onChangeTimePeriod = (value) => {
    setTimePeriod([value[0], value[1]]);
  };

  return (
    <div className="wrapper-box-relative">
      {Object.entries(props[storeProducts]).length !== 0 ? (
        <div>
          <div className="currently-viewing-title">
            You are currently viewing <span>{props[currentShare].retailer}</span> products for{" "}
            <span>“{type === "search" ? cachedCurrentItemTermSearch.title : props.termProdcut}”</span>.
          </div>
          <div className="filters-viewing">
            <div className="search-box">
              <img src={search} alt="search" />
              <input value={queryParams.productName} onChange={(e) => handleSearch(e.target.value)} placeholder="Search for a Product" />
            </div>
            <div className="products-slider">
              <Slider
                range
                min={minValue}
                max={maxValue}
                marks={marks}
                value={timePeriod}
                onChange={onChangeTimePeriod}
                tipFormatter={(e) => dates[e]}
              />
            </div>
          </div>
          <div className="currently-viewing-box">
            {props[storeProducts].products.length ? (
              <>
                <div className="product-wrapper">
                  <div className="product-box main">
                    <div className="product-title">
                      <span>“{type === "search" ? cachedCurrentItemTermSearch.title : props.termProdcut}”</span> in{" "}
                      <span className="retailer">{props[currentShare].retailer}</span>
                    </div>
                  </div>
                  <div style={{ width: "100%" }}>
                    <СurrentlyItemChart
                      type={"average"}
                      currentRetailer={props[currentShare].retailer}
                      actionType={type}
                      timePeriod={timePeriod}
                      data={props[storeProducts].averageRankingData}
                      searchTerm={type === "search" ? cachedCurrentItemTermSearch.title : props.termProdcut}
                      sourceType={props[currentShare].sourceType}
                      manufacturer={manufacturer.toString()}
                      productId={0}
                      actionProductPopup={actionProductPopup}
                      isPopup={false}
                      outside={false}
                    />
                  </div>
                </div>
                <div>
                  {props[storeProducts].products.map((item, index) => (
                    <div key={index} className="product-wrapper">
                      <div className="product-box">
                        <img className="product-image" src={item.image} alt="product" />
                        <div className="product-title">{item.title}</div>
                        <div className="icons-box">
                          {item.star ? <img src={star} alt="start" /> : null}
                          {item.topRanking ? <img src={cup} alt="cup" /> : null}
                        </div>
                      </div>
                      <div style={{ width: "100%" }}>
                        <СurrentlyItemChart
                          type={"rank"}
                          actionType={type}
                          timePeriod={timePeriod}
                          data={item.ranks}
                          searchTerm={type === "search" ? cachedCurrentItemTermSearch.title : props.termProdcut}
                          sourceType={props[currentShare].sourceType}
                          manufacturer={manufacturer.toString()}
                          productId={item.id}
                          actionProductPopup={actionProductPopup}
                          isPopup={false}
                          outside={false}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div style={{ marginTop: "32px" }} className="chart-no-data static">
                Looks like we don't have data for this request
              </div>
            )}

            {props[status] === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
          </div>
          {props[storeProducts].fullCount > 0 ? (
            <div className="pagimation-wrapper">
              <Pagination
                className="pagination-controls"
                onChange={onChangePage}
                current={queryParams.page ? Number(queryParams.page) : 1}
                pageSize={queryParams.limit ? Number(queryParams.limit) : 10}
                total={props[storeProducts].fullCount}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
              />
              {props[storeProducts].fullCount >= 20
                ? selects.map((item, index) => (
                    <div className="per-page-box" key={index}>
                      <span>Show</span>
                      <SelectBox key={index} data={item} setSelectValue={setPerPage} />
                      <span style={{ marginLeft: "15px" }}>Per Page</span>
                    </div>
                  ))
                : null}
            </div>
          ) : null}
        </div>
      ) : null}
      {props[status] === STATE_STATUSES.PENDING && Object.entries(props[storeProducts]).length === 0 ? <LoaderBox /> : null}
      {props[showPopup] ? (
        <ProductPopup
          storeProductPopup={storeProductPopup}
          actionShowPopup={actionShowPopup}
          showPopup={showPopup}
          type={type}
          searchTerm={searchTerm}
          sourceType={props[currentShare].sourceType}
          actionSingleProduct={actionSingleProduct}
          storeSingleProduct={storeSingleProduct}
          actionRemoveProduct={actionRemoveProduct}
          actionProductCompare={actionProductCompare}
          storeProductCompare={storeProductCompare}
          status={status}
          manufacturer={manufacturer.toString()}
          actionProductPopup={actionProductPopup}
        />
      ) : null}
    </div>
  );
};

export default connect(
  (state) => ({
    rankingSearchAnalysisProducts: state.rankingSearchAnalysis.rankingSearchAnalysisProducts,
    currentShareSearch: state.rankingSearchAnalysis.currentShareSearch,
    showProductSearchPopup: state.rankingSearchAnalysis.showProductPopup,
    statusSearch: state.rankingSearchAnalysis.productsStatus,

    rankingLocationAnalysisProducts: state.rankingLocationAnalysis.rankingLocationAnalysisProducts,
    currentShareLocation: state.rankingLocationAnalysis.currentShareLocation,
    termProdcut: state.rankingLocationAnalysis.termProdcut,
    showProductLocationPopup: state.rankingLocationAnalysis.showProductPopup,
    statusLocation: state.rankingLocationAnalysis.productsStatus,
  }),
  null
)(СurrentlyProducts);
