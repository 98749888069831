import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//Component
import CompareBox from "components/CompareBox";
import TopMovers from "components/TopMovers";
import ChartSingleLine from "components/Charts/ChartSingleLine";
import LoaderBox from "components/LoaderBox";
import useFilters from "../../../../hooks/useFilters";

//Utils
import { getTimePeriod } from "utils/getTimePeriod";
import { STATE_STATUSES } from "utils/statuses";
import { getOverallChange } from "utils/getOverallChange";
//Actions
import { fetchContentTrend, fetchCompareContentTrend, setCompareData } from "store/content/contentTrend/actions";

const ContentTrend = () => {
  const dispatch = useDispatch();

  const { contentTrend, status } = useSelector((state) => state.contentTrend);
  const { currentData, topMovers, overalChange, compareData } = contentTrend;
  const { mainLastFilter: lastFilter, statusFilters } = useFilters();
  const [comparativeValue, setComparativeValue] = useState("");
  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    setQueryParams((prevState) => ({
      ...prevState,
      sourceType: lastFilter.sourceType,
      timePeriod: getTimePeriod(lastFilter.date),
      product: lastFilter.product,
    }));
  }, [lastFilter]);

  useEffect(() => {
    if (Object.keys(queryParams).length && statusFilters === STATE_STATUSES.READY) {
      dispatch(fetchContentTrend(queryParams));
    }
  }, [queryParams, statusFilters, dispatch]);

  useEffect(() => {
    if (Boolean(comparativeValue?.length)) {
      dispatch(
        fetchCompareContentTrend({
          sourceType: lastFilter.sourceType,
          timePeriod: comparativeValue,
          product: lastFilter.product,
        })
      );
    } else {
      dispatch(setCompareData({}));
    }
  }, [comparativeValue]);

  const renderChart = () => {
    return (
      <div
        className="chart-price-wrapper"
        style={{ width: `${Boolean(comparativeValue?.length) ? "100%" : "calc(100% - 425px)"}` }}
      >
        <div className="chart-title-desc">
          {getOverallChange(overalChange, "shelfPrices", [
            "Overall, the content score",
            "increased by",
            "decreased by",
          ])}
        </div>
        <ChartSingleLine
          priceParam={"shelfPrices"}
          currentData={currentData}
          compareData={
            Object.entries(compareData).length !== 0 ? compareData?.byTotal?.priceChart : { data: { values: [] } }
          }
          isCompare={Boolean(comparativeValue?.length)}
          overallChange={overalChange}
          type={"content"}
        />
      </div>
    );
  };

  const renderTopMovers = () => {
    return !comparativeValue?.length ? (
      <TopMovers currentChart={"byTotal"} priceParam={"shelfPrices"} topMovers={topMovers} />
    ) : null;
  };

  return (
    <>
      <div className="filters-box">
        <CompareBox setComparativeValue={setComparativeValue} disabled={status === STATE_STATUSES.PENDING} />
      </div>
      <div className="wrapper-box-relative">
        {currentData.dates.length ? (
          <div className="chart-wrapper-box">
            {renderChart()}
            {renderTopMovers()}
          </div>
        ) : null}
        {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
      </div>
    </>
  );
};

export default ContentTrend;
