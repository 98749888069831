import { SHOW_ERROR_POPUP } from "./actions";

const initialStore = {
  isShowError: false,
};

export default (state = initialStore, { type, data }) => {
  switch (type) {
    case SHOW_ERROR_POPUP: {
      return { ...state, isShowError: data };
    }
    default: {
      return state;
    }
  }
};
