const url = process.env.REACT_APP_URL;

export const FETCH_TEMP_FILTERS = "FETCH_TEMP_FILTERS";
export const fetchTempFilters = (data) => ({
  type: FETCH_TEMP_FILTERS,
  request: {
    url: `${url}/v2/filters/universal`,
    method: "POST",
    data,
  },
  meta: {
    asPromise: true,
  },
});

export const SET_TEMP_TITLE_DATE = "SET_TEMP_TITLE_DATE";
export const setTitleDateStore = (title) => {
  localStorage.setItem("tempTitleDate", title);
  return {
    type: SET_TEMP_TITLE_DATE,
    payload: title,
  };
};

export const UPDATE_TEMP_FILTERS = "UPDATE_TEMP_FILTERS";
export const updateFilters = (filters) => {
  return {
    type: UPDATE_TEMP_FILTERS,
    payload: filters,
  };
};

export const UPDATE_EQUAL_STATUS = "UPDATE_EQUAL_STATUS";
export const updateEqualStatus = (equalValues) => {
  return {
    type: UPDATE_EQUAL_STATUS,
    payload: equalValues,
  };
};
export const CLEAR_EQUAL_STATUS = "CLEAR_EQUAL_STATUS";
export const clearEqualStatus = () => {
  return {
    type: CLEAR_EQUAL_STATUS,
  };
};
export const RESET_TEMP_FILTERS = "RESET_TEMP_FILTERS";
export const resetFilters = () => {
  return {
    type: RESET_TEMP_FILTERS,
  };
};

export const UPDATE_TEMP_COUNTRY = "UPDATE_TEMP_COUNTRY";
export const updateCountry = (data) => ({
  type: UPDATE_TEMP_COUNTRY,
  request: {
    url: `${url}/auth/country`,
    method: "PUT",
    data,
  },
  meta: {
    asPromise: true,
  },
});
