import { success, error } from "redux-saga-requests";
import { FETCH_PROMOTION_BREAKDOWN, SET_BREAKDOWN_FILTER, SET_PRISTINE_PROMOTIONS_BREAKDOWN } from "./actions";
import { RESET_ERROR } from "store/error/actions";

import { STATE_STATUSES } from "utils/statuses";

const defaultFilter = [
  {
    name: "All",
    value: "All",
  },
  { name: "discount", value: "percent" },
  {
    name: "byRetailer",
    value: "byRetailer",
  },
  { name: "rangeValues", value: [] },
];

const initialState = {
  promotionBreakdown: {
    success: false,
    averageDiscount: 0,
    averageDiscountPercent: 0,
    products: 0,
    byRetailer: {
      overall: 0,
      totalRatio: [],
      data: [],
    },
    byBrand: {
      overall: 0,
      totalRatio: [],
      data: [],
    },
    range: { percent: { min: 0, max: 0 }, value: { min: 0, max: 0 } },
  },
  filter: localStorage.getItem(SET_BREAKDOWN_FILTER)
    ? JSON.parse(localStorage.getItem(SET_BREAKDOWN_FILTER))
    : defaultFilter,
  status: STATE_STATUSES.INIT,
  isPristine: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROMOTION_BREAKDOWN: {
      return processReducer(state);
    }
    case success(FETCH_PROMOTION_BREAKDOWN): {
      return {
        ...state,
        promotionBreakdown: action.data,
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }
    case error(FETCH_PROMOTION_BREAKDOWN): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case SET_PRISTINE_PROMOTIONS_BREAKDOWN: {
      return {
        ...state,
        isPristine: action.payload,
      };
    }

    case SET_BREAKDOWN_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
