import React, { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_rounded from "highcharts-rounded-corners";

//Assets
import { colorsPriceCut, opacityColorsPriceCut } from "assets/colors/colors";
//Components
import RenderNoData from "components/RenderNoData";
import RowOfBrands from "components/RowOfBrands";

import useRetailers from "../../../../../hooks/useRetailers";
import useCountry, { NUMBER_TYPE } from "../../../../../hooks/useCountry";
import { changeNameRetailer } from "utils/changeNameRetailer";

HC_rounded(Highcharts);

const PromotionBreakDownChart = (props) => {
  //Props
  const { data, currentChart, setHoverEl, hoverEl, arrayOfDesiredBrand, setArrayOfDesiredBrand } = props;

  //Refs
  const inputRef = useRef(null);
  const moreBrandsRef = useRef(null);
  const ref = useRef(null);
  const { getRemoteLogo } = useRetailers();

  //Constants
  const isBrand = currentChart[2].value === "byBrand";
  const isDiscountInPercents = currentChart[1].value === "percent";
  const MORE = "more";
  const PRICE_CUT = "Price Cut";
  const MULTIBUY = "Multibuy";
  const OTHER = "Other";
  const renderLabelsStep = 16;

  //States
  const [names, setNames] = useState([]);
  const [pricings, setPricings] = useState([]);
  const [isTooMoreData, setIsTooMoreData] = useState(false);

  const [emptyData, setEmptyData] = useState(false);
  const [clickedLabel, setClickedLabel] = useState("");
  const [chartWidth, setChartWidth] = useState(0);
  const { formatCurrencyNumber } = useCountry();

  useEffect(() => {
    pricings[0]?.data.length * 25 > ref?.current?.offsetWidth
      ? setChartWidth(pricings[0]?.data.length * 25)
      : setChartWidth(ref?.current?.offsetWidth);
  }, [ref, pricings]);

  //Converting Data
  useEffect(() => {
    const dataFilteredByChart = data[currentChart[2].value];
    //Find Names
    const namesArr = dataFilteredByChart.data.map(({ title, color }) => ({
      name: title,
      color: color,
    }));
    setNames(namesArr);

    const promotionsActivityArr = dataFilteredByChart.data.map(({ promotionActivity }) => promotionActivity);

    const getDesiredArray = (type, colorType) => {
      //get arr of obj with key of type and get its value(products)
      const data = dataFilteredByChart.data.map((el) => {
        return el.activity.find(({ title }) => title === type).products;
      });
      //formating this arr to have obj with keys: name, color, y
      const dataWithNameAndColors = data.map((el, indx) => {
        const isIncluded = arrayOfDesiredBrand.includes(namesArr[indx].name);

        const isHovered = namesArr[indx].name === hoverEl;
        const isPriceCut = type === PRICE_CUT && indx % 3 === 0;

        const color = isPriceCut
          ? colorsPriceCut[colorType]
          : type === MULTIBUY && indx % 2 === 0
          ? colorsPriceCut[colorType]
          : colorsPriceCut[colorType];

        const opacityColor = isPriceCut
          ? opacityColorsPriceCut[colorType]
          : type === MULTIBUY && indx % 2 === 0
          ? opacityColorsPriceCut[colorType]
          : opacityColorsPriceCut[colorType];

        const getColor = () => {
          if (isBrand) {
            if (isIncluded) {
              return color;
            } else {
              return opacityColor;
            }
          } else {
            if (hoverEl && hoverEl.length && !isHovered) {
              return opacityColor;
            } else if (isHovered && hoverEl.length) {
              return color;
            } else {
              return color;
            }
          }
        };

        return {
          name: namesArr[indx].name,
          y: el,
          color: getColor(),
          activity: promotionsActivityArr[indx],
        };
      });

      return dataWithNameAndColors;
    };

    const priceCutData = getDesiredArray(PRICE_CUT, "priceCut");

    const multiBuyData = getDesiredArray(MULTIBUY, "multibuy");

    const otherData = getDesiredArray(OTHER, "other");
    //get pricing arr with desired data
    const pricing = [
      { name: "Price Cut", data: priceCutData },
      { name: "Multibuy", data: multiBuyData },
      { name: "Other", data: otherData },
    ];
    //filter pricing by filter props
    const dataAfterFilter = pricing.filter((el) => {
      if (currentChart[0].value === "All") {
        return true;
      }
      if (el.name === currentChart[0].value) {
        return true;
      }
    });

    const dataWithRoundedCorners = dataAfterFilter.map((el, i) => {
      if (i === 0) {
        return {
          ...el,
          borderRadiusTopLeft: isBrand ? "40%" : "10%",
          borderRadiusTopRight: isBrand ? "40%" : "10%",
        };
      } else {
        return el;
      }
    });
    setPricings(dataWithRoundedCorners);
  }, [data, currentChart, arrayOfDesiredBrand, hoverEl]);
  //check for empty array
  useEffect(() => {
    if (pricings.length) {
      pricings.forEach(({ data }) => {
        if (data.length) {
          setEmptyData(false);
        } else {
          setEmptyData(true);
        }
      });
    } else {
      setEmptyData(true);
    }
  }, [pricings]);

  useEffect(() => {
    if (names.length > renderLabelsStep && isBrand) {
      if (!arrayOfDesiredBrand.length) {
        setIsTooMoreData(true);
      } else {
        setIsTooMoreData(false);
      }
    } else {
      setIsTooMoreData(false);
    }
  }, [names, isBrand, arrayOfDesiredBrand]);

  //Settings for chart
  const options = {
    title: {
      text: "",
    },

    chart: {
      width: chartWidth,
      animation: false,

      type: "column",

      style: {
        fontFamily: "Gilroy-Medium",
        fontSize: "10px",
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: null,
      borderWidth: 0,
      formatter: function () {
        let initialData = this.points.map((item) => {
          return `<div class='wrapper'>
                    <div class='box'>
                      <div class='name'>${item.series.name}</div>
                    </div>
                    <div class='price'>${item.y}</div>
                   </div>`;
        });

        initialData = initialData.join("");

        return `<div class="wrapper-category">
          <div class='title'>${changeNameRetailer(this.points[0].key)}</div>
          <div class="tip">${+this.points[0].point.activity}% of total promotion activity</div>
          <div class='wrapper-box-promotion'>
              ${initialData}
            </div>`;
      },
    },
    xAxis: {
      categories: names.map(({ name }) => name),
      labels: {
        useHTML: !isBrand,
        formatter: function () {
          return `<img style="width: 25px;" src=${getRemoteLogo(this.value)}></img>`;
        },
      },
    },
    yAxis: {
      tickInterval: 8,
      title: {
        offset: 15,
        x: 25,
        text: "No. of products",
      },
      offset: 30,
      labels: { style: { fontSize: "10px" } },
      style: {
        fontFamily: "Gilroy-Medium",
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        animation: false,

        stacking: "normal",
        borderWidth: 0,
        pointWidth: isBrand ? 6 : 24,
        groupPadding: 0.2,
        states: {},
        point: {
          events: {
            click: function () {
              setClickedLabel(this.category);
            },
            mouseOut: function () {
              setElement("");
            },
            mouseOver: function () {
              setElement(this.category);
            },
          },
        },
        events: {},
      },
    },
    series: pricings,
  };

  useEffect(() => {
    if (clickedLabel) {
      onLabelClick(clickedLabel);
    }
  }, [clickedLabel]);

  //set hover element
  const setElement = (el) => {
    setHoverEl(el);
  };
  //if array of brands has at least 1 item remove div with opacity
  useEffect(() => {
    if (arrayOfDesiredBrand.length) {
      setIsTooMoreData(false);
    }
  }, [arrayOfDesiredBrand]);
  //put name of brand to array
  const onLabelClick = (key) => {
    let array;

    if (arrayOfDesiredBrand.includes(key)) {
      array = arrayOfDesiredBrand.filter((brand) => brand !== key);
      setArrayOfDesiredBrand(array);
    } else {
      array = [...arrayOfDesiredBrand, key];
      setArrayOfDesiredBrand(array);
    }
  };

  return (
    <div className="chart-price-wrapper" style={{ width: `calc(100% - 425px)` }} ref={ref}>
      <div className={isTooMoreData ? "too-more-data" : "display-null"}>
        <p>Looks like we have a lot of data.</p>
        <p>Select the buttons below to see the graph.</p>
      </div>
      <div className="chart-title-desc">
        Overall we found {data.products} Products on Promotion, with an average discount of{" "}
        {isDiscountInPercents
          ? `${formatCurrencyNumber(data.averageDiscountPercent, NUMBER_TYPE.DECIMAL)}%`
          : `${formatCurrencyNumber(data.averageDiscount, NUMBER_TYPE.CURRENCY)}`}
        , based on the selected filters.
      </div>

      {emptyData ? (
        <RenderNoData />
      ) : (
        <HighchartsReact
          ref={inputRef}
          highcharts={Highcharts}
          options={options}
          containerProps={{ className: "enableOverflow" }}
        />
      )}

      <div className="brand-labels-row">
        {isBrand ? (
          <RowOfBrands
            names={names}
            arrayOfDesiredBrand={arrayOfDesiredBrand}
            setArrayOfDesiredBrand={setArrayOfDesiredBrand}
          />
        ) : null}
      </div>
    </div>
  );
};

export default PromotionBreakDownChart;
