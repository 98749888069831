import { success, error } from "redux-saga-requests";
import { FETCH_KEY_CONTENT, SET_KEY_CONTENT_FILTER, SET_PRISTINE_KEY_CONTENT } from "./actions";
import { STATE_STATUSES } from "utils/statuses";
import { RESET_ERROR } from "store/error/actions";

const defaultFilter = [
  {
    name: "productTitles",
    value: "title",
  },
  { name: "manufacture", value: null },
];

const initialState = {
  keyContent: {
    total: 0,
    products: [],
    retailers: [],
    success: false,
  },
  keyFilter: localStorage.getItem(SET_KEY_CONTENT_FILTER)
    ? JSON.parse(localStorage.getItem(SET_KEY_CONTENT_FILTER))
    : defaultFilter,
  status: STATE_STATUSES.INIT,
  isPristine: false,
  errorL: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_KEY_CONTENT: {
      return processReducer(state);
    }

    case success(FETCH_KEY_CONTENT): {
      return {
        ...state,
        keyContent: action.data,
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }

    case error(FETCH_KEY_CONTENT): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case SET_KEY_CONTENT_FILTER: {
      return {
        ...state,
        keyFilter: action.payload,
      };
    }

    case SET_PRISTINE_KEY_CONTENT: {
      return {
        ...state,
        isPristine: action.payload,
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
