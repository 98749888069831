import React, { useState, useEffect } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HighchartsExporting from "highcharts/modules/exporting";
import HC_rounded from "highcharts-rounded-corners";
import moment from "moment";

HC_rounded(Highcharts);
HighchartsExporting(Highcharts);

const CurrentlyTrendChartColumn = ({ data, colorChart, schedule }) => {
  const [chartData, setChartData] = useState([]);
  const [dates, setDates] = useState([]);
  const [maxPercent, setMaxPercent] = useState(0);
  const [maxItems, setMaxItems] = useState(0);
  const [isScroll, setIsScroll] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);

  useEffect(() => {
    if (data && data[schedule]?.length) {
      const chartData = data[schedule].map((item) => ({
        name: "term",
        color: colorChart,
        y: Number(item.percent),
      }));

      const percents = data[schedule].map((item) => item.percent);
      const maxPercent = Math.max(...percents);

      let dates;

      if (schedule === "weekly") {
        dates = data[schedule].map(
          (item) => `${moment(item.date).format("DD MMM YYYY")} - ${moment(item.endDate).format("DD MMM YYYY")}`
        );
      } else {
        dates = data[schedule].map((item) => moment(item.date).format("MMM YYYY"));
      }

      let maxItems = dates.length - 1 < 12 ? dates.length - 1 : 12;
      let isScroll = dates.length - 1 <= 12 ? false : true;

      setIsEmpty(false);
      setChartData(chartData);
      setMaxPercent(maxPercent);
      setDates(dates);
      setMaxItems(maxItems);
      setIsScroll(isScroll);
    } else {
      setIsEmpty(true);
    }
  }, [data, schedule]);

  const options = {
    title: "",
    chart: {
      type: "column",
      reflow: true,
      height: 280,
      style: {
        fontFamily: "Gilroy-Medium",
      },
    },
    plotOptions: {
      series: {
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    yAxis: {
      title: {
        // offset: 15,
        // x: 25,
        text: "",
      },
      // offset: 30,
      max: maxPercent,
      // labels: {
      //   formatter: function () {
      //     return `${this.value}%`;
      //   },
      // },
      minTickInterval: 0.1,
      labels: {
        format: `{value:.2f}%`,
        style: {
          fontSize: "10px",
        },
      },
    },
    xAxis: {
      min: 0,
      max: maxItems,
      scrollbar: {
        enabled: isScroll,
      },
      tickLength: 0,
      categories: dates,
      labels: {
        style: {
          fontSize: "10px",
        },
      },
    },
    tooltip: {
      shared: true,
      backgroundColor: null,
      borderWidth: 0,
      useHTML: true,
      formatter: function () {
        return `<div class="wrapper-total">
                        <div class='wrapper'>
                              <div class='box'>
                                  <div class='retailer-title'>Search Trend</div>
                                  <div style="margin-bottom: 5px">${this.points[0].x}</div>
                                  <div>${this.points[0].y}%</div>
                              </div>
                        </div>
                      </div>`;
      },
    },
    credits: {
      enabled: false,
    },
    exporting: { enabled: false },
    legend: {
      enabled: false,
    },
    series: [{ borderRadiusTopLeft: "10%", borderRadiusTopRight: "10%", data: chartData }],
  };

  return (
    <div>
      {isEmpty ? (
        <div className="chart-no-data static">Looks like we don't have data for this request</div>
      ) : (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}
    </div>
  );
};

export default CurrentlyTrendChartColumn;
