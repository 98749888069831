const url = process.env.REACT_APP_URL;

export const FETCH_SETTINGS_YOUR_PRODUCTS = "FETCH_SETTINGS_YOUR_PRODUCTS";
export const fetchSettingsYourProducts = (params) => ({
  type: FETCH_SETTINGS_YOUR_PRODUCTS,
  request: {
    url: `${url}/auth/products`,
    method: "GET",
    params,
  },
});

export const SET_ORDER_SETTINGS_RETAILERS = "SET_ORDER_SETTINGS_RETAILERS";
export const setOrderSettingsRetailers = (retailers) => ({
  type: SET_ORDER_SETTINGS_RETAILERS,
  request: {
    url: `${url}/auth/retailers`,
    method: "POST",
    data: { retailers },
  },
  meta: {
    asPromise: true,
  },
});

export const SET_PRISTINE_SETTINGS = "SET_PRISTINE_SETTINGS ";
export const setPristineSettings = (pristine) => {
  return {
    type: SET_PRISTINE_SETTINGS,
    payload: pristine,
  };
};
