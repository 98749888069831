import { success, error } from "redux-saga-requests";
import {
  FETCH_SETTINGS_MY_GROUPS,
  DELETE_SETTINGS_MY_GROUP,
  CREATE_SETTINGS_MY_GROUP,
  EDIT_SETTINGS_MY_GROUP,
} from "./actions";
import { STATE_STATUSES } from "utils/statuses";
import { RESET_ERROR } from "store/error/actions";

const initialState = {
  settingsProductGroups: [],
  status: STATE_STATUSES.INIT,
  isPristine: true,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SETTINGS_MY_GROUPS:
    case CREATE_SETTINGS_MY_GROUP:
    case DELETE_SETTINGS_MY_GROUP:
    case EDIT_SETTINGS_MY_GROUP: {
      return processReducer(state);
    }
    case success(FETCH_SETTINGS_MY_GROUPS): {
      return {
        ...state,
        settingsProductGroups: action.data.groups,
        isPristine: false,
        status: STATE_STATUSES.READY,
      };
    }
    case success(CREATE_SETTINGS_MY_GROUP): {
      return {
        ...state,
        settingsProductGroups: [...state.settingsProductGroups, action.data.group],
        isPristine: false,
        status: STATE_STATUSES.READY,
      };
    }
    case success(DELETE_SETTINGS_MY_GROUP): {
      const groups = state.settingsProductGroups.filter((item) => item.id !== action.meta.id);

      return {
        ...state,
        settingsProductGroups: groups,
        isPristine: false,
        status: STATE_STATUSES.READY,
      };
    }
    case success(EDIT_SETTINGS_MY_GROUP): {
      const productGroup = action.data.group;
      const productGroups = state.settingsProductGroups.filter((item) => item.id !== productGroup.id);
      return {
        ...state,
        settingsProductGroups: [productGroup, ...productGroups],
        isPristine: false,
        status: STATE_STATUSES.READY,
      };
    }
    case error(FETCH_SETTINGS_MY_GROUPS):
    case error(CREATE_SETTINGS_MY_GROUP):
    case error(DELETE_SETTINGS_MY_GROUP):
    case error(EDIT_SETTINGS_MY_GROUP): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
