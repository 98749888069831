import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//Components
import { Styles } from "./styles";
import AssortmentSummaryRetailers from "components/CalendarItems/AssortmetnSummaryCalendar/AssortmentSummaryRetailers";
import LoaderBox from "components/LoaderBox";
import AssortmentCalendar from "../AssortmentCalendar";
import useFilters from "../../../../../../../hooks/useFilters";

//Actions
import { fetchAssortmentSummaryCalendar } from "store/assortment/assortmentSummaryCalendar/actions";

//Utils
import { STATE_STATUSES } from "utils/statuses";
import { getTimePeriod } from "utils/getTimePeriod";
import { formatDateToTimeStamp } from "utils/calendarPromotionsHelper";
import { firstCharToUpperCase } from "utils/generalUtils";

const AssortmentSummaryCalendar = ({
  selectedEntity,
  clickedArray,
  setClickedArray,
  setIsRedraw,
  isRedraw,
  arrayOfDesiredBrand,
}) => {
  //Selectors
  const { status, assortmentSummaryCalendar, controlData } = useSelector((state) => state.assortmentSummaryCalendar);
  const { filter: topFilter } = useSelector((state) => state.assortmentSummaryTop);
  const { mainLastFilter: lastFilter, statusFilters } = useFilters();
  //States
  const [queryParams, setQueryParams] = useState({});
  const [emptyData, setEmptyData] = useState(false);
  const [maxMinYValue, setMaxMinYValue] = useState({ min: 0, max: 100 });
  const [maxMinSliderValue, setMaxMinSliderValue] = useState({
    min: 0,
    max: 100,
  });
  const [sliderValue, onSliderChange] = useState([0, 100]);
  const [search, setSearch] = useState("");
  const [sortDirection, setSortDirection] = useState(true);

  //Const
  const dispatch = useDispatch();

  //set queryParams
  useEffect(() => {
    setQueryParams((prevState) => ({
      ...prevState,
      sourceType: String(selectedEntity.item.id),
      timePeriod: getTimePeriod(lastFilter.date),
      product: lastFilter.product,
      search,
      groupBy: topFilter[2].value,
      sortDirection: "asc",
    }));
    setClickedArray([]);
  }, [lastFilter, search, topFilter[2].value, selectedEntity]);

  // dispatch based on query
  useEffect(() => {
    if (Object.keys(queryParams).length && statusFilters === STATE_STATUSES.READY) {
      dispatch(fetchAssortmentSummaryCalendar(queryParams));
    }
  }, [queryParams, statusFilters, dispatch]);
  //if data empty show no data banner
  useEffect(() => {
    if (!assortmentSummaryCalendar.result.length) {
      setEmptyData(true);
      setIsRedraw(true);
    } else {
      setEmptyData(false);
    }
  }, [assortmentSummaryCalendar]);

  //on click + btn
  const onOpen = (id, children, color, element) => {
    const retailer = { id: element.item.id, label: element.item.name };

    // setIsRedraw(false);
    const mapedChildren = children.map(({ item: { title, id: childId, color: childColor }, period }) => {
      return {
        item: { id: childId, name: title, color: childColor ? childColor : color },
        days: period,
      };
    });

    let arr = [];
    if (isIncluded(id)) {
      const matchedEl = clickedArray.find((item) => item.id === id);

      const filteredElements = matchedEl.children.map((el) => clickedArray.filter((item) => item.id === el.id)).flat();

      if (filteredElements.length) {
        const clickedArrIncludesChildrens = filteredElements
          .map(({ children }) => clickedArray.filter((el) => children.some(({ id }) => id === el.id)))
          .flat();
        const resultArr = clickedArray.filter(
          (el) => !filteredElements.some((item) => item.id === el.id) && el.id !== id
        );
        if (clickedArrIncludesChildrens.length) {
          arr = resultArr.filter(
            (el) => !clickedArrIncludesChildrens.some((item) => item.id === el.id) && el.id !== id
          );
        } else {
          arr = resultArr;
        }
      } else {
        arr = clickedArray.filter((item) => item.id !== id);
      }
    } else {
      arr = [...clickedArray, { id, children: mapedChildren, retailer }];
    }

    setClickedArray(arr);
  };

  const isIncluded = (id) => {
    if (clickedArray.some((el) => el.id === id)) {
      return true;
    }
  };

  const handleSort = () => {
    setSortDirection(!sortDirection);

    const sort = !sortDirection ? "asc" : "desc";
    //TODO change sort direction
    setQueryParams((prevState) => ({
      ...prevState,
      sortDirection: sort,
    }));
  };

  const sliderValueHandler = (values) => {
    setIsRedraw(false);
    setTimeout(
      () =>
        setMaxMinYValue({
          max: values[1],
          min: values[0],
        }),
      500
    );
  };
  //Set min max values when loading is done
  useEffect(() => {
    if (status === STATE_STATUSES.READY && assortmentSummaryCalendar.days.length) {
      const periods = assortmentSummaryCalendar.days;

      if (periods.length) {
        const min = formatDateToTimeStamp(assortmentSummaryCalendar.days[0]);
        const max = formatDateToTimeStamp(assortmentSummaryCalendar.days[periods.length - 1]);
        setMaxMinYValue({ min, max });
        setMaxMinSliderValue({ min, max });
        onSliderChange([min, max]);
      }
    }
  }, [status]);

  const renderRetailers = () => {
    return (
      <AssortmentSummaryRetailers
        data={assortmentSummaryCalendar.result}
        clickedArray={clickedArray}
        onOpen={onOpen}
        handleSearch={setSearch}
        search={search}
        handleSort={handleSort}
        sortDirection={sortDirection}
        isEmpty={emptyData}
        setEmptyData={setEmptyData}
        arrayOfDesiredBrand={arrayOfDesiredBrand}
      />
    );
  };

  const renderChart = () => {
    return (
      <AssortmentCalendar
        data={assortmentSummaryCalendar}
        controlData={controlData}
        clickedArray={clickedArray}
        setClickedArray={setClickedArray}
        maxMinYValue={maxMinYValue}
        maxMinSliderValue={maxMinSliderValue}
        sliderValue={sliderValue}
        onSliderChange={onSliderChange}
        setIsRedraw={setIsRedraw}
        desiredArr={arrayOfDesiredBrand}
        isEmpty={emptyData}
        sliderValueHandler={sliderValueHandler}
      />
    );
  };

  return (
    <Styles>
      <>
        {assortmentSummaryCalendar.success ? (
          <div className="calendar-title-desc">
            <span>
              You are currently viewing <span className="bold">{firstCharToUpperCase(selectedEntity.item.name)}.</span>{" "}
              There are {selectedEntity.total.group.count} brands and {selectedEntity.total.products.count} products.
            </span>
          </div>
        ) : null}
        {assortmentSummaryCalendar.success ? (
          <div className={"calendar-wrapper"}>
            {renderRetailers()}
            {renderChart()}
          </div>
        ) : null}
        {status === STATE_STATUSES.PENDING ? (
          // || !isRedraw
          <LoaderBox />
        ) : null}
      </>
    </Styles>
  );
};

export default AssortmentSummaryCalendar;
