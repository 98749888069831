const url = process.env.REACT_APP_URL;
//TODO Change url to share/chart
export const FETCH_ASSORTMENT_CHANGE = "FETCH_ASSORTMENT_CHANGE";
export const fetchAssortmentChange = (params) => ({
  type: FETCH_ASSORTMENT_CHANGE,
  request: {
    url: `${url}/v2/assortment/change`,
    method: "POST",
    data: params,
  },
});

export const FETCH_ASSORTMENT_CHANGE_COMPARE = "FETCH_ASSORTMENT_CHANGE_COMPARE";
export const fetchAssortmentChangeCompare = (params) => ({
  type: FETCH_ASSORTMENT_CHANGE_COMPARE,
  request: {
    url: `${url}/v2/assortment/change`,
    method: "POST",
    data: params,
  },
});

export const SET_PRISTINE_ASSORTMENT_CHANGE = "SET_PRISTINE_ASSORTMENT_CHANGE";
export const setPristineAssortmentChange = (pristine) => {
  return {
    type: SET_PRISTINE_ASSORTMENT_CHANGE,
    payload: pristine,
  };
};

export const SET_ASSORTMENT_CHANGE_FILTER = "SET_ASSORTMENT_CHANGE_FILTER";
export const setAssortmentChangeFilter = (filter) => {
  localStorage.setItem(SET_ASSORTMENT_CHANGE_FILTER, JSON.stringify(filter));
  return {
    type: SET_ASSORTMENT_CHANGE_FILTER,
    payload: filter,
  };
};

export const RESET_ASSORTMENT_CHANGE_COMPARE = "RESET_ASSORTMENT_CHANGE_COMPARE";
export const resetAssortmentChangeCompare = (row) => {
  return {
    type: RESET_ASSORTMENT_CHANGE_COMPARE,
    payload: row,
  };
};
