const url = process.env.REACT_APP_URL;

export const FETCH_KEY_CONTENT = "FETCH_KEY_CONTENT";
export const fetchKeyContent = (params) => ({
  type: FETCH_KEY_CONTENT,
  request: {
    url: `${url}/v2/content/key_content`,
    method: "POST",
    data: params,
  },
});

export const SET_KEY_CONTENT_FILTER = "SET_KEY_CONTENT_FILTER";
export const setKeyContentFilter = (filter) => {
  localStorage.setItem(SET_KEY_CONTENT_FILTER, JSON.stringify(filter));
  return {
    type: SET_KEY_CONTENT_FILTER,
    payload: filter,
  };
};

export const SET_PRISTINE_KEY_CONTENT = "SET_PRISTINE_KEY_CONTENT";
export const setPristineKeyContent = (pristine) => {
  return {
    type: SET_PRISTINE_KEY_CONTENT,
    payload: pristine,
  };
};
