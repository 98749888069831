const url = process.env.REACT_APP_URL;

//Price Range
export const FETCH_PRICE_RANGE = "FETCH_PRICE_RANGE";
export const fetchPriceRange = (data) => ({
  type: FETCH_PRICE_RANGE,
  request: {
    url: `${url}/v2/price/range`,
    method: "POST",
    data,
  },
});

export const FETCH_COMPARE_PRICE_RANGE = "FETCH_COMPARE_PRICE_RANGE";
export const fetchComparePriceRange = (data) => ({
  type: FETCH_COMPARE_PRICE_RANGE,
  request: {
    url: `${url}/v2/price/range`,
    method: "POST",
    data,
  },
  meta: {
    asPromise: true,
  },
});

export const SET_PRISTINE_RANGE = "SET_PRISTINE_RANGE";
export const setPristineRange = (pristine) => {
  return {
    type: SET_PRISTINE_RANGE,
    payload: pristine,
  };
};
