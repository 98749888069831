import styled from "styled-components";

export const Styles = styled.div.attrs((props) => props)`
  .popup-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 100;
    .popup-box {
      max-width: 1240px;
      height: calc(100vh - 80px);
      overflow: hidden;
      width: 100%;
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 8px 20px 0 rgba(30, 31, 32, 0.18),
        0 2px 4px 0 rgba(0, 0, 0, 0.17);
      padding: 40px;
      position: relative;

      .cancel {
        position: absolute;
        width: 24px;
        height: 24px;
        right: 40px;
        top: 40px;
        cursor: pointer;
      }
    }
  }
  .header {
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 26px;
    & > span {
      font-size: 22px;
      font-family: Gilroy-ExtraBold;
      margin-bottom: 26px;
    }
  }
  .retailer-website {
    display: flex;
    align-items: center;
    cursor: pointer;
    & > img {
      width: 24px;
      margin-right: 15px;
    }
    & > a {
      font-size: 16px;
      color: ${(props) => props.color};
      font-family: Gilroy-ExtraBold;
    }
  }
  .table {
    height: 650px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #abaaba;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #807f97;
    }
  }
`;
