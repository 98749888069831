import React, { useEffect, useState } from "react";

import MediaSummaryRetailersItem from "./MediaSummaryRetailersItem";

import searchIcon from "assets/images/search.svg";
import sort from "assets/images/sort.svg";
import sortIconDown from "assets/images/sort_down.svg";
import sortIconUp from "assets/images/sort_up.svg";
import { Empty } from "antd";

const MediaSummaryRetailers = ({
  data,
  isBrand,
  clickedArray,
  onOpen,
  handleSearch,
  search,
  handleSort,
  sortDirection,
  sortId,
  filter,
  desiredArr,
  isEmpty,
  setEmptyData,
}) => {
  const [filteredData, setFilteredData] = useState([]);
  //filter data by clicked names and by bottom filter

  useEffect(() => {
    const isShowAll = filter[0].value;

    let filteredData = [];
    if (desiredArr.length) {
      filteredData = data.filter(({ label }) => desiredArr.some((el) => el === label));
    } else {
      filteredData = data;
    }

    if (filteredData.length) {
      let retailers = [];
      for (let index = 0; index < filteredData.length; index++) {
        const element = filteredData[index];

        let brands = [];
        for (let index = 0; index < element.children.length; index++) {
          const el = element.children[index];

          let children = [];
          for (let index = 0; index < el.children.length; index++) {
            const child = el.children[index];

            if (filter[1].value === child.label || filter[1].value === "All") {
              children.push(child);
            }
          }
          if (children.length) {
            brands.push({ ...el, children });
          }
        }

        if (brands.length) {
          retailers.push({ ...element, children: brands });
        } else if (!brands.length && isShowAll) {
          retailers.push({ ...element });
        }
      }

      if (retailers.length) {
        setFilteredData(retailers);
        setEmptyData(false);
      } else {
        setFilteredData([]);
      }
    } else {
      setFilteredData([]);
    }
  }, [data, filter, desiredArr]);

  useEffect(() => {
    if (filteredData.length) {
      setEmptyData(false);
    } else {
      setEmptyData(true);
    }
  }, [filteredData]);

  return (
    <div className="top-retailers">
      <div className="collapse-all">
        <div className="search">
          <img src={searchIcon} alt="search" />
          <input onChange={(e) => handleSearch(e.target.value)} placeholder="Search for a Search Term and a Location" value={search} />

          <img
            src={sortId === "title" ? (sortDirection ? sortIconUp : sortIconDown) : sort}
            alt="sort"
            className="search-sort-btn"
            onClick={() => handleSort("title")}
          />
        </div>
      </div>
      {isEmpty ? (
        <div className="empty-wrapper" style={{ marginTop: 21, marginRight: 10 }}>
          <div className="no-data-box">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        </div>
      ) : (
        <div className="retailers-media">
          {filteredData.map((el, index) => {
            return (
              <div key={index}>
                <MediaSummaryRetailersItem data={el} index={index} clickedArray={clickedArray} onOpen={onOpen} isBrand={isBrand} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MediaSummaryRetailers;
