import React from "react";

import amazon from "assets/images/company-logo/amazon.svg";
import asda from "assets/images/company-logo/asda.svg";
import morrisons from "assets/images/company-logo/morrisons.svg";
import sainsburys from "assets/images/company-logo/sainsburys.svg";
import tesco from "assets/images/company-logo/tesco.svg";
import waitrose from "assets/images/company-logo/waitrose.svg";
import iceland from "assets/images/company-logo/iceland.svg";
import ocado from "assets/images/company-logo/ocado.svg";
import amazon_fresh from "assets/images/company-logo/amazon_fresh.svg";
import coop from "assets/images/company-logo/coop.svg";
import bm from "assets/images/company-logo/b&m.svg";
import home_bargains from "assets/images/company-logo/home_bargains.svg";
import aldi from "assets/images/company-logo/aldi.svg";
import boots from "assets/images/company-logo/boots.svg";
import superdrug from "assets/images/company-logo/superdrug.svg";
import currys from "assets/images/company-logo/currys.svg";
import very from "assets/images/company-logo/very.svg";
import ao from "assets/images/company-logo/ao.svg";
import argos from "assets/images/company-logo/argos.svg";

import placeholder from "assets/images/placeholder.svg";
import searchOpacity from "assets/images/search-opacity.svg";
import burger from "assets/images/burger.svg";

export const Icons = {
  amazon,
  asda,
  morrisons,
  sainsburys,
  tesco,
  waitrose,
  iceland,
  ocado,
  amazon_fresh,
  placeholder,
  searchOpacity,
  burger,
  coop,
  "b&m": bm,
  home_bargains,
  aldi,
  boots,
  superdrug,
  currys,
  very,
  ao,
  argos,
};

export const Icon = ({ icon, onClick, width, heigth, style }) => {
  return (
    <div onClick={onClick} style={{ display: "flex", height: heigth }}>
      <img src={icon} alt="" style={{ heigth, width, ...style }} />
    </div>
  );
};

export default Icon;
