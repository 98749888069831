import { success, error } from "redux-saga-requests";
import {
  FETCH_MEDIA_SUMMARY_BOTTOM,
  SET_MEDIA_SUMMARY_BOTTOM_FILTER,
  SET_PRISTINE_MEDIA_SUMMARY_BOTTOM,
  ADD_TO_DATA_MEDIA,
  FETCH_MEDIA_SUMMARY_BANNER,
  DELETE_MEDIA_SUMMARY_BANNER,
} from "./actions";
import { STATE_STATUSES } from "utils/statuses";
import { RESET_ERROR } from "store/error/actions";

const defaultFilter = [
  {
    name: "showAll",
    value: false,
  },
  {
    name: "banners",
    options: [
      { lable: "All Banners", value: "All" },
      { lable: "Search Terms", value: "Search Terms" },
      { lable: "Locations", value: "Locations" },
    ],
    value: "All",
  },
];

const initialState = {
  mediaSummaryBottom: {
    success: false,
    days: [],
    result: [],
  },
  controlData: {
    success: false,
    days: [],
    result: [],
  },
  mediaBanner: [],
  filter: localStorage.getItem(SET_MEDIA_SUMMARY_BOTTOM_FILTER)
    ? JSON.parse(localStorage.getItem(SET_MEDIA_SUMMARY_BOTTOM_FILTER))
    : defaultFilter,
  status: STATE_STATUSES.INIT,
  isPristine: true,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MEDIA_SUMMARY_BOTTOM:
    case FETCH_MEDIA_SUMMARY_BANNER: {
      return processReducer(state);
    }

    case success(FETCH_MEDIA_SUMMARY_BOTTOM): {
      return {
        ...state,
        mediaSummaryBottom: { ...action.data, success: true },
        controlData: { ...action.data, success: true },
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }
    case success(FETCH_MEDIA_SUMMARY_BANNER): {
      return {
        ...state,
        mediaBanner: [...state.mediaBanner, action.data],
        status: STATE_STATUSES.READY,
      };
    }
    case error(FETCH_MEDIA_SUMMARY_BOTTOM):
    case error(FETCH_MEDIA_SUMMARY_BANNER): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case SET_PRISTINE_MEDIA_SUMMARY_BOTTOM: {
      return {
        ...state,
        isPristine: action.payload,
      };
    }

    case SET_MEDIA_SUMMARY_BOTTOM_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }

    case DELETE_MEDIA_SUMMARY_BANNER: {
      return {
        ...state,
        mediaBanner: [],
      };
    }

    case ADD_TO_DATA_MEDIA: {
      return {
        ...state,
        mediaSummaryBottom: {
          ...state.mediaSummaryBottom,
          ...action.payload,
        },
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
