import styled from "styled-components";

export const Styles = styled.div`
  .chartOpportunites {
    height: 70px;
    margin-top: 12px;
    overflow: auto !important;

    display: flex;
    flex-wrap: wrap;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #abaaba;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #807f97;
    }
  }
`;
