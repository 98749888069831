import React from "react";
import { Tabs } from "antd";
//Components
import SummaryTab from "./SummaryTab";
import PromotionBreakdownTab from "./PromotionBreakdownTab";
import DiscountCutTab from "./DiscountCutTab";
import { TabWithIcon } from "assets/icons/icons";
//Icons
import about from "assets/images/about.svg";

const PromotionsTopTabs = ({ setActiveTabTop, activeTabTop }) => {
  const { TabPane } = Tabs;

  function callback(key) {
    setActiveTabTop(key);
  }

  return (
    <div className="main-box">
      <Tabs defaultActiveKey={activeTabTop} onChange={callback}>
        <TabPane
          tab={<TabWithIcon tabName={"Summary"} icon={about} tooltip={"Overall the prices moved up 20% based on the selected filters."} />}
          key="1"
        >
          <SummaryTab />
        </TabPane>
        <TabPane
          tab={
            <TabWithIcon
              tabName={"Promotion Breakdown"}
              icon={about}
              tooltip={"Overall the prices moved up 20% based on the selected filters."}
            />
          }
          key="2"
        >
          <PromotionBreakdownTab />
        </TabPane>
        <TabPane
          tab={
            <TabWithIcon tabName={"Discount Cut"} icon={about} tooltip={"Overall the prices moved up 20% based on the selected filters."} />
          }
          key="3"
        >
          <DiscountCutTab />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default PromotionsTopTabs;
