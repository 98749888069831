import React, { useEffect, useState } from "react";
import { Pagination } from "antd";
import { useDispatch, useSelector } from "react-redux";

//Actions
import { deleteMediaSummaryBanner, fetchMediaSummaryBanner } from "store/media/mediaSummaryBottom/actions";
import { fetchMediaDetails } from "store/media/mediaDetails/actions";

//Utils
import { getTimePeriod } from "utils/getTimePeriod";
import { STATE_STATUSES } from "utils/statuses";
//Components
import { Styles } from "./styles";
import MediaDetailsTable from "./components/MediaDetailsTable";
import LoaderBox from "components/LoaderBox";
import PopupMediaSummary from "components/Popups/PopupMediaSummary";
import PopupMultiContent from "components/Popups/PopupMultiContent";
import ShowPerPage from "components/ShowPerPage";
import useFilters from "../../../../hooks/useFilters";

const MediaDetailsTab = () => {
  //Selectors
  const { mediaSummaryDetails, status } = useSelector((state) => state.mediaSummaryDetails);
  const { status: mediaSummaryBottomStatus, mediaBanner } = useSelector((state) => state.mediaSummaryBottom);
  const { mainLastFilter: lastFilter, statusFilters } = useFilters();

  //States
  const [queryParams, setQueryParams] = useState({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortId, setSortId] = useState("duration");
  const [sortDirection, setSortDirection] = useState(true);

  const [isEmpty, setIsEmpty] = useState(false);
  const [isShowPopup, setIsShowPopup] = useState(false);

  //Const
  const dispatch = useDispatch();

  const baseQuery = {
    limit: 10,
    page: 1,
    sort: "duration|asc",
  };

  useEffect(() => {
    setQueryParams((prevState) => ({
      ...prevState,
      sourceType: lastFilter.sourceType,
      timePeriod: getTimePeriod(lastFilter.date),
      product: lastFilter.product,
      ...baseQuery,
    }));

    setLimit(10);
    setPage(1);
    setSortDirection(true);
    setSortId("duration");
  }, [lastFilter]);

  useEffect(() => {
    if (Object.keys(queryParams).length && statusFilters === STATE_STATUSES.READY) {
      dispatch(fetchMediaDetails(queryParams));
    }
  }, [queryParams, statusFilters, dispatch]);

  useEffect(() => {
    if (mediaSummaryDetails.rows.length) {
      setIsEmpty(false);
    } else {
      setIsEmpty(true);
    }
  }, [mediaSummaryDetails]);

  const changePage = (page, lim) => {
    setPage(page);

    setQueryParams((prevState) => ({
      ...prevState,
      page,
    }));
  };

  const changeLimitHandler = (limit) => {
    setLimit(limit);
    changePage(1);

    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      limit,
    }));
  };

  const handleSort = (id) => {
    setSortDirection(!sortDirection);

    changePage(1);

    setSortId(id);

    const sort = `${id}|${!sortDirection ? "asc" : "desc"}`;

    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      sort,
    }));
  };

  const fetchBanner = async (idsArr) => {
    const promises = idsArr.map((id) => {
      return dispatch(fetchMediaSummaryBanner(id));
    });

    Promise.all(promises)
      .then((el) => {
        setIsShowPopup(true);
      })
      .catch((e) => {
        setIsShowPopup(false);
      });
  };

  const renderTable = () => {
    return (
      <MediaDetailsTable
        currentData={mediaSummaryDetails}
        isEmpty={isEmpty}
        handleSort={handleSort}
        sortId={sortId}
        sortDirection={sortDirection}
        fetchBanner={fetchBanner}
      />
    );
  };

  return (
    <Styles>
      <div className="wrapper-box-relative">
        {mediaSummaryDetails.success ? (
          <>
            <div className="chart-wrapper-box">{renderTable()}</div>{" "}
            {isEmpty ? null : (
              <div className="pagination-with-per-page">
                <Pagination
                  className="pagination-controls"
                  onChange={changePage}
                  current={page}
                  pageSize={limit}
                  total={mediaSummaryDetails.total.bannerCount}
                  showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
                />
                <ShowPerPage setLimit={changeLimitHandler} value={limit} />
              </div>
            )}
          </>
        ) : null}

        {status === STATE_STATUSES.PENDING || mediaSummaryBottomStatus === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
        {isShowPopup && mediaSummaryBottomStatus === STATE_STATUSES.READY ? (
          <PopupMultiContent moreData={mediaBanner.length > 1} isLastEl={false} isFirstEl={true} nextPopup={() => {}} prevPopup={() => {}}>
            <PopupMediaSummary
              banner={mediaBanner[0]}
              closePopup={() => {
                dispatch(deleteMediaSummaryBanner());
                setIsShowPopup(false);
              }}
            />
          </PopupMultiContent>
        ) : null}
      </div>
    </Styles>
  );
};

export default MediaDetailsTab;
