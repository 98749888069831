import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "antd";

//Utils
import { getTimePeriod } from "utils/getTimePeriod";
import { STATE_STATUSES } from "utils/statuses";
import { formatToDateForDays } from "utils/calendarPromotionsHelper";

//Components
import { Styles } from "./styles";
import LoaderBox from "components/LoaderBox";
import ShareOfMediaAccordion from "./components/ShareOfMediaAccordion";
import ShowPerPage from "components/ShowPerPage";
import ShareOfMediaTable from "./components/ShareOfMediaTable";
import useFilters from "../../../../../hooks/useFilters";

//Actions
import { fetchShareOfMediaBottom } from "store/media/shareOfMediaBottom/actions";

const ShareOfMediaBottom = ({ selectedDate }) => {
  //Selectors
  const { shareOfMediaBottom, status } = useSelector((state) => state.shareOfMediaBottom);
  const { filter, shareOfMediaTop } = useSelector((state) => state.shareOfMediaTop);
  const { mainLastFilter: lastFilter, statusFilters } = useFilters();

  //States
  const [queryParams, setQueryParams] = useState({});
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [manufacturer, setManufacturer] = useState({ name: "", color: "" });
  const [isOpened, setIsOpened] = useState(false);

  const [sortId, setSortId] = useState("manufacturers");
  const [sortDirection, setSortDirection] = useState(true);

  //Const
  const dispatch = useDispatch();
  const baseQuery = {
    limit: 10,
    page: 1,
    sort: "manufacturers|asc",
  };

  useEffect(() => {
    setQueryParams((prevState) => ({
      ...prevState,
      sourceType: lastFilter.sourceType,
      timePeriod: getTimePeriod(lastFilter.date),
      product: lastFilter.product,
      date: selectedDate ? formatToDateForDays(selectedDate) : "All",
      ...baseQuery,
    }));
    setLimit(10);
    setPage(1);
    setSortDirection(true);
    setSortId("manufacturers");
  }, [lastFilter, selectedDate]);

  useEffect(() => {
    if (Object.keys(queryParams).length && filter[0].value && statusFilters === STATE_STATUSES.READY) {
      dispatch(fetchShareOfMediaBottom(filter[0].value, queryParams));
    }
  }, [queryParams, filter[0].value, statusFilters, dispatch]);

  useEffect(() => {
    if (shareOfMediaTop.manufacturers.length && filter[0].value) {
      const selectedManufacture = shareOfMediaTop.manufacturers.find(({ id }) => id === filter[0].value) || { name: "", color: "" };
      setManufacturer({
        name: selectedManufacture.name,
        color: selectedManufacture.color,
      });
    }
  }, [filter[0].value, shareOfMediaTop]);

  useEffect(() => {
    setIsOpened(false);
  }, [filter, lastFilter, selectedDate]);

  const changePage = (page, lim) => {
    setPage(page);

    setQueryParams((prevState) => ({
      ...prevState,
      page,
    }));
  };

  const handleSort = (id) => {
    setSortDirection(!sortDirection);

    setSortId(id);

    const sort = `${id}|${!sortDirection ? "asc" : "desc"}`;

    changePage(1);

    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      sort,
    }));
  };

  const changeLimitHandler = (limit) => {
    setLimit(limit);
    changePage(1);

    setQueryParams((prevState) => ({
      ...prevState,
      page: 1,
      limit,
    }));
  };

  const renderChart = () => {
    return (
      <div className="accordion-wrapper">
        <ShareOfMediaAccordion data={shareOfMediaBottom} manufacturer={manufacturer} isOpened={isOpened} setIsOpened={setIsOpened} />
        {isOpened ? (
          <ShareOfMediaTable handleSort={handleSort} rows={shareOfMediaBottom.rows} sortId={sortId} sortDirection={sortDirection} />
        ) : null}
      </div>
    );
  };

  return (
    <>
      {filter[0].value ? (
        <Styles>
          <div className="wrapper-box-relative">
            {shareOfMediaBottom.success ? <div className="chart-wrapper-box">{renderChart()}</div> : null}
            {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
          </div>
          {isOpened && shareOfMediaBottom.rows.length ? (
            <div className="pagination-with-per-page">
              <Pagination
                className="pagination-controls"
                onChange={changePage}
                current={page}
                pageSize={limit}
                total={shareOfMediaBottom.total.locations}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
              />
              <ShowPerPage setLimit={changeLimitHandler} value={limit} />
            </div>
          ) : null}
        </Styles>
      ) : null}
    </>
  );
};

export default ShareOfMediaBottom;
