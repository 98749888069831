import { success, error } from "redux-saga-requests";
import {
  FETCH_SETTINGS_YOUR_PROFILE,
  EDIT_SETTINGS_YOUR_PROFILE,
  SET_SETTINGS_AVATAR,
  SET_PRISTINE_SETTINGS_PROFILE,
  SET_THEME_COLOR,
  AUTH_ME,
  SET_IS_COLOR_THEME_FADE_IN,
  SET_CURRENT_COUNTRY,
  SET_SETTINGS_YOUR_PROFILE,
} from "./actions";
import { STATE_STATUSES } from "utils/statuses";

const initialState = {
  success: false,
  settingsYourProfile: {
    avatar: "",
    email: "",
    jobTitle: "",
    first_name: "",
    phone: "",
    last_name: "",
    company: { sections: [] },
    colorTheme: "",
  },
  status: STATE_STATUSES.INIT,
  isPristine: true,
  error: null,
  isColorThemeFadeIn: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SETTINGS_YOUR_PROFILE:
    case EDIT_SETTINGS_YOUR_PROFILE:
    case SET_SETTINGS_AVATAR: {
      return processReducer(state);
    }
    case success(FETCH_SETTINGS_YOUR_PROFILE): {
      return {
        ...state,
        settingsYourProfile: action.data,
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }
    case SET_SETTINGS_YOUR_PROFILE: {
      return {
        ...state,
        settingsYourProfile: action.payload,
        status: STATE_STATUSES.READY,
      };
    }
    case success(EDIT_SETTINGS_YOUR_PROFILE): {
      localStorage.setItem("colorTheme", JSON.stringify(action.data.user?.colorTheme));
      return {
        ...state,
        settingsYourProfile: action.data.user,
        status: STATE_STATUSES.READY,
        isPristine: false,
        success: true,
      };
    }
    case success(AUTH_ME): {
      const {
        colorTheme,
      } = action.data;
      return {
        ...state,
        settingsYourProfile: {
          ...state.settingsYourProfile,
          colorTheme,
        },
      };
    }
    case success(SET_SETTINGS_AVATAR): {
      return {
        ...state,
        settingsYourProfile: action.data.user,
        status: STATE_STATUSES.READY,
      };
    }
    case error(FETCH_SETTINGS_YOUR_PROFILE):
    case error(EDIT_SETTINGS_YOUR_PROFILE):
    case error(SET_SETTINGS_AVATAR): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case SET_PRISTINE_SETTINGS_PROFILE: {
      return {
        ...state,
        isPristine: action.payload,
      };
    }

    case SET_THEME_COLOR: {
      return {
        ...state,
        settingsYourProfile: {
          ...state.settingsYourProfile,
          colorTheme: { 
            ...state.settingsYourProfile.colorTheme,
            primary: action.payload,
          },
        }
      }
    }
    
    case SET_IS_COLOR_THEME_FADE_IN: {
      return {
        ...state,
        isColorThemeFadeIn: action.payload,
      };
    }

    case SET_CURRENT_COUNTRY: {
      return {
        ...state,
        settingsYourProfile: {
          ...state.settingsYourProfile,
          country: action.payload,
          countryId: action.payload.id,
        }
      }
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  success: false,
  error: null,
});
