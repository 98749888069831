import React, { useState } from "react";
//utils
import { getImage } from "utils/getImage";
import useRetailers from "../../../../../hooks/useRetailers";
import { getDateDiffInDays } from "utils/ratingHelper";
//Icons
import sort from "assets/images/sort.svg";
import sortIconDown from "assets/images/sort_down.svg";
import sortIconUp from "assets/images/sort_up.svg";
//Components
import RenderNoData from "components/RenderNoData";
import { Popover } from "antd";
import { firstCharToUpperCase } from "utils/generalUtils";

export const specifications = [
  { value: "Retailer", key: "retailer" },
  { value: "Location", key: "location" },
  { value: "Thumbnail", key: "thumbnail" },
  { value: "Last Seen", key: "lastSeen" },
  { value: "Duration", key: "duration" },
  { value: "No. of Products", key: "productCount" },
];

const MediaDetailsTable = ({ currentData, isEmpty, handleSort, sortId, sortDirection, fetchBanner }) => {
  const [popupData, setPopupData] = useState({});
  const { getRemoteLogo } = useRetailers();

  const { rows } = currentData;

  const popupContent = () => {
    return (
      <div className="popup-wrapper">
        <div className="thumbnail-wrapper">
          {popupData?.image?.length ? <img src={popupData.image} alt="" /> : <div className="no-iamge">No Image</div>}
        </div>
        {popupData?.image?.length ? (
          <span className="show-details" onClick={() => fetchBanner([popupData?.id])}>
            Show Banner
          </span>
        ) : null}
      </div>
    );
  };

  const renderCellItem = (el) => {
    return specifications.map(({ key }, index) => {
      return (
        <div key={index} className="table-item">
          <>
            {key === "retailer" ? (
              <img src={getRemoteLogo(el?.retailer?.name)} alt="" />
            ) : key === "location" ? (
              <div className="location">
                <div className="location-icon">
                  <img src={getImage(el.location.type !== "search" ? "burger" : "search")} alt="" />
                </div>
                <div className="location-name">{el.location.category}</div>
              </div>
            ) : key === "thumbnail" ? (
              <Popover
                content={popupContent}
                title={`"${el.location.category}" in ${firstCharToUpperCase(el.retailer.name)}`}
                trigger="hover"
                align={{ offset: [0, 25] }}
              >
                <div className="thumbnail-wrapper" onMouseOver={() => setPopupData({ image: el.image, id: el.id })}>
                  {el.image?.length ? <img src={el.image} alt="" /> : <div className="no-iamge">No Image</div>}
                </div>
              </Popover>
            ) : key === "lastSeen" ? (
              <span>
                {getDateDiffInDays(el[key]) === 0
                  ? "Today"
                  : `${getDateDiffInDays(el[key])} day${getDateDiffInDays(el[key]) > 1 ? "s" : ""} ago`}
              </span>
            ) : key === "duration" ? (
              <span>{`${el[key]} day${el[key] > 1 ? "s" : ""}`}</span>
            ) : (
              <span>{el[key]}</span>
            )}
          </>
        </div>
      );
    });
  };

  return (
    <div className="chart-price-wrapper" style={{ width: "100%" }}>
      <div className="chart-title-desc">
        A total of {currentData.total.bannerCount} banners have been detected. Average time banners live{" "}
        <span style={{ fontWeight: 800, marginLeft: 4 }}>{currentData.total.avgLive} days.</span>
      </div>
      {isEmpty ? (
        <RenderNoData />
      ) : (
        <div className="current-table-box">
          <div className="wrapper-table-box">
            <div className="wrapper-table">
              <div className="box-wrapper">
                <div className="wrapper">
                  {specifications.map((item, index) => {
                    const hideSortBtns = item.key === "thumbnail";
                    return (
                      <div className="table-item sort" key={index} onClick={hideSortBtns ? () => {} : () => handleSort(item.key)}>
                        <span className="">{item.value}</span>

                        {hideSortBtns ? null : (
                          <img src={sortId === item.key ? (sortDirection ? sortIconUp : sortIconDown) : sort} alt="sort" />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="wrapper-table">
              <div className="wrapper-box-relative">
                {rows.length ? (
                  rows.map((el, index) => (
                    <div className="box-wrapper" key={index}>
                      <div className="wrapper">{renderCellItem(el)}</div>
                    </div>
                  ))
                ) : (
                  <RenderNoData />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MediaDetailsTable;
