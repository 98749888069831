import React from "react";

import "./styles.scss";

const NotFoundPage = () => {
  return (
    <div className="not-found-wrapper">
      <span>404</span>
      <h1>Page not found</h1>
    </div>
  );
};

export default NotFoundPage;
