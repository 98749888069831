const url = process.env.REACT_APP_URL;

//Ranking Search Analysis
export const FETCH_RANKING_SEARCH_ANALYSIS = "FETCH_RANKING_SEARCH_ANALYSIS";
export const fetchRankingSearchAnalysis = (data) => ({
  type: FETCH_RANKING_SEARCH_ANALYSIS,
  request: {
    url: `${url}/v2/ranking/search-analysis`,
    method: "POST",
    data,
  },
});

export const SET_CURRENT_ITEM_TERM_SEARCH = "SET_CURRENT_ITEM_TERM_SEARCH";
export const setCurrentItemTermSearch = (data) => {
  return {
    type: SET_CURRENT_ITEM_TERM_SEARCH,
    payload: data,
  };
};

export const SET_CURRENT_SHARE_SEARCH = "SET_CURRENT_SHARE_SEARCH";
export const setCurrentShareSearch = (retailer) => {
  return {
    type: SET_CURRENT_SHARE_SEARCH,
    payload: retailer,
  };
};

export const SET_CURRENT_SHARE_SEARCH_SINGLE_DAY = "SET_CURRENT_SHARE_SEARCH_SINGLE_DAY";
export const setCurrentShareSearchSingleDay = (retailer) => {
  return {
    type: SET_CURRENT_SHARE_SEARCH_SINGLE_DAY,
    payload: retailer,
  };
};

export const FETCH_RANKING_SEARCH_ANALYSIS_TREND = "FETCH_RANKING_SEARCH_ANALYSIS_TREND";
export const fetchRankingSearchAnalysisTrend = (data) => ({
  type: FETCH_RANKING_SEARCH_ANALYSIS_TREND,
  request: {
    url: `${url}/v2/ranking/search-analysis/charts`,
    method: "POST",
    data,
  },
});

export const FETCH_RANKING_SEARCH_ANALYSIS_PRODUCTS = "FETCH_RANKING_SEARCH_ANALYSIS_PRODUCTS";
export const fetchRankingSearchAnalysisProducts = (data) => ({
  type: FETCH_RANKING_SEARCH_ANALYSIS_PRODUCTS,
  request: {
    url: `${url}/v2/ranking/search-analysis/products`,
    method: "POST",
    data,
  },
});

export const FETCH_RANKING_SEARCH_ANALYSIS_PRODUCTS_POPUP = "FETCH_RANKING_SEARCH_ANALYSIS_PRODUCTS_POPUP";
export const fetchRankingSearchAnalysisProductsPopup = (productId, data) => ({
  type: FETCH_RANKING_SEARCH_ANALYSIS_PRODUCTS_POPUP,
  request: {
    url: `${url}/v2/ranking/search-analysis/products/${productId}`,
    method: "POST",
    data,
  },
});

export const SET_SHOW_PRODUCT_SEARCH_POPUP = "SET_SHOW_PRODUCT_SEARCH_POPUP";

export const SET_PRISTINE_ANALYSIS_SEARCH = "SET_PRISTINE_ANALYSIS_SEARCH";

export const FETCH_SEARCH_SINGLE_PRODUCT = "FETCH_SEARCH_SINGLE_PRODUCT";
export const fetchSearchSingleProduct = (data, isSingle) => ({
  type: FETCH_SEARCH_SINGLE_PRODUCT,
  request: {
    url: `${url}/v2/ranking/search-analysis/products`,
    method: "POST",
    data,
  },
  meta: {
    asPromise: true,
    isSingle,
  },
});

export const FETCH_SEARCH_SINGLE_PRODUCT_SUMMARY = "FETCH_SEARCH_SINGLE_PRODUCT_SUMMARY";
export const fetchSearchSingleProductSummary = (data) => ({
  type: FETCH_SEARCH_SINGLE_PRODUCT_SUMMARY,
  request: {
    url: `${url}/v2/ranking/search-analysis/products`,
    method: "POST",
    data,
  },
  meta: {
    takeLatest: false,
    asPromise: true,
  },
});

export const SET_SEARCH_SINGLE_PRODUCT_PERIOD = "SET_SEARCH_SINGLE_PRODUCT_PERIOD";
export const searchSingleProductSetPeriod = (data) => {
  return {
    type: SET_SEARCH_SINGLE_PRODUCT_PERIOD,
    payload: data,
  };
};

export const REMOVE_SEARCH_PRODUCT = "REMOVE_SEARCH_PRODUCT";

export const FETCH_SEARCH_SINGLE_PRODUCT_COMPARE = "FETCH_SEARCH_SINGLE_PRODUCT_COMPARE";
export const fetchSearchSingleProductCompare = (data) => ({
  type: FETCH_SEARCH_SINGLE_PRODUCT_COMPARE,
  request: {
    url: `${url}/v2/ranking/search-analysis/products`,
    method: "POST",
    data,
  },
  meta: {
    asPromise: true,
  },
});

export const FETCH_SEARCH_SINGLE_PRODUCT_COMPARE_SUMMARY = "FETCH_SEARCH_SINGLE_PRODUCT_COMPARE_SUMMARY";
export const fetchSearchSingleProductCompareSummary = (data) => ({
  type: FETCH_SEARCH_SINGLE_PRODUCT_COMPARE_SUMMARY,
  request: {
    url: `${url}/v2/ranking/search-analysis/products`,
    method: "POST",
    data,
  },
  meta: {
    takeLatest: false,
    asPromise: true,
  },
});

export const SET_SEARCH_SINGLE_PRODUCT_COMPARE = "SET_SEARCH_SINGLE_PRODUCT_COMPARE";
export const searchSingleProductSetCompare = (data) => {
  return {
    type: SET_SEARCH_SINGLE_PRODUCT_COMPARE,
    payload: data,
  };
};
