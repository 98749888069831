import { COLORS } from "assets/colors/colors";
import styled from "styled-components";

export const Styles = styled.div`
  margin-left: 12px;
  margin-top: 1px;

  width: calc(100% - 390px - 12px);
  .top-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .top-content-item {
      font-size: 12px;
      font-family: Gilroy-ExtraBold;
      color: ${COLORS.darkGray};
      display: flex;
      height: 64px;
      align-items: center;
      cursor: pointer;
      width: 270px;
    }
  }
  .chart-content {
    display: flex;
    flex-direction: column;

    & > :first-child {
      border-top: 1px solid ${COLORS.lightGrayOpacity};
    }
    & > div {
      border-bottom: 1px solid ${COLORS.lightGrayOpacity};
    }
    .chart-content-item {
      display: flex;
      align-items: center;
      height: 48px;
      justify-content: space-between;
      & > :first-child {
        padding-left: 10px;
      }
      & > div {
        font-size: 14px;
        font-family: Gilroy-Medium;
        width: 270px;
        & > div {
          cursor: pointer;
          width: fit-content;
        }
      }
    }
  }
`;
