import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import SelectBox from "components/Selects/SelectBox";
import CurrentlyTrendChart from "../charts/CurrentlyTrendChart";
import CurrentlyTrendChartColumn from "../charts/CurrentlyTrendChartColumn";
import useColorTheme from "../../../../../hooks/useColorTheme";
import useFilters from "../../../../../hooks/useFilters";

import { changeNameRetailer } from "utils/changeNameRetailer";

const selectsSchedule = [
  {
    name: "chartSchedule",
    default: "daily",
    options: [
      { lable: "Daily", value: "daily" },
      { lable: "Weekly", value: "weekly" },
      { lable: "Monthly", value: "monthly" },
    ],
  },
];

const TrendAllDayColumn = ({ data, activeTab }) => {
  const { sourceType } = useSelector((state) => state.filters.filters);
  const { lastFilter } = useFilters();

  const [selectsType, setSelectsType] = useState([]);

  const [chartType, setChartType] = useState("byTotal");
  const [chartSchedule, setChartSchedule] = useState("daily");

  const [chartDataLine, setChartDataLine] = useState({});
  const [chartDataColumn, setChartDataColumn] = useState({});
  const [colorChart, setColorChart] = useState("");
  const { primaryColor } = useColorTheme();

  useEffect(() => {
    if (sourceType?.length) {
      const retailers = sourceType.map((item) => ({
        lable: changeNameRetailer(item.name),
        value: item.name,
        disabled: false,
        id: item.id,
      }));

      let currentRetailers;
      if (lastFilter.sourceType.length) {
        currentRetailers = retailers.filter((item) =>
          lastFilter.sourceType
            .split("|")
            .map((item) => Number(item))
            .includes(item.id)
        );
      } else {
        currentRetailers = retailers;
      }

      const total = [
        { lable: "Total", value: "byTotal", disabled: false },
        { lable: "All Retailers", value: "all", disabled: Boolean(chartSchedule !== "daily") },
      ];

      const options = [...total, ...currentRetailers.sort((a, b) => a.lable.localeCompare(b.lable))];

      const selectsType = [
        {
          name: "chartType",
          default: chartType,
          options,
        },
      ];

      setSelectsType(selectsType);
    }
  }, [lastFilter, sourceType, chartSchedule]);

  useEffect(() => {
    if (data && Object.entries(data).length !== 0) {
      if (chartSchedule === "daily") {
        if (chartType === "byTotal") {
          setChartDataLine({ data: data.data.daily });
        } else if (chartType === "all") {
          const retailersData = data.share.map((item) => ({
            title: item.title,
            color: item.color,
            data: item.data.daily,
          }));
          setChartDataLine({ share: retailersData });
        } else {
          const dataChart = data.share.find((item) => item.title === chartType);
          setChartDataLine({
            share: [{ title: dataChart.title, color: dataChart.color, data: dataChart.data.daily }],
          });
        }
      } else {
        if (chartType === "byTotal") {
          setChartDataColumn(data.data);
          setColorChart(primaryColor);
        } else {
          const dataChart = data.share.find((item) => item.title === chartType);
          setChartDataColumn(dataChart.data);
          setColorChart(dataChart.color);
        }
      }
    }
  }, [data, chartSchedule, chartType]);

  const handleChangeChartType = (values) => {
    setChartType(values["chartType"]);
    setChartDataLine({});
    setChartDataColumn({});
  };

  const handleChangeChartSchedule = (values) => {
    setChartSchedule(values["chartSchedule"]);
    setChartDataLine({});
    setChartDataColumn({});
  };

  return (
    <div className="viewing-trend-box">
      <div className="trend-header">
        <div className="title">
          You are currently viewing trend for <span>{activeTab}</span>.
        </div>
        <div style={{ display: "flex", textTransform: "capitalize", fontFamily: "Gilroy-Medium" }}>
          {selectsSchedule.map((item, index) => (
            <SelectBox
              key={index}
              disabled={chartType === "all"}
              data={item}
              setSelectValue={handleChangeChartSchedule}
            />
          ))}
          {selectsType.map((item, index) => (
            <SelectBox key={index} data={item} setSelectValue={handleChangeChartType} />
          ))}
        </div>
      </div>

      {chartSchedule === "daily" ? (
        <>
          {Object.entries(chartDataLine).length ? <CurrentlyTrendChart data={chartDataLine} type={chartType} /> : null}
        </>
      ) : (
        <>
          {Object.entries(chartDataColumn).length ? (
            <CurrentlyTrendChartColumn data={chartDataColumn} colorChart={colorChart} schedule={chartSchedule} />
          ) : null}
        </>
      )}
    </div>
  );
};

export default TrendAllDayColumn;
