import moment from "moment";

const dateParams = {
  //relative
  1: `custom|${moment().subtract(1, "d").format("YYYY-MM-DD")}|${moment().format("YYYY-MM-DD")}`,
  2: `custom|${moment().subtract(2, "d").format("YYYY-MM-DD")}|${moment().subtract(1, "d").format("YYYY-MM-DD")}`,
  3: `custom|${moment().subtract(8, "d").format("YYYY-MM-DD")}|${moment().subtract(1, "d").format("YYYY-MM-DD")}`,
  4: `custom|${moment().subtract(15, "d").format("YYYY-MM-DD")}|${moment().subtract(1, "d").format("YYYY-MM-DD")}`,
  5: `custom|${moment().subtract(31, "d").format("YYYY-MM-DD")}|${moment().subtract(1, "d").format("YYYY-MM-DD")}`,
  6: `custom|${moment().subtract(91, "d").format("YYYY-MM-DD")}|${moment().subtract(1, "d").format("YYYY-MM-DD")}`,
  90: `custom|${moment().subtract(366, "d").format("YYYY-MM-DD")}|${moment().subtract(1, "d").format("YYYY-MM-DD")}`, // Last 365
  91: `custom|${moment(`${moment().year()}-01-01`).format("YYYY-MM-DD")}|${moment().subtract(1, "d").format("YYYY-MM-DD")}|ytd`, // Year To Date
  //monthly
  14: `custom|${moment(`${moment().year()}-01-01`).format("YYYY-MM-DD")}|${moment(`${moment().year()}-01-31`).format("YYYY-MM-DD")}`,
  13: `custom|${moment(`${moment().year()}-02-01`).format("YYYY-MM-DD")}|${moment(`${moment().year()}-02-28`).format("YYYY-MM-DD")}`,
  12: `custom|${moment(`${moment().year()}-03-01`).format("YYYY-MM-DD")}|${moment(`${moment().year()}-03-31`).format("YYYY-MM-DD")}`,
  10: `custom|${moment(`${moment().year()}-04-01`).format("YYYY-MM-DD")}|${moment(`${moment().year()}-04-30`).format("YYYY-MM-DD")}`,
  9: `custom|${moment(`${moment().year()}-05-01`).format("YYYY-MM-DD")}|${moment(`${moment().year()}-05-31`).format("YYYY-MM-DD")}`,
  8: `custom|${moment(`${moment().year()}-06-01`).format("YYYY-MM-DD")}|${moment(`${moment().year()}-06-30`).format("YYYY-MM-DD")}`,
  7: `custom|${moment(`${moment().year()}-07-01`).format("YYYY-MM-DD")}|${moment(`${moment().year()}-07-31`).format("YYYY-MM-DD")}`,
  19: `custom|${moment(`${moment().year()}-08-01`).format("YYYY-MM-DD")}|${moment(`${moment().year()}-08-31`).format("YYYY-MM-DD")}`,
  18: `custom|${moment(`${moment().year()}-09-01`).format("YYYY-MM-DD")}|${moment(`${moment().year()}-09-30`).format("YYYY-MM-DD")}`,
  17: `custom|${moment(`${moment().year()}-10-01`).format("YYYY-MM-DD")}|${moment(`${moment().year()}-10-31`).format("YYYY-MM-DD")}`,
  16: `custom|${moment(`${moment().year()}-11-01`).format("YYYY-MM-DD")}|${moment(`${moment().year()}-11-30`).format("YYYY-MM-DD")}`,
  15: `custom|${moment(`${moment().year()}-12-01`).format("YYYY-MM-DD")}|${moment(`${moment().year()}-12-31`).format("YYYY-MM-DD")}`,
};

const monthly = [14, 13, 12, 10, 9, 8, 7, 19, 18, 17, 16, 15];
const yearly = {
  start: 20,
  end: 89,
};

export const getTimePeriod = (date) => {
  if (!Number(date)) return date;

  if (Number(date) >= yearly.start && Number(date) <= yearly.end) {
    const yearsDate = moment('2019-01-01').startOf('year');
    yearsDate.add(Number(date) - yearly.start, 'year');

    return `custom|${yearsDate.format('YYYY')}-01-01|${yearsDate.format('YYYY')}-12-31`;
  } else if (monthly.includes(Number(date))) {
    const currentDate = dateParams[date].split("|");
    const startDate = currentDate[1];
    const endDate = currentDate[2];
    const dateHasСome = moment(new Date()).isAfter(startDate);

    if (dateHasСome) return dateParams[date];

    const startItems = startDate.split("-");
    const endItems = endDate.split("-");
    const start = `-${startItems[1]}-${startItems[2]}`;
    const end = `-${endItems[1]}-${endItems[2]}`;
    const createrMoment = (date) => `${moment(`${moment().subtract(1, "year").year()}${date}`).format("YYYY-MM-DD")}`;

    return `custom|${createrMoment(start)}|${createrMoment(end)}`;
  } else {

    return dateParams[date];
  }
};
