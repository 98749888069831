import { COLORS } from '../assets/colors/colors';

const formatDefault = (r, g, b, opacity) => `rgba(${[r, g, b].join(',')}, ${opacity})`

export const hexToRgbA = (hex, opacity, outputFormat=formatDefault) => {
    let c

    if(hex) {
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if(c.length== 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x'+c.join('');

            return outputFormat((c>>16)&255, (c>>8)&255, c&255, opacity);
        }
        //subject: "New notifications",
        //throw new Error('Bad Hex');
        return outputFormat(0, 0, 0, 1);

    } else {
        return outputFormat(0, 0, 0, 1);
    }
    
}

const colorShift = (color, shiftfactor) => {
    return (255 - color)/255 * shiftfactor + color;
}
export const hexToRgbShift = (hex, hexDiff) => {
    let c, d

    if(hex && hexDiff) {
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split('');
            if(c.length== 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x'+c.join('');
 
            const red = parseInt(colorShift(Number((c>>16)&255), hexDiff[0]));
            const green = parseInt(colorShift(Number((c>>8)&255), hexDiff[1]));
            const blue = parseInt(colorShift(Number(c&255), hexDiff[2]));

            return '#' + ('00' + red.toString(16)).substr(-2) 
              + ('00' + green.toString(16)).substr(-2) 
              + ('00' + blue.toString(16)).substr(-2);
        }

        return COLORS.darkGray;

    } else {
        return COLORS.darkGray;
    }
    
}