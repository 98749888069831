import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useColorTheme from "../../../../../hooks/useColorTheme";
import useFilters from "../../../../../hooks/useFilters";
import about from "assets/images/about.svg";

//Components
import SelectBox from "components/Selects/SelectBox";
import RatingSummaryTopChart from "tabs/RatingTabs/RatingTopTabs/RatingSummary/RatingSummaryTop/components/RatingSummaryTopChart";
import { Styles } from "./styles";
import LoaderBox from "components/LoaderBox";
import TopMoversRating from "tabs/RatingTabs/RatingTopTabs/RatingSummary/RatingSummaryTop/components/TopMoversRating";
import ShowAll from "components/ShowAll";
//Utils
import { STATE_STATUSES } from "utils/statuses";
import { getTimePeriod } from "utils/getTimePeriod";

//Actions
import { fetchRatingSummary, setRatingSummaryFilter } from "store/rating/ratingSummary/actions";
//Constants
import { filtersForRatingSummary } from "constants/filters";

//Images
import { Cancel } from "assets/icons/icons";

const RatingSummaryTop = ({ arrayOfDesiredBrand, setArrayOfDesiredBrand, retailer, setRetailer, setClickedArray }) => {
  //dispatch
  const dispatch = useDispatch();
  const { primaryColor } = useColorTheme();

  //selectors
  const { status, filter, ratingSummary } = useSelector((state) => state.ratingSummary);
  const { statusFilters, mainLastFilter } = useFilters();
  const { ratingSummaryProduct, status: ratingSummaryProductStatus } = useSelector(
    (state) => state.ratingSummaryProduct
  );

  //states
  const [hoverEl, setHoverEl] = useState("");
  const [queryParams, setQueryParams] = useState({});

  //Constants
  const isBrand = filter[0].value === "byBrand";
  // form query
  useEffect(() => {
    if (statusFilters !== STATE_STATUSES.READY) return;

    setQueryParams((prevState) => ({
      ...prevState,
      timePeriod: filter[1].value ? "0" : getTimePeriod(mainLastFilter.date),
      sourceType: mainLastFilter.sourceType,
      category: mainLastFilter.category,
      manufacture: mainLastFilter.manufacture,
      productBrand: mainLastFilter.productBrand,
      productGroup: mainLastFilter.productGroup,
      product: mainLastFilter.product,
    }));

    setClickedArray([]);
    setArrayOfDesiredBrand([]);
    setRetailer([]);
  }, [mainLastFilter, filter, statusFilters, setArrayOfDesiredBrand, setClickedArray, setRetailer]);

  //set values for filters
  const setSelectValue = (values) => {
    const value = Object.keys(values);
    setArrayOfDesiredBrand([]);
    setRetailer([]);
    setClickedArray([]);

    const updateData = filter.map((item) => {
      if (item.name === value[0]) {
        return {
          ...item,
          value: values[value[0]],
        };
      } else if (item.name === value[1]) {
        return {
          ...item,
          value: values[value[1]],
        };
      }

      return item;
    });

    dispatch(setRatingSummaryFilter(updateData));
  };
  useEffect(() => {
    if (Object.keys(queryParams).length) {
      dispatch(fetchRatingSummary(queryParams));
    }
  }, [queryParams, dispatch]);

  const renderChart = () => {
    return (
      <RatingSummaryTopChart
        data={ratingSummary}
        currentChart={filter}
        setHoverEl={setHoverEl}
        hoverEl={hoverEl}
        arrayOfDesiredBrand={arrayOfDesiredBrand}
        setArrayOfDesiredBrand={setArrayOfDesiredBrand}
        retailer={retailer}
        setRetailer={setRetailer}
        setClickedArray={setClickedArray}
      />
    );
  };

  const renderMovers = () => {
    return <TopMoversRating data={ratingSummary} filter={filter} />;
  };

  const renderSelectedEntity = () => {
    let content = "";
    let func;

    const suffix = arrayOfDesiredBrand.length > 1 ? "s" : "";

    if (isBrand) {
      content = arrayOfDesiredBrand.length + " brand" + suffix;
      func = setArrayOfDesiredBrand;
    } else {
      content = retailer[0];
      func = setRetailer;
    }

    const condition = (isBrand && arrayOfDesiredBrand.length) || (!isBrand && retailer.length);

    if (condition) {
      return (
        <div className="filter" onClick={() => func([])}>
          <span className="filter-title">{content}</span>
          <Cancel fill={"#fff"} />
        </div>
      );
    }
  };

  return (
    <Styles color={primaryColor}>
      <>
        <ShowAll
          icon={about}
          text={"Show All Reviews"}
          onChangeHandler={setSelectValue}
          value={filter[1].value}
          disabled={status !== STATE_STATUSES.READY}
        />
        <div className="filters-box">
          {filtersForRatingSummary.map((item, index) => {
            return (
              <div key={index}>
                <SelectBox
                  filter={filter[0].value}
                  data={item}
                  setSelectValue={setSelectValue}
                  disabled={status !== STATE_STATUSES.READY ? true : false}
                />
              </div>
            );
          })}
          {renderSelectedEntity()}
        </div>

        <div className="wrapper-box-relative">
          {ratingSummary.success ? (
            <div className="chart-wrapper-box">
              {renderChart()}
              {renderMovers()}
            </div>
          ) : null}
          {status === STATE_STATUSES.PENDING ||
          (!ratingSummaryProduct.success && ratingSummaryProductStatus === STATE_STATUSES.PENDING) ? (
            <LoaderBox />
          ) : null}
        </div>
      </>
    </Styles>
  );
};

export default RatingSummaryTop;
