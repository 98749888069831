import React from "react";
import { useSelector } from "react-redux";
import { Radio } from "antd";
import { sectionNemes } from "components/TabsExport/exportSections";

const ReportData = ({ current, options, setSectionId, sectionId, disabled }) => {
  const { sections } = useSelector((state) => state.authorization);

  return (
    <>
      <div className="export-item">
        <div className="number">{current}</div>
        <div className="text">Report Data</div>
      </div>
      <div className="export-hint" style={{ marginTop: "10px", fontFamily: "Gilroy-ExtraBold" }}>
        Select the data type you wish to report on
      </div>
      <Radio.Group onChange={(e) => setSectionId(e.target.value)} value={sectionId} disabled={disabled}>
        {sectionNemes.map((item, index) => {
          return (
            <Radio
              key={index}
              disabled={!sections.find((section) => section.id === item.value).status || !options?.[item.section]}
              value={item.value}
            >
              {item.lable}
            </Radio>
          );
        })}
      </Radio.Group>
    </>
  );
};

export default ReportData;
