import React from "react";
import PieChart from "tabs/RankingTabs/RankingBottomTabs/components/PieChart";
import AverageChangeChart from "tabs/RankingTabs/RankingBottomTabs/components/AverageChangeChart";

const SearchTerm = (props) => {
  const {
    action,
    storeTerm,
    storeShare,
    actionShareSingleDay,
    storeShareSingleDay,
    param,
    data,
    isInactive,
    handleActiveTab,
    isLocation,
    isMany,
  } = props;

  return (
    <div className="item-chart-wrapper">
      {isInactive && isMany ? <div className="inactive-box" onClick={() => handleActiveTab(data.title)}></div> : null}
      <div className="chart-border"></div>
      <PieChart title={data.title} percent={data.percent} />
      <AverageChangeChart
        action={action}
        storeTerm={storeTerm}
        storeShare={storeShare}
        actionShareSingleDay={actionShareSingleDay}
        storeShareSingleDay={storeShareSingleDay}
        name={data.title}
        data={data[param]}
        isLocation={isLocation}
      />
    </div>
  );
};

export default SearchTerm;
