import { COLORS } from "assets/colors/colors";
import styled from "styled-components";

export const Styles = styled.div.attrs((props) => props)`
  .popup-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 3;
    .popup-box {
      max-width: 1240px;
      height: calc(100vh - 80px);
      height: min-content;
      overflow: hidden;
      width: 100%;
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 8px 20px 0 rgba(30, 31, 32, 0.18), 0 2px 4px 0 rgba(0, 0, 0, 0.17);
      padding: 40px;
      position: relative;

      .cancel {
        position: absolute;
        width: 24px;
        height: 24px;
        right: 40px;
        top: 40px;
        cursor: pointer;
      }
    }
  }
  .header {
    min-height: 40px;
    display: flex;
    margin-bottom: 15px;
    .product-img-wrapper {
      display: flex;
      width: 120px;
      height: 120px;
      padding: 5px;
      justify-content: center;
      align-items: center;
      margin-right: 30px;
      border: 1px solid #e9e9e9;
      border-radius: 5px;
      & > img {
        max-width: 116px;
      }
    }
    .header-text-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      & > span {
        font-family: Gilroy-ExtraBold;
        font-size: 22px;
        font-weight: 800;
        margin-top: 5px;
      }
      .see-on-website {
        display: flex;
        margin-bottom: 5px;
        align-items: center;
        cursor: pointer;
        & > img {
          width: 24px;
          margin-right: 16px;
        }
        & > a {
          font-family: Gilroy-ExtraBold;
          font-size: 16px;
          font-weight: 800;
          color: ${(props) => props.color};
        }
      }
    }
  }
  .scroll {
    height: 288px;
    overflow-x: hidden;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #abaaba;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #807f97;
    }
    .screenshot-text {
      font-size: 14px;
      margin-bottom: 16px;
      font-family: "Gilroy-Medium";
    }
    .screenshot {
      display: flex;
      flex: 1;
      & > img {
        max-width: 100%;
      }
    }
  }
  .scroll.snapshot {
    height: 677px;
  }

  .table-wrapper-availability {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    .table-header-availability {
      display: flex;
      .table-item-availability {
        display: flex;
        flex: 1;
      }
      .table-item-availability.availability-header {
        max-width: 20%;
        height: 64px;
        padding: 0;
        display: flex;
        align-items: center;
        font-family: Gilroy-ExtraBold;
        font-size: 12px;
        color: #9696a0;
        padding-left: 18px;
      }
      & > :first-child {
        padding-left: 0 !important;
      }
    }
    .table-body-availability {
      display: flex;
      .table-item-availability.availability-body {
        max-width: 20%;
        border: 1px solid #e0e0e4;
        padding: 18px;
        border-left: none;
        font-family: Gilroy-Medium;
        font-size: 14px;
      }
      & > :last-child {
        border-right: none !important;
      }
      & > :first-child {
        padding-left: 0 !important;
      }
    }
  }
  .group-btns {
    position: absolute;
    right: 60px;

    display: flex;
    background: ${COLORS.lightGray};
    height: fit-content;
    margin-right: 17px;
    border-radius: 5px;
    padding: 3px;
    & > div {
      width: 34px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      cursor: pointer;
      & > img {
        width: 20px;
        height: 20px;
      }
    }
  }
`;
