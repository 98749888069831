import React, { useState, useEffect, useRef  } from 'react'
import { useDispatch, useSelector } from "react-redux"
import Portal from 'utils/Portal'
import LoaderBox from "components/LoaderBox"

import green from 'assets/images/green.svg'
import red from 'assets/images/red.svg'

const TotalTermChart = ({ data, termProdcut, shareSize, currentManufacturer }) => {

    const wrapperRef = useRef(null)

    const dispatch = useDispatch()

    const { currentShareLocation } = useSelector((state) => state.rankingLocationAnalysis)
    const { currentShareLocationSingleDay } = useSelector((state) => state.rankingLocationAnalysis)

    const [ isLoading, setIsLoading ] = useState(false)
    const [ showTooltip, setShowTooltip ] = useState(false)
    const [ indexTooltip, setIndexTooltip ] = useState(null)
    const [coords, setCoords] = useState({})

    useEffect(() => {
        setIsLoading(true)

        setTimeout(function () {
            setIsLoading(false)
        }, 300);
    }, [shareSize, currentManufacturer])

    const handleClick = (title) => {
        dispatch({ type: 'SET_TERM_PRODUCT', payload: title })
    }

    function useOutsideAlerter(refWrapper) {
        useEffect(() => {
            if (showTooltip) {
                function handleClickOutside(event) {
                    if (refWrapper.current && !refWrapper.current.contains(event.target)) {
                        setShowTooltip(false)
                    }
                }
                document.addEventListener("mouseout", handleClickOutside)

                return () => {
                    document.removeEventListener("mouseout", handleClickOutside);
                }
            }
        }, [refWrapper, showTooltip])

    }
    useOutsideAlerter(wrapperRef)

    const onMouseMove = (e, index) => {
        if(wrapperRef.current) {
            let wrapperBox = wrapperRef.current.getBoundingClientRect()

            setCoords({
                y: wrapperBox.top-100,
                x: e.pageX - 98
            })

            setShowTooltip(true)
            setIndexTooltip(index)
        }
    }

    const tooltip = (item) => {
        return (
            <Portal>
                <div className='tooltip' style={{ left: `${coords.x}px`, top: `${coords.y}px` }}>
                    <div className='name'>{item.title}</div>
                    <div className='description'>
                        <div>Change</div>
                        <div className='change'>
                            { item.change !== 0 ? <img className='direction' src={item.change > 0 ? green : red} alt='direction' /> : null }
                            <div>{item.change}%</div>
                        </div>
                    </div>
                    <div className='description'>
                        <div>Average Share</div>
                        <div>{item.averageShare}%</div>
                    </div>
                </div>
            </Portal>
        )
    }

    return (
        <div className='total-term-chart-wrapper'>
            {
                isLoading ?
                    <LoaderBox /> 
                    :
                    <>
                        <div className='title'>
                            There are <span>{data.searchTermsCount}
                            </span> locations in 
                            <span> {Object.entries(currentShareLocation).length !== 0 ? currentShareLocation.retailer : currentShareLocationSingleDay.retailer}</span>. 
                            The biggest change was in <span>{data.biggestChange}</span>.
                        </div>
                        <div className='total-term-chart-box'>
                            <div className='change-box-wrapper'>
                                <div className='change-box'>
                                    {
                                        data.searchTerms.map((item, index) => 
                                            <div 
                                                key={index}
                                                className='change-column'
                                                ref={wrapperRef}
                                                onClick={() => handleClick(item.title)}
                                                onMouseMove={(e) => onMouseMove(e, index)}
                                            >
                                                <div 
                                                    className='change-item' 
                                                    style={{ 
                                                        background: termProdcut.length ? item.title === termProdcut ? item.change > 0 ? '#1ec68d' : '#fe6a68' : item.change > 0 ? '#74DAB9' : '#FBA2A2' : item.change > 0 ? '#1ec68d' : '#fe6a68',
                                                        height: `${Math.abs(item.change)/2}px`,
                                                        bottom: `${item.change < 0 ? item.change/2 : 0}px`,
                                                        borderRadius: item.change > 0 ? '3px 3px 0px 0px' : '0px 0px 3px 3px'
                                                    }}
                                                ></div>
                                                { showTooltip && indexTooltip === index ? tooltip(item) : null }
                                            </div>)
                                    }
                                </div>
                            </div>
                            <div className='averageShare-box-wrapper'>
                                <div className='averageShare-box'>
                                    {
                                        data.searchTerms.map((item, index) => 
                                            <div 
                                                key={index}
                                                className='averageShare-column'
                                                onClick={() => handleClick(item.title)}
                                                onMouseMove={(e) => onMouseMove(e, index)}
                                            >
                                                <div 
                                                    className='averageShare-item' 
                                                    style={{ 
                                                        background: termProdcut.length ? item.title === termProdcut ? '#a0a2a6' : '#D9DADC' : '#a0a2a6',
                                                        height: `${Number(item.averageShare)}px` 
                                                    }}
                                                ></div>
                                            </div>)
                                    }
                                </div>
                            </div>
                        </div>
                    </>
            }
        </div>
    )
}

export default TotalTermChart