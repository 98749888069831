import { success, error } from "redux-saga-requests";
import { FETCH_UNIVERSAL_FILTERS, SET_FETCH_UNIVERSAL_FILTERS, SET_IS_FETCH_STATUS, SET_UNIVERSAL_OBJ_STATUS } from "./actions";
import { STATE_STATUSES } from "utils/statuses";
import { RESET_ERROR } from "store/error/actions";

const initialState = {
  filters: {
    category: "",
    date: { relative: [] },
    manufacture: "",
    product: "",
    productBrand: "",
    productGroup: "",
    sourceType: "",
  },
  status: STATE_STATUSES.INIT,
  isFetchStatus: STATE_STATUSES.INIT,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_UNIVERSAL_FILTERS: {
      return processReducer(state);
    }
    case success(FETCH_UNIVERSAL_FILTERS): {
      // localStorage.setItem("universalFilter", JSON.stringify(action.data.filters));
      // addItem({ data: action.data.filters })
      return {
        ...state,
        filters: action.data.filters,
        status: STATE_STATUSES.READY,
        isFetchStatus: STATE_STATUSES.READY,
      };
    }

    case error(FETCH_UNIVERSAL_FILTERS): {
        const error = action.error.response;
        return errorReducer(state, error);
      }
    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }
    case SET_FETCH_UNIVERSAL_FILTERS: {
      return {
        ...state,
        filters: action.data,
        status: STATE_STATUSES.READY,
      };
    }
    case SET_IS_FETCH_STATUS: {
      return {
        ...state,
        isFetchStatus: action.data,
      };
    }
    case SET_UNIVERSAL_OBJ_STATUS: {
      return {
        ...state,
        status: action.data,
      };
    }
    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
