import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const PieChart = (props) => {
  const { title, percent } = props;

  const [pricings, setPricings] = useState([]);

  useEffect(() => {
    const pricings = [
      {
        innerSize: "70%",
        clickable: false,
        data: [
          {
            y: percent,
            color: "#f14758",
          },
          {
            y: 100 - percent,
            color: "#D2D3D5",
          },
        ],
      },
    ];

    setPricings(pricings);
  }, [percent]);

  const options = {
    chart: {
      type: "pie",
      height: 120,
      width: 120,
      backgroundColor: "rgba(0,0,0,0)",
      animation: false,
    },
    title: {
      text: "",
    },
    tooltip: {
      shared: false,
      enabled: false,
      backgroundColor: null,
      borderWidth: 0,
    },
    plotOptions: {
      series: {
        states: {
          hover: {
            enabled: false,
          },
        },
        borderWidth: 1,
      },
      pie: {
        borderWidth: 0,
        states: {
          hover: {
            halo: null,
          },
          inactive: {
            opacity: 1,
          },
        },
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: pricings,
  };

  return (
    <div>
      <div className="pie-chart">
        <div className="percent">{Math.ceil(percent * 100) / 100}%</div>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
      <div className="pie-title">{title}</div>
    </div>
  );
};

export default PieChart;
