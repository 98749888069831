import React, { useState } from "react";
//Components
import AssortmentSummaryTop from "./AssortmentSummaryTop";
import AssortmentSummaryBottom from "./AssortmentSummaryBottom";
import AssortmentSummaryCalendar from "./AssortmentSummaryBottom/components/AssortmentSummaryCalendar/index";

const AssortmentSummary = () => {
  const [selectedEntity, setSelectedEntity] = useState({});
  const [clickedArray, setClickedArray] = useState([]);
  const [isRedraw, setIsRedraw] = useState(false);
  const [arrayOfDesiredBrand, setArrayOfDesiredBrand] = useState([]);

  const renderBottomPart = () => {
    if (Object.entries(selectedEntity).length) {
      return (
        <AssortmentSummaryCalendar
          selectedEntity={selectedEntity}
          clickedArray={clickedArray}
          setClickedArray={setClickedArray}
          setIsRedraw={setIsRedraw}
          isRedraw={isRedraw}
          arrayOfDesiredBrand={arrayOfDesiredBrand}
        />
      );
    } else {
      return <AssortmentSummaryBottom />;
    }
  };

  return (
    <div>
      <AssortmentSummaryTop
        selectedEntity={selectedEntity}
        setSelectedEntity={setSelectedEntity}
        setClickedArray={setClickedArray}
        arrayOfDesiredBrand={arrayOfDesiredBrand}
        setArrayOfDesiredBrand={setArrayOfDesiredBrand}
      />
      {renderBottomPart()}
    </div>
  );
};

export default AssortmentSummary;
