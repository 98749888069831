import { success, error } from "redux-saga-requests";
import { FETCH_SHARE_OF_MEDIA_BOTTOM, SET_PRISTINE_SHARE_OF_MEDIA_BOTTOM } from "./actions";
import { STATE_STATUSES } from "utils/statuses";
import { RESET_ERROR } from "store/error/actions";

const initialState = {
  shareOfMediaBottom: {
    success: false,
    total: {
      locations: 0,
      searchTermCount: 0,
      locationCount: 0,
      table: {
        retailerCount: 0,
        locationCount: 0,
        productCount: 0,
      },
    },
    rows: [],
  },
  status: STATE_STATUSES.INIT,
  isPristine: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SHARE_OF_MEDIA_BOTTOM: {
      return processReducer(state);
    }
    case success(FETCH_SHARE_OF_MEDIA_BOTTOM): {
      return {
        ...state,
        shareOfMediaBottom: { ...action.data, success: true },
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }
    case error(FETCH_SHARE_OF_MEDIA_BOTTOM): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case SET_PRISTINE_SHARE_OF_MEDIA_BOTTOM: {
      return {
        ...state,
        isPristine: action.payload,
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
