import React, { useState } from "react";
import useRetailers from "../../../../hooks/useRetailers";

const Box = (props) => {
  const { getRemoteLogo } = useRetailers();
  return (
    <div
      className="retailer-box"
      draggable={props.draggable}
      onDragStart={props.onDragStart({ id: props.box.id })}
      onDragOver={props.onDragOver({ id: props.box.id })}
      onDrop={props.onDrop({ id: props.box.id })}
    >
      <div className="retailer-name">
        <img src={getRemoteLogo(props.box.name)} alt="retailer" />
        <span>{props.index + 1}</span>
      </div>
    </div>
  );
};

const RetailerBoxesGroup = ({
  orderRetailers,
  handleChangeOrder,
  componentForRender,
}) => {
  const Component = componentForRender ? componentForRender : Box;

  const swapBoxes = (fromBox, toBox) => {
    let boxes = orderRetailers.slice();
    let fromIndex = -1;
    let toIndex = -1;

    for (let i = 0; i < boxes.length; i++) {
      if (boxes[i].id === fromBox.id) {
        fromIndex = i;
      }
      if (boxes[i].id === toBox.id) {
        toIndex = i;
      }
    }

    if (fromIndex != -1 && toIndex != -1) {
      let { fromId, ...fromRest } = boxes[fromIndex];
      let { toId, ...toRest } = boxes[toIndex];
      boxes[fromIndex] = { id: fromBox.id, ...toRest };
      boxes[toIndex] = { id: toBox.id, ...fromRest };

      handleChangeOrder(boxes);
    }
  };

  const handleDragStart = (data) => (event) => {
    let fromBox = JSON.stringify({ id: data.id });
    event.dataTransfer.setData("dragContent", fromBox);
  };

  const handleDragOver = (data) => (event) => {
    event.preventDefault();
    return false;
  };

  const handleDrop = (data) => (event) => {
    event.preventDefault();

    let fromBox = JSON.parse(event.dataTransfer.getData("dragContent"));
    let toBox = { id: data.id };

    swapBoxes(fromBox, toBox);
    return false;
  };

  const makeBoxes = () => {
    return orderRetailers.map((box, index) => (
      <Component
        index={index}
        box={box}
        key={box.id}
        draggable="true"
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      />
    ));
  };

  return (
    <div
      className="retailer-boxes-group"
      style={{ justifyContent: componentForRender ? "flex-start" : null }}
    >
      {makeBoxes()}
    </div>
  );
};

export default RetailerBoxesGroup;
