import React, { useState } from "react";
import useColorTheme from "../../../hooks/useColorTheme";

import moment from "moment";
//Components
import { Styles } from "./style";
//Assets
import cancel2 from "assets/images/cancel2.svg";
import smallBurger from "assets/images/burger-small.svg";
import snapShot from "assets/images/snapshot.svg";
import { COLORS } from "assets/colors/colors";
import product from "assets/images/test-product.png";
import LoaderBox from "components/LoaderBox";
import { firstCharToUpperCase } from "utils/generalUtils";

const PopupMediaSummary = ({ banner, closePopup }) => {
  const { title, retailer, live, startDate, endDate, image, products, screenshot, category } = banner;
  const [activeBtn, setActiveBtn] = useState(0);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const { primaryColor } = useColorTheme();

  const changePage = (i) => {
    if (screenshot?.length) {
      setActiveBtn(i);
    }
    setIsImageLoading(true);
  };

  const SingleProduct = ({ item }) => {
    const { image, title } = item;
    return (
      <div className="product-row">
        <img src={image?.length ? image : product} alt="" />
        <span>{title}</span>
      </div>
    );
  };

  return (
    <Styles>
      <div className="popup-wrapper">
        <div className="popup-box">
          <img className="cancel" src={cancel2} alt="cancel" onClick={closePopup} />
          <div className="header" style={{ marginBottom: activeBtn === 1 ? 10 : null }}>
            <span>
              "{category}" in {firstCharToUpperCase(retailer.name)}
            </span>
            <div className="group-btns">
              <div
                className="burger"
                onClick={() => changePage(0)}
                style={{
                  background: activeBtn === 0 ? primaryColor : COLORS.lightGray,
                }}
              >
                <img src={smallBurger} alt="" />
              </div>
              <div
                className="snapshot"
                style={{
                  background: activeBtn === 1 ? primaryColor : COLORS.lightGray,
                  cursor: screenshot?.length ? null : "not-allowed",
                }}
                onClick={() => changePage(1)}
              >
                <img src={snapShot} alt="" />
              </div>
            </div>
          </div>
          {activeBtn === 0 ? (
            <div className="scroll">
              <div className="row-periods">
                <div className="days">
                  <span>Days</span>
                  <span>{live}</span>
                </div>
                <div className="start-end">
                  <span>Start - End</span>
                  <span>
                    {moment(startDate).format("D MMM YY")} - {moment(endDate).format("D MMM YY")}
                  </span>
                </div>
              </div>

              <div className="banner-wrapper">{image?.length ? <img src={image} alt="" /> : <span>No Image</span>}</div>

              <div className="products">
                <div className="products-title">Product</div>
                <div className="products-container">
                  {products.map((el, i) => {
                    return <SingleProduct item={el} />;
                  })}
                </div>
              </div>
            </div>
          ) : (
            <div className="scroll">
              {/* <span className="screenshot-text">Screenshot</span> */}
              <div className="screenshot">
                {isImageLoading ? <LoaderBox style={{ top: 82 }} /> : null}
                <img onLoad={() => setIsImageLoading(false)} src={screenshot} alt="" className="screenshot-img" />
              </div>
            </div>
          )}
        </div>
      </div>
    </Styles>
  );
};

export default PopupMediaSummary;
