import React, { useEffect, useState, useMemo } from "react";
import StarRatings from "react-star-ratings";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, Checkbox } from "antd";
import useColorTheme from "../../../hooks/useColorTheme";
import useRetailers from "../../../hooks/useRetailers";
import { STATE_STATUSES } from "utils/statuses";

//Components
import { Styles } from "./styles";
import PopupRatingProductTable from "./PopupRatingProductTable";
import ShowPerPage from "components/ShowPerPage";
import LoaderBox from "components/LoaderBox";
//Images
import cancel2 from "assets/images/cancel2.svg";
import productImage from "assets/images/testImage.png";

//Actions
import { fetchRatingSummaryReviews, fetchRatingSummaryReviewsAll } from "store/rating/ratingSummaryProduct/actions";

const PopupRatingProduct = ({
  closePopup,
  product,
  showRetailer,
  combinedCoreRetailers,
  timePeriod,
  showAllReviews,
}) => {
  const { primaryColor } = useColorTheme();
  const { getRemoteLogo } = useRetailers();
  const { ratingSummaryReviews, ratingSummaryReviewsAll, status } = useSelector((state) => state.ratingSummaryProduct);

  //constants
  const dispatch = useDispatch();
  const id = product.id.toString();
  const baseQuery = {
    coreRetailerIds: combinedCoreRetailers && combinedCoreRetailers.length > 0 ? combinedCoreRetailers.join("|") : id,
    limit: 10,
    page: 1,
    sort: "date|desc",
    timePeriod: timePeriod,
  };
  //States
  const [sortId, setSortId] = useState("date");
  const [sortDirection, setSortDirection] = useState(false);
  const [query, setQuery] = useState({});
  const [showAll, setShowAll] = useState(false);
  const [reviewsCount, setReviewsCount] = useState(null);
  const [avgRating, setAvgRating] = useState(null);

  useEffect(() => {
    if (showAll && ratingSummaryReviewsAll.success && status !== STATE_STATUSES.PENDING) {
      setReviewsCount(ratingSummaryReviewsAll.total);
      setAvgRating(ratingSummaryReviewsAll.avgRating);
    } else if (!showAll && ratingSummaryReviews.success && status !== STATE_STATUSES.PENDING) {
      setReviewsCount(ratingSummaryReviews.total);
      setAvgRating(ratingSummaryReviews.avgRating);
    } else {
      setReviewsCount(null);
      setAvgRating(null)
    }
  }, [showAll, ratingSummaryReviewsAll, ratingSummaryReviews, status]);

  useEffect(() => {
    setShowAll(showAllReviews ? true : false);
  }, []);


  const handleSort = (id) => {
    setSortDirection(!sortDirection);

    setSortId(id);

    const sort = `${id}|${!sortDirection ? "asc" : "desc"}`;
    setQuery((prevState) => ({
      ...baseQuery,
      ...prevState,
      page: 1,
      sort,
    }));
  };

  const changePageHandler = (page) => {
    setQuery((prevState) => ({
      ...baseQuery,
      ...prevState,
      page,
    }));
  };

  const changeLimitHandler = (limit) => {
    setQuery((prevState) => ({
      ...baseQuery,
      ...prevState,
      page: 1,
      limit,
    }));
  };

  const showAllPopupHandler = () => {
    setShowAll((prevState) => !prevState);
    setQuery((prevState) => ({
      ...baseQuery,
      ...prevState,
      page: 1,
      coreRetailerIds: combinedCoreRetailers && combinedCoreRetailers.length > 0 ? combinedCoreRetailers.join("|") : id,
    }));
  };

  useEffect(() => {
    if (Object.entries(query).length) {
      if (showAll) {
        dispatch(
          fetchRatingSummaryReviewsAll({
            ...query,
            coreRetailerIds:
              combinedCoreRetailers && combinedCoreRetailers.length > 0 ? combinedCoreRetailers.join("|") : id,
          })
        );
      } else {
        dispatch(
          fetchRatingSummaryReviews({
            ...query,
            coreRetailerIds:
              combinedCoreRetailers && combinedCoreRetailers.length > 0 ? combinedCoreRetailers.join("|") : id,
          })
        );
      }
    }
  }, [query, showAll, dispatch]);

  return (
    <>
      <Styles color={primaryColor} topRowMargin={product.resultRatings ? product.resultRatings.length : 0}>
        <div className="popup-wrapper">
          <div className="popup-box">
            <img className="cancel" src={cancel2} alt="cancel" onClick={closePopup} />
            <div className="top-block">
              <div className="img-wrapper">
                <img src={product?.coreProduct?.image ? product?.coreProduct?.image : productImage} alt="" />
              </div>
              <div className="row">
                <div className="title-wrapper">
                  <span>{product?.coreProduct?.title}</span>
                  {product.resultRatings ? (
                    product.resultRatings.map((rating) => (
                      <div className="web-site">
                        <img src={getRemoteLogo(rating?.retailer?.name)} alt="" />
                        <a href={rating.href} target="_blank">
                          <span>See on Retailer Website</span>
                        </a>
                      </div>
                    ))
                  ) : (
                    <div className="web-site">
                      <img src={getRemoteLogo(product?.retailer?.name)} alt="" />
                      <a href={product.href} target="_blank">
                        <span>See on Retailer Website</span>
                      </a>
                    </div>
                  )}
                </div>
                <div className="review-wrapper">
                  <div className="avg-rating">
                    <span>Average Rating</span>
                    {avgRating ? (
                      <StarRatings
                        rating={avgRating}
                        starRatedColor="#F9C752"
                        numberOfStars={5}
                        name="rating"
                        isSelectable={false}
                        starDimension="18px"
                        starSpacing="2px"
                        starEmptyColor="#DDE4EC"
                      />
                    ) : (
                      <span className="no-reviews">No Reviews</span>
                    )}
                  </div>
                  <div className="avg-rating review">
                    <span>Reviews</span>
                    <span className="reviews-count">{reviewsCount}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="show-all-wrapper">
              <Styles>
                <div className="show-all-btn">
                  <Checkbox checked={showAll} onChange={showAllPopupHandler}>
                    Show All Reviews
                  </Checkbox>
                </div>
              </Styles>
            </div>
            <div className="table">
              <PopupRatingProductTable
                data={showAll ? ratingSummaryReviewsAll.reviews : ratingSummaryReviews.reviews}
                handleSort={handleSort}
                sortId={sortId}
                sortDirection={sortDirection}
                showRetailer={showRetailer}
              />
            </div>
            {reviewsCount > 0 ? (
              <div className="pagination-with-per-page">
                <Pagination
                  className="pagination-controls"
                  onChange={(page) => {
                    changePageHandler(page);
                  }}
                  current={query.page ? query.page : 1}
                  pageSize={query.limit ? query.limit : 10}
                  total={reviewsCount}
                  showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
                />
                <ShowPerPage setLimit={changeLimitHandler} value={query.limit ? query.limit : 10} />
              </div>
            ) : null}
            {status === STATE_STATUSES.PENDING && <LoaderBox />}
          </div>
        </div>
      </Styles>
    </>
  );
};

export default PopupRatingProduct;
