import { success, error } from "redux-saga-requests";
import {
  FETCH_RANKING_SEARCH_TERMS_LIST,
  FETCH_RANKING_RETAILERS_TREND,
  SET_PRISTINE_RETAILER_SEARCH,
  REMOVE_RETAILER_PRODUCT,
  FETCH_RETAILER_SINGLE_PRODUCT,
} from "./actions";
import { RESET_ERROR } from "store/error/actions";

import { STATE_STATUSES } from "utils/statuses";

const initialState = {
  rankingSearchTermsList: [],
  rankingRetailersTrend: {},
  retailerSingleProduct: [],
  status: STATE_STATUSES.INIT,
  isPristine: true,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RANKING_SEARCH_TERMS_LIST: {
      return processReducer(state);
    }
    case success(FETCH_RANKING_SEARCH_TERMS_LIST): {
      return {
        ...state,
        rankingSearchTermsList: action.data,
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }
    case error(FETCH_RANKING_SEARCH_TERMS_LIST): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case FETCH_RANKING_RETAILERS_TREND: {
      return processReducer(state);
    }
    case success(FETCH_RANKING_RETAILERS_TREND): {
      const retailerProducts = [];
      if (action.data.data.length) {
        action.data.data[0].retailers.forEach((item) =>
          retailerProducts.push({ id: item.id, name: item.title, data: [], itemStatus: STATE_STATUSES.INIT })
        );
      }

      return {
        ...state,
        rankingRetailersTrend: action.data,
        retailerSingleProduct: retailerProducts,
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }
    case error(FETCH_RANKING_RETAILERS_TREND): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case FETCH_RETAILER_SINGLE_PRODUCT: {
      const { retailer } = action.meta;

      const retailerProducts = state.retailerSingleProduct.map((item) => {
        if (item.name === retailer) {
          return {
            ...item,
            itemStatus: STATE_STATUSES.PENDING,
          };
        }

        return item;
      });

      return {
        ...state,
        retailerSingleProduct: retailerProducts,
      };
    }
    case success(FETCH_RETAILER_SINGLE_PRODUCT): {
      const { isSingle, retailer } = action.meta;
      const { products } = action.data;

      const retailerProducts = state.retailerSingleProduct.map((item) => {
        if (item.name === retailer) {
          return {
            ...item,
            data: isSingle ? [...item.data, ...products] : products,
            itemStatus: STATE_STATUSES.READY,
          };
        }

        return item;
      });

      return {
        ...state,
        retailerSingleProduct: retailerProducts,
      };
    }
    case error(FETCH_RETAILER_SINGLE_PRODUCT): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case REMOVE_RETAILER_PRODUCT: {
      const { uid, retailer } = action.payload;

      const retailerProducts = state.retailerSingleProduct.map((item) => {
        if (item.name === retailer) {
          return {
            ...item,
            data: uid ? item.data.filter((prod) => prod.uid !== uid) : [],
          };
        }

        return item;
      });

      return {
        ...state,
        retailerSingleProduct: retailerProducts,
      };
    }

    case SET_PRISTINE_RETAILER_SEARCH: {
      return {
        ...state,
        isPristine: action.payload,
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
