import React, { useState } from 'react'
import { Tabs } from 'antd'

import SearchAnalysisTab from 'tabs/RankingTabs/RankingBottomTabs/SearchAnalysisTab/SearchAnalysisTab'
import LocationAnalysisTab from 'tabs/RankingTabs/RankingBottomTabs/LocationAnalysisTab/LocationAnalysisTab'


import about from 'assets/images/about.svg'
import { TabWithIcon } from 'assets/icons/icons'

const RankingBottomTabs = ({ setActiveTab, isFeaturedProducts }) => {
    const { TabPane } = Tabs
    const [currentKey, setCurrentKey] = useState('1');

    function callback(key) {
        setCurrentKey(key);
        setActiveTab(key)
    }

    return (
        <div className="main-box">
            <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab={<TabWithIcon tabName={'Search Analysis'}
                    icon={about}
                    tooltip={'Overall the prices moved up 20% based on the selected filters.'}
                />} key="1">
                    <SearchAnalysisTab
                        isFeaturedProducts={isFeaturedProducts}
                        isActive={currentKey==='1'}
                    />
                </TabPane>
                <TabPane tab={<TabWithIcon tabName={'Location Analysis'}
                    icon={about}
                    tooltip={'Overall the prices moved up 20% based on the selected filters.'}
                />} key="2">
                    <LocationAnalysisTab
                        isFeaturedProducts={isFeaturedProducts}
                        isActive={currentKey==='2'}
                    />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default RankingBottomTabs
