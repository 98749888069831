import React, { useState, useEffect, useRef, useMemo } from "react";
import useColorTheme from "../../../../hooks/useColorTheme";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_rounded from "highcharts-rounded-corners";
import _ from "lodash";

import { changeNameRetailer } from "utils/changeNameRetailer";
import { priceRangeTimePeriod } from "utils/priceRangeTimePeriod";
import useRetailers from "../../../../hooks/useRetailers";

HC_rounded(Highcharts);

const PriceRangeChart = (props) => {
  const { data, maxTotalY, maxDiscountY, label, labelDiscount, isDiscount, isCompare, compareData, currentDate } =
    props;

  const inputRef = useRef(null);
  const { primaryColor } = useColorTheme();
  const [names, setNames] = useState([]);
  const [pricings, setPricings] = useState([]);
  const { getRemoteLogo, getRetailerColor } = useRetailers();

  const setCurrentData = () => {
    const sliceData = data.filter((item) => item.label !== "No. of Products");

    const names = sliceData.map((item) => item.label);
    setNames(names);

    const sumItems = sliceData.map((item) => item.total);

    let largeIndex = sumItems.indexOf(Math.max(...sumItems));

    const total = sliceData.map((item) => item.total - item.star);

    const star = sliceData.map((item) => item.star);

    const dataTotal = total.map((item, index) => {
      return {
        y: item,
        color: index === largeIndex ? getRetailerColor(names[index]) : "#a0a2a6",
      };
    });

    const dataStar = star.map((item, index) => {
      return {
        y: item,
        color: index === largeIndex ? getRetailerColor(names[index], '.7') : "rgba(160, 162, 166, .7)",
      };
    });

    const rangeItems = [
      {
        type: "column",
        name: "promoted",
        stack: "current",
        order: 2,
        orderTooltip: 2,
        yAxis: 0,
        data: dataStar,
        borderRadiusTopLeft: "40%",
        borderRadiusTopRight: "40%",
      },
      {
        type: "column",
        name: "no. of products",
        stack: "current",
        order: 2,
        orderTooltip: 1,
        yAxis: 0,
        data: dataTotal,
      },
    ];

    if (isDiscount) {
      const discount = sliceData.map((item) => item.discount);

      const itemsDiscount = {
        type: "line",
        name: "discount",
        color: primaryColor,
        stack: "current",
        order: 2,
        orderTooltip: 3,
        yAxis: 1,
        data: discount,
      };

      rangeItems.push(itemsDiscount);
    }

    setPricings(rangeItems);
  };

  useEffect(() => {
    if (data.length) {
      setCurrentData();
    }
  }, [data]);

  useEffect(() => {
    if (!isCompare) {
      setCurrentData();
    }
  }, [isCompare]);

  useEffect(() => {
    if (isDiscount) {
      const sliceData = data.filter((item) => item.label !== "No. of Products");
      const discount = sliceData.map((item) => item.discount);

      const itemsDiscount = [
        {
          type: "line",
          name: "discount",
          color: primaryColor,
          stack: "current",
          order: 2,
          orderTooltip: 3,
          yAxis: 1,
          data: discount,
        },
      ];

      if (compareData.length) {
        const sliceCompareData = compareData.filter((item) => item.label !== "No. of Products");

        const dataDiscount = sliceCompareData.map((item) => item.discount);

        const compareDiscount = {
          type: "line",
          dashStyle: "dash",
          name: "discount",
          stack: "compare",
          order: 1,
          orderTooltip: 3,
          color: "#7B7E96",
          yAxis: 1,
          data: dataDiscount,
        };

        itemsDiscount.push(compareDiscount);
      }

      setPricings((prevState) => [...prevState, ...itemsDiscount]);
    } else if (!isDiscount && pricings.length) {
      const hiddeDiscount = pricings.filter((item) => item.orderTooltip !== 3);

      setPricings(hiddeDiscount);
    }
  }, [isDiscount]);

  useEffect(() => {
    if (compareData.length) {
      const sliceCompareData = compareData.filter((item) => item.label !== "No. of Products");

      const sumItems = sliceCompareData.map((item) => item.total);

      let largeIndex = sumItems.indexOf(Math.max(...sumItems));

      const total = sliceCompareData.map((item) => item.total - item.star);

      const star = sliceCompareData.map((item) => item.star);

      const dataTotal = total.map((item, index) => {
        return {
          y: item,
          color: index === largeIndex ? getRetailerColor(names[index],'.5') : "rgba(160, 162, 166, .5)",
        };
      });

      const dataStar = star.map((item, index) => {
        return {
          y: item,
          color: index === largeIndex ? getRetailerColor(names[index], '.3') : "rgba(160, 162, 166, .3)",
        };
      });

      const compareItems = [
        {
          type: "column",
          name: "promoted",
          stack: "compare",
          order: 1,
          orderTooltip: 2,
          yAxis: 0,
          data: dataStar,
          borderRadiusTopLeft: "40%",
          borderRadiusTopRight: "40%",
        },
        {
          type: "column",
          name: "no. of products",
          stack: "compare",
          order: 1,
          orderTooltip: 1,
          yAxis: 0,
          data: dataTotal,
        },
      ];

      if (isDiscount) {
        const dataDiscount = sliceCompareData.map((item) => item.discount);

        const compareDiscount = {
          type: "line",
          dashStyle: "dash",
          name: "discount",
          stack: "compare",
          order: 1,
          orderTooltip: 3,
          color: "#7B7E96",
          yAxis: 1,
          data: dataDiscount,
        };

        compareItems.push(compareDiscount);
      }

      setPricings((prevState) => [...prevState, ...compareItems]);
    }
  }, [compareData]);

  const getPricings = useMemo(
    () => _.cloneDeep(pricings).sort((first, second) => first.order - second.order),
    [pricings]
  );

  const options = {
    chart: {
      width: 320,
      height: 300,
      type: "column",
      animation: false,
      style: {
        fontFamily: "Gilroy-Medium",
      },
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: names,
      labels: {
        useHTML: true,
        formatter: function () {
          return `<span class='image-chart-box'><img style="width: 25px;" src=${getRemoteLogo(this.value)}></img></span>`;
        },
        align: "center",
      },
      title: {
        text: null,
      },
    },
    yAxis: [
      {
        title: {
          text: "",
        },
        labels: {
          enabled: label,
        },
        max: maxTotalY,
        min: 0,
      },
      {
        opposite: true,
        title: {
          text: "",
        },
        labels: {
          enabled: labelDiscount,
        },
        max: maxTotalY,
        min: 0,
        gridLineWidth: 0,
      },
    ],
    plotOptions: {
      series: {
        animation: false,
        stacking: "normal",
        pointPadding: 0.2,
        states: {
          inactive: {
            opacity: 1,
          },
        },
        borderWidth: 0,
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
    },
    tooltip: {
      animation: false,
      shared: true,
      backgroundColor: null,
      borderWidth: 0,
      useHTML: true,
      hideDelay: 0,
      formatter: function () {
        let initialData = this.points
          .filter((item) => item.series.userOptions.stack === "current")
          .sort((first, second) => {
            return first.series.userOptions.orderTooltip - second.series.userOptions.orderTooltip;
          })
          .map((item, index, array) => {
            return `<div class='item-range'>
                ${!isCompare ? `<span>${item.series.userOptions.name}</span>` : ""}
                <span class='range-price'>${index === 0 ? item.y + array[1].y : item.y}${
              item.series.options.type === "line" ? "%" : ""
            }</span>
              </div>`;
          });
        initialData = initialData.join("");

        let dataCompare = this.points
          .filter((item) => item.series.userOptions.stack === "compare")
          .sort((first, second) => {
            return first.series.userOptions.orderTooltip - second.series.userOptions.orderTooltip;
          })
          .map((item, index, array) => {
            return `<div class='item-range'>
                  <span>${item.series.userOptions.name}</span>
                  <span class='range-price'>${index === 0 ? item.y + array[1].y : item.y}${
              item.series.options.type === "line" ? "%" : ""
            }</span>
                </div>`;
          });
        dataCompare = dataCompare.join("");

        return `<div class='range-tooltip'>
                  <div class='retailer'>${changeNameRetailer(this.x)}</div>
                  <div class='tooltip-box'>
                    ${
                      !isCompare
                        ? `
                        <div>
                            <div>${priceRangeTimePeriod(currentDate)}</div>
                            ${initialData}
                        </div>
                      `
                        : `
                        <div>
                          <div>Previous Period</div>
                          ${dataCompare}
                        </div>
                        <div class='separete-line'></div>
                        <div>
                            <div>${priceRangeTimePeriod(currentDate)}</div>
                            ${initialData}
                        </div>
                      `
                    }
                  </div>
              </div>`;
      },
    },
    exporting: { enabled: false },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    series: getPricings,
  };

  return (
    <div className="chart-price-wrapper" style={{ width: "100%" }}>
      <HighchartsReact ref={inputRef} highcharts={Highcharts} options={options} />
    </div>
  );
};

export default PriceRangeChart;
