const url = process.env.REACT_APP_URL;

export const FETCH_SHARE_PRODUCTS = "FETCH_SHARE_PRODUCTS";
export const fetchShareProducts = (data) => ({
  type: FETCH_SHARE_PRODUCTS,
  request: {
    url: `${url}/v2/navigation/share`,
    method: "POST",
    data,
  },
  meta: {
    asPromise: true,
  },
});

export const FETCH_SHARE_POPUP = "FETCH_SHARE_POPUP";
export const fetchSharePopup = (data) => ({
  type: FETCH_SHARE_POPUP,
  request: {
    url: `${url}/v2/navigation/popup`,
    method: "POST",
    data,
  },
  meta: {
    asPromise: true,
  },
});

export const SET_PRISTINE_SHARE_PRODUCTS = "SET_PRISTINE_SHARE_PRODUCTS";
export const setPristineShareProducts = (pristine) => {
  return {
    type: SET_PRISTINE_SHARE_PRODUCTS,
    payload: pristine,
  };
};
