import { Popover, Checkbox } from "antd";
import React from "react";
import styled from "styled-components";

const Styles = styled.div`
  .wrapper-discount {
    width: auto;
    background: #eef2f7;
    border: none;
    height: 44px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin-left: 15px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    font-family: "Gilroy-Medium";
    position: absolute;
    top: -64px;
    right: 0;
  }
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 50%;
    border: 1px solid #626e81ff;
    background-color: white;
    display: flex;
    justify-content: center;
  }
`;

const ShowAllTrendAnalysis = ({ icon, onChangeHandler, value, disabled, text }) => {
  const content = (
    <div>
      <p style={{ display: "flex", justifyContent: "center", width: "180px", height: "85px", textAlign: "center" }}>
        Trend graph displays time period last selected in global filter. Ratings and reviews display all time.
      </p>
    </div>
  );

  return (
    <Styles>
      <div className="wrapper-discount">
        <div className="icon">
          {icon && (
            <Popover content={content} placement="left">
              <img src={icon} alt="Icon" />
            </Popover>
          )}
        </div>
        <Checkbox checked={value} onChange={() => onChangeHandler({ showAll: !value })} disabled={disabled}>
          {text ? text : "Show All Reviews"}
        </Checkbox>
      </div>
    </Styles>
  );
};

export default ShowAllTrendAnalysis;
