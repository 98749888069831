import { success, error } from "redux-saga-requests";
import { FETCH_MEDIA_SUMMARY_TOP, SET_MEDIA_SUMMARY_TOP_FILTER, SET_PRISTINE_MEDIA_SUMMARY_TOP } from "./actions";
import { STATE_STATUSES } from "utils/statuses";
import { RESET_ERROR } from "store/error/actions";

const defaultFilter = [
  {
    name: "byRetailer",
    options: [
      { lable: "By Retailer", value: "byRetailer" },
      { lable: "By Brand", value: "byBrand" },
    ],
    value: "byRetailer",
  },
];

const initialState = {
  mediaSummaryTop: {
    success: false,
    total: {
      banners: 0,
      live: 0,
    },
    result: [],
  },
  filter: localStorage.getItem(SET_MEDIA_SUMMARY_TOP_FILTER)
    ? JSON.parse(localStorage.getItem(SET_MEDIA_SUMMARY_TOP_FILTER))
    : defaultFilter,
  status: STATE_STATUSES.INIT,
  isPristine: true,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MEDIA_SUMMARY_TOP: {
      return processReducer(state);
    }
    case success(FETCH_MEDIA_SUMMARY_TOP): {
      return {
        ...state,
        mediaSummaryTop: { ...action.data, success: true },
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }
    case error(FETCH_MEDIA_SUMMARY_TOP): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case SET_PRISTINE_MEDIA_SUMMARY_TOP: {
      return {
        ...state,
        isPristine: action.payload,
      };
    }

    case SET_MEDIA_SUMMARY_TOP_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
