import { COLORS } from "assets/colors/colors";
import styled from "styled-components";

export const Styles = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;

  .content-box {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 145px;
    background-color: ${COLORS.lightGrayOpacity};
    padding: 19px 16px 0;
    max-width: 100%;
  }
  .header {
    display: flex;
    align-content: center;
    position: relative;
  }
  .sort-btn {
    cursor: pointer;
  }
  .content-header {
    font-size: 14px;
    font-weight: 800;
    font-family: "Gilroy-ExtraBold";
    margin-top: 3px;
    margin-left: 5px;
  }
  .sort-btns {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .sort-btn-up {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 12px;
  }
  .sort-btn-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 12px;
  }
`;
