import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import MainFilters from "components/Filters/MainFilters";
import NavBar from "components/NavBar";
import SettingsLeftTabs from "tabs/SettingsTabs/SettingsLeftTabs";
import { STATE_STATUSES } from "utils/statuses";
import LoaderBox from "components/LoaderBox";
import { setPristineSettingsProfile } from "store/settings/settingsYourProfile/actions";
import { setPristineSettings } from "store/settings/actions";
import { setPristineSettingsSubscriptions } from "store/settings/settingsSubscriptions/actions";
import useFilters from "../hooks/useFilters";

const Settings = () => {
  //Selectors
  const { status: filtersStatus } = useSelector((state) => state.filters);
  const { status: authStatus } = useSelector((state) => state.authorization);
  const { universalFiltersStatus } = useFilters();

  //States
  const [activeTab, setActiveTab] = useState("0");

  //Constants
  const dispatch = useDispatch();

  const getActionTop = useCallback((value) =>
    [
      setPristineSettingsProfile(value),
      () => {},
      () => {},
      () => {},
      setPristineSettingsSubscriptions(value),
      setPristineSettings(value),
    ][+activeTab]
    ,[activeTab]);


  const getPristineTop = +activeTab > 0 ? false : true;

  useEffect(() => {
    return () => {
      dispatch(getActionTop(getPristineTop));
    };
  }, []);

  useEffect(() => {
    if (filtersStatus === STATE_STATUSES.PENDING || authStatus === STATE_STATUSES.PENDING) {
      dispatch(getActionTop(true));
    }
  }, [filtersStatus, authStatus, dispatch, getActionTop]);

  return (
    <div className="main-page-wrapper">
      <NavBar />
      <div className="main-page-box">
        <MainFilters />
        <div className="main-title-page">Settings</div>
        <div className="main-tabs-box">
          <SettingsLeftTabs activeTab={activeTab} setActiveTab={setActiveTab} />
        </div>
      </div>
      {universalFiltersStatus !== STATE_STATUSES.READY ? (
        <LoaderBox isOpacity={false} />
      ) : null}
    </div>
  );
};

export default Settings;
