import React, { useState } from "react";
import { Popover } from "antd";
//Components
import { Styles } from "./styles";
import RenderNoData from "components/RenderNoData";
//Utils
import useRetailers from "../../../../../hooks/useRetailers";
import { STATE_STATUSES } from "utils/statuses";
//Images
import search from "assets/images/search.svg";
import sort from "assets/images/sort.svg";
import sortIconDown from "assets/images/sort_down.svg";
import sortIconUp from "assets/images/sort_up.svg";
import productImage from "assets/images/testImage.png";

const KeyContentTable = (props) => {
  const { status, data, sortId, sortDirection, handleSearch, handleSort, keyFilter, searchState } = props;

  const isTitle = keyFilter[0].value === "title";
  const cellLengthForImg = 116;
  const [image, setImage] = useState("");
  const { getRemoteLogo } = useRetailers();

  const renderCellItem = (el) => {
    return data.retailers.map(({ name }, index) => {
      const matchedEl = Object.entries(el.retailers).find((item) => item[1].name === name);
      const arrOfTitleStrings = el.title.split(" ");
      const arrOfProductStrings = matchedEl[1].product.split(" ");

      if (isTitle && !matchedEl[1].product.length) {
        return (
          <div key={index} className="table-item-key-content">
            <div style={{ justifyContent: "center" }}>
              <span className="not-listed">Not Seen</span>
            </div>
          </div>
        );
      }
      if (isTitle && matchedEl[1].product.length && status === STATE_STATUSES.READY) {
        return (
          <div key={index} className="table-item-key-content">
            <div className="table-item-key-title">
              {/* <span>{matchedEl[1].product}</span> */}
              <div>
                {arrOfProductStrings.map((el, i) => {
                  const ifIncluded = arrOfTitleStrings.includes(el);
                  return (
                    <span key={i} className={!ifIncluded ? "table-item-title-highlighted" : null}>
                      {" "}
                      {el}{" "}
                    </span>
                  );
                })}
              </div>
              <span></span>
            </div>
          </div>
        );
      }
      if (!isTitle && !matchedEl[1].product.length && status === STATE_STATUSES.READY) {
        return (
          <div key={index} className="table-item-key-content" style={{ maxWidth: 116 }}>
            <div className="img-wrapper">
              <span className="not-listed">Not Seen</span>
            </div>
          </div>
        );
      }
      if (!isTitle && matchedEl[1].product.length && status === STATE_STATUSES.READY) {
        return (
          <Popover content={popupContent} title="" trigger="hover" align={{ offset: [0, 25] }} overlayStyle={{ width: 256 }} key={index}>
            <div className="table-item-key-content" onMouseOver={() => setImage(matchedEl[1].product)} style={{ maxWidth: 116 }}>
              <div className="img-wrapper">
                <img src={matchedEl[1].product.startsWith("https") ? matchedEl[1].product : productImage} />
              </div>
            </div>
          </Popover>
        );
      }
    });
  };

  const popupContent = () => {
    return (
      <div className="popup-with-img">
        <img src={image.startsWith("https") ? image : productImage} />
      </div>
    );
  };

  return (
    <Styles>
      <div className="current-table-box">
        {data.success ? (
          <div className="wrapper-table-box">
            <div className="wrapper-table">
              <div className="box-wrapper">
                <div className="title-search">
                  <div className="search">
                    <img src={search} alt="search" />
                    <input onChange={(e) => handleSearch(e.target.value)} placeholder="Search for a Product" value={searchState} />
                    <img
                      className="search-sort-btn"
                      src={sortId === "productNameOrder" ? (sortDirection ? sortIconUp : sortIconDown) : sort}
                      onClick={() => handleSort("productNameOrder")}
                      alt="sort"
                    />
                  </div>
                </div>
                <div
                  className="wrapper"
                  style={{
                    width: !isTitle ? data.retailers.length * cellLengthForImg : null,
                  }}
                >
                  {data.products.length
                    ? data.retailers.map((item, index) => (
                        <div
                          className="table-item sort"
                          style={{ flex: isTitle ? "0 0 366px" : "0 0 116px" }}
                          onClick={() => handleSort(item.id)}
                          key={index}
                        >
                          <img src={getRemoteLogo(item.name)} alt="retailer" />
                          <img src={sortId === item.id ? (sortDirection ? sortIconUp : sortIconDown) : sort} alt="sort" />
                        </div>
                      ))
                    : null}
                </div>
              </div>
            </div>
            <div className="wrapper-table">
              <div className="wrapper-box-relative">
                {data.products.length ? (
                  data.products.map((data, index) => (
                    <div className="box-wrapper" key={index}>
                      <div className="title">
                        <img className="image" src={data.image.startsWith("https") ? data.image : productImage} alt="banner" />
                        <span>{data.title}</span>
                      </div>
                      <div className="wrapper">{renderCellItem(data)}</div>
                    </div>
                  ))
                ) : (
                  <RenderNoData />
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </Styles>
  );
};

export default KeyContentTable;
