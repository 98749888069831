import moment from "moment";

export const START = "start";
export const END = "end";
export const PRICE_CUT = "Price Cut";
export const MULTIBUY = "Multibuy";
export const OTHER = "Other";

export const formatToStartEndValues = (arr, type, mechanic) => {
  const result = arr.map((el) => {
    return el.filter((item) => item[1] === true);
  });

  let pushArrStart = [];
  let pushArrEnd = [];
  for (let index = 0; index < result.length; index++) {
    if (type === START) {
      pushArrStart.push(result[index][0] || []);
    } else {
      pushArrEnd.push(result[index][result[index].length - 1] || []);
    }
  }

  if (type === START && !mechanic) {
    return pushArrStart.map((value) => formatDateToTimeStamp(value[0]));
  }
  if (type === END && !mechanic) {
    return pushArrEnd.map((value) => formatDateToTimeStamp(value[0]));
  }
  if (type === START && mechanic) {
    return pushArrStart;
  }
  if (type === END && mechanic) {
    return pushArrEnd;
  }
};

export const formatDateToTimeStamp = (string) => {
  return new Date(string).getTime();
};

export const formatToDate = (timeStamp) => {
  return moment(timeStamp).format("DD MMM YY");
};

export const formatToDateForPeriods = (timeStamp) => {
  return moment(timeStamp).format("M/D/YY");
};

export const formatToDateForDays = (timeStamp) => {
  return moment(timeStamp).format("YYYY-MM-DD");
};

export const getDiscount = (arr) => {
  const result = arr.length ? arr.filter((x) => x !== 0) : arr;

  if (result.length) {
    const data = +(result.reduce((a, b) => a + b, 0) / result.length).toFixed(1);
    return data;
  } else return 0;
};

export const getSplitPromotions = (childrens, type) => {
  const item = childrens.map(({ promotions }) => promotions.filter(({ mechanic }) => mechanic === type).flat()).flat();
  const periods = item.map(({ period }) => Object.entries(period));
  let newPeriod = {};
  for (let index = 0; index < periods.length; index++) {
    periods[index].forEach((el) => {
      if (newPeriod[el[0]] === true) {
        newPeriod[el[0]] = true;
      } else {
        newPeriod[el[0]] = el[1];
      }
    });
  }

  var lastPromoStatus = false;
  const orderedPeriods = Object.keys(newPeriod)
    .sort((a, b) => moment(a, "MM/DD/YY") - moment(b, "MM/DD/YY"))
    .reduce((obj, key) => {
      if (!lastPromoStatus && newPeriod[key]) {
        // Split a NEW promo period
        lastPromoStatus = true;
        return [
          ...obj,
          {
            [key]: newPeriod[key],
          },
        ];
      }
      if (lastPromoStatus && newPeriod[key]) {
        return [
          ...obj.slice(0, -1),
          {
            ...obj.slice(-1)[0],
            [key]: newPeriod[key],
          },
        ];
      }
      lastPromoStatus = false;
      return obj;
    }, []);

  return orderedPeriods.map((subPeriod) => {
    const period = Object.keys(subPeriod).sort((a, b) => moment(a, "MM/DD/YY") - moment(b, "MM/DD/YY"));
    const periodStart = moment(period[0], "MM/DD/YY");
    const periodEnd = moment(period.slice(-1)[0], "MM/DD/YY");
    const subItem = item.filter((el) => {
      const elStart = moment(el.startDate, "MM/DD/YYYY");
      const elEnd = moment(el.endDate, "MM/DD/YYYY");
      return (
        (elStart.diff(periodStart) >= 0 && periodEnd.diff(elStart) >= 0) ||
        (elEnd.diff(periodStart) >= 0 && periodEnd.diff(elEnd) >= 0) ||
        (periodStart.diff(elStart) >= 0 && elEnd.diff(periodEnd) >= 0)
      );
    });

    const discountValues = subItem.map(({ discountValue }) => +discountValue);
    const discountValue = getDiscount(discountValues);
    const discountPercents = subItem.map(({ discountPercent }) => +discountPercent);
    const discountPercent = getDiscount(discountPercents);

    return { label: type, period: subPeriod, discountPercent, discountValue };
  });
};

export const getPromotions = (childrens, type) => {
  const item = childrens.map(({ promotions }) => promotions.filter(({ mechanic }) => mechanic === type).flat()).flat();
  const discountValues = item.map(({ discountValue }) => +discountValue);
  const discountValue = getDiscount(discountValues);
  const discountPercents = item.map(({ discountPercent }) => +discountPercent);
  const discountPercent = getDiscount(discountPercents);
  const periods = item.map(({ period }) => Object.entries(period));
  let newPeriod = {};

  for (let index = 0; index < periods.length; index++) {
    periods[index].forEach((el) => {
      if (newPeriod[el[0]] === true) {
        newPeriod[el[0]] = true;
      } else {
        newPeriod[el[0]] = el[1];
      }
    });
  }

  return { label: type, period: newPeriod, discountPercent, discountValue };
};

export const formatToPeriodObj = (period, min, max, type) => {
  let func = type === "days" ? formatToDateForDays : formatToDateForPeriods;
  const arrOfPeriods = Object.entries(period);
  const arrOfTruePeriods = Object.entries(period).filter((item) => item[1] === true);
  const arrOfTrueDates = arrOfTruePeriods.map((el) => formatDateToTimeStamp(el[0]));
  const arrOfDates = arrOfPeriods.map((el) => formatDateToTimeStamp(el[0]));

  const isInRange = arrOfTrueDates.some((el) => el >= min && el <= max);

  const dataObjsArr = isInRange
    ? arrOfDates.map((el) => {
        if (arrOfTrueDates.includes(el)) {
          return {
            [func(el)]: true,
          };
        } else {
          return {
            [func(el)]: false,
          };
        }
      })
    : [];

  //some data in reverse>>>Why?
  let newPeriod = {};

  dataObjsArr.forEach((el) => {
    return (newPeriod = { ...newPeriod, ...el });
  });

  return newPeriod;
};

export const formatToNewDaysObj = (daysObj, min, max) => {
  const arrOfDays = Object.entries(daysObj);
  const arrOfDates = arrOfDays.map((el) => formatDateToTimeStamp(el[0]));

  const dataObjsArr = arrOfDates.map((el) => {
    if (el >= min && el <= max) {
      return {
        [formatToDateForDays(el)]: true,
      };
    } else {
      return {
        [formatToDateForDays(el)]: false,
      };
    }
  });

  let newPeriod = {};

  dataObjsArr.forEach((el) => {
    return (newPeriod = { ...newPeriod, ...el });
  });

  return newPeriod;
};

export const getNewSplitPromotions = (children, type, min, max) => {
  const typeOfPromotions = getSplitPromotions(children, type);
  return typeOfPromotions.map((typeOfPromotion) => {
    const newPeriod = formatToPeriodObj(typeOfPromotion.period, min, max);
    return { ...typeOfPromotion, period: newPeriod };
  });
};

export const getNewPromotion = (children, type, min, max) => {
  const typeOfPromotion = getPromotions(children, type);
  const newPeriod = formatToPeriodObj(typeOfPromotion.period, min, max);
  const newTypeOfPromotion = { ...typeOfPromotion, period: newPeriod };
  return newTypeOfPromotion;
};

export const getPromotionsObjForRetailer = (brands) => {
  let newPeriod = {};
  for (let index = 0; index < brands.length; index++) {
    const brand = brands[index];

    for (let index = 0; index < brand.retailerPromotions.length; index++) {
      const promotions = brand.retailerPromotions[index];
      const periods = Object.entries(promotions.period);

      for (let index = 0; index < periods.length; index++) {
        periods.forEach((el) => {
          if (newPeriod[el[0]] === true) {
            newPeriod[el[0]] = true;
          } else {
            newPeriod[el[0]] = el[1];
          }
        });
      }
    }
  }
  return newPeriod;
};
