import { Button, Radio, Slider } from "antd";

import { Styles } from "./NumberRangeStyles";
import React, { useEffect, useRef, useState } from "react";
import { REVIEWS } from "constants/constants";
import { getInteger } from "utils/getInteger";
import { ArrowSelect } from "assets/icons/icons";
import useColorTheme from "../../../hooks/useColorTheme";

export default function NumberRangeFilter({
  setSelectValue,
  minMaxValues,
  disabled,
  title,
  hint,
  data,
  value,
  isTouchedHandler,
  label,
}) {
  const { primaryColor } = useColorTheme();
  //Refs
  const wrapperRef = useRef(null);
  //Constants
  const min = minMaxValues[0];
  const max = minMaxValues[1];
  const customTitle =
    value[0] === min && value[1] === max ? title : `${value[0] || 0} - ${value[1] || 0}` + (label ? ` ${label}` : "");

  //States
  const [showDropdown, setShowDropdown] = useState(false);
  const [sliderValue, setSliderValue] = useState([]);

  useEffect(() => {
    setSliderValue([value[0], value[1]]);
  }, [value]);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const onSliderChange = (numsArr) => {
    setSliderValue(numsArr);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      if (showDropdown) {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setShowDropdown(false);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
    }, [ref, showDropdown]);
  }
  useOutsideAlerter(wrapperRef);

  const applyFilter = () => {
    setSelectValue({ [data.name]: sliderValue });
    setShowDropdown(false);
    isTouchedHandler();
  };

  const resetAll = () => {
    setSelectValue({ [data.name]: minMaxValues });
    isTouchedHandler();
    setShowDropdown(false);
  };

  const inputChangeHandler = (e, index) => {
    const {
      target: { value },
    } = e;
    const val = value?.length ? value : "0";
    let iAmount = getInteger(val);
    // maximum of TaxiBadge
    if (iAmount > max) {
      iAmount = max;
    }

    if (index === 0) {
      setSliderValue([iAmount, sliderValue[1]]);
    } else {
      setSliderValue([sliderValue[0], iAmount]);
    }
  };

  return (
    <Styles color={primaryColor}>
      <div className="wrapper-compare" ref={wrapperRef}>
        <div
          className="compare-box"
          onClick={disabled ? () => {} : toggleDropdown}
          style={{ cursor: disabled ? "not-allowed" : "pointer" }}
        >
          <span className="compare-title">{customTitle}</span>

          <ArrowSelect />
        </div>
        {showDropdown ? (
          <div className="radioBox" style={{ left: data.name === REVIEWS ? 15 : null }}>
            <span className="hint">{hint}</span>

            <Slider max={max} min={0} range step={1} value={sliderValue} onChange={onSliderChange} />
            <div className="slider-values">
              <input className="values-container" value={sliderValue[0]} onChange={(e) => inputChangeHandler(e, 0)} />
              <div className="dash">-</div>
              <input className="values-container" value={sliderValue[1]} onChange={(e) => inputChangeHandler(e, 1)} />
            </div>
            <div className="btn-wrapper">
              <Button className="select" onClick={resetAll}>
                Reset Range
              </Button>
              <Button className="aplly-btn" onClick={applyFilter}>
                Apply
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    </Styles>
  );
}
