import { success, error } from "redux-saga-requests";
import {
  CREATE_SCHEDULED_REPORT,
  FETCH_SAVED_REPORTS,
  FETCH_SAVED_REPORT,
  DELETE_SAVED_REPORT,
  EDIT_SAVED_REPORT,
  DOWNLOAD_QUICK_REPORT,
  FETCH_ACTIVE_COMPANY_MEMBERS,
  FETCH_EXPORT_FILTERS,
  DOWNLOAD_SCHEDULED_REPORT,
  CLEAR_SAVED_REPORT,
  FETCH_DOWNLOAD_REPORTS_QUEUE,
  DOWNLOAD_REPORT_BY_KEY,
  ACK_REPORTS,
  CLEAR_DOWNLOADED_FILES,
  SET_SELECT_ALL_PRODUCTS,
  SET_SELECT_ALL_BRANDS,
} from "./actions";
import { STATE_STATUSES } from "utils/statuses";
import { RESET_ERROR } from "store/error/actions";

const initialState = {
  savedReports: [],
  downloadReports: [],
  savedReport: {},
  companyMembers: [],
  filters: {
    filters: {
      date: {},
      category: [],
      manufacture: [],
      productBrand: [],
      productGroup: { userProductGroup: [] },
      product: [],
    },
  },
  filtersStatus: STATE_STATUSES.INIT,
  status: STATE_STATUSES.INIT,
  error: null,
  selectAllProducts: true,
  selectAllBrands: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SAVED_REPORTS:
    case FETCH_SAVED_REPORT:
    case DELETE_SAVED_REPORT:
    case CREATE_SCHEDULED_REPORT:
    case EDIT_SAVED_REPORT:
    case FETCH_EXPORT_FILTERS:
    case DOWNLOAD_SCHEDULED_REPORT:
    case FETCH_DOWNLOAD_REPORTS_QUEUE:
    case DOWNLOAD_REPORT_BY_KEY:
    case ACK_REPORTS:
    case DOWNLOAD_QUICK_REPORT: {
      return processReducer(state);
    }
    case SET_SELECT_ALL_PRODUCTS: {
      return {
        ...state,
        selectAllProducts: action.payload !== undefined ? action.payload : state.selectAllProducts,
      };
    }
    case SET_SELECT_ALL_BRANDS: {
      return {
        ...state,
        selectAllBrands: action.payload !== undefined ? action.payload : state.selectAllBrands,
      };
    }
    case success(FETCH_SAVED_REPORTS): {
      return {
        ...state,
        savedReports: action.data,
        status: STATE_STATUSES.READY,
      };
    }
    case success(FETCH_DOWNLOAD_REPORTS_QUEUE): {
      return {
        ...state,
        files: action.data.files,
        downloadReports: action.data.queue || [],
        status: STATE_STATUSES.READY,
      };
    }
    case CLEAR_DOWNLOADED_FILES: {
      return {
        ...state,
        files: [],
      };
    }
    case success(ACK_REPORTS): {
      return {
        ...state,
        downloadReports: action.data.queue || [],
        status: STATE_STATUSES.READY,
      };
    }
    case success(FETCH_SAVED_REPORT): {
      return {
        ...state,
        savedReport: action.data,
        status: STATE_STATUSES.READY,
        selectAllProducts: action.data.data.filters.product.includes("All"),
        selectAllBrands: action.data.data.filters.productBrand.includes("All"),
      };
    }
    case success(DELETE_SAVED_REPORT): {
      return {
        ...state,
        savedReports: action.data,
        status: STATE_STATUSES.READY,
      };
    }
    case success(FETCH_ACTIVE_COMPANY_MEMBERS): {
      return {
        ...state,
        companyMembers: action.data.filter((member) => member.status == "active"),
      };
    }
    case success(DOWNLOAD_REPORT_BY_KEY): {
      return {
        ...state,
        downloadReports: action.data.queue || [],
        status: STATE_STATUSES.READY,
      };
    }
    case success(DOWNLOAD_SCHEDULED_REPORT): {
      return {
        ...state,
        downloadReports: action.data.queue || [],
        status: STATE_STATUSES.READY,
      };
    }
    case success(CREATE_SCHEDULED_REPORT): {
      return {
        ...state,
        savedReports: [...[action.data], ...state.savedReports],
        status: STATE_STATUSES.READY,
      };
    }
    case success(EDIT_SAVED_REPORT): {
      const { data } = action;

      const reports = [...state.savedReports];

      const foundIndex = reports.findIndex((obj) => obj.id === data.id);
      reports[foundIndex] = data;

      return {
        ...state,
        savedReports: reports,
        status: STATE_STATUSES.READY,
      };
    }
    case success(FETCH_EXPORT_FILTERS): {
      return {
        ...state,
        filters: action.data,
        filtersStatus: STATE_STATUSES.READY,
        status: STATE_STATUSES.READY,
      };
    }
    case success(DOWNLOAD_QUICK_REPORT): {
      return {
        ...state,
        downloadReports: action.data.queue || [],
        status: STATE_STATUSES.READY,
      };
    }
    case error(FETCH_SAVED_REPORTS):
    case error(FETCH_SAVED_REPORT):
    case error(DELETE_SAVED_REPORT):
    case error(CREATE_SCHEDULED_REPORT):
    case error(EDIT_SAVED_REPORT):
    case error(FETCH_DOWNLOAD_REPORTS_QUEUE):
    case error(DOWNLOAD_QUICK_REPORT): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    case CLEAR_SAVED_REPORT: {
      return {
        ...state,
        savedReport: {},
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
