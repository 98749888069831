import React, { useState, useEffect } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import useRetailers from "../../../../hooks/useRetailers";

const СurrentlyRetailerTerms = (props) => {
  const { data, currentRetailer } = props;

  const [dates, setDates] = useState([]);
  const [pricings, setPricings] = useState([]);
  const { getRetailerColorHex, getRetailerBackgroundColor } = useRetailers();

  const getMainColor = (retailer) => getRetailerColorHex(retailer);

  const getBackground = (retailer) => getRetailerBackgroundColor(retailer);

  useEffect(() => {
    if (Object.entries(data).length !== 0) {
      const pricings = [
        {
          name: data.title,
          color: getMainColor(data.title),
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: getBackground(data.title),
          },
          data: data.data.map((item) => Number(item.percent)),
        },
      ];

      const datesMoment = data.data.map((item) => moment(item.date).format("DD MMM YYYY"));

      setPricings(pricings);
      setDates(datesMoment);
    }
  }, [data]);

  const options = {
    title: "",
    chart: {
      type: "areaspline",
      reflow: true,
      height: 280,
    },
    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
      areaspline: {
        marker: {
          enabled: false,
        },
        lineWidth: 3,
        threshold: null,
      },
    },
    xAxis: {
      crosshair: {
        width: 1,
        color: "gray",
        dashStyle: "solid",
      },
      type: "datetime",
      labels: {
        step: parseInt(dates.length / 3),
        style: {
          fontSize: "10px",
        },
      },
      categories: dates,
    },
    yAxis: {
      title: {
        text: "",
      },
      labels: {
        formatter: function () {
          return `${this.value}%`;
        },
        style: {
          fontSize: "10px",
        },
      },
    },
    tooltip: {
      followPointer: false,
      shared: true,
      useHTML: true,
      backgroundColor: null,
      borderWidth: 0,
      formatter: function () {
        return `<div class="wrapper-category">
            <div class='title'>Search Trend</div>
            <div class='total-data'>${moment(this.points[0].key).format("DD MMM YY")}</div>
            <div class='wrapper'>
              <div class='box'>
                <div>${this.points[0].y}%</div>
              </div>
            </div>
          </div>`;
      },
    },
    legend: {
      enabled: false,
    },
    series: pricings,
  };

  return (
    <div className="total-retailer-chart">
      <div className="title-hint">
        You are currently viewing trend for <span style={{ textTransform: "capitalize" }}>{currentRetailer}</span>.
      </div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default СurrentlyRetailerTerms;
