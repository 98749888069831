import React from "react";
//utils
import { getImage } from "utils/getImage";
import useRetailers from "../../../../../../hooks/useRetailers";
//Icons
import sort from "assets/images/sort.svg";
import sortIconDown from "assets/images/sort_down.svg";
import sortIconUp from "assets/images/sort_up.svg";
//Components
import { Styles } from "./styles";
import RenderNoData from "components/RenderNoData";
import { sortAlphabetically } from "utils/generalUtils";

export const specifications = [
  { value: "Manufacturer", key: "manufacturers" },
  { value: "Retailer", key: "retailer" },
  { value: "Location", key: "location" },
  { value: "Thumbnail", key: "thumbnail" },
  { value: "No. of Products", key: "productCount" },
];

const ShareOfMediaTable = ({ handleSort, rows, sortId, sortDirection }) => {
  const { getRemoteLogo } = useRetailers();

  const renderCellItem = (el) => {
    return specifications.map(({ key }, index) => {
      return (
        <div key={index} className="table-item">
          <>
            {key === "manufacturers" ? (
              <div className="manufacturers">
                {sortAlphabetically(el.manufacturers, "name").map((manufacture, index) => {
                  const { name, color } = manufacture;
                  return (
                    <div className="manufacture" key={index}>
                      <div className="manufacture-color" style={{ background: color }}></div>
                      <div className="manufacture-name">{name}</div>
                    </div>
                  );
                })}
              </div>
            ) : key === "retailer" ? (
              <img src={getRemoteLogo(el?.retailer?.name)} alt="" />
            ) : key === "location" ? (
              <div className="location">
                <div className="location-icon">
                  <img src={getImage(el.location.type !== "search" ? "burger" : "search")} alt="" />
                </div>
                <div className="location-name">{el.location.category}</div>
              </div>
            ) : key === "thumbnail" ? (
              <div className="thumbnail-wrapper">
                {el.thumbnail?.length ? <img src={el.thumbnail} alt="" /> : <div className="no-iamge">No Image</div>}
              </div>
            ) : (
              <span>{el[key]}</span>
            )}
          </>
        </div>
      );
    });
  };
  return (
    <Styles>
      <div className="current-table-box">
        <div className="wrapper-table-box">
          <div className="wrapper-table">
            <div className="box-wrapper">
              <div className="wrapper">
                {rows.length
                  ? specifications.map((item, index) => {
                      const hideSortBtns = item.key === "thumbnail";
                      return (
                        <div className="table-item sort" key={index} onClick={hideSortBtns ? () => {} : () => handleSort(item.key)}>
                          <span className="">{item.value}</span>

                          {hideSortBtns ? null : (
                            <img src={sortId === item.key ? (sortDirection ? sortIconUp : sortIconDown) : sort} alt="sort" />
                          )}
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
          <div className="wrapper-table">
            <div className="wrapper-box-relative">
              {rows.length ? (
                rows.map((el, index) => (
                  <div className="box-wrapper" key={index}>
                    <div className="wrapper">{renderCellItem(el)}</div>
                  </div>
                ))
              ) : (
                <RenderNoData />
              )}
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default ShareOfMediaTable;
