import React, { useState, useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import HC_patternFill from "highcharts-pattern-fill";
import HC_rounded from "highcharts-rounded-corners";
import { formatToDate } from "utils/calendarPromotionsHelper";
import RenderNoData from "components/RenderNoData";

HC_rounded(Highcharts);
HC_patternFill(Highcharts);

const ShareOfMediaTopChart = ({ currentData, filter, setSelectedDate, selectedDate }) => {
  //Constants
  const TOTAL_SEACRH = "totalSearch";
  const TOTAL_LOCATION = "totalLocation";
  const MANUFACTURER_SEARCH = "manufacturerSearch";
  const MANUFACTURER_LOCATION = "manufacturerLocation";
  const twentyHoursInMls = 7.1e7;
  const sixHoursInMls = 2.16e7;
  const ref = useRef(null);

  //States
  const [dates, setDates] = useState([]);
  const [pricings, setPricings] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [manufacturerColor, setManufacturerColor] = useState("#fbc010");
  const [chartWidth, setChartWidth] = useState(0);

  useEffect(() => {
    pricings[0]?.data?.length * 25 > ref?.current?.offsetWidth
      ? setChartWidth(pricings[0]?.data?.length * 25 + 1000)
      : setChartWidth(ref?.current?.offsetWidth);
  }, [ref, pricings]);

  useEffect(() => {
    const days = currentData.days;

    if (currentData.manufacturers.length && days.length) {
      setIsEmpty(false);
      const datesMoment = days.map((item) => moment(item).valueOf());

      setDates(datesMoment);
      const selectedManufacture = currentData.manufacturers.find(({ id }) => id === filter) || { days: {}, color: "#fbc010" };

      if (Object.entries(selectedManufacture)) {
        setManufacturerColor(selectedManufacture.color);
      }

      const daysArr = Object.entries(selectedManufacture.days);

      const mathchedDate = datesMoment.find((date) => date === selectedDate) || [];
      let oneDayAfterTimeStamp, oneDayBeforeTimeStamp;
      if (mathchedDate) {
        oneDayAfterTimeStamp = moment(mathchedDate).add(1, "days").valueOf();
        oneDayBeforeTimeStamp = moment(mathchedDate).subtract(1, "days").valueOf();
      }

      const totalSearch = daysArr.map((el, i) => ({
        x: datesMoment[i],
        y: el[1].total.searchTermCount,
        type: TOTAL_SEACRH,
        total: el[1].total.total,
        share: el[1].share,
      }));
      const totalLocation = daysArr.map((el, i) => ({
        x: datesMoment[i],
        y: el[1].total.locationCount,
        type: TOTAL_LOCATION,
        total: el[1].total.total,
        share: el[1].share,
      }));

      const manufacturerSearch = daysArr.map((el, i) => ({
        x: datesMoment[i],
        y: el[1].manufacturer.searchTermCount,
        type: MANUFACTURER_SEARCH,
        manufacturer: el[1].manufacturer.total,
        share: el[1].share,
      }));

      const manufacturerLocation = daysArr.map((el, i) => ({
        x: datesMoment[i],
        y: el[1].manufacturer.locationCount,
        type: MANUFACTURER_LOCATION,
        manufacturer: el[1].manufacturer.total,
        share: el[1].share,
      }));

      setPricings([
        {
          type: "areaspline",
          name: "totalSearch",
          data: totalSearch,
          zoneAxis: "x",
          zones: [
            {
              value: oneDayBeforeTimeStamp + twentyHoursInMls,
              color: selectedDate ? "url(#custom-pattern-2)" : "url(#custom-pattern)",
            },
            {
              value: oneDayAfterTimeStamp - twentyHoursInMls,
              color: "url(#custom-pattern)",
            },
            {
              color: selectedDate ? "url(#custom-pattern-2)" : "url(#custom-pattern)",
            },
          ],
        },
        {
          type: "areaspline",
          name: "totalLocation",
          data: totalLocation,
          zoneAxis: "x",
          zones: [
            {
              value: oneDayBeforeTimeStamp + twentyHoursInMls,
              color: selectedDate ? "rgba(238,238,242, .3)" : "rgba(238,238,242)",
            },
            {
              value: oneDayAfterTimeStamp - twentyHoursInMls,
              color: "rgba(238,238,242)",
            },
            {
              color: selectedDate ? "rgba(238,238,242, .3)" : "rgba(238,238,242)",
            },
          ],
        },
        {
          type: "column",
          name: "manufacturerSearch",
          data: manufacturerSearch,
          zIndex: 3,
          borderRadiusTopLeft: "40%",
          borderRadiusTopRight: "40%",
          color: selectedManufacture.color,
          opacity: 0.5,
        },
        {
          type: "column",
          name: "manufacturerLocation",
          data: manufacturerLocation,
          zIndex: 3,
          color: selectedManufacture.color,
        },
      ]);
    } else {
      setIsEmpty(true);
    }
  }, [currentData, filter, selectedDate]);

  const options = {
    title: "",
    defs: {
      patterns: [
        {
          id: "custom-pattern",
          path: {
            d: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
            stroke: "rgba(218, 221, 236, 1)",
            strokeWidth: 3,
          },
        },
        {
          id: "custom-pattern-2",
          path: {
            d: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
            stroke: "rgba(218, 221, 236, .3)",
            strokeWidth: 3,
          },
        },
      ],
    },
    chart: {
      animation: false,
      height: 300,
      width: chartWidth,
      style: {
        fontSize: "10px",
        fontFamily: "Gilroy-Medium",
      },
    },
    plotOptions: {
      series: {
        animation: false,
        pointWidth: 12,
        groupPadding: 0.2,
        point: {
          events: {
            click: function () {
              setSelectedDate(this.category);
            },
          },
        },
        borderWidth: 0,
        stacking: "normal",
        label: {
          connectorAllowed: false,
        },
        states: {
          hover: {
            enabled: false,
          },
        },

        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
      areaspline: {
        marker: {
          enabled: false,
        },

        lineWidth: 0,
      },
      column: {},
    },
    xAxis: {
      min: dates[0] - sixHoursInMls,
      max: dates[dates.length - 1] + sixHoursInMls,
      tickPositions: dates,

      crosshair: {
        width: 2,
        color: "#384493",
        dashStyle: "solid",
        zIndex: 10,
      },

      type: "datetime",
      labels: {
        step: parseInt(dates.length / 6),
        formatter: function () {
          return formatToDate(this.value);
        },
        style: {
          fontSize: "10px",
        },
      },
      style: {
        fontFamily: "Gilroy-Medium",
      },

      categories: dates,
    },

    yAxis: {
      title: {
        offset: 15,
        x: 25,
        text: "No. of Banners",
      },
      offset: 30,

      gridZIndex: 2,
      gridLineColor: "#f0f0f2",
      gridLineWidth: 1,
      labels: {
        formatter: function () {
          return this.value;
        },

        style: {
          fontSize: "10px",
        },
      },
      style: {
        fontFamily: "Gilroy-Medium",
      },
    },
    tooltip: {
      shared: true,
      useHTML: true,
      backgroundColor: null,
      borderWidth: 0,
      formatter: function () {
        return renderTooltip(this);
      },
    },
    legend: {
      enabled: false,
    },
    series: pricings,
  };

  const renderTooltip = (thisObj) => {
    const selectedManufacture = currentData.manufacturers.find(({ id }) => id === filter);
    const totalSearch = thisObj?.points.find(({ point }) => point.options.type === TOTAL_SEACRH)?.point.options.y;
    const totalLocation = thisObj?.points.find(({ point }) => point.options.type === TOTAL_LOCATION)?.point.options.y;
    const manufacturerSearch = thisObj?.points.find(({ point }) => point.options.type === MANUFACTURER_SEARCH)?.point.options.y;
    const manufacturerLocation = thisObj?.points.find(({ point }) => point.options.type === MANUFACTURER_LOCATION)?.point.options.y;

    return `<div class="share-tooltip-wrapper">
        <div class="title">${selectedManufacture?.name}</div>
        <div class="date">${formatToDate(thisObj.x)}</div>
        <div class="row">
          <span>Share of Media</span>
          <span>${thisObj.points[0]?.point.options.share}%</span>
        </div>
        <div class="separator"></div>
        <div class="row">
          <span>Total No. of Banners</span>
          <span>${thisObj.points[0]?.point.options.total}</span>
        </div>
        <div class="row">
          <div class="title-with-color">
            <div
              class="color"
              style="background: repeating-linear-gradient(45deg,#fff,#fff 1px,#DADDEC 0px, #DADDEC 4px)"
            ></div>
            <span>Search Term Banners</span>
          </div>

          <span>${totalSearch}</span>
        </div>
        <div class="row">
          <div class="title-with-color">
            <div class="color" style="background: #f2f2f4"></div>
            <span>Location Banners</span>
          </div>

          <span>${totalLocation}</span>
        </div>
        <div class="separator"></div>
        <div class="row">
          <span>My Banners</span>
          <span>${thisObj.points[3]?.point.options.manufacturer}</span>
        </div>
        <div class="row">
          <div class="title-with-color">
            <div
              class="color"
              style="background: ${manufacturerColor}; opacity: 0.5"
            ></div>
            <span>Search Term Banners</span>
          </div>

          <span>${manufacturerSearch}</span>
        </div>
        <div class="row">
          <div class="title-with-color">
            <div class="color" style="background: ${manufacturerColor}"></div>
            <span>Location Banners</span>
          </div>

          <span>${manufacturerLocation}</span>
        </div>
      </div>`;
  };

  return (
    <div className="chart-price-wrapper" style={{ width: "100%" }} ref={ref}>
      <div className="chart-title-desc">
        A total of {currentData.total.bannerCount} banners have been detected. Average time banners live{" "}
        <span style={{ fontWeight: 800, marginLeft: 4 }}>{currentData.total.avgLive} days.</span>
      </div>

      {isEmpty ? (
        <RenderNoData />
      ) : (
        <HighchartsReact highcharts={Highcharts} options={options} containerProps={{ className: "enableOverflow" }} />
      )}
    </div>
  );
};

export default ShareOfMediaTopChart;
