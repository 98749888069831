import React, { useState, useEffect, useRef, useCallback } from "react";
import { connect } from "react-redux";
//Actions
import { fetchTrend, fetchCompareTrend, clearCompareTrend } from "store/pricing/priceTrend/actions";
//Component
import ChartSingleLine from "components/Charts/ChartSingleLine";
import ChartMultiLine from "components/Charts/ChartMultiLine";
import TopMovers from "components/TopMovers";
import SelectBox from "components/Selects/SelectBox";
import CompareBox from "components/CompareBox";
import LoaderBox from "components/LoaderBox";
//Utils
import { STATE_STATUSES } from "utils/statuses";
import { getTimePeriod } from "utils/getTimePeriod";
import { getOverallChange } from "utils/getOverallChange";
import useFilters from "../../../../hooks/useFilters";

const selects = [
  {
    name: "price",
    default: "shelfPrices",
    options: [
      { lable: "Shelf Price", value: "shelfPrices" },
      { lable: "Base Price", value: "basePrices" },
      { lable: "Promoted Price", value: "promotedPrices" },
    ],
  },
  {
    name: "type",
    default: "byTotal",
    options: [
      { lable: "Total", value: "byTotal" },
      { lable: "By Retailer", value: "byRetailer" },
      { lable: "By Brand", value: "byBrand" },
      { lable: "By Category", value: "byCategory" },
      { lable: "By Manufacturer", value: "byManufacturer" },
      { lable: "By Custom Group", value: "byProductGroup" },
    ],
  },
];

const PriceTrendTab = (props) => {
  const { status, currentData, compareData, fetchTrend, fetchCompareTrend, clearCompareTrend, statusFilters, statusAuth } = props;
  const {
    setRefreshStatus,
    refreshStatus,
    lastFilter,
  } = useFilters();
  const [tabParams, setTabParams] = useState([
    {
      name: "price",
      value: "shelfPrices",
    },
    {
      name: "type",
      value: "byTotal",
    },
  ]);

  const [manyData, setManyData] = useState(false);

  const [names, setNames] = useState({
    byRetailer: {
      list: [],
      pristine: true,
    },
    byBrand: {
      list: [],
      pristine: true,
    },
    byCategory: {
      list: [],
      pristine: true,
    },
    byManufacturer: {
      list: [],
      pristine: true,
    },
    byProductGroup: {
      list: [],
      pristine: true,
    },
  });

  const [comparativeValue, setComparativeValue] = useState("");
  const fetchRef = useRef();

  useEffect(() => {
    if (statusFilters === STATE_STATUSES.READY && statusAuth === STATE_STATUSES.PENDING && status !== STATE_STATUSES.PENDING) {

      fetchRef.current = false;
    } else if ((statusFilters === STATE_STATUSES.READY && statusAuth === STATE_STATUSES.READY && !fetchRef.current) || refreshStatus === STATE_STATUSES.PENDING) {
      fetchRef.current = true;
      setRefreshStatus(STATE_STATUSES.READY);

      fetchTrend({
        sourceType: lastFilter.sourceType,
        product: lastFilter.product,
        timePeriod: getTimePeriod(lastFilter.date),
      });
    }
  }, [lastFilter, statusFilters, statusAuth, status, fetchTrend, refreshStatus, setRefreshStatus]);

  const handleComparativeValue = useCallback((value) => {
    setComparativeValue(value);
    if (value?.length) {
      fetchCompareTrend({
        sourceType: lastFilter.sourceType,
        product: lastFilter.product,
        timePeriod: value,
      });
    } else {
      clearCompareTrend();
    }
  },[lastFilter, fetchCompareTrend, clearCompareTrend])

  const setSelectValue = (values) => {
    const value = Object.keys(values);

    const updateData = tabParams.map((item) => {
      if (item.name === value[0]) {
        return {
          ...item,
          value: values[value],
        };
      }

      return item;
    });

    setTabParams(updateData);
  };

  const handleSetNames = (name, type) => {
    if (manyData) {
      setManyData(false);
    }

    const itemName = names[type];
    let updateName = {};

    if (itemName.list.includes(name)) {
      updateName = {
        ...itemName,
        list: itemName.list.filter((el) => el !== name),
        pristine: false,
      };
    } else {
      updateName = {
        ...itemName,
        list: [...itemName.list, name],
        pristine: false,
      };
    }

    setNames((prevState) => ({ ...prevState, [type]: updateName }));
  };

  const autoSetNames = (type, list) => {
    setNames((prevState) => ({ ...prevState, [type]: { list, pristine: true } }));
  };

  return (
    <>
      <div className="filters-box">
        <CompareBox setComparativeValue={handleComparativeValue} disabled={status === STATE_STATUSES.PENDING} />
        {selects.map((item, index) => (
          <SelectBox
            key={index}
            data={item}
            disabled={status === STATE_STATUSES.PENDING}
            setSelectValue={setSelectValue}
          />
        ))}
      </div>
      <div className="wrapper-box-relative">
        <div className="chart-wrapper-box">
          <div
            className="chart-price-wrapper"
            style={{ width: `${Boolean(comparativeValue?.length) ? "100%" : "calc(100% - 425px)"}` }}
          >
            <div className="chart-title-desc">
              {getOverallChange(currentData.overallChange, tabParams[0].value, [
                "Overall the prices",
                "moved up",
                "moved down",
              ])}
            </div>

            {tabParams[1].value === "byTotal" ? (
              <>
                {currentData[tabParams[1].value].priceChart.data.values.length ? (
                  <ChartSingleLine
                    title={"Average Price"}
                    priceParam={tabParams[0].value}
                    currentData={currentData["byTotal"].priceChart}
                    compareData={compareData["byTotal"].priceChart}
                    isCompare={Boolean(comparativeValue?.length)}
                    type={"pricing"}
                  />
                ) : null}
              </>
            ) : (
              <>
                {currentData[tabParams[1].value].priceChart.data.length ? (
                  <ChartMultiLine
                    title={"Average Price"}
                    priceParam={tabParams[0].value}
                    currentData={currentData[tabParams[1].value].priceChart.data}
                    compareData={compareData[tabParams[1].value].priceChart.data}
                    isCompare={Boolean(comparativeValue?.length)}
                    type={tabParams[1].value}
                    names={names}
                    autoSetNames={autoSetNames}
                    handleSetNames={handleSetNames}
                    manyData={manyData}
                    setManyData={setManyData}
                    status={status}
                  />
                ) : null}
              </>
            )}
          </div>
          {!comparativeValue?.length ? (
            <TopMovers
              currentChart={tabParams[1].value}
              priceParam={tabParams[0].value}
              topMovers={currentData[tabParams[1].value].topMovers}
            />
          ) : null}
        </div>
        {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    currentData: state.priceTrend.currentData,
    compareData: state.priceTrend.compareData,
    status: state.priceTrend.status,
    statusFilters: state.filters.status,
    statusAuth: state.authorization.status,
  }),
  { fetchTrend, fetchCompareTrend, clearCompareTrend }
)(PriceTrendTab);
