import { success, error } from "redux-saga-requests";
import { SET_SETTINGS_CHANGE_PASSWORD } from "./actions";
import { STATE_STATUSES } from "utils/statuses";

const initialState = {
  success: false,
  status: STATE_STATUSES.READY,
  isPristine: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SETTINGS_CHANGE_PASSWORD: {
      return processReducer(state);
    }
    case success(SET_SETTINGS_CHANGE_PASSWORD): {
      return { ...state, isPristine: true, status: STATE_STATUSES.READY, ...action.data };
    }
    case error(SET_SETTINGS_CHANGE_PASSWORD): {
      return errorReducer(state, action.error.response.data);
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  success: false,
  error: null,
});
