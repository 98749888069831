const url = process.env.REACT_APP_URL;

export const FETCH_DISCOUNT_CUT = "FETCH_DISCOUNT_CUT";
export const fetchDiscountCut = (params) => ({
  type: FETCH_DISCOUNT_CUT,
  request: {
    url: `${url}/v2/promotion/discount-cut`,
    method: "POST",
    data: params,
  },
});

export const SET_DISCOUNT_FILTER = "SET_DISCOUNT_FILTER";
export const setDiscountFilter = (filter) => {
  localStorage.setItem(SET_DISCOUNT_FILTER, JSON.stringify(filter));
  return {
    type: SET_DISCOUNT_FILTER,
    payload: filter,
  };
};

export const SET_PRISTINE_PROMOTIONS_DISCOUNT_CUT = "SET_PRISTINE_PROMOTIONS_DISCOUNT_CUT";
export const setPristinePromotionsDiscountCut = (pristine) => {
  return {
    type: SET_PRISTINE_PROMOTIONS_DISCOUNT_CUT,
    payload: pristine,
  };
};
