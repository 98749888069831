import React, { useEffect, useRef } from "react";
import useColorTheme from "../../../hooks/useColorTheme";

import { useState } from "react";

//Components
import { Styles } from "./styles";
import DownArrowIcon from "components/DownArrowIcon";
//import { COLORS } from "assets/colors/colors";
import { ArrowSelect } from "assets/icons/icons";

const filterValues = [
  { title: "Last 7 days", key: 7 },
  { title: "Last 14 days", key: 14 },
  { title: "Last 30 days", key: 30 },
  { title: "Last 90 days", key: 90 },
];

const DaysPeriodFilter = ({ disabled }) => {
  const { primaryColor } = useColorTheme();
  //Refs
  const wrapperRef = useRef(null);
  //States
  const [value, setValue] = useState({ title: "Last 7 days", key: 7 });
  const [showDropdown, setShowDropdown] = useState(false);
  const selectedStyles = {
    border: `2px solid ${primaryColor}`,
    color: primaryColor,
    fontWeight: "bold",
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      if (showDropdown) {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setShowDropdown(false);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
    }, [ref, showDropdown]);
  }
  useOutsideAlerter(wrapperRef);

  const isSelected = (key) => {
    return value.key === key ? true : false;
  };

  const setDate = (title, key) => {
    setValue({ title, key });
    setShowDropdown(false);
  };

  return (
    <Styles>
      <div className="wrapper-compare" ref={wrapperRef}>
        <div
          className="compare-box"
          onClick={disabled ? () => {} : toggleDropdown}
          style={{ cursor: disabled ? "not-allowed" : "pointer" }}
        >
          <span className="compare-title">{value.title}</span>

          <ArrowSelect />
        </div>
        {showDropdown ? (
          <div className="dropdown">
            <div className="dropdown-title">Relative</div>
            <div className="values-row">
              {filterValues.map(({ key, title }) => {
                return (
                  <div key={key} className="date-value" style={isSelected(key) ? selectedStyles : null} onClick={() => setDate(title, key)}>
                    {title}
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    </Styles>
  );
};

export default DaysPeriodFilter;
