import React, { useState } from "react";
import ShareOfMediaBottom from "./ShareOfMediaBottom";
import ShareOfMediaTop from "./ShareOfMediaTop";

const ShareOfMediaTab = () => {
  const [selectedDate, setSelectedDate] = useState();

  return (
    <div>
      <ShareOfMediaTop selectedDate={selectedDate} setSelectedDate={setSelectedDate} />

      <ShareOfMediaBottom selectedDate={selectedDate} />
    </div>
  );
};

export default ShareOfMediaTab;
