import React from "react";
import StarRatings from "react-star-ratings";
import { useSelector } from "react-redux";
import useColorTheme from "../../../hooks/useColorTheme";
import useRetailers from "../../../hooks/useRetailers";

//Components
import { Styles } from "./tableStyles";

//Images
import sort from "assets/images/sort.svg";
import sortIconDown from "assets/images/sort_down.svg";
import sortIconUp from "assets/images/sort_up.svg";
//Utils
import { formatDate } from "utils/formatDate";
import { specifications } from "utils/ratingHelper";

const PopupRatingProductTable = ({ data, handleSort, sortId, sortDirection, showRetailer }) => {
  const { primaryColor } = useColorTheme();
  const { getRemoteLogo } = useRetailers();

  const renderCellItem = (el) => {
    return specifications.map(({ key }, index) => {
      if (key === "retailer" && !showRetailer) {
        return null;
      } else {
        return (
          <div key={index} className={"table-item"}>
            <>
              {key === "retailer" && showRetailer ? (
                <img src={getRemoteLogo(el?.coreRetailer?.retailer?.name)} alt="" />
              ) : key === "rating" && el[key] ? (
                <StarRatings
                  rating={+el[key]}
                  starRatedColor="#F9C752"
                  numberOfStars={5}
                  name="rating"
                  isSelectable={false}
                  starDimension="18px"
                  starSpacing="2px"
                  starEmptyColor="#DDE4EC"
                />
              ) : key === "rating" && el[key] ? (
                <span className="no-reviews">No Reviews</span>
              ) : key === "date" ? (
                <span>{formatDate(el[key])}</span>
              ) : (
                <span>{el[key]}</span>
              )}
            </>
          </div>
        );
      }
    });
  };

  return (
    <Styles color={primaryColor}>
      <div className="current-table-box">
        <div className="wrapper-table-box">
          <div className="wrapper-table">
            <div className="box-wrapper">
              <div className={showRetailer ? "wrapper-with-retailer" : "wrapper"}>
                {specifications.map((item, index) => {
                  const hideSortBtns = item.key === "title" || item.key === "comment";
                  if (item.key === "retailer" && !showRetailer) {
                    return null;
                  } else {
                    return (
                      <div
                        className="table-item sort"
                        key={index}
                        onClick={hideSortBtns ? () => {} : () => handleSort(item.key)}
                        style={{ cursor: hideSortBtns ? "default" : "pointer" }}
                      >
                        <span className="">{item.value}</span>

                        {hideSortBtns ? null : (
                          <img src={sortId === item.key ? (sortDirection ? sortIconUp : sortIconDown) : sort} alt="sort" />
                        )}
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
          <div className="wrapper-table">
            <div className="wrapper-box-relative">
              {data?.length ? (
                data.map((el, index) => (
                  <div className="box-wrapper" key={index}>
                    <div className={showRetailer ? "wrapper-with-retailer" : "wrapper"}>
                      {renderCellItem(el)}
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-reviews">No Reviews</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default PopupRatingProductTable;
