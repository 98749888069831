import { success, error } from "redux-saga-requests";
import { FETCH_ASSORTMENT_SUMMARY_CALENDAR, ADD_TO_DATA_ASSORTMENT_SUMMARY_CALENDAR } from "./actions";
import { STATE_STATUSES } from "utils/statuses";
import { RESET_ERROR } from "store/error/actions";

const initialState = {
  assortmentSummaryCalendar: {
    success: false,
    days: [],
    result: [],
  },
  controlData: {
    success: false,
    days: [],
    result: [],
  },
  isPristine: true,
  status: STATE_STATUSES.INIT,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ASSORTMENT_SUMMARY_CALENDAR: {
      return processReducer(state);
    }
    case success(FETCH_ASSORTMENT_SUMMARY_CALENDAR): {
      const days = action.data.length ? Object.keys(action.data[0].days) : [];
      return {
        ...state,
        assortmentSummaryCalendar: { result: [...action.data], days, success: true },
        controlData: { result: [...action.data], days, success: true },
        status: STATE_STATUSES.READY,
        isPristine: false,
      };
    }

    case error(FETCH_ASSORTMENT_SUMMARY_CALENDAR): {
      const error = action.error.response;
      return errorReducer(state, error);
    }

    case ADD_TO_DATA_ASSORTMENT_SUMMARY_CALENDAR: {
      return {
        ...state,
        assortmentSummaryCalendar: {
          ...state.assortmentSummaryCalendar,
          ...action.payload,
        },
      };
    }

    case RESET_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const errorReducer = (state, error) => ({
  ...state,
  status: STATE_STATUSES.ERROR,
  isPristine: false,
  error,
});

const processReducer = (state) => ({
  ...state,
  status: STATE_STATUSES.PENDING,
  error: null,
});
