import React, { useEffect } from "react";

import Icon, { Icons } from "components/Icon/Icon";

import { Styles } from "../Styles";
import ProductItemCalendar from "./ProductItemCalendar";

import { changeNameRetailer } from "utils/changeNameRetailer";

const RetailersItemCalendar = ({ element, isAllCollapsed, index, clickedArray, setClickedArray, onOpen, isBrand }) => {
  const { children } = element;

  const isIncluded = (id) => {
    if (clickedArray.some((el) => el.id === id)) {
      return true;
    }
  };

  useEffect(() => {
    if (isAllCollapsed) {
      setClickedArray([]);
    }
  }, [isAllCollapsed]);

  const renderRetailers = (element, type, index) => {
    const {
      children,
      item: { name, color, id },
    } = element;
    const isBrands = type === "brands";

    const icon =
      !isBrand && isBrands
        ? Icons["placeholder"]
        : !isBrand && !isBrands
        ? Icons[name]
        : isBrand && isBrands
        ? Icons[name]
        : Icons["placeholder"];

    const backgroundColor =
      !isBrand && isBrands ? color : !isBrand && !isBrands ? null : isBrand && isBrands ? null : color;

    return (
      <div key={index}>
        <div className="retailer-item" onClick={() => onOpen(id, children, name, isBrands)}>
          <div className={`plus-btn-${type}`}>
            <span>{!isIncluded(id) ? "+" : "-"}</span>
          </div>
          <Icon
            icon={icon}
            width={24}
            heigth={24}
            style={{
              backgroundColor: backgroundColor,
              borderRadius: 2,
            }}
          />
          <div className="retailer-row">
            <div className="retailer-title">
              <span className="retailer-name">{changeNameRetailer(name)} </span>
              <span className="retailer-total">({children?.length})</span>
            </div>
          </div>
        </div>
        {isIncluded(id) && isBrands
          ? children.map((el, i) => {
              const isLastChild = i === children.length - 1;
              return (
                <div key={i}>
                  <ProductItemCalendar item={el} isLastChild={isLastChild} />
                </div>
              );
            })
          : null}
      </div>
    );
  };

  return (
    <Styles>
      {renderRetailers(element, "retailers", index)}
      {isIncluded(element.item.id)
        ? children.map((el, index) => {
            return renderRetailers(el, "brands", index);
          })
        : null}
    </Styles>
  );
};

export default RetailersItemCalendar;
