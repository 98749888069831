const url = process.env.REACT_APP_URL;

export const FETCH_CURRENT_CONTENT_TABLE = "FETCH_CURRENT_CONTENT_TABLE";
export const fetchCurrentContentTable = (params) => ({
  type: FETCH_CURRENT_CONTENT_TABLE,
  request: {
    url: `${url}/v2/content/table`,
    method: "POST",
    data: params,
  },
});

export const FETCH_CURRENT_CONTENT_POPUP = "FETCH_CURRENT_CONTENT_POPUP";
export const fetchCurrentContenPopup = (id) => ({
  type: FETCH_CURRENT_CONTENT_POPUP,
  request: {
    url: `${url}/v2/content/product/${id}`,
    method: "POST",
  },
});

export const SET_PRISTINE_CURRENT_CONTENT_TABLE = "SET_PRISTINE_CURRENT_CONTENT_TABLE";
export const setPristineCurrentContentTable = (pristine) => {
  return {
    type: SET_PRISTINE_CURRENT_CONTENT_TABLE,
    payload: pristine,
  };
};
