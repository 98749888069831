import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Checkbox, Tooltip } from "antd";

import { fetchRankingSearchOverview } from "store/ranking/rankingSearchOverview/actions";

import OverviewPieChart from "./OverviewPieChart";
import SelectBox from "components/Selects/SelectBox";
import LoaderBox from "components/LoaderBox";
import ChartLegends from "components/ChartLegends";
import useFilters from "../../../../hooks/useFilters";

import { getTimePeriodRanking } from "utils/getTimePeriodRanking";
import { STATE_STATUSES } from "utils/statuses";

import about from "assets/images/about.svg";
import { useRef } from "react";

const selects = [
  {
    name: "shareSize",
    default: "5",
    options: [
      { lable: "Share of Top 5", value: "5" },
      { lable: "Share of Top 10", value: "10" },
      { lable: "Share of Top 20", value: "20" },
    ],
  },
  {
    name: "type",
    default: "bySearchTerm",
    options: [
      { lable: "By Search Term", value: "bySearchTerm" },
      { lable: "By Retailer", value: "byRetailer" },
    ],
  },
];

const SearchOverviewTab = (props) => {
  const {
    status,
    rankingSearchOverview,
    fetchRankingSearchOverview,
    isFeaturedProducts,
    setIsFeaturedProducts,
    isActive,
  } = props;
  const { mainLastFilter: lastFilter, statusFilters } = useFilters();

  const [optionsTab, setOptionsTab] = useState({
    shareSize: "5",
    type: "bySearchTerm",
  });

  const [legends, setLegends] = useState([]);
  const [selectedLegend, setSelectedLegend] = useState([]);

  const [titlePopup, setTitlePopup] = useState("");

  const filterRef = useRef();

  const getRankingTop = () => {
    const rankingTop = {
      5: "rankingTop5",
      10: "rankingTop10",
      20: "rankingTop20",
    };

    return rankingTop[optionsTab.shareSize];
  };

  useEffect(() => {
    if (optionsTab.type === "bySearchTerm") {
      setTitlePopup("Search Share in");
    } else if (optionsTab.type === "byRetailer") {
      setTitlePopup("Retailer Share for");
    }
  }, [optionsTab]);

  const handleClickLable = (name) => {
    if (selectedLegend.includes(name)) {
      const items = selectedLegend.filter((item) => item !== name);
      setSelectedLegend(items);
    } else {
      setSelectedLegend((prevState) => [...prevState, name]);
    }
  };

  useEffect(() => {
    if (isActive && statusFilters === STATE_STATUSES.READY) {
      fetchRankingSearchOverview({
        sourceType: lastFilter.sourceType,
        timePeriod: getTimePeriodRanking(lastFilter.date),
        product: lastFilter.product,
        featured: isFeaturedProducts,
      });
    }

  }, [fetchRankingSearchOverview, lastFilter, isFeaturedProducts, isActive, statusFilters]);

  useEffect(() => {
    if (Object.entries(rankingSearchOverview).length !== 0) {
      const items = [];

      rankingSearchOverview[optionsTab.type][getRankingTop()].data.forEach((item) =>
        item.manufacturers.forEach((manufacturer) => items.push(manufacturer))
      );

      const dataArr = items.map((item) => [item.title, item]);

      const mapArr = new Map(dataArr);

      const legends = [...mapArr.values()];

      setLegends(legends);
    }
  }, [rankingSearchOverview, optionsTab]);

  const setSelectValue = (values) => {
    const value = Object.keys(values);

    setOptionsTab((prevState) => ({
      ...prevState,
      [value[0]]: values[value],
    }));
  };

  return (
    <div>
      <div className="filters-box">
        {selects.map((item, index) => (
          <SelectBox
            ref={index !== 0 ? filterRef : null}
            key={index}
            data={item}
            disabled={status !== STATE_STATUSES.READY}
            setSelectValue={setSelectValue}
          />
        ))}
      </div>

      <div className="wrapper-box-relative">
        {rankingSearchOverview[optionsTab.type] ? (
          <>
            <div className="sponsored-wrapper">
              <div className="title-hint">
                You are currently viewing
                <span> {rankingSearchOverview[optionsTab.type][getRankingTop()].searchTermsCount} Search Terms </span>
                across the selected Retailers.
              </div>
              <div className="onlyProducts right-wrapper">
                <Checkbox
                  checked={isFeaturedProducts}
                  disabled={status !== STATE_STATUSES.READY}
                  onChange={() => setIsFeaturedProducts(!isFeaturedProducts)}
                  style={{ width: filterRef?.current?.offsetWidth - 20 }} //minus 2 padding with 10 px
                >
                  Sponsored
                </Checkbox>
              </div>
            </div>

            <div className="wrapper-charts">
              {rankingSearchOverview[optionsTab.type][getRankingTop()].data.length ? (
                rankingSearchOverview[optionsTab.type][getRankingTop()].data
                  .sort((a, b) => a["title"].localeCompare(b["title"]))
                  .map((item, index) => (
                    <div className="chart-box" key={index}>
                      <div className="title">
                        <span>{item.title}</span>
                        {/* <Tooltip
                          color={"#fff"}
                          title={<div className="tooltip-piechart">{parseInt(item.manufacturers[0].percent / 10)}%</div>}
                        >
                          <img src={about} alt="icon" />
                        </Tooltip> */}
                      </div>
                      <OverviewPieChart
                        type={optionsTab.type}
                        name={item.title}
                        data={item.manufacturers}
                        titlePopup={titlePopup}
                        selectedLegend={selectedLegend}
                      />
                    </div>
                  ))
              ) : (
                <div className="chart-no-data static">Looks like we don't have data for this request</div>
              )}
            </div>
            {legends.length ? (
              <ChartLegends
                param={"title"}
                legends={legends}
                selectedLegends={selectedLegend}
                legendLength={10}
                setLegends={handleClickLable}
              />
            ) : null}
          </>
        ) : null}

        {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    rankingSearchOverview: state.rankingSearchOverview.rankingSearchOverview,
    status: state.rankingSearchOverview.status,
  }),
  { fetchRankingSearchOverview }
)(SearchOverviewTab);
