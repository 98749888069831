import React, { useState } from "react";
import useColorTheme from "../../hooks/useColorTheme";
import useRetailers from "../../hooks/useRetailers";
import useCountry, { NUMBER_TYPE } from "../../hooks/useCountry";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "antd";
import { Link } from "react-router-dom";
import { deleteAlert, getAllAlerts } from "store/alerts/actions";
import { CollapseClose, CollapseOpen } from "assets/icons/icons";
import { Pricing, Promotions, Availability, Content, Email, Sms, WhatsApp, Calendar } from "assets/icons/icons";

import deleteIcn from "assets/images/delete.svg";
import editIcn from "assets/images/edit.svg";
import categoryAlert from "assets/images/category-alert.svg";
import brandAlert from "assets/images/brand-alert.svg";

import moment from "moment";
import { flattenHistoricAlertBrands } from "utils/flattenHistoricAlertBrands";
import AlertMessage from "./components/AlertMessage";

const ItemAlert = ({ alert }) => {
  const { name, createdAt, pricing, promotion, availability, listing, sms, whatsApp, schedule } = alert;
  const { formatCurrencyNumber } = useCountry();

  const dispatch = useDispatch();
  const { primaryColor } = useColorTheme();
  const { getRemoteLogo } = useRetailers();
  const { filters, alerts } = useSelector((state) => state.alerts);

  const [isOpenBody, setIsOpenBody] = useState(false);
  const [isOpenHint, setIsOpenHint] = useState(false);
  const obsoleteAlertBrands = flattenHistoricAlertBrands(alerts, alert);

  const handleTogelleBody = () => {
    if (Object.entries(filters).length) {
      setIsOpenBody(!isOpenBody);
    }
  };

  const removerAlert = (id) => {
    dispatch(deleteAlert(id)).then(() => dispatch(getAllAlerts()));
    setIsOpenHint(false);
  };

  const renderCategories = (title, img, data) => (
    <div className="categories">
      <div className="title">
        <img src={img} alt="category" />
        <div>{title}</div>
      </div>
      {data.map((item, index) =>
        index <= 3 ? (
          <div key={index} className="category">
            <div className="color" style={{ background: item.color }}></div>
            <div className="name">{item.name}</div>
          </div>
        ) : null
      )}
      {data.length > 4 ? <div className="total">(+{data.length - 4})</div> : null}
    </div>
  );
  const renderBrands = (data, index, totalBrands) => (
    <div key={index} className="categories">
      {index <= 3 && (
        <div className="category">
          <div className="color" style={{ background: data.color }}></div>
          <div className="name">{data.name}</div>
        </div>
      )}
      {index === 3 && totalBrands > 4 && <div className="total">(+{totalBrands - 4})</div>}
    </div>
  );

  return (
    <div className="alert-item-wrapper">
      <div className="alert-header">
        <div className="name" onClick={handleTogelleBody}>
          {isOpenBody ? <CollapseOpen color={"#abaaba"} /> : <CollapseClose color={"#abaaba"} />}
          <span>{name}</span>
        </div>
        <div className="info">
          <div className="date">
            <div>Created</div>
            <div>{moment(createdAt).format("DD MMM YY")}</div>
          </div>
          <div className="control">
            <Popover
              content={
                <div className="delete-alert-hint">
                  <div className="title">Do you want to remove this alert?</div>
                  <div className="wrapper-btn">
                    <div className="cancel" onClick={() => setIsOpenHint(false)}>
                      Cancel
                    </div>
                    <div className="remove" onClick={() => removerAlert(alert.id)}>
                      Remove
                    </div>
                  </div>
                </div>
              }
              trigger="click"
              visible={isOpenHint}
              onVisibleChange={(visible) => setIsOpenHint(visible)}
            >
              <div>
                <img src={deleteIcn} alt="icon" />
              </div>
            </Popover>
            <Link to={`/alert/${alert.id}`}>
              <div>
                <img src={editIcn} alt="icon" />
              </div>
            </Link>
          </div>
        </div>
      </div>
      {isOpenBody ? (
        <div className="alert-body">
          <div className="body-header">
            <div className="filters-box-title">
              <span>Filters</span>
            </div>
            <div className="metrics-box-title">
              <span>Metrics</span>
            </div>
            <div className="delivery-box-title">
              <span>Delivery Channels</span>
            </div>
            <div className="schedule-box-title">
              <span>Schedule</span>
            </div>
          </div>
          <div className="body-body">
            <div className="filters-box-title">
              <div className="retailer">
                {filters.filters.sourceType
                  .filter((item) => alert.filters.retailer.includes(item.id))
                  .map((item, index) => (
                    <img key={index} src={getRemoteLogo(item.name)} />
                  ))}
              </div>
              {renderCategories(
                "Category",
                categoryAlert,
                filters.filters.category.filter((item) => alert.filters.category.includes(item.id))
              )}
              <div className="categories">
                <div className="title">
                  <img src={brandAlert} alt="category" />
                  <div>Brand</div>
                </div>
                {obsoleteAlertBrands.length > 0 && (
                  <AlertMessage
                    message={`It looks like this alert might be tracking some historic brands. Review your filter selection to find out more.`}
                    type="warning"
                  />
                )}
                {alert.brandObj.map((item, index, array) => renderBrands(item, index, array.length))}
              </div>
            </div>
            <div className="metrics-box-title">
              <div className="metrics-wrapper">
                {pricing && pricing.selected ? (
                  <div className="metric-box">
                    <div className="title-item">
                      <Pricing fill={primaryColor} />
                      <div>Pricing</div>
                    </div>
                    <div className="item-table">Changing greater than</div>
                    <div className="changeRange">
                      <div>
                        {formatCurrencyNumber(pricing.changeRange[0], NUMBER_TYPE.DECIMAL)}-
                        {formatCurrencyNumber(pricing.changeRange[1], NUMBER_TYPE.DECIMAL)}%
                      </div>
                      ,{"  "}
                      <div>
                        {formatCurrencyNumber(pricing.changeRangePercent[0], NUMBER_TYPE.CURRENCY)}-
                        {formatCurrencyNumber(pricing.changeRangePercent[1], NUMBER_TYPE.DECIMAL)}
                      </div>
                    </div>
                  </div>
                ) : null}

                {promotion && promotion.selected ? (
                  <div className="metric-box">
                    <div className="title-item">
                      <Promotions fill={primaryColor} />
                      <div>Promotions</div>
                    </div>
                    {promotion.mechanic.priceCut ? <div className="item-table">• Price cut</div> : null}
                    {promotion.mechanic.multiBuy ? <div className="item-table">• Multi Buy</div> : null}
                    {promotion.mechanic.other ? <div className="item-table">• Other</div> : null}
                    <div className="item-table" style={{ display: "flex" }}>
                      • Discount{" "}
                      <div className="discount-item">
                        {Number.parseFloat(promotion.discountRangePercent[0]).toFixed(1)}-
                        {Number.parseFloat(promotion.discountRangePercent[1]).toFixed(1)}%
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="metrics-wrapper">
                {availability && availability.selected ? (
                  <div className="metric-box">
                    <div className="title-item">
                      <Availability fill={primaryColor} />
                      <div>Availability</div>
                    </div>
                    {availability.available ? <div className="item-table">• Available</div> : null}
                    {availability.unavailable ? <div className="item-table">• Unavailable</div> : null}
                  </div>
                ) : null}
                {listing && listing.selected ? (
                  <div className="metric-box">
                    <div className="title-item">
                      <Content fill={primaryColor} />
                      <div>Listing Status</div>
                    </div>
                    {listing.newly ? <div className="item-table">• New Listing</div> : null}
                    {listing.deListed ? <div className="item-table">• Not Seen</div> : null}
                    {listing.reListed ? <div className="item-table">• Reseen</div> : null}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="delivery-box-title">
              <div className="title-item">
                <Email fill={primaryColor} />
                <div>Email</div>
              </div>
              {whatsApp ? (
                <div className="title-item">
                  <WhatsApp fill={primaryColor} />
                  <div>WhatsApp</div>
                </div>
              ) : null}
              {sms ? (
                <div className="title-item">
                  <Sms fill={primaryColor} />
                  <div>SMS</div>
                </div>
              ) : null}
            </div>
            <div className="schedule-box-title">
              <div className="title-item">
                <Calendar fill={primaryColor} />
                <div>{schedule.type}</div>
              </div>
              <div className="time">{moment(schedule.time).format("HH:mm")}</div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ItemAlert;
