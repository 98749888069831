import React from "react";

import PromotionsCalendar from "./PromotionsCalendar/PromotionsCalendar";

const PromotionsBottomTabs = () => {
  return (
    <div className="main-box">
      <PromotionsCalendar />
    </div>
  );
};

export default PromotionsBottomTabs;
