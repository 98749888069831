import React, { useEffect, useState } from "react";
import { Styles } from "./styles";
import search from "./../../../assets/images/search.svg";
import sortIcon from "./../../../assets/images/icons/sort-icon.png";
import sortUpIcon from "./../../../assets/images/icons/sort-up-icon.svg";
import sortDownIcon from "./../../../assets/images/icons/sort-down-icon.svg";
import productMinis from "./../../../assets/images/product-minis.png";
import { Select, Pagination } from "antd";
import { connect } from "react-redux";
import { fetchSettingsYourProducts } from "store/settings/actions";
import { STATE_STATUSES } from "utils/statuses";
import LoaderBox from "components/LoaderBox";
import ShowPerPage from "components/ShowPerPage";
import RenderNoData from "components/RenderNoData";
import ErrorRow from "components/ErrorRow";

const SettingsYourProducts = (props) => {
  const { fetchSettingsYourProducts, count, products, settingsStatus, error } = props;

  const { Option } = Select;

  const [paginationData, setPaginationData] = useState({
    perPage: 10,
    page: 1,
    order: "title",
    direction: "ASC",
    nameLike: "",
  });

  const [sortId, setSortId] = useState("title");

  useEffect(() => {
    fetchSettingsYourProducts(paginationData);
  }, [paginationData]);

  const handleSearch = (value) => {
    setPaginationData({
      ...paginationData,
      nameLike: value,
      page: 1,
    });
  };

  const onChangePage = (page) => {
    setPaginationData({
      ...paginationData,
      page: page,
    });
  };

  const handleSelectChange = (value) => {
    setPaginationData({
      ...paginationData,
      perPage: value,
      page: 1,
    });
  };

  const onChangeSortDirection = (value) => {
    const direction = paginationData.direction === "ASC" ? "DESC" : "ASC";
    setSortId(value);

    switch (value) {
      case "title":
        setPaginationData({
          ...paginationData,
          order: "title",
          direction,
        });
        break;
      case "brand":
        setPaginationData({
          ...paginationData,
          order: "brand",
          direction,
        });
        break;
    }
  };

  return (
    <Styles>
      {!!error ? <ErrorRow message={error?.message ? error.message : error} /> : null}

      <div className="text-container">
        Here are products assigned to your profile. You can recognise them in the platform thanks to blue text and indicator.
      </div>
      <div className="item-box header">
        <div className="col-1">
          <div className="item-text">
            <div className="search">
              <img className="search-icon" src={search} alt="search" />
              <input onChange={(e) => handleSearch(e.target.value)} placeholder="Search for a Product" />
              <img
                className="sort-icon"
                src={sortId !== "title" ? sortIcon : sortId === "title" && paginationData.direction === "ASC" ? sortUpIcon : sortDownIcon}
                alt="sort icon"
                onClick={() => onChangeSortDirection("title")}
              />
            </div>
          </div>
        </div>
        <div className="col-2">
          <div className="item-text item-brand">
            Brand
            <img
              className="icon-for-sort"
              src={sortId !== "brand" ? sortIcon : sortId === "brand" && paginationData.direction === "ASC" ? sortUpIcon : sortDownIcon}
              alt="sort icon"
              onClick={() => onChangeSortDirection("brand")}
            />
          </div>
        </div>
      </div>
      {products.length ? (
        products.map((item) => {
          return (
            <div key={item.id} className="item-box">
              <div className="col-1 item-product">
                <img src={item.image} alt="product-picture" />
                <div>
                  <div className="product-text-box-title">{item.title}</div>
                  {/* <div className="product-text-box-desc">
                    {item.description.length >= 200 ? item.description.substring(0, 199) + "..." : item.description}
                  </div> */}
                </div>
              </div>
              {item.productBrand ? (
                <div className="col-2 item-product">
                  <div className="colour" style={{ backgroundColor: item.productBrand.color }} /> {item.productBrand.name}
                </div>
              ) : (
                <div className="col-2 item-product">
                  <div className="colour" style={{ backgroundColor: "gray" }} /> undefined
                </div>
              )}
            </div>
          );
        })
      ) : (
        <RenderNoData />
      )}

      {settingsStatus === STATE_STATUSES.READY && count > 0 ? (
        <div className="pagination-with-per-page">
          <Pagination
            style={{ margin: "12px 0 2px 0" }}
            className="pagination-controls"
            onChange={onChangePage}
            current={paginationData.page}
            pageSize={paginationData.perPage}
            total={count}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
          />
          <ShowPerPage setLimit={handleSelectChange} value={paginationData.perPage} />
        </div>
      ) : null}

      {settingsStatus === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
    </Styles>
  );
};

export default connect(
  (state) => ({
    count: state.settings.settingsYourProducts.count,
    products: state.settings.settingsYourProducts.products,
    error: state.settings.settingsYourProducts.error,
    settingsStatus: state.settings.status,
  }),
  { fetchSettingsYourProducts }
)(SettingsYourProducts);
