import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form, Input, Button } from "antd";
import { changePassword } from "store/authorization/actions";
import logo from "../assets/images/general-logo-white.svg";

const ResetPassword = ({ history, match: { params } }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const onFinishChange = (values) => {
    setIsLoading(true);
    dispatch(changePassword({ password: values["password"] }, params.id)).then(() => {
      setIsLoading(false);
      history.push("/");
    });
  };

  const onFinishFailedChange = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="authorization-wrapper">
      <img className="logo" src={logo} alt="logo" />
      <div className="signin-box">
        <Form
          name="login"
          initialValues={{ remember: true }}
          onFinish={onFinishChange}
          onFinishFailed={onFinishFailedChange}
        >
          <div className="title">Create Password</div>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            dependencies={["password"]}
            rules={[
              { required: true, message: "Please confirm your password!" },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("The two passwords that you entered do not match!");
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Send
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default withRouter(ResetPassword);
