import React from "react";
import { Tooltip } from "antd";

import about from "assets/images/about.svg";

const PromotionsCalendarHeader = ({ header, avgDiscount, productsCount, suffix }) => {
  return (
    <div className="main-box-title">
      {header.length ? (
        <div className="calendar-title">
          <span>
            {header}
            {/* <Tooltip
              className="about-icon-tooltip"
              placement="topLeft"
              title={
                "Overall the prices moved up 20% based on the selected filters."
              }
            >
              <img src={about} className="about-icon" />
            </Tooltip> */}
          </span>
        </div>
      ) : null}

      <div className="calendar-title-desc">
        <span>
          Overall we found {productsCount} Products on Promotion, with an average discount of{" "}
          {avgDiscount}
          {suffix}, based on the selected filters.
        </span>
      </div>
    </div>
  );
};

export default PromotionsCalendarHeader;
