const url = process.env.REACT_APP_URL;

export const SET_SETTINGS_CHANGE_PASSWORD = "SET_SETTINGS_CHANGE_PASSWORD";
export const setSettingsChangePassword = (values) => ({
  type: SET_SETTINGS_CHANGE_PASSWORD,
  request: {
    url: `${url}/auth/changePassword`,
    method: "POST",
    data: values,
  },
  meta: {
    asPromise: true,
  },
});
