import LoaderBox from "components/LoaderBox";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useColorTheme from "../../../../../hooks/useColorTheme";
import useFilters from "../../../../../hooks/useFilters";

//Actions
import { fetchShareOfMediaTop, setFilterShareOfMediaTop } from "store/media/shareOfMediaTop/actions";
//Utils
import { getTimePeriod } from "utils/getTimePeriod";
import { STATE_STATUSES } from "utils/statuses";
import { formatToDate } from "utils/calendarPromotionsHelper";
//Components
import { Styles } from "./styles";
import SelectBox from "components/Selects/SelectBox";
import ShareOfMediaTopChart from "./components/ShareOfMediaTopChart";
import { Cancel } from "assets/icons/icons";

const ShareOfMediaTop = ({ selectedDate, setSelectedDate }) => {
  //Selectors
  const { shareOfMediaTop, filter, status } = useSelector((state) => state.shareOfMediaTop);
  const { mainLastFilter: lastFilter, statusFilters } = useFilters();
  const { primaryColor } = useColorTheme();

  //Const
  const dispatch = useDispatch();

  //States
  const [queryParams, setQueryParams] = useState({});
  const [manufacturers, setManufacturers] = useState({
    name: filter[0].name,
    options: [],
  });

  useEffect(() => {
    setQueryParams((prevState) => ({
      ...prevState,
      sourceType: lastFilter.sourceType,
      timePeriod: getTimePeriod(lastFilter.date),
      product: lastFilter.product,
    }));
    setSelectValue({ byManufacturer: null });
  }, [lastFilter]);

  useEffect(() => {
    if (Object.keys(queryParams).length && statusFilters === STATE_STATUSES.READY) {
      dispatch(fetchShareOfMediaTop(queryParams));
    }
  }, [queryParams, statusFilters, dispatch]);

  const setSelectValue = (values) => {
    const value = Object.keys(values);

    const updateData = filter.map((item) => {
      if (item.name === value[0]) {
        return {
          ...item,
          value: values[value[0]],
        };
      } else if (item.name === value[1]) {
        return {
          ...item,
          value: values[value[1]],
        };
      }

      return item;
    });
    setSelectedDate();
    dispatch(setFilterShareOfMediaTop(updateData));
  };

  useEffect(() => {
    if (shareOfMediaTop.manufacturers.length) {
      const options = shareOfMediaTop.manufacturers.map(({ name, id, color }) => ({
        lable: name,
        value: id,
        color,
      }));
      const filteredOptions = options.sort((a, b) => {
        if (a.lable < b.lable) {
          return -1;
        }
        if (a.lable > b.lable) {
          return 1;
        }
        return 0;
      });

      const defaultValue = getDefaultValue(filteredOptions);

      setManufacturers((prevState) => ({
        ...prevState,
        options,
        default: defaultValue,
      }));
      setSelectValue({ byManufacturer: defaultValue });
    } else {
      setManufacturers({ name: filter[0].name, options: [] });
      // setSelectValue({ byManufacturer: null });
    }
  }, [shareOfMediaTop]);

  const getDefaultValue = (arr) => {
    const belManufacture = arr.find((el) => el.lable === "Bel UK Ltd") || {};

    const defaultValue = Object.keys(belManufacture).length ? belManufacture.value : arr[0]?.value;
    return defaultValue;
  };

  const renderChart = () => {
    return (
      <ShareOfMediaTopChart
        currentData={shareOfMediaTop}
        filter={filter[0].value}
        setSelectedDate={setSelectedDate}
        selectedDate={selectedDate}
      />
    );
  };

  const renderSelectedEntity = () => {
    if (selectedDate) {
      return (
        <div className="filter" onClick={() => setSelectedDate()}>
          <span className="filter-title">{formatToDate(selectedDate)}</span>
          <Cancel fill={"#fff"} />
        </div>
      );
    }
  };

  return (
    <Styles color={primaryColor}>
      <div className="filters-box">
        {filter[0].value ? (
          <SelectBox
            filter={filter[0].value}
            data={manufacturers}
            setSelectValue={setSelectValue}
            disabled={status !== STATE_STATUSES.READY}
            // alignRight
          />
        ) : null}
        {renderSelectedEntity()}
      </div>
      <div className="wrapper-box-relative">
        {shareOfMediaTop.success ? <div className="chart-wrapper-box">{renderChart()}</div> : null}
        {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
      </div>
    </Styles>
  );
};

export default ShareOfMediaTop;
