import React from "react";

import RetailersItemCalendar from "components/CalendarItems/PromotionsCalendar/RetailersItemCalendar";
import RetailersItemCalendarRating from "components/CalendarItems/RatingCalendar/RetailersItemCalendar";
import { RATING } from "constants/constants";
import { Empty } from "antd";

const PromotionsCalendarRetailers = ({
  data,
  isDiscountInPercents,
  isBrand,
  clickedArray,
  setClickedArray,
  marginTop,
  isAllCollapsed,
  onOpen,
  collapseHandle,
  type,
  isEmpty,
}) => {
  const Component = type === RATING ? RetailersItemCalendarRating : RetailersItemCalendar;

  return (
    <div className="top-retailers">
      <div
        style={{ display: "flex", justifyContent: "space-between" }}
        className="collapse-all"
        onClick={collapseHandle}
      >
        <span style={{ visibility: isEmpty ? "hidden" : null, cursor: "pointer" }}>
          {!clickedArray.length ? "Expand All" : "Collapse All"}
        </span>
        {/* <span
          style={{
            fontFamily: "Gilroy-Regular",
            color: "#000000",
          }}
        >
          Avg
        </span> */}
      </div>
      {isEmpty ? (
        <div className="empty-wrapper">
          <div className="no-data-box">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        </div>
      ) : (
        <div className="retailers" style={{ marginTop: marginTop }}>
          {data.map((el, index) => {
            if (el.children.length) {
              return (
                <Component
                  element={el}
                  key={index}
                  isDiscountInPercents={isDiscountInPercents}
                  isAllCollapsed={isAllCollapsed}
                  index={index}
                  clickedArray={clickedArray}
                  setClickedArray={setClickedArray}
                  onOpen={onOpen}
                  isBrand={isBrand}
                />
              );
            }
          })}
        </div>
      )}
    </div>
  );
};

export default PromotionsCalendarRetailers;
