import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HC_rounded from "highcharts-rounded-corners";

import { Styles } from "./styles";
import { COLORS } from "assets/colors/colors";
import { OPPORTUNITES } from "utils/currentContentHelper";
import { firstCharToUpperCase } from "utils/generalUtils";

HC_rounded(Highcharts);

const Opportunities = ({ data, setSelectedFilter, selectedFilter }) => {
  const [pricings, setPricings] = useState([]);
  const filterSelected = Object.entries(selectedFilter).length ? true : false;

  const isIncuded = (name) => {
    if (selectedFilter.value === name) {
      return true;
    } else return false;
  };

  useEffect(() => {
    const pricing = data.map(({ name, score }) => {
      const capitalizeName = firstCharToUpperCase(name);

      return {
        name: capitalizeName,
        clickName: name,
        y: +score || 0,
        color: !filterSelected ? COLORS.yellow : isIncuded(capitalizeName) ? COLORS.yellow : COLORS.yellowOpacity,
      };
    });

    setPricings([
      {
        data: pricing,
        borderRadiusTopLeft: "40%",
        borderRadiusTopRight: "40%",
        color: COLORS.yellow,
      },
    ]);
  }, [data, selectedFilter]);

  const options = {
    chart: {
      type: "bar",
      margin: [0, 0, 0, 140],
      spacing: 0,
      backgroundColor: "transparent",
      height: data.length * 23,
    },
    title: {
      text: "",
    },

    xAxis: {
      tickInterval: 1,
      tickColor: "transparent",
      visible: true,
      useHTM: true,
      labels: {
        style: {
          fontSize: "14px",
          fontFamily: "Gilroy-Medium",
          color: "#000000",
        },
        formatter: function () {
          const text = pricings[0].data[this.value].name.includes("Secondary") ? "Secondary Images" : pricings[0].data[this.value].name;
          return `<span style="opacity: ${!filterSelected ? 1 : isIncuded(text) ? 1 : 0.6}">${text}</span>`;
        },
      },
      lineColor: "transparent",
    },
    yAxis: {
      visible: false,
      max: 100,
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        cursor: "pointer",
        borderWidth: 0,
        pointWidth: 6,

        states: {
          hover: {
            enabled: false,
            halo: null,
          },
          active: {
            opacity: 1,
          },
        },
        point: {
          events: {
            click: function () {
              clickHandler(this.clickName);
            },
          },
        },
      },
    },

    series: pricings,
  };

  const clickHandler = (name) => {
    if (selectedFilter.name === name) {
      setSelectedFilter({});
    } else {
      setSelectedFilter({ type: OPPORTUNITES, value: name });
    }
  };

  return (
    <Styles>
      <HighchartsReact containerProps={{ className: "chartOpportunites" }} highcharts={Highcharts} options={options} />
    </Styles>
  );
};

export default Opportunities;
