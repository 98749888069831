import React from "react";
import _ from "lodash";
import NameBox from "./components/NameBox";
import FiltersAlert from "./components/FiltersAlert";
import MetricsAlert from "./components/MetricsAlert";
import DeliveryAlert from "./components/DeliveryAlert";
import ScheduleAlert from "./components/ScheduleAlert";
import MessageBox from "./components/MessageBox";
import { alertTemplate } from "components/Alerts/alertTemplate";
import useColorTheme from "../../hooks/useColorTheme";
import useCountry from "../../hooks/useCountry";
import EmailInputs from "./components/EmailInputs";
import { useSelector } from "react-redux";
import { Checkbox } from "antd";

const AlertCruds = ({
  queryParams,
  setTextParams,
  setFiltersParams,
  setMetricsParamsSlider,
  setMetricsParamsCheckbox,
  setIsCheckMetric,
  toggleMetricsParams,
  setDeliveryParams,
  setScheduleType,
  setScheduleDays,
  setScheduleTime,
  setIsAllowEmpty,
  retailers,
  filters: { sourceType, category, manufacture, productBrand, product },
  errorField,
  disabled,
  emails,
  handleAddInput,
  handleChangeInput,
  handleDeleteInput,
}) => {
  const { primaryColor } = useColorTheme();
  const { currentCurrency, currentCurrencyName } = useCountry();
  const { email } = useSelector((state) => state.settingsYourProfile.settingsYourProfile);
  const { selectAllProducts, selectAllBrands } = useSelector((state) => state.alerts);

  return (
    <div>
      <div style={{ marginTop: "0" }} className="export-item alert">
        <div className="number">1</div>
        <div className="text">Alert Name</div>
      </div>
      <div className={errorField.isEmptyName ? "is-empty-field" : "alert-field"}>
        <div className="subLabel-alert">Enter name</div>
        <NameBox value={queryParams["name"]} setValue={(value) => setTextParams("name", value)} />
        {errorField.isEmptyName ? <div className="error-hint">This cannot be empty.</div> : null}
      </div>
      <div className="export-item alert">
        <div className="number">2</div>
        <div className="text">Filters</div>
      </div>
      <div className="subLabel-alert">
        Select Retailer, Category or Brand <span>(You have to select at least one option)</span>
      </div>
      <div className="selects-box">
        {alertTemplate(
          retailers?.length ? _.sortBy(sourceType, (item) => retailers.indexOf(item.id)) : sourceType,
          category,
          manufacture,
          productBrand,
          product,
          primaryColor,
          currentCurrencyName,
          currentCurrency
        ).filters.map((item, index) => {
          const selectAllProp =
            item.name === "productBrand" ? selectAllBrands : item.name === "product" ? selectAllProducts : false;
          return (
            <FiltersAlert
              hasAllCheckbox={item.name === "product" || item.name === "productBrand"}
              key={index}
              item={item}
              setFiltersParams={setFiltersParams}
              selectItems={queryParams[item.name]}
              selectAllProp={selectAllProp}
            />
          );
        })}
      </div>
      <div className="export-item alert">
        <div className="number">3</div>
        <div className="text">Metrics</div>
      </div>
      <div className="subLabel-alert">Select at least one metric</div>
      <div className="selects-box">
        {alertTemplate(null, null, null, null, primaryColor, currentCurrencyName, currentCurrency).metrics.map(
          (item, index) => (
            <MetricsAlert
              key={index}
              item={item}
              selectItems={queryParams[item.name]}
              setMetricsParamsSlider={setMetricsParamsSlider}
              setMetricsParamsCheckbox={setMetricsParamsCheckbox}
              isMetric={queryParams[item.name].selected}
              setIsCheckMetric={setIsCheckMetric}
              toggleMetricsParams={toggleMetricsParams}
              displayAsCheckbox={item.displayAsCheckbox}
            />
          )
        )}
      </div>
      <div className="export-item alert">
        <div className="number">4</div>
        <div className="text"> Delivery Channels</div>
      </div>
      <div className="subLabel-alert">Where would you like to be informed?</div>
      <div className="selects-box">
        {alertTemplate(null, null, null, null, primaryColor, currentCurrencyName, currentCurrency).delivery.map(
          (item, index) => (
            <DeliveryAlert key={index} item={item} value={queryParams[item.name]} setValue={setDeliveryParams} />
          )
        )}
      </div>
      <div style={{ marginTop: "24px" }} className="subLabel-alert">
        Change a message
      </div>
      <MessageBox value={queryParams["message"]} setValue={(value) => setTextParams("message", value)} />

      <div className="export-item alert">
        <div className="number">5</div>
        <div className="text">Schedule</div>
      </div>
      <div className="subLabel-alert">How often would you like to get an alert?</div>

      <ScheduleAlert
        type={queryParams.schedule["type"]}
        days={queryParams.schedule["days"]}
        time={queryParams.schedule["time"]}
        setType={setScheduleType}
        setDays={setScheduleDays}
        setTime={setScheduleTime}
        errorField={errorField}
        isDays={true}
      />
      <EmailInputs
        disabled={disabled}
        handleAddInput={handleAddInput}
        handleDeleteInput={handleDeleteInput}
        handleChangeInput={handleChangeInput}
        emails={emails}
        email={email}
      />
      <div className="export-item alert">
        <div className="number">6</div>
        <div className="text">Empty alerts</div>
      </div>
      <div className="subLabel-alert">Would you like to still receive alerts (empty) if there are no changes?</div>
      <div className="selects-box">
        {alertTemplate(null, null, null, null, primaryColor, currentCurrencyName, currentCurrency).empty.map((item) => (
          <Checkbox
            label="Receive empty alerts (no changes)"
            value={queryParams[item.name]}
            onChange={() => setIsAllowEmpty(item.name, !queryParams[item.name])}
          />
        ))}
      </div>
    </div>
  );
};

export default AlertCruds;
