import { useEffect, useState } from "react";
import { STATE_STATUSES } from "utils/statuses";
import { convertArrToString, convertRatingToString } from "utils/ratingHelper";
import { getTimePeriod } from "utils/getTimePeriod";

const useRatingFilters = (
  statusFilters,
  mainLastFilter,
  sortId,
  sortDirection,
  bottomFilter,
  filter,
  setClickedArray
) => {
  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    if (statusFilters !== STATE_STATUSES.READY) return;

    setClickedArray([]);
    const newQueryParams = {
      timePeriod: filter[1].value ? "0" : getTimePeriod(mainLastFilter.date),
      sourceType: mainLastFilter.sourceType,
      category: mainLastFilter.category,
      manufacture: mainLastFilter.manufacture,
      productBrand: mainLastFilter.productBrand,
      productGroup: mainLastFilter.productGroup,
      product: mainLastFilter.product,
      sort: `${sortId}|${sortDirection ? "asc" : "desc"}`,
      daysRange: "All",
      rating: convertRatingToString(bottomFilter[0].value),
      countRange: bottomFilter[1].value.length ? convertArrToString(bottomFilter[1].value) : "All",
    };

    if (JSON.stringify(newQueryParams) !== JSON.stringify(queryParams)) {
      setQueryParams(newQueryParams);
    }
  }, [statusFilters, mainLastFilter, sortId, sortDirection, bottomFilter, filter, setClickedArray, queryParams]);

  return [queryParams, setQueryParams];
};

export default useRatingFilters;
