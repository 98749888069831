import styled from 'styled-components'

export const Styles = styled.div`
    .price-range-wrapper {
        width: 100%;
        display: flex;
        overflow-x: auto;
        margin-top: 25px;
        &::-webkit-scrollbar {
            height: 5px;
        }
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        &::-webkit-scrollbar-thumb {
            background: #abaaba;
            border-radius: 4px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #807f97;
        }
        .priceRange-chart {
            margin-right: 25px;
        }
    }

    .wrapper-dscount {
        width: auto;
        background: #eef2f7;
        border: none;
        height: 44px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        margin-left: 15px;
        padding: 0 10px;
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        font-family: 'Gilroy-Medium';
    }

    .range-price-table {
        font-family: 'Gilroy-Medium';
        width: 100%;
        overflow-x: auto;
        margin-top: 45px;
        display: inline-flex;
        flex-direction: column;
        &::-webkit-scrollbar {
            height: 5px;
        }
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        &::-webkit-scrollbar-thumb {
            background: #abaaba;
            border-radius: 4px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #807f97;
        }
        .priceRange-chart {
            margin-right: 25px;
        }
        .price-range-header {
            display: inline-flex;
            align-items: stretch;
            padding: 20px 0px;
            border-bottom: 1px solid #e0e0e4;
            .header-item {
                cursor: pointer;
                display: flex;
                align-items: center;
                .retailer-img-rn {
                    cursor: pointer;
                    width: 172px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        width: 25px;
                    }
                }
            }
            .header-item.no-prod {
                width: 172px;
            }
            .header-item.price {
                width: 170px;
            }
        }
        .main-rows-box {
            display: inline-flex;
            align-items: stretch;
            font-family: 'Gilroy-Medium';
            font-size: 14px;
            .range-box {
                width: 170px;
                display: flex;
                align-items: center;
                padding-left: 10px;
                border-right: 1px solid #e0e0e4;
                border-bottom: 1px solid #e0e0e4;
            }
            .rows-box {
                display: flex;
                .item-box {
                    width: 172px;
                    display: flex;
                    justify-content: center;
                    border-right: 1px solid #e0e0e4;
                    border-bottom: 1px solid #e0e0e4;
                    padding: 11px 30px;
                    align-items: center;
                    .item {
                        display: flex;
                        align-items: center;
                        img {
                            margin-right: 30px;
                        }
                    }
                    .direction {
                        height: 20px;
                        width: 20px;
                        margin-left: 30px;
                    }
                }
                & > div:last-child  {
                    border-right: none;
                }
            }
        }
    }

    .range-tooltip {
        border-radius: 2px;
        box-shadow: 0 30px 50px 0 rgba(29, 30, 78, 0.1), 0 0 6px 0 rgba(27, 28, 51, 0.08);
        font-family: 'Gilroy-Medium';
        position: relative;
        background: #ffffff;
        z-index: 999;
        display: flex;
        flex-direction: column;
        padding: 10px;
        text-transform: capitalize;
        .retailer {
            font-size: 12px;
            font-weight: 800;
            color: #000000;
            text-transform: capitalize;
            margin-bottom: 5px;
        }
        .tooltip-box {
            display: flex;
            align-items: stretch;
        }
        .separete-line {
            width: 1px;
            background-color: #e0e0e4;
            margin: 0 15px;
        }
    }
    
    .item-range {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        .range-price {
            width: 100%;
            margin-left: 10px;
            text-align: right;
        }
    }
    .title-range-box {
        width: 170px;
        display: flex;
        align-items: center;
    }
`