import styled from "styled-components";

export const Styles = styled.div`
  position: relative;
  .select-product-box {
    width: 288px;
    height: 44px;
    background: #eef2f7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    font-size: 14px;
    cursor: pointer;
    margin: 0 !important;

    .select-placeholder {
      color: #333333 !important;
      opacity: 0.65;
      font-family: Gilroy-Medium;
    }

    .select-count {
      color: #000000;
      font-family: Gilroy-Medium;
    }
  }
  .ant-btn {
    width: 71px !important;
  }
`;
