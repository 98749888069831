import styled from "styled-components";

export const Styles = styled.div`
  .ranking-wrapper {
    display: flex;
    align-items: center;
    padding: 10px 0;
  }
  .ranking-item {
    width: 160px;
    height: 250px;
    padding: 15px;
    box-sizing: border-box;
    margin-right: 15px;
    border-radius: 4px;
    cursor: pointer;
    .arrow {
      width: 12px;
      height: 12px;
      margin: 0 10px;
    }
    .title {
      font-family: "Gilroy-ExtraBold";
      font-size: 14px;
      color: #000000;
      font-weight: 800;
      margin-bottom: 15px;
    }
    .image {
      width: 24px;
      height: 24px;
    }
    .retailers {
      display: flex;
      align-items: center; 
    }
    .rate {
      width: 130px;
      display: flex;
      flex-direction: column;
      margin-top: 25px;
      .title {
        font-family: "Gilroy-ExtraBold";
        font-size: 14px;
        color: #000000;
        font-weight: 800;
        margin-bottom: 0px;
      }
      .title.prev {
        color: #797b69;
      }
      .hint {
        font-size: 12px;
        font-weight: 500;
        color: #000000;
        font-family: "Gilroy-Medium";
      }
    }
  }
  .chart-title-desc {
    margin: 0;
  }
  .compare-params {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    .delisted {
      width: 14px;
      height: 14px;
      margin: 0 15px;
    }
  }
  .chart-price-wrapper.ranking {
    // width: calc(100% - 425px);
    display: flex;
    overflow-x: auto;
    align-items: center;
    overflow-x: auto;
    height: 300px;
    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #abaaba;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #807f97;
    }
  }
  .chart-wrapper-box {
    align-items: flex-end;
  }
`;
