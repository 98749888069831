import React from "react";
import { useSelector } from "react-redux";

//Components
import MainFilters from "components/Filters/MainFilters";
import SummaryTab from "tabs/SummaryTab";
import NavBar from "components/NavBar";
import LoaderBox from "components/LoaderBox";
import useFilters from "../hooks/useFilters";
import { STATE_STATUSES } from "utils/statuses";

const Summary = () => {
  const { prepareLogoutStatus, status: statusAuth } = useSelector((state) => state.authorization);

  const {
    mainLastFilter,
    statusMainFilters,
    universalFiltersStatus,
    statusFilters,
    titleDateStatus,
  } = useFilters();

  return (
    <div className="main-page-wrapper">
      <NavBar />
      <div className="main-page-box">
        <MainFilters />
        <div className="main-title-page">My Performance Summary</div>
        <div className="main-tabs-box">
          <SummaryTab />
        </div>
      </div>
      {
        statusAuth !== STATE_STATUSES.PENDING &&
        mainLastFilter &&
        statusMainFilters === STATE_STATUSES.READY &&
        universalFiltersStatus === STATE_STATUSES.READY &&
        statusFilters === STATE_STATUSES.READY &&
        titleDateStatus === STATE_STATUSES.READY &&
        prepareLogoutStatus === STATE_STATUSES.INIT ? null : <LoaderBox isOpacity={true} />
      }
      </div>
  );
};

export default Summary;
