import React, { useEffect, useState } from "react";
import useColorTheme from "../../../../hooks/useColorTheme";
import { Pagination } from "antd";
import { useDispatch, useSelector } from "react-redux";
//Components
import { Styles } from "./styles";
//Images
import { Cancel } from "assets/icons/icons";
//Components
import CurrentTopContent from "./CurrentTopContent";
import CurrentBottomContent from "./CurrentBottomContent";
import LoaderBox from "components/LoaderBox";
//Utils
import { getTimePeriod } from "utils/getTimePeriod";
import { STATE_STATUSES } from "utils/statuses";
import { BRAND, tableQuery } from "utils/currentContentHelper";
//Actions
import { fetchCurrentContent } from "store/content/currentContent/actions";
import { fetchCurrentContentTable } from "store/content/currentContentTable/actions";
import { firstCharToUpperCase } from "utils/generalUtils";
import useCache from "../../../../hooks/useCache";
import useFilters from "../../../../hooks/useFilters";

const CurrentContent = () => {
  const { primaryColor } = useColorTheme();
  const dispatch = useDispatch();
  //Selectors
  const { currentContentTable, status: tableStatus } = useSelector((state) => state.currentContentTable);
  const { status } = useSelector((state) => state.currentContent);
  const { cachedCurrentContent: currentContent } = useCache();
  
  const { mainLastFilter: lastFilter, statusFilters } = useFilters();

  const [selectedFilter, setSelectedFilter] = useState({});
  const [queryParams, setQueryParams] = useState({});
  const [queryParamsTable, setQueryParamsTable] = useState({});
  const [sortId, setSortId] = useState("productName");
  const [sortDirection, setSortDirection] = useState(true);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (statusFilters !== STATE_STATUSES.READY) return;

    setQueryParams((prevState) => ({
      ...prevState,
      sourceType: lastFilter.sourceType,
      timePeriod: getTimePeriod(lastFilter.date),
      product: lastFilter.product,
    }));

    setQueryParamsTable((prevState) => ({
      ...prevState,
      sourceType: lastFilter.sourceType,
      timePeriod: getTimePeriod(lastFilter.date),
      product: lastFilter.product,
      limit: 10,
      page: 1,
      ...tableQuery,
    }));
    setSelectedFilter({});
  }, [lastFilter, statusFilters]);

  useEffect(() => {
    if (Object.keys(queryParams).length && statusFilters === STATE_STATUSES.READY) {
      dispatch(fetchCurrentContent(queryParams));
    }
  }, [queryParams, statusFilters, dispatch]);

  useEffect(() => {
    if (Object.keys(queryParamsTable).length) {
      dispatch(fetchCurrentContentTable(queryParamsTable));
    }
  }, [queryParamsTable]);

  useEffect(() => {
    if (statusFilters !== STATE_STATUSES.READY) return;

    setSortId("productName");
    setSearch("");

    if (Object.keys(selectedFilter).length) {
      if (selectedFilter.type === BRAND) {
        setQueryParamsTable({
          sourceType: lastFilter.sourceType,
          timePeriod: getTimePeriod(lastFilter.date),
          product: lastFilter.product,
          limit: 10,
          page: 1,
          ...tableQuery,
          brand: selectedFilter.value,
        });
      } else {
        setQueryParamsTable({
          sourceType: lastFilter.sourceType,
          timePeriod: getTimePeriod(lastFilter.date),
          product: lastFilter.product,
          limit: 10,
          page: 1,
          ...tableQuery,
          opportunity: selectedFilter.value,
        });
      }
    } else {
      setQueryParamsTable({
        sourceType: lastFilter.sourceType,
        timePeriod: getTimePeriod(lastFilter.date),
        product: lastFilter.product,
        limit: 10,
        page: 1,
        ...tableQuery,
        brand: "",
        opportunity: "",
      });
    }
  }, [selectedFilter, statusFilters]);

  const handleSearch = (value) => {
    setSearch(value);
    setQueryParamsTable((prevState) => ({
      ...prevState,
      productName: value,
      page: 1,
    }));
  };

  const handleSort = (id) => {
    let direction;

    setSortDirection(!sortDirection);
    direction = !sortDirection ? "asc" : "desc";

    setSortId(id);

    let key;
    if (id === "retailer") {
      key = "sourceTypeSort";
    } else {
      key = id + "Order";
    }
    if (id === "productName" || id === "retailer") {
      direction = sortDirection ? "desc" : "asc";
    }

    setQueryParamsTable({
      sourceType: lastFilter.sourceType,
      timePeriod: getTimePeriod(lastFilter.date),
      product: lastFilter.product,
      limit: 10,
      page: 1,
      ...tableQuery,
      [key]: direction,
    });
  };

  const onChangePage = (page) => {
    setQueryParamsTable((prevState) => ({
      ...prevState,
      page: page,
    }));
  };

  const selectedEntity = (value) => {
    if (value === "secondaryImages") {
      return "Secondary Images";
    } else return firstCharToUpperCase(value);
  };

  return (
    <Styles color={primaryColor}>
      <>
        {selectedFilter.value ? (
          <div className="filters-box">
            <div className="filter" onClick={() => setSelectedFilter({})}>
              <span className="filter-title">{selectedEntity(selectedFilter.value)}</span>
              <Cancel fill={"#fff"} />
            </div>
          </div>
        ) : null}

        <div className="wrapper-box-relative">
          {currentContent.success ? (
            <div className="chart-wrapper-box">
              {
                <CurrentTopContent
                  selectedFilter={selectedFilter}
                  setSelectedFilter={setSelectedFilter}
                  currentContent={currentContent}
                  status={status}
                />
              }
            </div>
          ) : null}
          {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
        </div>
        <div className="wrapper-box-relative">
          <CurrentBottomContent
            data={currentContentTable}
            timePeriod={queryParams.timePeriod}
            handleSearch={handleSearch}
            handleSort={handleSort}
            sortId={sortId}
            sortDirection={sortDirection}
            search={search}
          />
          {tableStatus === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
        </div>

        {currentContentTable.count > queryParamsTable.limit ? (
          <Pagination
            className="pagination-controls"
            onChange={onChangePage}
            current={queryParamsTable.page ? queryParamsTable.page : 1}
            pageSize={queryParamsTable.limit ? queryParamsTable.limit : 10}
            total={currentContentTable.count}
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
          />
        ) : null}
      </>
    </Styles>
  );
};

export default CurrentContent;
