const url = process.env.REACT_APP_URL;

export const FETCH_ALERT_FILTERS = "FETCH_ALERT_FILTERS";
export const fetchAlertFilters = (data) => ({
  type: FETCH_ALERT_FILTERS,
  request: {
    url: `${url}/v2/filters`,
    method: "POST",
    data,
  },
  meta: {
    asPromise: true,
  },
});

export const RESET_ALERT_FILTERS = "RESET_ALERT_FILTERS";
export const resetAlertFilter = () => {
  return {
    type: RESET_ALERT_FILTERS,
  };
};

export const SET_SELECT_ALL_PRODUCTS = "SET_SELECT_ALL_PRODUCTS";
export const setSelectAllProducts = (data) => {
  return {
    type: SET_SELECT_ALL_PRODUCTS,
    payload: data,
  };
};

export const SET_SELECT_ALL_BRANDS = "SET_SELECT_ALL_BRANDS";
export const setSelectAllBrands = (data) => ({
  type: SET_SELECT_ALL_BRANDS,
  payload: data,
});

export const GET_ALL_ALERTS = "GET_ALL_ALERTS";
export const getAllAlerts = () => {
  return {
    type: GET_ALL_ALERTS,
    request: {
      method: "GET",
      url: `${url}/v2/alerts`,
    },
    meta: {
      asPromise: true,
    },
  };
};

export const CREATE_ALERT = "CREATE_ALERT";
export const createAlert = (data) => {
  return {
    type: CREATE_ALERT,
    request: {
      method: "POST",
      url: `${url}/v2/alerts`,
      data,
    },
    meta: {
      asPromise: true,
    },
  };
};

export const TEST_ALERT = "TEST_ALERT";
export const testAlert = (data) => {
  return {
    type: TEST_ALERT,
    request: {
      method: "POST",
      url: `${url}/v2/alerts/test`,
      data,
    },
    meta: {
      asPromise: true,
    },
  };
};

export const GET_ALERT = "GET_ALERT";
export const getAlert = (id) => {
  return {
    type: GET_ALERT,
    request: {
      method: "GET",
      url: `${url}/v2/alerts/${id}`,
    },
    meta: {
      asPromise: true,
    },
  };
};

export const DELETE_ALERT = "DELETE_ALERT";
export const deleteAlert = (id) => {
  return {
    type: DELETE_ALERT,
    request: {
      method: "DELETE",
      url: `${url}/v2/alerts/${id}`,
    },
    meta: {
      asPromise: true,
    },
  };
};

export const EDIT_ALERTS = "EDIT_ALERTS";
export const editAlert = (data, id) => {
  return {
    type: EDIT_ALERTS,
    request: {
      method: "PUT",
      url: `${url}/v2/alerts/${id}`,
      data,
    },
    meta: {
      asPromise: true,
    },
  };
};

export const SET_PRISTINE_ALERTS = "SET_PRISTINE_ALERTS";
export const setPristineAlerts = (pristine) => {
  return {
    type: SET_PRISTINE_ALERTS,
    payload: pristine,
  };
};
