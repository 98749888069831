import React, { useEffect, useState } from "react";

import RatingSummaryTop from "./RatingSummaryTop";
import RatingSummaryBottom from "./RatingSummaryBottom";
import { useDispatch, useSelector } from "react-redux";
import { setRatingSummaryFilterBottom } from "store/rating/ratingSummaryBottom/actions";

const RatingSummary = () => {
  const [arrayOfDesiredBrand, setArrayOfDesiredBrand] = useState([]);
  const [retailer, setRetailer] = useState([]);
  const [clickedArray, setClickedArray] = useState([]);
  const [isRedraw, setIsRedraw] = useState(false);
  const [isAllCollapsed, setAllCollapsed] = useState(false);

  //Const
  const dispatch = useDispatch();

  const { bottomFilter } = useSelector((state) => state.ratingSummaryBottom);

  const resetClickedArr = async () => {
    new Promise((resolve, reject) => {
      setIsRedraw(false);

      setAllCollapsed(true);
      setClickedArray([]);

      return setTimeout(() => {
        resolve();
        setIsRedraw(true);
      }, 500);
    });
  };

  useEffect(() => {
    resetClickedArr();
  }, [retailer, arrayOfDesiredBrand]);

  const setSelectValue = (values, func, filter) => {
    const value = Object.keys(values);

    const updateData = filter.map((item) => {
      if (item.name === value[0]) {
        return {
          ...item,
          value: values[value[0]],
        };
      } else if (item.name === value[1]) {
        return {
          ...item,
          value: values[value[1]],
        };
      }

      return item;
    });
    //set filters only when chart was redrawn
    resetClickedArr().then(() => {
      dispatch(func(updateData));
    });
  };

  return (
    <>
      <RatingSummaryTop
        arrayOfDesiredBrand={arrayOfDesiredBrand}
        setArrayOfDesiredBrand={setArrayOfDesiredBrand}
        retailer={retailer}
        setRetailer={setRetailer}
        setClickedArray={setClickedArray}
      />
      <RatingSummaryBottom
        arrayOfDesiredBrand={arrayOfDesiredBrand}
        clickedArray={clickedArray}
        retailer={retailer}
        setClickedArray={setClickedArray}
        setSelectValue={(values) => setSelectValue(values, setRatingSummaryFilterBottom, bottomFilter)}
        isRedraw={isRedraw}
        setIsRedraw={setIsRedraw}
        resetClickedArr={resetClickedArr}
        isAllCollapsed={isAllCollapsed}
        setAllCollapsed={setAllCollapsed}
      />
    </>
  );
};

export default RatingSummary;
