import { COLORS } from "assets/colors/colors";
import styled from "styled-components";

export const Styles = styled.div`
  position: "relative";
  .page-count {
    position: absolute;
    left: 20px;
    bottom: 0;
    font-size: 14px;
  }
  .current-table-box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 100%;
  }
  .wrapper-table-box {
    width: 100%;
    overflow-x: auto;
    display: inline-block;
    &::-webkit-scrollbar {
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: #abaaba;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #807f97;
    }
  }
  .wrapper-table {
    width: 100%;
  }
  .box-wrapper {
    width: 100%;
    display: flex;
  }
  .brand {
    width: 181px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dfdfea;
    border-right: none;
    border-top: none;

    span {
      font-family: "Gilroy-Medium";
      width: 280px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.29;
      color: #000000;
      padding: 0 10px;
    }

    .brand-color {
      width: 100%;
      max-width: 12px;
      height: 12px;
      margin-right: 16px;
      border-radius: 2px;
      margin-left: 19px;
    }
  }
  .title {
    width: 277px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #dfdfea;
    border-left: none;
    border-right: none;
    border-top: none;
    .image {
      width: 40px;
      height: 40px;
    }
    span {
      font-family: "Gilroy-Medium";
      width: 280px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.29;
      color: #000000;
      padding: 0 10px;
    }
    .averagePrice {
      width: 280px;
      padding-left: 28px;
    }
    .search {
      width: 280px;
      position: relative;
      position: relative;
      font-size: 14px;
      color: #000000;
      img {
        position: absolute;
        top: 10px;
        left: 10px;
      }
      input {
        border: none;
        background-color: #eef2f7;
        width: 240px;
        height: 44px;
        border-radius: 2px;
        outline: none;
        padding-left: 40px;
        padding-right: 12px;
      }
      .search-sort-btn {
        left: 240px;
        cursor: pointer;
      }
    }

    .title-image {
      margin-right: 16px;
      border-radius: 5px;
      & > img {
        width: 40px;
        height: 40px;
      }
    }
  }

  .title.sort {
    height: 64px;
    border: none;
    border-bottom: 1px solid #dfdfea;
  }
  .wrapper {
    width: 100%;
    display: flex;
    .no-listed {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
    }
    .table-item {
      min-width: 7.3%;
      height: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid #dfdfea;
      border-right: none;
      border-top: none;
      span {
        font-family: "Gilroy-Medium";
        font-size: 14px;
      }
      img {
        width: 25px;
      }
    }
  }
  .table-item.sort {
    height: 64px;
    border: none;
    flex-direction: row;
    border-bottom: 1px solid #dfdfea;
  }
  .table-item.sort.brand-title {
    cursor: pointer;
    min-width: 181px;
    max-width: 181px;
    display: flex;
    align-items: center;
    height: 64px;
    border: none;
    flex-direction: row;
    border-bottom: 1px solid #dfdfea;
    span {
      padding-left: 19px;
      font-family: "Gilroy-ExtraBold";
      font-size: 12px;
      color: #9696a0;
    }
  }
`;
