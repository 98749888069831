export const RESET_ERROR = "RESET_ERROR";
export const resetError = () => {
  return {
    type: RESET_ERROR,
  };
};

export const SHOW_ERROR_POPUP = "SHOW_ERROR_POPUP";
export const showErrorPopup = (bool) => {
  return {
    type: SHOW_ERROR_POPUP,
    action: bool,
  };
};
