import React, { useEffect, useState } from "react";
import { Empty, Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
//Components
import { Styles } from "./styles";
import PopupRatingProduct from "components/Popups/PopupRatingProduct/PopupRatingProduct";

//Images
import star from "assets/images/star.svg";
import productImage from "assets/images/testImage.png";
//Utils
import useRetailers from "../../../../../../hooks/useRetailers";
import { converToThousands } from "utils/ratingHelper";
import { getTimePeriod } from "utils/getTimePeriod";
import useFilters from "hooks/useFilters";
//Actions
import { fetchRatingSummaryProduct, fetchRatingSummaryReviews, fetchRatingSummaryReviewsAll } from "store/rating/ratingSummaryProduct/actions";

const TopMoversRating = ({ data, filter }) => {
  //States
  const [movers, setMovers] = useState([]);
  const [emptyData, setEmptyData] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [avgRating, setAvgRating] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [combinedCoreRetailers, setCombinedCoreRetailers] = useState([]);
  //Selectors
  const { ratingSummaryProduct, ratingSummaryReviews, ratingSummaryReviewsAll } = useSelector((state) => state.ratingSummaryProduct);
  const {
    lastFilter,
  } = useFilters();

  //Const
  const dispatch = useDispatch();
  const { getRemoteLogo } = useRetailers();

  useEffect(() => {
    if (data.bestRated.length) {
      setMovers(data.bestRated);
      setEmptyData(false);
    } else {
      setEmptyData(true);
      setMovers([]);
    }
  }, [data]);

  const fetchProduct = (id) => {
    const data = {
      coreRetailerIds: id.join('|'),
      limit: "10",
      page: "1",
      sort: "date|desc",
      timePeriod: getTimePeriod(lastFilter.date)
    };
    dispatch(fetchRatingSummaryProduct({ id: id[0] }));
    if(filter[1].value) {
      dispatch(fetchRatingSummaryReviewsAll(data))
    } else {
      dispatch(fetchRatingSummaryReviews(data));
    }

    setShowPopup(true);
  };

  const popupContent = () => {
    return (
      <div className="popup-wrapper">
        <div className="popup-row">
          <span>Avg. Rating</span>
          <span>{popupData.avgRating}</span>
        </div>
        <div className="popup-row">
          <span>No. of Reviews</span>
          <span>{popupData.reviewCount}</span>
        </div>
        <span onClick={() => {
          const ids = popupData.combinedCoreRetailers || [popupData.coreRetailersId];
          fetchProduct(ids);
          setAvgRating(popupData.avgRating);
          setReviewCount(popupData.reviewCount);
          setCombinedCoreRetailers(popupData.combinedCoreRetailers);
        }}>Show Reviews</span>
      </div>
    );
  };

  return (
    <Styles>
      <div className="filters">
        <span>Best Rated</span>
      </div>
      <div className="topMovers">
        {emptyData ? (
          <div className="empty-wrapper">
            <div className="no-data-box">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
          </div>
        ) : null}

        {movers.map((item, index) => {
          return (
            <Popover key={index} content={popupContent} title={item.title} trigger="hover" overlayStyle={{ width: 200 }}>
              <div className="mover" key={index} onMouseOver={() => setPopupData(item)}>
                <div className="row">
                  <div className="wrapper-prod">
                    <img className="image" src={!item?.image?.length ? productImage : item.image} alt="banner" />

                    <img className="retailer-marcker" src={getRemoteLogo(item?.retailer?.name)} alt="retailer" />
                  </div>
                  <div className="title">{item.title}</div>
                </div>
                <div className="mover-results">
                  <div className="mover-result">{item.avgRating}</div>
                  <div className="mover-result">
                    <img src={star} alt="star" />
                  </div>
                  <div className="mover-result">({converToThousands(item.reviewCount)})</div>
                </div>
              </div>
            </Popover>
          );
        })}
      </div>
      {showPopup && ratingSummaryProduct.success ? (
        <PopupRatingProduct
          product={ratingSummaryProduct}
          reviews={filter[1].value ? ratingSummaryReviewsAll : ratingSummaryReviews}
          closePopup={() => setShowPopup(false)}
          showRetailer={false}
          avgRating={avgRating}
          reviewCount={reviewCount}
          combinedCoreRetailers={combinedCoreRetailers}
          timePeriod={getTimePeriod(lastFilter.date)}
          showAllReviews={filter[1].value}
        />
      ) : null}
    </Styles>
  );
};

export default TopMoversRating;
