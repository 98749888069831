const url = process.env.REACT_APP_URL;

export const FETCH_RATING_SUMMARY = "FETCH_RATING_SUMMARY";
export const fetchRatingSummary = (params) => ({
  type: FETCH_RATING_SUMMARY,
  request: {
    url: `${url}/v2/rating/summary`,
    method: "POST",
    data: params,
  },
});

export const SET_RATING_SUMMARY_FILTER = "SET_RATING_SUMMARY_FILTER";
export const setRatingSummaryFilter = (filter) => {
  localStorage.setItem(SET_RATING_SUMMARY_FILTER, JSON.stringify(filter));
  return {
    type: SET_RATING_SUMMARY_FILTER,
    payload: filter,
  };
};

export const SET_PRISTINE_RATING_SUMMARY_CHART = "SET_PRISTINE_RATING_SUMMARY_CHART";
export const setPristineRatingSummaryChart = (pristine) => {
  return {
    type: SET_PRISTINE_RATING_SUMMARY_CHART,
    payload: pristine,
  };
};
