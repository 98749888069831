const url = process.env.REACT_APP_URL;

export const FETCH_ASSORTMENT_SUMMARY_CALENDAR = "FETCH_ASSORTMENT_SUMMARY_CALENDAR";
export const fetchAssortmentSummaryCalendar = (params) => ({
  type: FETCH_ASSORTMENT_SUMMARY_CALENDAR,
  request: {
    url: `${url}/v2/assortment/calendar`,
    method: "POST",
    data: params,
  },
});

export const ADD_TO_DATA_ASSORTMENT_SUMMARY_CALENDAR = "ADD_TO_DATA_ASSORTMENT_SUMMARY_CALENDAR";
export const addToDataAssortmentSummaryCalendar = (rows) => {
  return {
    type: ADD_TO_DATA_ASSORTMENT_SUMMARY_CALENDAR,
    payload: rows,
  };
};
