import React from "react";

//Components
import RenderNoData from "components/RenderNoData";

//Assets
import sort from "assets/images/sort.svg";
import sortIconDown from "assets/images/sort_down.svg";
import sortIconUp from "assets/images/sort_up.svg";
import search from "assets/images/search.svg";
//Utils
import useRetailers from "../../../../../../hooks/useRetailers";
import { firstCharToUpperCase } from "utils/generalUtils";

const RetailerAvailabilityTable = ({ inputSearch, handleSearch, data, handleSort, sortId, sortDirection, isEmpty, filterBy }) => {
  const { getRemoteLogo } = useRetailers();

  const keyValue = {
    true: "good",
    false: "bad",
  };

  const renderTitleBlock = (el) => {
    return (
      <div className={`title`}>
        <div className="title-color" style={{ background: el.color }}></div>

        <span>{el.title}</span>
      </div>
    );
  };

  const renderProductsBlock = (el, arr) => {
    return (
      <div className="wrapper">
        {arr.map((item, index) => (
          <div className={`table-item`} key={index}>
            <img src={getRemoteLogo(keyValue[item])} alt="" />
          </div>
        ))}
      </div>
    );
  };

  const renderRowBlock = (key, item, arr) => {
    return (
      <div className="box-wrapper" key={key}>
        {renderTitleBlock(item)}
        {renderProductsBlock(item, arr)}
      </div>
    );
  };

  return (
    <div className="chart-price-wrapper" style={{ width: "100%" }}>
      <div className="current-table-box">
        <div className="wrapper-table-box">
          <div className="wrapper-table">
            <div className="box-wrapper">
              <div className="title sort" style={{ borderBottom: isEmpty ? "none" : "" }}>
                <div className="search">
                  <img src={search} alt="search" />
                  <input
                    value={inputSearch}
                    onChange={(e) => handleSearch(e.target.value)}
                    placeholder={`Search for a ${firstCharToUpperCase(filterBy)}`}
                  />
                  <img
                    src={sortId === "0" ? (sortDirection ? sortIconUp : sortIconDown) : sort}
                    alt="sort"
                    className="search-sort-btn"
                    onClick={data.total === 0 ? () => {} : () => handleSort("0")}
                  />
                </div>
              </div>
              {isEmpty ? null : (
                <div className="wrapper">
                  {data.retailers.map((item, index) => (
                    <div className="table-item sort" onClick={() => handleSort(item.id)} key={index}>
                      <img src={getRemoteLogo(item.name)} alt="retailer" />
                      <img src={sortId === item.id ? (sortDirection ? sortIconUp : sortIconDown) : sort} alt="sort" />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          {isEmpty ? (
            <RenderNoData />
          ) : (
            <div className="wrapper-table">
              <div className="wrapper-box-relative">
                {data.rows.map((el, index) => {
                  return renderRowBlock(index, el, el.data);
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RetailerAvailabilityTable;
