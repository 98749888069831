import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import useCountry from "../../../hooks/useCountry";
import useFilters from "../../../hooks/useFilters";

import _ from "lodash";
import { Checkbox } from "antd";

import { sendWatchList, setWatchList } from "store/settings/SettingsWatchlist/actions";
import { setWatchlist as setAuthWatchList  } from "store/authorization/actions";

import FiltersWatchlist from "components/Watchlist/components/FiltersWatchlist";
import LoaderBox from "components/LoaderBox";

import { STATE_STATUSES } from "utils/statuses";
import { getTimePeriod } from "../../../utils/getTimePeriod";
import { getWatchlistInArray } from "../../../utils/getWatchlistInArray";
const LABEL_RETAILERS = "Retailers";
const LABEL_CATEGORIES = "Categories";
const LABEL_MANUFACTURERS = "Manufacturers";
const LABEL_BRANDS =  "Brands";
const sectionName = (formNum) => {
  const names = {
    0: LABEL_RETAILERS,
    1: LABEL_CATEGORIES,
    2: LABEL_MANUFACTURERS,
    3: LABEL_BRANDS,
  };
  return names[formNum];
};

const setDefaults = (params, manufacturer, brands) => {
  const results =  { ...params }
  results.manufacture = results.manufacture.length
    ? [...new Set(results.manufacture.concat(manufacturer.map(i => Number(i))))]
    : [];
  results.productBrand = results.productBrand.length
    ? [...new Set(results.productBrand.concat(brands.map(i => Number(i))))]
    : [];
  return results;
}

const SettingsWatchlist = () => {
  const dispatch = useDispatch();
  const { filters, status } = useSelector((state) => state.settingsWatchlist);
  const { watchlist, watchlistFilter } = useSelector((state) => state.authorization);
  const { currentCountry, countryStatus } = useCountry();
  const { getFilters, setWatchlistFilter, fetchMainFilters, filters: mainFilters, lastFilter } = useFilters();
  const {
    settingsYourProfile: { retailers },
  } = useSelector((state) => state.settingsYourProfile);
  const userManufacturer = [localStorage.getItem("UserManufacturer")];
  const userBrands = JSON.parse(localStorage.getItem("UserBrands"));

  useEffect(() => {
    console.log('!!mainFilters=', mainFilters)
  },[mainFilters]);

  const filtersArray = [
    {
      name: "sourceType",
      label: "Retailers",
      placeholder: "retailer",
      nameParam: "label",
      data: Boolean(retailers)
        ? _.sortBy(filters.sourceType, (item) => retailers.indexOf(item.id))
        : filters.sourceType,
    },
    {
      name: "category",
      label: "Categories",
      placeholder: "category",
      nameParam: "name",
      data: filters.category,
    },
    {
      name: "manufacture",
      label: "Manufacturers",
      placeholder: "manufacture",
      nameParam: "name",
      data: filters.manufacture,
    },
    {
      name: "productBrand",
      label: "Brands",
      placeholder: "brand",
      nameParam: "name",
      data: filters.productBrand,
    },
  ];

  const watchlistFilterState = useMemo(() => {
    const result = {
      sourceType: watchlistFilter.sourceType ? [...new Set(watchlistFilter.sourceType.map(item => Number(item)))]: [],
      category: watchlistFilter.category ? [...new Set(watchlistFilter.category.map(item => Number(item)))]: [],
      manufacture: watchlistFilter.manufacture ? [...new Set(watchlistFilter.manufacture.map(item => Number(item)))]: [],
      productBrand: watchlistFilter.productBrand ? [...new Set(watchlistFilter.productBrand.map(item => Number(item)))]: [],
    }
    //return setDefaults(result, userManufacturer, userBrands);
    return result;
  }, [watchlistFilter.sourceType, watchlistFilter.category, watchlistFilter.manufacture, watchlistFilter.productBrand]);

  // setDefaults(watchlistFilterState, userManufacturer, userBrands);

  const [queryParams, setQueryParams] = useState(watchlistFilterState);
  const [currentForm, setCurrentForm] = useState(0);
  const [isDefaultWatchlist, setIsDefaultWatchlist] = useState(watchlist);


  useEffect(() => {
    setIsDefaultWatchlist(watchlist);
  }, [watchlist]);

  const watchlistFilterItems = useMemo(() => {
    return {
      sourceType: queryParams.sourceType.join("|"),
      category: queryParams.category.join("|"),
      manufacture: queryParams.manufacture.join("|"),
      productBrand: queryParams.productBrand.join("|"),
      watchlist: false,
    };
  }, [queryParams.sourceType, queryParams.category, queryParams.manufacture, queryParams.productBrand]);

  const [hasReset, setHasReset] = useState(false);
  useEffect(() => {
    console.log('!!fetchWatchListFilters by ==> currentCountry.countryId, hasReset= ', hasReset)
    if (!hasReset) {
      setHasReset(true);
      const newFilters = getFilters({ ...watchlistFilterItems, product: 'All'});
      dispatch(setWatchList(newFilters));
    }
  }, [dispatch, currentCountry.countryId, watchlistFilterItems, hasReset, getFilters]);

  const resetFilter = (filters) => {
    let setAll = (obj, val, except) =>
      Object.keys(lastFilter).forEach((k) => {
        if (!except.includes(k)) {
          obj[k] = val;
        } else {
          obj["date"] = "4";
          obj["manufacture"] = userManufacturer[0];
        }
      });

    let setEmpty = (obj) => setAll(obj, "", ["date", "manufacture"]);
    setEmpty(lastFilter);
    setEmpty(filters);
  }

  const handleChangeUse = (e) => {
    const watchlist = e.target.checked;
    setIsDefaultWatchlist(watchlist);
  };

  const handleNextForm = (name, selectItems) => {
    if (selectItems.length && name === 'manufacture') {
      selectItems = [...new Set(selectItems.concat(userManufacturer).map(item => Number(item)))];
    }

    setQueryParams((prevState) => ({
      ...prevState,
      [name]: selectItems,
    }));

    const sendParams = {
      ...watchlistFilterItems,
      ...{ [name]: selectItems.join("|") },
      product: 'All',
      ...(name === 'manufacture' ? { productBrand: 'All' } : {})
    };
    const newFilters = getFilters(sendParams);
    dispatch(setWatchList(newFilters));
    setCurrentForm((prevState) => prevState + 1)
  };

  const handleSendWatchList = (name, selectItems) => {
    if (selectItems.length && name === 'productBrand') {
      selectItems = [...new Set(selectItems.concat(userBrands))];
    }
    setQueryParams((prevState) => ({
      ...prevState,
      [name]: selectItems,
    }));

    const filtersData = { ...watchlistFilterItems, ...{ [name]: selectItems.join("|") } };

    const sendParams = { watchlist: isDefaultWatchlist, watchlistFilter: filtersData };
    console.log(`!!handleSendWatchList -> sendParams: ${JSON.stringify(sendParams)}`)

    const filtersParams = {
      ...filtersData,
      date: lastFilter.date,
      timePeriod: getTimePeriod(lastFilter.date),
    }

    setWatchlistFilter({ watchlist: isDefaultWatchlist, watchlistFilter: filtersData });

    dispatch(sendWatchList(sendParams)).then(() => {
      if (isDefaultWatchlist) {
        fetchMainFilters({
          product: 'All',
          ...filtersParams,
          watchlist: isDefaultWatchlist,
          watchlistFilter: getWatchlistInArray(filtersData),
        });
      } else {
        fetchMainFilters({
          ...lastFilter,
          watchlist: false,
          //watchlistFilter: getWatchlistInArray(filtersData),
        });
      }

      dispatch(setAuthWatchList(
        {
          watchlistFilter:  getWatchlistInArray(filtersData),
          watchlist: isDefaultWatchlist,
        }
      ))
      setCurrentForm(0);
    });
  };

  return (
    <div style={{ position: "relative" }}>
      <div style={{ fontFamily: "Gilroy-Medium", marginBottom: "25px" }}>
        Only interested in a specific set of Retailers, Categories, Manufacturers and Brands?
        <br />
        You can narrow the scope of the Global Filters below by setting up a Watchlist
        <br />
        <br />
        Select the tickbox below for the Watchlist to be applied automatically when you login to the
        <br />
        platform (you can toggle the Watchlist on/off in your user profile dropdown menu).
      </div>
      <div style={{ fontFamily: "Gilroy-Medium", marginBottom: "25px" }}>
        <Checkbox checked={isDefaultWatchlist} onChange={handleChangeUse}>
          Use Watchlist by default
        </Checkbox>
      </div>
      <div style={{ fontFamily: "Gilroy-Bold", marginBottom: "25px" }}>
        Select {sectionName(currentForm)} for your Watchlist below and then select{" "}
        {currentForm === 3 ? "Save to save your Watchlist." : "Next."}
      </div>
      {filtersArray.map((item, index) => {
        if (currentForm === index) {
          return (
            <FiltersWatchlist
              key={index}
              item={item}
              setFiltersParams={handleSendWatchList}
              selectItems={queryParams[item.name]}
              onlyDropdown={true}
              setCurrentForm={setCurrentForm}
              handleNextForm={handleNextForm}
              index={index}
            />
          );
        }
      })}
      {status === STATE_STATUSES.PENDING || countryStatus === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
    </div>
  );
};

export default SettingsWatchlist;
