import React from "react";
import { Radio } from "antd";
import SelectBox from "components/Selects/SelectBox";
import { calculationType, weeklyTypes, snapshotDays } from "components/TabsExport/exportSections";

const SelectCalculation = ({ disabled, options: { viewLogic, weeklyType, snapshotDay }, handleSetOptions }) => {
  return (
    <div className="select-calculation-box">
      <div className="export-hint" style={{ marginTop: "24px", fontFamily: "Gilroy-ExtraBold" }}>
        Select Pricing Calculation
      </div>
      <div className="select-calculation">
        <div style={{ width: "334px" }}>
          <Radio.Group
            disabled={disabled}
            onChange={(e) => handleSetOptions("viewLogic", e.target.value)}
            value={viewLogic}
          >
            {calculationType.map((item, index) => (
              <Radio key={index} value={item.value}>
                {item.lable}
              </Radio>
            ))}
          </Radio.Group>
        </div>
        {viewLogic === "weekly" ? (
          <div>
            <div
              className="export-hint"
              style={{ marginBottom: "5px", marginTop: "10px", fontFamily: "Gilroy-ExtraBold" }}
            >
              Select Weekly Pricing Type
            </div>
            <SelectBox
              disabled={disabled}
              filter={weeklyType}
              data={weeklyTypes}
              customWidth={"250px"}
              setSelectValue={(val) => handleSetOptions("weeklyType", val["weeklyTypes"])}
            />
          </div>
        ) : null}
      </div>
      {viewLogic === "weekly" && weeklyType === "snapshot" ? (
        <div className="select-calculation" style={{ justifyContent: "flex-end" }}>
          <div>
            <div
              className="export-hint"
              style={{ marginBottom: "5px", marginTop: "10px", fontFamily: "Gilroy-ExtraBold" }}
            >
              Select Day of the Week for Snapshot
            </div>
            <SelectBox
              disabled={disabled}
              filter={snapshotDay}
              data={snapshotDays}
              customWidth={"250px"}
              setSelectValue={(val) => handleSetOptions("snapshotDay", val["snapshotDays"])}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SelectCalculation;
