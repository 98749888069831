import styled from "styled-components";

export const Styles = styled.div`
  .wrapper-compare {
    position: relative;
  }
  .compare-box {
    position: relative;
    height: 44px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    background: #eef2f7;
    padding: 0 15px;
    font-size: 14px;
    color: #000000;
    border-radius: 4px;
    cursor: pointer;
    font-family: "Gilroy-Medium";
    margin-left: 15px;

    & > span {
      padding-right: 20px;
    }
  }
  .dropdown {
    width: 260px;
    border-radius: 2px;
    box-shadow: 0 30px 50px 0 rgba(29, 30, 78, 0.1),
      0 0 6px 0 rgba(27, 28, 51, 0.08);
    background-color: #ffffff;
    position: absolute;
    padding: 16px;
    top: 50px;
    right: 0;
    z-index: 9999;
  }
  .dropdown-title {
    font-size: 12px;
    color: #9696a0;
    font-family: "Gilroy-Medium";
    font-weight: bold;
    padding: 10px 0;
  }
  .values-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .date-value {
    height: 40px;
    border-radius: 4px;
    border: solid 1px #dfdfea;
    width: 110px;
    margin: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Gilroy-Medium;
    font-size: 14px;
    cursor: pointer;
  }
`;
