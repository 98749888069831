import React, { useState } from "react";
import useColorTheme from "../../hooks/useColorTheme";
import { Checkbox } from "antd";
import { useHistory } from "react-router-dom";

//Components
import { Styles } from "./styles";
import ContentCard from "./components/ContentCard";
import DaysPeriodFilter from "components/Filters/DaysPeriodFilter";
import ChangeViewFilter from "components/Filters/ChangeViewFilter";

const defaultFilters = [
  {
    name: "lastDay",
    default: "byRetailer",
    options: [
      { lable: "By Retailer", value: "byRetailer" },
      { lable: "By Brand", value: "byBrand" },
    ],
  },
  {
    name: "byRetailer",
    default: "byRetailer",
    options: [
      { lable: "Last 7 days", value: "3" },
      { lable: "Last 14 days", value: "4" },
      { lable: "Last 30 days", value: "5" },
      { lable: "Last 90 days", value: "6" },
    ],
  },
];

const data = [
  {
    title: "Pricing",
    performance: 1.49,
    goal: 1.3,
    variance: 0.19,
    change: 0.49,
    prevPeriod: 25,
    isGoal: true,
    chart: Array.from({ length: 10 }, () => Math.floor(Math.random() * 10)),
  },
  {
    title: "Promotion Discount",
    performance: 11,
    goal: 25,
    variance: 14,
    change: null,
    prevPeriod: -20,
    isGoal: true,
    chart: Array.from({ length: 10 }, () => Math.floor(Math.random() * 10)),
  },
  {
    title: "Availability",
    performance: 80,
    goal: 95,
    variance: 15,
    change: null,
    prevPeriod: -5,
    isGoal: true,
    chart: Array.from({ length: 10 }, () => Math.floor(Math.random() * 10)),
  },
  {
    title: "Media",
    performance: 42,
    goal: 40,
    variance: 2,
    change: null,
    prevPeriod: 15,
    isGoal: true,
    chart: Array.from({ length: 10 }, () => Math.floor(Math.random() * 10)),
  },
  {
    title: "Assortment",
    performance: 56,
    goal: 60,
    variance: 4,
    change: null,
    prevPeriod: 32,
    isGoal: true,
    chart: Array.from({ length: 10 }, () => Math.floor(Math.random() * 10)),
  },
  {
    title: "Ratings & Reviews",
    performance: 3.5,
    goal: 4.6,
    variance: 1.1,
    change: null,
    prevPeriod: -3.7,
    isGoal: true,
    chart: Array.from({ length: 10 }, () => Math.floor(Math.random() * 10)),
  },
  {
    title: "Content",
    performance: 90,
    goal: 80,
    variance: 10,
    change: null,
    prevPeriod: 35,
    isGoal: true,
    chart: Array.from({ length: 10 }, () => Math.floor(Math.random() * 10)),
  },
  {
    title: "Navigation",
    performance: 35,
    goal: 45,
    variance: 10,
    change: null,
    prevPeriod: -25,
    isGoal: false,
    chart: Array.from({ length: 10 }, () => Math.floor(Math.random() * 10)),
  },
  {
    title: "Ranking by Search Terms",
    performance: 20,
    goal: 40,
    variance: 20,
    change: null,
    prevPeriod: -40,
    isGoal: true,
    chart: Array.from({ length: 10 }, () => Math.floor(Math.random() * 10)),
  },
  {
    title: "Ranking by Locations",
    performance: 35,
    goal: 28,
    variance: 5,
    change: null,
    prevPeriod: 0,
    isGoal: false,
    chart: Array.from({ length: 10 }, () => Math.floor(Math.random() * 10)),
  },
];

const SummaryTab = () => {
  const { primaryColor } = useColorTheme();

  const [checked, setChecked] = useState(false);
  const history = useHistory();

  const setSelectValue = () => {};

  const onCardClick = (title) => {
    const titleToRoute = {
      Pricing: "pricing",
      "Promotion Discount": "promotions",
      Availability: "availability",
      Media: "media",
      Assortment: "assortment",
      "Ratings & Reviews": "rating",
      Content: "content",
      Navigation: "navigation",
      "Ranking by Search Terms": "ranking",
      "Ranking by Locations": "ranking",
    };

    history.push(`/${titleToRoute[title]}`);
  };
  return (
    <Styles color={primaryColor}>
      <div className="main-box">
        <div className="filters">
          <div className="filters-row">
            <div className="wrapper-checkbox">
              <Checkbox
                checked={checked}
                onChange={() => setChecked(!checked)}
                // disabled={}
              >
                Show Goals
              </Checkbox>
            </div>
            <div className="filters">
              <ChangeViewFilter />
              <DaysPeriodFilter />
            </div>
          </div>
        </div>
        <div className="title">
          You are currently viewing summary for your products.
        </div>
        <div className="content-row">
          {data.map((el, i) => {
            return (
              <div key={i} className="content-card-wrapper">
                <ContentCard data={el} onClick={() => onCardClick(el.title)} />
              </div>
            );
          })}
        </div>
      </div>
    </Styles>
  );
};

export default SummaryTab;
