import React from "react";
import _ from "lodash";
import { Popover } from "antd";
import { useSelector } from "react-redux";
import { changeNameRetailer } from "utils/changeNameRetailer";

const ChartLegends = ({ param, legends, selectedLegends, legendLength, setLegends, sortByY, sortByRetailer }) => {
  const {
    settingsYourProfile: { retailers },
  } = useSelector((state) => state.settingsYourProfile);

  const initialData = sortByY
    ? legends.sort((a, b) => b.y - a.y)
    : sortByRetailer
    ? _.sortBy(legends, (item) => retailers.indexOf(item.id))
    : legends.sort((a, b) => a[param].localeCompare(b[param]));

  const legendsList = initialData.length > legendLength ? initialData.slice(0, legendLength) : initialData;

  const renderItems = (item, index) => (
    <div
      key={index}
      className={`legend-box ${selectedLegends.includes(item[param]) ? "active-legend" : ""}`}
      onClick={() => setLegends(item[param])}
    >
      <span style={{ background: item.color }} className="color"></span>
      <span className="name">{changeNameRetailer(item[param])}</span>
    </div>
  );

  return (
    <div className="change-legends-wrapper">
      {legendsList.map((item, index) => renderItems(item, index))}
      {initialData.length > legendLength ? (
        <Popover
          placement="bottomLeft"
          content={
            <div className="legends-dropdown">
              {initialData.slice(legendLength, initialData.length).map((item, index) => renderItems(item, index))}
            </div>
          }
          trigger="click"
        >
          <div className="more">More</div>
        </Popover>
      ) : null}
    </div>
  );
};

export default ChartLegends;
