import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Empty, Tooltip, Modal } from "antd";

import { fetchSharePopup } from "store/navigation/navigationShareProducts/actions";

import PieChart from "tabs/NavigationTabs/components/PieChart";
import SharePopup from "tabs/NavigationTabs/components/SharePopup";

import useRetailers from "../../../hooks/useRetailers";
import { getTimePeriod } from "utils/getTimePeriod";
import { changeNameRetailer } from "utils/changeNameRetailer";

import { CollapseClose, CollapseOpen } from "assets/icons/icons";
import search from "assets/images/search.svg";
import down from "assets/images/down.svg";
import useFilters from "../../../hooks/useFilters";

const LocationsBox = ({ data, showNumProdacts }) => {
  const wrapperRef = useRef(null);
  const { getRemoteLogo } = useRetailers();

  const dispatch = useDispatch();

  const { retailer, departmentPercent, aislePercent, taxonomies } = data;

  const { lastFilter } = useFilters();
  const { sharePopup } = useSelector((state) => state.navigationShareProducts);

  const [searchValue, setSearchValue] = useState("");
  const [isOpenChild, setIsOpenChild] = useState(false);
  const [indexChild, setIndexChild] = useState(null);
  const [locationItemsLength, setLocationItemsLength] = useState([]);
  const [selectItems, setSelectItems] = useState([]);

  const [isOpenSetects, setIsOpenSetects] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [taxonomyId, setTaxonomyId] = useState(null);

  const [taxonomyIsClose, setTaxonomyIsClose] = useState([]);

  const getAllLocations = () => {
    const ids = [];
    taxonomies.forEach((taxonomy) => {
      ids.push(taxonomy.id);
      if (taxonomy.children.length) {
        taxonomy.children.forEach((child) => {
          ids.push(child.id);
          if (child.children.length) {
            child.children.forEach((item) => ids.push(item.id));
          }
        });
      }
    });

    return ids;
  };

  useEffect(() => {
    if (taxonomies.length) {
      setSelectItems(getAllLocations());
      setLocationItemsLength(getAllLocations());
    }
  }, [taxonomies]);

  const handleDeselectAll = () => setSelectItems([]);

  const handleSelectAll = () => setSelectItems(getAllLocations());

  const handleToggleLocation = (id) => {
    if (taxonomyIsClose.includes(id)) {
      const ids = taxonomyIsClose.filter((item) => item !== id);
      setTaxonomyIsClose(ids);
    } else {
      setTaxonomyIsClose((prevState) => [...prevState, id]);
    }
  };

  const handlePopup = (item) => {
    dispatch(
      fetchSharePopup({
        taxonomyId: item.id.toString(),
        product: lastFilter.product,
        timePeriod: getTimePeriod(lastFilter.date),
        productName: "",
        productNameOrder: "None",
        sortBy: "",
        page: "1",
        limit: "100000",
      })
    ).then(() => setShowModal(true));

    setTaxonomyId(item.id.toString());
  };

  const handleSearch = (e) => {
    const { value } = e.target;

    setSearchValue(value);
  };

  const onSearch = (data) =>
    data.filter((item) => {
      if (searchValue) {
        return item.category.toLowerCase().includes(searchValue.toLowerCase());
      }
      return item;
    });

  const toggleOpenSubBrand = (index) => {
    if (index !== indexChild) {
      setIsOpenChild(true);
    } else {
      setIsOpenChild(!isOpenChild);
    }
    setIndexChild(index);
  };

  const handleSelectItem = (id) => {
    if (Number(id)) {
      if (selectItems.includes(id)) {
        const filtred = selectItems.filter((item) => item !== id);
        setSelectItems(filtred);
      } else {
        setSelectItems((prevState) => [...prevState, id]);
      }
    } else {
      if (selectItems.includes(id.id)) {
        let selectLocations = selectItems;

        if (id.children.length) {
          const subbrends = id.children.map((item) => item.id);

          const filtredSubbrand = selectItems.filter((el) => !subbrends.includes(el));

          selectLocations = filtredSubbrand;
        }

        const filtred = selectLocations.filter((item) => item !== id.id);
        setSelectItems(filtred);
      } else {
        let items = id.children.length ? id.children.map((item) => item.id) : [];

        setSelectItems((prevState) => [...new Set([...prevState, id.id, ...items])]);
      }
    }
  };

  const renderLocation = (item, index, sufix) => (
    <React.Fragment key={index}>
      {selectItems.includes(item.id) ? (
        <div className={`location-item ${sufix}`}>
          <div className="location-row">
            <div style={{ width: `${Number(item.percent)}px` }}></div>
          </div>
          <div style={{ display: "flex" }}>
            {item.children.length ? (
              <div style={{ cursor: "pointer", display: "flex" }} onClick={() => handleToggleLocation(item.id)}>
                {taxonomyIsClose.includes(item.id) ? (
                  <CollapseClose color={"#000000"} />
                ) : (
                  <CollapseOpen color={"#000000"} />
                )}
              </div>
            ) : null}
            <Tooltip
              color={"#fff"}
              title={
                <div className="location-tooltip">
                  <div className="title">
                    {item.category} in <span style={{ textTransform: "capitalize" }}>{retailer}</span>
                  </div>
                  <div className="value" style={{ margin: "10px 0" }}>
                    <span>Total Share</span> <span>{item.percent}%</span>
                  </div>
                  {showNumProdacts ? (
                    <div className="value">
                      <span>Total No. of Products</span> <span>{item.productsCount}</span>
                    </div>
                  ) : null}

                  <div className="value">
                    <span>Manufacturer No. of Products</span> <span>{item.manufacturerProductsCount}</span>
                  </div>
                  <div className="link" onClick={() => handlePopup(item)}>
                    Show Location
                  </div>
                </div>
              }
            >
              <div className="location-category">
                {item.category}
                {showNumProdacts ? <span> ({item.productsCount})</span> : null}
              </div>
            </Tooltip>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );

  function useOutsideAlerter(ref) {
    useEffect(() => {
      if (isOpenSetects) {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setIsOpenSetects(false);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
    }, [ref, isOpenSetects]);
  }
  useOutsideAlerter(wrapperRef);

  return (
    <div className="location-wrapper">
      <div ref={wrapperRef}>
        <div className="location-title" onClick={() => setIsOpenSetects(!isOpenSetects)}>
          <img src={getRemoteLogo(retailer)} />
          <div>
            Filter <span>{changeNameRetailer(retailer)}</span> Locations
          </div>
        </div>
        {isOpenSetects ? (
          <div className="selects-list-wrapper">
            <div className="search-location">
              <img src={search} alt="search" />
              <input onChange={handleSearch} value={searchValue} placeholder="Search for a Location" />
            </div>
            <div className="count-items">
              <div>{locationItemsLength.length} Locations</div>
              <div>{selectItems.length} Selected</div>
            </div>
            <div className="select-lists">
              {onSearch(taxonomies).length ? (
                onSearch(taxonomies).map((taxonomi, index) => (
                  <div key={index} className="select-item-box">
                    <div
                      className={`
                        select-item ${selectItems.includes(taxonomi.id) ? "selected-item" : ""} 
                        ${
                          taxonomi.children.length && selectItems.length
                            ? `${
                                !taxonomi.children
                                  .map((item) => item.id)
                                  .some((brand) => selectItems.includes(brand)) && selectItems.includes(taxonomi.id)
                                  ? "selected-some-brand"
                                  : ""
                              }`
                            : ""
                        }
                        ${
                          taxonomi.children.length && selectItems.length
                            ? `${
                                !taxonomi.children
                                  .map((item) => item.id)
                                  .every((brand) => selectItems.includes(brand)) && selectItems.includes(taxonomi.id)
                                  ? "selected-some-brand"
                                  : ""
                              }`
                            : ""
                        }
                        ${
                          taxonomi.children.length && selectItems.length
                            ? `${
                                taxonomi.children.map((item) => item.id).some((brand) => selectItems.includes(brand)) &&
                                !selectItems.includes(taxonomi.id)
                                  ? "selected-some-brand"
                                  : ""
                              }`
                            : ""
                        }
                    `}
                      onClick={() => handleSelectItem(taxonomi)}
                    >
                      {taxonomi.category}
                    </div>
                    {taxonomi.children.length ? (
                      <span onClick={() => toggleOpenSubBrand(index)} className="down-box">
                        <img src={down} alt="down" />
                      </span>
                    ) : null}
                    {isOpenChild && indexChild === index && taxonomi.children.length ? (
                      <>
                        {taxonomi.children.map((child, index) => (
                          <React.Fragment key={index}>
                            <div
                              className={`select-item child ${selectItems.includes(child.id) ? "selected-item" : ""}`}
                              onClick={() => handleSelectItem(child.children.length ? child : child.id)}
                            >
                              {child.category}
                            </div>
                            {child.children.length
                              ? child.children.map((item, index) => (
                                  <div
                                    key={index}
                                    className={`select-item child children ${
                                      selectItems.includes(item.id) ? "selected-item" : ""
                                    }`}
                                    onClick={() => handleSelectItem(item.id)}
                                  >
                                    {item.category}
                                  </div>
                                ))
                              : null}
                          </React.Fragment>
                        ))}
                      </>
                    ) : null}
                  </div>
                ))
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
            <div className="controls-box">
              {locationItemsLength.length === selectItems.length ? (
                <div onClick={handleDeselectAll}>Deselect All</div>
              ) : (
                <div onClick={handleSelectAll}>Select All</div>
              )}
            </div>
          </div>
        ) : null}
      </div>

      <div className="pie-charts">
        <PieChart title={"Department"} percent={Number(departmentPercent)} />
        <PieChart title={"Aisle / Shelf"} percent={Number(aislePercent)} />
      </div>

      <div className="locations-list">
        {taxonomies.length ? (
          taxonomies.map((taxonomi, index) => (
            <div key={index} className="location-wrapper-box">
              {renderLocation(taxonomi, index, "")}
              <div>
                {!taxonomyIsClose.includes(taxonomi.id) && taxonomi.children.length ? (
                  <>
                    {taxonomi.children.map((child, index) => (
                      <div key={index}>
                        {renderLocation(child, index, `child ${child.children.length ? "parent" : ""}`)}
                        <div>
                          {!taxonomyIsClose.includes(child.id) && child.children.length
                            ? child.children.map((item, index) => renderLocation(item, index, "child children"))
                            : null}
                        </div>
                      </div>
                    ))}
                  </>
                ) : null}
              </div>
            </div>
          ))
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
      <Modal
        className="export-modal navigation"
        title={sharePopup.title}
        visible={showModal}
        onCancel={() => setShowModal(false)}
      >
        <SharePopup data={sharePopup} taxonomyId={taxonomyId} />
      </Modal>
    </div>
  );
};

export default LocationsBox;
