export const STATE_STATUSES = {
    READY: 'ready',
    PENDING: 'pending',
    ERROR: 'error',
    INIT: 'init',
};
export const STATUS_REPORT = {
    SUBMITTED: 'submitted',
    PROCESSING: 'processing',
    READY: 'ready',
    DOWNLOADED: 'downloaded',
    ACKNOWLEDGED: 'acknowledged',
  }