import { COLORS } from "assets/colors/colors";
import styled from "styled-components";

export const Styles = styled.div`
  min-height: 100px;
  position: relative;
  margin-top: 16px;
  .main-box-header {
    display: flex;
    margin-bottom: 30px;
    justify-content: space-between;
  }
  .main-box-filters {
    display: flex;
  }
  .calendar-title-desc {
    display: flex;
    flex-direction: column;
    line-height: 1.29;
    font-size: 14px;
    max-width: 522px;
    margin-bottom: 24px;
    font-family: Gilroy-Medium;
    .bold {
      font-weight: bold;
    }
  }
  .calendar {
    width: calc(100% - 390px);
  }
  .calendar-wrapper {
    display: flex;
  }
  .top-retailers {
    width: 390px;
    display: flex;
    flex-direction: column;
  }
  .retailers-media {
    margin-top: 21px;
    border-top: 2px solid ${COLORS.lightGray};
    border-right: 1px solid ${COLORS.lightGray};
  }
  .collapse-all {
    .search {
      width: 100%;
      position: relative;
      font-size: 14px;
      color: #000000;
      display: flex;
      img {
        position: absolute;
        top: 10px;
        left: 10px;
      }
      input {
        border: none;
        background-color: #eef2f7;
        width: 350px;
        height: 44px;
        border-radius: 2px;
        outline: none;
        padding-left: 40px;
        padding-right: 12px;
      }
      .search-sort-btn {
        left: 350px !important;
      }
    }
  }
  .slider-wrapper {
    margin-left: 14px;
    margin-right: 6px;
  }
  .wrapper-dscount {
    width: auto;
    background: #eef2f7;
    border: none;
    height: 44px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin-left: 15px;
    padding: 0 10px;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    font-family: "Gilroy-Medium";
  }
`;
