import React from "react";
import { Checkbox } from "antd";

const DropdownMetricValue = ({ title }) => {
  return (
    <div className="dropdown-value metric-value">
      <Checkbox checked={true} onChange={() => {}} />
      <span>{title}</span>
    </div>
  );
};

export default DropdownMetricValue;
