import React from "react";
import { getImage } from "utils/getImage"

import exportImg from "assets/images/export.svg";

const Reports = () => {
    return (
        <div className='export-tab'>

            <div className='export-table-header'>
                <div className='export-report'>Report title</div>
                <div className='export-retailer'>Retailers</div>
                <div className='export-period'>Updated</div>
                <div style={{ width: '44px' }}></div>
            </div>

            <div className='export-table-body'>
                <div className='export-table-item'>
                    <div className='export-report'>Best Sellers</div>
                    <div className='export-retailer'>
                        <img
                          src={getImage('ocado')}
                          alt="retailer"
                        />
                        <img
                          src={getImage('tesco')}
                          alt="retailer"
                        />
                        <img
                          src={getImage('waitrose')}
                          alt="retailer"
                        />
                        <img
                          src={getImage('iceland')}
                          alt="retailer"
                        />
                        <img
                          src={getImage('amazon_fresh')}
                          alt="retailer"
                        />
                    </div>
                    <div className='export-period'>14/01/2020</div>
                    <div className='export-controls' style={{ width: 'auto' }}>
                        <div>
                            <img src={exportImg} alt='export' />
                        </div>
                    </div>
                </div>
                <div className='export-table-item'>
                    <div className='export-report'>Availability</div>
                    <div className='export-retailer'>
                        <img
                          src={getImage('sainsburys')}
                          alt="retailer"
                        />
                        <img
                          src={getImage('tesco')}
                          alt="retailer"
                        />
                        <img
                          src={getImage('morrisons')}
                          alt="retailer"
                        />
                    </div>
                    <div className='export-period'>14/01/2020</div>
                    <div className='export-controls' style={{ width: 'auto' }}>
                        <div>
                            <img src={exportImg} alt='export' />
                        </div>
                    </div>
                </div>
                <div className='export-table-item'>
                    <div className='export-report'>Rating & Reviews Action list</div>
                    <div className='export-retailer'>
                        <img
                          src={getImage('asda')}
                          alt="retailer"
                        />
                        <img
                          src={getImage('amazon')}
                          alt="retailer"
                        />
                        <img
                          src={getImage('ocado')}
                          alt="retailer"
                        />
                        <img
                          src={getImage('waitrose')}
                          alt="retailer"
                        />
                    </div>
                    <div className='export-period'>14/01/2020</div>
                    <div className='export-controls' style={{ width: 'auto' }}>
                        <div>
                            <img src={exportImg} alt='export' />
                        </div>
                    </div>
                </div>
                <div className='export-table-item'>
                    <div className='export-report'>Online offline SKU benchmarking</div>
                    <div className='export-retailer'>
                        <img
                          src={getImage('tesco')}
                          alt="retailer"
                        />
                        <img
                          src={getImage('asda')}
                          alt="retailer"
                        />
                        <img
                          src={getImage('morrisons')}
                          alt="retailer"
                        />
                    </div>
                    <div className='export-period'>14/01/2020</div>
                    <div className='export-controls' style={{ width: 'auto' }}>
                        <div>
                            <img src={exportImg} alt='export' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reports
