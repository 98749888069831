import React from "react";
import { Tooltip } from "antd";

export const Search = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" transform="translate(0 1)" />
        <path
          fill={props.fill}
          d="M10.5 4c3.59 0 6.5 2.91 6.5 6.5 0 1.434-.464 2.759-1.25 3.834l3.957 3.959c.39.39.39 1.024 0 1.414-.36.36-.928.388-1.32.083l-.094-.083-3.96-3.957c-1.074.786-2.4 1.25-3.833 1.25C6.91 17 4 14.09 4 10.5S6.91 4 10.5 4zm0 2C8.015 6 6 8.015 6 10.5S8.015 15 10.5 15s4.5-2.015 4.5-4.5S12.985 6 10.5 6z"
          transform="translate(0 1)"
        />
      </g>
    </svg>
  );
};

export const Desktop = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0L24 0 24 24 0 24z" transform="translate(0 1)" />
        <path
          fill={props.fill}
          d="M4 13h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zm0 8h6c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1zm10 0h6c.55 0 1-.45 1-1v-8c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zM13 4v4c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1z"
          transform="translate(0 1)"
        />
      </g>
    </svg>
  );
};

export const Pricing = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none">
        <path d="M0 0H24V24H0z" />
        <path
          fill={props.fill}
          d="M10.05 3.99c-.259.008-.506.117-.687.303l-5.576 5.793C1.944 12 1.92 15.022 3.733 16.965l2.552 2.734c.248.263.619.371.97.283.35-.087.626-.357.722-.706.095-.348-.005-.721-.262-.975l-2.633-2.69c-1.15-1.174-1.14-3.055.02-4.218l5.675-5.686c.296-.287.385-.727.224-1.107-.16-.38-.538-.622-.95-.61zm5.561.01c-.53 0-1.039.21-1.414.586L6.62 12.164c-.825.825-.825 2.165 0 2.99l4.227 4.227c.825.825 2.165.825 2.99 0l7.578-7.578c.375-.375.586-.884.586-1.414V6c0-1.105-.895-2-2-2h-4.389zM19 6c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z"
        />
      </g>
    </svg>
  );
};

export const Promotions = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" transform="translate(0 1)" />
        <path
          fill={props.fill}
          d="M16.903 3.254L18.1 5.227c.166.275.397.506.672.672l1.973 1.198c.747.453 1.113 1.337.905 2.185l-.548 2.243c-.077.312-.077.638 0 .95l.548 2.243c.208.848-.158 1.732-.905 2.185L18.773 18.1c-.275.166-.506.397-.672.672l-1.198 1.973c-.453.747-1.337 1.113-2.185.905l-2.243-.548c-.312-.077-.638-.077-.95 0l-2.243.548c-.848.208-1.732-.158-2.185-.905L5.9 18.773c-.166-.275-.397-.506-.672-.672l-1.973-1.198c-.747-.453-1.113-1.337-.905-2.185l.548-2.243c.077-.312.077-.638 0-.95L2.35 9.282c-.208-.848.158-1.732.905-2.185L5.227 5.9c.275-.166.506-.397.672-.672l1.198-1.973c.453-.747 1.337-1.113 2.185-.905l2.243.548c.312.077.638.077.95 0l2.243-.548c.848-.208 1.732.158 2.185.905zM15 14c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zm.536-5.536c-.391-.39-1.024-.39-1.415 0l-5.657 5.657c-.39.39-.39 1.024 0 1.415.391.39 1.024.39 1.415 0l5.657-5.657c.39-.39.39-1.024 0-1.415zM9 8c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z"
          transform="translate(0 1)"
        />
      </g>
    </svg>
  );
};

export const Ranking = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <path d="M0 0H24V24H0z" transform="translate(-38 -402) translate(25 389) translate(13 13)" />
              <path
                fill={props.fill}
                fillRule="nonzero"
                d="M16.643 2c.38 0 .693.282.743.648l.007.102-.001.474.215.005c.709.034 1.4.25 2.01.632l.225.15.195.149c.777.662 1.222 1.652 1.213 2.69 0 2.227-2.34 4.704-4.553 4.905l-.047.002-.053.097c-.767 1.288-2.005 2.197-3.427 2.522l-.277.055L12.892 19H15.5c.828 0 1.5.672 1.5 1.5v1c0 .276-.224.5-.5.5h-9c-.276 0-.5-.224-.5-.5v-1c0-.828.672-1.5 1.5-1.5h2.606l.001-4.569-.022-.002c-1.437-.25-2.712-1.09-3.538-2.32l-.141-.222-.053-.092h-.039C5.202 11.67 3.012 9.46 2.772 7.277l-.016-.198-.005-.192c-.02-1.061.424-2.076 1.215-2.764.64-.53 1.418-.842 2.226-.9l.243-.01.172.002V2.75c0-.345.234-.636.551-.723l.098-.02L7.358 2h9.285zM6.458 5.043c-.483.012-.952.185-1.334.497-.382.328-.6.82-.588 1.343 0 .978.996 2.27 2.06 2.81l.067.032-.021-.16c-.011-.102-.02-.205-.026-.307l-.008-.308-.001-3.906-.149-.001zm10.934.016v3.926c0 .2-.013.399-.035.597l-.019.127.088-.043c1.023-.554 1.942-1.714 2.031-2.643l.007-.145c.009-.394-.12-.772-.357-1.07l-.042-.047-.184-.15c-.38-.31-.835-.497-1.309-.542l-.18-.01z"
                transform="translate(-38 -402) translate(25 389) translate(13 13)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const Content = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" transform="translate(-38 -470) translate(38 470)" />
        <path
          fill={props.fill}
          fillRule="nonzero"
          d="M21 6c.552 0 1 .448 1 1v14c0 .552-.448 1-1 1H7c-.552 0-1-.448-1-1V7c0-.552.448-1 1-1zm-2 11H9c-.552 0-1 .448-1 1s.448 1 1 1h10c.552 0 1-.448 1-1s-.448-1-1-1zm0-4h-2c-.552 0-1 .448-1 1s.448 1 1 1h2c.552 0 1-.448 1-1s-.448-1-1-1zm-6-4H9c-.552 0-1 .448-1 1v4c0 .552.448 1 1 1h4c.552 0 1-.448 1-1v-4c0-.552-.448-1-1-1zm-1.09 1.794c.115.051.206.144.256.26l.715 1.667c.043.102-.004.22-.106.263-.024.01-.051.016-.078.016h-3.39c-.11 0-.2-.09-.2-.2 0-.028.007-.056.018-.081l.537-1.208c.086-.193.312-.28.505-.194.083.037.15.103.19.185l.17.36c.048.1.167.142.267.094.043-.02.078-.056.097-.1l.358-.808c.112-.252.408-.366.66-.254zM19 9h-2c-.552 0-1 .448-1 1s.448 1 1 1h2c.552 0 1-.448 1-1s-.448-1-1-1z"
          transform="translate(-38 -470) translate(38 470)"
        />
        <path
          fill={props.fill}
          fillRule="nonzero"
          d="M15.03 2c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L15.03 4H6c-1.054 0-1.918.816-1.995 1.85L4 6v8.03c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.884L2 14.03V6c0-2.142 1.684-3.891 3.8-3.995L6 2h9.03z"
          transform="translate(-38 -470) translate(38 470)"
        />
      </g>
    </svg>
  );
};

export const Cancel = (props) => {
  return (
    <svg height="15px" viewBox="0 0 329.26933 329" width="15px" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={props.fill}
        d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"
      />
    </svg>
  );
};

export const Rating = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" transform="translate(-38 -538) translate(25 525) translate(13 13)" />
        <path
          fill={props.fill}
          fillRule="nonzero"
          d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10c-1.72 0-3.38-.44-4.858-1.263l.253.135.023.012-3.83 1.07c-.9.252-1.73-.532-1.567-1.423l.027-.117 1.069-3.83-.022-.041c-.66-1.29-1.034-2.707-1.088-4.175L2.001 12C2 6.477 6.478 2 12 2zm-.448 5.08l-1.248 2.528-2.79.406c-.188.028-.344.16-.404.341-.058.18-.01.38.127.512l2.019 1.968-.477 2.78c-.032.188.045.377.199.489.155.113.358.128.527.038L12 14.829l2.495 1.313c.073.038.154.057.233.057.104 0 .207-.032.294-.096.154-.112.231-.301.199-.489l-.477-2.779 2.019-1.969c.137-.131.185-.33.127-.511-.06-.181-.216-.313-.404-.34l-2.79-.407-1.248-2.528c-.168-.342-.729-.342-.897 0z"
          transform="translate(-38 -538) translate(25 525) translate(13 13)"
        />
      </g>
    </svg>
  );
};

export const Media = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" transform="translate(-38 -606) translate(25 593) translate(13 13)" />
        <path
          fill={props.fill}
          fillRule="nonzero"
          d="M21 6c.552 0 1 .448 1 1v14c0 .552-.448 1-1 1H7c-.552 0-1-.448-1-1V7c0-.552.448-1 1-1zm-4.318 7.994c-.46-.305-1.08-.18-1.386.28l-1.75 2.631c-.035.051-.077.095-.127.13-.225.16-.537.108-.697-.117l-.88-1.236c-.07-.099-.159-.184-.26-.252-.46-.306-1.08-.182-1.387.278l-1.677 2.515c-.055.082-.084.178-.084.277 0 .276.224.5.5.5h10.15c.096 0 .19-.028.27-.08.233-.149.3-.458.15-.69l-2.534-3.944c-.075-.116-.173-.215-.288-.292zM15.03 2c.552 0 1 .448 1 1 0 .513-.386.936-.883.993L15.03 4H6c-1.054 0-1.918.816-1.995 1.85L4 6v8.03c0 .552-.448 1-1 1-.513 0-.936-.386-.993-.884L2 14.03V6c0-2.142 1.684-3.891 3.8-3.995L6 2h9.03z"
          transform="translate(-38 -606) translate(25 593) translate(13 13)"
        />
      </g>
    </svg>
  );
};

export const Report = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="M0 0L24 0 24 24 0 24z"
                    transform="translate(-1116 -343) translate(350 102) translate(40 213) translate(716 18) translate(10 10)"
                  />
                  <path
                    fill={props.fill}
                    d="M19.52 15.333c.54 0 .98.448.98 1V21c0 .552-.44 1-.98 1H4.48c-.54 0-.98-.448-.98-1v-4.667c0-.552.44-1 .98-1 .542 0 .982.448.982 1V20h13.076v-3.667c0-.552.44-1 .981-1zM12 2c.542 0 .98.448.98 1v10.805l3.209-3.488c.236-.273.6-.393.948-.314.349.08.628.346.728.695.1.35.006.728-.246.986l-5.64 6.135-5.618-6.49c-.359-.415-.32-1.047.086-1.412.407-.366 1.026-.326 1.385.088l3.187 3.684V3c0-.552.44-1 .981-1z"
                    transform="translate(-1116 -343) translate(350 102) translate(40 213) translate(716 18) translate(10 10)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const Navigation = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path
          d="M0 0H24V24H0z"
          transform="translate(-1097 -610) translate(150 200) translate(932 388) translate(13 10) translate(2) translate(0 12)"
        />
        <path
          fill={props.fill}
          fillRule="nonzero"
          d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm-.248 15.039l-1.373-3.418-3.418-1.373c-.745-.299-.726-1.361.029-1.633l8.25-2.973c.696-.251 1.37.423 1.119 1.119l-2.973 8.25c-.272.754-1.334.773-1.634.028z"
          transform="translate(-1097 -610) translate(150 200) translate(932 388) translate(13 10) translate(2) translate(0 12)"
        />
      </g>
    </svg>
  );
};

export const Availability = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path
          d="M0 0H24V24H0z"
          transform="translate(-793 -336) translate(150 200) translate(628 114) translate(13 10) translate(2) translate(0 12)"
        />
        <path
          fill={props.fill}
          fillRule="nonzero"
          d="M.76 3c-.27-.004-.521.138-.658.372-.136.234-.136.523 0 .756.137.234.388.376.659.372h1.378c.364 0 .667.252.736.61l.147.773.001.007 1.614 8.469C4.928 15.889 6.272 17 7.83 17h9.362c1.559 0 2.9-1.11 3.193-2.641l1.613-8.47c.041-.218-.017-.445-.16-.617C21.697 5.1 21.485 5 21.262 5H4.38l-.032-.17C4.147 3.772 3.214 3 2.139 3H.76zm8.25 15c-.828 0-1.5.672-1.5 1.5 0 .829.672 1.5 1.5 1.5.83 0 1.5-.671 1.5-1.5 0-.828-.67-1.5-1.5-1.5zm7 0c.83 0 1.5.672 1.5 1.5 0 .829-.67 1.5-1.5 1.5-.828 0-1.5-.671-1.5-1.5 0-.828.672-1.5 1.5-1.5zM13.5 9c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1h-2c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1z"
          transform="translate(-793 -336) translate(150 200) translate(628 114) translate(13 10) translate(2) translate(0 12)"
        />
      </g>
    </svg>
  );
};

export const Assortment = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path
          d="M0 0L24 0 24 24 0 24z"
          transform="translate(-185 -610) translate(150 200) translate(20 388) translate(13 10) translate(2) translate(0 12)"
        />
        <path
          fill={props.fill}
          fillRule="nonzero"
          d="M5 21c-1.104 0-2-.896-2-2V5c0-1.104.896-2 2-2h14c1.104 0 2 .896 2 2v14c0 1.104-.896 2-2 2zm6-8H5v6h6v-6zm8 4.414L17.414 19H19v-1.586zM19 13h-1.586L13 17.414V19h1.584L19 14.584V13zm-4.416 0H13v1.584L14.584 13zM11 5H5v6h6V5zm8 0h-6v6h6V5zM8 18c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-3-3c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm6 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm-3-3c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm4-8v8H4V4h8z"
          transform="translate(-185 -610) translate(150 200) translate(20 388) translate(13 10) translate(2) translate(0 12)"
        />
      </g>
    </svg>
  );
};

export const Good = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path
          d="M0 0H24V24H0z"
          transform="translate(-740 -702) translate(165 199) translate(20 411) translate(0 63) translate(509 1) translate(46 28)"
        />
        <path
          fill={props.fill}
          fillRule="nonzero"
          d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm4.329 6.18c-.454-.316-1.077-.205-1.392.249l-4.15 5.962-2.285-2.55-.09-.09c-.374-.325-.942-.329-1.322.012-.411.368-.446 1-.077 1.412l3.128 3.492.09.09c.441.383 1.132.309 1.476-.186l4.871-7 .065-.107c.231-.44.104-.994-.314-1.285z"
          transform="translate(-740 -702) translate(165 199) translate(20 411) translate(0 63) translate(509 1) translate(46 28)"
        />
      </g>
    </svg>
  );
};

export const TabWithIcon = (props) => {
  return (
    <span style={{fontSize:18}}>
      {props.tabName}
      {/*<Tooltip className="about-icon-tooltip" placement="topLeft" title={props.tooltip}>*/}
      {/*  <img src={props.icon} className="about-icon" />*/}
      {/*</Tooltip>*/}
    </span>
  );
};

export const CollapseClose = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <path d="M0 0H24V24H0z" transform="translate(-182 -1654) translate(152 78) translate(20 1548) rotate(90 3 31)" />
                <path
                  fill={props.color}
                  d="M16 6.5H8c-.828 0-1.5.672-1.5 1.5v8c0 .828.672 1.5 1.5 1.5h8c.828 0 1.5-.672 1.5-1.5V8c0-.828-.672-1.5-1.5-1.5zm-8 1h8c.276 0 .5.224.5.5v8c0 .276-.224.5-.5.5H8c-.276 0-.5-.224-.5-.5V8c0-.276.224-.5.5-.5z"
                  transform="translate(-182 -1654) translate(152 78) translate(20 1548) rotate(90 3 31)"
                />
                <path
                  fill={props.color}
                  d="M12 9.5c.276 0 .5.224.5.5v1.5H14c.276 0 .5.224.5.5s-.224.5-.5.5h-1.5V14c0 .276-.224.5-.5.5s-.5-.224-.5-.5v-1.5H10c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h1.5V10c0-.276.224-.5.5-.5z"
                  transform="translate(-182 -1654) translate(152 78) translate(20 1548) rotate(90 3 31)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const CollapseOpen = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g transform="translate(-182 -628) translate(152 78) translate(30 550) rotate(90 12 12)">
                <path d="M0 0H24V24H0z" />
                <path
                  fill={props.color}
                  d="M16 6.5H8c-.828 0-1.5.672-1.5 1.5v8c0 .828.672 1.5 1.5 1.5h8c.828 0 1.5-.672 1.5-1.5V8c0-.828-.672-1.5-1.5-1.5zm-8 1h8c.276 0 .5.224.5.5v8c0 .276-.224.5-.5.5H8c-.276 0-.5-.224-.5-.5V8c0-.276.224-.5.5-.5z"
                />
                <rect width="5" height="1" x="9.5" y="11.5" fill={props.color} rx=".5" transform="rotate(90 12 12)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const ArrowSelect = () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="10px"
      height="10px"
      viewBox="0 0 292.362 292.362"
    >
      <g>
        <path
          fill="#737283"
          d="M286.935,69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952,0-9.233,1.807-12.85,5.424
		C1.807,72.998,0,77.279,0,82.228c0,4.948,1.807,9.229,5.424,12.847l127.907,127.907c3.621,3.617,7.902,5.428,12.85,5.428
		s9.233-1.811,12.847-5.428L286.935,95.074c3.613-3.617,5.427-7.898,5.427-12.847C292.362,77.279,290.548,72.998,286.935,69.377z"
        />
      </g>
    </svg>
  );
};

export const Email = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <g fill="none" fillRule="nonzero">
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <path
                      fill={props.fill}
                      fillRule="nonzero"
                      d="M17.049 0c-.285 0-.571.108-.79.326l-2.804 2.805c-.32.32-.093.869.361.869H16v3c-.005.36.184.696.496.878.311.182.697.182 1.008 0 .312-.182.501-.517.496-.878V4h2.281c.453 0 .682-.548.362-.87L17.838.327C17.62.108 17.334 0 17.048 0zM2 4C.976 4 .14 4.773.023 5.766L10 12l4.79-2.992C14.303 8.475 14 7.773 14 7V6h-1c-1.105 0-2-.895-2-2H2zM0 7.752V18c0 1.105.895 2 2 2h16c1.105 0 2-.895 2-2V9.527c0-.46-.563-.725-.889-.4-.544.539-1.29.873-2.111.873-.189 0-.374-.023-.553-.057L16.4 10l-5.87 3.67c-.325.203-.735.203-1.06 0L0 7.752z"
                      transform="translate(-427 -804) translate(150 199) translate(20 570) translate(0 22) translate(254 10) translate(2 2)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
    </svg>
  );
};

export const Sms = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g fill="none" fillRule="nonzero">
          <g>
            <g>
              <g>
                <g>
                  <g transform="translate(-731 -804) translate(150 199) translate(20 570) translate(304 22) translate(254 10) translate(6 2)">
                    <path
                      fill={props.fill}
                      fillRule="nonzero"
                      d="M11 0H1C.448.002.002.448 0 1v18c.002.552.448.998 1 1h10c.552-.002.998-.448 1-1V1c-.002-.552-.448-.998-1-1zM6.71 18.703c-.19.188-.444.295-.71.297-.265-.002-.518-.109-.705-.297-.189-.188-.295-.445-.295-.712 0-.267.106-.524.295-.712.4-.372 1.015-.372 1.415 0 .184.19.288.446.29.713 0 .266-.105.521-.29.711zM9.5 16h-7c-.276 0-.5-.224-.5-.5v-13c0-.276.224-.5.5-.5h7c.276 0 .5.224.5.5v13c0 .276-.224.5-.5.5z"
                    />
                    <rect width="6" height="3" x="3" y="3" rx=".5" />
                    <rect width="4" height="3" x="5" y="7" rx=".5" />
                  </g>
                </g>
              </g>
            </g>
        </g>
      </g>
    </svg>
  );
};

export const WhatsApp = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <g fill="none" fillRule="nonzero">
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g>
                      <path
                        fill={props.fill}
                        fillRule="nonzero"
                        d="M9.593.305c4.092-.2 7.875 2.17 9.48 5.939 1.604 3.77.69 8.139-2.292 10.949-2.832 2.67-6.959 3.393-10.51 1.895l-.22-.097-4.094.97c-.397.094-.811.002-1.13-.244l-.114-.1c-.328-.319-.471-.784-.378-1.232l.863-4.193-.123-.275C-.087 11.194.045 8.081 1.45 5.46l.16-.289C3.275 2.3 6.28.468 9.594.305zm-1.34 5.788c-.083-.003-.161.003-.235.017l-.028.006-.038.006c-.246.05-.478.166-.67.34-.58.527-.98 1.12-1.047 1.835-.121 1.288.465 2.622 1.811 3.973 1.518 1.523 3.036 2.074 4.363 1.727.696-.182 1.194-.695 1.607-1.395.22-.373.264-.829.12-1.239-.15-.425-.488-.76-.922-.895l-1.117-.345-.143-.035c-.43-.085-.872.04-1.198.33l-.026.025-.046-.037c-.279-.232-.617-.56-.84-.82l-.022-.027.014-.018c.296-.412.357-.95.164-1.419l-.47-1.141c-.172-.42-.53-.737-.972-.846l-.153-.029-.151-.013z"
                        transform="translate(-1035 -804) translate(150 199) translate(20 570) translate(608 22) translate(254 10) translate(1.5 1.5) translate(.5 .5)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
      </g>
    </svg>
  );
};

export const Calendar = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g fill="none" fillRule="nonzero">
          <g>
            <g>
              <g>
                <path
                  fill={props.fill}
                  fillRule="nonzero"
                  d="M6 3v1H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-1V3c0-.552-.448-1-1-1s-1 .448-1 1v1H8V3c0-.552-.448-1-1-1s-1 .448-1 1zM5 9h14v11H5V9z"
                  transform="translate(-1206 -499) translate(150 199) translate(20 276) translate(1036 12) translate(0 12)"
                />
              </g>
            </g>
          </g>
        </g>
    </svg>
  );
};

export const Export = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    d="M0 0L24 0 24 24 0 24z"
                    transform="translate(-1116 -343) translate(350 102) translate(40 213) translate(716 18) translate(10 10)"
                  />
                  <path
                    fill={props.color}
                    d="M19.52 15.333c.54 0 .98.448.98 1V21c0 .552-.44 1-.98 1H4.48c-.54 0-.98-.448-.98-1v-4.667c0-.552.44-1 .98-1 .542 0 .982.448.982 1V20h13.076v-3.667c0-.552.44-1 .981-1zM12 2c.542 0 .98.448.98 1v10.805l3.209-3.488c.236-.273.6-.393.948-.314.349.08.628.346.728.695.1.35.006.728-.246.986l-5.64 6.135-5.618-6.49c-.359-.415-.32-1.047.086-1.412.407-.366 1.026-.326 1.385.088l3.187 3.684V3c0-.552.44-1 .981-1z"
                    transform="translate(-1116 -343) translate(350 102) translate(40 213) translate(716 18) translate(10 10)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
