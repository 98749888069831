import React from "react";
import useColorTheme from "../../../hooks/useColorTheme";
import useCountry, { NUMBER_TYPE } from "../../../hooks/useCountry";

//Images
import green from "assets/images/green.svg";
import red from "assets/images/red.svg";
import gray from "assets/images/gray.svg";
import {
  Assortment,
  Availability,
  Media,
  Pricing,
  Promotions,
  Rating,
  Content,
  Navigation,
  Ranking,
  Good,
} from "assets/icons/icons";
import { COLORS } from "assets/colors/colors";
//Components
import ContentCardChart from "./ContentCardChart";

const KEYS = [
  { title: "Performance", key: "performance" },
  { title: "Goal", key: "goal" },
  { title: "Variance", key: "variance" },
];

const ContentCard = ({ data, onClick }) => {
  const { primaryColor } = useColorTheme();
  const { formatCurrencyNumber } = useCountry();

  const { title, prevPeriod, chart, isGoal } = data;

  const suffix =
    title === "Ratings & Reviews" || title === "Pricing" ? null : "%";

  const titleToImage = {
    Pricing: Pricing,
    "Promotion Discount": Promotions,
    Availability: Availability,
    Media: Media,
    Assortment: Assortment,
    "Ratings & Reviews": Rating,
    Content: Content,
    Navigation: Navigation,
    "Ranking by Search Terms": Ranking,
    "Ranking by Locations": Ranking,
  };

  const Icon = titleToImage[title];
  return (
    <div className="content-wrapper" onClick={onClick}>
      <div className="top-block">
        <div className="top-block-title">
          <Icon fill={primaryColor} />
          <span>{title}</span>
        </div>
        <div className="images">
          {isGoal ? <Good fill={primaryColor} /> : null}
          <img
            className="direction"
            src={prevPeriod > 0 ? green : prevPeriod === 0 ? gray : red}
            style={{
              transform: prevPeriod === 0 ? `rotate(90deg)` : null,
            }}
            alt="direction"
          />
        </div>
      </div>
      <div className="separator"></div>
      <div className="keys-wrapper">
        {KEYS.map(({ title, key }) => {
          const goalTitle = title === "Goal";
          return (
            <div className="keys-wrapper-row" key={key}>
              <span className={goalTitle ? "bold" : ""}>{title}</span>
              <span className={goalTitle ? "bold" : ""}>
                {formatCurrencyNumber(data[key], title === "Pricing" ? NUMBER_TYPE.CURRENCY : NUMBER_TYPE.DECIMAL)}
                {suffix}
              </span>
            </div>
          );
        })}
      </div>

      <ContentCardChart data={chart} />
      <div className="keys-wrapper-row">
        <span>Change vs. Previous Period</span>
        <span>
          {formatCurrencyNumber(prevPeriod, title === "Pricing" ? NUMBER_TYPE.CURRENCY : NUMBER_TYPE.DECIMAL)}
          {title === "Pricing" ? " (+25%)" : null}
          {suffix}
        </span>
      </div>
    </div>
  );
};

export default ContentCard;
