import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import {
  fetchRankingLocationAnalysis,
  setsetCurrentItemTermLocation,
  setCurrentShareLocation,
  setTermProduct,
  fetchRankingLocationAnalysisProducts,
  fetchRankingLocationAnalysisProductsPopup,
  setCurrentShareLocationSingleDay,
  fetchAnalysisSingleProduct,
  fetchAnalysisSingleProductSummary,
  analysisSingleProductSetPeriod,
  fetchAnalysisSingleProductCompare,
  fetchAnalysisSingleProductCompareSummary,
  analysisSingleProductSetCompare,
} from "store/ranking/rankingLocationAnalysis/actions";
import SearchTerm from "tabs/RankingTabs/RankingBottomTabs/components/SearchTerm";
import СurrentlyMainRetailerChart from "./СurrentlyMainRetailerChart";
import СurrentlyRetailerTerms from "./СurrentlyRetailerTerms";
import СurrentlyProducts from "tabs/RankingTabs/RankingBottomTabs/components/СurrentlyProducts";
import CurrentlyViewingTrend from "tabs/RankingTabs/RankingBottomTabs/LocationAnalysisTab/CurrentlyViewingTrend";

import LoaderBox from "components/LoaderBox";
import SelectBox from "components/Selects/SelectBox";
import { getTimePeriodRanking } from "utils/getTimePeriodRanking";
import { STATE_STATUSES } from "utils/statuses";
import useRetailers from "../../../../hooks/useRetailers";
import useCache from "../../../../hooks/useCache";
import useFilters from "../../../../hooks/useFilters";

import { Cancel } from "assets/icons/icons";

const prevueBox = [
  { image: "tesco", height: 58 },
  { image: "asda", height: 93 },
  { image: "sainsburys", height: 51 },
  { image: "morrisons", height: 62 },
  { image: "waitrose", height: 20 },
  { image: "ocado", height: 73 },
  { image: "amazon", height: 78 },
  { image: "iceland", height: 51 },
  { image: "amazon_fresh", height: 31 },
];

const LocationAnalysisTab = (props) => {
  const {
    status,
    fetchRankingLocationAnalysis,
    setsetCurrentItemTermLocation,
    setCurrentShareLocation,
    currentShareLocation,
    termProdcut,
    setTermProduct,
    fetchRankingLocationAnalysisProducts,
    fetchAnalysisSingleProduct,
    fetchAnalysisSingleProductSummary,
    analysisSingleProductSetPeriod,
    fetchAnalysisSingleProductCompare,
    fetchAnalysisSingleProductCompareSummary,
    analysisSingleProductSetCompare,
    fetchRankingLocationAnalysisProductsPopup,
    currentShareLocationSingleDay,
    setCurrentShareLocationSingleDay,
    isFeaturedProducts,
    isActive,
  } = props;

  const { cachedRankingLocationAnalysis: rankingLocationAnalysis } = useCache();
  const [currentData, setCurrentData] = useState(null);
  const [currentDataRank, setCurrentDataRank] = useState(null);
  const [shareSize, setShareSize] = useState(null);
  const [shareSelect, setShareSelect] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [currentManufacturer, setCurrentManufacturer] = useState(null);
  const [currentManufacturerId, setCurrentManufacturerId] = useState(null);
  const [currentRetailerData, setCurrentRetailerData] = useState({});
  const { getRemoteLogo } = useRetailers();
  const { mainLastFilter: lastFilter, statusFilters } = useFilters();
  const { manufacturer: defaultManufacturer } = useSelector(
    (state) => state.settingsYourProfile.settingsYourProfile.company
  );

  useEffect(() => {
    setCurrentShareLocationSingleDay({});
    setCurrentShareLocation({});
    setTermProduct("");
    setCurrentRetailerData({});
    setCurrentManufacturer(null);
  }, [lastFilter]);

  useEffect(() => {
    if (isActive && statusFilters === STATE_STATUSES.READY) {
      fetchRankingLocationAnalysis({
        sourceType: lastFilter.sourceType,
        timePeriod: getTimePeriodRanking(lastFilter.date),
        product: lastFilter.product,
        featured: isFeaturedProducts,
      });
    }
  }, [fetchRankingLocationAnalysis, lastFilter, isFeaturedProducts, isActive, statusFilters]);

  //get current manufacturer
  useEffect(() => {
    if (rankingLocationAnalysis.length) {
      const options = rankingLocationAnalysis
        .map((item) => ({ lable: item.title, value: item.title }))
        .sort((a, b) => a.lable.localeCompare(b.lable));
      let defaultName;
      let defaultId;

      const initialValue =
        currentManufacturer && currentManufacturerId
          ? rankingLocationAnalysis.find((item) => item.title === currentManufacturer)
          : rankingLocationAnalysis.find((item) => item.title === defaultManufacturer[0].name);

      if (initialValue) {
        defaultName = initialValue.title;
        defaultId = initialValue.id;
      } else {
        defaultName = rankingLocationAnalysis[0].title;
        defaultId = rankingLocationAnalysis[0].id;
      }

      const manufacturers = [
        {
          name: "manufacturers",
          default: defaultName,
          options,
        },
      ];

      setManufacturers(manufacturers);
      setCurrentManufacturer(defaultName);
      setCurrentManufacturerId(defaultId);
    }
  }, [rankingLocationAnalysis]);

  const getRankingTop = (shareSize) => {
    const rankingTop = {
      5: "rankingTop5",
      10: "rankingTop10",
      20: "rankingTop20",
    };

    return rankingTop[shareSize];
  };

  //get current data by Search Terms
  useEffect(() => {
    if (rankingLocationAnalysis.length && currentManufacturer) {
      const currentDataRank = rankingLocationAnalysis.find(
        (manufacturer) => manufacturer.title === currentManufacturer
      );

      const optionsInitial = [
        { lable: "Share of Top 5", value: "5" },
        { lable: "Share of Top 10", value: "10" },
        { lable: "Share of Top 20", value: "20" },
      ];

      const options = [];

      const rankingTop = ["rankingTop5", "rankingTop10", "rankingTop20"];

      rankingTop.forEach((item, index) => {
        if (currentDataRank[item]["data"].length) {
          options.push(optionsInitial[index]);
        }
      });

      const selects = [
        {
          name: "shareSize",
          default: options[0].value,
          options,
        },
      ];

      setCurrentDataRank(currentDataRank);
      setShareSelect(selects);
      setShareSize(options[0].value);
    }
  }, [rankingLocationAnalysis, currentManufacturer]);

  useEffect(() => {
    if (shareSize && currentDataRank) {
      const currentData = currentDataRank[getRankingTop(shareSize)];

      setCurrentData(currentData);
      setsetCurrentItemTermLocation(currentData);
    }
  }, [shareSize, currentDataRank]);

  useEffect(() => {
    let retailer;

    if (Object.entries(currentShareLocation).length !== 0) {
      retailer = currentShareLocation.retailer;
    } else if (Object.entries(currentShareLocationSingleDay).length !== 0) {
      retailer = currentShareLocationSingleDay.retailer;
    } else {
      return;
    }

    if (currentData?.retailers.length) {
      const currentRetailerData = currentData.retailers.find((item) => item.title === retailer);

      setCurrentRetailerData(currentRetailerData);
    }
  }, [currentShareLocation, currentShareLocationSingleDay, currentData]);

  useEffect(() => {
    setTermProduct("");
  }, [currentShareLocation, currentShareLocationSingleDay]);

  const handleSetShareSize = (values) => {
    const value = Object.keys(values);
    setShareSize(values[value]);
  };

  const handleSetCurrentManufacturer = (values) => {
    const value = Object.keys(values);
    const id = rankingLocationAnalysis.find((item) => item.title === values[value]).id;
    setCurrentManufacturer(values[value]);
    setCurrentManufacturerId(id);
    setTermProduct("");
  };

  const hiddenProducts = () => {
    setCurrentShareLocation({});
    setCurrentRetailerData({});
  };

  const hiddenShareDay = () => {
    setCurrentShareLocationSingleDay({});
    setCurrentRetailerData({});
  };

  const hiddenTermProdcut = () => {
    setTermProduct("");
  };

  return (
    <div>
      <div className="filters-box">
        {shareSelect.length
          ? shareSelect.map((item, index) => (
              <SelectBox
                key={index}
                data={item}
                disabled={status !== STATE_STATUSES.READY}
                setSelectValue={handleSetShareSize}
              />
            ))
          : null}
        {rankingLocationAnalysis.length
          ? manufacturers.map((item, index) => (
              <SelectBox
                key={index}
                data={item}
                disabled={status !== STATE_STATUSES.READY}
                setSelectValue={handleSetCurrentManufacturer}
              />
            ))
          : null}
        {Object.entries(currentShareLocation).length !== 0 && !termProdcut.length ? (
          <div className="current-retailer" onClick={hiddenProducts}>
            <span>{currentShareLocation.retailer}</span>
            <Cancel fill="#fff" />
          </div>
        ) : null}
        {Object.entries(currentShareLocationSingleDay).length !== 0 && !termProdcut.length ? (
          <div className="current-retailer" onClick={hiddenShareDay}>
            <span>{currentShareLocationSingleDay.retailer}</span>
            <Cancel fill="#fff" />
          </div>
        ) : null}
        {termProdcut.length ? (
          <div className="current-retailer" onClick={hiddenTermProdcut}>
            <span>{termProdcut}</span>
            <Cancel fill="#fff" />
          </div>
        ) : null}
      </div>
      <div className="wrapper-box-relative">
        {status === STATE_STATUSES.READY ? (
          <>
            <div>
              <div className="title-hint">
                Average Ranking Share of Top {shareSize} for {currentManufacturer}
              </div>
              <div className="row-charts-wrapper">
                {currentData?.retailers.length && rankingLocationAnalysis?.length ? (
                  <>
                    <SearchTerm
                      action={"SET_CURRENT_SHARE_LOCATION"}
                      storeTerm={"currentItemTermLocation"}
                      storeShare={"currentShareLocation"}
                      actionShareSingleDay={"SET_CURRENT_SHARE_LOCATION_SINGLE_DAY"}
                      storeShareSingleDay={"currentShareLocationSingleDay"}
                      param={"retailers"}
                      data={currentData}
                      isLocation={true}
                      isMany={false}
                    />
                    {Object.entries(currentRetailerData).length !== 0 ? (
                      <СurrentlyRetailerTerms
                        data={currentRetailerData}
                        termProdcut={termProdcut}
                        shareSize={shareSize}
                        currentManufacturer={currentManufacturer}
                      />
                    ) : (
                      <div className="hint-no-retailer">
                        <span>Select the Retailer to see its locations</span>
                        <div className="box-hint">
                          {prevueBox.map((item, index) => (
                            <div key={index} className="item-hint">
                              <div
                                style={{
                                  height: item.height,
                                  width: "6px",
                                  background: "#a0a2a6",
                                }}
                              ></div>
                              <img src={getRemoteLogo(item.image)} />
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="chart-no-data static">
                    <span>Looks like we don't have data for this request</span>
                  </div>
                )}
              </div>
            </div>
            {(Object.entries(currentRetailerData).length === 0 &&
              currentData?.retailers.length &&
              rankingLocationAnalysis?.length) ||
            (Object.entries(currentShareLocationSingleDay).length !== 0 &&
              currentData?.retailers.length &&
              rankingLocationAnalysis?.length) ? (
              <CurrentlyViewingTrend
                data={{
                  data: currentData.data,
                  share: currentData.retailers,
                }}
                currentManufacturerId={currentManufacturerId}
                currentManufacturer={currentManufacturer}
                selectProduct={termProdcut}
                type={"location"}
                actionSingleProduct={fetchAnalysisSingleProduct}
                storeSingleProduct={"analisisSingleProduct"}
                actionSingleProductSummary={fetchAnalysisSingleProductSummary}
                actionSingleProductSetPeriod={analysisSingleProductSetPeriod}
                actionRemoveProduct={"REMOVE_ANALYSIS_PRODUCT"}
                actionProductCompare={fetchAnalysisSingleProductCompare}
                storeProductCompare={"analisisSingleProductCompare"}
                actionSingleProductCompareSummary={fetchAnalysisSingleProductCompareSummary}
                actionSingleProductSetCompare={analysisSingleProductSetCompare}
                statusProduct={"statusLocation"}
              />
            ) : null}
            {Object.entries(currentRetailerData).length !== 0 &&
            !termProdcut.length &&
            Object.entries(currentShareLocationSingleDay).length === 0 ? (
              <СurrentlyMainRetailerChart data={currentRetailerData} currentRetailer={currentShareLocation.retailer} />
            ) : null}
            {currentManufacturerId &&
            termProdcut.length &&
            Object.entries(currentShareLocationSingleDay).length === 0 ? (
              <СurrentlyProducts
                type={"location"}
                searchTerm={termProdcut}
                manufacturer={currentManufacturerId}
                topRanking={shareSize}
                currentShare={"currentShareLocation"}
                storeProducts={"rankingLocationAnalysisProducts"}
                actionProducts={fetchRankingLocationAnalysisProducts}
                storeProductPopup={"rankingLocationAnalysisProductsPopup"}
                actionProductPopup={fetchRankingLocationAnalysisProductsPopup}
                actionSingleProduct={fetchAnalysisSingleProduct}
                storeSingleProduct={"analisisSingleProduct"}
                actionRemoveProduct={"REMOVE_ANALYSIS_PRODUCT"}
                actionProductCompare={fetchAnalysisSingleProductCompare}
                storeProductCompare={"analisisSingleProductCompare"}
                actionShowPopup={"SET_SHOW_PRODUCT_LOCATION_POPUP"}
                showPopup={"showProductLocationPopup"}
                status={"statusLocation"}
              />
            ) : null}
          </>
        ) : null}
        {status === STATE_STATUSES.PENDING ? <LoaderBox /> : null}
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    currentShareLocation: state.rankingLocationAnalysis.currentShareLocation,
    currentShareLocationSingleDay: state.rankingLocationAnalysis.currentShareLocationSingleDay,
    termProdcut: state.rankingLocationAnalysis.termProdcut,
    status: state.rankingLocationAnalysis.status,
    lastFilter: state.authorization.lastFilter,
  }),
  {
    fetchRankingLocationAnalysis,
    setsetCurrentItemTermLocation,
    setCurrentShareLocation,
    setTermProduct,
    fetchRankingLocationAnalysisProducts,
    fetchAnalysisSingleProduct,
    fetchAnalysisSingleProductSummary,
    analysisSingleProductSetPeriod,
    fetchAnalysisSingleProductCompare,
    fetchAnalysisSingleProductCompareSummary,
    analysisSingleProductSetCompare,
    fetchRankingLocationAnalysisProductsPopup,
    setCurrentShareLocationSingleDay,
  }
)(LocationAnalysisTab);
