import React, { useEffect, useRef, useState } from "react";
import useColorTheme from "../../hooks/useColorTheme";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { Styles } from "./Styles";
import { COLORS } from "assets/colors/colors";

const TotalScore = ({ data, inTable }) => {
  const { primaryColor } = useColorTheme();
  const inputRef = useRef(null);
  const [pricings, setPricings] = useState([
    { y: 75, color: primaryColor },
    { y: 25, color: COLORS.lightGrayOpacity },
  ]);

  useEffect(() => {
    const diff = 100 - data;
    setPricings([
      { y: +data, color: primaryColor },
      { y: diff, color: COLORS.lightGrayOpacity },
    ]);
  }, [data]);

  //Options
  const options = {
    chart: {
      type: "pie",
      margin: 0,
      spacing: 0,
      backgroundColor: "transparent",
    },
    title: {
      text: ``,
    },
    tooltip: {
      headerFormat: "",
      enabled: false,
    },
    plotOptions: {
      series: {
        groupPadding: 0,
        pointPadding: 0,
        borderWidth: 0,
        animation: {
          duration: 200,
        },
        borderWidth: 1,
      },
      pie: {
        center: ["50%", "50%"],
        states: {
          hover: {
            enabled: false,
            halo: null,
          },
          inactive: {
            opacity: 1,
          },
          active: {
            opacity: 1,
          },
        },

        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        minPointSize: 10,
        innerSize: "75%",
        name: "countries",
        data: pricings,
      },
    ],
  };
  return (
    <Styles>
      <div className="total-score-pie-chart" style={{ width: inTable ? 118 : 70 }}>
        <HighchartsReact
          containerProps={{ className: "chartContainer" }}
          ref={inputRef}
          highcharts={Highcharts}
          options={options}
        />

        <span className="label-inside" style={{ left: inTable ? 43 : null }}>
          {Number.parseFloat(+data || 0).toFixed(1)}%
        </span>
      </div>

      {inTable ? null : <span className="bottom-text">Across all your products</span>}
    </Styles>
  );
};

export default TotalScore;
