import React, { useEffect, useRef } from "react";
import useColorTheme from "../../../../../../hooks/useColorTheme";
import { Dropdown, Menu } from "antd";
//Components
import { Styles } from "./styles";
//Images
import search from "assets/images/search.svg";
import productImage from "assets/images/testImage.png";

const ProductInsightSearch = ({
  handleSearch,
  productArr,
  setProduct,
  setDropdownVisible,
  dropdownVisible,
  product,
  searchForProd,
  setIsBtnClicked,
}) => {
  const isEmpty = productArr[0] === "empty";
  const wrapperRef = useRef();
  const { primaryColor } = useColorTheme();

  useEffect(() => {
    if (productArr.length && !isEmpty && !Object.entries(product).length) {
      setDropdownVisible(true);
    } else {
      setDropdownVisible(false);
    }
  }, [productArr, product]);

  const onMenuClick = (el) => {
    handleSearch(el.title);
    setProduct(el);
    setDropdownVisible(false);
  };

  const inputHandler = (el) => {
    handleSearch(el);
    setProduct({});
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      if (dropdownVisible) {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setDropdownVisible(false);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
    }, [ref, dropdownVisible]);
  }
  useOutsideAlerter(wrapperRef);

  const menu = (
    <Menu>
      {productArr.length && !isEmpty ? (
        <span className="products-count">{productArr.length} Products</span>
      ) : null}
      {productArr.length && !isEmpty
        ? productArr.map((el, index) => {
            return (
              <div key={index}>
                <Menu.Item
                  onClick={() => onMenuClick(el)}
                  onItemHover={() => {}}
                >
                  <div className="product-item">
                    <img src={el.image ? el.image : productImage} />
                    <span>{el.title}</span>
                  </div>
                </Menu.Item>
              </div>
            );
          })
        : null}
    </Menu>
  );

  return (
    <Styles color={primaryColor}>
      <div className="row" ref={wrapperRef}>
        <Dropdown
          overlay={menu}
          placement="bottomLeft"
          trigger="click"
          visible={dropdownVisible}
          getPopupContainer={(trigger) => trigger.parentElement}
        >
          <div className="search">
            <img src={search} alt="search" />
            <input
              onChange={(e) => inputHandler(e.target.value)}
              placeholder="Choose Product"
              value={searchForProd}
            />
            {productArr.length && isEmpty ? (
              <span className="error">
                Product not listed. Select another product.
              </span>
            ) : null}
          </div>
        </Dropdown>
        <button type="button" onClick={() => setIsBtnClicked(true)}>
          <span>Get Product Insight</span>
        </button>
      </div>
    </Styles>
  );
};

export default ProductInsightSearch;
