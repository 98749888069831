import React from "react";
import { Tabs } from "antd";
//Images
import about from "assets/images/about.svg";
//Assets
import { TabWithIcon } from "assets/icons/icons";
//Components
import RatingSummary from "./RatingSummary";
import ProductInsight from "./ProductInsight";
import TrendAnalysis from "./TrendAnalysis";

const RatingTopTabs = ({ currentTab, setCurrentTab }) => {
  const { TabPane } = Tabs;

  function callback(key) {
    setCurrentTab(key);
  }

  return (
    <div className="main-box">
      <Tabs defaultActiveKey={currentTab} onChange={callback}>
        <TabPane
          tab={
            <TabWithIcon
              tabName={"Summary"}
              icon={about}
              tooltip={
                "Overall the prices moved up 20% based on the selected filters."
              }
            />
          }
          key="1"
        >
          <RatingSummary />
        </TabPane>
        <TabPane
          tab={
            <TabWithIcon
              tabName={"Product Insights"}
              icon={about}
              tooltip={
                "Overall the prices moved up 20% based on the selected filters."
              }
            />
          }
          key="2"
        >
          <ProductInsight />
        </TabPane>
        <TabPane
          tab={
            <TabWithIcon
              tabName={"Trend Analysis"}
              icon={about}
              tooltip={
                "Overall the prices moved up 20% based on the selected filters."
              }
            />
          }
          key="3"
        >
          <TrendAnalysis />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default RatingTopTabs;
