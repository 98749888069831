import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//Actions
import { setFilterMediaSummaryBottom } from "store/media/mediaSummaryBottom/actions";
import { setFilterMediaSummaryTop } from "store/media/mediaSummaryTop/actions";
//Components
import MediaSummaryBottom from "./MediaSummaryBottom";
import MediaSummaryTop from "./MediaSummaryTop";

const MediaSummaryTab = () => {
  //Selects
  const { filter: mediaSummaryBottomFilter } = useSelector((state) => state.mediaSummaryBottom);
  const { filter: mediaSummaryTopFilter } = useSelector((state) => state.mediaSummaryTop);
  //States
  const [isRedraw, setIsRedraw] = useState(false);
  const [clickedArray, setClickedArray] = useState([]);
  const [arrayOfDesiredBrand, setArrayOfDesiredBrand] = useState([]);
  const [retailer, setRetailer] = useState([]);

  //Const
  const dispatch = useDispatch();

  const resetClickedArr = async () => {
    new Promise((resolve, reject) => {
      setIsRedraw(false);

      setClickedArray([]);

      return setTimeout(() => {
        resolve();
        setIsRedraw(true);
      }, 500);
    });
  };

  useEffect(() => {
    resetClickedArr();
  }, [retailer, arrayOfDesiredBrand]);

  const setSelectValue = (values, func, filter) => {
    const value = Object.keys(values);

    const updateData = filter.map((item) => {
      if (item.name === value[0]) {
        return {
          ...item,
          value: values[value[0]],
        };
      } else if (item.name === value[1]) {
        return {
          ...item,
          value: values[value[1]],
        };
      }

      return item;
    });
    //set filters only when chart was redrawn
    resetClickedArr().then(() => {
      dispatch(func(updateData));
    });
  };

  return (
    <div>
      <MediaSummaryTop
        retailer={retailer}
        setRetailer={setRetailer}
        arrayOfDesiredBrand={arrayOfDesiredBrand}
        setArrayOfDesiredBrand={setArrayOfDesiredBrand}
        setSelectValue={(values) => setSelectValue(values, setFilterMediaSummaryTop, mediaSummaryTopFilter)}
      />
      <MediaSummaryBottom
        setSelectValue={(values) => setSelectValue(values, setFilterMediaSummaryBottom, mediaSummaryBottomFilter)}
        isRedraw={isRedraw}
        setIsRedraw={setIsRedraw}
        clickedArray={clickedArray}
        setClickedArray={setClickedArray}
        setArrayOfDesiredBrand={setArrayOfDesiredBrand}
        retailer={retailer}
        arrayOfDesiredBrand={arrayOfDesiredBrand}
      />
    </div>
  );
};

export default MediaSummaryTab;
