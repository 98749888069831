import React from "react";

const keys = {
  newlyListed: "Newly Listed",
  inStock: "In Stock",
  outOfStock: "Out of Stock",
  "de-listed": "Not Seen",
  relisted: "Reseen",
};

const PopupAvailabilityTable = ({ data }) => {
  return (
    <div className="table-wrapper-availability">
      <div className="table-header-availability">
        {Object.values(keys).map((el, i) => {
          return (
            <div key={i} className="table-item-availability availability-header">
              {el}
            </div>
          );
        })}
      </div>
      <div className="table-body-availability">
        {Object.keys(keys).map((el, i) => {
          return (
            <div key={i} className="table-item-availability availability-body">
              {data[el]}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PopupAvailabilityTable;
